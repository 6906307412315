import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080 1080">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
					<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M1081.000000,248.000000 
	C1081.000000,525.967896 1081.000000,803.435791 1081.000000,1080.903687 
	C1080.865479,1080.935791 1080.730835,1080.995972 1080.596313,1080.995972 
	C720.804565,1081.000000 361.012878,1081.000000 1.110574,1081.000000 
	C1.110574,721.115356 1.110574,361.230713 1.110574,1.173016 
	C360.999939,1.173016 720.999939,1.173016 1081.000000,1.173016 
	C1081.000000,83.186020 1081.000000,165.343002 1081.000000,248.000000 
M474.145355,512.622620 
	C475.053925,513.784973 475.962494,514.947327 476.896057,516.106323 
	C476.896057,516.106323 476.889374,516.133423 476.822845,516.859680 
	C478.395111,520.041260 480.050507,523.076721 484.308319,523.072266 
	C497.545105,523.058594 510.781891,523.082764 524.367676,523.532043 
	C524.906250,523.017639 525.444885,522.503174 526.263367,521.473083 
	C526.651001,521.031738 527.038696,520.590454 527.877808,520.004578 
	C527.905151,519.282532 527.932495,518.560425 527.663147,517.434265 
	C527.508423,517.371155 527.353760,517.308044 527.342590,516.403015 
	C527.278442,515.282227 527.214294,514.161499 527.593628,513.152283 
	C527.593628,513.152283 528.027710,513.008545 528.823303,512.855530 
	C529.203064,511.504242 529.848206,510.166687 529.918701,508.799438 
	C530.327881,500.868195 530.871643,492.929840 530.866211,484.994202 
	C530.854309,467.574585 530.510071,450.155365 530.384155,432.735413 
	C530.364685,430.041443 530.941650,427.823120 534.165710,426.568848 
	C536.230774,425.765472 538.902771,423.335876 539.087646,421.421417 
	C540.034790,411.612701 540.772400,401.722046 540.531128,391.885864 
	C540.407166,386.830688 534.026794,384.780914 528.037842,387.368683 
	C511.835297,387.386627 495.632751,387.404602 478.753082,387.190399 
	C478.148499,387.746338 477.543884,388.302277 476.538544,389.313110 
	C476.226715,389.831879 475.914886,390.350647 475.003326,391.088989 
	C474.997467,392.391785 474.991638,393.694580 475.047333,395.832214 
	C475.211426,396.634918 475.375519,397.437653 475.422882,399.127106 
	C475.318268,403.080750 475.213654,407.034424 474.369598,410.955017 
	C471.204407,407.365295 468.174164,403.643799 464.834961,400.223969 
	C461.677826,396.990601 461.640900,390.144958 454.050537,390.975861 
	C452.367950,389.968750 450.685333,388.961670 448.426117,387.352692 
	C434.034119,387.249390 419.642212,387.129211 405.250031,387.076477 
	C404.155640,387.072449 403.059509,387.546448 401.084625,387.775452 
	C398.449005,388.318451 395.813385,388.861420 392.381592,389.171783 
	C390.391022,388.467499 388.405823,387.170074 386.409027,387.151978 
	C369.113647,386.995300 351.778259,386.420746 334.540344,387.431183 
	C328.650055,387.776459 323.002716,390.959656 317.243103,387.077240 
	C294.444031,387.250916 271.644958,387.424591 247.956451,387.369629 
	C235.015686,387.428680 222.074921,387.487701 208.232040,387.189911 
	C199.444702,387.145935 190.653351,386.952576 181.873566,387.189209 
	C180.201035,387.234314 178.574310,388.979126 177.004868,389.988892 
	C177.004868,389.988892 176.933838,389.927368 176.191406,390.112091 
	C166.747253,390.035736 157.163269,391.827423 147.183914,387.799652 
	C136.082703,387.598389 124.981682,387.249329 113.880241,387.235474 
	C101.621101,387.220154 89.361588,387.512115 76.532394,387.377625 
	C75.974594,387.891815 75.416786,388.405975 74.169601,389.184265 
	C73.850945,389.554230 73.264679,389.915924 73.252907,390.295410 
	C72.985161,398.927826 72.442535,407.577667 72.837135,416.183685 
	C72.919998,417.990875 76.246964,420.486450 78.519356,421.144562 
	C81.369934,421.970123 82.193413,423.000122 82.179863,425.818481 
	C82.084564,445.637543 82.089516,465.457520 82.185692,485.276581 
	C82.200600,488.348175 81.348106,489.729614 78.049858,490.064362 
	C76.289009,490.243042 73.288437,492.005890 73.246475,493.142334 
	C72.905922,502.364990 73.133865,511.608673 73.163643,521.529724 
	C73.832306,521.952148 74.500969,522.374512 75.906670,523.025208 
	C80.522293,523.206543 85.139534,523.570557 89.753181,523.529541 
	C98.166946,523.454651 106.578575,523.139709 115.900803,523.093079 
	C119.587006,522.999268 123.273216,522.905457 127.604942,522.992188 
	C128.421890,522.674683 129.238831,522.357178 130.802032,521.960144 
	C132.498077,521.667297 134.194138,521.374451 136.548264,521.517822 
	C138.179077,522.083252 139.806259,523.126160 141.441269,523.138550 
	C154.296478,523.236145 167.153183,523.136658 180.361328,523.517517 
	C180.922226,523.031982 181.483109,522.546448 182.353058,521.606995 
	C182.783554,521.265625 183.214066,520.924194 184.503510,520.804382 
	C185.670929,520.867920 186.838364,520.931458 188.629639,521.453125 
	C190.454681,522.026733 192.276093,523.085205 194.105301,523.098877 
	C211.998459,523.232544 229.893478,523.261047 247.786209,523.109558 
	C249.919922,523.091553 252.043015,521.822021 255.077576,521.076111 
	C259.198853,521.029053 263.320160,520.987122 267.441345,520.933655 
	C273.215729,520.858826 275.659851,518.307190 276.059601,512.601013 
	C276.192902,510.698242 277.322357,508.865204 278.512939,507.430023 
	C283.750336,514.675049 291.087067,518.977600 299.260040,521.996887 
	C311.485413,526.513184 324.191315,526.845154 337.376007,526.498047 
	C338.291473,526.139893 339.206970,525.781799 340.915497,525.175232 
	C343.900024,524.608826 346.884583,524.042419 350.006439,524.310547 
	C352.836121,524.536743 355.963684,525.627991 358.423676,524.780701 
	C361.870911,523.593506 364.821198,520.963196 368.440491,518.923462 
	C368.440491,518.923462 368.897614,518.933594 369.566376,518.905396 
	C370.039124,518.263611 370.511871,517.621765 371.000000,517.000000 
	C371.000000,517.000000 370.983795,516.984253 371.722534,516.988159 
	C375.264923,514.468811 378.990265,512.161133 382.263855,509.330933 
	C383.665497,508.119171 384.126801,505.819733 385.699371,503.897247 
	C386.134949,502.933533 386.570526,501.969818 387.676331,500.940552 
	C388.946747,500.638641 390.217163,500.336731 391.734375,500.933746 
	C391.746857,506.242035 391.740356,511.550476 391.777954,516.858582 
	C391.807526,521.029236 393.544159,523.185669 398.118439,523.138428 
	C412.053772,522.994202 425.992065,523.160950 439.928986,523.125854 
	C444.872040,523.113464 449.814514,522.868530 455.447510,522.828979 
	C455.921753,522.176086 456.395966,521.523193 457.293060,520.360779 
	C463.596802,514.916016 462.235901,507.044586 463.087067,499.076233 
	C465.554016,501.272644 467.176605,502.717316 469.006165,504.505493 
	C469.006165,504.505493 469.272278,504.805664 469.139618,505.455994 
	C469.695862,506.020447 470.252106,506.584900 470.954163,507.061371 
	C470.954163,507.061371 470.834015,507.182068 470.767181,507.882202 
	C471.872253,509.221497 472.977295,510.560760 474.028137,511.975586 
	C474.028137,511.975586 474.107727,511.927490 474.145355,512.622620 
M609.613953,162.783432 
	C610.190796,161.155807 610.767639,159.528198 611.766296,157.391464 
	C611.940186,156.755280 612.114075,156.119110 612.856262,155.073929 
	C614.159241,151.700317 615.462280,148.326691 617.166199,144.327179 
	C618.860779,140.026337 620.555298,135.725510 622.897278,130.889709 
	C623.818237,130.189453 624.739136,129.489212 625.421936,129.724731 
	C625.407898,196.303833 625.390320,262.882935 625.393494,329.462036 
	C625.393616,331.944458 625.596558,334.426880 625.459045,337.482758 
	C625.853577,338.173615 626.248047,338.864502 626.825500,340.181854 
	C627.971985,341.756195 629.118408,343.330566 630.309692,345.512085 
	C630.819885,346.103790 631.330078,346.695496 631.937988,347.704865 
	C631.937988,347.704865 632.296753,347.940033 632.429504,348.589203 
	C633.290649,350.038483 634.151794,351.487762 635.017212,353.002930 
	C635.017212,353.002930 635.077759,352.976685 635.461670,353.577240 
	C636.084900,353.777466 636.707764,354.151031 637.331421,354.152283 
	C649.492981,354.177246 661.654724,354.187408 673.815796,354.096832 
	C674.828674,354.089294 675.836060,353.341980 677.394470,352.671204 
	C677.474487,352.084045 677.554504,351.496857 678.024170,350.218353 
	C678.057556,349.720306 678.120178,349.222290 678.120239,348.724243 
	C678.124939,243.608002 678.129333,138.491776 678.085571,33.375549 
	C678.084900,31.817474 677.483154,30.259649 676.996948,28.202164 
	C676.531433,28.005190 676.065979,27.808216 674.983459,27.136642 
	C659.353699,27.200274 643.723938,27.263905 627.307007,27.162346 
	C626.509399,27.222914 625.711731,27.283482 624.423340,27.019390 
	C623.088623,27.215351 621.753967,27.411312 619.877014,27.649702 
	C619.549805,28.042007 619.222595,28.434313 618.330383,29.040462 
	C617.922058,29.702229 617.513733,30.363997 616.589600,31.452658 
	C614.409729,34.562717 611.594116,37.404568 610.150940,40.825420 
	C598.919922,67.446480 587.986816,94.192993 576.891846,120.871727 
	C570.464417,136.326996 563.907959,151.728607 556.636658,166.979324 
	C555.708252,165.750198 554.779846,164.521072 554.129639,163.660233 
	C540.622437,163.824432 527.315125,165.623947 513.979065,161.095535 
	C518.708313,154.949142 523.166809,149.154602 527.625366,143.360077 
	C528.744995,140.626587 528.781738,137.194427 533.222839,135.943390 
	C534.203125,135.667236 534.745178,133.869186 535.509766,132.786606 
	C536.992493,130.687012 538.309021,128.434006 540.022400,126.541595 
	C542.796082,123.478264 546.106750,120.874107 548.688110,117.671043 
	C551.553711,114.115295 553.936829,110.156326 556.375122,106.275597 
	C558.053955,103.603600 559.469299,100.766014 561.708862,97.932030 
	C564.117615,95.997078 566.815308,94.324860 568.868408,92.066605 
	C572.079834,88.534409 574.217163,83.717247 577.951965,81.035614 
	C585.055542,75.935242 588.950012,69.047974 590.776062,61.087658 
	C592.273010,54.562057 592.475159,47.704227 592.832642,40.971443 
	C592.958374,38.602379 591.841370,36.167339 591.198486,33.354259 
	C590.365784,32.639496 588.815063,31.560558 587.576172,30.198135 
	C583.717896,25.955074 579.382080,23.169451 573.536377,21.648094 
	C567.162903,19.989397 561.115417,20.938240 554.891846,20.955328 
	C521.017151,21.048336 487.141876,20.935581 453.267212,21.036785 
	C442.029022,21.070360 430.941589,28.439613 429.613831,38.821396 
	C428.535553,47.252514 426.771759,56.176613 432.457611,64.776085 
	C437.692078,72.692917 444.527618,76.471214 453.143768,76.843132 
	C466.769684,77.431305 480.439758,76.961876 494.090790,77.020348 
	C499.393707,77.043053 501.733826,81.325729 498.693024,85.729446 
	C497.136902,87.983040 494.590698,89.530548 492.872833,91.698914 
	C486.475311,99.774269 480.325958,108.046928 473.890808,116.091438 
	C471.425354,119.173470 468.332581,121.745209 465.721222,124.721054 
	C461.901459,129.074005 458.015930,133.409012 454.662689,138.111267 
	C453.390991,139.894562 453.518188,142.675430 453.002502,145.667847 
	C453.006653,146.112030 453.010803,146.556229 452.383911,146.987183 
	C451.963715,146.982758 451.543488,146.978317 450.717102,146.736710 
	C450.717102,146.736710 450.285431,146.549942 450.310486,145.807175 
	C437.648895,115.769218 425.039185,85.709206 412.293121,55.707138 
	C408.606873,47.030357 405.761932,37.925922 399.084229,30.724867 
	C396.841980,28.306862 394.839172,27.017361 391.491638,27.051580 
	C376.330475,27.206564 361.166870,27.145700 346.004211,27.126635 
	C338.689850,27.117437 338.544647,27.174751 338.540192,34.695782 
	C338.518555,71.352837 338.521515,108.009911 338.527344,144.666977 
	C338.538025,211.982513 338.554382,279.298035 338.575806,346.613586 
	C338.578125,353.995850 338.600555,353.993347 346.169922,353.989197 
	C353.667969,353.985077 361.227997,353.383392 368.647614,354.145782 
	C377.025085,355.006622 382.484283,352.565216 386.376099,344.793457 
	C388.734802,340.083313 390.651398,335.821259 390.638092,330.368164 
	C390.483948,267.218353 390.550812,204.067963 390.528290,140.917740 
	C390.526703,136.433563 390.283813,131.949493 390.519775,126.979454 
	C391.015808,126.669777 391.511871,126.360107 392.043701,126.685692 
	C393.183350,129.241013 394.322998,131.796341 395.385101,134.553238 
	C395.441498,134.830688 395.600891,135.017303 395.887115,135.782593 
	C414.726440,180.149612 433.575531,224.512466 452.401459,268.885193 
	C463.438721,294.899994 474.508514,320.901459 485.392029,346.980560 
	C487.366638,351.712128 489.565369,354.530975 495.474579,354.170624 
	C505.096771,353.583923 514.788330,353.803925 524.436462,354.119293 
	C528.884521,354.264709 530.887451,352.477844 532.472168,348.518372 
	C538.959106,332.310211 545.787659,316.238983 552.448547,300.100067 
	C561.064270,279.224701 569.639893,258.332794 578.696472,237.094788 
	C578.912170,236.370255 579.127869,235.645721 579.759338,234.426041 
	C579.941895,233.806091 580.124451,233.186157 580.808899,232.092194 
	C581.697205,229.705170 582.585510,227.318161 583.881042,224.356598 
	C584.268372,223.264679 584.655640,222.172745 585.384827,220.806213 
	C585.587708,220.205276 585.790649,219.604340 586.393005,218.505066 
	C586.945312,217.370071 587.497620,216.235062 588.647034,214.994247 
	C588.880920,214.303680 589.114868,213.613113 589.677734,212.309998 
	C589.692261,211.850494 589.706726,211.391006 590.102356,210.386169 
	C590.526062,208.898788 590.949707,207.411392 591.818726,205.351578 
	C592.256287,203.985397 592.693909,202.619217 593.719482,200.902237 
	C595.553772,196.239868 597.388062,191.577499 599.653748,186.332733 
	C600.155518,184.990143 600.657227,183.647552 601.672485,181.857651 
	C603.950928,176.216843 606.229370,170.576019 608.845581,164.390793 
	C608.925110,163.973969 609.004578,163.557159 609.613953,162.783432 
M761.756104,523.050415 
	C762.493591,522.993774 763.231079,522.937134 764.858154,523.109314 
	C767.912659,523.047363 770.967224,522.985352 774.804932,523.069275 
	C775.860291,523.004578 776.915588,522.939880 778.888062,523.106384 
	C790.600586,523.040771 802.313049,522.975159 814.757751,523.082214 
	C815.509460,523.095337 816.261108,523.108398 817.485107,523.601990 
	C820.727783,523.318542 823.970398,523.035156 828.102539,522.965027 
	C829.763306,523.046631 831.424011,523.199036 833.084778,523.199158 
	C868.526489,523.200867 903.968140,523.195007 939.409851,523.160095 
	C943.231873,523.156372 947.053772,522.964844 951.546753,522.907288 
	C952.028931,522.269775 952.511047,521.632202 953.462036,520.315186 
	C953.618164,503.911469 953.910950,487.506104 953.718201,471.106506 
	C953.698975,469.475128 950.806091,466.603333 949.102295,466.490479 
	C942.363403,466.043976 935.566833,466.466919 927.908447,466.428680 
	C924.273132,466.285889 920.637878,466.143066 917.087097,465.314117 
	C917.286255,464.209320 917.485352,463.104553 917.977722,461.643494 
	C917.977722,461.643494 918.073303,461.192108 918.683838,460.976227 
	C919.385742,459.982697 920.087708,458.989166 921.276917,457.547974 
	C922.521790,456.041565 923.766724,454.535156 925.643494,452.829376 
	C927.110901,450.901276 928.578369,448.973145 930.691833,446.865265 
	C931.580200,445.611542 932.468628,444.357819 933.901062,442.939575 
	C934.277222,442.319244 934.653442,441.698914 935.626343,440.915161 
	C936.058960,440.276428 936.491577,439.637695 937.139404,438.742279 
	C937.139404,438.742279 937.150879,438.407562 937.727356,438.031189 
	C939.090210,436.335815 940.453125,434.640442 942.356445,432.739044 
	C942.602539,432.235931 942.848633,431.732819 943.755554,431.047546 
	C945.842224,428.367004 947.928955,425.686462 950.467773,422.782135 
	C950.632019,422.496185 950.796265,422.210236 951.574036,421.860413 
	C952.068848,421.244110 952.563599,420.627777 953.606750,419.943756 
	C953.769287,419.294922 953.931885,418.646057 954.191528,417.063812 
	C954.161499,410.403870 954.140015,403.743866 954.099365,397.083984 
	C954.046204,388.384857 952.950500,387.215668 944.339722,387.246796 
	C906.216125,387.384613 868.092651,387.566559 829.594238,387.360382 
	C829.013916,387.851227 828.433533,388.342072 827.464111,389.122101 
	C827.219360,389.334320 826.992737,389.564392 826.013672,389.876953 
	C824.268433,389.383911 822.535217,388.841095 820.773865,388.414795 
	C819.537048,388.115448 818.261780,387.974945 816.118530,387.739624 
	C812.765869,387.787537 809.413269,387.835449 805.212463,387.461517 
	C795.773560,387.583466 786.334595,387.705383 776.037048,387.496063 
	C752.692383,387.646759 729.347656,387.797455 705.154785,387.750366 
	C703.429382,387.872559 701.703918,387.994751 699.245605,387.963287 
	C698.497925,387.971710 697.750183,387.980133 696.684631,387.512756 
	C696.150574,388.038086 695.616516,388.563416 694.782166,389.230835 
	C694.782166,389.230835 694.450439,389.211334 693.652832,389.072418 
	C692.040161,389.560822 688.931946,386.233643 688.169678,390.957367 
	C687.113037,390.949585 686.056458,390.941803 684.496094,390.394562 
	C680.629456,389.285156 678.739990,390.748993 677.834961,394.697144 
	C674.374023,409.795532 676.054871,425.060608 676.443848,440.230316 
	C676.613586,446.851929 680.223755,448.933746 687.288147,449.001007 
	C692.267395,449.048431 697.249268,448.944000 702.225647,449.072815 
	C703.402222,449.103271 704.561584,449.798218 705.728821,450.187500 
	C705.191895,451.431427 704.799988,452.765778 704.091248,453.902649 
	C701.772095,457.623077 699.337646,461.271667 696.267151,465.052002 
	C693.146179,468.342865 690.119873,471.730682 686.876526,474.896179 
	C683.893677,477.807526 680.677429,480.479645 677.081482,482.686371 
	C676.445496,480.464600 675.855835,478.228363 675.166504,476.023254 
	C666.332703,447.761902 657.484558,419.504974 648.793518,390.593811 
	C648.226746,390.039154 647.659973,389.484528 646.600342,388.343597 
	C643.064453,387.984100 639.528564,387.624573 635.662720,386.702698 
	C634.779663,386.899506 633.896667,387.096313 632.076599,387.255402 
	C613.739685,387.272644 595.402710,387.289917 576.731323,386.750732 
	C575.767273,387.058411 574.803223,387.366119 573.284668,387.849762 
	C572.794128,388.138489 572.303589,388.427216 571.235779,388.917572 
	C570.568237,389.380066 569.900635,389.842529 568.499695,390.217560 
	C566.388855,392.298370 562.703979,394.213043 562.463562,396.491241 
	C561.754822,403.207611 562.077515,410.096893 562.666260,416.860229 
	C562.980957,420.475006 566.089600,424.239227 565.474487,427.466583 
	C563.821777,436.137146 560.955994,444.589478 558.360107,453.063568 
	C555.771973,461.512634 552.517395,469.781403 550.394592,478.337524 
	C549.540405,481.780426 548.150208,482.892120 545.118652,483.609680 
	C537.328735,485.453430 535.085693,488.502777 535.079773,496.578918 
	C535.077026,500.407257 535.425415,504.274048 535.001587,508.054657 
	C534.408203,513.348022 535.900146,517.378357 540.870239,520.637024 
	C541.223511,521.119873 541.576782,521.602722 541.983826,522.645447 
	C542.654175,522.800049 543.324463,522.954590 544.932190,523.145447 
	C564.293274,523.166870 583.654419,523.188232 603.452148,523.629761 
	C604.321167,523.113647 605.190247,522.597534 606.456970,521.799622 
	C606.707092,521.590210 606.933533,521.357727 607.769836,521.007507 
	C608.534180,520.119019 609.298584,519.230530 610.588257,518.430542 
	C610.831848,519.056396 611.075378,519.682190 611.077820,521.018677 
	C612.046204,521.708801 613.014526,522.398987 614.919556,523.132019 
	C620.290894,523.029114 625.662170,522.926208 631.669312,523.001038 
	C632.101990,522.944397 632.534607,522.887756 633.877869,523.090576 
	C638.929932,523.021484 643.981995,522.952393 649.673340,523.039673 
	C650.105774,522.984680 650.538269,522.929626 651.720398,523.112305 
	C652.491455,523.021057 653.262512,522.929810 654.670837,522.995178 
	C655.101318,522.941833 655.531799,522.888428 656.767761,523.102539 
	C667.517334,523.134338 678.266846,523.166138 689.481018,523.605408 
	C691.655579,523.401245 693.830200,523.197083 696.890808,523.036804 
	C699.250793,522.982910 701.610718,522.929016 704.853760,523.114868 
	C714.578369,523.046204 724.303040,522.977478 734.806641,523.068787 
	C735.860962,523.010803 736.915283,522.952820 738.841003,523.125000 
	C741.238037,523.046936 743.635010,522.968933 746.921387,523.062561 
	C749.935120,522.995056 752.948853,522.927551 756.783813,523.108215 
	C758.198059,523.041077 759.612366,522.973999 761.756104,523.050415 
M736.106506,656.276001 
	C736.192017,656.326172 736.277527,656.376343 736.363098,656.426514 
	C736.240723,656.285217 736.118347,656.143982 735.992188,655.230774 
	C735.992188,653.938538 735.992188,652.646362 735.992188,651.743835 
	C744.147522,651.743835 752.268616,651.743835 760.555725,651.743835 
	C760.380493,651.264038 760.062927,650.394348 759.627136,649.009827 
	C759.689392,648.665710 759.751648,648.321533 760.694946,647.992737 
	C761.187195,648.011108 761.680786,648.012451 762.171509,648.050415 
	C770.664001,648.708313 779.276428,651.384949 787.464844,646.564331 
	C787.558960,646.508850 788.106140,647.222839 788.478638,647.796997 
	C788.478638,647.796997 788.346436,647.975830 787.656189,648.363647 
	C787.534119,649.111267 787.412048,649.858948 787.383667,650.793335 
	C787.383667,650.793335 787.280884,650.975220 786.648865,651.290588 
	C786.428467,652.167419 786.208069,653.044189 785.501465,654.454712 
	C777.059753,676.392151 768.617981,698.329590 760.122681,720.406311 
	C769.367249,721.557190 769.343994,721.548157 772.295288,713.944946 
	C773.857544,709.919922 775.321899,705.835266 777.243103,701.983337 
	C777.883179,700.700012 779.787537,699.278625 781.146545,699.245178 
	C791.112305,699.000122 801.090759,698.942200 811.053467,699.229614 
	C812.639832,699.275330 814.867065,701.015930 815.593750,702.545227 
	C817.938965,707.480652 819.648193,712.713745 821.745300,717.773010 
	C823.393127,721.748596 829.117371,722.333008 832.723511,718.552979 
	C832.385437,718.073120 832.047363,717.593262 831.557556,716.815918 
	C831.557556,716.815918 831.616516,716.487122 831.689514,715.890076 
	C831.414612,715.626465 831.139771,715.362793 830.500854,714.646790 
	C830.356140,714.087036 830.211487,713.527222 830.526611,712.579773 
	C829.911133,711.423584 829.295654,710.267334 828.488098,708.856262 
	C828.488098,708.856262 828.515320,708.538330 828.639465,707.921387 
	C828.359314,707.638672 828.079102,707.355957 827.603149,706.806396 
	C827.603149,706.806396 827.611511,706.475586 827.707703,705.883118 
	C827.448425,705.603638 827.189148,705.324158 826.625732,704.611511 
	C826.582764,704.265442 826.539734,703.919312 826.765930,702.878235 
	C826.184265,701.927002 825.602600,700.975708 824.807861,699.690552 
	C824.807861,699.690552 824.761230,699.297180 824.887573,698.628784 
	C824.536987,698.114319 824.186340,697.599792 823.421082,696.674438 
	C823.291687,696.112122 823.162354,695.549866 823.370117,694.780212 
	C823.157104,694.561340 822.944092,694.342468 822.545593,693.846924 
	C822.545593,693.846924 822.571472,693.514954 822.675293,692.903748 
	C822.400208,692.623840 822.125183,692.343933 821.641113,691.791443 
	C821.641113,691.791443 821.629272,691.448242 821.726807,690.881958 
	C821.492371,690.599915 821.257935,690.317810 820.848267,689.668945 
	C820.848267,689.668945 820.807434,689.264526 820.808594,688.748169 
	C820.583252,688.502502 820.357910,688.256775 819.903381,687.492432 
	C819.831116,687.123230 819.758789,686.754028 819.849304,685.689514 
	C819.485474,685.150391 819.121582,684.611206 818.576416,683.826416 
	C818.576416,683.826416 818.583130,683.521179 818.815186,682.763916 
	C818.222961,681.847778 817.630676,680.931641 816.868713,679.623535 
	C816.868713,679.623535 816.833984,679.197815 816.920715,678.543396 
	C816.562805,678.042358 816.204834,677.541321 815.539124,676.488464 
	C815.223083,675.490173 814.907043,674.491943 814.799072,672.765808 
	C814.147461,671.526855 813.495850,670.287903 812.656677,668.774292 
	C812.656677,668.774292 812.665100,668.441772 812.740356,667.859985 
	C812.488708,667.583618 812.237061,667.307251 811.714294,666.580444 
	C811.653809,666.237671 811.593323,665.894897 811.752197,664.901794 
	C811.304993,664.245239 810.857788,663.588684 810.089294,662.451721 
	C809.970642,662.087280 809.851990,661.722778 809.801758,660.824463 
	C809.587952,660.552246 809.374207,660.280029 808.919189,659.488342 
	C808.842590,659.115845 808.765991,658.743286 808.884705,657.636963 
	C808.261047,656.435486 807.637390,655.234070 806.793152,653.692322 
	C806.793152,653.692322 806.755249,653.288574 806.891479,652.606689 
	C806.511719,652.101013 806.131958,651.595276 805.543030,650.810730 
	C805.543030,650.810730 805.566528,650.462952 805.794617,649.752930 
	C805.410828,649.209961 805.027039,648.666992 804.521301,647.823792 
	C804.521301,647.823792 804.724976,647.571655 804.920654,646.863403 
	C805.592590,646.800720 806.808105,646.554321 806.857971,646.701904 
	C808.714844,652.195862 813.686340,653.118530 819.100403,655.693176 
	C819.833191,650.557617 822.182739,645.494324 820.647461,642.295776 
	C818.062561,636.910522 813.011353,632.709106 808.999023,628.000000 
	C808.999023,628.000000 808.990234,628.002441 808.986816,627.303528 
	C807.992004,626.203979 806.997253,625.104431 805.711304,623.403320 
	C804.019470,621.384521 802.354736,619.342224 800.630371,617.351624 
	C796.766357,612.891357 792.872559,608.456787 788.770081,603.573975 
	C788.569092,603.301025 788.307251,603.112793 787.915039,602.367004 
	C787.283081,601.904175 786.651123,601.441345 785.935791,600.322937 
	C784.951599,599.218018 783.967468,598.113159 782.998291,596.339600 
	C782.998047,595.893860 782.997803,595.448181 783.935181,594.995544 
	C793.848755,594.993225 803.762451,595.014160 813.675964,594.979980 
	C819.870911,594.958618 820.993835,593.850830 821.019958,587.895630 
	C821.037354,583.930481 821.008301,579.965210 821.010498,575.140564 
	C821.016907,573.421082 821.023315,571.701538 821.308655,569.349731 
	C821.218262,567.898621 821.127869,566.447571 821.038940,564.059631 
	C821.047119,557.899963 821.079590,551.740173 821.057129,545.580566 
	C821.031616,538.591064 820.403198,537.973938 813.227539,537.972412 
	C775.603394,537.964661 737.979126,538.003357 700.355103,537.928528 
	C696.300598,537.920410 692.311584,538.449951 688.185974,538.331970 
	C664.066162,537.641968 639.916077,537.905762 615.779297,538.151917 
	C614.170715,538.168335 611.421143,540.528809 611.202820,542.080505 
	C610.422058,547.631104 610.503479,553.303101 610.105286,559.811279 
	C610.084534,560.639282 610.043457,561.467407 610.046204,562.295349 
	C610.072144,570.029358 611.989807,571.928894 619.716370,572.022644 
	C621.293945,572.041748 622.868591,572.301025 624.444641,572.449646 
	C624.390198,572.802307 624.335754,573.154968 624.281372,573.507629 
	C615.406250,573.507629 606.531128,573.507629 596.454346,573.507629 
	C608.426270,568.744324 608.426270,568.744324 607.915771,556.035156 
	C607.848694,552.229065 607.801025,548.422424 607.709839,544.616943 
	C607.583862,539.361084 604.859619,537.794373 599.708862,537.874817 
	C581.475403,538.159668 563.235229,538.009583 544.670288,537.562134 
	C544.084961,538.014648 543.499634,538.467224 542.267151,539.302612 
	C541.516479,541.204834 540.213074,543.080322 540.119507,545.014282 
	C539.846802,550.648865 540.057983,556.306763 540.006104,562.818420 
	C539.976562,570.653015 541.094238,572.067566 548.635376,571.922668 
	C553.422058,571.830627 554.797668,574.701904 555.927673,578.405823 
	C558.230957,585.955688 560.653992,593.469055 563.111694,601.785828 
	C565.076111,607.858032 567.040588,613.930237 568.998901,620.835815 
	C570.107300,624.865295 571.277039,628.879272 572.302368,632.929749 
	C572.978882,635.602478 573.447876,638.327698 573.087402,641.204773 
	C568.173462,641.204773 563.259460,641.204773 558.488159,641.204773 
	C558.488159,668.257324 558.488159,694.467468 558.488159,720.646667 
	C569.771362,720.646667 580.631653,721.312439 591.372742,720.466064 
	C602.937622,719.554871 612.009644,713.824280 617.631775,703.131958 
	C624.096375,690.837341 625.395691,663.770813 611.032593,648.934387 
	C611.202026,647.772644 611.371460,646.610901 611.616882,644.928467 
	C616.218689,653.871460 634.219666,656.005859 642.526733,649.134216 
	C648.107178,652.492126 648.107178,652.492126 653.297668,652.666138 
	C653.297668,652.666138 653.048035,652.999451 652.519348,653.366089 
	C652.415649,653.773682 652.311951,654.181213 652.295227,655.206421 
	C651.881104,656.138000 651.466980,657.069641 650.520264,658.370605 
	C650.421509,658.782349 650.322754,659.194031 650.340271,660.317200 
	C642.432922,680.282837 634.525574,700.248474 626.516113,720.472046 
	C635.978271,721.404968 635.988953,721.409241 639.060730,713.766602 
	C640.426331,710.368958 641.934021,707.010803 643.020142,703.523499 
	C644.090088,700.087891 646.079407,698.897156 649.652039,699.019104 
	C656.810242,699.263306 663.982666,699.092529 671.149170,699.094604 
	C680.975220,699.097412 680.973511,699.098694 684.654358,708.441284 
	C685.447937,710.455444 686.258179,712.463135 687.061768,714.473328 
	C689.970154,721.748535 690.119690,721.825806 698.931641,720.329163 
	C698.594177,719.059021 698.385132,717.779175 697.921265,716.599548 
	C688.908447,693.677612 679.595093,670.868042 671.018738,647.784485 
	C668.856750,641.965393 665.755859,640.458679 660.385315,641.261108 
	C659.566467,641.383484 658.757751,641.573425 658.019958,640.845093 
	C659.681580,634.233582 661.343262,627.622131 663.014893,620.511963 
	C663.014893,620.511963 663.021973,620.013489 663.705200,619.897766 
	C664.135193,618.933167 664.565125,617.968628 665.008911,616.212830 
	C666.290710,612.380798 667.625610,608.565552 668.842957,604.713135 
	C671.238464,597.132324 673.568726,589.530884 676.052246,581.188477 
	C676.408997,580.095032 676.946960,579.024902 677.091003,577.904114 
	C677.708679,573.100464 680.273438,571.227234 685.102051,571.883850 
	C686.776245,572.111511 688.577820,571.173706 690.325500,571.154480 
	C692.004089,571.135925 693.830505,571.300415 695.328308,571.972229 
	C696.708679,572.591370 697.738770,573.991638 699.033508,575.673767 
	C699.662231,576.122314 700.290894,576.570862 701.001953,577.658142 
	C701.368164,578.429810 701.734436,579.201477 702.347534,580.590271 
	C709.582458,589.050171 716.817383,597.510010 724.322327,606.596069 
	C726.492371,609.721619 728.662354,612.847168 730.863342,616.017273 
	C720.052185,616.017273 709.835388,615.966980 699.619568,616.043213 
	C695.789856,616.071777 693.362427,617.540161 693.932373,622.056274 
	C694.261658,624.665222 694.006653,627.348022 694.003662,630.935059 
	C694.003662,638.231750 694.003662,645.528381 694.003662,653.129700 
	C704.582397,649.325012 714.087097,651.721497 723.299011,651.004517 
	C723.902710,653.128418 724.301086,654.529907 724.881470,656.860840 
	C724.881470,678.070862 724.881470,699.280884 724.881470,721.429138 
	C728.652893,720.827759 731.469299,720.378662 734.765747,719.313232 
	C734.663025,715.913391 734.560364,712.513489 734.284546,708.377808 
	C734.342163,707.880676 734.399719,707.383484 734.855835,706.196838 
	C734.730896,695.173035 734.605957,684.149170 734.299744,672.192200 
	C734.374512,666.814026 734.449219,661.435791 735.016724,656.044495 
	C735.344727,656.014343 735.673401,655.998108 736.106506,656.276001 
M458.324829,572.051208 
	C457.893921,572.705261 457.463013,573.359253 456.506500,574.403870 
	C456.316345,575.237610 456.126160,576.071350 455.261139,577.041870 
	C451.715118,581.744019 448.169067,586.446167 443.971527,592.012207 
	C443.971527,584.293640 443.971527,578.140442 443.996216,571.987915 
	C443.996216,571.987915 443.986267,571.971741 444.826630,571.981812 
	C446.226013,571.938721 447.625366,571.895630 449.763092,571.907898 
	C451.173065,571.596313 452.583038,571.284790 454.632568,570.908936 
	C455.105865,570.283264 455.579163,569.657593 456.633209,568.425781 
	C456.751801,561.283569 456.870361,554.141418 457.016632,546.169312 
	C456.984253,544.786377 456.951874,543.403442 456.963928,541.312927 
	C456.311646,540.537292 455.659363,539.761719 454.250092,538.628235 
	C435.298828,538.514587 416.347534,538.337708 397.396271,538.336853 
	C393.261139,538.336670 391.564178,540.962952 391.407837,545.523682 
	C391.364075,547.031311 391.320312,548.538940 391.180450,550.768127 
	C391.213898,551.492798 391.247314,552.217529 391.117706,553.871521 
	C391.098877,557.356262 390.461792,560.989868 391.253693,564.279480 
	C391.906036,566.989197 394.233673,571.393738 395.850342,571.407654 
	C402.606079,571.466125 402.898804,574.970398 402.095459,579.991028 
	C401.837646,581.602539 402.123718,583.301025 402.058716,585.897217 
	C402.368927,594.932312 402.679138,603.967346 402.398041,613.592590 
	C402.263428,615.724365 402.125702,617.855957 401.994720,619.987976 
	C401.472595,628.487488 403.554047,637.203491 399.117615,645.248718 
	C395.736633,644.133545 392.355682,643.018433 388.451630,641.363831 
	C387.190216,641.292419 385.928772,641.221008 384.108215,641.298584 
	C383.730804,641.240417 383.353394,641.182251 382.975006,640.280518 
	C382.969666,634.849731 382.964325,629.419006 382.979156,623.053650 
	C382.646484,613.364197 382.313843,603.674744 381.979675,593.093018 
	C381.947937,590.423157 381.916168,587.753296 381.922821,584.469177 
	C381.615631,583.979736 381.308441,583.490295 380.991943,582.212524 
	C380.968445,581.161804 380.944946,580.111023 380.933319,578.444275 
	C380.610046,577.963196 380.286804,577.482117 379.878113,576.240479 
	C379.581116,575.140991 379.438751,573.968628 378.960693,572.954529 
	C376.749817,568.264282 374.445679,563.617981 372.125336,558.472229 
	C372.125336,558.472229 372.040527,557.993347 372.028442,557.262024 
	C371.015289,555.849060 370.002136,554.436096 368.700165,552.413879 
	C364.047485,546.098938 357.825012,541.825317 350.615326,539.053406 
	C330.611206,531.362488 311.007721,533.352539 292.163269,542.681458 
	C287.539001,544.970703 284.017975,549.488464 279.368591,553.252747 
	C271.660614,560.902588 269.051758,570.827942 266.428009,581.526062 
	C265.964020,592.346802 265.500031,603.167542 265.009766,614.897522 
	C265.009583,617.930054 265.009369,620.962585 264.998474,624.928833 
	C264.998138,627.402832 264.996735,629.876770 264.997650,632.350769 
	C264.999908,638.390076 259.850464,642.578491 253.742416,641.854187 
	C250.905487,641.517822 247.985336,641.883240 245.018753,641.010620 
	C244.986298,632.325867 244.953842,623.641174 244.956757,614.109863 
	C244.966721,612.409058 244.976685,610.708313 245.222778,608.464111 
	C245.146942,607.643311 245.071091,606.822571 245.000717,605.064087 
	C244.972214,595.359131 244.943710,585.654236 244.946991,575.178162 
	C245.938522,574.061401 246.930069,572.944641 248.679611,571.939758 
	C249.805069,571.311279 250.930542,570.682800 252.496887,569.471985 
	C253.310944,567.750488 254.815384,566.030457 254.819046,564.307251 
	C254.834595,557.012512 254.316132,549.718445 254.174118,542.420654 
	C254.105103,538.875000 252.311554,537.922302 249.102386,537.934204 
	C230.625412,538.002747 212.148026,537.994507 193.670914,537.949280 
	C189.736359,537.939636 187.782227,539.606079 187.920303,543.648315 
	C188.020935,546.594360 187.939972,549.546570 187.939972,552.495972 
	C187.410553,552.516052 186.881134,552.536133 186.351715,552.556274 
	C185.648865,549.874329 184.836899,547.215393 184.267166,544.505493 
	C183.255280,539.692566 180.676376,537.688904 175.504684,537.854187 
	C164.363129,538.210205 153.199249,538.115112 142.050949,537.888245 
	C137.709122,537.799927 135.655594,539.588806 134.870621,543.647217 
	C134.446060,545.842102 133.568588,547.949463 132.895447,550.096313 
	C131.947433,548.853027 131.961288,547.861755 131.945236,546.871094 
	C131.823395,539.350098 130.407578,537.965454 122.814087,537.963501 
	C108.331932,537.959717 93.849724,537.938477 79.367661,537.976074 
	C74.197006,537.989441 71.995583,539.977722 72.056435,545.160278 
	C72.130226,551.445129 72.657486,557.724609 72.956772,564.671875 
	C72.975990,565.115540 72.995201,565.559204 73.003723,566.748413 
	C75.336517,568.145203 77.669319,569.542053 80.364830,571.637634 
	C80.918167,573.462341 81.904137,575.276306 81.949913,577.113708 
	C82.147591,585.048523 82.069977,592.990173 82.032104,601.873718 
	C82.350273,614.249146 82.668449,626.624573 82.374817,639.465698 
	C81.401169,639.973572 80.336136,641.040833 79.468582,640.899170 
	C73.535622,639.929871 72.263199,642.900391 72.963837,647.977417 
	C73.205360,649.727600 73.002251,651.539062 73.002251,654.918396 
	C76.925362,653.653625 79.816612,652.455933 82.826805,651.832886 
	C85.355026,651.309570 88.077026,651.051636 90.614647,651.398193 
	C94.654457,651.949890 98.628716,653.924866 102.601715,653.845642 
	C114.491837,653.608337 126.369049,652.685974 138.248810,651.988708 
	C142.011292,651.767883 145.404449,652.032959 148.046951,655.410278 
	C149.741791,657.576416 152.279984,658.052856 153.467209,654.853455 
	C156.146698,647.632690 158.501709,640.291443 161.000549,632.216797 
	C161.770706,629.871460 162.540878,627.526123 163.311035,625.180786 
	C163.769150,625.256531 164.227264,625.332214 164.685379,625.407959 
	C165.124985,627.273682 165.564590,629.139404 166.082077,631.756836 
	C167.053604,634.887573 167.933029,638.050659 169.020096,641.140747 
	C170.366501,644.968079 171.640396,648.862305 173.502243,652.437683 
	C173.964905,653.326172 176.565857,653.311157 178.190887,653.341614 
	C181.849258,653.410339 185.528046,653.008240 189.168015,653.252441 
	C192.254639,653.459534 195.283981,654.556030 198.368958,654.734131 
	C199.488525,654.798828 201.005508,653.578003 201.796631,652.531921 
	C203.296722,650.548340 204.403717,648.267517 206.152039,645.298340 
	C206.763916,647.149048 206.965607,647.759094 206.907532,648.932129 
	C200.679047,658.996399 201.875351,669.410950 209.966049,675.026428 
	C209.966049,675.026428 209.873535,675.089783 210.136246,675.703003 
	C212.641815,677.293457 214.990173,679.252991 217.684357,680.400635 
	C224.089706,683.129028 230.670303,685.443481 237.158630,687.980103 
	C244.957642,691.029236 248.836258,696.406555 247.950684,702.825378 
	C247.086105,709.092285 240.969284,713.931091 232.870285,714.101318 
	C228.600601,714.191040 224.311127,713.340088 220.012939,712.989807 
	C220.012939,712.989807 220.076965,712.945374 219.726852,712.351135 
	C217.123108,709.923706 213.937515,707.879761 212.033463,704.991089 
	C207.704773,698.424072 207.534851,698.078430 198.623810,699.708496 
	C198.779419,700.644348 198.788849,701.640076 199.113968,702.519104 
	C207.666611,725.642456 236.672577,727.824036 251.346619,716.457642 
	C259.746063,709.951538 261.123352,698.820190 255.431793,689.877441 
	C250.170990,681.611572 241.783127,679.124023 232.430847,676.981689 
	C232.016876,676.961548 231.602905,676.941406 230.882858,676.285400 
	C228.412964,675.361145 225.943054,674.436829 223.423065,673.552979 
	C223.423065,673.552979 223.366135,673.522949 223.029907,672.984497 
	C221.793045,672.163330 220.543716,671.360168 219.321320,670.518005 
	C211.415283,665.071411 211.686951,655.723877 219.892136,650.676636 
	C229.736572,644.620972 242.264450,648.770813 247.111359,660.092590 
	C247.392548,660.164734 247.673752,660.236816 248.852966,660.287109 
	C250.933884,660.390991 253.014786,660.494812 255.271484,660.756287 
	C255.271484,660.756287 255.612274,660.789673 255.612274,660.789673 
	C255.612274,660.789673 255.904144,660.603760 256.525574,660.305176 
	C256.908081,659.555786 257.290619,658.806458 258.505737,658.022827 
	C261.164062,657.346985 263.799255,656.258057 266.487000,656.107544 
	C269.373322,655.945923 269.942932,654.768127 270.089478,652.188721 
	C270.217255,649.940613 271.189758,647.740356 271.914215,646.164001 
	C271.762115,646.562927 271.609985,646.961792 271.285736,647.960510 
	C271.490753,648.302917 271.695770,648.645386 272.065002,649.914368 
	C272.088470,664.595215 272.111938,679.276123 271.561646,694.367798 
	C272.007355,696.239746 272.453064,698.111694 273.126129,700.518005 
	C273.135712,700.901489 273.145294,701.285034 273.062653,702.328918 
	C273.352356,702.881042 273.642059,703.433228 274.291595,704.602356 
	C275.152771,706.393005 275.861542,708.277832 276.899902,709.959045 
	C283.620483,720.840393 298.883118,725.698853 313.708130,721.541077 
	C320.623627,719.601562 325.913300,715.868774 329.644775,709.159668 
	C332.706238,703.655273 334.065033,698.174561 334.158966,692.165161 
	C334.384583,677.729797 334.497192,663.292603 335.581757,648.938599 
	C339.927979,649.129761 344.274200,649.320984 349.133331,649.534790 
	C349.133331,646.774048 349.133331,644.427002 349.133331,642.079956 
	C349.417999,642.085632 349.702698,642.091370 349.987366,642.097046 
	C349.987366,643.731079 349.987305,645.365173 349.987366,646.999207 
	C349.988281,668.567322 349.970551,690.135498 350.025085,711.703491 
	C350.029510,713.455750 350.528595,715.206787 350.942688,717.636414 
	C350.907715,718.095520 350.872742,718.554565 350.539093,719.224915 
	C350.691742,719.482483 350.844360,719.740051 351.604828,720.579407 
	C363.562683,720.608032 375.527313,720.871704 387.474304,720.525330 
	C391.169403,720.418213 394.964813,719.010925 398.455902,717.572021 
	C406.866638,714.105469 409.225098,707.088135 409.216919,698.582153 
	C409.208862,690.216675 405.095490,684.855713 397.907623,681.376221 
	C396.950012,680.912598 395.927338,680.583374 394.026428,679.833740 
	C396.157410,678.535339 397.518494,677.706055 399.378326,676.791992 
	C399.558868,676.522339 399.739380,676.252625 400.208527,675.748230 
	C400.208527,675.748230 400.564758,675.641113 401.200684,675.598328 
	C401.460815,675.058350 401.720947,674.518372 402.299622,673.444214 
	C402.735352,672.999756 403.171082,672.555237 404.110504,671.922058 
	C404.251831,671.454102 404.393127,670.986145 404.721588,669.910583 
	C405.125519,668.931396 405.529449,667.952209 406.553589,666.439514 
	C406.713409,663.296387 406.873230,660.153320 407.738068,657.081543 
	C409.264038,658.008606 411.590576,660.093689 412.189301,659.679688 
	C416.987549,656.362183 421.364655,661.140137 425.925690,660.023926 
	C427.032715,659.753113 428.575165,660.214722 429.300812,659.605347 
	C433.471802,656.102722 438.382965,657.082214 443.114929,657.016174 
	C448.090851,656.946716 453.068481,657.004822 458.181030,657.763855 
	C458.438477,659.516296 458.695923,661.268738 458.622559,663.205933 
	C458.807159,663.434814 458.991760,663.663696 459.324402,664.251099 
	C459.324402,664.251099 459.341095,664.638733 459.276581,665.185608 
	C459.514038,665.448059 459.751465,665.710510 460.315643,666.541443 
	C460.974945,667.918518 461.634247,669.295532 462.285736,671.234436 
	C462.565979,671.458679 462.846191,671.682861 463.523560,672.446838 
	C464.411377,673.794800 465.299225,675.142761 466.287079,676.642578 
	C463.227875,678.710632 460.391541,680.436462 457.767273,682.440063 
	C451.336487,687.349792 447.237732,693.551331 447.822418,702.024719 
	C448.604828,713.363586 457.557617,721.660706 469.810669,722.658752 
	C481.240814,723.589783 491.595734,721.006042 500.793213,712.850464 
	C505.615082,720.041199 511.884460,722.877686 520.502686,720.125305 
	C517.183899,717.032471 514.311035,713.793457 510.889130,711.311218 
	C506.793549,708.340332 506.390045,705.700378 508.509827,700.945618 
	C511.050018,695.247925 511.878571,688.787231 513.579163,682.146057 
	C509.399994,682.146057 506.796783,682.146057 504.384247,682.146057 
	C502.923126,688.044312 501.529419,693.670532 500.135712,699.296692 
	C499.552368,699.373657 498.969055,699.450623 498.385712,699.527588 
	C492.226593,693.030640 486.067444,686.533630 480.721832,680.894775 
	C486.683777,674.752380 491.917450,669.360352 497.595123,663.569092 
	C497.671722,663.182495 497.748322,662.795898 497.694244,661.602905 
	C497.765015,659.402405 497.835815,657.201904 498.762665,655.023865 
	C507.796722,655.817627 516.835449,659.173706 525.828796,654.347412 
	C527.893982,653.239075 530.131470,653.755066 531.139771,656.948059 
	C531.790588,659.008850 533.872986,660.617554 535.313843,662.428894 
	C535.843323,662.179810 536.372803,661.930786 536.902283,661.681702 
	C537.910767,656.933472 539.050781,652.208374 539.889832,647.430420 
	C540.686890,642.891846 539.055603,640.948669 534.411682,641.059204 
	C531.126953,641.137390 528.888428,640.519897 528.661377,636.703857 
	C528.625732,636.105225 527.587036,635.566284 527.007263,634.125793 
	C527.011353,632.086792 527.015442,630.047852 527.682251,627.902588 
	C527.463623,626.931335 527.244995,625.960144 527.017578,624.079651 
	C527.014160,619.726257 527.010742,615.372925 527.108887,610.184875 
	C527.393799,608.562439 527.915039,606.941650 527.925781,605.317383 
	C528.004089,593.503540 528.014465,581.688782 527.946655,569.874878 
	C527.937439,568.262878 527.375610,566.654114 527.067932,564.101196 
	C527.082092,557.288513 527.054749,550.475342 527.126343,543.663269 
	C527.167786,539.720215 525.396912,537.918884 521.391724,537.944458 
	C509.096191,538.023010 496.798828,537.896301 484.504883,538.056641 
	C482.886200,538.077759 480.689514,538.856201 479.785828,540.056091 
	C477.221588,543.460938 475.188690,547.265930 472.284363,551.054321 
	C471.538300,552.376404 470.792236,553.698425 469.541779,555.339600 
	C469.354279,555.890930 469.166779,556.442200 468.376648,557.299316 
	C465.236481,562.174927 462.096344,567.050537 458.324829,572.051208 
M856.995850,606.004272 
	C856.995850,606.004272 856.985168,606.017517 857.000427,606.740906 
	C859.556824,609.883606 862.113220,613.026306 864.534973,616.003418 
	C854.176758,616.003418 844.153259,615.801758 834.148071,616.140991 
	C831.547302,616.229187 827.447632,615.214294 826.216064,620.169495 
	C824.753784,623.265015 823.429993,626.348511 826.989258,629.805786 
	C826.990356,630.870544 826.991516,631.935242 826.263489,633.140015 
	C825.915283,636.038208 825.047607,638.990662 825.342896,641.821716 
	C825.765625,645.874878 826.922913,649.872131 828.039246,653.815979 
	C828.280579,654.668335 829.970886,655.949463 830.500366,655.746338 
	C834.142944,654.349426 839.915466,657.655701 841.307373,650.938477 
	C841.476562,650.122192 843.436951,649.199036 844.602783,649.148682 
	C850.177246,648.907410 855.777649,648.676208 861.343506,648.951904 
	C871.186768,649.439453 881.003418,650.566040 890.848328,650.868958 
	C894.864990,650.992615 898.943848,649.019043 902.963989,649.127380 
	C913.028687,649.398682 923.068420,650.600342 933.133118,650.869690 
	C939.651001,651.044067 946.116699,650.466003 950.584900,656.572449 
	C950.697815,656.726807 951.206055,656.591858 952.314636,656.591858 
	C952.863770,652.903198 953.752747,649.098633 953.920715,645.262573 
	C954.136169,640.342163 951.407959,637.008728 946.897644,634.328247 
	C943.598816,630.086304 940.441650,625.724121 936.966003,621.632324 
	C932.444336,616.309204 927.669739,611.200867 922.909302,605.368469 
	C922.281738,604.904968 921.654175,604.441406 921.006958,603.273010 
	C920.337158,602.515381 919.667358,601.757690 918.904297,600.303467 
	C917.702759,598.891968 916.501221,597.480469 914.694580,595.358215 
	C917.045837,595.196411 918.607178,595.001160 920.169189,594.996033 
	C929.258728,594.966125 938.348572,594.996277 947.438171,594.973694 
	C951.758240,594.963013 954.317505,593.185730 954.019531,588.424011 
	C953.864990,585.954407 953.975769,583.468262 953.991333,580.055237 
	C953.968445,574.362305 953.945557,568.669373 953.968262,562.043335 
	C953.981079,556.382202 954.113098,550.718201 953.972839,545.060852 
	C953.835754,539.529053 952.352600,537.984802 947.223450,537.980713 
	C908.427551,537.950073 869.631592,537.996338 830.835815,537.940918 
	C826.530823,537.934753 824.844421,539.852051 824.944397,544.017273 
	C825.080200,549.675415 824.994141,555.338867 824.967102,561.667725 
	C824.981140,562.113159 824.995117,562.558594 825.003601,563.744141 
	C825.023926,564.483948 825.044250,565.223755 825.014954,566.713196 
	C826.069763,568.693481 826.824463,570.923828 828.230469,572.611633 
	C835.065002,580.816406 842.073914,588.875977 849.075745,597.675049 
	C850.378235,598.790100 851.680786,599.905151 853.052063,601.656799 
	C853.700378,602.439514 854.348755,603.222168 855.078369,604.643738 
	C855.713379,605.100464 856.348389,605.557129 856.995850,606.004272 
M109.827431,824.169556 
	C106.547020,823.677612 105.564034,825.057678 105.589478,828.350769 
	C105.734825,847.162720 105.665337,865.976379 105.668625,884.789429 
	C105.669174,887.912842 105.668701,891.036255 105.668701,894.294800 
	C117.401398,894.294800 128.649261,894.378967 139.893661,894.219360 
	C141.986588,894.189636 144.118225,893.325867 146.141571,892.596252 
	C153.394455,889.981018 156.859589,884.608826 157.234940,877.036377 
	C157.605560,869.559082 155.151611,863.687866 148.042679,860.310120 
	C146.957504,859.794556 146.041122,858.923706 144.922913,858.129211 
	C154.155365,850.859375 156.691940,844.830017 154.035583,836.466858 
	C151.211426,827.575378 143.900070,824.982727 135.716629,824.498657 
	C127.419930,824.007874 119.079453,824.257019 109.827431,824.169556 
M796.739502,883.469482 
	C796.736816,865.155762 796.635071,846.841003 796.814697,828.529053 
	C796.850769,824.847595 795.509766,824.110168 792.182373,824.065735 
	C788.711609,824.019348 788.039734,825.412292 788.064880,828.521362 
	C788.187439,843.670776 788.121948,858.821838 788.095154,873.972290 
	C788.092468,875.491760 787.902039,877.010864 787.798767,878.530090 
	C785.409180,877.262756 784.240417,875.717285 783.132263,874.129456 
	C772.575317,859.002625 761.809875,844.014038 751.604919,828.653076 
	C748.302063,823.681396 744.609436,823.563477 739.757507,824.730103 
	C739.757507,848.096497 739.757507,871.168213 739.757507,894.457764 
	C742.592041,894.457764 745.127502,894.457764 748.281006,894.457764 
	C748.281006,876.247192 748.281006,858.411560 748.281006,840.575928 
	C750.463318,841.539673 751.631958,842.817871 752.612915,844.226807 
	C763.347656,859.644348 774.047791,875.086121 784.769653,890.512634 
	C788.195312,895.441284 790.194946,896.004272 796.738586,893.786255 
	C796.738586,890.749573 796.738586,887.602905 796.739502,883.469482 
M377.395844,894.682861 
	C379.977844,894.682861 382.559875,894.682861 385.582886,894.682861 
	C385.582886,892.411987 385.582794,890.773132 385.582886,889.134216 
	C385.583984,869.642639 385.584961,850.151062 385.586426,830.659485 
	C385.586975,823.352844 384.861786,822.854126 376.732056,825.242310 
	C376.732056,842.983154 376.732056,860.931274 376.732056,880.253784 
	C374.782959,877.767700 373.705475,876.523132 372.769684,875.179932 
	C362.027985,859.760864 351.073334,844.481628 340.719086,828.806091 
	C337.443115,823.846497 333.789856,823.605286 328.967773,824.621277 
	C328.967773,848.106140 328.967773,871.297668 328.967773,894.410767 
	C331.872070,894.410767 334.267456,894.410767 337.260925,894.410767 
	C337.260925,876.001404 337.260925,858.014648 337.260925,838.607117 
	C339.261810,841.248779 340.392914,842.651306 341.427338,844.121704 
	C353.194305,860.847961 364.946533,877.584595 377.395844,894.682861 
M971.347107,832.175964 
	C967.994995,827.910400 963.581238,825.194336 958.235413,824.883728 
	C949.104492,824.352966 939.943787,824.318176 930.794189,824.143433 
	C929.044495,824.109924 927.288818,824.391479 925.626343,824.520630 
	C925.626343,848.200012 925.626343,871.277344 925.626343,894.477905 
	C928.382080,894.477905 930.767273,894.477905 933.670898,894.477905 
	C933.670898,884.529968 933.670898,874.943604 933.670898,864.717346 
	C939.292053,864.717346 944.413818,864.497559 949.495605,864.851318 
	C951.018677,864.957336 953.068970,866.328125 953.786133,867.693054 
	C957.574707,874.902893 961.059814,882.274414 964.560913,889.632385 
	C967.325806,895.443176 968.722961,896.055664 975.574890,893.810364 
	C973.219482,888.865540 970.901367,883.944458 968.535156,879.046631 
	C966.108398,874.023499 963.634583,869.023071 961.196411,864.043396 
	C975.041260,856.325317 978.692810,845.189026 971.347107,832.175964 
M507.434448,840.933350 
	C506.902496,838.531006 506.929169,835.802002 505.746582,833.780212 
	C498.805023,821.912415 479.487823,818.578918 467.663605,826.995605 
	C456.935211,834.632263 457.073792,849.072937 468.174011,856.231445 
	C471.767365,858.548767 475.908600,860.042480 479.862152,861.768799 
	C484.727509,863.893311 489.959229,865.355774 494.493530,868.021301 
	C501.987946,872.427063 501.794067,881.872681 494.536987,886.435181 
	C485.949677,891.834167 472.333771,889.185791 467.735748,880.244263 
	C464.796539,874.528503 461.011780,875.173889 455.047180,875.971985 
	C457.662994,880.389465 459.402588,884.561523 462.203033,887.822449 
	C470.253571,897.197083 488.997742,899.387207 500.127136,892.679749 
	C512.870178,884.999878 512.823975,868.294861 499.874817,861.028381 
	C495.993805,858.850586 491.624756,857.550354 487.504974,855.788086 
	C482.927643,853.830139 478.090088,852.275330 473.886932,849.695923 
	C467.639984,845.862183 467.908600,836.943481 473.963989,832.891296 
	C480.741302,828.355957 492.053986,829.511841 496.489685,836.227722 
	C499.268982,840.435669 502.263733,841.585938 507.434448,840.933350 
M274.024323,833.165649 
	C275.207916,834.326233 276.895508,835.275452 277.486511,836.684631 
	C279.859924,842.343445 284.124756,841.429871 288.879333,840.511963 
	C288.598511,839.308838 288.495819,838.328674 288.145203,837.447205 
	C282.769684,823.932556 265.813354,818.257690 251.297073,825.238708 
	C244.865463,828.331665 240.761780,833.292297 240.387924,840.740051 
	C240.012360,848.221924 243.600143,853.612488 249.888138,857.036316 
	C254.240402,859.406128 259.071228,860.880310 263.603516,862.940979 
	C268.105988,864.988098 272.969788,866.654541 276.832581,869.594849 
	C282.226593,873.700623 281.585480,881.426025 276.267548,885.560669 
	C267.844543,892.109497 253.011230,889.194641 247.751205,879.957031 
	C244.856842,874.874023 242.525299,874.128540 236.475403,876.507507 
	C239.345520,886.192444 245.373978,892.978210 255.273819,895.223694 
	C265.809357,897.613342 276.380157,897.498169 284.847473,889.374939 
	C293.143738,881.415894 291.565765,867.964905 281.806824,861.874695 
	C278.595245,859.870483 274.972961,858.495728 271.469208,856.994995 
	C265.974457,854.641479 260.033722,853.039246 255.051895,849.897705 
	C252.472900,848.271362 250.645309,844.169495 250.106491,840.916443 
	C249.431442,836.840759 252.350708,833.703247 256.109924,832.031433 
	C261.915955,829.449341 267.731598,829.468079 274.024323,833.165649 
M519.531677,877.299011 
	C522.035400,881.351135 523.882629,886.065735 527.171814,889.325073 
	C535.429749,897.508179 553.831360,898.819092 563.722412,892.639587 
	C575.815491,885.084412 575.996338,868.794678 563.813477,861.404053 
	C559.745544,858.936340 554.991577,857.603394 550.560852,855.728882 
	C546.276978,853.916443 541.778992,852.467102 537.779175,850.158081 
	C531.009277,846.249878 531.022949,836.963928 537.495972,832.708191 
	C544.441711,828.141663 556.049316,829.739807 560.296692,836.838440 
	C563.413635,842.047791 567.063904,841.358765 571.956116,840.509277 
	C571.170776,838.476685 570.676331,836.719482 569.850342,835.135132 
	C563.596191,823.138794 546.790527,818.648682 533.634338,825.484253 
	C527.479858,828.681946 523.463440,833.504517 523.084412,840.713074 
	C522.691345,848.187195 526.250854,853.621521 532.643066,856.924622 
	C537.785095,859.581665 543.353088,861.403015 548.669189,863.738281 
	C552.150940,865.267639 555.802490,866.649902 558.881531,868.806763 
	C565.188660,873.224854 564.858398,881.553467 558.531311,885.994141 
	C549.635620,892.237732 535.485413,888.949768 530.212402,879.414062 
	C527.761963,874.982666 524.714783,874.173828 519.531677,877.299011 
M401.991211,876.507690 
	C401.991211,882.445251 401.991211,888.382874 401.991211,894.799438 
	C415.973541,894.799438 429.427216,894.756104 442.880157,894.836365 
	C445.531769,894.852112 447.255096,894.668213 447.330688,891.231506 
	C447.410645,887.595642 445.702087,887.211975 442.750732,887.258972 
	C433.428131,887.407288 424.100555,887.434387 414.779022,887.271973 
	C413.297852,887.246216 410.593018,886.054993 410.560883,885.311584 
	C410.242554,877.942810 410.368164,870.554810 410.368164,862.855469 
	C419.745819,862.855469 428.216431,862.863647 436.687042,862.852844 
	C443.198242,862.844543 443.730621,862.063599 441.524750,855.128784 
	C431.425873,855.128784 421.160645,855.128784 410.287506,855.128784 
	C410.287506,847.868164 410.163788,841.086914 410.441101,834.322021 
	C410.476532,833.457642 412.573792,832.023315 413.764435,831.971619 
	C419.582214,831.719116 425.416473,831.843811 431.244904,831.839600 
	C436.150146,831.836121 441.055420,831.838806 445.832001,831.838806 
	C446.758240,824.124878 446.758240,824.122009 440.274384,824.127258 
	C429.116974,824.136353 417.959534,824.134705 406.802246,824.177979 
	C405.226318,824.184082 403.651215,824.417725 401.991272,824.552979 
	C401.991272,841.920959 401.991272,858.722168 401.991211,876.507690 
M224.710999,862.478271 
	C224.710999,849.883606 224.710999,837.289001 224.710999,824.825500 
	C216.222855,823.231262 216.090347,823.338745 216.108231,831.009521 
	C216.122589,837.168884 216.189667,843.328857 216.121460,849.487366 
	C216.036697,857.139282 216.280518,864.831116 215.573700,872.430542 
	C214.699646,881.828247 209.076797,887.582397 200.778793,888.831238 
	C187.639420,890.808594 178.287064,882.879944 177.965927,868.991882 
	C177.669693,856.180298 177.879929,843.357117 177.864334,830.539001 
	C177.855728,823.464722 176.735703,822.723999 169.532822,825.084778 
	C169.532822,840.524780 169.105286,856.003479 169.666214,871.446350 
	C170.126373,884.115295 176.504456,892.746277 186.912292,894.873779 
	C192.948441,896.107727 199.520187,896.174927 205.627167,895.265198 
	C214.592773,893.929565 220.573898,887.995178 222.833069,879.231567 
	C224.142273,874.152954 224.129272,868.733459 224.710999,862.478271 
M720.315430,894.845703 
	C722.251465,894.756958 724.187561,894.668213 726.224060,894.574890 
	C726.224060,891.981323 726.224060,889.908875 726.224060,887.592834 
	C724.817383,887.487427 723.691711,887.333069 722.565430,887.329468 
	C713.239502,887.299561 703.909485,887.134766 694.589478,887.366821 
	C690.832764,887.460388 689.557068,886.179260 689.709473,882.522888 
	C689.924316,877.368591 689.938660,872.192871 689.729675,867.038757 
	C689.594116,863.693237 690.883484,862.731445 694.101868,862.815186 
	C701.424683,863.005920 708.756104,862.868591 716.083984,862.861816 
	C722.677246,862.855713 723.179443,862.158630 721.148560,855.300476 
	C712.294189,855.300476 703.316589,855.157715 694.347534,855.371338 
	C690.792969,855.455933 689.638977,854.058411 689.717529,850.749268 
	C689.836121,845.756165 689.895508,840.752869 689.705933,835.764343 
	C689.586853,832.633057 690.863037,831.714050 693.823364,831.785889 
	C700.148438,831.939453 706.479797,831.835938 712.808533,831.836914 
	C717.049866,831.837646 721.291260,831.837036 725.420410,831.837036 
	C726.107483,824.102844 726.107483,824.111633 719.680847,824.121216 
	C708.688782,824.137573 697.696655,824.125671 686.704712,824.167603 
	C684.964111,824.174255 683.224304,824.400940 681.647766,824.514160 
	C681.647766,848.248413 681.647766,871.322876 681.647766,894.845276 
	C694.445923,894.845276 706.902100,894.845276 720.315430,894.845703 
M875.336853,844.498413 
	C875.336853,840.423828 875.336853,836.349243 875.336853,831.783447 
	C887.775146,831.783447 899.357605,831.783447 910.835327,831.783447 
	C912.153748,824.289307 912.062012,824.156921 905.260681,824.150757 
	C894.099854,824.140686 882.935791,824.308289 871.779907,824.074829 
	C867.990662,823.995483 866.973877,825.192017 866.997925,828.898743 
	C867.132141,849.553711 867.009460,870.210266 867.015259,890.866272 
	C867.015625,892.113342 867.365906,893.360352 867.530151,894.452759 
	C882.542664,894.452759 897.113647,894.452759 911.632019,894.452759 
	C912.427612,887.259827 912.427551,887.238159 905.975220,887.255615 
	C897.314270,887.278992 888.652039,887.265625 879.993225,887.423401 
	C876.789429,887.481812 875.038635,886.841736 875.286133,883.009583 
	C875.618347,877.867737 875.574646,872.673340 875.262939,867.527466 
	C875.028259,863.652222 876.555908,862.708374 880.167175,862.794678 
	C889.105713,863.008423 898.052795,862.864807 907.259033,862.864807 
	C907.259033,860.078735 907.259033,857.884155 907.259033,855.065674 
	C896.492554,855.065674 886.101624,855.065674 875.336731,855.065674 
	C875.336731,851.459351 875.336731,848.476929 875.336853,844.498413 
M628.320374,885.430908 
	C626.178345,883.156494 623.441345,881.206055 621.996216,878.552185 
	C615.401794,866.441650 615.401917,853.726379 621.222534,841.563782 
	C627.844543,827.726562 648.992004,826.097778 656.884460,838.721802 
	C660.071472,843.819397 663.596008,843.419128 668.546021,842.624146 
	C667.923035,840.904297 667.565491,839.479187 666.919617,838.199646 
	C657.708496,819.953308 630.556335,816.677307 617.403442,832.419312 
	C608.488098,843.089600 607.604065,855.919434 609.649170,868.894409 
	C612.852966,889.218994 627.478333,899.283447 647.759338,895.815308 
	C658.279907,894.016235 667.149780,885.473450 668.258972,874.849854 
	C664.920654,875.839355 660.715576,875.772583 659.380859,877.696838 
	C651.359253,889.262024 641.159851,892.266174 628.320374,885.430908 
M832.500000,824.138916 
	C823.723938,824.138916 814.947815,824.138916 805.856995,824.138916 
	C805.856995,826.923401 805.856995,829.141602 805.856995,831.934021 
	C813.243042,831.934021 820.304749,831.934021 827.818359,831.934021 
	C827.818359,853.050110 827.818359,873.599487 827.818359,894.418152 
	C830.805176,894.418152 833.315857,894.418152 836.125488,894.418152 
	C836.125488,893.035767 836.124512,892.064392 836.125671,891.093018 
	C836.147583,872.951721 836.205566,854.810364 836.156372,836.669312 
	C836.148193,833.663574 836.314880,831.478088 840.321777,831.791626 
	C844.290833,832.102173 848.305054,831.828979 852.299438,831.825256 
	C859.113159,831.818909 859.195007,831.718689 857.689880,824.141052 
	C849.719604,824.141052 841.609802,824.141052 832.500000,824.138916 
M312.487122,869.500122 
	C312.487122,854.587036 312.487122,839.673950 312.487122,824.878723 
	C304.236603,823.292786 304.080658,823.430908 304.085480,831.102600 
	C304.097504,850.223389 304.097778,869.344238 304.096558,888.465027 
	C304.096100,895.567505 305.148010,896.269409 312.486633,893.830017 
	C312.486633,886.096741 312.486633,878.298401 312.487122,869.500122 
z"/>
<path fill="#060B10" opacity="1.000000" stroke="none" 
	d="
M622.249878,131.424683 
	C620.555298,135.725510 618.860779,140.026337 616.686890,144.629272 
	C615.841614,144.989670 615.475586,145.047958 615.073730,145.072235 
	C615.037903,145.038223 614.963745,144.973038 614.967529,144.578827 
	C615.117554,143.204010 615.070557,142.143036 615.439819,141.255234 
	C619.126831,132.390747 622.962891,123.587654 626.589233,114.698830 
	C628.872437,109.102295 630.870544,103.389442 632.996582,97.728767 
	C632.606384,97.417084 632.216125,97.105392 631.825928,96.793701 
	C630.916077,97.376427 629.564148,97.746277 629.165039,98.574974 
	C625.402649,106.387642 621.558289,114.176651 618.224182,122.175316 
	C614.815369,130.353333 612.149170,138.839203 608.801453,147.044861 
	C604.670288,157.170547 600.259338,167.185989 595.750732,177.150146 
	C594.929382,178.965317 593.060120,180.306305 591.674683,181.866211 
	C590.368591,180.556152 589.165710,179.118118 587.733765,177.964203 
	C585.197144,175.920029 582.776794,173.440231 579.857788,172.214310 
	C569.499146,167.864059 569.651184,168.889023 572.597412,158.735703 
	C572.650818,158.551468 572.234558,158.230942 572.045532,157.666626 
	C572.058411,156.948914 572.063232,156.538635 572.415039,156.045044 
	C574.140076,153.307022 575.518066,150.652298 576.917236,147.662720 
	C576.934692,146.880676 576.930969,146.433472 576.952332,145.740356 
	C576.977417,145.494461 576.988831,145.000229 577.007996,144.767227 
	C577.027161,144.534225 577.030212,144.066666 577.370361,143.992706 
	C578.142761,142.944992 578.575073,141.971268 579.023804,140.768372 
	C579.040161,140.539215 579.037781,140.079773 579.375000,139.998764 
	C580.439697,138.278015 581.167236,136.638260 581.927124,134.604706 
	C582.015076,133.158630 582.070679,132.106384 582.462646,130.984161 
	C584.504395,127.606583 586.209717,124.298973 587.954590,120.614716 
	C588.345886,118.526192 588.697693,116.814316 589.370361,115.024933 
	C590.783936,114.285034 591.876709,113.622665 592.978333,113.366409 
	C592.549622,115.703712 592.362061,117.735161 591.634277,119.550003 
	C586.492676,132.371597 581.204407,145.134415 576.066345,157.957413 
	C575.395630,159.631393 575.314819,161.541733 574.875244,163.777390 
	C584.054993,160.343124 587.003906,170.937103 595.173462,170.669601 
	C597.075195,166.315399 599.202454,161.580353 601.214294,156.796768 
	C602.916077,152.750488 604.386719,148.604996 606.157593,144.590744 
	C606.901245,142.905045 608.468201,141.564178 609.120178,139.859726 
	C611.288940,134.189911 613.080444,128.374893 615.282898,122.719215 
	C616.471252,119.667496 619.742371,116.582443 619.324585,113.948586 
	C618.705566,110.047050 620.767822,108.127716 622.250122,105.510040 
	C623.364319,103.542328 624.182556,101.401329 625.358643,99.475571 
	C627.576904,95.843208 629.536255,91.919235 632.387878,88.844101 
	C635.352356,85.647255 639.536865,86.686119 640.950256,90.849892 
	C642.422363,95.186516 643.859131,99.819061 643.869629,104.325584 
	C644.045410,179.817154 644.001770,255.309265 643.963379,330.801208 
	C643.962219,333.096893 643.075684,335.445953 643.363098,337.671814 
	C643.618042,339.646790 644.723572,342.983185 645.815247,343.136047 
	C652.108521,344.017120 658.507324,344.271179 664.875366,344.353851 
	C665.829712,344.366241 667.462708,342.466309 667.661316,341.265656 
	C668.368896,336.987518 668.944824,332.629700 668.948120,328.301544 
	C669.016846,237.977844 669.013428,147.654068 668.958069,57.330379 
	C668.955444,53.040249 668.017456,48.744598 668.094971,44.462017 
	C668.199158,38.710068 667.089966,37.067001 661.423950,37.021519 
	C650.759094,36.935905 640.092651,36.951523 629.428101,37.055408 
	C628.045044,37.068878 625.973755,37.433899 625.414795,38.358021 
	C623.345825,41.778599 621.417725,45.376038 620.101074,49.139442 
	C617.887817,55.465942 616.615845,62.141567 614.156677,68.357323 
	C610.911926,76.558983 607.009033,84.509926 603.142029,92.448303 
	C602.281677,94.214462 600.391785,95.479118 598.973389,96.644875 
	C598.950745,95.875305 598.930664,95.435562 598.938171,94.605652 
	C598.991699,93.172859 599.017639,92.130226 599.385742,91.004112 
	C601.146667,87.612984 602.565369,84.305328 604.263611,80.756523 
	C604.728149,79.050728 604.913147,77.586082 605.443970,76.029922 
	C606.400024,75.185471 607.220703,74.522858 607.588562,73.665886 
	C612.326355,62.627880 617.145447,51.620686 621.618042,40.474926 
	C623.430298,35.958866 626.357117,33.968399 631.073486,33.992992 
	C641.572266,34.047752 652.071655,33.977055 662.570557,34.012772 
	C669.338196,34.035797 671.017273,35.789913 671.017334,42.637547 
	C671.018188,140.128983 671.017334,237.620438 671.016541,335.111877 
	C671.016479,337.445007 671.022766,339.778168 671.008118,342.111237 
	C670.987549,345.395325 669.292236,346.992676 666.039062,346.992218 
	C660.206299,346.991425 654.363525,347.202393 648.543335,346.930908 
	C641.577515,346.605988 640.987854,345.789246 640.987732,338.884918 
	C640.986267,261.225067 640.999817,183.565216 640.935486,105.905434 
	C640.932861,102.735481 640.199707,99.566132 639.806885,96.396500 
	C639.132996,96.360626 638.459106,96.324745 637.785217,96.288872 
	C637.517090,98.494301 637.015625,100.699608 637.014404,102.905190 
	C636.971680,181.564926 636.976440,260.224670 636.969849,338.884430 
	C636.969666,341.383392 637.414246,344.015594 636.785400,346.352661 
	C635.596558,350.771484 637.824585,351.073700 641.191162,351.046082 
	C652.314148,350.954742 663.438416,351.011627 675.048523,351.011627 
	C675.048523,349.547668 675.048584,348.088348 675.048584,346.629028 
	C675.045898,244.304688 675.042969,141.980347 675.042786,39.656010 
	C675.042786,37.990074 674.680542,36.195999 675.169922,34.682426 
	C676.589783,30.291470 674.221375,29.960133 670.859680,29.980690 
	C657.861328,30.060183 644.837952,30.495249 631.872437,29.839729 
	C624.213806,29.452522 620.157166,31.221714 617.594788,39.175392 
	C613.651917,51.414135 607.955139,63.087818 602.855225,75.327484 
	C601.429871,79.428238 600.151672,83.202751 598.671753,87.092926 
	C598.225769,87.419006 598.065796,87.680962 597.838379,88.313950 
	C595.119385,95.414803 592.552063,102.196152 589.668457,109.030807 
	C588.905212,109.722443 588.458374,110.360794 587.938232,111.351547 
	C586.890381,114.804436 585.915833,117.904907 584.799194,121.317215 
	C583.106750,124.753258 581.556335,127.877457 579.733887,131.231720 
	C574.598694,142.956741 569.996643,154.576019 564.638062,165.835205 
	C563.641357,167.929413 559.601746,168.575424 556.992310,169.536224 
	C557.148621,168.506561 557.279114,167.830475 557.409546,167.154388 
	C563.907959,151.728607 570.464417,136.326996 576.891846,120.871727 
	C587.986816,94.192993 598.919922,67.446480 610.150940,40.825420 
	C611.594116,37.404568 614.409729,34.562717 617.182800,31.204550 
	C618.149170,30.246502 618.522278,29.536560 618.895386,28.826618 
	C619.222595,28.434313 619.549805,28.042007 620.521545,27.777412 
	C622.415405,27.718100 623.664795,27.531075 624.914124,27.344051 
	C625.711731,27.283482 626.509399,27.222914 628.016602,27.501150 
	C644.350891,27.763712 659.975708,27.687471 675.600464,27.611237 
	C676.065979,27.808216 676.531433,28.005190 676.993652,28.934212 
	C676.986267,135.139038 676.979248,240.611816 677.005249,346.084595 
	C677.005615,347.693024 677.415710,349.301331 677.634521,350.909698 
	C677.554504,351.496857 677.474487,352.084045 676.662476,352.773743 
	C662.312866,352.909790 648.695312,352.943237 635.077759,352.976685 
	C635.077759,352.976685 635.017212,353.002930 635.030518,352.619354 
	C634.128174,350.803864 633.212463,349.371948 632.296753,347.940033 
	C632.296753,347.940033 631.937988,347.704865 631.933167,347.168823 
	C631.373840,346.056854 630.819397,345.480865 630.264893,344.904907 
	C629.118408,343.330566 627.971985,341.756195 626.841919,339.565369 
	C626.473877,338.269043 626.089417,337.589172 625.704956,336.909302 
	C625.596558,334.426880 625.393616,331.944458 625.393494,329.462036 
	C625.390320,262.882935 625.407898,196.303833 625.731689,128.980682 
	C626.041382,126.810684 626.041382,125.384727 626.041382,123.958778 
	C625.618469,123.860611 625.195496,123.762444 624.772583,123.664276 
	C623.931641,126.251076 623.090759,128.837875 622.249878,131.424683 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M395.462646,134.351669 
	C394.322998,131.796341 393.183350,129.241013 392.013733,126.024994 
	C391.501617,124.816658 391.019440,124.269035 389.916626,123.016502 
	C390.033356,125.211952 390.093262,126.338661 390.153137,127.465363 
	C390.283813,131.949493 390.526703,136.433563 390.528290,140.917740 
	C390.550812,204.067963 390.483948,267.218353 390.638092,330.368164 
	C390.651398,335.821259 388.734802,340.083313 386.376099,344.793457 
	C382.484283,352.565216 377.025085,355.006622 368.647614,354.145782 
	C361.227997,353.383392 353.667969,353.985077 346.169922,353.989197 
	C338.600555,353.993347 338.578125,353.995850 338.575806,346.613586 
	C338.554382,279.298035 338.538025,211.982513 338.527344,144.666977 
	C338.521515,108.009911 338.518555,71.352837 338.540192,34.695782 
	C338.544647,27.174751 338.689850,27.117437 346.004211,27.126635 
	C361.166870,27.145700 376.330475,27.206564 391.491638,27.051580 
	C394.839172,27.017361 396.841980,28.306862 399.084229,30.724867 
	C405.761932,37.925922 408.606873,47.030357 412.293121,55.707138 
	C425.039185,85.709206 437.648895,115.769218 450.194519,146.574371 
	C450.071350,150.066879 448.702118,151.267456 445.863678,150.655396 
	C437.634735,131.509903 429.471313,112.721741 421.483124,93.859360 
	C415.559265,79.871490 409.898651,65.772202 404.000061,51.773418 
	C403.510101,50.610626 402.096985,49.836823 401.105164,48.469406 
	C399.401123,42.723633 397.874725,37.325264 395.866882,32.112312 
	C395.458557,31.052114 393.242676,30.108530 391.850861,30.095549 
	C376.354309,29.950987 360.855652,30.049549 345.358337,29.957085 
	C342.074097,29.937492 340.934174,31.308029 341.007812,34.463913 
	C341.142181,40.224270 340.989563,45.990677 341.062714,51.753216 
	C341.333038,73.050064 341.753357,94.345688 341.888062,115.643211 
	C341.937378,123.439110 341.120148,131.238907 341.115356,139.037186 
	C341.110779,146.501678 341.870697,153.965500 341.905273,161.431412 
	C342.016052,185.359085 342.049713,209.288208 341.907288,233.215546 
	C341.823151,247.347290 341.177765,261.475677 341.090790,275.607452 
	C340.946136,299.103394 341.098480,322.601074 340.992096,346.097412 
	C340.975616,349.729431 342.136475,351.151337 345.900635,351.071350 
	C355.395111,350.869476 364.901306,350.822388 374.393036,351.077515 
	C378.198334,351.179779 379.122620,349.988800 379.117523,346.258301 
	C379.009735,267.269135 379.045471,188.279785 378.995728,109.290489 
	C378.994415,107.190247 378.352295,105.090416 378.011047,102.591293 
	C378.153687,100.282158 378.294312,98.372116 378.434906,96.462082 
	C377.975250,96.296349 377.515564,96.130623 377.055908,95.964897 
	C376.370941,97.609032 375.161469,99.232292 375.095062,100.901047 
	C374.853333,106.974556 374.990723,113.063293 374.990723,119.146286 
	C374.990784,192.141281 375.002228,265.136292 374.950562,338.131256 
	C374.948700,340.750519 374.327118,343.369324 373.997192,345.994171 
	C374.000000,346.000000 373.988373,345.993652 373.613525,345.992493 
	C372.488312,345.983459 371.738007,345.975616 371.034729,345.600464 
	C371.389893,341.608307 371.964020,337.983673 371.966705,334.358643 
	C372.019196,263.285431 372.005188,192.212158 372.005005,121.138908 
	C372.004974,112.902580 371.914307,104.664886 372.036407,96.430367 
	C372.130737,90.070312 379.187073,85.018066 384.464111,87.354652 
	C388.073181,88.952705 393.054901,100.652290 391.717834,104.823753 
	C390.656830,104.087715 389.853851,103.523819 389.001709,102.616638 
	C387.641815,100.501770 386.331116,98.730186 385.016449,96.576004 
	C384.562897,94.862068 384.113281,93.530731 383.663635,92.199394 
	C383.024200,92.463455 382.384766,92.727509 381.745300,92.991570 
	C382.462646,94.327850 383.179993,95.664139 383.913940,97.410660 
	C384.083099,98.784691 384.086212,99.802567 384.412323,100.703545 
	C386.610779,106.777649 388.877686,112.826973 391.119568,119.212067 
	C391.721008,120.035957 392.325287,120.532089 392.997864,121.405121 
	C393.942963,123.818192 395.181763,125.784317 395.593140,127.910538 
	C395.992004,129.971985 395.540649,132.197952 395.462646,134.351669 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M588.049988,215.100067 
	C587.497620,216.235062 586.945312,217.370071 585.912781,218.902084 
	C585.302673,219.893005 585.172791,220.486908 585.042908,221.080811 
	C584.655640,222.172745 584.268372,223.264679 583.361755,224.752808 
	C581.997314,227.621429 581.152161,230.093826 580.307068,232.566223 
	C580.124451,233.186157 579.941895,233.806091 579.261841,234.794800 
	C578.587219,235.925034 578.410034,236.686508 578.232788,237.447983 
	C569.639893,258.332794 561.064270,279.224701 552.448547,300.100067 
	C545.787659,316.238983 538.959106,332.310211 532.472168,348.518372 
	C530.887451,352.477844 528.884521,354.264709 524.436462,354.119293 
	C514.788330,353.803925 505.096771,353.583923 495.474579,354.170624 
	C489.565369,354.530975 487.366638,351.712128 485.392029,346.980560 
	C474.508514,320.901459 463.438721,294.899994 452.401459,268.885193 
	C433.575531,224.512466 414.726440,180.149612 396.395691,135.587860 
	C398.130371,136.666245 399.861603,137.725159 400.505951,139.244888 
	C408.843506,158.910248 416.997772,178.653214 425.279846,198.342285 
	C426.498535,201.239487 428.138275,203.959610 429.583191,206.761658 
	C430.034027,206.620575 430.484894,206.479477 430.935730,206.338379 
	C430.935730,201.916321 430.935730,197.494263 431.089874,192.767807 
	C431.815857,191.289612 432.387695,190.115814 432.971527,188.574738 
	C432.993561,187.470642 433.003662,186.733856 433.006287,185.997971 
	C432.998779,185.998886 432.998230,186.013855 433.339233,185.980743 
	C435.433777,184.238403 437.187347,182.529144 439.276031,180.767578 
	C444.320190,175.789444 449.029266,170.863632 454.144806,165.952240 
	C455.364685,165.979263 456.178070,165.991882 456.876404,166.270172 
	C456.503510,167.051636 456.245636,167.567398 455.981323,168.270874 
	C455.974823,168.458572 455.955658,168.833694 455.625122,168.924133 
	C448.646179,176.739334 441.964142,184.435760 435.394135,192.226654 
	C434.619629,193.145096 434.116180,194.570511 434.076050,195.780457 
	C433.922516,200.410645 433.804260,205.064026 434.145691,209.673721 
	C434.235229,210.882553 436.029114,211.965164 437.026245,213.420807 
	C437.008545,214.159073 437.004883,214.582275 436.997192,215.259750 
	C436.988800,215.683472 436.984406,215.852936 436.619354,216.027100 
	C432.147736,216.691666 434.934113,219.345444 435.391632,220.457443 
	C440.805908,233.616806 446.479492,246.669235 452.054108,259.762909 
	C459.358978,276.920685 466.629669,294.092987 473.927673,311.253632 
	C478.912231,322.974487 484.141998,334.598938 488.767517,346.459381 
	C490.024658,349.682861 491.369965,351.045380 494.702240,351.014618 
	C504.681488,350.922424 514.665283,350.845062 524.640442,351.068146 
	C527.772705,351.138214 528.891235,349.827667 529.893921,347.167023 
	C532.225830,340.979248 535.154480,335.013336 537.414551,328.802185 
	C540.168457,321.233826 542.484802,313.506226 544.991577,305.661896 
	C544.992737,305.476318 544.991028,305.105194 545.352661,305.040771 
	C546.511108,304.028046 547.597717,303.203094 548.061523,302.113098 
	C556.598816,282.048462 565.140625,261.984955 573.501770,241.846542 
	C576.903259,233.653946 579.877869,225.284119 583.398804,216.929413 
	C584.168884,215.597519 584.586914,214.331131 585.350708,212.860565 
	C586.113037,211.959473 586.878906,211.268646 586.891174,210.564682 
	C586.995728,204.571426 587.128662,198.566681 586.800720,192.590225 
	C586.749207,191.650848 584.425110,190.079178 583.147156,190.074509 
	C549.005127,189.949570 514.862366,189.983078 480.719849,190.041260 
	C479.507721,190.043320 478.296539,190.570251 477.084900,190.852554 
	C477.128174,191.396713 477.171448,191.940887 477.214722,192.485046 
	C478.883392,192.656876 480.551575,192.972137 482.220825,192.977768 
	C495.211517,193.021667 508.202454,193.008163 521.193298,193.008118 
	C539.513733,193.008057 557.834229,192.995819 576.154663,193.006912 
	C582.227539,193.010590 582.969482,193.793640 583.001953,199.996048 
	C583.005493,200.662231 583.005371,201.328461 583.001099,201.994644 
	C582.961487,208.197205 582.213745,208.996674 576.160767,209.005569 
	C562.743774,209.025284 549.326843,209.007614 535.909851,209.007385 
	C520.420776,209.007111 504.931671,209.009705 489.442566,209.010147 
	C474.120026,209.010574 458.796600,209.087433 443.476227,208.905334 
	C441.685699,208.884064 438.489624,207.611069 438.362823,206.611633 
	C437.566315,200.333191 437.530884,194.118530 442.266296,188.809814 
	C448.100708,182.269012 453.515747,175.355850 459.185547,168.665955 
	C465.065643,161.727921 471.033691,154.864410 477.215210,147.975922 
	C477.635101,147.987411 477.803070,147.991272 477.940308,148.344452 
	C473.605347,154.122772 469.322144,159.568527 464.992279,164.976974 
	C457.668091,174.125717 450.577362,183.481949 442.848724,192.276611 
	C439.578430,195.997986 439.232880,200.108292 440.506409,203.731186 
	C441.140198,205.534073 445.679169,206.802765 448.475037,206.847626 
	C456.425751,206.975204 464.387512,206.129044 472.346924,206.091354 
	C478.470276,206.062378 484.595642,206.937851 490.721863,206.961227 
	C517.887756,207.064835 545.054321,207.006836 572.220642,207.003906 
	C573.553345,207.003769 574.942627,207.209824 576.208008,206.909271 
	C579.852234,206.043762 581.129944,203.540207 581.063660,199.877289 
	C580.998169,196.266403 578.861938,195.930984 576.085999,195.958389 
	C570.169434,196.016846 564.251892,195.978760 558.334778,195.978836 
	C538.168396,195.979065 518.001953,195.978745 497.835541,195.978592 
	C491.253143,195.978531 484.670715,195.969971 478.088318,195.980774 
	C472.840546,195.989380 468.111511,191.214432 469.188843,186.376221 
	C470.710327,179.543243 475.007874,174.559372 481.013184,171.277206 
	C481.035065,171.706207 481.044952,171.877838 481.040894,172.446426 
	C480.335236,174.289429 479.825287,175.864182 478.912140,177.153351 
	C477.278412,179.459885 475.401733,181.594360 473.626617,183.800766 
	C474.174896,184.260941 474.723175,184.721100 475.271454,185.181274 
	C477.182770,183.121902 479.094086,181.062531 481.306519,179.132660 
	C482.192078,179.571457 482.776520,179.880737 483.302673,180.552109 
	C482.573792,182.371857 481.903198,183.829514 480.996979,185.799362 
	C483.417877,186.160889 485.486847,186.734894 487.556549,186.737625 
	C506.735626,186.762863 525.914978,186.716415 545.093384,186.566940 
	C546.067017,186.559357 547.031860,185.417404 548.368042,184.867325 
	C549.468994,185.058060 550.202759,185.185089 551.105713,185.667130 
	C558.842590,186.230865 566.410217,186.439590 574.373535,186.757370 
	C575.852661,186.779617 576.936096,186.692795 578.397339,186.709900 
	C581.157349,186.207520 583.539490,185.601196 586.294922,185.038132 
	C587.800720,186.722244 588.933105,188.363083 590.045532,190.415436 
	C590.025513,193.359253 590.025513,195.891571 590.025513,198.423874 
	C590.626099,198.558319 591.226624,198.692764 591.827209,198.827209 
	C594.253540,191.568710 596.679932,184.310196 599.416260,177.044968 
	C600.139404,177.023880 600.552612,177.009521 601.243042,177.005844 
	C601.705200,177.025467 601.890015,177.034424 602.045776,177.429230 
	C601.730835,179.311676 601.444885,180.808319 601.158936,182.304962 
	C600.657227,183.647552 600.155518,184.990143 599.150635,186.752975 
	C596.808838,191.866486 594.970154,196.559769 593.131470,201.253052 
	C592.693909,202.619217 592.256287,203.985397 591.310486,205.736176 
	C590.441956,207.724350 590.081604,209.327927 589.721252,210.931503 
	C589.706726,211.391006 589.692261,211.850494 589.246826,212.710297 
	C588.560669,213.773758 588.305359,214.436920 588.049988,215.100067 
z"/>
<path fill="#6D48B3" opacity="1.000000" stroke="none" 
	d="
M350.837769,719.013672 
	C350.872742,718.554565 350.907715,718.095520 350.990112,716.845581 
	C351.037506,691.181030 351.037506,666.307312 351.037506,641.124084 
	C362.350067,641.124084 372.663025,641.124084 382.975983,641.124084 
	C383.353394,641.182251 383.730804,641.240417 384.692322,641.447754 
	C386.509155,641.699036 387.741943,641.801147 388.974701,641.903320 
	C392.355682,643.018433 395.736633,644.133545 399.562225,645.542969 
	C400.231140,646.030762 400.490967,646.115967 400.893311,646.362793 
	C401.569397,647.138611 402.138489,647.644409 402.745972,648.492920 
	C403.433746,649.991638 404.083130,651.147827 404.758392,652.499695 
	C404.784302,652.695435 404.980133,653.038269 404.966675,653.459473 
	C405.279907,658.244751 405.606628,662.608887 405.933350,666.973022 
	C405.529449,667.952209 405.125519,668.931396 404.391296,670.330566 
	C403.909576,671.203918 403.758179,671.657288 403.606750,672.110718 
	C403.171082,672.555237 402.735352,672.999756 401.864899,673.803223 
	C401.141693,674.655151 400.853210,675.148132 400.564758,675.641113 
	C400.564758,675.641113 400.208527,675.748230 399.813232,675.900269 
	C399.238495,676.327087 399.059052,676.601868 398.879578,676.876709 
	C397.518494,677.706055 396.157410,678.535339 394.026428,679.833740 
	C395.927338,680.583374 396.950012,680.912598 397.907623,681.376221 
	C405.095490,684.855713 409.208862,690.216675 409.216919,698.582153 
	C409.225098,707.088135 406.866638,714.105469 398.455902,717.572021 
	C394.964813,719.010925 391.169403,720.418213 387.474304,720.525330 
	C375.527313,720.871704 363.562683,720.608032 351.338257,720.030029 
	C350.993683,719.325012 350.915710,719.169373 350.837769,719.013672 
M393.725800,709.529663 
	C399.116882,705.989380 400.918671,700.998230 399.445038,694.838806 
	C398.010834,688.844116 393.476990,685.910828 387.926361,685.354553 
	C379.843323,684.544434 371.672516,684.511780 363.536774,684.449341 
	C362.504517,684.441406 360.582184,686.230408 360.558136,687.222900 
	C360.376770,694.701782 360.472534,702.193298 360.764282,709.669861 
	C360.800049,710.586731 362.532623,712.228149 363.435944,712.199097 
	C373.339813,711.880371 383.445862,713.901184 393.725800,709.529663 
M393.277161,670.740051 
	C399.740967,663.105347 397.527649,653.339783 387.975922,651.437073 
	C379.220428,649.693054 370.034668,650.109192 360.468201,649.539551 
	C360.468201,658.233276 360.334473,665.689514 360.624084,673.129333 
	C360.663818,674.150391 362.767639,675.868530 363.985779,675.932556 
	C369.445068,676.219421 375.019806,676.621765 380.376465,675.813477 
	C384.625061,675.172485 388.617706,672.835266 393.277161,670.740051 
z"/>
<path fill="#6D48B3" opacity="1.000000" stroke="none" 
	d="
M459.341095,664.638733 
	C459.341095,664.638733 459.324402,664.251099 459.273315,663.834167 
	C459.222229,663.417236 458.953369,663.021179 458.953369,663.021179 
	C458.695923,661.268738 458.438477,659.516296 458.319153,657.106140 
	C458.631775,655.291931 458.806335,654.135437 459.119263,652.798340 
	C459.257690,652.617676 459.284363,652.163330 459.405579,652.020142 
	C459.526794,651.876892 459.612427,651.511536 459.869293,651.332642 
	C460.888855,649.881470 461.651550,648.609253 462.637695,647.189697 
	C463.254761,646.680664 463.648376,646.318848 464.286102,645.923035 
	C464.828033,645.697205 464.995880,645.425171 465.032562,645.051636 
	C465.031372,645.030334 465.072388,645.041687 465.333435,645.006836 
	C465.854340,644.746521 466.114136,644.521118 466.721283,644.179199 
	C477.931641,639.157471 493.759644,640.035400 497.906555,655.001404 
	C497.835815,657.201904 497.765015,659.402405 497.517822,662.197632 
	C497.277985,663.184326 497.214569,663.576355 497.151123,663.968323 
	C491.917450,669.360352 486.683777,674.752380 480.721832,680.894775 
	C486.067444,686.533630 492.226593,693.030640 498.385712,699.527588 
	C498.969055,699.450623 499.552368,699.373657 500.135712,699.296692 
	C501.529419,693.670532 502.923126,688.044312 504.384247,682.146057 
	C506.796783,682.146057 509.399994,682.146057 513.579163,682.146057 
	C511.878571,688.787231 511.050018,695.247925 508.509827,700.945618 
	C506.390045,705.700378 506.793549,708.340332 510.889130,711.311218 
	C514.311035,713.793457 517.183899,717.032471 520.502686,720.125305 
	C511.884460,722.877686 505.615082,720.041199 500.793213,712.850464 
	C491.595734,721.006042 481.240814,723.589783 469.810669,722.658752 
	C457.557617,721.660706 448.604828,713.363586 447.822418,702.024719 
	C447.237732,693.551331 451.336487,687.349792 457.767273,682.440063 
	C460.391541,680.436462 463.227875,678.710632 466.287079,676.642578 
	C465.299225,675.142761 464.411377,673.794800 463.293457,671.911926 
	C462.806763,671.142212 462.550140,670.907410 462.293518,670.672607 
	C461.634247,669.295532 460.974945,667.918518 460.159851,665.991333 
	C459.783051,665.173767 459.562073,664.906250 459.341095,664.638733 
M482.294434,714.119080 
	C486.289032,712.084717 490.283600,710.050354 495.708313,707.287659 
	C486.440063,699.348999 478.507629,692.554626 470.575226,685.760193 
	C470.900116,684.977478 471.225037,684.194763 471.549927,683.412048 
	C467.879669,686.410828 463.794495,689.029236 460.624603,692.486694 
	C453.799988,699.930481 456.955383,711.294006 466.769440,713.839966 
	C471.403503,715.042114 476.561188,714.225769 482.294434,714.119080 
M471.677521,650.927612 
	C465.979889,655.801819 466.934204,667.724731 473.417023,671.606384 
	C474.092407,672.010803 475.504120,671.986267 476.131042,671.539124 
	C479.875183,668.869690 483.968597,666.455872 487.022369,663.113525 
	C490.619232,659.176697 489.172424,652.290588 484.190063,650.593933 
	C480.664154,649.393250 476.348785,650.510864 471.677521,650.927612 
z"/>
<path fill="#EDF5FA" opacity="1.000000" stroke="none" 
	d="
M947.000000,635.002441 
	C951.407959,637.008728 954.136169,640.342163 953.920715,645.262573 
	C953.752747,649.098633 952.863770,652.903198 952.314636,656.591858 
	C951.206055,656.591858 950.697815,656.726807 950.584900,656.572449 
	C946.116699,650.466003 939.651001,651.044067 933.133118,650.869690 
	C923.068420,650.600342 913.028687,649.398682 902.963989,649.127380 
	C898.943848,649.019043 894.864990,650.992615 890.848328,650.868958 
	C881.003418,650.566040 871.186768,649.439453 861.343506,648.951904 
	C855.777649,648.676208 850.177246,648.907410 844.602783,649.148682 
	C843.436951,649.199036 841.476562,650.122192 841.307373,650.938477 
	C839.915466,657.655701 834.142944,654.349426 830.500366,655.746338 
	C829.970886,655.949463 828.280579,654.668335 828.039246,653.815979 
	C826.922913,649.872131 825.765625,645.874878 825.342896,641.821716 
	C825.047607,638.990662 825.915283,636.038208 827.054382,633.070007 
	C831.743469,633.795349 834.326965,631.911865 836.582947,628.981445 
	C837.727295,627.494934 839.235291,625.370789 840.730225,625.237976 
	C845.928162,624.776367 851.192261,625.059631 854.782837,625.059631 
	C857.905701,630.549561 860.367371,634.877136 862.938782,639.397583 
	C864.893738,637.741943 865.931030,636.863525 867.031250,636.378540 
	C869.693420,641.109070 873.872253,638.255493 877.266541,638.957397 
	C880.355896,639.596191 881.322998,637.797913 881.373413,635.035889 
	C883.824646,635.715271 885.912354,636.356384 888.329773,637.141724 
	C890.013489,636.671204 891.442932,636.178528 892.697388,635.402954 
	C893.689453,634.789673 894.461670,633.139893 895.340088,633.133606 
	C901.259155,633.090820 907.180908,633.516846 913.100891,633.496887 
	C921.067688,633.470032 929.033691,633.178162 937.348938,633.002991 
	C938.798584,633.671509 939.899292,634.336975 941.032715,635.332397 
	C941.632141,636.317810 942.198975,636.973328 942.765747,637.628784 
	C943.177124,637.087463 943.588562,636.546143 944.319092,635.979614 
	C945.425537,635.637024 946.212769,635.319702 947.000000,635.002441 
z"/>
<path fill="#6D48B3" opacity="1.000000" stroke="none" 
	d="
M610.460938,649.256226 
	C625.395691,663.770813 624.096375,690.837341 617.631775,703.131958 
	C612.009644,713.824280 602.937622,719.554871 591.372742,720.466064 
	C580.631653,721.312439 569.771362,720.646667 558.488159,720.646667 
	C558.488159,694.467468 558.488159,668.257324 558.488159,641.204773 
	C563.259460,641.204773 568.173462,641.204773 574.007812,641.213867 
	C587.450684,640.900513 599.932556,640.758179 610.460938,649.256226 
M587.158936,650.076843 
	C580.710815,650.076843 574.262756,650.076843 567.756348,650.076843 
	C567.756348,671.163208 567.756348,691.695374 567.756348,712.286011 
	C574.548462,712.286011 580.869507,712.454468 587.178284,712.249084 
	C598.546082,711.879211 607.054199,705.955505 610.387756,695.120178 
	C612.065491,689.667053 612.981384,683.628174 612.608398,677.961731 
	C611.507202,661.230896 602.720398,651.710449 587.158936,650.076843 
z"/>
<path fill="#E9F2F6" opacity="1.000000" stroke="none" 
	d="
M400.786346,646.092834 
	C400.490967,646.115967 400.231140,646.030762 399.942200,645.638916 
	C403.554047,637.203491 401.472595,628.487488 401.994720,619.987976 
	C402.125702,617.855957 402.263428,615.724365 403.060425,613.333496 
	C405.149017,613.382935 406.575226,613.691467 408.000427,614.424988 
	C407.702240,617.630310 408.474396,619.687012 411.738373,618.932434 
	C420.069733,617.006348 428.236359,618.020935 436.679077,618.659973 
	C453.388580,619.924683 470.265808,619.667542 486.581787,614.393555 
	C487.731750,614.021851 489.565765,614.709167 490.680847,615.482239 
	C494.214661,617.932251 497.493378,619.969910 502.011749,617.317505 
	C503.513336,616.436035 505.940094,616.856567 507.896606,617.085754 
	C511.162384,617.468201 514.413269,618.070251 517.627075,618.777039 
	C520.676086,619.447632 522.430237,618.479248 522.974365,615.348389 
	C523.228271,613.887512 523.646484,612.455139 524.367310,611.007019 
	C525.498718,611.009277 526.252991,611.014404 527.007324,611.019531 
	C527.010742,615.372925 527.014160,619.726257 527.013062,624.911865 
	C527.012207,626.499023 527.015869,627.253906 527.019531,628.008850 
	C527.015442,630.047852 527.011353,632.086792 526.738525,634.778687 
	C525.256165,637.354675 524.042542,639.277710 522.316406,642.012756 
	C518.633240,635.773193 514.399414,632.770874 508.198029,634.044922 
	C504.828949,634.737000 501.444305,635.467102 498.191803,636.554382 
	C496.973724,636.961487 495.558655,638.256470 495.184296,639.445923 
	C493.807770,643.819397 491.466461,642.289673 489.930939,640.123352 
	C488.677155,638.354553 488.338745,635.821289 487.971985,633.565613 
	C487.365479,629.835083 487.062897,626.055115 486.466309,620.847473 
	C483.882629,624.287842 482.401062,626.439575 480.736877,628.439392 
	C477.307892,632.559814 475.170197,639.391785 467.479279,635.029297 
	C467.102356,634.815491 466.498291,635.002197 465.909729,634.605957 
	C464.992615,633.809631 464.179810,633.095947 463.336731,633.058105 
	C454.119659,632.644531 444.897583,632.343262 435.677399,631.997681 
	C429.593201,631.769592 423.511017,631.468567 417.424988,631.327515 
	C416.392273,631.303528 414.884521,631.598145 414.385132,632.303894 
	C412.419495,635.081604 410.768829,638.082153 408.693359,641.002197 
	C408.024933,642.069336 407.664368,643.134277 407.411560,643.880920 
	C405.210022,644.615906 402.998169,645.354370 400.786346,646.092834 
z"/>
<path fill="#EDF5FA" opacity="1.000000" stroke="none" 
	d="
M453.007751,145.001587 
	C453.518188,142.675430 453.390991,139.894562 454.662689,138.111267 
	C458.015930,133.409012 461.901459,129.074005 465.721222,124.721054 
	C468.332581,121.745209 471.425354,119.173470 473.890808,116.091438 
	C480.325958,108.046928 486.475311,99.774269 492.872833,91.698914 
	C494.590698,89.530548 497.136902,87.983040 498.693024,85.729446 
	C501.733826,81.325729 499.393707,77.043053 494.090790,77.020348 
	C480.439758,76.961876 466.769684,77.431305 453.143768,76.843132 
	C444.527618,76.471214 437.692078,72.692917 432.457611,64.776085 
	C426.771759,56.176613 428.535553,47.252514 429.613831,38.821396 
	C430.941589,28.439613 442.029022,21.070360 453.267212,21.036785 
	C487.141876,20.935581 521.017151,21.048336 554.891846,20.955328 
	C561.115417,20.938240 567.162903,19.989397 573.536377,21.648094 
	C579.382080,23.169451 583.717896,25.955074 587.576172,30.198135 
	C588.815063,31.560558 590.365784,32.639496 591.198486,33.354259 
	C591.841370,36.167339 592.958374,38.602379 592.832642,40.971443 
	C592.475159,47.704227 592.273010,54.562057 590.776062,61.087658 
	C588.950012,69.047974 585.055542,75.935242 577.951965,81.035614 
	C574.217163,83.717247 572.079834,88.534409 568.868408,92.066605 
	C566.815308,94.324860 564.117615,95.997078 561.354065,97.564392 
	C560.987976,96.122864 560.976685,95.048973 560.974670,93.979065 
	C560.983948,93.983063 560.975586,93.969460 561.323364,93.946846 
	C563.886963,91.977913 566.495422,90.325798 568.226379,88.016098 
	C570.799866,84.582237 572.752991,80.683426 574.985107,76.987656 
	C575.000000,76.992790 574.974548,76.969978 575.300171,76.938835 
	C576.069214,76.260902 576.512695,75.614113 576.978149,74.980850 
	C577.000000,74.994377 576.964600,74.955811 577.326538,74.933273 
	C579.279114,73.046005 584.808289,73.992249 581.950256,68.578148 
	C582.612793,67.121803 583.269165,66.035309 584.295898,64.927505 
	C589.447266,61.053062 590.002625,56.027931 585.735779,53.611649 
	C585.704773,52.147903 585.809570,51.072281 586.242432,50.036461 
	C587.289307,50.394279 588.008179,50.712299 588.651367,50.996868 
	C588.651367,47.692032 588.651367,44.476559 588.651367,42.231407 
	C587.926331,44.029591 586.923706,46.516342 585.773071,48.558571 
	C585.575439,45.283871 585.531372,42.453575 585.474854,39.623528 
	C585.335632,32.648209 585.333374,32.648254 578.494263,31.579464 
	C578.172852,30.426334 577.820984,29.160635 577.199890,29.010736 
	C571.263428,27.577942 565.294250,25.285053 559.310303,25.211720 
	C528.396606,24.832863 497.475037,24.923895 466.558075,25.129999 
	C460.298859,25.171726 453.985229,26.096191 447.830780,27.299589 
	C445.983795,27.660740 444.590881,30.344234 442.660156,32.055389 
	C438.121490,31.945065 436.852997,34.292709 437.084900,38.071453 
	C437.185577,39.712566 437.050934,41.368118 436.620453,43.072369 
	C434.735474,46.872631 433.191376,50.622219 437.120850,54.368824 
	C437.168304,55.844868 437.116241,56.925709 436.795715,58.171593 
	C436.945435,59.233379 437.363647,60.130123 438.061584,61.199310 
	C438.889862,61.731735 439.438354,62.091717 439.984406,62.800583 
	C440.598389,66.623108 442.603882,68.671989 446.060699,69.556129 
	C451.376160,70.915642 456.470734,72.443901 462.233276,72.179108 
	C474.851379,71.599312 487.519165,71.818153 500.155701,72.140205 
	C502.961212,72.211708 507.400970,73.423790 508.089355,75.244537 
	C509.007874,77.673973 507.858856,81.883995 506.089874,84.154205 
	C499.182281,93.018959 491.571503,101.331047 484.426086,110.016350 
	C475.191925,121.240540 466.194916,132.659927 456.947693,143.873093 
	C456.231445,144.741638 454.348450,144.648010 453.007751,145.001587 
z"/>
<path fill="#EDF5FA" opacity="1.000000" stroke="none" 
	d="
M610.746765,649.095337 
	C599.932556,640.758179 587.450684,640.900513 574.468994,641.126160 
	C573.447876,638.327698 572.978882,635.602478 572.302368,632.929749 
	C571.277039,628.879272 570.107300,624.865295 569.378601,620.424438 
	C574.249207,621.495422 578.433472,626.104004 583.591431,621.225708 
	C584.014221,620.825867 585.709961,621.741150 586.801758,622.084900 
	C591.151489,623.454346 595.546387,625.623962 599.204163,620.589417 
	C599.618896,620.018616 601.042053,620.180359 602.033081,620.357788 
	C604.513977,623.138672 606.615234,626.948608 609.486206,627.662354 
	C613.706055,628.711487 617.870911,626.479431 620.429504,622.003723 
	C626.394287,622.442932 632.293213,621.825500 637.379211,623.573425 
	C644.498352,626.020020 650.424011,621.476135 657.387939,622.006592 
	C659.518738,621.674072 661.261841,621.342346 663.004883,621.010620 
	C661.343262,627.622131 659.681580,634.233582 657.847595,641.489136 
	C657.231628,643.071167 656.788086,644.009216 655.966553,644.969238 
	C654.902527,645.378296 654.216492,645.765320 653.530457,646.152344 
	C654.152039,646.486450 654.773621,646.820557 655.416443,647.508301 
	C654.937866,648.913574 654.438049,649.965271 653.731323,651.156128 
	C653.377502,651.589172 653.230713,651.883057 653.149048,652.157593 
	C653.214050,652.138123 653.289429,652.251038 653.289429,652.251038 
	C648.107178,652.492126 648.107178,652.492126 642.526733,649.134216 
	C634.219666,656.005859 616.218689,653.871460 611.616882,644.928467 
	C611.371460,646.610901 611.202026,647.772644 610.746765,649.095337 
z"/>
<path fill="#EDF5FA" opacity="1.000000" stroke="none" 
	d="
M160.988724,632.999207 
	C158.501709,640.291443 156.146698,647.632690 153.467209,654.853455 
	C152.279984,658.052856 149.741791,657.576416 148.046951,655.410278 
	C145.404449,652.032959 142.011292,651.767883 138.248810,651.988708 
	C126.369049,652.685974 114.491837,653.608337 102.601715,653.845642 
	C98.628716,653.924866 94.654457,651.949890 90.614647,651.398193 
	C88.077026,651.051636 85.355026,651.309570 82.826805,651.832886 
	C79.816612,652.455933 76.925362,653.653625 73.002251,654.918396 
	C73.002251,651.539062 73.205360,649.727600 72.963837,647.977417 
	C72.263199,642.900391 73.535622,639.929871 79.468582,640.899170 
	C80.336136,641.040833 81.401169,639.973572 83.048294,639.189697 
	C86.148254,634.609863 88.574745,630.306152 91.423599,625.991577 
	C94.799126,625.653931 97.771690,624.945190 100.700676,625.093994 
	C104.597870,625.291992 108.475151,626.720032 112.336151,626.623779 
	C121.261955,626.401184 130.166153,625.372864 139.092468,625.085022 
	C143.714417,624.935913 148.364792,625.668274 153.001144,626.444275 
	C152.297104,633.863342 153.113312,637.572632 160.988724,632.999207 
z"/>
<path fill="#21529F" opacity="1.000000" stroke="none" 
	d="
M110.292786,824.175842 
	C119.079453,824.257019 127.419930,824.007874 135.716629,824.498657 
	C143.900070,824.982727 151.211426,827.575378 154.035583,836.466858 
	C156.691940,844.830017 154.155365,850.859375 144.922913,858.129211 
	C146.041122,858.923706 146.957504,859.794556 148.042679,860.310120 
	C155.151611,863.687866 157.605560,869.559082 157.234940,877.036377 
	C156.859589,884.608826 153.394455,889.981018 146.141571,892.596252 
	C144.118225,893.325867 141.986588,894.189636 139.893661,894.219360 
	C128.649261,894.378967 117.401398,894.294800 105.668701,894.294800 
	C105.668701,891.036255 105.669174,887.912842 105.668625,884.789429 
	C105.665337,865.976379 105.734825,847.162720 105.589478,828.350769 
	C105.564034,825.057678 106.547020,823.677612 110.292786,824.175842 
M127.469749,887.316772 
	C131.431931,886.959290 135.485962,887.012878 139.335831,886.152710 
	C145.944885,884.676147 149.036850,880.619324 148.921112,874.636475 
	C148.807297,868.752808 145.484207,864.324524 138.876526,863.492859 
	C130.870850,862.485229 122.721481,862.619446 114.285019,862.244019 
	C114.285019,870.984558 114.285019,878.855713 114.285019,887.316956 
	C118.576675,887.316956 122.530190,887.316956 127.469749,887.316772 
M141.112122,851.774109 
	C145.423843,849.055786 146.978653,845.130554 145.858444,840.247314 
	C144.626053,834.875183 140.346939,832.741882 135.575684,832.275452 
	C129.318649,831.663818 122.977730,831.811890 116.675270,831.873779 
	C115.769363,831.882629 114.118286,833.214050 114.096146,833.970947 
	C113.894508,840.862366 113.977798,847.762146 113.977798,855.224060 
	C123.306206,854.247986 132.250824,857.161987 141.112122,851.774109 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M72.988533,564.006409 
	C72.657486,557.724609 72.130226,551.445129 72.056435,545.160278 
	C71.995583,539.977722 74.197006,537.989441 79.367661,537.976074 
	C93.849724,537.938477 108.331932,537.959717 122.814087,537.963501 
	C130.407578,537.965454 131.823395,539.350098 131.945236,546.871094 
	C131.961288,547.861755 131.947433,548.853027 132.895447,550.096313 
	C133.568588,547.949463 134.446060,545.842102 134.870621,543.647217 
	C135.655594,539.588806 137.709122,537.799927 142.050949,537.888245 
	C153.199249,538.115112 164.363129,538.210205 175.504684,537.854187 
	C180.676376,537.688904 183.255280,539.692566 184.267166,544.505493 
	C184.836899,547.215393 185.648865,549.874329 186.351715,552.556274 
	C186.881134,552.536133 187.410553,552.516052 187.939972,552.495972 
	C187.939972,549.546570 188.020935,546.594360 187.920303,543.648315 
	C187.782227,539.606079 189.736359,537.939636 193.670914,537.949280 
	C212.148026,537.994507 230.625412,538.002747 249.102386,537.934204 
	C252.311554,537.922302 254.105103,538.875000 254.174118,542.420654 
	C254.316132,549.718445 254.834595,557.012512 254.819046,564.307251 
	C254.815384,566.030457 253.310944,567.750488 251.900574,569.743774 
	C250.176712,570.619690 249.049149,571.223816 247.921600,571.827881 
	C246.930069,572.944641 245.938522,574.061401 244.539948,575.579102 
	C243.097214,576.718201 241.957535,577.349426 241.056656,578.226013 
	C240.239594,579.021057 239.682632,580.083435 239.005676,580.620422 
	C238.985168,579.129028 238.970001,578.045227 238.964417,576.530640 
	C238.934555,571.503784 238.526215,566.688354 245.402878,565.965576 
	C246.863922,564.954529 248.694992,564.044556 248.845505,562.911316 
	C249.495605,558.015930 249.577621,553.048584 250.015091,548.120605 
	C250.363190,544.199524 248.711090,542.894287 244.831528,542.931274 
	C229.068268,543.081665 213.301987,543.067444 197.538498,542.926270 
	C194.178253,542.896240 192.646835,543.741699 192.922104,547.369934 
	C193.197510,551.000183 192.986649,554.666931 192.999359,558.317688 
	C193.025238,565.752258 193.121811,565.842163 201.300003,566.149536 
	C202.417435,568.544617 203.218185,570.769836 204.012894,573.424438 
	C204.340973,577.568359 204.675064,581.282959 204.999832,585.351746 
	C204.325577,586.473083 203.660645,587.240173 202.996094,587.573792 
	C202.657455,583.191406 202.619507,579.179504 201.813797,575.328308 
	C201.536880,574.004517 199.342270,573.081970 198.015778,571.607422 
	C197.860260,570.310608 197.712677,569.382568 197.377518,567.275085 
	C195.146027,569.182312 193.538681,570.556091 191.568939,572.018860 
	C190.467590,575.408447 189.728607,578.709106 188.627228,581.936646 
	C186.837723,578.910583 185.410629,575.957764 183.979370,572.592651 
	C183.322845,568.787720 182.670456,565.395081 182.013748,561.580322 
	C181.010040,556.139771 180.206192,551.068665 178.877518,546.138916 
	C178.533875,544.863953 176.564194,543.163208 175.307785,543.134460 
	C164.814880,542.895386 154.306610,542.805542 143.823395,543.205505 
	C142.165054,543.268738 139.718369,545.747131 139.176300,547.567566 
	C137.682312,552.584900 137.005295,557.845520 135.994858,563.406250 
	C134.661362,567.875732 133.338165,571.948914 132.007721,575.579773 
	C131.992844,566.492981 131.703186,566.006409 125.974228,564.578857 
	C126.305580,561.098755 126.632889,558.047729 126.967636,554.542236 
	C126.977242,552.759705 126.985619,551.431763 126.980515,550.103760 
	C126.954155,543.242493 126.712975,542.993408 120.082771,542.990479 
	C110.952919,542.986450 101.823059,542.983154 92.693207,542.983398 
	C76.917801,542.983826 76.918152,542.985046 77.998077,558.638855 
	C78.032303,559.134888 77.843750,559.875427 78.100243,560.085632 
	C78.989388,560.814148 80.029327,561.358704 81.330482,562.123901 
	C83.428986,562.861023 85.208885,563.447632 86.996300,564.421997 
	C87.371078,566.501221 87.941963,568.182251 88.028984,569.887939 
	C88.080925,570.906128 87.373955,571.962952 86.860756,572.667236 
	C85.707832,570.664856 84.702286,568.997681 83.696732,567.330566 
	C82.465187,568.533325 81.233650,569.736084 80.002121,570.938843 
	C77.669319,569.542053 75.336517,568.145203 73.328430,566.334351 
	C74.101891,565.280212 74.550644,564.640137 75.331589,563.950745 
	C75.964211,563.206970 76.264648,562.512451 76.565086,561.817871 
	C76.201126,561.691101 75.837173,561.564392 75.473213,561.437622 
	C75.315674,562.291748 75.158142,563.145874 74.665329,563.999634 
	C73.882874,564.001648 73.435707,564.004028 72.988533,564.006409 
M132.202332,563.608276 
	C132.206543,563.214233 132.210754,562.820129 132.214966,562.426086 
	C132.051468,562.424500 131.745865,562.411987 131.745010,562.422913 
	C131.714783,562.815125 131.713058,563.209473 132.202332,563.608276 
z"/>
<path fill="#21529F" opacity="1.000000" stroke="none" 
	d="
M796.739014,883.962891 
	C796.738586,887.602905 796.738586,890.749573 796.738586,893.786255 
	C790.194946,896.004272 788.195312,895.441284 784.769653,890.512634 
	C774.047791,875.086121 763.347656,859.644348 752.612915,844.226807 
	C751.631958,842.817871 750.463318,841.539673 748.281006,840.575928 
	C748.281006,858.411560 748.281006,876.247192 748.281006,894.457764 
	C745.127502,894.457764 742.592041,894.457764 739.757507,894.457764 
	C739.757507,871.168213 739.757507,848.096497 739.757507,824.730103 
	C744.609436,823.563477 748.302063,823.681396 751.604919,828.653076 
	C761.809875,844.014038 772.575317,859.002625 783.132263,874.129456 
	C784.240417,875.717285 785.409180,877.262756 787.798767,878.530090 
	C787.902039,877.010864 788.092468,875.491760 788.095154,873.972290 
	C788.121948,858.821838 788.187439,843.670776 788.064880,828.521362 
	C788.039734,825.412292 788.711609,824.019348 792.182373,824.065735 
	C795.509766,824.110168 796.850769,824.847595 796.814697,828.529053 
	C796.635071,846.841003 796.736816,865.155762 796.739014,883.962891 
z"/>
<path fill="#21529F" opacity="1.000000" stroke="none" 
	d="
M377.049255,894.500671 
	C364.946533,877.584595 353.194305,860.847961 341.427338,844.121704 
	C340.392914,842.651306 339.261810,841.248779 337.260925,838.607117 
	C337.260925,858.014648 337.260925,876.001404 337.260925,894.410767 
	C334.267456,894.410767 331.872070,894.410767 328.967773,894.410767 
	C328.967773,871.297668 328.967773,848.106140 328.967773,824.621277 
	C333.789856,823.605286 337.443115,823.846497 340.719086,828.806091 
	C351.073334,844.481628 362.027985,859.760864 372.769684,875.179932 
	C373.705475,876.523132 374.782959,877.767700 376.732056,880.253784 
	C376.732056,860.931274 376.732056,842.983154 376.732056,825.242310 
	C384.861786,822.854126 385.586975,823.352844 385.586426,830.659485 
	C385.584961,850.151062 385.583984,869.642639 385.582886,889.134216 
	C385.582794,890.773132 385.582886,892.411987 385.582886,894.682861 
	C382.559875,894.682861 379.977844,894.682861 377.049255,894.500671 
z"/>
<path fill="#6D48B3" opacity="1.000000" stroke="none" 
	d="
M656.344482,644.947205 
	C656.788086,644.009216 657.231628,643.071167 657.809814,641.932617 
	C658.757751,641.573425 659.566467,641.383484 660.385315,641.261108 
	C665.755859,640.458679 668.856750,641.965393 671.018738,647.784485 
	C679.595093,670.868042 688.908447,693.677612 697.921265,716.599548 
	C698.385132,717.779175 698.594177,719.059021 698.931641,720.329163 
	C690.119690,721.825806 689.970154,721.748535 687.061768,714.473328 
	C686.258179,712.463135 685.447937,710.455444 684.654358,708.441284 
	C680.973511,699.098694 680.975220,699.097412 671.149170,699.094604 
	C663.982666,699.092529 656.810242,699.263306 649.652039,699.019104 
	C646.079407,698.897156 644.090088,700.087891 643.020142,703.523499 
	C641.934021,707.010803 640.426331,710.368958 639.060730,713.766602 
	C635.988953,721.409241 635.978271,721.404968 626.516113,720.472046 
	C634.525574,700.248474 642.432922,680.282837 650.544312,659.769165 
	C650.849854,658.814453 650.951416,658.407837 651.052979,658.001221 
	C651.466980,657.069641 651.881104,656.138000 652.510254,654.706055 
	C652.832825,653.803589 652.940430,653.401550 653.048035,652.999451 
	C653.048035,652.999451 653.297668,652.666138 653.293579,652.458618 
	C653.289429,652.251038 653.214050,652.138123 653.405762,652.072632 
	C653.711060,651.677124 653.824707,651.347046 653.938293,651.016968 
	C654.438049,649.965271 654.937866,648.913574 655.627563,647.283691 
	C655.993164,646.119385 656.168823,645.533264 656.344482,644.947205 
M654.245850,674.739380 
	C652.322693,679.762512 650.399475,684.785645 648.317810,690.222839 
	C658.571777,690.222839 667.894104,690.222839 677.844177,690.222839 
	C672.977417,677.761292 668.346985,665.904785 663.716492,654.048218 
	C663.296448,654.124573 662.876343,654.200989 662.456238,654.277344 
	C659.815247,660.860535 657.174255,667.443665 654.245850,674.739380 
z"/>
<path fill="#6D48B3" opacity="1.000000" stroke="none" 
	d="
M787.280884,650.975220 
	C787.280884,650.975220 787.383667,650.793335 787.608887,650.447937 
	C788.004883,649.393677 788.175659,648.684753 788.346436,647.975830 
	C788.346436,647.975830 788.478638,647.796997 788.730469,647.451416 
	C792.275574,640.756042 796.167419,639.453613 802.570435,642.702576 
	C802.574036,642.845398 802.772156,643.051392 802.808472,643.405151 
	C803.471558,645.029846 804.098267,646.300781 804.724976,647.571655 
	C804.724976,647.571655 804.521301,647.823792 804.590576,648.297791 
	C804.962158,649.335510 805.264343,649.899231 805.566528,650.462952 
	C805.566528,650.462952 805.543030,650.810730 805.651123,651.267700 
	C806.091187,652.245972 806.423218,652.767273 806.755249,653.288574 
	C806.755249,653.288574 806.793152,653.692322 806.869263,654.206543 
	C807.526733,655.937500 808.108093,657.154114 808.689392,658.370789 
	C808.765991,658.743286 808.842590,659.115845 809.015381,660.012573 
	C809.318848,660.810669 809.526062,661.084473 809.733337,661.358337 
	C809.851990,661.722778 809.970642,662.087280 810.180054,662.996338 
	C810.691467,664.211365 811.112183,664.881775 811.532837,665.552124 
	C811.593323,665.894897 811.653809,666.237671 811.853516,667.083008 
	C812.216858,667.870972 812.440979,668.156372 812.665100,668.441772 
	C812.665100,668.441772 812.656677,668.774292 812.740845,669.271729 
	C813.413696,671.010681 814.002380,672.252197 814.591003,673.493652 
	C814.907043,674.491943 815.223083,675.490173 815.677612,677.080811 
	C816.155457,678.181396 816.494690,678.689575 816.833984,679.197815 
	C816.833984,679.197815 816.868713,679.623535 816.933044,680.156128 
	C817.526001,681.632874 818.054565,682.577026 818.583130,683.521179 
	C818.583130,683.521179 818.576416,683.826416 818.660400,684.275269 
	C819.058472,685.277649 819.372498,685.831238 819.686523,686.384827 
	C819.758789,686.754028 819.831116,687.123230 819.991394,688.014771 
	C820.322083,688.779541 820.564758,689.022034 820.807434,689.264526 
	C820.807434,689.264526 820.848267,689.668945 820.912720,690.127747 
	C821.194580,690.873779 821.411926,691.161011 821.629272,691.448242 
	C821.629272,691.448242 821.641113,691.791443 821.761902,692.212158 
	C822.112305,692.926880 822.341858,693.220886 822.571472,693.514954 
	C822.571472,693.514954 822.545593,693.846924 822.643066,694.225586 
	C822.740540,694.604309 823.032959,694.987549 823.032959,694.987549 
	C823.162354,695.549866 823.291687,696.112122 823.616882,697.197815 
	C824.128845,698.246521 824.445007,698.771851 824.761230,699.297180 
	C824.761230,699.297180 824.807861,699.690552 824.881104,700.196289 
	C825.468506,701.659058 825.982666,702.616150 826.496704,703.573242 
	C826.539734,703.919312 826.582764,704.265442 826.793884,705.105408 
	C827.178528,705.891418 827.395020,706.183472 827.611511,706.475586 
	C827.611511,706.475586 827.603149,706.806396 827.719238,707.225464 
	C828.062073,707.942444 828.288696,708.240356 828.515320,708.538330 
	C828.515320,708.538330 828.488098,708.856262 828.594849,709.342224 
	C829.156677,710.874634 829.611755,711.921082 830.066772,712.967468 
	C830.211487,713.527222 830.356140,714.087036 830.703308,715.150391 
	C831.142700,715.931702 831.379578,716.209412 831.616516,716.487122 
	C831.616516,716.487122 831.557556,716.815918 831.633057,717.310669 
	C831.944519,718.192566 832.180542,718.579773 832.416504,718.966919 
	C829.117371,722.333008 823.393127,721.748596 821.745300,717.773010 
	C819.648193,712.713745 817.938965,707.480652 815.593750,702.545227 
	C814.867065,701.015930 812.639832,699.275330 811.053467,699.229614 
	C801.090759,698.942200 791.112305,699.000122 781.146545,699.245178 
	C779.787537,699.278625 777.883179,700.700012 777.243103,701.983337 
	C775.321899,705.835266 773.857544,709.919922 772.295288,713.944946 
	C769.343994,721.548157 769.367249,721.557190 760.122681,720.406311 
	C768.617981,698.329590 777.059753,676.392151 786.044312,654.027100 
	C786.818359,652.724670 787.049622,651.849976 787.280884,650.975220 
M793.192261,660.734253 
	C789.408752,670.372498 785.625244,680.010681 781.686646,690.043823 
	C791.887573,690.043823 801.274292,690.043823 811.153870,690.043823 
	C806.314941,677.589844 801.751831,665.845703 796.680969,652.794922 
	C795.190857,656.165833 794.337952,658.095093 793.192261,660.734253 
z"/>
<path fill="#F4F8FC" opacity="1.000000" stroke="none" 
	d="
M466.000000,635.002441 
	C466.498291,635.002197 467.102356,634.815491 467.479279,635.029297 
	C475.170197,639.391785 477.307892,632.559814 480.736877,628.439392 
	C482.401062,626.439575 483.882629,624.287842 486.466309,620.847473 
	C487.062897,626.055115 487.365479,629.835083 487.971985,633.565613 
	C488.338745,635.821289 488.677155,638.354553 489.930939,640.123352 
	C491.466461,642.289673 493.807770,643.819397 495.184296,639.445923 
	C495.558655,638.256470 496.973724,636.961487 498.191803,636.554382 
	C501.444305,635.467102 504.828949,634.737000 508.198029,634.044922 
	C514.399414,632.770874 518.633240,635.773193 522.316406,642.012756 
	C524.042542,639.277710 525.256165,637.354675 526.740967,635.215698 
	C527.587036,635.566284 528.625732,636.105225 528.661377,636.703857 
	C528.888428,640.519897 531.126953,641.137390 534.411682,641.059204 
	C539.055603,640.948669 540.686890,642.891846 539.889832,647.430420 
	C539.050781,652.208374 537.910767,656.933472 536.902283,661.681702 
	C536.372803,661.930786 535.843323,662.179810 535.313843,662.428894 
	C533.872986,660.617554 531.790588,659.008850 531.139771,656.948059 
	C530.131470,653.755066 527.893982,653.239075 525.828796,654.347412 
	C516.835449,659.173706 507.796722,655.817627 498.334595,655.012634 
	C493.759644,640.035400 477.931641,639.157471 466.440094,644.163391 
	C465.565155,644.523254 465.318756,644.782471 465.072388,645.041687 
	C465.072388,645.041687 465.031372,645.030334 464.798859,645.107971 
	C464.263763,645.355835 464.088959,645.612915 464.041992,645.956970 
	C463.648376,646.318848 463.254761,646.680664 462.280090,647.118164 
	C459.269073,647.794861 457.519928,648.724915 459.612427,651.511536 
	C459.612427,651.511536 459.526794,651.876892 459.201385,652.017090 
	C458.770325,652.236755 458.565857,652.373047 458.573883,652.386963 
	C458.692993,652.593811 458.840271,652.784485 458.980896,652.978943 
	C458.806335,654.135437 458.631775,655.291931 458.251312,656.727783 
	C453.068481,657.004822 448.090851,656.946716 443.114929,657.016174 
	C438.382965,657.082214 433.471802,656.102722 429.300812,659.605347 
	C428.575165,660.214722 427.032715,659.753113 425.925690,660.023926 
	C421.364655,661.140137 416.987549,656.362183 412.189301,659.679688 
	C411.590576,660.093689 409.264038,658.008606 407.323120,656.717957 
	C406.265472,655.249023 405.622803,654.143616 404.980133,653.038269 
	C404.980133,653.038269 404.784302,652.695435 404.835693,652.122070 
	C404.160614,650.415833 403.434082,649.283081 402.707581,648.150269 
	C402.138489,647.644409 401.569397,647.138611 400.893311,646.362793 
	C402.998169,645.354370 405.210022,644.615906 407.411560,643.880920 
	C407.664368,643.134277 408.024933,642.069336 409.061615,641.006470 
	C411.127838,641.805847 412.475861,642.691040 413.917175,643.381470 
	C416.558441,644.646729 419.205200,646.676025 421.937439,646.830017 
	C430.073181,647.288696 438.266968,647.305237 446.408966,646.903870 
	C454.909607,646.484863 462.492310,643.934753 466.000000,635.002441 
z"/>
<path fill="#6D48B3" opacity="1.000000" stroke="none" 
	d="
M334.655426,648.856140 
	C334.497192,663.292603 334.384583,677.729797 334.158966,692.165161 
	C334.065033,698.174561 332.706238,703.655273 329.644775,709.159668 
	C325.913300,715.868774 320.623627,719.601562 313.708130,721.541077 
	C298.883118,725.698853 283.620483,720.840393 276.899902,709.959045 
	C275.861542,708.277832 275.152771,706.393005 274.154755,703.974609 
	C273.730255,702.787476 273.442566,702.227966 273.154907,701.668518 
	C273.145294,701.285034 273.135712,700.901489 273.071167,699.860596 
	C272.722595,697.454468 272.429016,695.705750 272.135406,693.956970 
	C272.111938,679.276123 272.088470,664.595215 272.050232,649.148865 
	C271.842896,648.042480 271.650360,647.701599 271.457855,647.360718 
	C271.609985,646.961792 271.762115,646.562927 271.963257,645.546265 
	C272.855164,640.738708 274.388641,640.254089 281.729889,642.326660 
	C281.729889,647.389893 281.729889,652.677979 281.591553,658.420410 
	C281.652679,670.588074 281.852112,682.301453 282.001465,694.393494 
	C282.283691,696.215271 282.615997,697.658325 282.951660,699.461609 
	C287.268097,711.145203 295.211945,715.824219 307.626404,714.053223 
	C316.761322,712.750061 323.826141,706.852661 324.264343,694.420105 
	C324.879333,676.972656 324.634521,659.494873 325.088043,641.768677 
	C328.523865,641.570801 331.629120,641.634399 334.632294,642.080933 
	C334.571991,644.594604 334.613708,646.725403 334.655426,648.856140 
z"/>
<path fill="#2656A1" opacity="1.000000" stroke="none" 
	d="
M971.548462,832.482788 
	C978.692810,845.189026 975.041260,856.325317 961.196411,864.043396 
	C963.634583,869.023071 966.108398,874.023499 968.535156,879.046631 
	C970.901367,883.944458 973.219482,888.865540 975.574890,893.810364 
	C968.722961,896.055664 967.325806,895.443176 964.560913,889.632385 
	C961.059814,882.274414 957.574707,874.902893 953.786133,867.693054 
	C953.068970,866.328125 951.018677,864.957336 949.495605,864.851318 
	C944.413818,864.497559 939.292053,864.717346 933.670898,864.717346 
	C933.670898,874.943604 933.670898,884.529968 933.670898,894.477905 
	C930.767273,894.477905 928.382080,894.477905 925.626343,894.477905 
	C925.626343,871.277344 925.626343,848.200012 925.626343,824.520630 
	C927.288818,824.391479 929.044495,824.109924 930.794189,824.143433 
	C939.943787,824.318176 949.104492,824.352966 958.235413,824.883728 
	C963.581238,825.194336 967.994995,827.910400 971.548462,832.482788 
M943.510620,831.824341 
	C940.407776,831.824341 937.304932,831.824341 934.080444,831.824341 
	C934.080444,840.546509 934.080444,848.572876 934.080444,857.318420 
	C942.098267,856.964111 949.742493,857.087463 957.252319,856.142822 
	C962.867493,855.436584 966.177002,850.470093 966.308350,844.677795 
	C966.451721,838.355103 963.874084,834.237122 957.830933,832.895630 
	C953.517944,831.938354 948.947144,832.142639 943.510620,831.824341 
z"/>
<path fill="#21529F" opacity="1.000000" stroke="none" 
	d="
M507.054810,841.001892 
	C502.263733,841.585938 499.268982,840.435669 496.489685,836.227722 
	C492.053986,829.511841 480.741302,828.355957 473.963989,832.891296 
	C467.908600,836.943481 467.639984,845.862183 473.886932,849.695923 
	C478.090088,852.275330 482.927643,853.830139 487.504974,855.788086 
	C491.624756,857.550354 495.993805,858.850586 499.874817,861.028381 
	C512.823975,868.294861 512.870178,884.999878 500.127136,892.679749 
	C488.997742,899.387207 470.253571,897.197083 462.203033,887.822449 
	C459.402588,884.561523 457.662994,880.389465 455.047180,875.971985 
	C461.011780,875.173889 464.796539,874.528503 467.735748,880.244263 
	C472.333771,889.185791 485.949677,891.834167 494.536987,886.435181 
	C501.794067,881.872681 501.987946,872.427063 494.493530,868.021301 
	C489.959229,865.355774 484.727509,863.893311 479.862152,861.768799 
	C475.908600,860.042480 471.767365,858.548767 468.174011,856.231445 
	C457.073792,849.072937 456.935211,834.632263 467.663605,826.995605 
	C479.487823,818.578918 498.805023,821.912415 505.746582,833.780212 
	C506.929169,835.802002 506.902496,838.531006 507.054810,841.001892 
z"/>
<path fill="#21529F" opacity="1.000000" stroke="none" 
	d="
M273.684021,832.970459 
	C267.731598,829.468079 261.915955,829.449341 256.109924,832.031433 
	C252.350708,833.703247 249.431442,836.840759 250.106491,840.916443 
	C250.645309,844.169495 252.472900,848.271362 255.051895,849.897705 
	C260.033722,853.039246 265.974457,854.641479 271.469208,856.994995 
	C274.972961,858.495728 278.595245,859.870483 281.806824,861.874695 
	C291.565765,867.964905 293.143738,881.415894 284.847473,889.374939 
	C276.380157,897.498169 265.809357,897.613342 255.273819,895.223694 
	C245.373978,892.978210 239.345520,886.192444 236.475403,876.507507 
	C242.525299,874.128540 244.856842,874.874023 247.751205,879.957031 
	C253.011230,889.194641 267.844543,892.109497 276.267548,885.560669 
	C281.585480,881.426025 282.226593,873.700623 276.832581,869.594849 
	C272.969788,866.654541 268.105988,864.988098 263.603516,862.940979 
	C259.071228,860.880310 254.240402,859.406128 249.888138,857.036316 
	C243.600143,853.612488 240.012360,848.221924 240.387924,840.740051 
	C240.761780,833.292297 244.865463,828.331665 251.297073,825.238708 
	C265.813354,818.257690 282.769684,823.932556 288.145203,837.447205 
	C288.495819,838.328674 288.598511,839.308838 288.879333,840.511963 
	C284.124756,841.429871 279.859924,842.343445 277.486511,836.684631 
	C276.895508,835.275452 275.207916,834.326233 273.684021,832.970459 
z"/>
<path fill="#21529F" opacity="1.000000" stroke="none" 
	d="
M519.499390,876.930420 
	C524.714783,874.173828 527.761963,874.982666 530.212402,879.414062 
	C535.485413,888.949768 549.635620,892.237732 558.531311,885.994141 
	C564.858398,881.553467 565.188660,873.224854 558.881531,868.806763 
	C555.802490,866.649902 552.150940,865.267639 548.669189,863.738281 
	C543.353088,861.403015 537.785095,859.581665 532.643066,856.924622 
	C526.250854,853.621521 522.691345,848.187195 523.084412,840.713074 
	C523.463440,833.504517 527.479858,828.681946 533.634338,825.484253 
	C546.790527,818.648682 563.596191,823.138794 569.850342,835.135132 
	C570.676331,836.719482 571.170776,838.476685 571.956116,840.509277 
	C567.063904,841.358765 563.413635,842.047791 560.296692,836.838440 
	C556.049316,829.739807 544.441711,828.141663 537.495972,832.708191 
	C531.022949,836.963928 531.009277,846.249878 537.779175,850.158081 
	C541.778992,852.467102 546.276978,853.916443 550.560852,855.728882 
	C554.991577,857.603394 559.745544,858.936340 563.813477,861.404053 
	C575.996338,868.794678 575.815491,885.084412 563.722412,892.639587 
	C553.831360,898.819092 535.429749,897.508179 527.171814,889.325073 
	C523.882629,886.065735 522.035400,881.351135 519.499390,876.930420 
z"/>
<path fill="#2656A1" opacity="1.000000" stroke="none" 
	d="
M401.991241,876.015503 
	C401.991272,858.722168 401.991272,841.920959 401.991272,824.552979 
	C403.651215,824.417725 405.226318,824.184082 406.802246,824.177979 
	C417.959534,824.134705 429.116974,824.136353 440.274384,824.127258 
	C446.758240,824.122009 446.758240,824.124878 445.832001,831.838806 
	C441.055420,831.838806 436.150146,831.836121 431.244904,831.839600 
	C425.416473,831.843811 419.582214,831.719116 413.764435,831.971619 
	C412.573792,832.023315 410.476532,833.457642 410.441101,834.322021 
	C410.163788,841.086914 410.287506,847.868164 410.287506,855.128784 
	C421.160645,855.128784 431.425873,855.128784 441.524750,855.128784 
	C443.730621,862.063599 443.198242,862.844543 436.687042,862.852844 
	C428.216431,862.863647 419.745819,862.855469 410.368164,862.855469 
	C410.368164,870.554810 410.242554,877.942810 410.560883,885.311584 
	C410.593018,886.054993 413.297852,887.246216 414.779022,887.271973 
	C424.100555,887.434387 433.428131,887.407288 442.750732,887.258972 
	C445.702087,887.211975 447.410645,887.595642 447.330688,891.231506 
	C447.255096,894.668213 445.531769,894.852112 442.880157,894.836365 
	C429.427216,894.756104 415.973541,894.799438 401.991211,894.799438 
	C401.991211,888.382874 401.991211,882.445251 401.991241,876.015503 
z"/>
<path fill="#2656A1" opacity="1.000000" stroke="none" 
	d="
M224.711151,862.972839 
	C224.129272,868.733459 224.142273,874.152954 222.833069,879.231567 
	C220.573898,887.995178 214.592773,893.929565 205.627167,895.265198 
	C199.520187,896.174927 192.948441,896.107727 186.912292,894.873779 
	C176.504456,892.746277 170.126373,884.115295 169.666214,871.446350 
	C169.105286,856.003479 169.532822,840.524780 169.532822,825.084778 
	C176.735703,822.723999 177.855728,823.464722 177.864334,830.539001 
	C177.879929,843.357117 177.669693,856.180298 177.965927,868.991882 
	C178.287064,882.879944 187.639420,890.808594 200.778793,888.831238 
	C209.076797,887.582397 214.699646,881.828247 215.573700,872.430542 
	C216.280518,864.831116 216.036697,857.139282 216.121460,849.487366 
	C216.189667,843.328857 216.122589,837.168884 216.108231,831.009521 
	C216.090347,823.338745 216.222855,823.231262 224.710999,824.825500 
	C224.710999,837.289001 224.710999,849.883606 224.711151,862.972839 
z"/>
<path fill="#2656A1" opacity="1.000000" stroke="none" 
	d="
M719.836853,894.845459 
	C706.902100,894.845276 694.445923,894.845276 681.647766,894.845276 
	C681.647766,871.322876 681.647766,848.248413 681.647766,824.514160 
	C683.224304,824.400940 684.964111,824.174255 686.704712,824.167603 
	C697.696655,824.125671 708.688782,824.137573 719.680847,824.121216 
	C726.107483,824.111633 726.107483,824.102844 725.420410,831.837036 
	C721.291260,831.837036 717.049866,831.837646 712.808533,831.836914 
	C706.479797,831.835938 700.148438,831.939453 693.823364,831.785889 
	C690.863037,831.714050 689.586853,832.633057 689.705933,835.764343 
	C689.895508,840.752869 689.836121,845.756165 689.717529,850.749268 
	C689.638977,854.058411 690.792969,855.455933 694.347534,855.371338 
	C703.316589,855.157715 712.294189,855.300476 721.148560,855.300476 
	C723.179443,862.158630 722.677246,862.855713 716.083984,862.861816 
	C708.756104,862.868591 701.424683,863.005920 694.101868,862.815186 
	C690.883484,862.731445 689.594116,863.693237 689.729675,867.038757 
	C689.938660,872.192871 689.924316,877.368591 689.709473,882.522888 
	C689.557068,886.179260 690.832764,887.460388 694.589478,887.366821 
	C703.909485,887.134766 713.239502,887.299561 722.565430,887.329468 
	C723.691711,887.333069 724.817383,887.487427 726.224060,887.592834 
	C726.224060,889.908875 726.224060,891.981323 726.224060,894.574890 
	C724.187561,894.668213 722.251465,894.756958 719.836853,894.845459 
z"/>
<path fill="#2656A1" opacity="1.000000" stroke="none" 
	d="
M875.336792,844.996460 
	C875.336731,848.476929 875.336731,851.459351 875.336731,855.065674 
	C886.101624,855.065674 896.492554,855.065674 907.259033,855.065674 
	C907.259033,857.884155 907.259033,860.078735 907.259033,862.864807 
	C898.052795,862.864807 889.105713,863.008423 880.167175,862.794678 
	C876.555908,862.708374 875.028259,863.652222 875.262939,867.527466 
	C875.574646,872.673340 875.618347,877.867737 875.286133,883.009583 
	C875.038635,886.841736 876.789429,887.481812 879.993225,887.423401 
	C888.652039,887.265625 897.314270,887.278992 905.975220,887.255615 
	C912.427551,887.238159 912.427612,887.259827 911.632019,894.452759 
	C897.113647,894.452759 882.542664,894.452759 867.530151,894.452759 
	C867.365906,893.360352 867.015625,892.113342 867.015259,890.866272 
	C867.009460,870.210266 867.132141,849.553711 866.997925,828.898743 
	C866.973877,825.192017 867.990662,823.995483 871.779907,824.074829 
	C882.935791,824.308289 894.099854,824.140686 905.260681,824.150757 
	C912.062012,824.156921 912.153748,824.289307 910.835327,831.783447 
	C899.357605,831.783447 887.775146,831.783447 875.336853,831.783447 
	C875.336853,836.349243 875.336853,840.423828 875.336792,844.996460 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M610.258728,558.929138 
	C610.503479,553.303101 610.422058,547.631104 611.202820,542.080505 
	C611.421143,540.528809 614.170715,538.168335 615.779297,538.151917 
	C639.916077,537.905762 664.066162,537.641968 688.185974,538.331970 
	C692.311584,538.449951 696.300598,537.920410 700.355103,537.928528 
	C737.979126,538.003357 775.603394,537.964661 813.227539,537.972412 
	C820.403198,537.973938 821.031616,538.591064 821.057129,545.580566 
	C821.079590,551.740173 821.047119,557.899963 820.752930,564.724915 
	C819.213623,567.176147 817.960327,568.962036 816.707031,570.747925 
	C817.017517,570.993530 817.328064,571.239136 817.638550,571.484741 
	C818.768982,570.983826 819.899353,570.482910 821.029724,569.982056 
	C821.023315,571.701538 821.016907,573.421082 820.604065,575.569519 
	C819.127686,576.002625 818.057739,576.006714 816.936707,575.664612 
	C816.245667,573.881104 815.605713,572.443848 814.974243,570.552124 
	C814.979492,567.067993 814.976318,564.038269 814.981018,560.632202 
	C814.978943,559.503052 814.968994,558.750305 814.971069,557.530640 
	C814.991638,554.401855 814.740601,551.707886 815.065430,549.085205 
	C815.648926,544.373962 814.049133,542.865112 809.123047,542.898560 
	C776.682922,543.118896 744.240479,542.979553 711.799011,543.063354 
	C708.520264,543.071777 705.194336,543.559631 701.985107,544.259583 
	C700.249756,544.638062 697.251282,546.130981 697.338440,546.786743 
	C697.755493,549.924622 699.033142,552.948059 699.993225,556.380005 
	C699.654358,558.165955 699.327209,559.584229 698.666260,561.001953 
	C697.887512,561.004211 697.442566,561.006958 696.995361,560.598022 
	C696.829407,558.573364 696.665649,556.960449 696.501953,555.347534 
	C695.897949,555.345520 695.294006,555.343445 694.690063,555.341431 
	C694.284607,558.779968 693.879150,562.218445 693.473633,565.656982 
	C692.844177,565.654907 692.214722,565.652893 691.585205,565.650879 
	C691.389160,563.049744 691.078186,560.450684 691.032532,557.846985 
	C690.994324,555.673584 690.945557,553.745667 687.977295,553.566956 
	C687.490723,549.915222 687.009705,546.685059 686.460449,542.996277 
	C684.440979,542.996277 682.646545,542.996277 680.852173,542.996277 
	C662.535339,542.996826 644.218567,542.995789 625.901733,542.998535 
	C615.972351,543.000061 615.972351,543.002930 615.891113,553.285095 
	C615.524963,554.031311 615.266907,554.521790 614.655884,555.071289 
	C612.954895,556.396667 611.606812,557.662903 610.258728,558.929138 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M281.729919,657.966064 
	C281.729889,652.677979 281.729889,647.389893 281.729889,642.326660 
	C274.388641,640.254089 272.855164,640.738708 271.900665,645.223755 
	C271.189758,647.740356 270.217255,649.940613 270.089478,652.188721 
	C269.942932,654.768127 269.373322,655.945923 266.487000,656.107544 
	C263.799255,656.258057 261.164062,657.346985 257.867004,657.789001 
	C254.624237,653.915222 252.020248,650.275269 249.342255,646.696533 
	C249.268250,646.757690 249.359436,646.588684 249.282471,646.287231 
	C247.679291,644.795288 246.153107,643.604858 244.746582,642.294067 
	C244.866241,642.173767 245.102905,641.930542 245.102905,641.930542 
	C247.985336,641.883240 250.905487,641.517822 253.742416,641.854187 
	C259.850464,642.578491 264.999908,638.390076 264.997650,632.350769 
	C264.996735,629.876770 264.998138,627.402832 265.420746,624.463928 
	C269.678070,623.824585 272.959320,624.624695 274.100800,629.624756 
	C276.372772,627.854614 278.123627,626.490417 280.094452,624.954895 
	C283.127716,628.287903 286.354645,627.295349 290.050262,626.278259 
	C294.016083,625.186829 297.935181,621.705200 302.552094,624.780945 
	C302.782959,624.934753 304.813080,623.437622 304.860962,622.640381 
	C305.113007,618.446472 304.996765,614.230347 305.113159,609.801147 
	C305.414429,609.305664 305.673065,609.115479 306.326660,609.012390 
	C307.103027,609.021362 307.546631,609.027771 307.991791,609.466980 
	C308.360352,613.099915 308.727356,616.299988 309.141785,619.913635 
	C310.839111,618.616760 311.904938,617.802429 312.981262,617.457336 
	C312.991760,625.466003 312.991760,633.005432 312.991760,639.815430 
	C316.943512,641.074097 319.970734,642.038269 322.830811,643.258057 
	C322.349609,644.033447 321.667694,644.652100 321.776794,645.058044 
	C323.403778,651.109802 322.875000,651.374573 316.920685,650.562256 
	C305.868744,649.054504 294.743225,648.085876 283.852020,646.914673 
	C283.044250,651.121277 282.387085,654.543701 281.729919,657.966064 
z"/>
<path fill="#21529F" opacity="1.000000" stroke="none" 
	d="
M628.644043,885.619263 
	C641.159851,892.266174 651.359253,889.262024 659.380859,877.696838 
	C660.715576,875.772583 664.920654,875.839355 668.258972,874.849854 
	C667.149780,885.473450 658.279907,894.016235 647.759338,895.815308 
	C627.478333,899.283447 612.852966,889.218994 609.649170,868.894409 
	C607.604065,855.919434 608.488098,843.089600 617.403442,832.419312 
	C630.556335,816.677307 657.708496,819.953308 666.919617,838.199646 
	C667.565491,839.479187 667.923035,840.904297 668.546021,842.624146 
	C663.596008,843.419128 660.071472,843.819397 656.884460,838.721802 
	C648.992004,826.097778 627.844543,827.726562 621.222534,841.563782 
	C615.401917,853.726379 615.401794,866.441650 621.996216,878.552185 
	C623.441345,881.206055 626.178345,883.156494 628.644043,885.619263 
z"/>
<path fill="#EDF5FA" opacity="1.000000" stroke="none" 
	d="
M207.167313,648.369141 
	C206.965607,647.759094 206.763916,647.149048 206.152039,645.298340 
	C204.403717,648.267517 203.296722,650.548340 201.796631,652.531921 
	C201.005508,653.578003 199.488525,654.798828 198.368958,654.734131 
	C195.283981,654.556030 192.254639,653.459534 189.168015,653.252441 
	C185.528046,653.008240 181.849258,653.410339 178.190887,653.341614 
	C176.565857,653.311157 173.964905,653.326172 173.502243,652.437683 
	C171.640396,648.862305 170.366501,644.968079 169.020096,641.140747 
	C167.933029,638.050659 167.053604,634.887573 166.390228,631.437622 
	C169.830048,634.779907 171.037048,634.611267 172.802231,630.244141 
	C173.233551,629.177002 173.604126,628.085266 174.431076,627.005249 
	C179.141983,627.337708 183.428757,627.991882 187.706024,627.934814 
	C196.653442,627.815430 205.595917,627.322754 214.540146,626.976685 
	C215.037216,626.957458 215.538956,626.913086 216.026642,626.818848 
	C223.119080,625.447693 230.146149,625.193970 237.278519,628.254761 
	C237.981155,632.474426 238.407104,636.441711 238.462112,640.379211 
	C237.373474,640.156555 236.655792,639.963745 235.630035,639.527100 
	C231.915466,639.400024 228.508972,639.516785 224.707031,639.765259 
	C217.596756,640.697327 211.972641,643.704773 207.167313,648.369141 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M804.822815,647.217529 
	C804.098267,646.300781 803.471558,645.029846 803.130188,643.357727 
	C803.753357,642.532776 804.091125,642.109070 804.428894,641.685364 
	C803.808228,641.976868 803.187500,642.268311 802.566833,642.559753 
	C796.167419,639.453613 792.275574,640.756042 788.712036,647.341797 
	C788.106140,647.222839 787.558960,646.508850 787.464844,646.564331 
	C779.276428,651.384949 770.664001,648.708313 762.171509,648.050415 
	C761.680786,648.012451 761.187195,648.011108 760.117920,647.685669 
	C760.420837,642.427307 758.501282,640.400330 753.425049,641.216797 
	C751.674438,641.498474 749.815247,641.105164 748.047974,640.690552 
	C748.117126,638.788452 748.560791,637.066528 748.064514,635.681458 
	C747.452332,633.972900 746.057983,632.544556 745.267456,630.711731 
	C748.741821,630.015198 752.219666,629.873169 751.242065,624.723877 
	C755.467896,625.500793 759.445801,626.566895 763.431946,627.601074 
	C765.343323,628.096924 767.249573,628.797058 769.193848,628.949585 
	C777.587952,629.608582 785.792786,629.134033 793.388611,624.045654 
	C797.476196,624.831177 800.612854,625.773376 802.640869,629.829712 
	C804.341125,633.230347 807.614502,632.112305 808.997559,628.009766 
	C813.011353,632.709106 818.062561,636.910522 820.647461,642.295776 
	C822.182739,645.494324 819.833191,650.557617 819.100403,655.693176 
	C813.686340,653.118530 808.714844,652.195862 806.857971,646.701904 
	C806.808105,646.554321 805.592590,646.800720 804.822815,647.217529 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M114.991089,522.924927 
	C106.578575,523.139709 98.166946,523.454651 89.753181,523.529541 
	C85.139534,523.570557 80.522293,523.206543 75.467300,522.617310 
	C74.414818,521.754883 73.801704,521.300415 73.188591,520.845947 
	C73.133865,511.608673 72.905922,502.364990 73.246475,493.142334 
	C73.288437,492.005890 76.289009,490.243042 78.049858,490.064362 
	C81.348106,489.729614 82.200600,488.348175 82.185692,485.276581 
	C82.089516,465.457520 82.084564,445.637543 82.179863,425.818481 
	C82.193413,423.000122 81.369934,421.970123 78.519356,421.144562 
	C76.246964,420.486450 72.919998,417.990875 72.837135,416.183685 
	C72.442535,407.577667 72.985161,398.927826 73.252907,390.295410 
	C73.264679,389.915924 73.850945,389.554230 74.805176,389.067932 
	C75.994591,388.524506 76.548416,388.097382 77.102234,387.670258 
	C89.361588,387.512115 101.621101,387.220154 113.880241,387.235474 
	C124.981682,387.249329 136.082703,387.598389 147.692383,388.168091 
	C148.456345,389.043579 148.711838,389.550659 148.673401,390.181610 
	C147.253799,390.835938 146.128098,391.366425 144.912354,391.507751 
	C144.303757,390.763855 143.787369,390.103333 143.266373,390.099670 
	C133.450470,390.030670 123.626572,389.823761 113.822212,390.162750 
	C110.861015,390.265137 107.953712,391.926605 104.552788,392.905060 
	C97.939560,392.949585 91.794121,392.893799 85.651299,392.995544 
	C79.380409,393.099335 77.885902,394.529266 78.046455,400.646698 
	C78.163147,405.092896 78.713966,409.527771 79.052200,414.369934 
	C79.550934,415.481262 80.119797,416.838287 80.578789,416.802094 
	C87.397118,416.264282 87.361618,420.677032 87.024391,425.418976 
	C86.920067,426.885895 86.998917,428.365845 86.628464,429.936737 
	C82.912682,434.940857 83.057587,436.828735 86.981758,439.720123 
	C86.991516,444.328827 86.991928,448.493011 86.601204,452.833710 
	C85.788864,453.842712 85.010704,454.671234 85.001511,455.508179 
	C84.906586,464.149750 84.856903,472.794159 85.016853,481.433655 
	C85.040474,482.709808 86.219589,483.964569 86.892616,485.661316 
	C86.932381,488.683777 86.943634,491.274109 86.540039,493.901184 
	C84.500404,494.260834 82.849945,494.949768 81.255692,494.837891 
	C76.539612,494.506958 75.414436,497.033234 75.952820,501.000275 
	C76.547729,505.383789 77.597168,509.732880 77.826881,514.129456 
	C77.988670,517.226196 79.015198,518.123718 81.982903,518.068604 
	C89.641502,517.926270 97.304993,518.047180 105.253265,518.338135 
	C108.005798,518.880493 110.471603,519.147583 113.018570,519.707520 
	C113.730194,520.975159 114.360641,521.950073 114.991089,522.924927 
z"/>
<path fill="#6D48B3" opacity="1.000000" stroke="none" 
	d="
M748.005615,641.020142 
	C749.815247,641.105164 751.674438,641.498474 753.425049,641.216797 
	C758.501282,640.400330 760.420837,642.427307 759.677368,647.678040 
	C759.751648,648.321533 759.689392,648.665710 759.246094,649.373901 
	C750.736816,649.738037 742.608459,649.738037 734.038818,649.738037 
	C734.253845,652.539429 734.388855,654.298523 734.523926,656.057617 
	C734.449219,661.435791 734.374512,666.814026 734.251587,673.097778 
	C734.288025,684.964294 734.372681,695.925354 734.457275,706.886353 
	C734.399719,707.383484 734.342163,707.880676 734.242920,709.177246 
	C734.229370,713.294312 734.257507,716.611938 734.285706,719.929565 
	C731.469299,720.378662 728.652893,720.827759 724.881470,721.429138 
	C724.881470,699.280884 724.881470,678.070862 724.921021,655.977722 
	C724.960632,653.517212 724.960632,651.939880 724.960632,649.768677 
	C716.252563,649.768677 708.219482,649.768677 699.819702,649.768677 
	C699.976318,646.598145 700.101624,644.061829 700.252014,641.016174 
	C715.552551,641.016174 730.273865,641.016174 745.367432,641.095215 
	C746.494934,641.122925 747.250305,641.071533 748.005615,641.020142 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M335.118591,648.897339 
	C334.613708,646.725403 334.571991,644.594604 334.811279,641.705566 
	C338.214447,638.997864 339.513275,636.294739 339.052948,632.550354 
	C338.747650,630.067261 338.984711,627.517456 339.316620,624.950623 
	C341.436798,622.939819 343.219330,620.976013 345.424805,619.019470 
	C351.572906,619.351990 357.313477,619.514221 363.018463,620.053223 
	C367.712097,620.496643 371.769714,619.606995 375.338562,616.189941 
	C376.109467,617.380127 377.028961,618.446655 376.909363,619.380066 
	C376.240570,624.600830 378.905029,625.135437 382.958984,623.988220 
	C382.964325,629.419006 382.969666,634.849731 382.975494,640.702271 
	C372.663025,641.124084 362.350067,641.124084 351.037506,641.124084 
	C351.037506,666.307312 351.037506,691.181030 350.917664,716.506592 
	C350.528595,715.206787 350.029510,713.455750 350.025085,711.703491 
	C349.970551,690.135498 349.988281,668.567322 349.987366,646.999207 
	C349.987305,645.365173 349.987366,643.731079 349.987366,642.097046 
	C349.702698,642.091370 349.417999,642.085632 349.133331,642.079956 
	C349.133331,644.427002 349.133331,646.774048 349.133331,649.534790 
	C344.274200,649.320984 339.927979,649.129761 335.118591,648.897339 
z"/>
<path fill="#683FB6" opacity="1.000000" stroke="none" 
	d="
M231.188934,676.921265 
	C231.602905,676.941406 232.016876,676.961548 232.793701,677.415771 
	C233.910492,678.449402 234.610092,679.477600 235.426910,679.581177 
	C242.197845,680.439514 246.920273,684.923340 251.713531,688.994873 
	C258.554077,694.805359 258.766296,703.166992 253.581940,710.278503 
	C247.930084,718.031372 240.065430,720.680603 231.190109,720.868713 
	C226.676147,720.964355 221.569229,720.770813 217.736282,718.775574 
	C212.086136,715.834412 206.134659,712.457886 203.432419,705.909729 
	C202.952927,704.747803 202.254517,703.512207 202.335617,702.358337 
	C202.371368,701.849609 204.253281,700.925598 205.030197,701.143127 
	C206.161926,701.459961 207.340729,702.453308 207.996658,703.479553 
	C210.934219,708.075684 213.420593,713.131470 220.076965,712.945374 
	C220.076965,712.945374 220.012939,712.989807 220.174622,713.294312 
	C227.467010,718.663208 241.099762,716.769714 247.693314,709.799133 
	C252.471863,704.747375 250.610077,692.195862 244.304535,689.344543 
	C236.066360,685.619385 227.577042,682.450500 219.204590,679.020874 
	C216.081512,677.741577 212.983154,676.401917 209.873535,675.089783 
	C209.873535,675.089783 209.966049,675.026428 209.966156,674.690918 
	C209.482254,672.964172 209.342285,671.285278 208.458466,670.228394 
	C202.642044,663.272949 204.464645,656.292542 209.261627,650.250610 
	C212.978714,645.568726 218.091446,642.046509 224.758087,641.949707 
	C229.496262,641.880981 234.228256,641.385071 239.255173,641.044312 
	C240.076218,641.222351 240.605087,641.435791 241.129593,642.022339 
	C243.869965,643.793152 246.614700,645.190918 249.359436,646.588684 
	C249.359436,646.588684 249.268250,646.757690 249.229004,647.068604 
	C251.142929,650.809204 253.096100,654.238892 255.049286,657.668518 
	C254.820023,658.010254 254.590759,658.351929 254.361511,658.693604 
	C252.251450,657.806335 249.911926,657.245789 248.081573,655.960205 
	C244.805832,653.659546 242.090332,650.500122 238.686691,648.455200 
	C231.607620,644.202026 224.374619,645.042358 217.541641,649.300964 
	C211.748260,652.911560 209.955154,657.983337 211.926147,664.201965 
	C214.030045,670.839966 217.558304,673.714661 223.366135,673.522949 
	C223.366135,673.522949 223.423065,673.552979 223.331177,673.908325 
	C224.908524,678.082825 228.079758,677.409241 231.188934,676.921265 
z"/>
<path fill="#21529F" opacity="1.000000" stroke="none" 
	d="
M833.000000,824.140015 
	C841.609802,824.141052 849.719604,824.141052 857.689880,824.141052 
	C859.195007,831.718689 859.113159,831.818909 852.299438,831.825256 
	C848.305054,831.828979 844.290833,832.102173 840.321777,831.791626 
	C836.314880,831.478088 836.148193,833.663574 836.156372,836.669312 
	C836.205566,854.810364 836.147583,872.951721 836.125671,891.093018 
	C836.124512,892.064392 836.125488,893.035767 836.125488,894.418152 
	C833.315857,894.418152 830.805176,894.418152 827.818359,894.418152 
	C827.818359,873.599487 827.818359,853.050110 827.818359,831.934021 
	C820.304749,831.934021 813.243042,831.934021 805.856995,831.934021 
	C805.856995,829.141602 805.856995,826.923401 805.856995,824.138916 
	C814.947815,824.138916 823.723938,824.138916 833.000000,824.140015 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M825.003235,561.000000 
	C824.994141,555.338867 825.080200,549.675415 824.944397,544.017273 
	C824.844421,539.852051 826.530823,537.934753 830.835815,537.940918 
	C869.631592,537.996338 908.427551,537.950073 947.223450,537.980713 
	C952.352600,537.984802 953.835754,539.529053 953.972839,545.060852 
	C954.113098,550.718201 953.981079,556.382202 953.564087,562.523804 
	C952.104370,564.018799 950.639282,564.849609 950.083618,566.088440 
	C949.106750,568.266357 948.686157,570.693726 947.738403,572.742310 
	C947.299438,569.982666 947.154602,567.497437 947.059937,564.598389 
	C947.724609,559.439575 948.383362,554.699890 948.942017,549.948303 
	C949.648132,543.942993 948.776794,542.992859 942.634521,542.991333 
	C909.484131,542.982849 876.333374,542.898315 843.183533,543.027161 
	C827.645996,543.087524 830.198730,540.465149 830.096863,556.619324 
	C830.087585,558.085144 831.322083,559.558899 831.983765,561.432983 
	C833.663086,565.560059 835.337646,569.283142 837.002563,573.269287 
	C836.983093,573.707458 836.973328,573.882629 836.618652,574.009521 
	C832.537415,571.295349 828.800964,568.629456 825.064575,565.963562 
	C825.044250,565.223755 825.023926,564.483948 825.303345,563.333130 
	C825.919434,562.514648 826.235779,562.107239 826.552124,561.699829 
	C826.035828,561.466553 825.519531,561.233276 825.003235,561.000000 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M866.968262,635.985107 
	C865.931030,636.863525 864.893738,637.741943 862.938782,639.397583 
	C860.367371,634.877136 857.905701,630.549561 854.782837,625.059631 
	C851.192261,625.059631 845.928162,624.776367 840.730225,625.237976 
	C839.235291,625.370789 837.727295,627.494934 836.582947,628.981445 
	C834.326965,631.911865 831.743469,633.795349 827.418945,633.000000 
	C826.991516,631.935242 826.990356,630.870544 826.992798,628.955688 
	C826.991821,625.401611 826.987366,622.697632 826.982910,619.993652 
	C827.447632,615.214294 831.547302,616.229187 834.148071,616.140991 
	C844.153259,615.801758 854.176758,616.003418 864.534973,616.003418 
	C862.113220,613.026306 859.556824,609.883606 857.406555,606.372314 
	C860.206482,606.339905 862.600220,606.676086 865.050415,607.356201 
	C866.937256,609.467163 869.204895,610.963684 870.501770,613.060608 
	C872.787354,616.756287 875.904419,618.144897 880.050415,618.410828 
	C880.666565,624.566406 881.882446,629.983887 886.825256,633.839478 
	C887.579163,634.427612 887.626099,635.922241 888.000000,636.997559 
	C885.912354,636.356384 883.824646,635.715271 881.224609,634.736206 
	C876.550842,629.939026 872.389404,625.479797 868.228027,621.020569 
	C867.808105,621.244263 867.388184,621.468018 866.968262,621.691772 
	C866.968262,626.456238 866.968262,631.220642 866.968262,635.985107 
z"/>
<path fill="#8F8E92" opacity="1.000000" stroke="none" 
	d="
M950.875732,522.860474 
	C947.053772,522.964844 943.231873,523.156372 939.409851,523.160095 
	C903.968140,523.195007 868.526489,523.200867 833.084778,523.199158 
	C831.424011,523.199036 829.763306,523.046631 827.587463,522.553223 
	C826.677612,521.181030 826.282837,520.220642 826.242798,519.103149 
	C827.364319,518.849915 828.131226,518.753784 829.313477,518.804504 
	C842.482117,519.259888 855.235352,519.568420 868.409668,519.923462 
	C875.607666,519.647461 882.382751,519.101074 889.161865,519.046326 
	C906.453918,518.906677 923.748657,519.073547 941.040405,518.912292 
	C944.994995,518.875427 949.822266,519.290344 949.794739,512.669312 
	C949.249695,511.841797 948.679688,511.325348 948.108643,510.376831 
	C948.065063,507.628296 948.022522,505.311798 948.275391,502.751770 
	C948.670959,497.011078 948.771118,491.513947 948.903625,485.562866 
	C948.289185,480.731171 947.642395,476.353485 947.033569,471.627869 
	C947.998779,469.087280 947.941406,467.574188 944.434265,468.146118 
	C940.811707,468.150177 937.633911,468.311096 934.491211,468.050110 
	C932.563599,467.890045 930.689697,467.083618 928.791687,466.567474 
	C935.566833,466.466919 942.363403,466.043976 949.102295,466.490479 
	C950.806091,466.603333 953.698975,469.475128 953.718201,471.106506 
	C953.910950,487.506104 953.618164,503.911469 952.916809,520.700195 
	C951.872925,521.676880 951.374329,522.268677 950.875732,522.860474 
z"/>
<path fill="#DBECF1" opacity="1.000000" stroke="none" 
	d="
M453.005127,145.334717 
	C454.348450,144.648010 456.231445,144.741638 456.947693,143.873093 
	C466.194916,132.659927 475.191925,121.240540 484.426086,110.016350 
	C491.571503,101.331047 499.182281,93.018959 506.089874,84.154205 
	C507.858856,81.883995 509.007874,77.673973 508.089355,75.244537 
	C507.400970,73.423790 502.961212,72.211708 500.155701,72.140205 
	C487.519165,71.818153 474.851379,71.599312 462.233276,72.179108 
	C456.470734,72.443901 451.376160,70.915642 446.060699,69.556129 
	C442.603882,68.671989 440.598389,66.623108 440.401428,62.682846 
	C442.535400,62.340073 444.249878,62.463921 445.979675,62.925812 
	C448.640472,66.876335 452.309357,68.048149 456.645599,68.031921 
	C472.436920,67.972832 488.229553,67.930717 504.019440,68.095947 
	C507.099426,68.128181 510.291992,68.760727 513.208984,69.765663 
	C516.037109,70.739975 518.273804,72.472206 515.621704,76.094223 
	C513.016846,79.651764 510.821686,83.508385 508.426086,87.220459 
	C506.305878,90.505882 504.157288,93.772995 501.786804,97.220413 
	C501.387207,97.940331 501.221985,98.488037 500.675903,99.026344 
	C499.552155,100.030128 498.809265,101.043312 498.033478,102.029907 
	C498.000580,102.003319 498.059570,102.064644 497.706360,102.069138 
	C492.595856,108.086319 487.838593,114.098999 482.974457,120.320831 
	C482.677612,120.790916 482.432434,120.980431 481.770355,121.091721 
	C477.278992,126.406090 473.149353,131.727264 468.774902,137.219543 
	C468.028748,138.258972 467.527435,139.127289 466.671753,140.021622 
	C464.545319,142.706909 462.773285,145.366196 460.705872,148.191559 
	C458.246429,150.253098 456.082306,152.148560 453.743103,153.749054 
	C453.213684,152.378845 452.859314,151.303635 452.813049,150.058777 
	C453.085724,148.926239 453.050354,147.963333 453.014954,147.000412 
	C453.010803,146.556229 453.006653,146.112030 453.005127,145.334717 
z"/>
<path fill="#E9F2F6" opacity="1.000000" stroke="none" 
	d="
M560.965393,93.975067 
	C560.976685,95.048973 560.987976,96.122864 561.000854,97.599594 
	C559.469299,100.766014 558.053955,103.603600 556.375122,106.275597 
	C553.936829,110.156326 551.553711,114.115295 548.688110,117.671043 
	C546.106750,120.874107 542.796082,123.478264 540.022400,126.541595 
	C538.309021,128.434006 536.992493,130.687012 535.509766,132.786606 
	C534.745178,133.869186 534.203125,135.667236 533.222839,135.943390 
	C528.781738,137.194427 528.744995,140.626587 527.625366,143.360077 
	C523.166809,149.154602 518.708313,154.949142 513.979065,161.095535 
	C527.315125,165.623947 540.622437,163.824432 554.129639,163.660233 
	C554.779846,164.521072 555.708252,165.750198 557.023132,167.066864 
	C557.279114,167.830475 557.148621,168.506561 556.781616,169.545151 
	C556.545166,169.907654 556.162842,170.085007 555.880493,170.068573 
	C555.410767,170.072662 555.223389,170.093201 554.655029,169.930145 
	C552.492065,169.506287 550.711243,169.067932 548.927979,169.057846 
	C537.462219,168.992935 525.991028,169.202087 514.533447,168.889069 
	C511.777435,168.813782 508.947113,167.336624 506.407135,166.012650 
	C505.653381,165.619751 505.094086,163.102875 505.606964,162.321609 
	C508.444977,157.998444 511.522125,153.815399 514.749084,149.769165 
	C517.973633,145.725952 521.570251,141.980652 524.824463,137.959595 
	C529.151794,132.612564 533.256165,127.085732 537.546509,121.708099 
	C542.405518,115.617722 547.304871,109.557266 552.303162,103.581200 
	C555.067444,100.276184 558.070129,97.170570 560.965393,93.975067 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M744.995178,641.016174 
	C730.273865,641.016174 715.552551,641.016174 700.252014,641.016174 
	C700.101624,644.061829 699.976318,646.598145 699.819702,649.768677 
	C708.219482,649.768677 716.252563,649.768677 724.960632,649.768677 
	C724.960632,651.939880 724.960632,653.517212 724.830078,655.513062 
	C724.301086,654.529907 723.902710,653.128418 723.299011,651.004517 
	C714.087097,651.721497 704.582397,649.325012 694.003662,653.129700 
	C694.003662,645.528381 694.003662,638.231750 694.421387,630.466980 
	C696.729797,629.637756 699.688416,629.905273 700.332886,628.810547 
	C703.770203,622.971313 709.190857,623.828796 714.385437,624.061646 
	C717.805542,624.214844 722.570862,623.737244 724.313538,625.695801 
	C727.734070,629.539856 730.447998,628.992371 734.258423,627.245850 
	C734.346985,629.264526 734.069702,631.036438 734.021606,632.814636 
	C733.857666,638.877258 734.159973,639.001770 740.185486,639.106079 
	C741.799255,639.133972 743.392517,640.347168 744.995178,641.016174 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M635.992676,387.265015 
	C639.528564,387.624573 643.064453,387.984100 646.837463,388.947083 
	C647.596436,390.116028 648.118286,390.681488 648.640137,391.246918 
	C657.484558,419.504974 666.332703,447.761902 675.166504,476.023254 
	C675.855835,478.228363 676.445496,480.464600 677.398926,483.292175 
	C677.863525,484.544128 678.010681,485.190308 678.224609,486.168091 
	C679.520386,487.305573 680.749268,488.111389 681.758972,489.196655 
	C679.345154,489.235443 677.150635,488.994812 674.847839,488.500061 
	C674.154663,487.779877 673.569702,487.313721 672.983276,486.466187 
	C672.315918,483.088898 671.650024,480.093018 671.006592,476.655701 
	C671.026184,474.892914 671.471375,473.340149 670.932068,472.295776 
	C669.588684,469.694336 670.644226,465.374969 666.016479,464.974304 
	C666.000122,464.982635 666.031677,464.980957 666.033630,464.671326 
	C665.698364,463.873779 665.361206,463.385834 665.010864,462.512878 
	C664.006042,458.104370 663.014465,454.080780 662.298950,449.807770 
	C662.755737,444.739807 661.823914,440.451172 657.029419,437.722534 
	C656.969116,437.283844 656.942749,437.108704 656.939697,436.545898 
	C656.309448,433.454315 655.655945,430.750427 655.147705,427.738220 
	C655.738281,426.419312 656.766052,425.241943 656.532349,424.425873 
	C655.224792,419.860626 653.827209,415.292419 651.991333,410.922302 
	C651.319641,409.323517 649.360596,408.265625 647.831787,406.617432 
	C647.424194,404.540070 647.173706,402.806274 647.212646,400.826691 
	C648.875244,396.232910 647.113525,393.322540 642.981812,392.197296 
	C639.040466,391.123901 634.932922,390.132141 630.884399,390.073151 
	C614.608948,389.835907 598.327637,390.025970 582.049805,389.896515 
	C580.365540,389.883118 578.689758,388.794373 577.036011,387.980957 
	C577.061890,387.757355 577.065796,387.307159 577.065796,387.307190 
	C595.402710,387.289917 613.739685,387.272644 632.748047,387.468811 
	C634.277161,387.543182 635.134949,387.404083 635.992676,387.265015 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M454.757233,522.729797 
	C449.814514,522.868530 444.872040,523.113464 439.928986,523.125854 
	C425.992065,523.160950 412.053772,522.994202 398.118439,523.138428 
	C393.544159,523.185669 391.807526,521.029236 391.777954,516.858582 
	C391.740356,511.550476 391.746857,506.242035 391.744751,500.113037 
	C392.320862,497.156281 392.886566,495.020172 393.622925,493.217377 
	C394.209045,494.727478 394.624542,495.904327 395.153564,497.389343 
	C395.474335,499.710907 395.681549,501.724213 395.481873,503.863770 
	C394.750275,508.244324 394.334198,512.496399 394.230255,516.756165 
	C394.218811,517.225159 395.996155,517.737854 397.283386,518.474365 
	C412.082245,518.789001 426.541565,518.859497 441.441772,518.953735 
	C444.936066,518.632812 447.989471,518.288086 451.451660,517.995972 
	C455.816376,518.681885 458.063202,517.246765 458.018921,512.525818 
	C458.046478,507.228271 458.204163,502.387085 457.926636,497.571014 
	C457.836853,496.013092 456.605072,494.520966 455.838806,492.614685 
	C453.963074,491.753174 452.136292,491.303741 450.327271,490.791595 
	C447.661316,490.036835 443.915833,490.624969 444.492371,485.689941 
	C444.721985,480.834839 444.721985,476.315979 444.721985,472.159058 
	C446.730865,471.185516 448.006805,470.567139 449.608643,470.016418 
	C451.925415,472.658691 453.916229,475.233337 455.799927,478.119690 
	C455.064270,479.326843 454.435730,480.222321 453.471863,481.054565 
	C451.750244,479.649323 450.363983,478.307220 448.628876,476.627380 
	C446.997467,484.228851 447.560547,489.120880 455.693359,487.425293 
	C455.424408,486.012115 455.154663,484.594818 455.211182,483.276245 
	C456.300262,484.423431 457.127594,485.431427 457.815125,486.527191 
	C461.495728,492.393402 465.141449,498.281433 468.799225,504.161957 
	C467.176605,502.717316 465.554016,501.272644 463.087067,499.076233 
	C462.235901,507.044586 463.596802,514.916016 456.778900,520.667847 
	C455.762238,521.559875 455.259735,522.144836 454.757233,522.729797 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M677.829346,350.564026 
	C677.415710,349.301331 677.005615,347.693024 677.005249,346.084595 
	C676.979248,240.611816 676.986267,135.139038 677.076172,29.183983 
	C677.483154,30.259649 678.084900,31.817474 678.085571,33.375549 
	C678.129333,138.491776 678.124939,243.608002 678.120239,348.724243 
	C678.120178,349.222290 678.057556,349.720306 677.829346,350.564026 
z"/>
<path fill="#21529F" opacity="1.000000" stroke="none" 
	d="
M312.486877,870.000122 
	C312.486633,878.298401 312.486633,886.096741 312.486633,893.830017 
	C305.148010,896.269409 304.096100,895.567505 304.096558,888.465027 
	C304.097778,869.344238 304.097504,850.223389 304.085480,831.102600 
	C304.080658,823.430908 304.236603,823.292786 312.487122,824.878723 
	C312.487122,839.673950 312.487122,854.587036 312.486877,870.000122 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M733.997559,627.001953 
	C730.447998,628.992371 727.734070,629.539856 724.313538,625.695801 
	C722.570862,623.737244 717.805542,624.214844 714.385437,624.061646 
	C709.190857,623.828796 703.770203,622.971313 700.332886,628.810547 
	C699.688416,629.905273 696.729797,629.637756 694.426880,629.998230 
	C694.006653,627.348022 694.261658,624.665222 693.932373,622.056274 
	C693.362427,617.540161 695.789856,616.071777 699.619568,616.043213 
	C709.835388,615.966980 720.052185,616.017273 730.863342,616.017273 
	C728.662354,612.847168 726.492371,609.721619 724.601440,606.290161 
	C727.252930,606.323669 729.625244,606.663025 732.159424,607.311462 
	C734.547974,609.747864 736.774658,611.875122 739.192505,614.272705 
	C741.830383,617.168274 744.181824,619.892944 746.766907,622.374390 
	C747.938049,623.498596 749.572998,624.139709 750.997559,625.000000 
	C752.219666,629.873169 748.741821,630.015198 744.964111,630.564697 
	C741.530396,627.612549 738.665833,624.523987 735.801270,621.435425 
	C735.272278,621.732849 734.743347,622.030334 734.214355,622.327759 
	C734.142090,623.885864 734.069824,625.443909 733.997559,627.001953 
z"/>
<path fill="#E1EEF5" opacity="1.000000" stroke="none" 
	d="
M305.008392,610.021973 
	C304.996765,614.230347 305.113007,618.446472 304.860962,622.640381 
	C304.813080,623.437622 302.782959,624.934753 302.552094,624.780945 
	C297.935181,621.705200 294.016083,625.186829 290.050262,626.278259 
	C286.354645,627.295349 283.127716,628.287903 280.094452,624.954895 
	C278.123627,626.490417 276.372772,627.854614 274.100800,629.624756 
	C272.959320,624.624695 269.678070,623.824585 265.426086,623.997070 
	C265.009369,620.962585 265.009583,617.930054 265.408325,614.454224 
	C268.380951,613.973389 271.895905,617.295715 273.380859,611.993164 
	C277.246399,610.995422 280.707397,609.263245 284.214020,609.165283 
	C291.132111,608.971985 298.075470,609.684204 305.008392,610.021973 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M948.032471,573.016663 
	C948.686157,570.693726 949.106750,568.266357 950.083618,566.088440 
	C950.639282,564.849609 952.104370,564.018799 953.541260,562.990356 
	C953.945557,568.669373 953.968445,574.362305 953.583740,580.533936 
	C952.119141,582.368713 950.161072,583.732788 950.173035,585.079468 
	C950.209656,589.215088 948.307434,590.169128 944.639893,590.055054 
	C938.502808,589.864075 932.355469,590.001099 926.212402,590.002258 
	C918.127747,590.003723 915.540344,588.179077 913.376892,580.453796 
	C912.854919,578.589905 913.206177,576.497925 912.956177,574.536255 
	C912.751587,572.931213 913.066650,570.399658 909.536865,572.002258 
	C905.051331,572.006531 901.028931,572.008484 896.803589,571.774414 
	C896.087708,571.935120 895.574707,572.331909 895.061646,572.728638 
	C895.705017,573.486572 896.348389,574.244507 897.012329,575.229614 
	C897.032837,575.456848 897.065979,575.911926 897.079346,576.274292 
	C898.572388,579.172729 896.132324,582.314941 899.467285,585.079285 
	C902.546631,587.631775 903.891846,592.276428 905.698547,595.853394 
	C902.265808,593.138184 899.135315,590.569092 895.968750,587.649780 
	C893.949341,583.867981 891.966125,580.436401 889.846802,576.681946 
	C888.281433,573.649658 886.852173,570.940369 884.771362,566.995972 
	C893.144714,566.995972 899.746277,566.995850 906.347778,566.996033 
	C916.372559,566.996277 916.096252,567.026306 917.186035,577.102417 
	C917.492432,579.934814 919.330750,583.999512 921.541931,584.922607 
	C925.540283,586.591614 930.336609,586.514832 934.822693,586.783447 
	C937.247681,586.928711 939.741821,586.394714 942.160645,585.935181 
	C943.132812,585.750488 944.549744,585.133057 944.799744,584.388672 
	C946.052856,580.656921 946.986511,576.817932 948.032471,573.016663 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M610.182007,559.370239 
	C611.606812,557.662903 612.954895,556.396667 614.656372,555.521606 
	C615.011414,557.078735 615.011963,558.244690 615.014893,559.410645 
	C615.032593,566.521545 615.503357,566.982727 622.768188,566.989746 
	C625.976990,566.992859 629.185791,566.990234 631.927979,566.990234 
	C631.555237,570.259766 631.284912,572.631104 630.855957,575.305298 
	C626.280090,579.742249 621.219727,581.715759 615.024719,581.079468 
	C610.936462,580.659546 606.769531,581.041565 602.638794,580.988220 
	C598.561829,580.935547 593.976624,582.307190 591.697693,577.009033 
	C591.436707,576.402222 589.940552,576.326721 589.012207,575.549255 
	C588.996216,567.868286 588.994019,567.850647 596.129761,566.960999 
	C600.106018,566.465210 602.942078,565.166321 602.080994,560.243164 
	C601.850769,558.926819 602.657715,557.429077 603.346436,556.046326 
	C605.075134,556.368652 606.445862,556.659363 607.816650,556.950012 
	C608.426270,568.744324 608.426270,568.744324 596.454346,573.507629 
	C606.531128,573.507629 615.406250,573.507629 624.281372,573.507629 
	C624.335754,573.154968 624.390198,572.802307 624.444641,572.449646 
	C622.868591,572.301025 621.293945,572.041748 619.716370,572.022644 
	C611.989807,571.928894 610.072144,570.029358 610.046204,562.295349 
	C610.043457,561.467407 610.084534,560.639282 610.182007,559.370239 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M449.002747,387.954559 
	C450.685333,388.961670 452.367950,389.968750 454.412598,391.450195 
	C463.992157,405.635345 473.157654,419.381775 482.490448,433.013611 
	C483.711060,434.796448 485.790771,435.991089 487.675598,437.704224 
	C487.562134,439.136749 486.842285,440.384125 487.010437,441.497437 
	C487.398285,444.065521 488.002869,446.658081 488.998169,449.040771 
	C489.278931,449.712952 491.215851,449.693359 492.627991,450.239319 
	C493.011292,451.605072 493.159760,452.722626 493.218567,454.211548 
	C492.124481,455.351105 491.120056,456.119324 490.087036,456.530853 
	C487.779846,452.395721 485.639465,448.525391 483.199097,444.854492 
	C471.583832,427.382416 459.887024,409.964569 448.412750,392.816620 
	C435.038269,392.816620 422.065552,392.816620 409.066406,392.444397 
	C409.035980,391.077301 409.031952,390.082397 409.492981,389.053986 
	C417.634125,389.033600 425.310272,389.046692 433.035980,389.388092 
	C434.061432,389.497192 435.037292,389.278046 436.439087,389.037598 
	C438.570557,389.036285 440.276093,389.056274 442.064392,389.444641 
	C444.644440,391.514923 447.036499,392.200531 449.055695,388.812439 
	C449.050934,388.335144 449.026855,388.144836 449.002747,387.954559 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M816.987793,576.010864 
	C818.057739,576.006714 819.127686,576.002625 820.598816,575.999268 
	C821.008301,579.965210 821.037354,583.930481 821.019958,587.895630 
	C820.993835,593.850830 819.870911,594.958618 813.675964,594.979980 
	C803.762451,595.014160 793.848755,594.993225 783.167480,595.033936 
	C782.079712,595.479614 781.759583,595.886902 781.439392,596.294189 
	C781.953979,596.532227 782.468628,596.770264 782.983276,597.008301 
	C783.967468,598.113159 784.951599,599.218018 786.009766,600.974121 
	C786.717407,602.086609 787.350952,602.547913 787.984558,603.009216 
	C788.307251,603.112793 788.569092,603.301025 788.871704,604.166626 
	C787.649658,606.175293 786.326050,607.591309 784.665039,608.955261 
	C780.884338,605.604553 777.440979,602.305969 773.958862,598.638428 
	C772.280151,595.847168 770.640076,593.424805 768.993530,590.611511 
	C767.664185,585.468994 766.341248,580.717468 765.365112,576.014099 
	C769.706116,580.733643 773.700317,585.404968 777.694458,590.076355 
	C778.157410,589.817017 778.620300,589.557617 779.083252,589.298218 
	C779.083252,585.524048 779.083252,581.749817 779.450500,577.981506 
	C781.213440,578.329773 782.609192,578.672180 784.005249,579.449951 
	C784.007019,581.916565 784.008362,583.947754 784.017517,586.376587 
	C784.859253,587.538818 785.632141,588.875854 786.537476,588.972412 
	C791.341614,589.485168 796.179016,589.686523 801.435059,589.996826 
	C805.294861,589.651062 808.840881,589.716309 812.107849,588.793518 
	C813.896240,588.288330 816.035889,586.360107 816.552429,584.635071 
	C817.354309,581.956970 816.906067,578.904602 816.987793,576.010864 
z"/>
<path fill="#E1EEF5" opacity="1.000000" stroke="none" 
	d="
M601.997559,620.002441 
	C601.042053,620.180359 599.618896,620.018616 599.204163,620.589417 
	C595.546387,625.623962 591.151489,623.454346 586.801758,622.084900 
	C585.709961,621.741150 584.014221,620.825867 583.591431,621.225708 
	C578.433472,626.104004 574.249207,621.495422 569.381714,620.007690 
	C567.040588,613.930237 565.076111,607.858032 563.448181,601.402893 
	C565.523926,602.349060 567.263184,603.678223 569.007996,605.391418 
	C569.759949,610.372620 572.970337,609.675781 575.105042,607.755249 
	C578.346069,604.839417 581.893616,604.957458 585.660400,604.991516 
	C590.105103,605.031738 594.550293,605.008118 599.003845,605.406616 
	C600.007446,610.534851 601.002502,615.268677 601.997559,620.002441 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M372.040527,557.993347 
	C372.040527,557.993347 372.125336,558.472229 371.808563,558.748535 
	C370.660919,559.684082 369.830017,560.343384 368.625610,561.001953 
	C367.508240,560.999146 366.764404,560.997131 365.983154,560.641602 
	C363.030853,552.948059 357.330536,547.734192 350.672882,544.626282 
	C336.985382,538.236633 322.454590,538.924927 307.957336,542.109680 
	C297.989502,544.299316 290.039612,550.172791 281.750183,555.867676 
	C280.965546,554.815002 280.477631,553.898315 279.989746,552.981689 
	C284.017975,549.488464 287.539001,544.970703 292.163269,542.681458 
	C311.007721,533.352539 330.611206,531.362488 350.615326,539.053406 
	C357.825012,541.825317 364.047485,546.098938 368.848877,553.070557 
	C370.011902,555.149292 371.026215,556.571289 372.040527,557.993347 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M540.527466,520.132568 
	C535.900146,517.378357 534.408203,513.348022 535.001587,508.054657 
	C535.425415,504.274048 535.077026,500.407257 535.079773,496.578918 
	C535.085693,488.502777 537.328735,485.453430 545.118652,483.609680 
	C548.150208,482.892120 549.540405,481.780426 550.394592,478.337524 
	C552.517395,469.781403 555.771973,461.512634 558.360107,453.063568 
	C560.955994,444.589478 563.821777,436.137146 565.474487,427.466583 
	C566.089600,424.239227 562.980957,420.475006 562.666260,416.860229 
	C562.077515,410.096893 561.754822,403.207611 562.463562,396.491241 
	C562.703979,394.213043 566.388855,392.298370 568.834778,390.609619 
	C569.084839,391.942505 568.999817,392.883331 568.839905,394.150848 
	C568.060425,395.608307 567.355835,396.739105 566.306274,397.976440 
	C566.181091,400.430511 566.400940,402.778046 566.727356,405.494934 
	C566.964417,406.613129 567.094849,407.362030 567.125305,408.041809 
	C567.025269,407.972687 566.798645,407.884766 566.420105,407.992523 
	C566.258179,412.107025 566.474731,416.113800 566.885864,420.363098 
	C568.136108,421.212952 569.191895,421.820282 570.154419,422.593597 
	C570.061279,422.759552 570.098022,423.140900 570.021606,423.467896 
	C569.772705,426.649780 569.600220,429.504639 569.182007,432.620483 
	C568.048401,435.618958 567.160583,438.356384 566.110718,441.286682 
	C565.849670,441.615112 565.750671,441.750732 565.304565,441.991302 
	C564.305237,445.398346 563.652893,448.700409 562.794678,452.290619 
	C562.067688,453.748230 561.380127,454.873810 561.057800,456.095703 
	C560.205994,459.324371 559.647827,462.633881 558.723328,465.839264 
	C557.434387,470.308044 555.918640,474.711395 554.336609,479.487061 
	C554.072571,481.559113 553.971863,483.286896 553.497681,485.000854 
	C552.335754,485.837433 551.547241,486.687775 550.468079,487.640381 
	C549.799744,487.900299 549.421997,488.057922 548.727600,488.061432 
	C547.942566,487.895660 547.474243,487.884033 546.847534,487.661499 
	C546.126770,487.655579 545.564331,487.860565 544.626587,488.072144 
	C541.110168,488.232269 539.024292,489.494232 538.856506,493.117615 
	C538.823792,493.915924 539.012756,494.600464 539.289368,495.574768 
	C539.458923,496.259888 539.540833,496.655243 539.403687,497.375702 
	C539.098389,502.796783 539.012207,507.892792 538.941406,513.353699 
	C539.667664,515.437134 540.378601,517.155579 540.959534,519.193359 
	C540.728882,519.719299 540.628174,519.925964 540.527466,520.132568 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M906.000854,595.999634 
	C903.891846,592.276428 902.546631,587.631775 899.467285,585.079285 
	C896.132324,582.314941 898.572388,579.172729 897.417603,576.335938 
	C901.776550,580.369324 905.810608,584.703430 909.844666,589.037537 
	C910.441833,588.862305 911.039001,588.687073 911.636108,588.511780 
	C911.090759,583.007874 910.545349,577.503906 910.000000,572.000000 
	C913.066650,570.399658 912.751587,572.931213 912.956177,574.536255 
	C913.206177,576.497925 912.854919,578.589905 913.376892,580.453796 
	C915.540344,588.179077 918.127747,590.003723 926.212402,590.002258 
	C932.355469,590.001099 938.502808,589.864075 944.639893,590.055054 
	C948.307434,590.169128 950.209656,589.215088 950.173035,585.079468 
	C950.161072,583.732788 952.119141,582.368713 953.571472,581.001099 
	C953.975769,583.468262 953.864990,585.954407 954.019531,588.424011 
	C954.317505,593.185730 951.758240,594.963013 947.438171,594.973694 
	C938.348572,594.996277 929.258728,594.966125 920.169189,594.996033 
	C918.607178,595.001160 917.045837,595.196411 914.694580,595.358215 
	C916.501221,597.480469 917.702759,598.891968 918.709106,600.840088 
	C919.351501,602.243713 920.189087,603.110779 921.026611,603.977844 
	C921.654175,604.441406 922.281738,604.904968 922.691528,605.891602 
	C921.318298,607.612244 920.162842,608.809814 918.668823,609.858337 
	C916.088928,608.663086 912.414917,609.240906 912.955139,604.644470 
	C911.997681,603.248413 911.047791,602.231689 910.161926,601.161926 
	C908.752441,599.459778 907.385742,597.722168 906.000854,595.999634 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M468.979279,556.993530 
	C469.166779,556.442200 469.354279,555.890930 470.129364,555.125732 
	C471.460175,553.578979 472.203369,552.246155 472.946594,550.913330 
	C475.188690,547.265930 477.221588,543.460938 479.785828,540.056091 
	C480.689514,538.856201 482.886200,538.077759 484.504883,538.056641 
	C496.798828,537.896301 509.096191,538.023010 521.391724,537.944458 
	C525.396912,537.918884 527.167786,539.720215 527.126343,543.663269 
	C527.054749,550.475342 527.082092,557.288513 526.720337,564.508728 
	C525.449036,564.439514 524.525330,563.962769 522.994263,563.172485 
	C522.994263,567.942261 523.152283,572.058105 522.903015,576.149109 
	C522.822754,577.466370 521.669617,578.718201 521.004578,579.546265 
	C520.663818,575.393433 520.325806,571.694275 519.991455,567.557739 
	C520.000793,565.077942 520.006470,563.035461 520.406860,560.903198 
	C521.129517,555.331238 521.535583,549.850464 521.635681,544.364136 
	C521.643616,543.930420 519.529907,543.073608 518.396240,543.063110 
	C507.593811,542.962891 496.789032,542.929688 485.988068,543.090027 
	C484.538300,543.111511 482.392456,543.924561 481.787567,545.034912 
	C478.960236,550.225098 476.560150,555.648071 473.846313,561.346619 
	C473.452393,563.805237 473.219482,565.905090 472.794861,568.271362 
	C470.060120,570.360107 467.517029,572.182373 464.728394,573.786011 
	C466.058258,570.225281 467.558838,566.845093 469.234619,563.554138 
	C470.405029,561.255676 471.737885,559.081970 468.979279,556.993530 
z"/>
<path fill="#F8F9FA" opacity="1.000000" stroke="none" 
	d="
M393.452271,492.884094 
	C392.886566,495.020172 392.320862,497.156281 391.621368,499.663605 
	C390.217163,500.336731 388.946747,500.638641 387.328613,500.961304 
	C386.980896,500.982025 386.955688,500.958313 386.963867,500.557129 
	C387.140411,498.852814 386.948486,497.353973 387.533508,496.277191 
	C392.759186,486.658203 394.673309,476.225739 394.937317,465.481049 
	C395.202393,454.693695 394.990234,443.894836 395.006622,433.101044 
	C395.016418,426.666779 395.535431,426.166504 402.260803,426.379181 
	C402.500031,434.151794 402.668091,441.487213 402.637817,449.261475 
	C402.572662,460.745087 402.705841,471.789795 402.796997,483.208008 
	C402.456482,485.024597 402.157959,486.467773 401.527832,488.053864 
	C398.614899,489.759247 396.033600,491.321655 393.452271,492.884094 
z"/>
<path fill="#8F8E92" opacity="1.000000" stroke="none" 
	d="
M829.969177,387.732544 
	C868.092651,387.566559 906.216125,387.384613 944.339722,387.246796 
	C952.950500,387.215668 954.046204,388.384857 954.099365,397.083984 
	C954.140015,403.743866 954.161499,410.403870 953.895386,417.609558 
	C953.418884,418.774017 953.238647,419.392761 953.058411,420.011475 
	C952.563599,420.627777 952.068848,421.244110 951.248596,421.469696 
	C950.939941,413.922058 951.188171,406.753723 950.836670,399.614960 
	C950.726868,397.384705 948.998169,395.234192 947.624512,392.882690 
	C941.523376,392.491119 935.808289,392.264008 929.799744,391.744629 
	C926.444946,391.051849 923.387939,390.455383 920.319336,390.388184 
	C919.549194,390.371307 918.741943,392.053406 917.557983,392.980316 
	C916.113708,392.975189 915.063049,392.944458 913.929565,392.536133 
	C910.223022,391.438568 906.604919,390.130157 902.974792,390.095825 
	C882.319214,389.900421 861.660706,390.009155 840.560791,390.005280 
	C836.714111,389.644867 833.309814,389.293671 829.938477,388.640869 
	C829.970703,388.137054 829.969971,387.934784 829.969177,387.732544 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M607.866211,556.492554 
	C606.445862,556.659363 605.075134,556.368652 603.345093,555.665405 
	C602.971924,554.492126 602.957947,553.731384 602.954346,552.535156 
	C602.958557,543.011230 602.958557,543.004578 593.895874,542.999939 
	C580.574585,542.993103 567.253357,542.996887 553.932068,542.996155 
	C551.156738,542.996033 548.381409,542.996094 545.030823,542.996094 
	C545.030823,546.436829 545.030823,549.219238 545.024780,552.459839 
	C545.010864,556.290894 545.002991,559.663757 544.629395,563.022827 
	C542.868896,562.657898 541.474121,562.306763 540.079407,561.955566 
	C540.057983,556.306763 539.846802,550.648865 540.119507,545.014282 
	C540.213074,543.080322 541.516479,541.204834 542.898804,539.123779 
	C544.019531,538.638123 544.508545,538.331238 544.997559,538.024292 
	C563.235229,538.009583 581.475403,538.159668 599.708862,537.874817 
	C604.859619,537.794373 607.583862,539.361084 607.709839,544.616943 
	C607.801025,548.422424 607.848694,552.229065 607.866211,556.492554 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M706.002930,387.948181 
	C729.347656,387.797455 752.692383,387.646759 776.539917,388.005310 
	C778.277954,389.007050 779.488647,389.832520 780.752991,389.925049 
	C783.684387,390.139526 786.681702,389.720337 789.569824,390.133850 
	C790.817322,390.312531 791.860962,391.915131 792.529907,392.913147 
	C764.518921,392.966553 736.975098,393.005585 709.431702,392.916016 
	C707.868958,392.910919 706.309692,391.851166 704.909058,390.958252 
	C705.380615,389.738861 705.691772,388.843506 706.002930,387.948181 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M475.539581,398.240356 
	C475.375519,397.437653 475.211426,396.634918 475.265503,395.152374 
	C475.523468,393.271484 475.563263,392.070435 475.603058,390.869385 
	C475.914886,390.350647 476.226715,389.831879 477.052338,389.112366 
	C478.187500,388.415253 478.808838,387.918915 479.430206,387.422546 
	C495.632751,387.404602 511.835297,387.386627 528.807434,387.584900 
	C538.355591,388.468933 539.326843,389.361542 538.347656,396.081665 
	C536.668823,396.013855 535.300842,396.011536 533.656128,395.752502 
	C531.660217,394.681152 529.951294,393.186310 528.220032,393.160065 
	C514.951477,392.958771 501.675659,393.198883 488.408844,392.947418 
	C484.225311,392.868103 481.389709,393.939301 479.755981,397.913391 
	C478.159912,397.951904 476.849731,398.096130 475.539581,398.240356 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M611.318970,520.308044 
	C611.075378,519.682190 610.831848,519.056396 610.277466,518.065674 
	C609.776978,517.597534 609.587280,517.494202 609.384644,517.097900 
	C608.957947,516.401794 608.544128,515.998657 608.042725,515.249634 
	C605.258789,511.182709 605.264587,507.296509 607.122742,503.226013 
	C607.497437,502.405151 607.694702,501.362122 607.568970,500.479156 
	C607.112488,497.272308 606.483826,494.089996 606.095337,490.634277 
	C607.176453,489.895172 608.082275,489.420197 609.269165,489.245178 
	C613.920898,489.487610 618.291565,489.429993 623.886902,489.356232 
	C621.911133,486.191650 620.894165,484.562775 620.264526,482.896729 
	C622.437256,482.607574 624.222778,482.355560 626.346130,482.161621 
	C627.723816,483.478027 628.763733,484.736389 629.593750,486.228821 
	C630.054749,488.348480 630.725586,490.234131 631.261475,492.528992 
	C627.967041,493.309418 624.816040,493.852081 621.645874,494.008545 
	C617.640869,494.206207 615.131653,495.219147 615.574219,500.108734 
	C616.050659,505.371674 615.807861,510.699738 615.823364,516.316650 
	C615.685669,517.261780 615.603455,517.889709 615.200684,518.535522 
	C613.894165,518.771301 612.908325,518.989258 611.717224,519.317200 
	C611.311829,519.691162 611.247437,519.984802 611.318970,520.308044 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M391.914886,544.977356 
	C391.564178,540.962952 393.261139,538.336670 397.396271,538.336853 
	C416.347534,538.337708 435.298828,538.514587 454.629578,539.158081 
	C455.645905,540.465515 456.282684,541.243042 456.919495,542.020508 
	C456.951874,543.403442 456.984253,544.786377 456.662872,546.637939 
	C454.888763,548.411804 453.468384,549.717102 452.029510,550.581238 
	C451.958618,542.997742 451.958618,542.993347 444.846436,542.991272 
	C432.698029,542.987671 420.549591,542.990295 408.401184,542.990295 
	C397.117065,542.990295 397.117065,542.990234 396.571289,554.019409 
	C394.557892,553.657593 392.919312,553.299866 391.280762,552.942200 
	C391.247314,552.217529 391.213898,551.492798 391.549896,550.307861 
	C391.917877,548.224243 391.916382,546.600769 391.914886,544.977356 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M430.935730,193.072205 
	C430.935730,197.494263 430.935730,201.916321 430.935730,206.338379 
	C430.484894,206.479477 430.034027,206.620575 429.583191,206.761658 
	C428.138275,203.959610 426.498535,201.239487 425.279846,198.342285 
	C416.997772,178.653214 408.843506,158.910248 400.505951,139.244888 
	C399.861603,137.725159 398.130371,136.666245 396.383759,135.253128 
	C395.600891,135.017303 395.441498,134.830688 395.423889,134.452454 
	C395.540649,132.197952 395.992004,129.971985 395.593140,127.910538 
	C395.181763,125.784317 393.942963,123.818192 392.980316,121.088013 
	C392.303802,119.890770 391.713104,119.387543 391.122437,118.884308 
	C388.877686,112.826973 386.610779,106.777649 384.412323,100.703545 
	C384.086212,99.802567 384.083099,98.784691 384.195435,97.402748 
	C384.646973,96.975937 384.833679,96.967270 385.020386,96.958603 
	C386.331116,98.730186 387.641815,100.501770 388.985413,103.014359 
	C391.007385,107.841408 392.996521,111.927437 395.005554,116.325653 
	C395.054291,117.053955 395.083099,117.470062 395.075165,118.291351 
	C396.102997,122.111519 396.862579,125.660210 398.289795,128.916245 
	C402.450134,138.407578 406.786438,147.825302 411.247833,157.179306 
	C412.876465,160.593964 415.051392,163.748047 416.990906,167.258362 
	C417.004700,167.495697 417.020020,167.970932 417.009827,168.371399 
	C420.382874,176.272720 423.676117,183.817093 427.257568,191.222046 
	C427.731750,192.202423 429.673584,192.472931 430.935730,193.072205 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M409.027924,389.087494 
	C409.031952,390.082397 409.035980,391.077301 408.598633,392.503754 
	C404.427612,392.984314 400.698029,393.033356 396.529022,393.032837 
	C388.399231,392.752380 388.016235,393.114014 387.876251,400.979034 
	C387.826080,403.796753 388.183685,406.673889 387.715179,409.414459 
	C387.378784,411.382294 385.979584,413.168427 385.050415,414.610291 
	C385.374146,409.426117 385.875610,404.671387 385.987518,399.907471 
	C386.141815,393.340393 385.703644,393.013947 378.905792,393.003052 
	C366.814178,392.983673 354.719696,392.850037 342.632721,393.085358 
	C339.483246,393.146667 336.356873,394.394379 333.082092,394.742126 
	C331.632202,393.922119 330.320129,393.457214 329.026611,392.612061 
	C331.205658,391.144409 333.337280,389.190735 335.531647,389.117523 
	C345.804749,388.774841 356.101807,388.717285 366.377258,389.012512 
	C374.895813,389.257263 383.395020,390.154724 391.908264,390.673645 
	C392.304169,390.697754 392.753357,389.847443 393.177765,389.404388 
	C395.813385,388.861420 398.449005,388.318451 401.763947,388.115112 
	C404.638153,388.665649 406.833038,388.876587 409.027924,389.087494 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M349.869110,523.476013 
	C346.884583,524.042419 343.900024,524.608826 340.187988,525.191162 
	C338.641693,525.478516 337.822937,525.750000 337.004150,526.021423 
	C324.191315,526.845154 311.485413,526.513184 299.260040,521.996887 
	C291.087067,518.977600 283.750336,514.675049 278.150543,506.895416 
	C276.731384,504.769745 275.674622,503.178711 274.617859,501.587677 
	C274.076447,502.038422 273.535004,502.489197 272.993591,502.939972 
	C272.993591,505.726318 273.311493,508.565613 272.860077,511.276855 
	C272.632782,512.642273 271.009460,513.775330 269.955688,514.679321 
	C269.921112,513.893372 269.948273,513.441406 270.283569,512.710938 
	C270.819336,508.063324 271.046967,503.694275 271.316620,498.518890 
	C274.021545,500.010162 275.946106,501.071167 278.175140,502.282928 
	C279.305084,502.800659 280.130524,503.167664 280.898468,503.569702 
	C280.840942,503.604767 280.879425,503.733887 280.984283,504.075378 
	C281.721344,504.686798 282.353546,504.956757 283.149261,505.514954 
	C284.580505,507.537598 285.848175,509.272034 287.113586,511.355042 
	C291.841614,517.118591 297.685791,519.774231 305.228424,519.204651 
	C316.661469,520.337463 327.808746,521.439392 338.981506,521.852539 
	C342.631256,521.987488 346.347321,520.329163 350.364990,519.393860 
	C352.487213,519.240295 354.277496,519.183899 356.047607,519.451294 
	C355.006958,520.486572 353.986542,521.197998 352.671234,521.971497 
	C351.540619,522.514465 350.704865,522.995239 349.869110,523.476013 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M538.658386,396.147156 
	C539.326843,389.361542 538.355591,388.468933 529.273438,387.575409 
	C534.026794,384.780914 540.407166,386.830688 540.531128,391.885864 
	C540.772400,401.722046 540.034790,411.612701 539.087646,421.421417 
	C538.902771,423.335876 536.230774,425.765472 534.165710,426.568848 
	C530.941650,427.823120 530.364685,430.041443 530.384155,432.735413 
	C530.510071,450.155365 530.854309,467.574585 530.866211,484.994202 
	C530.871643,492.929840 530.327881,500.868195 529.918701,508.799438 
	C529.848206,510.166687 529.203064,511.504242 528.263672,512.500061 
	C527.594421,508.853882 527.484741,505.563202 527.561768,501.817200 
	C527.655762,497.156769 527.563110,492.951660 527.528442,488.319061 
	C527.521606,485.017181 527.456787,482.142822 527.585815,478.847504 
	C527.660400,477.529022 527.541199,476.631470 527.462524,475.413849 
	C527.352661,474.707367 527.202271,474.320984 527.208862,473.513794 
	C527.265381,470.417114 527.164917,467.741180 527.276611,464.641602 
	C527.463684,455.900299 527.438599,447.582642 527.595947,438.814728 
	C527.653809,435.488525 527.529358,432.612549 527.484253,429.320312 
	C527.417786,427.541626 527.272095,426.179230 527.413330,424.531433 
	C529.433105,423.712189 531.165894,423.178284 533.240417,422.739075 
	C535.020081,421.217712 536.458069,419.601715 538.231201,417.796051 
	C538.589661,416.353943 538.612915,415.101440 538.678650,413.393127 
	C538.700195,407.340576 538.679260,401.743866 538.658386,396.147156 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M443.971527,571.987244 
	C443.971527,578.140442 443.971527,584.293640 443.971527,592.012207 
	C448.169067,586.446167 451.715118,581.744019 455.614838,577.387695 
	C454.313171,582.821533 452.657776,587.909546 451.001831,593.426331 
	C450.999603,595.570068 450.997955,597.285034 450.695068,599.151855 
	C448.494141,601.745300 446.505310,604.123596 444.717438,606.644348 
	C442.480042,609.798889 439.140686,610.030762 437.493286,606.519531 
	C436.310699,603.999023 436.448700,600.858826 435.997375,597.565063 
	C436.335144,593.094055 436.673065,589.053101 437.369751,584.945679 
	C441.107910,582.381409 442.675171,579.182556 442.113312,574.864990 
	C442.000885,574.001038 443.315125,572.951294 443.971527,571.987244 
z"/>
<path fill="#6D48B3" opacity="1.000000" stroke="none" 
	d="
M219.901917,712.648254 
	C213.420593,713.131470 210.934219,708.075684 207.996658,703.479553 
	C207.340729,702.453308 206.161926,701.459961 205.030197,701.143127 
	C204.253281,700.925598 202.371368,701.849609 202.335617,702.358337 
	C202.254517,703.512207 202.952927,704.747803 203.432419,705.909729 
	C206.134659,712.457886 212.086136,715.834412 217.736282,718.775574 
	C221.569229,720.770813 226.676147,720.964355 231.190109,720.868713 
	C240.065430,720.680603 247.930084,718.031372 253.581940,710.278503 
	C258.766296,703.166992 258.554077,694.805359 251.713531,688.994873 
	C246.920273,684.923340 242.197845,680.439514 235.426910,679.581177 
	C234.610092,679.477600 233.910492,678.449402 233.093567,677.498901 
	C241.783127,679.124023 250.170990,681.611572 255.431793,689.877441 
	C261.123352,698.820190 259.746063,709.951538 251.346619,716.457642 
	C236.672577,727.824036 207.666611,725.642456 199.113968,702.519104 
	C198.788849,701.640076 198.779419,700.644348 198.623810,699.708496 
	C207.534851,698.078430 207.704773,698.424072 212.033463,704.991089 
	C213.937515,707.879761 217.123108,709.923706 219.901917,712.648254 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M174.002655,627.004883 
	C173.604126,628.085266 173.233551,629.177002 172.802231,630.244141 
	C171.037048,634.611267 169.830048,634.779907 166.351288,631.061768 
	C165.564590,629.139404 165.124985,627.273682 164.685379,625.407959 
	C164.227264,625.332214 163.769150,625.256531 163.311035,625.180786 
	C162.540878,627.526123 161.770706,629.871460 160.994629,632.608032 
	C153.113312,637.572632 152.297104,633.863342 153.203918,626.158691 
	C155.235672,621.269470 156.767212,616.936707 159.025238,613.023132 
	C160.045181,611.255493 162.459747,609.407349 164.337204,609.302185 
	C165.815948,609.219360 167.608749,611.606628 168.952072,613.164062 
	C170.183304,614.591614 170.999619,616.377136 171.998779,618.408569 
	C172.666748,621.543152 173.334702,624.273987 174.002655,627.004883 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M687.982910,553.988525 
	C690.945557,553.745667 690.994324,555.673584 691.032532,557.846985 
	C691.078186,560.450684 691.389160,563.049744 691.585205,565.650879 
	C692.214722,565.652893 692.844177,565.654907 693.473633,565.656982 
	C693.879150,562.218445 694.284607,558.779968 694.690063,555.341431 
	C695.294006,555.343445 695.897949,555.345520 696.501953,555.347534 
	C696.665649,556.960449 696.829407,558.573364 696.996948,560.927551 
	C696.999695,562.108459 696.998657,562.548157 696.996338,563.407898 
	C697.324463,565.943787 695.779114,569.147888 700.312378,569.159363 
	C701.773499,571.205505 702.922119,573.097107 703.948730,575.210266 
	C703.562683,575.632874 703.298645,575.833984 702.796875,575.756409 
	C701.347168,575.334412 700.135132,575.191162 698.923096,575.047913 
	C697.738770,573.991638 696.708679,572.591370 695.328308,571.972229 
	C693.830505,571.300415 692.004089,571.135925 690.325500,571.154480 
	C688.577820,571.173706 686.776245,572.111511 685.102051,571.883850 
	C680.273438,571.227234 677.708679,573.100464 677.091003,577.904114 
	C676.946960,579.024902 676.408997,580.095032 675.615845,581.582275 
	C673.891663,582.667358 671.457031,583.440063 671.499329,584.037048 
	C671.814636,588.481934 668.506409,590.463257 665.769043,592.855591 
	C665.345520,592.119629 665.169128,591.557373 664.999756,590.620483 
	C666.335938,586.164368 667.665039,582.082886 669.305664,577.846558 
	C670.627319,576.623535 672.393616,575.639648 672.523865,574.473145 
	C673.326599,567.283508 677.967773,566.361389 683.822998,566.959473 
	C686.700134,567.253357 688.145020,565.896973 687.997375,562.924805 
	C687.915771,561.282532 687.948181,559.634521 687.945190,557.589661 
	C687.968506,556.122986 687.975708,555.055725 687.982910,553.988525 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M696.947510,464.947845 
	C699.337646,461.271667 701.772095,457.623077 704.091248,453.902649 
	C704.799988,452.765778 705.191895,451.431427 705.728821,450.187500 
	C704.561584,449.798218 703.402222,449.103271 702.225647,449.072815 
	C697.249268,448.944000 692.267395,449.048431 687.288147,449.001007 
	C680.223755,448.933746 676.613586,446.851929 676.443848,440.230316 
	C676.054871,425.060608 674.374023,409.795532 677.834961,394.697144 
	C678.739990,390.748993 680.629456,389.285156 684.604248,390.982483 
	C679.979919,394.198364 678.635437,398.137634 678.859009,403.520416 
	C679.292175,413.947418 678.960022,424.405548 678.977356,434.850708 
	C678.996338,446.319977 686.267212,450.708496 697.467163,445.774231 
	C700.280029,445.642639 702.673340,445.521027 705.411377,445.495850 
	C707.170654,445.733917 708.585083,445.875549 709.968018,446.427856 
	C709.056702,449.301331 708.264832,451.800568 707.265686,454.213959 
	C706.604370,455.811462 705.645813,457.285858 704.460571,458.963043 
	C703.888733,460.276978 703.676758,461.443207 703.762207,462.637024 
	C704.252014,462.716309 704.444336,462.768036 704.184204,462.868439 
	C703.087463,463.541107 702.443115,464.165070 701.488770,464.742920 
	C700.768677,464.784058 700.358765,464.871277 699.597839,464.955353 
	C698.553345,465.023254 697.859802,465.094360 697.111572,465.111084 
	C697.056824,465.056671 696.947510,464.947845 696.947510,464.947845 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M864.994019,607.012207 
	C862.600220,606.676086 860.206482,606.339905 857.398926,606.010620 
	C856.985168,606.017517 856.995850,606.004272 856.955688,605.685791 
	C856.276062,604.913208 855.636597,604.459045 854.997070,604.004883 
	C854.348755,603.222168 853.700378,602.439514 852.990967,600.993164 
	C851.627014,599.215332 850.324097,598.101135 849.021179,596.986877 
	C842.073914,588.875977 835.065002,580.816406 828.230469,572.611633 
	C826.824463,570.923828 826.069763,568.693481 825.039795,566.338379 
	C828.800964,568.629456 832.537415,571.295349 836.665649,574.348633 
	C841.042725,578.495789 845.027893,582.255554 849.155640,586.316406 
	C850.299133,587.929932 851.111023,589.462585 852.336060,590.513855 
	C856.138062,593.776672 860.097046,596.856506 864.003296,600.402222 
	C864.338989,602.868835 864.666504,604.940491 864.994019,607.012207 
z"/>
<path fill="#ACADA9" opacity="1.000000" stroke="none" 
	d="
M392.779663,389.288086 
	C392.753357,389.847443 392.304169,390.697754 391.908264,390.673645 
	C383.395020,390.154724 374.895813,389.257263 366.377258,389.012512 
	C356.101807,388.717285 345.804749,388.774841 335.531647,389.117523 
	C333.337280,389.190735 331.205658,391.144409 328.888641,392.911438 
	C327.828003,394.138763 326.923889,394.686493 325.825745,394.913208 
	C324.668671,394.499939 323.705597,394.407623 322.570374,394.201752 
	C322.398254,394.088196 322.102325,393.800842 322.089478,393.423981 
	C321.215424,389.221497 318.218842,388.965271 315.137573,388.962280 
	C297.084808,388.944794 279.029724,388.800110 260.981018,389.070953 
	C256.981323,389.130951 253.005798,391.156250 248.990906,388.760193 
	C248.915268,388.218170 248.872391,387.909363 248.845886,387.598267 
	C271.644958,387.424591 294.444031,387.250916 317.243103,387.077240 
	C323.002716,390.959656 328.650055,387.776459 334.540344,387.431183 
	C351.778259,386.420746 369.113647,386.995300 386.409027,387.151978 
	C388.405823,387.170074 390.391022,388.467499 392.779663,389.288086 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M608.130310,515.595581 
	C608.544128,515.998657 608.957947,516.401794 609.093384,517.359009 
	C608.255371,518.976135 607.695801,520.039124 607.136230,521.102173 
	C606.933533,521.357727 606.707092,521.590210 605.905762,521.910400 
	C604.574829,522.417358 603.795166,522.813477 603.015503,523.209595 
	C583.654419,523.188232 564.293274,523.166870 544.302734,522.859497 
	C543.092224,522.410950 542.511169,522.248291 541.930054,522.085632 
	C541.576782,521.602722 541.223511,521.119873 540.698853,520.384766 
	C540.628174,519.925964 540.728882,519.719299 541.367065,519.215942 
	C542.992981,518.934387 544.081299,518.949646 545.615723,519.024658 
	C563.928406,518.737610 581.795288,518.399780 599.661133,518.012634 
	C600.422119,517.996094 601.173157,517.522827 602.339233,517.247314 
	C604.543152,516.686707 606.336731,516.141174 608.130310,515.595581 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M521.007324,580.000000 
	C521.669617,578.718201 522.822754,577.466370 522.903015,576.149109 
	C523.152283,572.058105 522.994263,567.942261 522.994263,563.172485 
	C524.525330,563.962769 525.449036,564.439514 526.721191,564.980042 
	C527.375610,566.654114 527.937439,568.262878 527.946655,569.874878 
	C528.014465,581.688782 528.004089,593.503540 527.925781,605.317383 
	C527.915039,606.941650 527.393799,608.562439 527.058105,610.602173 
	C526.252991,611.014404 525.498718,611.009277 524.048401,610.831299 
	C522.233276,607.438965 521.114197,604.219482 519.996216,600.550293 
	C520.327881,594.072693 520.658447,588.044922 520.993713,581.680908 
	C521.001343,580.896484 521.004333,580.448242 521.007324,580.000000 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M540.042725,562.387024 
	C541.474121,562.306763 542.868896,562.657898 544.632324,563.438965 
	C544.901062,568.466248 548.411743,566.877686 550.635925,566.945618 
	C557.260010,567.147949 559.500305,569.179443 560.170471,576.330688 
	C562.207092,580.783508 564.108398,584.894226 566.048096,589.380310 
	C567.058411,594.839600 568.030396,599.923462 569.002441,605.007324 
	C567.263184,603.678223 565.523926,602.349060 563.406189,601.008728 
	C560.653992,593.469055 558.230957,585.955688 555.927673,578.405823 
	C554.797668,574.701904 553.422058,571.830627 548.635376,571.922668 
	C541.094238,572.067566 539.976562,570.653015 540.042725,562.387024 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M666.016174,593.029236 
	C668.506409,590.463257 671.814636,588.481934 671.499329,584.037048 
	C671.457031,583.440063 673.891663,582.667358 675.552673,581.957031 
	C673.568726,589.530884 671.238464,597.132324 668.842957,604.713135 
	C667.625610,608.565552 666.290710,612.380798 664.657959,616.661743 
	C663.878601,618.078247 663.450317,619.045837 663.021973,620.013489 
	C663.021973,620.013489 663.014893,620.511963 663.009888,620.761230 
	C661.261841,621.342346 659.518738,621.674072 657.387817,621.543213 
	C657.334106,616.391052 657.668274,611.701599 658.305298,606.861023 
	C662.506897,602.923706 665.874451,598.849854 666.016174,593.029236 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M254.171051,521.132568 
	C252.043015,521.822021 249.919922,523.091553 247.786209,523.109558 
	C229.893478,523.261047 211.998459,523.232544 194.105301,523.098877 
	C192.276093,523.085205 190.454681,522.026733 188.329376,520.890747 
	C187.975540,519.665161 187.921982,519.001892 188.219452,518.224976 
	C189.061142,518.139221 189.551819,518.167114 190.341995,518.472839 
	C202.102234,518.908325 213.562988,519.065979 225.314087,519.464600 
	C231.396210,519.656555 237.188431,519.637146 242.979492,519.544128 
	C245.307526,519.506775 247.632980,519.308044 250.357620,519.153564 
	C252.070389,518.995483 253.385147,518.866577 254.714661,519.047852 
	C254.543304,519.949585 254.357178,520.541077 254.171051,521.132568 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M919.007324,610.007324 
	C920.162842,608.809814 921.318298,607.612244 922.738892,606.207336 
	C927.669739,611.200867 932.444336,616.309204 936.966003,621.632324 
	C940.441650,625.724121 943.598816,630.086304 946.948853,634.665344 
	C946.212769,635.319702 945.425537,635.637024 944.024780,635.852478 
	C942.607483,635.501221 941.803772,635.251831 941.000000,635.002441 
	C939.899292,634.336975 938.798584,633.671509 937.311462,632.653564 
	C934.669006,630.297302 932.402649,628.304932 930.159363,626.286926 
	C927.100281,623.534851 924.058777,620.763245 921.006470,617.610718 
	C920.337952,614.816711 919.672607,612.412048 919.007324,610.007324 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M487.472565,437.458191 
	C485.790771,435.991089 483.711060,434.796448 482.490448,433.013611 
	C473.157654,419.381775 463.992157,405.635345 454.720367,391.580444 
	C461.640900,390.144958 461.677826,396.990601 464.834961,400.223969 
	C468.174164,403.643799 471.204407,407.365295 474.950500,411.271118 
	C476.277466,414.137390 477.023499,416.687592 477.815948,419.602997 
	C478.808685,420.548248 479.760162,421.632050 480.700470,421.622498 
	C485.119812,421.577637 487.021820,424.069489 487.723877,428.383179 
	C487.609772,431.682037 487.541168,434.570129 487.472565,437.458191 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M391.199219,553.406860 
	C392.919312,553.299866 394.557892,553.657593 396.585449,554.445740 
	C396.974243,556.587402 396.973999,558.298584 396.700592,560.257202 
	C395.774292,566.503967 398.069519,568.177307 404.373444,566.066895 
	C405.737640,567.615601 407.031982,569.013000 407.649567,570.663696 
	C408.400299,572.670288 408.580688,574.890259 409.004822,577.478455 
	C409.000824,579.755920 409.243286,581.614624 408.941162,583.380005 
	C408.500610,585.954529 407.668457,588.462036 406.721008,590.736938 
	C405.537445,588.230286 407.007629,584.145630 402.160950,584.959717 
	C402.123718,583.301025 401.837646,581.602539 402.095459,579.991028 
	C402.898804,574.970398 402.606079,571.466125 395.850342,571.407654 
	C394.233673,571.393738 391.906036,566.989197 391.253693,564.279480 
	C390.461792,560.989868 391.098877,557.356262 391.199219,553.406860 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M785.002441,609.007324 
	C786.326050,607.591309 787.649658,606.175293 788.982056,604.385498 
	C792.872559,608.456787 796.766357,612.891357 800.630371,617.351624 
	C802.354736,619.342224 804.019470,621.384521 805.859985,624.067505 
	C807.002502,625.821899 807.996399,626.912170 808.990234,628.002441 
	C808.990234,628.002441 808.999023,628.000000 808.998291,628.004883 
	C807.614502,632.112305 804.341125,633.230347 802.640869,629.829712 
	C800.612854,625.773376 797.476196,624.831177 793.352417,623.690918 
	C791.424683,619.440735 789.379883,616.106567 785.003845,614.571899 
	C785.002686,612.431580 785.002563,610.719482 785.002441,609.007324 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M577.010071,388.204559 
	C578.689758,388.794373 580.365540,389.883118 582.049805,389.896515 
	C598.327637,390.025970 614.608948,389.835907 630.884399,390.073151 
	C634.932922,390.132141 639.040466,391.123901 642.981812,392.197296 
	C647.113525,393.322540 648.875244,396.232910 646.869629,400.746735 
	C645.699219,400.319275 644.803040,399.791901 644.679749,399.122253 
	C643.688293,393.736267 640.326233,392.809967 635.308838,392.877167 
	C614.919189,393.150360 594.523743,392.985168 574.022888,392.653961 
	C573.214600,391.122833 572.513855,389.919373 571.813049,388.715942 
	C572.303589,388.427216 572.794128,388.138489 573.943481,387.899628 
	C575.404907,388.034515 576.207458,388.119537 577.010071,388.204559 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M368.999146,561.002686 
	C369.830017,560.343384 370.660919,559.684082 371.833374,558.990356 
	C374.445679,563.617981 376.749817,568.264282 378.960693,572.954529 
	C379.438751,573.968628 379.581116,575.140991 379.701508,576.794434 
	C379.990417,577.919006 380.455933,578.489624 380.921448,579.060303 
	C380.944946,580.111023 380.968445,581.161804 380.769653,582.772339 
	C380.993073,583.915955 381.438751,584.499695 381.884430,585.083435 
	C381.916168,587.753296 381.947937,590.423157 381.603973,593.548645 
	C379.488678,596.342834 377.749084,598.681396 375.755920,601.009338 
	C375.333282,600.995117 375.164215,600.991455 374.997498,600.540100 
	C374.659698,595.061768 374.319550,590.031067 374.141418,584.641174 
	C374.655090,581.712402 376.759125,578.825867 372.991058,576.586365 
	C371.672485,572.113525 370.349426,568.056763 369.013031,563.625244 
	C368.999512,562.501221 368.999329,561.751953 368.999146,561.002686 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M272.996887,611.997559 
	C271.895905,617.295715 268.380951,613.973389 265.421448,613.999634 
	C265.500031,603.167542 265.964020,592.346802 267.125488,581.264526 
	C273.553864,581.962769 270.735748,586.059937 270.415527,588.862366 
	C270.136383,591.305481 269.674561,593.105103 272.988800,593.255493 
	C272.995819,593.677246 272.997894,593.845947 272.998871,594.474304 
	C272.997437,600.621826 272.997162,606.309692 272.996887,611.997559 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M91.001221,626.002380 
	C88.574745,630.306152 86.148254,634.609863 83.354187,638.956787 
	C82.668449,626.624573 82.350273,614.249146 82.444031,601.423584 
	C85.709946,600.815796 87.232697,602.018005 86.994568,605.428223 
	C87.666466,611.332031 88.241180,616.843933 89.074181,622.316528 
	C89.271507,623.612915 90.335754,624.777283 91.001221,626.002380 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M241.133942,641.649231 
	C240.605087,641.435791 240.076218,641.222351 239.216309,640.877930 
	C238.885269,640.746887 238.833054,640.409058 238.833054,640.409058 
	C238.407104,636.441711 237.981155,632.474426 237.277817,627.815552 
	C237.000488,623.418457 237.000549,619.712891 237.168610,615.674561 
	C237.890930,612.895325 238.445267,610.448914 239.005615,607.670166 
	C239.612152,606.858704 240.168320,606.057251 240.822418,605.967224 
	C242.185928,605.779541 243.601227,605.967896 244.995239,606.001770 
	C245.071091,606.822571 245.146942,607.643311 244.710144,608.746948 
	C243.472031,610.222229 242.249619,611.396484 242.204880,612.613953 
	C242.177460,613.360474 243.954605,614.173340 244.921387,614.956421 
	C244.953842,623.641174 244.986298,632.325867 245.060822,641.470581 
	C245.102905,641.930542 244.866241,642.173767 244.394821,642.232178 
	C242.993576,642.076782 242.063751,641.863037 241.133942,641.649231 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M731.997559,607.002441 
	C729.625244,606.663025 727.252930,606.323669 724.466492,605.977051 
	C716.817383,597.510010 709.582458,589.050171 702.585388,580.293457 
	C706.217957,581.661926 709.612610,583.327332 713.381531,584.994873 
	C714.504639,585.000488 715.253540,585.003906 716.036987,585.358521 
	C718.502869,588.445984 720.860596,591.252747 723.390869,593.894165 
	C725.136169,595.716125 727.120178,597.309387 729.054565,599.354736 
	C730.073608,602.137207 731.035583,604.569824 731.997559,607.002441 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M443.983887,571.987549 
	C443.315125,572.951294 442.000885,574.001038 442.113312,574.864990 
	C442.675171,579.182556 441.107910,582.381409 437.364807,584.500488 
	C436.995026,581.750305 436.988983,579.378845 436.988281,576.581116 
	C437.997955,572.774719 439.002197,569.394470 440.370239,566.170410 
	C444.411377,565.820496 449.817291,566.571350 451.329712,564.491211 
	C453.231964,561.874878 451.949280,556.942810 452.034729,552.678589 
	C452.035034,551.905518 452.041534,551.463928 452.048035,551.022339 
	C453.468384,549.717102 454.888763,548.411804 456.649048,547.052856 
	C456.870361,554.141418 456.751801,561.283569 456.016724,568.755005 
	C454.931183,569.713806 454.462097,570.343506 453.992981,570.973206 
	C452.583038,571.284790 451.173065,571.596313 449.309357,571.538391 
	C446.883484,569.319702 445.181946,569.111755 443.986267,571.971741 
	C443.986267,571.971741 443.996216,571.987915 443.983887,571.987549 
z"/>
<path fill="#6D48B3" opacity="1.000000" stroke="none" 
	d="
M223.198029,673.253723 
	C217.558304,673.714661 214.030045,670.839966 211.926147,664.201965 
	C209.955154,657.983337 211.748260,652.911560 217.541641,649.300964 
	C224.374619,645.042358 231.607620,644.202026 238.686691,648.455200 
	C242.090332,650.500122 244.805832,653.659546 248.081573,655.960205 
	C249.911926,657.245789 252.251450,657.806335 254.361511,658.693604 
	C254.590759,658.351929 254.820023,658.010254 255.049286,657.668518 
	C253.096100,654.238892 251.142929,650.809204 249.302994,647.007446 
	C252.020248,650.275269 254.624237,653.915222 257.450684,657.806152 
	C257.290619,658.806458 256.908081,659.555786 256.013397,660.351135 
	C255.501205,660.397095 255.095703,660.598694 255.095703,660.598694 
	C253.014786,660.494812 250.933884,660.390991 248.239639,660.086426 
	C247.459183,659.823059 247.292038,659.760315 247.124908,659.697571 
	C242.264450,648.770813 229.736572,644.620972 219.892136,650.676636 
	C211.686951,655.723877 211.415283,665.071411 219.321320,670.518005 
	C220.543716,671.360168 221.793045,672.163330 223.198029,673.253723 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M527.426331,520.149109 
	C527.038696,520.590454 526.651001,521.031738 525.816895,521.728271 
	C524.919800,522.353088 524.469238,522.722717 524.018677,523.092285 
	C510.781891,523.082764 497.545105,523.058594 484.308319,523.072266 
	C480.050507,523.076721 478.395111,520.041260 477.210083,516.500977 
	C479.067505,516.848938 480.537628,517.555664 482.326172,518.516296 
	C492.689362,518.884216 502.734192,519.072876 512.778992,519.070679 
	C516.179504,519.069885 519.579834,518.586243 523.351562,518.280273 
	C524.881592,517.906433 526.040344,517.575684 527.199036,517.244934 
	C527.353760,517.308044 527.508423,517.371155 527.557983,517.972778 
	C527.443909,519.057251 527.435120,519.603149 527.426331,520.149109 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M248.401169,387.483948 
	C248.872391,387.909363 248.915268,388.218170 248.696411,389.058044 
	C239.781387,389.727020 231.135025,389.665314 222.511444,390.078705 
	C217.979126,390.295990 213.486664,391.344513 208.603271,392.025818 
	C207.483612,392.026733 206.736877,392.019531 205.704041,391.715027 
	C200.894943,391.368622 196.147308,389.264160 191.688629,392.898682 
	C190.903564,392.901001 190.454910,392.886078 189.908264,392.500122 
	C186.721252,390.878326 183.595871,389.276947 180.978149,393.348785 
	C180.871902,393.857300 180.814667,394.057434 180.374481,394.195587 
	C178.683578,394.119263 177.375626,394.104889 176.060226,393.730865 
	C176.346451,392.223267 176.640137,391.075317 176.933838,389.927368 
	C176.933838,389.927368 177.004868,389.988892 177.389084,389.935730 
	C179.702408,389.580536 181.627823,389.048798 183.561279,389.017731 
	C190.633224,388.904144 197.709488,389.034332 204.780121,388.884491 
	C206.240265,388.853516 207.683258,388.013733 209.134155,387.546722 
	C222.074921,387.487701 235.015686,387.428680 248.401169,387.483948 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M86.991844,605.021973 
	C87.232697,602.018005 85.709946,600.815796 82.474258,600.951416 
	C82.069977,592.990173 82.147591,585.048523 81.949913,577.113708 
	C81.904137,575.276306 80.918167,573.462341 80.183472,571.288208 
	C81.233650,569.736084 82.465187,568.533325 83.696732,567.330566 
	C84.702286,568.997681 85.707832,570.664856 86.861977,573.111816 
	C87.344452,577.266907 87.678329,580.642212 88.008911,584.465210 
	C88.338089,590.941101 88.670570,596.969299 88.960098,603.319214 
	C88.275383,604.101257 87.633621,604.561646 86.991844,605.021973 
z"/>
<path fill="#6D48B3" opacity="1.000000" stroke="none" 
	d="
M210.004883,675.396362 
	C212.983154,676.401917 216.081512,677.741577 219.204590,679.020874 
	C227.577042,682.450500 236.066360,685.619385 244.304535,689.344543 
	C250.610077,692.195862 252.471863,704.747375 247.693314,709.799133 
	C241.099762,716.769714 227.467010,718.663208 220.183456,713.256348 
	C224.311127,713.340088 228.600601,714.191040 232.870285,714.101318 
	C240.969284,713.931091 247.086105,709.092285 247.950684,702.825378 
	C248.836258,696.406555 244.957642,691.029236 237.158630,687.980103 
	C230.670303,685.443481 224.089706,683.129028 217.684357,680.400635 
	C214.990173,679.252991 212.641815,677.293457 210.004883,675.396362 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M180.009430,523.097900 
	C167.153183,523.136658 154.296478,523.236145 141.441269,523.138550 
	C139.806259,523.126160 138.179077,522.083252 136.232788,520.976196 
	C135.920486,519.794861 135.923676,519.155090 136.365631,518.422791 
	C138.020203,518.246338 139.236008,518.162354 140.757080,518.330200 
	C143.012711,518.751099 144.971970,519.130615 146.911636,519.048340 
	C151.993454,518.832703 157.066666,518.150940 162.143417,518.149841 
	C167.714035,518.148560 173.284775,518.700745 179.247070,519.008057 
	C181.135284,518.807129 182.631897,518.609985 184.094208,518.691895 
	C183.921448,519.508179 183.783005,520.045471 183.644562,520.582825 
	C183.214066,520.924194 182.783554,521.265625 181.886887,521.818237 
	C180.950302,522.385620 180.479874,522.741760 180.009430,523.097900 
z"/>
<path fill="#BFC0BD" opacity="1.000000" stroke="none" 
	d="
M697.047485,445.784241 
	C686.267212,450.708496 678.996338,446.319977 678.977356,434.850708 
	C678.960022,424.405548 679.292175,413.947418 678.859009,403.520416 
	C678.635437,398.137634 679.979919,394.198364 684.856079,391.252197 
	C686.056458,390.941803 687.113037,390.949585 688.950195,390.983826 
	C691.228760,390.724884 692.726929,390.439423 694.206665,390.546448 
	C692.596924,392.011292 691.121704,393.727509 689.393066,394.039520 
	C683.389709,395.123138 681.225159,397.350555 681.368164,403.616882 
	C681.352905,408.314178 681.419861,412.567230 681.351990,417.173767 
	C681.301392,419.742584 681.385559,421.957916 681.393188,424.620239 
	C681.404358,429.634216 681.492126,434.201172 681.460022,439.095215 
	C681.960876,439.869415 682.581543,440.316559 683.155762,440.840210 
	C683.109253,440.916718 683.242554,440.797211 683.190552,441.124207 
	C684.428223,441.997711 685.718018,442.544250 687.355591,443.125549 
	C688.153503,443.061371 688.603577,442.962372 689.403320,443.001770 
	C690.496094,443.143219 691.239075,443.146301 692.264893,443.344635 
	C693.874451,443.842163 695.201233,444.144440 696.607788,444.677917 
	C696.956604,445.139557 697.076538,445.431244 697.047485,445.784241 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M272.983887,593.002441 
	C269.674561,593.105103 270.136383,591.305481 270.415527,588.862366 
	C270.735748,586.059937 273.553864,581.962769 267.405334,580.999634 
	C269.051758,570.827942 271.660614,560.902588 279.679138,553.117188 
	C280.477631,553.898315 280.965546,554.815002 281.596680,556.196411 
	C281.131744,557.979980 280.440277,559.267334 279.930695,560.623108 
	C278.262634,565.060974 276.660736,569.523804 275.019104,574.417786 
	C275.002502,576.911438 275.000153,578.964233 274.839722,581.362061 
	C274.115692,585.472168 273.549805,589.237305 272.983887,593.002441 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M376.009521,601.019897 
	C377.749084,598.681396 379.488678,596.342834 381.604736,593.994751 
	C382.313843,603.674744 382.646484,613.364197 382.969055,623.520935 
	C378.905029,625.135437 376.240570,624.600830 376.909363,619.380066 
	C377.028961,618.446655 376.109467,617.380127 375.336182,615.745789 
	C375.338196,610.417664 375.673828,605.718750 376.009521,601.019897 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M451.002441,592.997559 
	C452.657776,587.909546 454.313171,582.821533 455.952271,577.319336 
	C456.126160,576.071350 456.316345,575.237610 457.090820,574.160278 
	C458.102142,573.253174 458.529144,572.589661 458.956177,571.926147 
	C462.096344,567.050537 465.236481,562.174927 468.677979,557.146423 
	C471.737885,559.081970 470.405029,561.255676 469.234619,563.554138 
	C467.558838,566.845093 466.058258,570.225281 464.727875,574.163269 
	C463.981628,577.509827 462.990356,580.260376 461.942657,583.354126 
	C459.254333,586.417419 456.685272,589.203552 453.947754,591.812927 
	C453.236115,592.491211 451.998474,592.617615 451.002441,592.997559 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M244.997986,605.532959 
	C243.601227,605.967896 242.185928,605.779541 240.822418,605.967224 
	C240.168320,606.057251 239.612152,606.858704 238.625000,607.588623 
	C237.825363,605.560303 237.412384,603.281372 237.343384,600.806946 
	C238.116760,596.075134 238.546173,591.538757 238.984131,586.575073 
	C238.998764,584.441101 239.004868,582.734497 239.010986,581.027954 
	C239.682632,580.083435 240.239594,579.021057 241.056656,578.226013 
	C241.957535,577.349426 243.097214,576.718201 244.524048,575.964661 
	C244.943710,585.654236 244.972214,595.359131 244.997986,605.532959 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M402.109833,585.428467 
	C407.007629,584.145630 405.537445,588.230286 406.774292,591.081177 
	C408.405334,594.124390 409.700836,596.562195 410.949280,599.384644 
	C409.935303,604.512878 408.968353,609.256470 408.001404,614.000000 
	C406.575226,613.691467 405.149017,613.382935 403.356079,613.038452 
	C402.679138,603.967346 402.368927,594.932312 402.109833,585.428467 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M482.007782,518.262329 
	C480.537628,517.555664 479.067505,516.848938 477.243347,516.137817 
	C476.889374,516.133423 476.896057,516.106323 476.945496,515.765869 
	C476.032532,514.259460 475.070129,513.093506 474.107727,511.927490 
	C474.107727,511.927490 474.028137,511.975586 474.081360,511.585510 
	C473.034393,509.857635 471.934204,508.519836 470.834015,507.182068 
	C470.834015,507.182068 470.954163,507.061371 470.938141,506.780273 
	C470.372162,505.934662 469.822205,505.370178 469.272278,504.805664 
	C469.272278,504.805664 469.006165,504.505493 468.902710,504.333740 
	C465.141449,498.281433 461.495728,492.393402 457.815125,486.527191 
	C457.127594,485.431427 456.300262,484.423431 455.104126,482.990875 
	C454.382965,482.110474 454.095062,481.614105 453.807190,481.117767 
	C454.435730,480.222321 455.064270,479.326843 456.108459,478.300842 
	C465.353882,491.109131 474.183655,504.047913 482.756226,517.115601 
	C482.335297,517.583801 482.171539,517.923096 482.007782,518.262329 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M527.051941,473.934570 
	C527.202271,474.320984 527.352661,474.707367 527.213257,475.551392 
	C527.079712,477.095459 527.235840,478.181946 527.391968,479.268433 
	C527.456787,482.142822 527.521606,485.017181 527.230591,488.503357 
	C525.071777,493.575806 523.718445,498.019379 527.375061,502.272491 
	C527.484741,505.563202 527.594421,508.853882 527.865906,512.576538 
	C528.027710,513.008545 527.593628,513.152283 526.965942,513.025879 
	C525.897034,510.419769 525.690186,507.865448 524.932617,505.486542 
	C524.423157,503.886932 523.134644,502.535400 522.115479,500.603455 
	C521.774170,494.073242 521.512146,488.012390 521.222229,481.535278 
	C521.561768,479.076569 521.929260,477.034119 522.614746,474.979584 
	C524.305786,474.623199 525.678894,474.278870 527.051941,473.934570 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M477.769531,419.237793 
	C477.023499,416.687592 476.277466,414.137390 475.320221,411.287628 
	C475.213654,407.034424 475.318268,403.080750 475.481232,398.683716 
	C476.849731,398.096130 478.159912,397.951904 479.780640,398.367584 
	C480.156677,402.263580 480.222107,405.599701 480.211914,409.219910 
	C480.198547,409.689880 480.260803,409.875732 480.290100,410.471130 
	C479.804413,413.914917 480.868683,415.562561 484.180573,415.474182 
	C486.467621,415.413086 488.761719,415.615540 491.192108,416.064880 
	C491.657532,417.689423 491.983490,418.949341 492.263489,420.502319 
	C492.177338,421.188629 492.137207,421.581879 491.685455,422.006744 
	C486.772400,421.104797 482.270966,420.171295 477.769531,419.237793 
z"/>
<path fill="#6D48B3" opacity="1.000000" stroke="none" 
	d="
M238.462112,640.379211 
	C238.833054,640.409058 238.885269,640.746887 238.924133,640.913330 
	C234.228256,641.385071 229.496262,641.880981 224.758087,641.949707 
	C218.091446,642.046509 212.978714,645.568726 209.261627,650.250610 
	C204.464645,656.292542 202.642044,663.272949 208.458466,670.228394 
	C209.342285,671.285278 209.482254,672.964172 209.941376,674.741211 
	C201.875351,669.410950 200.679047,658.996399 207.037415,648.650635 
	C211.972641,643.704773 217.596756,640.697327 225.150757,639.833374 
	C229.305984,639.770142 232.622055,639.770508 235.938110,639.770935 
	C236.655792,639.963745 237.373474,640.156555 238.462112,640.379211 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M928.350098,466.498077 
	C930.689697,467.083618 932.563599,467.890045 934.491211,468.050110 
	C937.633911,468.311096 940.811707,468.150177 944.421875,468.432251 
	C944.547546,469.387726 944.226074,470.078186 943.497437,470.749756 
	C936.634277,470.557251 930.168091,470.111176 923.727295,470.349548 
	C921.290588,470.439728 918.912415,472.109711 916.205444,472.965027 
	C914.578491,472.197906 913.308838,471.363037 911.916260,470.889648 
	C909.647095,470.118286 907.297729,469.583008 905.106079,468.637299 
	C906.795715,467.178528 908.361389,466.030579 910.257935,465.050323 
	C911.997925,465.635132 913.407043,466.052246 915.034180,466.633148 
	C915.835754,466.531372 916.419128,466.265839 917.002563,466.000275 
	C920.637878,466.143066 924.273132,466.285889 928.350098,466.498077 
z"/>
<path fill="#8F8E92" opacity="1.000000" stroke="none" 
	d="
M792.997192,392.870911 
	C791.860962,391.915131 790.817322,390.312531 789.569824,390.133850 
	C786.681702,389.720337 783.684387,390.139526 780.752991,389.925049 
	C779.488647,389.832520 778.277954,389.007050 776.969238,388.170929 
	C786.334595,387.705383 795.773560,387.583466 805.637329,388.000610 
	C806.949890,389.360626 807.774597,390.267151 808.742493,390.978851 
	C809.750916,391.720337 810.897583,392.273865 811.518188,392.942017 
	C805.034119,392.939911 799.015686,392.905396 792.997192,392.870911 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M689.016418,523.197876 
	C678.266846,523.166138 667.517334,523.134338 656.404663,522.639893 
	C656.377625,521.123230 656.713745,520.069214 657.450928,518.999268 
	C659.195801,518.951111 660.539429,518.918884 662.327942,518.937134 
	C664.816895,519.062256 666.860962,519.137024 669.003052,519.530762 
	C670.433960,519.645447 671.766907,519.441162 673.400391,519.210876 
	C674.102539,519.198425 674.504150,519.211975 675.020874,519.563843 
	C677.315308,521.339905 679.424438,522.170471 681.441956,519.212769 
	C684.164490,518.876160 686.540283,518.539612 689.350464,518.179810 
	C691.516663,518.277466 693.248535,518.398254 694.706421,518.640930 
	C693.880737,519.295471 693.329041,519.828125 692.493530,520.404419 
	C691.145264,521.364624 690.080811,522.281250 689.016418,523.197876 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M814.025574,522.909546 
	C802.313049,522.975159 790.600586,523.040771 778.508179,522.626221 
	C781.309631,521.435608 784.507996,520.792114 787.665771,519.989075 
	C789.511536,519.519714 791.292603,518.795776 793.556519,518.139282 
	C798.641479,518.112427 803.272705,518.132690 808.002808,518.502808 
	C810.076294,520.205017 812.050964,521.557251 814.025574,522.909546 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M254.624313,521.104370 
	C254.357178,520.541077 254.543304,519.949585 254.824524,518.915894 
	C254.919617,518.473633 255.070740,518.165222 255.484863,518.204346 
	C258.933380,517.838196 261.967743,517.432983 265.213318,517.285522 
	C266.954254,516.701172 268.483978,515.859009 270.007690,515.010498 
	C270.001709,515.004211 270.017395,515.013428 270.017395,515.013428 
	C271.009460,513.775330 272.632782,512.642273 272.860077,511.276855 
	C273.311493,508.565613 272.993591,505.726318 272.993591,502.939972 
	C273.535004,502.489197 274.076447,502.038422 274.617859,501.587677 
	C275.674622,503.178711 276.731384,504.769745 277.892303,506.680542 
	C277.322357,508.865204 276.192902,510.698242 276.059601,512.601013 
	C275.659851,518.307190 273.215729,520.858826 267.441345,520.933655 
	C263.320160,520.987122 259.198853,521.029053 254.624313,521.104370 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M948.010559,393.047180 
	C948.998169,395.234192 950.726868,397.384705 950.836670,399.614960 
	C951.188171,406.753723 950.939941,413.922058 950.941833,421.501648 
	C950.796265,422.210236 950.632019,422.496185 949.870728,422.894470 
	C946.721863,423.981537 944.169983,424.956238 941.696045,425.557343 
	C942.651001,423.491730 943.490662,421.779022 944.414368,420.112915 
	C945.557800,418.050385 946.771057,416.026489 948.222778,413.760559 
	C948.341309,412.018433 948.191284,410.501678 948.056152,408.532227 
	C948.056458,405.056732 948.041809,402.033905 948.273621,398.774475 
	C948.350220,396.707611 948.180420,394.877411 948.010559,393.047180 
z"/>
<path fill="#ACADA9" opacity="1.000000" stroke="none" 
	d="
M176.562622,390.019714 
	C176.640137,391.075317 176.346451,392.223267 175.969101,393.949036 
	C175.725510,394.808502 175.507263,395.033661 174.790543,395.129669 
	C169.238739,394.713043 164.127060,394.369049 158.653107,393.866272 
	C155.934708,393.433838 153.578583,393.160187 150.919174,392.777252 
	C150.066360,391.797882 149.516846,390.927826 148.967316,390.057739 
	C148.711838,389.550659 148.456345,389.043579 148.100342,388.269043 
	C157.163269,391.827423 166.747253,390.035736 176.562622,390.019714 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M734.027588,522.908752 
	C724.303040,522.977478 714.578369,523.046204 704.493408,522.638306 
	C707.091919,521.147522 710.050842,520.133301 713.441406,519.095642 
	C720.575989,519.385437 727.278931,519.698730 733.984619,520.374878 
	C734.000854,521.461426 734.014221,522.185120 734.027588,522.908752 
z"/>
<path fill="#ACADA9" opacity="1.000000" stroke="none" 
	d="
M409.492981,389.053986 
	C406.833038,388.876587 404.638153,388.665649 402.203735,388.126495 
	C403.059509,387.546448 404.155640,387.072449 405.250031,387.076477 
	C419.642212,387.129211 434.034119,387.249390 448.714417,387.653625 
	C449.026855,388.144836 449.050934,388.335144 448.615967,388.759918 
	C446.098541,389.021698 444.040100,389.048981 441.981659,389.076294 
	C440.276093,389.056274 438.570557,389.036285 436.061768,388.994781 
	C434.501160,389.002106 433.743774,389.030945 432.986389,389.059814 
	C425.310272,389.046692 417.634125,389.033600 409.492981,389.053986 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M675.291992,27.373936 
	C659.975708,27.687471 644.350891,27.763712 628.410156,27.583744 
	C643.723938,27.263905 659.353699,27.200274 675.291992,27.373936 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M681.450378,403.172638 
	C681.225159,397.350555 683.389709,395.123138 689.393066,394.039520 
	C691.121704,393.727509 692.596924,392.011292 694.299683,390.319458 
	C694.411011,389.699951 694.450439,389.211334 694.450439,389.211334 
	C694.450439,389.211334 694.782166,389.230835 695.238892,389.172180 
	C696.131226,388.738525 696.566833,388.363525 697.002502,387.988556 
	C697.750183,387.980133 698.497925,387.971710 699.687256,388.285645 
	C700.061218,389.399963 699.993530,390.191925 699.602051,390.981476 
	C698.864624,391.656372 698.450989,392.333679 698.047852,393.375610 
	C698.158081,394.215668 698.257812,394.691071 698.291748,395.408905 
	C698.133240,395.790588 698.040344,395.929901 697.549194,396.067566 
	C692.097168,397.631592 685.921021,396.930328 682.828552,403.030701 
	C682.183899,403.085815 681.817139,403.129242 681.450378,403.172638 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M635.269714,353.276978 
	C648.695312,352.943237 662.312866,352.909790 676.388184,352.907349 
	C675.836060,353.341980 674.828674,354.089294 673.815796,354.096832 
	C661.654724,354.187408 649.492981,354.177246 637.331421,354.152283 
	C636.707764,354.151031 636.084900,353.777466 635.269714,353.276978 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M811.983765,392.909576 
	C810.897583,392.273865 809.750916,391.720337 808.742493,390.978851 
	C807.774597,390.267151 806.949890,389.360626 806.061401,388.211548 
	C809.413269,387.835449 812.765869,387.787537 816.716675,388.068939 
	C820.000244,390.414215 822.685669,392.430176 825.304199,394.797424 
	C824.194397,395.620056 822.467896,396.732635 822.214844,396.462952 
	C819.845032,393.936707 817.948730,395.282166 815.924194,396.513672 
	C816.215027,393.080170 814.142151,392.943481 811.983765,392.909576 
z"/>
<path fill="#8F8E92" opacity="1.000000" stroke="none" 
	d="
M914.816040,466.469360 
	C913.407043,466.052246 911.997925,465.635132 910.323730,464.714172 
	C914.328796,459.460144 918.598938,454.709991 923.179077,449.954132 
	C924.190125,449.858826 924.891235,449.769287 925.711670,449.664490 
	C925.437988,450.979614 925.224731,452.004150 925.011536,453.028717 
	C923.766724,454.535156 922.521790,456.041565 920.703979,457.819061 
	C919.445190,459.124146 918.759216,460.158112 918.073242,461.192078 
	C918.073303,461.192108 917.977722,461.643494 917.508606,461.898743 
	C916.298340,463.592438 915.557190,465.030884 914.816040,466.469360 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M678.157837,485.836456 
	C678.010681,485.190308 677.863525,484.544128 677.640137,483.577576 
	C680.677429,480.479645 683.893677,477.807526 686.876526,474.896179 
	C690.119873,471.730682 693.146179,468.342865 696.607300,464.999939 
	C696.947510,464.947845 697.056824,465.056671 697.067383,465.457886 
	C694.211487,469.595795 691.241272,473.259460 688.532410,477.107056 
	C687.526367,478.535950 687.132690,480.395905 686.440430,482.286316 
	C686.421204,482.513367 686.452026,482.968048 686.101807,482.905334 
	C683.220337,483.840546 680.689087,484.838501 678.157837,485.836456 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M352.966125,521.909363 
	C353.986542,521.197998 355.006958,520.486572 356.348572,519.282104 
	C358.433624,518.186279 360.197510,517.583618 362.332703,517.016479 
	C364.837463,515.804138 366.970947,514.556152 369.338959,513.097168 
	C369.902374,512.387268 370.231262,511.888367 370.803558,511.332275 
	C371.329315,511.077728 371.642670,510.976227 372.107178,511.247864 
	C372.805298,511.668823 373.383270,511.812439 373.954041,512.292419 
	C372.959167,514.080566 371.971497,515.532410 370.983795,516.984253 
	C370.983795,516.984253 371.000000,517.000000 370.673096,517.030762 
	C369.863312,517.685547 369.380432,518.309570 368.897583,518.933594 
	C368.897614,518.933594 368.440491,518.923462 367.823120,518.949829 
	C364.089264,520.002380 361.014496,521.197632 357.838196,521.981689 
	C356.313080,522.358154 354.596161,521.957886 352.966125,521.909363 
z"/>
<path fill="#ACADA9" opacity="1.000000" stroke="none" 
	d="
M208.683105,387.368317 
	C207.683258,388.013733 206.240265,388.853516 204.780121,388.884491 
	C197.709488,389.034332 190.633224,388.904144 183.561279,389.017731 
	C181.627823,389.048798 179.702408,389.580536 177.349869,389.911133 
	C178.574310,388.979126 180.201035,387.234314 181.873566,387.189209 
	C190.653351,386.952576 199.444702,387.145935 208.683105,387.368317 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M713.009766,519.119080 
	C710.050842,520.133301 707.091919,521.147522 704.051880,522.518433 
	C701.610718,522.929016 699.250793,522.982910 696.318237,522.721680 
	C694.756165,521.724670 693.766785,521.042725 692.777344,520.360779 
	C693.329041,519.828125 693.880737,519.295471 695.033325,518.496094 
	C696.105347,518.158325 696.576538,518.087158 697.304810,518.302246 
	C700.047119,518.376587 702.532288,518.164734 705.457642,517.967102 
	C708.245728,517.982178 710.593811,517.983093 712.961792,518.267578 
	C712.991150,518.740479 713.000427,518.929810 713.009766,519.119080 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M115.445946,523.009033 
	C114.360641,521.950073 113.730194,520.975159 113.473862,519.587280 
	C117.901878,519.072937 121.955780,518.971558 126.420982,518.928589 
	C128.211685,518.984070 129.591080,518.981201 130.917480,519.261597 
	C130.525406,520.010132 130.186340,520.475403 129.519333,520.963623 
	C128.447403,521.594971 127.703407,522.203308 126.959412,522.811646 
	C123.273216,522.905457 119.587006,522.999268 115.445946,523.009033 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M793.102783,518.186401 
	C791.292603,518.795776 789.511536,519.519714 787.665771,519.989075 
	C784.507996,520.792114 781.309631,521.435608 778.049683,522.510620 
	C776.915588,522.939880 775.860291,523.004578 774.345947,522.647461 
	C772.249084,521.490784 770.611084,520.755920 769.248047,519.729553 
	C772.352112,518.936951 775.181152,518.435852 778.475464,517.954285 
	C783.661377,518.044739 788.382080,518.115540 793.102783,518.186401 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M522.194702,501.072845 
	C523.134644,502.535400 524.423157,503.886932 524.932617,505.486542 
	C525.690186,507.865448 525.897034,510.419769 526.744202,512.970093 
	C527.214294,514.161499 527.278442,515.282227 527.270813,516.823975 
	C526.040344,517.575684 524.881592,517.906433 523.356995,517.946167 
	C522.697876,517.108154 522.404602,516.561096 522.090576,515.547729 
	C522.111450,510.411865 522.153076,505.742340 522.194702,501.072845 
z"/>
<path fill="#8F8E92" opacity="1.000000" stroke="none" 
	d="
M943.094727,431.229736 
	C942.848633,431.732819 942.602539,432.235931 941.724548,432.910187 
	C938.315369,433.773773 936.187439,434.946716 937.150879,438.407562 
	C937.150879,438.407562 937.139404,438.742279 936.713379,438.917694 
	C935.868103,439.754944 935.448853,440.416748 935.029541,441.078552 
	C934.653442,441.698914 934.277222,442.319244 933.306396,443.071930 
	C932.121521,443.466583 931.531250,443.728851 930.968262,443.613708 
	C931.985596,440.813385 932.975769,438.390442 933.974426,435.597595 
	C934.000122,434.486938 934.017273,433.746185 934.262390,432.984802 
	C934.490356,432.964142 934.947205,432.935242 935.286499,432.914215 
	C937.069885,431.598663 938.513977,430.304138 940.331177,429.014130 
	C941.501099,429.755707 942.297913,430.492737 943.094727,431.229736 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M452.504944,150.228409 
	C452.859314,151.303635 453.213684,152.378845 453.684631,154.075653 
	C453.886169,155.131485 453.971130,155.565735 453.755432,156.134430 
	C452.210632,157.111298 450.966461,157.953705 449.645721,158.847946 
	C448.612274,157.420166 447.780792,156.271423 446.936218,154.765625 
	C446.616913,153.266006 446.310669,152.123428 446.004425,150.980865 
	C448.702118,151.267456 450.071350,150.066879 450.182007,146.945740 
	C450.285431,146.549942 450.717102,146.736710 450.931824,147.156311 
	C451.599335,148.460068 452.052155,149.344238 452.504944,150.228409 
z"/>
<path fill="#F8F9FA" opacity="1.000000" stroke="none" 
	d="
M352.671234,521.971497 
	C354.596161,521.957886 356.313080,522.358154 357.838196,521.981689 
	C361.014496,521.197632 364.089264,520.002380 367.594910,518.963135 
	C364.821198,520.963196 361.870911,523.593506 358.423676,524.780701 
	C355.963684,525.627991 352.836121,524.536743 349.937775,523.893311 
	C350.704865,522.995239 351.540619,522.514465 352.671234,521.971497 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M632.967285,522.831055 
	C632.534607,522.887756 632.101990,522.944397 631.265747,522.565918 
	C628.908142,521.437317 626.954041,520.743835 625.015869,519.718201 
	C625.089661,518.948120 625.147522,518.510315 625.599243,518.064453 
	C628.276489,517.721680 630.559875,517.386780 633.108398,517.328369 
	C635.922607,518.394043 638.471558,519.183289 640.755005,520.264709 
	C637.981995,521.315002 635.474670,522.073059 632.967285,522.831055 
z"/>
<path fill="#F8F9FA" opacity="1.000000" stroke="none" 
	d="
M371.353149,516.986206 
	C371.971497,515.532410 372.959167,514.080566 373.960938,512.303955 
	C373.975067,511.979218 373.967194,511.963165 374.342468,511.982178 
	C376.196930,510.381897 377.676117,508.762543 379.505432,507.100525 
	C381.563751,506.032288 383.272034,505.006683 384.988708,503.989197 
	C384.997131,503.997253 385.015381,504.014526 385.015381,504.014526 
	C384.126801,505.819733 383.665497,508.119171 382.263855,509.330933 
	C378.990265,512.161133 375.264923,514.468811 371.353149,516.986206 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M829.905579,388.942474 
	C833.309814,389.293671 836.714111,389.644867 840.555054,390.354614 
	C840.962891,391.178436 840.934082,391.643707 840.485352,392.104095 
	C836.997314,392.784821 833.929016,393.470398 830.514404,394.126282 
	C829.095337,393.701660 828.022644,393.306763 826.933838,392.524353 
	C826.873230,391.362030 826.828735,390.587158 826.784241,389.812317 
	C826.992737,389.564392 827.219360,389.334320 827.997498,389.046570 
	C828.989136,388.961517 829.447327,388.951996 829.905579,388.942474 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M713.007324,584.992676 
	C709.612610,583.327332 706.217957,581.661926 702.461975,579.984863 
	C701.734436,579.201477 701.368164,578.429810 701.319458,577.331360 
	C702.113159,576.995728 702.589294,576.986877 703.459534,576.977539 
	C709.610535,576.559021 710.953552,581.181702 713.007324,584.992676 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M633.422607,522.960815 
	C635.474670,522.073059 637.981995,521.315002 641.158630,520.264893 
	C642.903992,519.896240 643.980164,519.819580 645.406738,519.800903 
	C646.463074,519.882385 647.169067,519.905762 648.145752,520.128540 
	C648.622314,521.179749 648.828186,522.031555 649.034058,522.883301 
	C643.981995,522.952393 638.929932,523.021484 633.422607,522.960815 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M814.391663,522.995850 
	C812.050964,521.557251 810.076294,520.205017 808.367554,518.412109 
	C810.065430,517.665466 811.497375,517.359314 813.177307,517.316345 
	C814.374817,517.648804 815.324402,517.718140 816.543396,517.768677 
	C817.172546,517.744141 817.532349,517.738464 818.061401,518.031860 
	C818.853699,518.573425 819.476746,518.815857 820.035583,519.384399 
	C818.985168,520.847473 817.998962,521.984497 817.012817,523.121521 
	C816.261108,523.108398 815.509460,523.095337 814.391663,522.995850 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M734.770264,656.051025 
	C734.388855,654.298523 734.253845,652.539429 734.038818,649.738037 
	C742.608459,649.738037 750.736816,649.738037 759.305176,649.631348 
	C760.062927,650.394348 760.380493,651.264038 760.555725,651.743835 
	C752.268616,651.743835 744.147522,651.743835 735.992188,651.743835 
	C735.992188,652.646362 735.992188,653.938538 735.996094,655.615356 
	C736.000000,656.000000 736.002686,655.995850 736.002686,655.995850 
	C735.673401,655.998108 735.344727,656.014343 734.770264,656.051025 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M943.425171,431.138672 
	C942.297913,430.492737 941.501099,429.755707 940.461914,428.702209 
	C940.511475,427.535797 940.803223,426.685883 941.228882,425.842834 
	C941.362671,425.849731 941.618103,425.930908 941.618103,425.930908 
	C944.169983,424.956238 946.721863,423.981537 949.644653,423.006409 
	C947.928955,425.686462 945.842224,428.367004 943.425171,431.138672 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M625.000000,520.050415 
	C626.954041,520.743835 628.908142,521.437317 630.947876,522.477051 
	C625.662170,522.926208 620.290894,523.029114 614.455933,522.838745 
	C613.987549,522.364441 613.982910,522.183411 614.348755,521.984253 
	C616.481140,521.328186 618.243103,520.690308 620.420898,520.037903 
	C622.224548,520.032349 623.612244,520.041382 625.000000,520.050415 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M817.248962,523.361755 
	C817.998962,521.984497 818.985168,520.847473 820.054932,519.119873 
	C820.092773,518.358826 820.046936,518.188354 820.403320,517.942749 
	C822.157410,517.934937 823.509155,518.002258 824.910339,518.369263 
	C825.314392,518.752014 825.669006,518.834961 825.933350,518.981201 
	C825.843079,519.044495 825.888062,519.260315 825.888062,519.260315 
	C826.282837,520.220642 826.677612,521.181030 827.142700,522.446533 
	C823.970398,523.035156 820.727783,523.318542 817.248962,523.361755 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M601.415771,182.081299 
	C601.444885,180.808319 601.730835,179.311676 602.272583,177.236893 
	C602.732056,176.099991 602.935852,175.541229 603.366821,174.759796 
	C604.076416,173.119019 604.558777,171.700928 605.272095,170.020142 
	C605.343262,168.726990 605.183350,167.696533 605.150146,166.507935 
	C605.500488,166.029083 605.724243,165.708420 606.267883,165.318329 
	C607.227783,165.144318 607.867798,165.039749 608.507812,164.935196 
	C606.229370,170.576019 603.950928,176.216843 601.415771,182.081299 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M734.656555,706.541626 
	C734.372681,695.925354 734.288025,684.964294 734.342163,673.564331 
	C734.605957,684.149170 734.730896,695.173035 734.656555,706.541626 
z"/>
<path fill="#BFC0BD" opacity="1.000000" stroke="none" 
	d="
M826.398926,389.844635 
	C826.828735,390.587158 826.873230,391.362030 826.694946,392.668091 
	C826.105042,393.614899 825.738037,394.030487 825.370972,394.446106 
	C822.685669,392.430176 820.000244,390.414215 817.159302,388.080872 
	C818.261780,387.974945 819.537048,388.115448 820.773865,388.414795 
	C822.535217,388.841095 824.268433,389.383911 826.398926,389.844635 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M755.962585,522.860046 
	C752.948853,522.927551 749.935120,522.995056 746.475952,522.617798 
	C746.014282,521.452881 745.997925,520.732788 746.437378,519.983032 
	C749.938782,519.979980 752.984314,520.006409 756.025513,520.387085 
	C756.001648,521.447571 755.982117,522.153809 755.962585,522.860046 
z"/>
<path fill="#060B10" opacity="1.000000" stroke="none" 
	d="
M612.287964,155.482941 
	C612.114075,156.119110 611.940186,156.755280 611.273438,157.731842 
	C610.187073,159.048141 609.593567,160.024063 609.000000,161.000000 
	C609.000000,161.000000 609.000000,161.000610 608.977173,160.589767 
	C610.957458,155.110306 612.960632,150.041687 614.963745,144.973053 
	C614.963745,144.973038 615.037903,145.038223 615.041992,145.445221 
	C614.126648,149.062469 613.207275,152.272705 612.287964,155.482941 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M593.425476,201.077637 
	C594.970154,196.559769 596.808838,191.866486 598.934937,187.044159 
	C597.388062,191.577499 595.553772,196.239868 593.425476,201.077637 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M768.973145,520.020996 
	C770.611084,520.755920 772.249084,521.490784 773.954346,522.574524 
	C770.967224,522.985352 767.912659,523.047363 764.416016,522.640991 
	C763.992615,521.459656 764.011353,520.746521 764.441345,520.018250 
	C766.226135,520.009033 767.599609,520.015015 768.973145,520.020996 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M649.353699,522.961487 
	C648.828186,522.031555 648.622314,521.179749 648.170532,519.915649 
	C647.924622,519.503235 647.970703,519.075500 648.255493,519.051941 
	C648.729858,519.008423 648.919312,518.988525 649.547119,518.963745 
	C652.340271,518.977722 654.695068,518.996521 657.049805,519.015259 
	C656.713745,520.069214 656.377625,521.123230 656.001953,522.506165 
	C655.531799,522.888428 655.101318,522.941833 654.294067,522.600586 
	C652.935120,522.428894 651.952942,522.651794 650.970703,522.874634 
	C650.538269,522.929626 650.105774,522.984680 649.353699,522.961487 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M745.981567,520.012573 
	C745.997925,520.732788 746.014282,521.452881 746.031311,522.531982 
	C743.635010,522.968933 741.238037,523.046936 738.406372,522.650940 
	C737.987305,521.455383 738.002808,520.733887 738.459839,520.000488 
	C741.261414,519.996643 743.621521,520.004639 745.981567,520.012573 
z"/>
<path fill="#6D48B3" opacity="1.000000" stroke="none" 
	d="
M231.035889,676.603333 
	C228.079758,677.409241 224.908524,678.082825 223.356232,673.888062 
	C225.943054,674.436829 228.412964,675.361145 231.035889,676.603333 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M821.169189,569.665894 
	C819.899353,570.482910 818.768982,570.983826 817.638550,571.484741 
	C817.328064,571.239136 817.017517,570.993530 816.707031,570.747925 
	C817.960327,568.962036 819.213623,567.176147 820.752136,565.193359 
	C821.127869,566.447571 821.218262,567.898621 821.169189,569.665894 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M612.572144,155.278442 
	C613.207275,152.272705 614.126648,149.062469 615.077759,145.479248 
	C615.475586,145.047958 615.841614,144.989670 616.486450,144.942230 
	C615.462280,148.326691 614.159241,151.700317 612.572144,155.278442 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M925.327515,452.929016 
	C925.224731,452.004150 925.437988,450.979614 925.711670,449.664490 
	C924.891235,449.769287 924.190125,449.858826 923.244385,449.625977 
	C923.390686,448.787964 923.781494,448.272339 924.490845,447.798340 
	C925.826660,447.536438 926.843994,447.232880 928.200317,446.949036 
	C928.988770,446.941498 929.438232,446.914246 929.927124,446.926514 
	C929.966675,446.966064 930.045837,447.045044 930.045837,447.045044 
	C928.578369,448.973145 927.110901,450.901276 925.327515,452.929016 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M692.493530,520.404419 
	C693.766785,521.042725 694.756165,521.724670 695.875183,522.699829 
	C693.830200,523.197083 691.655579,523.401245 689.248718,523.401611 
	C690.080811,522.281250 691.145264,521.364624 692.493530,520.404419 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M244.939072,614.533142 
	C243.954605,614.173340 242.177460,613.360474 242.204880,612.613953 
	C242.249619,611.396484 243.472031,610.222229 244.592072,609.018616 
	C244.976685,610.708313 244.966721,612.409058 244.939072,614.533142 
z"/>
<path fill="#EDF5FA" opacity="1.000000" stroke="none" 
	d="
M826.599487,620.081543 
	C826.987366,622.697632 826.991821,625.401611 826.990845,628.556458 
	C823.429993,626.348511 824.753784,623.265015 826.599487,620.081543 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M571.524414,388.816772 
	C572.513855,389.919373 573.214600,391.122833 574.001770,392.662811 
	C574.088196,392.999329 574.046875,392.981995 573.666626,392.908752 
	C571.829163,393.165070 570.372009,393.494629 568.914795,393.824188 
	C568.999817,392.883331 569.084839,391.942505 569.201477,390.653320 
	C569.900635,389.842529 570.568237,389.380066 571.524414,388.816772 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M622.573608,131.157196 
	C623.090759,128.837875 623.931641,126.251076 624.772583,123.664276 
	C625.195496,123.762444 625.618469,123.860611 626.041382,123.958778 
	C626.041382,125.384727 626.041382,126.810684 625.850708,128.512802 
	C624.739136,129.489212 623.818237,130.189453 622.573608,131.157196 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M404.966675,653.459473 
	C405.622803,654.143616 406.265472,655.249023 406.970612,656.682251 
	C406.873230,660.153320 406.713409,663.296387 406.243469,666.706299 
	C405.606628,662.608887 405.279907,658.244751 404.966675,653.459473 
z"/>
<path fill="#8F8E92" opacity="1.000000" stroke="none" 
	d="
M699.925903,390.983887 
	C699.993530,390.191925 700.061218,389.399963 700.053711,388.362457 
	C701.703918,387.994751 703.429382,387.872559 705.578857,387.849243 
	C705.691772,388.843506 705.380615,389.738861 704.576050,390.906738 
	C703.164001,391.149658 702.245361,391.120087 700.977051,391.055847 
	C700.393677,391.008789 700.159790,390.996338 699.925903,390.983887 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M129.847260,520.940674 
	C130.186340,520.475403 130.525406,520.010132 131.239136,519.088379 
	C133.051483,518.593079 134.489166,518.554199 135.926849,518.515320 
	C135.923676,519.155090 135.920486,519.794861 135.903748,520.758118 
	C134.194138,521.374451 132.498077,521.667297 130.371674,521.726013 
	C129.909958,521.308105 129.878616,521.124390 129.847260,520.940674 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M756.373169,522.984131 
	C755.982117,522.153809 756.001648,521.447571 756.436646,520.372070 
	C758.225098,520.012390 759.598022,520.021973 760.976929,520.391968 
	C760.997437,521.470520 761.012024,522.188721 761.026611,522.906860 
	C759.612366,522.973999 758.198059,523.041077 756.373169,522.984131 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M937.439087,438.219360 
	C936.187439,434.946716 938.315369,433.773773 941.454346,433.013184 
	C940.453125,434.640442 939.090210,436.335815 937.439087,438.219360 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M580.557983,232.329208 
	C581.152161,230.093826 581.997314,227.621429 583.158203,225.040100 
	C582.585510,227.318161 581.697205,229.705170 580.557983,232.329208 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M738.018311,520.012390 
	C738.002808,520.733887 737.987305,521.455383 737.970703,522.535828 
	C736.915283,522.952820 735.860962,523.010803 734.417114,522.988770 
	C734.014221,522.185120 734.000854,521.461426 734.387939,520.345703 
	C735.864990,519.973267 736.941650,519.992859 738.018311,520.012390 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M184.074036,520.693604 
	C183.783005,520.045471 183.921448,519.508179 184.456955,518.556030 
	C185.858810,518.207031 186.863617,518.272888 187.868408,518.338684 
	C187.921982,519.001892 187.975540,519.665161 188.017441,520.661682 
	C186.838364,520.931458 185.670929,520.867920 184.074036,520.693604 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M734.525757,719.621338 
	C734.257507,716.611938 734.229370,713.294312 734.329468,709.545166 
	C734.560364,712.513489 734.663025,715.913391 734.525757,719.621338 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M694.051636,389.141876 
	C694.450439,389.211334 694.411011,389.699951 694.317993,389.926971 
	C692.726929,390.439423 691.228760,390.724884 689.345947,390.960815 
	C688.931946,386.233643 692.040161,389.560822 694.051636,389.141876 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M849.048462,597.330933 
	C850.324097,598.101135 851.627014,599.215332 852.956665,600.674927 
	C851.680786,599.905151 850.378235,598.790100 849.048462,597.330933 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M391.661377,545.250488 
	C391.916382,546.600769 391.917877,548.224243 391.597961,549.947144 
	C391.320312,548.538940 391.364075,547.031311 391.661377,545.250488 
z"/>
<path fill="#060B10" opacity="1.000000" stroke="none" 
	d="
M608.676697,164.662994 
	C607.867798,165.039749 607.227783,165.144318 606.294678,164.923920 
	C607.001099,163.399490 608.000549,162.200043 609.000000,161.000610 
	C609.000000,161.000610 609.000000,161.000000 608.976440,161.358673 
	C608.996582,162.191696 609.040344,162.666016 609.084106,163.140335 
	C609.004578,163.557159 608.925110,163.973969 608.676697,164.662994 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M271.848511,694.162354 
	C272.429016,695.705750 272.722595,697.454468 272.957458,699.593445 
	C272.453064,698.111694 272.007355,696.239746 271.848511,694.162354 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M589.911804,210.658844 
	C590.081604,209.327927 590.441956,207.724350 591.087769,206.022369 
	C590.949707,207.411392 590.526062,208.898788 589.911804,210.658844 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M444.406433,571.976807 
	C445.181946,569.111755 446.883484,569.319702 448.940186,571.510681 
	C447.625366,571.895630 446.226013,571.938721 444.406433,571.976807 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M372.034485,557.627686 
	C371.026215,556.571289 370.011902,555.149292 368.993286,553.375183 
	C370.002136,554.436096 371.015289,555.849060 372.034485,557.627686 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M915.034180,466.633118 
	C915.557190,465.030884 916.298340,463.592438 917.361938,462.076904 
	C917.485352,463.104553 917.286255,464.209320 917.044800,465.657196 
	C916.419128,466.265839 915.835754,466.531372 915.034180,466.633118 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M609.348999,162.961884 
	C609.040344,162.666016 608.996582,162.191696 608.976440,161.358673 
	C609.593567,160.024063 610.187073,159.048141 611.062622,157.986404 
	C610.767639,159.528198 610.190796,161.155807 609.348999,162.961884 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M632.363159,348.264618 
	C633.212463,349.371948 634.128174,350.803864 635.028320,352.586426 
	C634.151794,351.487762 633.290649,350.038483 632.363159,348.264618 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M470.800598,507.532135 
	C471.934204,508.519836 473.034393,509.857635 474.108459,511.547729 
	C472.977295,510.560760 471.872253,509.221497 470.800598,507.532135 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M761.391357,522.978638 
	C761.012024,522.188721 760.997437,521.470520 761.358887,520.358643 
	C762.499939,519.987732 763.265015,520.010620 764.030090,520.033447 
	C764.011353,520.746521 763.992615,521.459656 763.971252,522.526611 
	C763.231079,522.937134 762.493591,522.993774 761.391357,522.978638 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M808.988525,627.652954 
	C807.996399,626.912170 807.002502,625.821899 806.005554,624.368286 
	C806.997253,625.104431 807.992004,626.203979 808.988525,627.652954 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M624.668701,27.181721 
	C623.664795,27.531075 622.415405,27.718100 620.792664,27.756199 
	C621.753967,27.411312 623.088623,27.215351 624.668701,27.181721 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M248.300598,571.883789 
	C249.049149,571.223816 250.176712,570.619690 251.680145,570.034973 
	C250.930542,570.682800 249.805069,571.311279 248.300598,571.883789 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M475.303192,390.979187 
	C475.563263,392.070435 475.523468,393.271484 475.234741,394.734955 
	C474.991638,393.694580 474.997467,392.391785 475.303192,390.979187 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M921.016785,603.625427 
	C920.189087,603.110779 919.351501,602.243713 918.755737,601.188354 
	C919.667358,601.757690 920.337158,602.515381 921.016785,603.625427 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M703.065491,576.978027 
	C702.589294,576.986877 702.113159,576.995728 701.278320,577.011963 
	C700.290894,576.570862 699.662231,576.122314 698.978271,575.360840 
	C700.135132,575.191162 701.347168,575.334412 702.799683,575.992126 
	C703.040161,576.506531 703.065491,576.978027 703.065491,576.978027 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M474.126526,512.275024 
	C475.070129,513.093506 476.032532,514.259460 476.932983,515.767517 
	C475.962494,514.947327 475.053925,513.784973 474.126526,512.275024 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M613.978271,522.002441 
	C613.982910,522.183411 613.987549,522.364441 613.987549,522.817322 
	C613.014526,522.398987 612.046204,521.708801 611.198364,520.663330 
	C611.247437,519.984802 611.311829,519.691162 611.791626,519.682251 
	C612.706970,520.625671 613.342590,521.314026 613.978271,522.002441 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M930.941040,443.991150 
	C931.531250,443.728851 932.121521,443.466583 933.034424,443.154175 
	C932.468628,444.357819 931.580200,445.611542 930.368835,446.955139 
	C930.045837,447.045044 929.966675,446.966064 929.964722,446.610107 
	C929.974365,445.827545 929.985962,445.400909 930.192627,444.849060 
	C930.635864,444.527649 930.820312,444.283417 930.941040,443.991150 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M472.615479,550.983826 
	C472.203369,552.246155 471.460175,553.578979 470.381592,554.966187 
	C470.792236,553.698425 471.538300,552.376404 472.615479,550.983826 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M918.378540,461.084167 
	C918.759216,460.158112 919.445190,459.124146 920.460388,458.042908 
	C920.087708,458.989166 919.385742,459.982697 918.378540,461.084167 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M390.336487,127.222412 
	C390.093262,126.338661 390.033356,125.211952 389.916626,123.016502 
	C391.019440,124.269035 391.501617,124.816658 391.995850,125.707367 
	C391.511871,126.360107 391.015808,126.669777 390.336487,127.222412 
z"/>
<path fill="#EDF5FA" opacity="1.000000" stroke="none" 
	d="
M452.813049,150.058777 
	C452.052155,149.344238 451.599335,148.460068 451.134918,147.274887 
	C451.543488,146.978317 451.963715,146.982758 452.699432,146.993805 
	C453.050354,147.963333 453.085724,148.926239 452.813049,150.058777 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M607.453003,521.054810 
	C607.695801,520.039124 608.255371,518.976135 609.106262,517.651978 
	C609.587280,517.494202 609.776978,517.597534 610.014771,518.021484 
	C609.298584,519.230530 608.534180,520.119019 607.453003,521.054810 
z"/>
<path fill="#F8F9FA" opacity="1.000000" stroke="none" 
	d="
M386.993530,500.994080 
	C386.570526,501.969818 386.134949,502.933533 385.357361,503.955872 
	C385.015381,504.014526 384.997131,503.997253 384.993652,503.637878 
	C385.645325,502.505066 386.300507,501.731689 386.955688,500.958313 
	C386.955688,500.958313 386.980896,500.982025 386.993530,500.994080 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M577.036011,387.980957 
	C576.207458,388.119537 575.404907,388.034515 574.220703,387.811646 
	C574.803223,387.366119 575.767273,387.058411 576.898560,387.028931 
	C577.065796,387.307159 577.061890,387.757355 577.036011,387.980957 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M635.827698,386.983856 
	C635.134949,387.404083 634.277161,387.543182 633.216553,387.487671 
	C633.896667,387.096313 634.779663,386.899506 635.827698,386.983856 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M818.699158,683.142578 
	C818.054565,682.577026 817.526001,681.632874 817.017883,680.352112 
	C817.630676,680.931641 818.222961,681.847778 818.699158,683.142578 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M808.787048,658.003906 
	C808.108093,657.154114 807.526733,655.937500 806.979492,654.376709 
	C807.637390,655.234070 808.261047,656.435486 808.787048,658.003906 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M814.695068,673.129761 
	C814.002380,672.252197 813.413696,671.010681 812.834656,669.409058 
	C813.495850,670.287903 814.147461,671.526855 814.695068,673.129761 
z"/>
<path fill="#F4F8FC" opacity="1.000000" stroke="none" 
	d="
M527.350891,627.955688 
	C527.015869,627.253906 527.012207,626.499023 527.017456,625.366455 
	C527.244995,625.960144 527.463623,626.931335 527.350891,627.955688 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M830.296692,712.773621 
	C829.611755,711.921082 829.156677,710.874634 828.690918,709.469604 
	C829.295654,710.267334 829.911133,711.423584 830.296692,712.773621 
z"/>
<path fill="#EDF5FA" opacity="1.000000" stroke="none" 
	d="
M663.363586,619.955627 
	C663.450317,619.045837 663.878601,618.078247 664.651001,617.057312 
	C664.565125,617.968628 664.135193,618.933167 663.363586,619.955627 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M651.345581,522.993469 
	C651.952942,522.651794 652.935120,522.428894 653.975464,522.522217 
	C653.262512,522.929810 652.491455,523.021057 651.345581,522.993469 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M603.233826,523.419678 
	C603.795166,522.813477 604.574829,522.417358 605.706909,522.051331 
	C605.190247,522.597534 604.321167,523.113647 603.233826,523.419678 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M337.190063,526.259766 
	C337.822937,525.750000 338.641693,525.478516 339.791443,525.315369 
	C339.206970,525.781799 338.291473,526.139893 337.190063,526.259766 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M456.941711,541.666748 
	C456.282684,541.243042 455.645905,540.465515 455.008087,539.337036 
	C455.659363,539.761719 456.311646,540.537292 456.941711,541.666748 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M129.519333,520.963623 
	C129.878616,521.124390 129.909958,521.308105 129.998535,521.765747 
	C129.238831,522.357178 128.421890,522.674683 127.282181,522.901917 
	C127.703407,522.203308 128.447403,521.594971 129.519333,520.963623 
z"/>
<path fill="#BFC0BD" opacity="1.000000" stroke="none" 
	d="
M953.332581,419.977600 
	C953.238647,419.392761 953.418884,418.774017 953.846802,418.076263 
	C953.931885,418.646057 953.769287,419.294922 953.332581,419.977600 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M826.631348,703.225708 
	C825.982666,702.616150 825.468506,701.659058 824.987671,700.363220 
	C825.602600,700.975708 826.184265,701.927002 826.631348,703.225708 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M524.193176,523.312195 
	C524.469238,522.722717 524.919800,522.353088 525.676880,521.986145 
	C525.444885,522.503174 524.906250,523.017639 524.193176,523.312195 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M811.642517,665.226929 
	C811.112183,664.881775 810.691467,664.211365 810.340698,663.236572 
	C810.857788,663.588684 811.304993,664.245239 811.642517,665.226929 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M824.985168,561.333862 
	C825.519531,561.233276 826.035828,561.466553 826.552124,561.699829 
	C826.235779,562.107239 825.919434,562.514648 825.306152,562.963013 
	C824.995117,562.558594 824.981140,562.113159 824.985168,561.333862 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M381.903625,584.776306 
	C381.438751,584.499695 380.993073,583.915955 380.774292,583.166504 
	C381.308441,583.490295 381.615631,583.979736 381.903625,584.776306 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M541.956909,522.365540 
	C542.511169,522.248291 543.092224,522.410950 543.834106,522.841370 
	C543.324463,522.954590 542.654175,522.800049 541.956909,522.365540 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M180.185394,523.307739 
	C180.479874,522.741760 180.950302,522.385620 181.732361,522.045227 
	C181.483109,522.546448 180.922226,523.031982 180.185394,523.307739 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M380.927368,578.752319 
	C380.455933,578.489624 379.990417,577.919006 379.744202,577.174683 
	C380.286804,577.482117 380.610046,577.963196 380.927368,578.752319 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M74.999390,564.000000 
	C74.550644,564.640137 74.101891,565.280212 73.333786,565.961609 
	C72.995201,565.559204 72.975990,565.115540 72.972656,564.339111 
	C73.435707,564.004028 73.882874,564.001648 74.665024,563.999634 
	C75.000000,564.000000 74.999390,564.000000 74.999390,564.000000 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M388.713165,641.633545 
	C387.741943,641.801147 386.509155,641.699036 384.971863,641.373291 
	C385.928772,641.221008 387.190216,641.292419 388.713165,641.633545 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M951.211243,522.883911 
	C951.374329,522.268677 951.872925,521.676880 952.682373,521.039917 
	C952.511047,521.632202 952.028931,522.269775 951.211243,522.883911 
z"/>
<path fill="#BFC0BD" opacity="1.000000" stroke="none" 
	d="
M696.843567,387.750671 
	C696.566833,388.363525 696.131226,388.738525 695.389038,389.101135 
	C695.616516,388.563416 696.150574,388.038086 696.843567,387.750671 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M479.091644,387.306458 
	C478.808838,387.918915 478.187500,388.415253 477.252716,388.884918 
	C477.543884,388.302277 478.148499,387.746338 479.091644,387.306458 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M618.612915,28.933540 
	C618.522278,29.536560 618.149170,30.246502 617.440674,30.991104 
	C617.513733,30.363997 617.922058,29.702229 618.612915,28.933540 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M935.327942,440.996857 
	C935.448853,440.416748 935.868103,439.754944 936.605835,439.046021 
	C936.491577,439.637695 936.058960,440.276428 935.327942,440.996857 
z"/>
<path fill="#ACADA9" opacity="1.000000" stroke="none" 
	d="
M76.817322,387.523926 
	C76.548416,388.097382 75.994591,388.524506 75.149872,388.935913 
	C75.416786,388.405975 75.974594,387.891815 76.817322,387.523926 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M469.205933,505.130829 
	C469.822205,505.370178 470.372162,505.934662 470.865234,506.824219 
	C470.252106,506.584900 469.695862,506.020447 469.205933,505.130829 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M544.833923,537.793213 
	C544.508545,538.331238 544.019531,538.638123 543.222412,538.932373 
	C543.499634,538.467224 544.084961,538.014648 544.833923,537.793213 
z"/>
<path fill="#BFC0BD" opacity="1.000000" stroke="none" 
	d="
M829.938477,388.640869 
	C829.447327,388.951996 828.989136,388.961517 828.192017,388.901978 
	C828.433533,388.342072 829.013916,387.851227 829.781738,387.546448 
	C829.969971,387.934784 829.970703,388.137054 829.938477,388.640869 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M855.037720,604.324341 
	C855.636597,604.459045 856.276062,604.913208 856.949524,605.690552 
	C856.348389,605.557129 855.713379,605.100464 855.037720,604.324341 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M648.716797,390.920349 
	C648.118286,390.681488 647.596436,390.116028 647.083862,389.240234 
	C647.659973,389.484528 648.226746,390.039154 648.716797,390.920349 
z"/>
<path fill="#F8F9FA" opacity="1.000000" stroke="none" 
	d="
M369.231995,518.919495 
	C369.380432,518.309570 369.863312,517.685547 370.665405,517.020752 
	C370.511871,517.621765 370.039124,518.263611 369.231995,518.919495 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M625.582031,337.196045 
	C626.089417,337.589172 626.473877,338.269043 626.750427,339.252136 
	C626.248047,338.864502 625.853577,338.173615 625.582031,337.196045 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M585.213867,220.943512 
	C585.172791,220.486908 585.302673,219.893005 585.713074,219.151260 
	C585.790649,219.604340 585.587708,220.205276 585.213867,220.943512 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M588.348511,215.047165 
	C588.305359,214.436920 588.560669,213.773758 589.082397,213.016571 
	C589.114868,213.613113 588.880920,214.303680 588.348511,215.047165 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M458.640503,571.988647 
	C458.529144,572.589661 458.102142,573.253174 457.353607,573.965027 
	C457.463013,573.359253 457.893921,572.705261 458.640503,571.988647 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M578.464600,237.271393 
	C578.410034,236.686508 578.587219,235.925034 579.053955,235.042374 
	C579.127869,235.645721 578.912170,236.370255 578.464600,237.271393 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M787.949829,602.688110 
	C787.350952,602.547913 786.717407,602.086609 786.051514,601.301880 
	C786.651123,601.441345 787.283081,601.904175 787.949829,602.688110 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M782.990784,596.673950 
	C782.468628,596.770264 781.953979,596.532227 781.439392,596.294189 
	C781.759583,595.886902 782.079712,595.479614 782.698730,595.037354 
	C782.997803,595.448181 782.998047,595.893860 782.990784,596.673950 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M527.652100,520.076843 
	C527.435120,519.603149 527.443909,519.057251 527.706299,518.174805 
	C527.932495,518.560425 527.905151,519.282532 527.652100,520.076843 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M455.102356,522.779419 
	C455.259735,522.144836 455.762238,521.559875 456.567474,520.922607 
	C456.395966,521.523193 455.921753,522.176086 455.102356,522.779419 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M630.287292,345.208496 
	C630.819397,345.480865 631.373840,346.056854 631.884277,346.960022 
	C631.330078,346.695496 630.819885,346.103790 630.287292,345.208496 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M73.176117,521.187866 
	C73.801704,521.300415 74.414818,521.754883 75.098785,522.503174 
	C74.500969,522.374512 73.832306,521.952148 73.176117,521.187866 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M454.312775,570.941040 
	C454.462097,570.343506 454.931183,569.713806 455.726379,569.057983 
	C455.579163,569.657593 455.105865,570.283264 454.312775,570.941040 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M786.964844,651.132935 
	C787.049622,651.849976 786.818359,652.724670 786.287415,653.760254 
	C786.208069,653.044189 786.428467,652.167419 786.964844,651.132935 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M273.108765,701.998718 
	C273.442566,702.227966 273.730255,702.787476 273.974854,703.666138 
	C273.642059,703.433228 273.352356,702.881042 273.108765,701.998718 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M824.824402,698.963013 
	C824.445007,698.771851 824.128845,698.246521 823.824219,697.403259 
	C824.186340,697.599792 824.536987,698.114319 824.824402,698.963013 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M788.001343,648.169739 
	C788.175659,648.684753 788.004883,649.393677 787.562012,650.354553 
	C787.412048,649.858948 787.534119,649.111267 788.001343,648.169739 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M832.570007,718.759949 
	C832.180542,718.579773 831.944519,718.192566 831.708862,717.459351 
	C832.047363,717.593262 832.385437,718.073120 832.570007,718.759949 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M819.767944,686.037170 
	C819.372498,685.831238 819.058472,685.277649 818.751099,684.398071 
	C819.121582,684.611206 819.485474,685.150391 819.767944,686.037170 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M816.877319,678.870605 
	C816.494690,678.689575 816.155457,678.181396 815.831543,677.356689 
	C816.204834,677.541321 816.562805,678.042358 816.877319,678.870605 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M400.882721,675.619751 
	C400.853210,675.148132 401.141693,674.655151 401.705627,674.070312 
	C401.720947,674.518372 401.460815,675.058350 400.882721,675.619751 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M403.858643,672.016357 
	C403.758179,671.657288 403.909576,671.203918 404.297699,670.634277 
	C404.393127,670.986145 404.251831,671.454102 403.858643,672.016357 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M806.823364,652.947632 
	C806.423218,652.767273 806.091187,652.245972 805.755676,651.407104 
	C806.131958,651.595276 806.511719,652.101013 806.823364,652.947632 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M805.680542,650.107910 
	C805.264343,649.899231 804.962158,649.335510 804.651611,648.447937 
	C805.027039,648.666992 805.410828,649.209961 805.680542,650.107910 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M827.659607,706.179321 
	C827.395020,706.183472 827.178528,705.891418 826.945923,705.322021 
	C827.189148,705.324158 827.448425,705.603638 827.659607,706.179321 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M828.577393,708.229858 
	C828.288696,708.240356 828.062073,707.942444 827.817139,707.358887 
	C828.079102,707.355957 828.359314,707.638672 828.577393,708.229858 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M831.653015,716.188599 
	C831.379578,716.209412 831.142700,715.931702 830.885376,715.376587 
	C831.139771,715.362793 831.414612,715.626465 831.653015,716.188599 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M350.688416,719.119263 
	C350.915710,719.169373 350.993683,719.325012 351.034363,719.739136 
	C350.844360,719.740051 350.691742,719.482483 350.688416,719.119263 
z"/>
<path fill="#EDF5FA" opacity="1.000000" stroke="none" 
	d="
M652.783691,653.182739 
	C652.940430,653.401550 652.832825,653.803589 652.466736,654.397217 
	C652.311951,654.181213 652.415649,653.773682 652.783691,653.182739 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M271.371796,647.660645 
	C271.650360,647.701599 271.842896,648.042480 271.968079,648.685547 
	C271.695770,648.645386 271.490753,648.302917 271.371796,647.660645 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M735.997925,656.001343 
	C736.118347,656.143982 736.240723,656.285217 736.363098,656.426514 
	C736.277527,656.376343 736.192017,656.326172 736.054565,656.135925 
	C736.002686,655.995850 736.000000,656.000000 735.997925,656.001343 
z"/>
<path fill="#EDF5FA" opacity="1.000000" stroke="none" 
	d="
M650.786621,658.185913 
	C650.951416,658.407837 650.849854,658.814453 650.486145,659.413452 
	C650.322754,659.194031 650.421509,658.782349 650.786621,658.185913 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M247.118134,659.895081 
	C247.292038,659.760315 247.459183,659.823059 247.790619,660.097412 
	C247.673752,660.236816 247.392548,660.164734 247.118134,659.895081 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M255.183594,660.677490 
	C255.095703,660.598694 255.501205,660.397095 255.702667,660.500427 
	C255.904144,660.603760 255.612274,660.789673 255.612274,660.789673 
	C255.612274,660.789673 255.271484,660.756287 255.183594,660.677490 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M809.767578,661.091431 
	C809.526062,661.084473 809.318848,660.810669 809.135986,660.272278 
	C809.374207,660.280029 809.587952,660.552246 809.767578,661.091431 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M458.787964,663.113525 
	C458.953369,663.021179 459.222229,663.417236 459.199310,663.654907 
	C458.991760,663.663696 458.807159,663.434814 458.787964,663.113525 
z"/>
<path fill="#F4F8FC" opacity="1.000000" stroke="none" 
	d="
M497.373138,663.768677 
	C497.214569,663.576355 497.277985,663.184326 497.583191,662.600830 
	C497.748322,662.795898 497.671722,663.182495 497.373138,663.768677 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M459.308838,664.912170 
	C459.562073,664.906250 459.783051,665.173767 459.996460,665.707092 
	C459.751465,665.710510 459.514038,665.448059 459.308838,664.912170 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M812.702759,668.150879 
	C812.440979,668.156372 812.216858,667.870972 811.989075,667.308228 
	C812.237061,667.307251 812.488708,667.583618 812.702759,668.150879 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M462.289612,670.953491 
	C462.550140,670.907410 462.806763,671.142212 463.094910,671.642029 
	C462.846191,671.682861 462.565979,671.458679 462.289612,670.953491 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M399.128967,676.834351 
	C399.059052,676.601868 399.238495,676.327087 399.668945,676.017578 
	C399.739380,676.252625 399.558868,676.522339 399.128967,676.834351 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M820.807983,689.006348 
	C820.564758,689.022034 820.322083,688.779541 820.105957,688.274048 
	C820.357910,688.256775 820.583252,688.502502 820.807983,689.006348 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M821.678040,691.165100 
	C821.411926,691.161011 821.194580,690.873779 821.000366,690.311157 
	C821.257935,690.317810 821.492371,690.599915 821.678040,691.165100 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M822.623413,693.209351 
	C822.341858,693.220886 822.112305,692.926880 821.866394,692.348389 
	C822.125183,692.343933 822.400208,692.623840 822.623413,693.209351 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M823.201538,694.883911 
	C823.032959,694.987549 822.740540,694.604309 822.735840,694.363892 
	C822.944092,694.342468 823.157104,694.561340 823.201538,694.883911 
z"/>
<path fill="#010101" opacity="1.000000" stroke="none" 
	d="
M598.975952,96.974686 
	C600.391785,95.479118 602.281677,94.214462 603.142029,92.448303 
	C607.009033,84.509926 610.911926,76.558983 614.156677,68.357323 
	C616.615845,62.141567 617.887817,55.465942 620.101074,49.139442 
	C621.417725,45.376038 623.345825,41.778599 625.414795,38.358021 
	C625.973755,37.433899 628.045044,37.068878 629.428101,37.055408 
	C640.092651,36.951523 650.759094,36.935905 661.423950,37.021519 
	C667.089966,37.067001 668.199158,38.710068 668.094971,44.462017 
	C668.017456,48.744598 668.955444,53.040249 668.958069,57.330379 
	C669.013428,147.654068 669.016846,237.977844 668.948120,328.301544 
	C668.944824,332.629700 668.368896,336.987518 667.661316,341.265656 
	C667.462708,342.466309 665.829712,344.366241 664.875366,344.353851 
	C658.507324,344.271179 652.108521,344.017120 645.815247,343.136047 
	C644.723572,342.983185 643.618042,339.646790 643.363098,337.671814 
	C643.075684,335.445953 643.962219,333.096893 643.963379,330.801208 
	C644.001770,255.309265 644.045410,179.817154 643.869629,104.325584 
	C643.859131,99.819061 642.422363,95.186516 640.950256,90.849892 
	C639.536865,86.686119 635.352356,85.647255 632.387878,88.844101 
	C629.536255,91.919235 627.576904,95.843208 625.358643,99.475571 
	C624.182556,101.401329 623.364319,103.542328 622.250122,105.510040 
	C620.767822,108.127716 618.705566,110.047050 619.324585,113.948586 
	C619.742371,116.582443 616.471252,119.667496 615.282898,122.719215 
	C613.080444,128.374893 611.288940,134.189911 609.120178,139.859726 
	C608.468201,141.564178 606.901245,142.905045 606.157593,144.590744 
	C604.386719,148.604996 602.916077,152.750488 601.214294,156.796768 
	C599.202454,161.580353 597.075195,166.315399 595.173462,170.669601 
	C587.003906,170.937103 584.054993,160.343124 574.875244,163.777390 
	C575.314819,161.541733 575.395630,159.631393 576.066345,157.957413 
	C581.204407,145.134415 586.492676,132.371597 591.634277,119.550003 
	C592.362061,117.735161 592.549622,115.703712 592.998596,113.129105 
	C593.010010,112.485687 593.044006,112.009842 593.384888,111.952591 
	C597.684631,107.699883 599.784058,102.850044 598.982788,96.981941 
	C598.989746,96.986923 598.975952,96.974686 598.975952,96.974686 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M603.002441,75.001228 
	C607.955139,63.087818 613.651917,51.414135 617.594788,39.175392 
	C620.157166,31.221714 624.213806,29.452522 631.872437,29.839729 
	C644.837952,30.495249 657.861328,30.060183 670.859680,29.980690 
	C674.221375,29.960133 676.589783,30.291470 675.169922,34.682426 
	C674.680542,36.195999 675.042786,37.990074 675.042786,39.656010 
	C675.042969,141.980347 675.045898,244.304688 675.048584,346.629028 
	C675.048584,348.088348 675.048523,349.547668 675.048523,351.011627 
	C663.438416,351.011627 652.314148,350.954742 641.191162,351.046082 
	C637.824585,351.073700 635.596558,350.771484 636.785400,346.352661 
	C637.414246,344.015594 636.969666,341.383392 636.969849,338.884430 
	C636.976440,260.224670 636.971680,181.564926 637.014404,102.905190 
	C637.015625,100.699608 637.517090,98.494301 637.785217,96.288872 
	C638.459106,96.324745 639.132996,96.360626 639.806885,96.396500 
	C640.199707,99.566132 640.932861,102.735481 640.935486,105.905434 
	C640.999817,183.565216 640.986267,261.225067 640.987732,338.884918 
	C640.987854,345.789246 641.577515,346.605988 648.543335,346.930908 
	C654.363525,347.202393 660.206299,346.991425 666.039062,346.992218 
	C669.292236,346.992676 670.987549,345.395325 671.008118,342.111237 
	C671.022766,339.778168 671.016479,337.445007 671.016541,335.111877 
	C671.017334,237.620438 671.018188,140.128983 671.017334,42.637547 
	C671.017273,35.789913 669.338196,34.035797 662.570557,34.012772 
	C652.071655,33.977055 641.572266,34.047752 631.073486,33.992992 
	C626.357117,33.968399 623.430298,35.958866 621.618042,40.474926 
	C617.145447,51.620686 612.326355,62.627880 607.588562,73.665886 
	C607.220703,74.522858 606.400024,75.185471 605.265930,75.765579 
	C604.162170,75.395576 603.582336,75.198395 603.002441,75.001228 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M608.977173,160.589767 
	C608.000549,162.200043 607.001099,163.399490 605.974854,164.993347 
	C605.724243,165.708420 605.500488,166.029083 605.112305,166.841461 
	C604.978943,168.316406 605.010071,169.299622 605.041138,170.282837 
	C604.558777,171.700928 604.076416,173.119019 603.023315,174.775345 
	C601.956970,175.674118 601.461365,176.334641 600.965759,176.995148 
	C600.552612,177.009521 600.139404,177.023880 599.037354,177.087982 
	C596.530212,180.676147 594.804626,184.267380 592.834534,187.719131 
	C592.279846,188.690918 591.008301,189.253586 590.065552,190.003906 
	C588.933105,188.363083 587.800720,186.722244 586.020020,184.856445 
	C583.247009,182.424438 581.122314,180.217407 578.990906,177.635590 
	C576.994080,175.840988 574.935242,174.506378 573.034424,172.975571 
	C571.387085,171.648880 568.483582,169.915924 568.641418,168.683563 
	C569.109619,165.028687 570.815491,161.532349 572.037476,157.974030 
	C572.234558,158.230942 572.650818,158.551468 572.597412,158.735703 
	C569.651184,168.889023 569.499146,167.864059 579.857788,172.214310 
	C582.776794,173.440231 585.197144,175.920029 587.733765,177.964203 
	C589.165710,179.118118 590.368591,180.556152 591.674683,181.866211 
	C593.060120,180.306305 594.929382,178.965317 595.750732,177.150146 
	C600.259338,167.185989 604.670288,157.170547 608.801453,147.044861 
	C612.149170,138.839203 614.815369,130.353333 618.224182,122.175316 
	C621.558289,114.176651 625.402649,106.387642 629.165039,98.574974 
	C629.564148,97.746277 630.916077,97.376427 631.825928,96.793701 
	C632.216125,97.105392 632.606384,97.417084 632.996582,97.728767 
	C630.870544,103.389442 628.872437,109.102295 626.589233,114.698830 
	C622.962891,123.587654 619.126831,132.390747 615.439819,141.255234 
	C615.070557,142.143036 615.117554,143.204010 614.967529,144.578827 
	C612.960632,150.041687 610.957458,155.110306 608.977173,160.589767 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M572.045532,157.666626 
	C570.815491,161.532349 569.109619,165.028687 568.641418,168.683563 
	C568.483582,169.915924 571.387085,171.648880 573.034424,172.975571 
	C574.935242,174.506378 576.994080,175.840988 578.666687,177.654175 
	C577.920227,178.099075 577.491272,178.150604 576.717896,178.008759 
	C571.477539,176.897293 566.534363,176.054565 564.878662,169.159882 
	C563.252991,171.134613 562.140930,172.159958 561.429138,173.412781 
	C559.487732,176.829803 557.395996,176.724579 555.092041,173.687805 
	C555.235840,172.871552 555.293335,172.323975 555.637085,171.585693 
	C556.003113,170.958313 556.083008,170.521667 556.162842,170.085007 
	C556.162842,170.085007 556.545166,169.907654 556.755859,169.898727 
	C559.601746,168.575424 563.641357,167.929413 564.638062,165.835205 
	C569.996643,154.576019 574.598694,142.956741 580.086548,131.232666 
	C581.182922,131.020432 581.654541,131.037277 582.126221,131.054123 
	C582.070679,132.106384 582.015076,133.158630 581.589172,134.667206 
	C580.491821,136.775604 579.764771,138.427673 579.037720,140.079773 
	C579.037781,140.079773 579.040161,140.539215 578.674866,140.823364 
	C577.883118,142.093887 577.456665,143.080276 577.030151,144.066650 
	C577.030212,144.066666 577.027161,144.534225 576.844482,144.850922 
	C576.595398,145.268494 576.449524,145.420990 576.475891,145.461517 
	C576.600525,145.653214 576.772278,145.814285 576.927246,145.986267 
	C576.930969,146.433472 576.934692,146.880676 576.580200,147.723068 
	C574.837341,150.788284 573.452637,153.458313 572.067993,156.128342 
	C572.063232,156.538635 572.058411,156.948914 572.045532,157.666626 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M589.984802,108.977509 
	C592.552063,102.196152 595.119385,95.414803 598.106567,88.526047 
	C598.698853,89.308289 598.871155,90.197945 599.043518,91.087593 
	C599.017639,92.130226 598.991699,93.172859 598.625671,94.654564 
	C597.863892,95.744225 597.442200,96.394814 597.016846,97.391022 
	C596.352783,99.154236 595.692444,100.571846 594.734375,102.132965 
	C593.972351,103.214546 593.508057,104.152611 593.046265,105.499374 
	C592.994629,107.268768 592.940491,108.629471 592.556885,109.989517 
	C591.479919,109.651741 590.732361,109.314629 589.984802,108.977509 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M582.462646,130.984161 
	C581.654541,131.037277 581.182922,131.020432 580.358521,131.002625 
	C581.556335,127.877457 583.106750,124.753258 585.173096,121.315239 
	C586.431091,120.998055 587.173035,120.994698 587.915039,120.991348 
	C586.209717,124.298973 584.504395,127.606583 582.462646,130.984161 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M602.855225,75.327469 
	C603.582336,75.198395 604.162170,75.395576 604.920105,75.857101 
	C604.913147,77.586082 604.728149,79.050728 603.923950,80.811981 
	C601.827698,83.064827 600.350586,85.021049 598.873474,86.977264 
	C600.151672,83.202751 601.429871,79.428238 602.855225,75.327469 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M587.954590,120.614700 
	C587.173035,120.994698 586.431091,120.998055 585.315186,121.003395 
	C585.915833,117.904907 586.890381,114.804436 588.259521,111.396835 
	C588.785889,112.427284 588.917664,113.764870 589.049500,115.102448 
	C588.697693,116.814316 588.345886,118.526192 587.954590,120.614700 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M598.671753,87.092926 
	C600.350586,85.021049 601.827698,83.064827 603.644409,81.053139 
	C602.565369,84.305328 601.146667,87.612984 599.385742,91.004112 
	C598.871155,90.197945 598.698853,89.308289 598.258301,88.206543 
	C598.065796,87.680962 598.225769,87.419006 598.671753,87.092926 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M572.415039,156.045044 
	C573.452637,153.458313 574.837341,150.788284 576.559021,148.057907 
	C575.518066,150.652298 574.140076,153.307022 572.415039,156.045044 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M589.370361,115.024933 
	C588.917664,113.764870 588.785889,112.427284 588.332764,111.044418 
	C588.458374,110.360794 588.905212,109.722443 589.668457,109.030807 
	C590.732361,109.314629 591.479919,109.651741 592.592285,110.325577 
	C592.986023,111.111473 593.015015,111.560661 593.044006,112.009842 
	C593.044006,112.009842 593.010010,112.485687 592.989746,112.722992 
	C591.876709,113.622665 590.783936,114.285034 589.370361,115.024933 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M579.374939,139.998749 
	C579.764771,138.427673 580.491821,136.775604 581.556824,135.061035 
	C581.167236,136.638260 580.439697,138.278015 579.374939,139.998749 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M577.370300,143.992691 
	C577.456665,143.080276 577.883118,142.093887 578.658447,141.052521 
	C578.575073,141.971268 578.142761,142.944992 577.370300,143.992691 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M597.020508,97.045403 
	C597.442200,96.394814 597.863892,95.744225 598.598083,95.044724 
	C598.930664,95.435562 598.950745,95.875305 598.973389,96.644867 
	C598.975952,96.974686 598.989746,96.986923 598.656860,96.984772 
	C597.889465,97.003548 597.455017,97.024475 597.020508,97.045403 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M576.952332,145.740372 
	C576.772278,145.814285 576.600525,145.653214 576.475891,145.461517 
	C576.449524,145.420990 576.595398,145.268494 576.825317,145.083923 
	C576.988831,145.000229 576.977417,145.494461 576.952332,145.740372 
z"/>
<path fill="#010102" opacity="1.000000" stroke="none" 
	d="
M417.020020,167.970932 
	C417.020020,167.970932 417.004700,167.495697 417.002686,166.862030 
	C413.942871,158.434998 410.874115,150.645920 407.830933,142.846848 
	C405.518372,136.920258 403.238312,130.980972 400.961334,124.666595 
	C400.312469,123.199409 399.646088,122.112976 398.989258,121.013885 
	C398.998779,121.001221 398.972198,121.018250 398.976562,120.662666 
	C398.319397,119.540619 397.657867,118.774155 396.681122,117.995682 
	C395.947906,117.951157 395.529877,117.918663 395.111877,117.886169 
	C395.083099,117.470062 395.054291,117.053955 395.017395,115.923828 
	C395.003448,114.139023 394.997589,113.068245 395.241577,111.793564 
	C394.656891,110.064713 393.822296,108.539772 392.744995,106.842178 
	C392.326782,106.111641 392.151276,105.553772 391.975800,104.995895 
	C393.054901,100.652290 388.073181,88.952705 384.464111,87.354652 
	C379.187073,85.018066 372.130737,90.070312 372.036407,96.430367 
	C371.914307,104.664886 372.004974,112.902580 372.005005,121.138908 
	C372.005188,192.212158 372.019196,263.285431 371.966705,334.358643 
	C371.964020,337.983673 371.389893,341.608307 370.568237,345.608887 
	C364.374420,345.977661 358.694153,345.970703 352.974182,345.623413 
	C350.957520,343.523254 348.980560,341.763458 347.000916,339.534790 
	C346.998199,322.376282 346.998138,305.686707 347.295288,288.703766 
	C347.727570,287.100555 347.980316,285.790710 347.980682,284.480804 
	C347.999390,218.213440 348.003448,151.946060 347.954163,85.678719 
	C347.953003,84.118904 347.327148,82.559547 346.993805,80.531311 
	C346.991516,69.041809 346.988068,58.020962 347.266113,46.747810 
	C348.744904,38.630730 350.718140,36.997875 359.046143,36.995644 
	C368.033966,36.993233 377.031097,36.780476 386.002380,37.165955 
	C387.960999,37.250114 390.684784,39.054466 391.554565,40.810394 
	C393.268005,44.269478 393.514832,48.443108 395.153351,51.954865 
	C396.527954,54.900791 399.018158,57.326149 401.019531,60.229698 
	C401.024597,60.477409 401.031403,60.972893 401.060974,61.349136 
	C402.987183,64.528587 401.283844,69.873108 406.961609,70.012833 
	C406.978088,70.006912 407.009888,69.992096 406.999481,70.368500 
	C406.992310,71.497757 406.995544,72.250618 406.730713,73.229340 
	C405.323822,76.780388 406.986694,78.318138 410.157837,79.241859 
	C410.857391,79.683815 411.396667,79.879784 412.068634,80.359299 
	C412.435883,81.423515 412.670441,82.204178 412.545227,83.070312 
	C410.134674,87.538216 411.872406,92.245102 416.959534,96.399429 
	C419.924744,104.024452 422.616180,111.389450 425.904633,118.477463 
	C427.792542,122.546707 430.648895,126.166679 433.061676,130.317749 
	C433.059082,131.085175 433.064056,131.523727 433.049072,132.365433 
	C434.254974,136.310394 435.427185,139.871948 436.721954,143.388367 
	C437.982147,146.810837 439.808167,150.096069 440.536041,153.617203 
	C440.984222,155.785278 440.458069,158.664291 439.289337,160.557251 
	C436.313690,165.376892 432.747803,169.834030 429.382385,174.409988 
	C428.266998,175.926544 427.042633,177.362915 425.868103,178.835938 
	C424.394440,177.197815 422.851562,175.615845 421.465576,173.906677 
	C419.909760,171.988052 418.495728,169.954483 417.020020,167.970932 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M378.009003,102.990395 
	C378.352295,105.090416 378.994415,107.190247 378.995728,109.290489 
	C379.045471,188.279785 379.009735,267.269135 379.117523,346.258301 
	C379.122620,349.988800 378.198334,351.179779 374.393036,351.077515 
	C364.901306,350.822388 355.395111,350.869476 345.900635,351.071350 
	C342.136475,351.151337 340.975616,349.729431 340.992096,346.097412 
	C341.098480,322.601074 340.946136,299.103394 341.090790,275.607452 
	C341.177765,261.475677 341.823151,247.347290 341.907288,233.215546 
	C342.049713,209.288208 342.016052,185.359085 341.905273,161.431412 
	C341.870697,153.965500 341.110779,146.501678 341.115356,139.037186 
	C341.120148,131.238907 341.937378,123.439110 341.888062,115.643211 
	C341.753357,94.345688 341.333038,73.050064 341.062714,51.753216 
	C340.989563,45.990677 341.142181,40.224270 341.007812,34.463913 
	C340.934174,31.308029 342.074097,29.937492 345.358337,29.957085 
	C360.855652,30.049549 376.354309,29.950987 391.850861,30.095549 
	C393.242676,30.108530 395.458557,31.052114 395.866882,32.112312 
	C397.874725,37.325264 399.401123,42.723633 401.067810,48.508484 
	C401.038055,48.960434 400.965515,49.034348 400.639648,49.024639 
	C399.878235,49.004585 399.442749,48.994240 398.998718,48.746784 
	C398.990173,48.509670 398.970917,48.035526 398.943329,47.712505 
	C398.293427,46.908268 397.671143,46.427052 397.038940,45.625774 
	C397.012146,44.879101 396.995270,44.452492 396.982666,43.649147 
	C395.668854,41.168777 394.350739,39.065136 392.970490,36.623840 
	C391.953003,35.842102 390.997650,35.398018 389.935822,34.564091 
	C389.151489,33.793171 388.475494,33.083679 387.795471,33.079762 
	C375.494720,33.008892 363.193054,32.978962 350.893250,33.102795 
	C349.908508,33.112701 348.935760,34.308414 347.741760,35.065536 
	C347.256989,35.376839 347.063934,35.632973 346.591766,36.027458 
	C345.842621,37.423313 345.448608,38.738117 345.045898,40.483898 
	C345.039642,42.926548 345.042023,44.938221 344.974365,47.368530 
	C344.614929,58.150188 344.304596,68.512764 344.082062,78.877220 
	C344.066864,79.586891 344.709412,80.310684 345.038147,81.494736 
	C345.032623,93.306129 345.034943,104.650703 344.736328,116.285942 
	C344.296753,117.886383 344.037231,119.196121 344.036835,120.505951 
	C344.015381,191.149261 344.005951,261.792572 344.049988,332.435852 
	C344.051727,335.225220 344.984436,338.043579 344.782593,340.797058 
	C344.405090,345.947174 345.700378,347.904938 350.628876,347.972107 
	C357.125488,348.060638 363.627563,348.102692 370.119324,347.904358 
	C371.427246,347.864410 372.699615,346.660767 373.988373,345.993652 
	C373.988373,345.993652 374.000000,346.000000 374.374847,345.931824 
	C375.492859,343.311646 376.881622,340.760651 376.885437,338.207520 
	C376.999420,261.852234 376.968353,185.496765 377.018280,109.141342 
	C377.019623,107.090813 377.664337,105.040703 378.009003,102.990395 
z"/>
<path fill="#031829" opacity="1.000000" stroke="none" 
	d="
M373.613525,345.992493 
	C372.699615,346.660767 371.427246,347.864410 370.119324,347.904358 
	C363.627563,348.102692 357.125488,348.060638 350.628876,347.972107 
	C345.700378,347.904938 344.405090,345.947174 344.782593,340.797058 
	C344.984436,338.043579 344.051727,335.225220 344.049988,332.435852 
	C344.005951,261.792572 344.015381,191.149261 344.036835,120.505951 
	C344.037231,119.196121 344.296753,117.886383 345.025024,116.585739 
	C345.743927,118.571838 345.985291,120.548752 345.986053,122.525764 
	C346.006561,176.122818 345.992249,229.719879 346.044373,283.316895 
	C346.046204,285.210602 346.666168,287.103699 346.998047,288.997101 
	C346.998138,305.686707 346.998199,322.376282 346.995148,339.950317 
	C346.332428,345.675568 349.162781,346.418915 353.013916,345.963745 
	C358.694153,345.970703 364.374420,345.977661 370.521179,345.976196 
	C371.738007,345.975616 372.488312,345.983459 373.613525,345.992493 
z"/>
<path fill="#031829" opacity="1.000000" stroke="none" 
	d="
M378.011047,102.591293 
	C377.664337,105.040703 377.019623,107.090813 377.018280,109.141342 
	C376.968353,185.496765 376.999420,261.852234 376.885437,338.207520 
	C376.881622,340.760651 375.492859,343.311646 374.372009,345.925995 
	C374.327118,343.369324 374.948700,340.750519 374.950562,338.131256 
	C375.002228,265.136292 374.990784,192.141281 374.990723,119.146286 
	C374.990723,113.063293 374.853333,106.974556 375.095062,100.901047 
	C375.161469,99.232292 376.370941,97.609032 377.055908,95.964897 
	C377.515564,96.130623 377.975250,96.296349 378.434906,96.462082 
	C378.294312,98.372116 378.153687,100.282158 378.011047,102.591293 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M412.904999,82.984833 
	C412.670441,82.204178 412.435883,81.423515 412.056702,79.976593 
	C410.277985,76.204254 408.643951,73.098175 407.009888,69.992096 
	C407.009888,69.992096 406.978088,70.006912 406.981262,69.682419 
	C406.977234,68.917633 406.970001,68.477356 406.969849,67.656448 
	C406.346771,65.580788 405.653625,63.906078 405.098969,62.186695 
	C403.688141,57.813232 402.339203,53.419792 400.965515,49.034348 
	C400.965515,49.034348 401.038055,48.960434 401.075439,48.921356 
	C402.096985,49.836823 403.510101,50.610626 404.000061,51.773418 
	C409.898651,65.772202 415.559265,79.871490 421.483124,93.859360 
	C429.471313,112.721741 437.634735,131.509903 445.863678,150.655396 
	C446.310669,152.123428 446.616913,153.266006 446.652985,154.758453 
	C445.929077,155.403595 445.475372,155.698822 444.651459,155.930557 
	C441.565704,149.249222 438.922394,142.600113 436.080383,136.037033 
	C435.426575,134.527222 434.090881,133.312698 433.069031,131.962280 
	C433.064056,131.523727 433.059082,131.085175 433.297119,130.109711 
	C433.474274,128.953003 433.554474,128.271072 433.321167,127.722481 
	C427.865631,114.894775 422.410614,102.066444 416.826263,89.294724 
	C416.390106,88.297234 415.006683,87.713905 414.040283,86.591599 
	C413.646332,85.158356 413.275665,84.071594 412.904999,82.984833 
z"/>
<path fill="#031829" opacity="1.000000" stroke="none" 
	d="
M391.717834,104.823753 
	C392.151276,105.553772 392.326782,106.111641 392.746948,107.210625 
	C393.658325,109.166977 394.325012,110.582222 394.991730,111.997467 
	C394.997589,113.068245 395.003448,114.139023 394.997498,115.611633 
	C392.996521,111.927437 391.007385,107.841408 389.034546,103.357651 
	C389.853851,103.523819 390.656830,104.087715 391.717834,104.823753 
z"/>
<path fill="#031829" opacity="1.000000" stroke="none" 
	d="
M385.016449,96.576004 
	C384.833679,96.967270 384.646973,96.975937 384.178802,96.992508 
	C383.179993,95.664139 382.462646,94.327850 381.745300,92.991570 
	C382.384766,92.727509 383.024200,92.463455 383.663635,92.199394 
	C384.113281,93.530731 384.562897,94.862068 385.016449,96.576004 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M391.119568,119.212067 
	C391.713104,119.387543 392.303802,119.890770 392.912048,120.711105 
	C392.325287,120.532089 391.721008,120.035957 391.119568,119.212067 
z"/>
<path fill="#010204" opacity="1.000000" stroke="none" 
	d="
M481.001221,171.019836 
	C475.007874,174.559372 470.710327,179.543243 469.188843,186.376221 
	C468.111511,191.214432 472.840546,195.989380 478.088318,195.980774 
	C484.670715,195.969971 491.253143,195.978531 497.835541,195.978592 
	C518.001953,195.978745 538.168396,195.979065 558.334778,195.978836 
	C564.251892,195.978760 570.169434,196.016846 576.085999,195.958389 
	C578.861938,195.930984 580.998169,196.266403 581.063660,199.877289 
	C581.129944,203.540207 579.852234,206.043762 576.208008,206.909271 
	C574.942627,207.209824 573.553345,207.003769 572.220642,207.003906 
	C545.054321,207.006836 517.887756,207.064835 490.721863,206.961227 
	C484.595642,206.937851 478.470276,206.062378 472.346924,206.091354 
	C464.387512,206.129044 456.425751,206.975204 448.475037,206.847626 
	C445.679169,206.802765 441.140198,205.534073 440.506409,203.731186 
	C439.232880,200.108292 439.578430,195.997986 442.848724,192.276611 
	C450.577362,183.481949 457.668091,174.125717 464.992279,164.976974 
	C469.322144,159.568527 473.605347,154.122772 478.099823,148.044006 
	C482.301605,143.313354 486.436523,139.345078 490.289978,135.120041 
	C494.172119,130.863617 498.059021,126.556183 501.408569,121.887100 
	C504.031342,118.231102 505.836578,113.988647 508.120605,109.792923 
	C508.437256,109.312744 508.693909,109.120628 509.346008,108.949890 
	C512.137024,106.751305 514.950562,104.900406 516.976807,102.409142 
	C526.056274,91.245598 534.799683,79.808281 543.909363,68.670097 
	C547.032776,64.851189 546.745300,60.629948 545.074280,57.077751 
	C544.226318,55.275097 540.164368,54.160110 537.545349,54.141296 
	C509.072662,53.936768 480.597778,53.960503 452.123993,54.062050 
	C447.598022,54.078194 446.079742,52.242374 445.844696,47.816826 
	C445.594818,43.112152 447.261444,40.982258 451.863800,40.982998 
	C453.029388,40.983185 454.195007,40.977970 455.360596,40.977966 
	C493.159637,40.977821 530.958679,40.976955 568.757690,40.979000 
	C576.908630,40.979439 576.908630,40.984360 577.065308,49.281456 
	C577.059998,49.697609 577.072693,49.864040 576.807861,50.226780 
	C574.314026,53.847260 572.337769,57.459064 569.836670,60.660801 
	C561.995544,70.698334 554.083374,80.686279 545.959839,90.495644 
	C537.283264,100.972755 528.240479,111.146111 519.530457,121.596077 
	C509.735443,133.347687 500.042511,145.187958 490.531189,157.169067 
	C488.806976,159.341003 488.170959,162.376801 487.008148,165.327103 
	C486.948059,166.057037 486.912415,166.473679 486.514130,166.909576 
	C484.434753,168.292526 482.717987,169.656189 481.001221,171.019836 
z"/>
<path fill="#0C1A28" opacity="1.000000" stroke="none" 
	d="
M508.005066,110.006752 
	C505.836578,113.988647 504.031342,118.231102 501.408569,121.887100 
	C498.059021,126.556183 494.172119,130.863617 490.289978,135.120041 
	C486.436523,139.345078 482.301605,143.313354 478.130554,147.694672 
	C477.803070,147.991272 477.635101,147.987411 477.215698,147.594330 
	C476.220856,144.066055 474.857880,144.238434 473.107361,146.483673 
	C472.038971,147.853989 471.007416,149.255020 469.896179,150.589645 
	C465.608398,155.739410 461.295197,160.868011 456.991455,166.004486 
	C456.178070,165.991882 455.364685,165.979263 454.320984,165.630753 
	C454.742371,163.786957 455.394073,162.279037 456.372742,160.720551 
	C467.724731,147.441711 478.828796,134.278366 489.753632,120.967926 
	C501.949921,106.108391 513.983521,91.115311 526.394897,76.030785 
	C529.779785,72.117638 532.857605,68.354034 535.935425,64.590439 
	C535.659973,64.113686 535.384521,63.636925 535.109009,63.160168 
	C530.739563,63.160168 526.370056,63.160168 521.557983,63.057281 
	C518.399414,63.041588 515.683472,63.128784 512.508118,63.127495 
	C495.360443,63.031609 478.672150,63.024216 461.653503,62.779743 
	C457.192871,62.502422 453.062683,62.462185 448.559540,62.338760 
	C447.445862,62.366306 446.705139,62.477036 445.964386,62.587769 
	C444.249878,62.463921 442.535400,62.340073 440.403870,62.333961 
	C439.438354,62.091717 438.889862,61.731735 438.103546,60.879208 
	C437.598541,59.593292 437.331360,58.799923 437.064178,58.006554 
	C437.116241,56.925709 437.168304,55.844868 437.295349,53.941162 
	C437.254608,49.751236 437.138885,46.384174 437.023193,43.017113 
	C437.050934,41.368118 437.185577,39.712566 437.084900,38.071453 
	C436.852997,34.292709 438.121490,31.945065 443.034149,32.216682 
	C483.972626,32.272713 524.207336,32.267292 564.442078,32.234070 
	C569.074402,32.230247 573.706604,32.071499 578.338867,31.984581 
	C585.333374,32.648254 585.335632,32.648209 585.474854,39.623528 
	C585.531372,42.453575 585.575439,45.283871 585.660461,48.806221 
	C585.768677,49.664478 585.841553,49.830570 585.914429,49.996658 
	C585.809570,51.072281 585.704773,52.147903 585.528625,53.859104 
	C584.882019,55.831059 584.306763,57.167435 583.421631,58.642525 
	C581.150146,60.881363 579.188416,62.981495 576.846680,65.054222 
	C575.959412,64.998550 575.452209,64.970284 575.004395,64.596741 
	C575.732605,62.767933 576.401245,61.284412 577.069946,59.800888 
	C576.662231,59.532692 576.254517,59.264492 575.846802,58.996296 
	C571.898499,63.250244 567.950195,67.504189 563.489441,72.310257 
	C565.239197,72.661926 566.123291,72.839600 566.863831,73.308563 
	C565.200745,75.081688 563.681152,76.563522 561.817017,78.057930 
	C561.314392,79.045601 561.156372,80.020699 560.963501,81.342896 
	C558.956604,83.795021 556.984375,85.900047 554.965027,87.677612 
	C553.110962,87.972313 550.622253,88.042862 549.603210,89.302422 
	C539.971069,101.208183 530.603394,113.327644 521.130493,125.362633 
	C514.067322,134.336258 507.140350,143.426559 499.801208,152.169830 
	C495.933655,156.777390 491.313141,160.752899 487.032593,165.013794 
	C488.170959,162.376801 488.806976,159.341003 490.531189,157.169067 
	C500.042511,145.187958 509.735443,133.347687 519.530457,121.596077 
	C528.240479,111.146111 537.283264,100.972755 545.959839,90.495644 
	C554.083374,80.686279 561.995544,70.698334 569.836670,60.660801 
	C572.337769,57.459064 574.314026,53.847260 577.068726,50.469406 
	C577.987427,51.889168 578.367798,53.262608 578.748169,54.636044 
	C579.847900,53.298714 579.867859,52.101955 580.289368,51.069046 
	C584.259033,41.341228 578.913757,35.691589 570.223633,35.792782 
	C530.761230,36.252312 491.289032,36.176529 451.823914,35.857529 
	C442.411926,35.781452 441.241913,38.713833 440.477234,46.328449 
	C439.554840,55.513893 442.957184,58.993301 452.237488,58.992367 
	C478.549286,58.989719 504.861206,59.024342 531.172791,58.949596 
	C534.092041,58.941299 537.009583,58.322510 539.927917,57.986855 
	C536.611145,56.542397 533.344788,56.050999 530.077087,56.042263 
	C504.931213,55.975044 479.785004,56.005672 454.638916,55.996655 
	C445.112885,55.993240 444.043671,54.767101 443.925446,45.495575 
	C443.865814,40.818542 445.556427,38.834702 450.348633,38.938755 
	C459.669952,39.141148 468.998749,38.996468 478.324463,38.996090 
	C509.798706,38.994816 541.273193,38.946190 572.746643,39.105804 
	C574.724854,39.115837 578.026428,40.404316 578.368896,41.711845 
	C578.939453,43.890102 577.611694,46.565598 577.083252,49.031734 
	C576.908630,40.984360 576.908630,40.979439 568.757690,40.979000 
	C530.958679,40.976955 493.159637,40.977821 455.360596,40.977966 
	C454.195007,40.977970 453.029388,40.983185 451.863800,40.982998 
	C447.261444,40.982258 445.594818,43.112152 445.844696,47.816826 
	C446.079742,52.242374 447.598022,54.078194 452.123993,54.062050 
	C480.597778,53.960503 509.072662,53.936768 537.545349,54.141296 
	C540.164368,54.160110 544.226318,55.275097 545.074280,57.077751 
	C546.745300,60.629948 547.032776,64.851189 543.909363,68.670097 
	C534.799683,79.808281 526.056274,91.245598 516.976807,102.409142 
	C514.950562,104.900406 512.137024,106.751305 509.376740,108.595016 
	C512.718628,103.357613 516.390076,98.437141 520.014465,93.482231 
	C523.040771,89.345001 526.011780,85.167419 529.354614,80.969376 
	C531.512756,79.012024 533.323669,77.092995 535.134583,75.173973 
	C534.465698,74.693886 533.796814,74.213799 533.127930,73.733711 
	C531.749084,76.152985 530.370300,78.572266 528.629150,80.994812 
	C527.545471,81.321945 526.593628,81.453621 526.138367,81.999489 
	C521.686584,87.336906 517.227844,92.673325 512.954651,98.153168 
	C509.734558,102.282570 507.002258,106.805168 503.645569,110.811531 
	C500.604889,114.440765 496.847473,117.463554 493.726990,121.034187 
	C491.866852,123.162636 490.625305,125.831757 489.105682,128.257828 
	C489.506592,128.565063 489.907501,128.872314 490.308441,129.179565 
	C496.207306,122.788628 502.106201,116.397690 508.005066,110.006752 
M541.981750,67.508369 
	C542.590393,66.236748 543.199036,64.965126 543.807678,63.693504 
	C543.283081,63.390743 542.758423,63.087986 542.233826,62.785225 
	C539.753967,65.813683 537.274048,68.842133 534.794189,71.870583 
	C535.401672,72.412300 536.009155,72.954010 536.616699,73.495720 
	C538.255615,71.686760 539.894592,69.877800 541.981750,67.508369 
M484.057281,133.395035 
	C483.438629,134.343704 482.819946,135.292374 482.201294,136.241043 
	C482.470734,136.431641 482.740173,136.622223 483.009644,136.812805 
	C485.087646,134.984634 487.165680,133.156448 489.243683,131.328262 
	C488.782227,130.817490 488.320770,130.306702 487.859283,129.795929 
	C486.739563,130.816116 485.619843,131.836304 484.057281,133.395035 
M479.906311,142.403503 
	C480.007233,141.745773 480.108154,141.088058 480.209076,140.430344 
	C479.665466,140.656540 479.121857,140.882736 478.578247,141.108917 
	C478.843475,141.631531 479.108734,142.154144 479.906311,142.403503 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M456.876404,166.270187 
	C461.295197,160.868011 465.608398,155.739410 469.896179,150.589645 
	C471.007416,149.255020 472.038971,147.853989 473.107361,146.483673 
	C474.857880,144.238434 476.220856,144.066055 476.963806,147.586731 
	C471.033691,154.864410 465.065643,161.727921 459.185547,168.665955 
	C453.515747,175.355850 448.100708,182.269012 442.266296,188.809814 
	C437.530884,194.118530 437.566315,200.333191 438.362823,206.611633 
	C438.489624,207.611069 441.685699,208.884064 443.476227,208.905334 
	C458.796600,209.087433 474.120026,209.010574 489.442566,209.010147 
	C504.931671,209.009705 520.420776,209.007111 535.909851,209.007385 
	C549.326843,209.007614 562.743774,209.025284 576.160767,209.005569 
	C582.213745,208.996674 582.961487,208.197205 583.001099,201.994644 
	C583.005371,201.328461 583.005493,200.662231 583.001953,199.996048 
	C582.969482,193.793640 582.227539,193.010590 576.154663,193.006912 
	C557.834229,192.995819 539.513733,193.008057 521.193298,193.008118 
	C508.202454,193.008163 495.211517,193.021667 482.220825,192.977768 
	C480.551575,192.972137 478.883392,192.656876 477.214722,192.485046 
	C477.171448,191.940887 477.128174,191.396713 477.084900,190.852554 
	C478.296539,190.570251 479.507721,190.043320 480.719849,190.041260 
	C514.862366,189.983078 549.005127,189.949570 583.147156,190.074509 
	C584.425110,190.079178 586.749207,191.650848 586.800720,192.590225 
	C587.128662,198.566681 586.995728,204.571426 586.891174,210.564682 
	C586.878906,211.268646 586.113037,211.959473 584.934326,212.840500 
	C581.815613,213.329071 579.459045,213.633514 576.763855,213.931885 
	C575.976807,213.908813 575.528442,213.891830 574.955566,213.497498 
	C569.220703,213.093887 563.610291,213.067642 557.603882,213.037201 
	C556.151672,213.036240 555.095520,213.039459 553.641479,213.014099 
	C548.492798,212.975159 543.742126,212.964813 538.740601,212.956177 
	C538.322693,212.965302 538.155579,212.972717 537.573364,212.979584 
	C535.772888,212.984146 534.387695,212.989258 532.533630,212.991241 
	C513.043640,212.991409 494.022430,212.994705 474.566956,212.991348 
	C472.684723,212.801804 471.233459,212.440445 469.789307,212.466919 
	C463.524658,212.581696 457.262085,212.809677 450.570312,212.995026 
	C448.428711,212.992249 446.715576,212.990967 444.786926,212.736572 
	C443.380951,212.656097 442.190460,212.828751 440.603241,213.005188 
	C439.151093,213.041229 438.095673,213.073486 437.040283,213.105743 
	C436.029114,211.965164 434.235229,210.882553 434.145691,209.673721 
	C433.804260,205.064026 433.922516,200.410645 434.076050,195.780457 
	C434.116180,194.570511 434.619629,193.145096 435.394135,192.226654 
	C441.964142,184.435760 448.646179,176.739334 455.790802,168.888992 
	C456.351593,168.672577 456.484497,168.515823 456.470306,168.501099 
	C456.323090,168.348633 456.152130,168.219086 455.987793,168.083176 
	C456.245636,167.567398 456.503510,167.051636 456.876404,166.270187 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M544.990356,305.847473 
	C542.484802,313.506226 540.168457,321.233826 537.414551,328.802185 
	C535.154480,335.013336 532.225830,340.979248 529.893921,347.167023 
	C528.891235,349.827667 527.772705,351.138214 524.640442,351.068146 
	C514.665283,350.845062 504.681488,350.922424 494.702240,351.014618 
	C491.369965,351.045380 490.024658,349.682861 488.767517,346.459381 
	C484.141998,334.598938 478.912231,322.974487 473.927673,311.253632 
	C466.629669,294.092987 459.358978,276.920685 452.054108,259.762909 
	C446.479492,246.669235 440.805908,233.616806 435.391632,220.457443 
	C434.934113,219.345444 432.147736,216.691666 436.664001,216.361206 
	C438.026276,217.483795 438.983246,218.276962 439.953003,219.434601 
	C439.983368,220.528229 440.000885,221.257401 440.030273,222.311432 
	C440.692780,223.426285 441.343445,224.216278 442.038391,225.346985 
	C442.401154,226.780746 442.719635,227.873795 443.022919,229.368820 
	C444.947266,234.800262 446.857391,239.841278 448.833954,244.856110 
	C451.118164,250.651505 453.363312,256.465332 455.803345,262.195435 
	C458.770844,269.164246 462.071381,275.993469 464.948486,282.997833 
	C467.117798,288.278992 468.681366,293.807983 470.823273,299.101746 
	C472.698608,303.736664 474.919159,308.240692 477.184875,312.702118 
	C477.690613,313.697968 479.022247,314.274353 480.204590,315.211243 
	C480.583252,315.916565 480.730957,316.454498 480.674683,317.152588 
	C480.640778,317.877808 480.810822,318.442841 480.984558,319.335754 
	C481.010406,320.099792 481.032532,320.535950 481.049683,321.348785 
	C484.090576,328.388336 487.090698,335.072723 490.199188,341.706329 
	C492.139008,345.845978 495.521759,347.944305 500.085358,347.975769 
	C507.056427,348.023865 514.068970,348.420776 520.979004,347.769165 
	C523.678833,347.514557 527.437256,345.416809 528.490784,343.133118 
	C532.186829,335.121735 534.695679,326.572327 538.099792,318.412659 
	C539.928589,314.028992 542.664062,310.023590 544.990356,305.847473 
z"/>
<path fill="#061421" opacity="1.000000" stroke="none" 
	d="
M544.991577,305.661896 
	C542.664062,310.023590 539.928589,314.028992 538.099792,318.412659 
	C534.695679,326.572327 532.186829,335.121735 528.490784,343.133118 
	C527.437256,345.416809 523.678833,347.514557 520.979004,347.769165 
	C514.068970,348.420776 507.056427,348.023865 500.085358,347.975769 
	C495.521759,347.944305 492.139008,345.845978 490.199188,341.706329 
	C487.090698,335.072723 484.090576,328.388336 481.414276,321.357422 
	C482.515472,320.999146 483.247040,321.008881 483.984070,321.263977 
	C483.989471,321.509369 484.002441,322.000000 484.026001,322.321350 
	C484.697937,323.428436 485.346375,324.214142 486.268127,325.242096 
	C488.988159,331.249512 491.253632,337.104218 494.022827,342.710052 
	C494.632629,343.944427 496.973969,344.769043 498.614960,344.963440 
	C503.205627,345.507385 507.859619,345.493988 512.465332,345.940277 
	C517.987610,346.475372 522.946838,345.792175 525.498596,340.103363 
	C529.753174,330.618469 533.896973,321.081360 537.921997,311.496857 
	C540.779663,304.692169 543.229980,297.715668 546.117554,290.924469 
	C548.229858,285.956604 550.968201,281.253601 553.054382,276.276184 
	C556.988342,266.890472 560.724060,257.419556 564.411621,247.933319 
	C568.794189,236.659424 575.801270,226.361496 577.102478,213.937958 
	C579.459045,213.633514 581.815613,213.329071 584.588562,213.044678 
	C584.586914,214.331131 584.168884,215.597519 583.187988,216.693954 
	C582.058044,216.894806 581.205200,217.134796 580.966675,217.655960 
	C577.249939,225.776764 573.473267,233.874847 569.986816,242.095322 
	C563.246277,257.988159 556.594788,273.920288 550.091553,289.911346 
	C548.082886,294.850494 546.674377,300.033722 544.991028,305.105194 
	C544.991028,305.105194 544.992737,305.476318 544.991577,305.661896 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M548.000732,184.803619 
	C547.031860,185.417404 546.067017,186.559357 545.093384,186.566940 
	C525.914978,186.716415 506.735626,186.762863 487.556549,186.737625 
	C485.486847,186.734894 483.417877,186.160889 480.996979,185.799362 
	C481.903198,183.829514 482.573792,182.371857 483.537964,180.485870 
	C484.300415,180.007431 484.769318,179.957336 485.754822,179.891510 
	C503.548065,179.936188 520.824951,180.064590 538.101135,180.008331 
	C542.323975,179.994568 545.596985,181.352631 548.000732,184.803619 
z"/>
<path fill="#031829" opacity="1.000000" stroke="none" 
	d="
M433.049072,132.365433 
	C434.090881,133.312698 435.426575,134.527222 436.080383,136.037033 
	C438.922394,142.600113 441.565704,149.249222 444.672272,156.260529 
	C445.089783,157.093994 445.116272,157.533997 444.869293,158.142242 
	C443.387299,161.566895 442.534058,165.016022 440.894257,168.038437 
	C437.753571,173.827271 431.762756,178.305359 432.998230,186.013855 
	C432.998230,186.013855 432.998779,185.998886 432.672607,186.047455 
	C432.550781,187.044693 432.755157,187.993362 432.959534,188.942017 
	C432.387695,190.115814 431.815857,191.289612 431.089874,192.767792 
	C429.673584,192.472931 427.731750,192.202423 427.257568,191.222046 
	C423.676117,183.817093 420.382874,176.272720 417.009827,168.371399 
	C418.495728,169.954483 419.909760,171.988052 421.465576,173.906677 
	C422.851562,175.615845 424.394440,177.197815 425.868103,178.835938 
	C427.042633,177.362915 428.266998,175.926544 429.382385,174.409988 
	C432.747803,169.834030 436.313690,165.376892 439.289337,160.557251 
	C440.458069,158.664291 440.984222,155.785278 440.536041,153.617203 
	C439.808167,150.096069 437.982147,146.810837 436.721954,143.388367 
	C435.427185,139.871948 434.254974,136.310394 433.049072,132.365433 
z"/>
<path fill="#88C9EF" opacity="1.000000" stroke="none" 
	d="
M548.368042,184.867310 
	C545.596985,181.352631 542.323975,179.994568 538.101135,180.008331 
	C520.824951,180.064590 503.548065,179.936188 485.908997,179.551819 
	C487.887238,176.327927 490.227905,173.427979 492.832550,170.845428 
	C498.557678,172.668808 503.937653,174.876846 509.498535,175.519928 
	C518.337646,176.542145 527.294006,176.708511 536.207458,176.872391 
	C541.967346,176.978302 547.739258,176.433838 553.486450,176.558380 
	C553.327393,177.921310 553.187744,178.906693 552.763916,180.129303 
	C551.965332,182.015060 551.450867,183.663589 550.936401,185.312103 
	C550.202759,185.185089 549.468994,185.058060 548.368042,184.867310 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M545.352661,305.040771 
	C546.674377,300.033722 548.082886,294.850494 550.091553,289.911346 
	C556.594788,273.920288 563.246277,257.988159 569.986816,242.095322 
	C573.473267,233.874847 577.249939,225.776764 580.966675,217.655960 
	C581.205200,217.134796 582.058044,216.894806 582.835938,216.759460 
	C579.877869,225.284119 576.903259,233.653946 573.501770,241.846542 
	C565.140625,261.984955 556.598816,282.048462 548.061523,302.113098 
	C547.597717,303.203094 546.511108,304.028046 545.352661,305.040771 
z"/>
<path fill="#060B10" opacity="1.000000" stroke="none" 
	d="
M440.018433,221.986557 
	C440.000885,221.257401 439.983368,220.528229 439.970001,219.063370 
	C438.983185,217.220276 437.992188,216.112885 437.001221,215.005493 
	C437.004883,214.582275 437.008545,214.159073 437.026245,213.420807 
	C438.095673,213.073486 439.151093,213.041229 440.815613,213.263489 
	C442.617340,213.341904 443.809875,213.165787 445.002441,212.989685 
	C446.715576,212.990967 448.428711,212.992249 450.864838,213.290161 
	C456.557129,213.828339 461.526398,214.069901 466.071106,214.290833 
	C466.445312,216.907242 466.735535,218.936584 466.555664,220.975052 
	C458.383545,222.256058 449.659302,217.667877 442.974030,224.669037 
	C441.960938,223.548630 440.989685,222.767593 440.018433,221.986557 
z"/>
<path fill="#364E6C" opacity="1.000000" stroke="none" 
	d="
M445.142761,157.973984 
	C445.116272,157.533997 445.089783,157.093994 445.042480,156.324036 
	C445.475372,155.698822 445.929077,155.403595 446.666046,155.115509 
	C447.780792,156.271423 448.612274,157.420166 449.645721,158.847946 
	C450.966461,157.953705 452.210632,157.111298 454.026306,156.326569 
	C455.080444,157.846542 455.563110,159.308838 456.045776,160.771133 
	C455.394073,162.279037 454.742371,163.786957 453.914490,165.616333 
	C449.029266,170.863632 444.320190,175.789444 438.979065,180.635498 
	C438.222382,179.271866 437.574493,177.709915 438.079102,176.760468 
	C439.227173,174.600220 441.090607,172.833618 442.392853,170.738922 
	C444.813477,166.845337 447.795227,162.992020 445.142761,157.973984 
z"/>
<path fill="#2656A1" opacity="1.000000" stroke="none" 
	d="
M551.105652,185.667114 
	C551.450867,183.663589 551.965332,182.015060 553.077209,180.289215 
	C555.738403,179.660141 557.803894,179.115082 559.865112,178.554214 
	C561.172058,178.198578 562.818909,177.123886 563.716248,177.570694 
	C567.671753,179.540207 571.410400,181.945251 575.404114,184.296448 
	C575.111877,184.778336 574.544922,185.713318 573.977905,186.648315 
	C566.410217,186.439590 558.842590,186.230865 551.105652,185.667114 
z"/>
<path fill="#364E6C" opacity="1.000000" stroke="none" 
	d="
M574.373535,186.757385 
	C574.544922,185.713318 575.111877,184.778336 575.404114,184.296448 
	C571.410400,181.945251 567.671753,179.540207 563.716248,177.570694 
	C562.818909,177.123886 561.172058,178.198578 559.865112,178.554214 
	C557.803894,179.115082 555.738403,179.660141 553.361389,180.051971 
	C553.187744,178.906693 553.327393,177.921310 553.594727,176.299286 
	C553.830139,175.107407 553.937866,174.552139 554.283386,173.934143 
	C554.521179,173.871414 555.005737,173.956497 555.005737,173.956497 
	C557.395996,176.724579 559.487732,176.829803 561.429138,173.412781 
	C562.140930,172.159958 563.252991,171.134613 564.878662,169.159882 
	C566.534363,176.054565 571.477539,176.897293 576.703674,178.393402 
	C577.362488,181.516251 577.691040,184.061096 578.019531,186.605957 
	C576.936096,186.692795 575.852661,186.779617 574.373535,186.757385 
z"/>
<path fill="#0C1A28" opacity="1.000000" stroke="none" 
	d="
M492.568542,170.528015 
	C490.227905,173.427979 487.887238,176.327927 485.392395,179.567551 
	C484.769318,179.957336 484.300415,180.007431 483.596252,180.123779 
	C482.776520,179.880737 482.192078,179.571457 481.452942,178.827728 
	C482.872528,176.219452 484.446777,174.045609 486.021027,171.871765 
	C485.729767,171.609421 485.438477,171.347092 485.147186,171.084747 
	C483.783081,171.406326 482.418976,171.727905 481.054871,172.049484 
	C481.044952,171.877838 481.035065,171.706207 481.013184,171.277206 
	C482.717987,169.656189 484.434753,168.292526 486.597107,167.258362 
	C487.997772,167.725128 489.283081,168.318283 489.844177,167.911774 
	C491.358856,166.814499 492.565186,165.291550 494.208954,164.168335 
	C494.778107,164.848007 495.032166,165.296875 495.220642,166.045410 
	C494.292908,167.739380 493.430725,169.133698 492.568542,170.528015 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M444.869293,158.142242 
	C447.795227,162.992020 444.813477,166.845337 442.392853,170.738922 
	C441.090607,172.833618 439.227173,174.600220 438.079102,176.760468 
	C437.574493,177.709915 438.222382,179.271866 438.643982,180.687805 
	C437.187347,182.529144 435.433777,184.238403 433.339233,185.980743 
	C431.762756,178.305359 437.753571,173.827271 440.894257,168.038437 
	C442.534058,165.016022 443.387299,161.566895 444.869293,158.142242 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M590.045532,190.415421 
	C591.008301,189.253586 592.279846,188.690918 592.834534,187.719131 
	C594.804626,184.267380 596.530212,180.676147 598.727356,177.094727 
	C596.679932,184.310196 594.253540,191.568710 591.827209,198.827209 
	C591.226624,198.692764 590.626099,198.558319 590.025513,198.423874 
	C590.025513,195.891571 590.025513,193.359253 590.045532,190.415421 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M578.397339,186.709900 
	C577.691040,184.061096 577.362488,181.516251 577.048157,178.586761 
	C577.491272,178.150604 577.920227,178.099075 578.673340,178.028961 
	C581.122314,180.217407 583.247009,182.424438 585.646729,184.813171 
	C583.539490,185.601196 581.157349,186.207520 578.397339,186.709900 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M481.040894,172.446426 
	C482.418976,171.727905 483.783081,171.406326 485.147186,171.084747 
	C485.438477,171.347092 485.729767,171.609421 486.021027,171.871765 
	C484.446777,174.045609 482.872528,176.219452 481.151855,178.698212 
	C479.094086,181.062531 477.182770,183.121902 475.271454,185.181274 
	C474.723175,184.721100 474.174896,184.260941 473.626617,183.800766 
	C475.401733,181.594360 477.278412,179.459885 478.912140,177.153351 
	C479.825287,175.864182 480.335236,174.289429 481.040894,172.446426 
z"/>
<path fill="#061421" opacity="1.000000" stroke="none" 
	d="
M436.997192,215.259750 
	C437.992188,216.112885 438.983185,217.220276 439.957153,218.698898 
	C438.983246,218.276962 438.026276,217.483795 437.024658,216.356522 
	C436.984406,215.852936 436.988800,215.683472 436.997192,215.259750 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M432.971497,188.574738 
	C432.755157,187.993362 432.550781,187.044693 432.680084,186.046539 
	C433.003662,186.733856 432.993561,187.470642 432.971497,188.574738 
z"/>
<path fill="#060B10" opacity="1.000000" stroke="none" 
	d="
M601.243042,177.005829 
	C601.461365,176.334641 601.956970,175.674118 602.796143,174.998047 
	C602.935852,175.541229 602.732056,176.099991 602.301575,176.851074 
	C601.890015,177.034424 601.705200,177.025467 601.243042,177.005829 
z"/>
<path fill="#0C1A28" opacity="1.000000" stroke="none" 
	d="
M455.981323,168.270874 
	C456.152130,168.219086 456.323090,168.348633 456.470306,168.501099 
	C456.484497,168.515823 456.351593,168.672577 456.121338,168.798553 
	C455.955658,168.833694 455.974823,168.458572 455.981323,168.270874 
z"/>
<path fill="#FAF9FD" opacity="1.000000" stroke="none" 
	d="
M393.394470,709.726746 
	C383.445862,713.901184 373.339813,711.880371 363.435944,712.199097 
	C362.532623,712.228149 360.800049,710.586731 360.764282,709.669861 
	C360.472534,702.193298 360.376770,694.701782 360.558136,687.222900 
	C360.582184,686.230408 362.504517,684.441406 363.536774,684.449341 
	C371.672516,684.511780 379.843323,684.544434 387.926361,685.354553 
	C393.476990,685.910828 398.010834,688.844116 399.445038,694.838806 
	C400.918671,700.998230 399.116882,705.989380 393.394470,709.726746 
z"/>
<path fill="#F4F8FC" opacity="1.000000" stroke="none" 
	d="
M393.000580,670.995605 
	C388.617706,672.835266 384.625061,675.172485 380.376465,675.813477 
	C375.019806,676.621765 369.445068,676.219421 363.985779,675.932556 
	C362.767639,675.868530 360.663818,674.150391 360.624084,673.129333 
	C360.334473,665.689514 360.468201,658.233276 360.468201,649.539551 
	C370.034668,650.109192 379.220428,649.693054 387.975922,651.437073 
	C397.527649,653.339783 399.740967,663.105347 393.000580,670.995605 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M402.745972,648.492920 
	C403.434082,649.283081 404.160614,650.415833 404.809814,651.926270 
	C404.083130,651.147827 403.433746,649.991638 402.745972,648.492920 
z"/>
<path fill="#FAF9FD" opacity="1.000000" stroke="none" 
	d="
M481.888031,714.221558 
	C476.561188,714.225769 471.403503,715.042114 466.769440,713.839966 
	C456.955383,711.294006 453.799988,699.930481 460.624603,692.486694 
	C463.794495,689.029236 467.879669,686.410828 471.549927,683.412048 
	C471.225037,684.194763 470.900116,684.977478 470.575226,685.760193 
	C478.507629,692.554626 486.440063,699.348999 495.708313,707.287659 
	C490.283600,710.050354 486.289032,712.084717 481.888031,714.221558 
z"/>
<path fill="#F6F0FA" opacity="1.000000" stroke="none" 
	d="
M472.031677,650.760559 
	C476.348785,650.510864 480.664154,649.393250 484.190063,650.593933 
	C489.172424,652.290588 490.619232,659.176697 487.022369,663.113525 
	C483.968597,666.455872 479.875183,668.869690 476.131042,671.539124 
	C475.504120,671.986267 474.092407,672.010803 473.417023,671.606384 
	C466.934204,667.724731 465.979889,655.801819 472.031677,650.760559 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M459.869293,651.332642 
	C457.519928,648.724915 459.269073,647.794861 462.056641,647.265381 
	C461.651550,648.609253 460.888855,649.881470 459.869293,651.332642 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M459.119293,652.798340 
	C458.840271,652.784485 458.692993,652.593811 458.573883,652.386963 
	C458.565857,652.373047 458.770325,652.236755 459.080170,652.160339 
	C459.284363,652.163330 459.257690,652.617676 459.119293,652.798340 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M465.333435,645.006836 
	C465.318756,644.782471 465.565155,644.523254 466.092743,644.279907 
	C466.114136,644.521118 465.854340,644.746521 465.333435,645.006836 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M464.286102,645.923035 
	C464.088959,645.612915 464.263763,645.355835 464.800049,645.129272 
	C464.995880,645.425171 464.828033,645.697205 464.286102,645.923035 
z"/>
<path fill="#E9F2F6" opacity="1.000000" stroke="none" 
	d="
M921.009766,618.000000 
	C924.058777,620.763245 927.100281,623.534851 930.159363,626.286926 
	C932.402649,628.304932 934.669006,630.297302 936.962524,632.650513 
	C929.033691,633.178162 921.067688,633.470032 913.100891,633.496887 
	C907.180908,633.516846 901.259155,633.090820 895.340088,633.133606 
	C894.461670,633.139893 893.689453,634.789673 892.697388,635.402954 
	C891.442932,636.178528 890.013489,636.671204 888.329773,637.141724 
	C887.626099,635.922241 887.579163,634.427612 886.825256,633.839478 
	C881.882446,629.983887 880.666565,624.566406 880.415283,618.249268 
	C884.724182,618.121460 888.722412,618.950745 892.712646,618.914429 
	C902.147034,618.828552 911.577637,618.331055 921.009766,618.000000 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M867.031250,636.378540 
	C866.968262,631.220642 866.968262,626.456238 866.968262,621.691772 
	C867.388184,621.468018 867.808105,621.244263 868.228027,621.020569 
	C872.389404,625.479797 876.550842,629.939026 880.860962,634.697876 
	C881.322998,637.797913 880.355896,639.596191 877.266541,638.957397 
	C873.872253,638.255493 869.693420,641.109070 867.031250,636.378540 
z"/>
<path fill="#E9F2F6" opacity="1.000000" stroke="none" 
	d="
M941.032715,635.332397 
	C941.803772,635.251831 942.607483,635.501221 943.705566,635.877747 
	C943.588562,636.546143 943.177124,637.087463 942.765747,637.628784 
	C942.198975,636.973328 941.632141,636.317810 941.032715,635.332397 
z"/>
<path fill="#FAF9FD" opacity="1.000000" stroke="none" 
	d="
M587.614746,650.089600 
	C602.720398,651.710449 611.507202,661.230896 612.608398,677.961731 
	C612.981384,683.628174 612.065491,689.667053 610.387756,695.120178 
	C607.054199,705.955505 598.546082,711.879211 587.178284,712.249084 
	C580.869507,712.454468 574.548462,712.286011 567.756348,712.286011 
	C567.756348,691.695374 567.756348,671.163208 567.756348,650.076843 
	C574.262756,650.076843 580.710815,650.076843 587.614746,650.089600 
z"/>
<path fill="#DBECF1" opacity="1.000000" stroke="none" 
	d="
M408.000427,614.424988 
	C408.968353,609.256470 409.935303,604.512878 411.353516,599.383179 
	C419.869049,598.663025 427.933289,598.329102 435.997559,597.995117 
	C436.448700,600.858826 436.310699,603.999023 437.493286,606.519531 
	C439.140686,610.030762 442.480042,609.798889 444.717438,606.644348 
	C446.505310,604.123596 448.494141,601.745300 451.067383,599.146973 
	C452.865356,598.765076 454.510498,597.947021 455.033691,598.406555 
	C460.428833,603.144958 466.475494,599.663086 472.188049,599.991577 
	C478.602509,600.360413 485.017487,600.844849 491.437866,600.955627 
	C500.954346,601.119812 510.475800,600.999329 519.995117,601.000000 
	C521.114197,604.219482 522.233276,607.438965 523.671326,610.834167 
	C523.646484,612.455139 523.228271,613.887512 522.974365,615.348389 
	C522.430237,618.479248 520.676086,619.447632 517.627075,618.777039 
	C514.413269,618.070251 511.162384,617.468201 507.896606,617.085754 
	C505.940094,616.856567 503.513336,616.436035 502.011749,617.317505 
	C497.493378,619.969910 494.214661,617.932251 490.680847,615.482239 
	C489.565765,614.709167 487.731750,614.021851 486.581787,614.393555 
	C470.265808,619.667542 453.388580,619.924683 436.679077,618.659973 
	C428.236359,618.020935 420.069733,617.006348 411.738373,618.932434 
	C408.474396,619.687012 407.702240,617.630310 408.000427,614.424988 
z"/>
<path fill="#EDF5FA" opacity="1.000000" stroke="none" 
	d="
M465.909729,634.605957 
	C462.492310,643.934753 454.909607,646.484863 446.408966,646.903870 
	C438.266968,647.305237 430.073181,647.288696 421.937439,646.830017 
	C419.205200,646.676025 416.558441,644.646729 413.917175,643.381470 
	C412.475861,642.691040 411.127838,641.805847 409.369507,641.004272 
	C410.768829,638.082153 412.419495,635.081604 414.385132,632.303894 
	C414.884521,631.598145 416.392273,631.303528 417.424988,631.327515 
	C423.511017,631.468567 429.593201,631.769592 435.677399,631.997681 
	C444.897583,632.343262 454.119659,632.644531 463.336731,633.058105 
	C464.179810,633.095947 464.992615,633.809631 465.909729,634.605957 
z"/>
<path fill="#C8E3EB" opacity="1.000000" stroke="none" 
	d="
M578.494263,31.579464 
	C573.706604,32.071499 569.074402,32.230247 564.442078,32.234070 
	C524.207336,32.267292 483.972626,32.272713 443.363922,32.125111 
	C444.590881,30.344234 445.983795,27.660740 447.830780,27.299589 
	C453.985229,26.096191 460.298859,25.171726 466.558075,25.129999 
	C497.475037,24.923895 528.396606,24.832863 559.310303,25.211720 
	C565.294250,25.285053 571.263428,27.577942 577.199890,29.010736 
	C577.820984,29.160635 578.172852,30.426334 578.494263,31.579464 
z"/>
<path fill="#C8E3EB" opacity="1.000000" stroke="none" 
	d="
M555.092041,173.687805 
	C555.005737,173.956497 554.521179,173.871414 553.962769,173.707611 
	C552.096558,173.028122 550.811279,172.138748 549.477295,172.058533 
	C541.875488,171.601425 534.264587,171.231537 526.651550,171.049713 
	C522.515564,170.950958 518.284973,170.806259 514.244934,171.519592 
	C505.486725,173.065918 501.449036,171.287552 498.182373,163.121323 
	C498.491394,162.265488 498.667572,161.778625 499.102966,161.214325 
	C499.522278,160.836838 499.682312,160.536774 500.062378,160.058258 
	C520.068176,135.686584 539.880493,111.514992 559.623657,87.287025 
	C565.589844,79.965530 571.364380,72.487785 577.226685,65.081619 
	C579.188416,62.981495 581.150146,60.881363 583.519043,59.015579 
	C583.925964,61.149563 583.925720,63.049191 583.925537,64.948814 
	C583.269165,66.035309 582.612793,67.121803 581.633545,68.734169 
	C578.961060,70.471146 576.210388,71.376297 576.964600,74.955811 
	C576.964600,74.955811 577.000000,74.994377 576.659912,75.022919 
	C575.871338,75.690964 575.422913,76.330467 574.974548,76.969971 
	C574.974548,76.969978 575.000000,76.992790 574.667847,77.128754 
	C570.077332,81.546295 565.736877,85.751869 561.636902,90.180061 
	C560.905457,90.970062 561.170654,92.682800 560.975586,93.969467 
	C560.975586,93.969460 560.983948,93.983063 560.974670,93.979065 
	C558.070129,97.170570 555.067444,100.276184 552.303162,103.581200 
	C547.304871,109.557266 542.405518,115.617722 537.546509,121.708099 
	C533.256165,127.085732 529.151794,132.612564 524.824463,137.959595 
	C521.570251,141.980652 517.973633,145.725952 514.749084,149.769165 
	C511.522125,153.815399 508.444977,157.998444 505.606964,162.321609 
	C505.094086,163.102875 505.653381,165.619751 506.407135,166.012650 
	C508.947113,167.336624 511.777435,168.813782 514.533447,168.889069 
	C525.991028,169.202087 537.462219,168.992935 548.927979,169.057846 
	C550.711243,169.067932 552.492065,169.506287 554.708496,170.349945 
	C555.212219,171.227676 555.281555,171.502029 555.350830,171.776398 
	C555.293335,172.323975 555.235840,172.871552 555.092041,173.687805 
z"/>
<path fill="#E9F2F6" opacity="1.000000" stroke="none" 
	d="
M561.323364,93.946854 
	C561.170654,92.682800 560.905457,90.970062 561.636902,90.180061 
	C565.736877,85.751869 570.077332,81.546295 574.652893,77.123611 
	C572.752991,80.683426 570.799866,84.582237 568.226379,88.016098 
	C566.495422,90.325798 563.886963,91.977913 561.323364,93.946854 
z"/>
<path fill="#E9F2F6" opacity="1.000000" stroke="none" 
	d="
M584.295898,64.927505 
	C583.925720,63.049191 583.925964,61.149563 583.828796,58.876873 
	C584.306763,57.167435 584.882019,55.831059 585.664429,54.247231 
	C590.002625,56.027931 589.447266,61.053062 584.295898,64.927505 
z"/>
<path fill="#E9F2F6" opacity="1.000000" stroke="none" 
	d="
M577.326538,74.933273 
	C576.210388,71.376297 578.961060,70.471146 581.627441,69.104019 
	C584.808289,73.992249 579.279114,73.046005 577.326538,74.933273 
z"/>
<path fill="#B2D5DD" opacity="1.000000" stroke="none" 
	d="
M436.620483,43.072369 
	C437.138885,46.384174 437.254608,49.751236 437.195862,53.545959 
	C433.191376,50.622219 434.735474,46.872631 436.620483,43.072369 
z"/>
<path fill="#E9F2F6" opacity="1.000000" stroke="none" 
	d="
M586.242432,50.036461 
	C585.841553,49.830570 585.768677,49.664478 585.808472,49.250740 
	C586.923706,46.516342 587.926331,44.029591 588.651367,42.231407 
	C588.651367,44.476559 588.651367,47.692032 588.651367,50.996868 
	C588.008179,50.712299 587.289307,50.394279 586.242432,50.036461 
z"/>
<path fill="#B2D5DD" opacity="1.000000" stroke="none" 
	d="
M436.795685,58.171593 
	C437.331360,58.799923 437.598541,59.593292 437.823792,60.706764 
	C437.363647,60.130123 436.945435,59.233379 436.795685,58.171593 
z"/>
<path fill="#E9F2F6" opacity="1.000000" stroke="none" 
	d="
M575.300171,76.938828 
	C575.422913,76.330467 575.871338,75.690964 576.638000,75.009384 
	C576.512695,75.614113 576.069214,76.260902 575.300171,76.938828 
z"/>
<path fill="#E1EEF5" opacity="1.000000" stroke="none" 
	d="
M658.002441,607.012085 
	C657.668274,611.701599 657.334106,616.391052 657.000122,621.543945 
	C650.424011,621.476135 644.498352,626.020020 637.379211,623.573425 
	C632.293213,621.825500 626.394287,622.442932 620.479614,621.598450 
	C620.734131,617.131165 621.367065,613.070496 622.380432,608.997192 
	C624.045898,608.321777 625.293152,607.177246 626.622009,607.073059 
	C636.313599,606.313354 646.019409,605.720032 655.728699,605.232605 
	C656.455017,605.196167 657.243103,606.390137 658.002441,607.012085 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M622.000000,609.009766 
	C621.367065,613.070496 620.734131,617.131165 620.051025,621.597168 
	C617.870911,626.479431 613.706055,628.711487 609.486206,627.662354 
	C606.615234,626.948608 604.513977,623.138672 602.033081,620.357788 
	C601.002502,615.268677 600.007446,610.534851 599.168457,605.079041 
	C600.771729,601.477173 603.109192,600.624512 606.237854,600.951416 
	C608.681763,601.206726 611.173767,601.002502 613.644348,601.002441 
	C621.043579,601.002380 621.039490,601.002625 621.619202,608.102783 
	C621.644531,608.413391 621.868530,608.707825 622.000000,609.009766 
M611.759399,616.221558 
	C612.785461,613.033630 613.811462,609.845703 615.299805,605.221436 
	C611.756714,606.179932 609.458557,606.801636 607.160400,607.423401 
	C608.493896,610.513672 609.827393,613.604004 611.759399,616.221558 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M655.966553,644.969238 
	C656.168823,645.533264 655.993164,646.119385 655.606323,646.930054 
	C654.773621,646.820557 654.152039,646.486450 653.530457,646.152344 
	C654.216492,645.765320 654.902527,645.378296 655.966553,644.969238 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M653.731323,651.156128 
	C653.824707,651.347046 653.711060,651.677124 653.340698,652.092102 
	C653.230713,651.883057 653.377502,651.589172 653.731323,651.156128 
z"/>
<path fill="#DBECF1" opacity="1.000000" stroke="none" 
	d="
M86.994568,605.428223 
	C87.633621,604.561646 88.275383,604.101257 89.333626,603.280273 
	C97.499756,602.617981 105.249413,602.316284 112.999352,602.477783 
	C113.171738,609.933105 111.599655,617.396301 119.113419,623.823730 
	C120.051811,620.010803 120.415321,616.998413 121.547974,614.310059 
	C123.050697,610.743408 125.153954,607.429688 127.467865,604.005127 
	C135.073410,604.002014 142.216827,603.820984 149.344177,604.080505 
	C152.611389,604.199463 155.847061,605.184509 157.940811,605.566040 
	C161.957657,605.325989 164.958374,605.131775 167.960876,604.970764 
	C172.121033,604.747681 176.281937,604.536987 180.443878,604.351501 
	C181.242310,604.315857 182.058228,604.349976 182.846863,604.474060 
	C185.903748,604.955200 188.952881,605.485535 192.269440,606.218262 
	C193.689117,608.296753 194.844254,610.154480 195.999298,612.402466 
	C195.941971,618.131104 191.685196,618.706543 188.105194,618.853516 
	C182.757034,619.073181 177.369537,618.335388 171.998779,618.004883 
	C170.999619,616.377136 170.183304,614.591614 168.952072,613.164062 
	C167.608749,611.606628 165.815948,609.219360 164.337204,609.302185 
	C162.459747,609.407349 160.045181,611.255493 159.025238,613.023132 
	C156.767212,616.936707 155.235672,621.269470 153.205078,625.716858 
	C148.364792,625.668274 143.714417,624.935913 139.092468,625.085022 
	C130.166153,625.372864 121.261955,626.401184 112.336151,626.623779 
	C108.475151,626.720032 104.597870,625.291992 100.700676,625.093994 
	C97.771690,624.945190 94.799126,625.653931 91.423592,625.991577 
	C90.335754,624.777283 89.271507,623.612915 89.074181,622.316528 
	C88.241180,616.843933 87.666466,611.332031 86.994568,605.428223 
z"/>
<path fill="#F4F8FC" opacity="1.000000" stroke="none" 
	d="
M126.976723,887.316895 
	C122.530190,887.316956 118.576675,887.316956 114.285019,887.316956 
	C114.285019,878.855713 114.285019,870.984558 114.285019,862.244019 
	C122.721481,862.619446 130.870850,862.485229 138.876526,863.492859 
	C145.484207,864.324524 148.807297,868.752808 148.921112,874.636475 
	C149.036850,880.619324 145.944885,884.676147 139.335831,886.152710 
	C135.485962,887.012878 131.431931,886.959290 126.976723,887.316895 
z"/>
<path fill="#F4F8FC" opacity="1.000000" stroke="none" 
	d="
M140.769913,851.961304 
	C132.250824,857.161987 123.306206,854.247986 113.977798,855.224060 
	C113.977798,847.762146 113.894508,840.862366 114.096146,833.970947 
	C114.118286,833.214050 115.769363,831.882629 116.675270,831.873779 
	C122.977730,831.811890 129.318649,831.663818 135.575684,832.275452 
	C140.346939,832.741882 144.626053,834.875183 145.858444,840.247314 
	C146.978653,845.130554 145.423843,849.055786 140.769913,851.961304 
z"/>
<path fill="#B8DBE1" opacity="1.000000" stroke="none" 
	d="
M183.983521,573.004883 
	C185.410629,575.957764 186.837723,578.910583 188.630051,582.309937 
	C188.997253,583.503418 188.999237,584.250488 188.756348,585.164673 
	C188.337402,585.885437 188.163315,586.439087 187.636230,586.957947 
	C185.392258,586.807373 183.502106,586.626221 181.610001,586.602661 
	C180.637558,586.590515 179.666046,586.918152 178.686569,586.991821 
	C164.076996,588.090027 149.274063,584.725098 134.777618,588.887817 
	C133.998199,589.111694 132.927841,588.322388 131.993500,587.575439 
	C132.003799,583.765808 132.013504,580.385315 132.399994,576.992432 
	C134.449173,575.671936 136.008408,573.460876 137.816238,573.234253 
	C142.296951,572.672974 146.891281,573.005432 151.439987,573.002686 
	C162.287827,572.996094 173.135681,573.003296 183.983521,573.004883 
z"/>
<path fill="#98C7CF" opacity="1.000000" stroke="none" 
	d="
M86.988777,564.034180 
	C85.208885,563.447632 83.428986,562.861023 81.327423,561.663879 
	C81.003632,559.729797 81.126694,558.392212 80.979355,557.085083 
	C79.892738,547.445374 83.550812,546.094360 92.059814,545.976562 
	C100.469482,545.860229 108.879974,545.413330 117.282982,545.549805 
	C118.895119,545.575989 121.499001,547.373352 121.866989,548.809387 
	C122.789726,552.410217 122.693520,556.272095 122.743721,560.306641 
	C119.388199,561.729858 116.366081,563.152954 113.188072,563.968445 
	C104.491051,566.199829 95.727608,566.504089 86.988777,564.034180 
z"/>
<path fill="#A4D0D5" opacity="1.000000" stroke="none" 
	d="
M183.979370,572.592651 
	C173.135681,573.003296 162.287827,572.996094 151.439987,573.002686 
	C146.891281,573.005432 142.296951,572.672974 137.816238,573.234253 
	C136.008408,573.460876 134.449173,575.671936 132.397430,576.746704 
	C132.018066,576.513428 132.014954,576.022034 132.014954,576.022034 
	C133.338165,571.948914 134.661362,567.875732 136.338715,563.349976 
	C138.123901,562.260864 139.554932,561.624329 141.456055,560.992004 
	C150.252487,560.997437 158.582230,560.856567 166.903717,561.057800 
	C171.297577,561.164062 175.449356,560.948975 179.031677,558.346802 
	C180.050858,559.796509 181.034470,560.899475 182.018066,562.002441 
	C182.670456,565.395081 183.322845,568.787720 183.979370,572.592651 
z"/>
<path fill="#B2D5DD" opacity="1.000000" stroke="none" 
	d="
M86.996300,564.421997 
	C95.727608,566.504089 104.491051,566.199829 113.188072,563.968445 
	C116.366081,563.152954 119.388199,561.729858 123.104027,560.234253 
	C124.804466,558.255310 125.882332,556.626038 126.960197,554.996704 
	C126.632889,558.047729 126.305580,561.098755 125.647858,564.748962 
	C123.824142,565.898743 122.322067,566.426819 120.840378,567.007019 
	C119.681946,567.460632 117.790024,567.725159 117.527641,568.509705 
	C115.820854,573.613159 114.470490,578.835876 112.576935,584.013428 
	C107.471130,584.055603 102.791924,584.133423 98.112587,584.142090 
	C94.745880,584.148315 91.379005,584.062378 88.012207,584.017517 
	C87.678329,580.642212 87.344452,577.266907 87.009354,573.447021 
	C87.373955,571.962952 88.080925,570.906128 88.028984,569.887939 
	C87.941963,568.182251 87.371078,566.501221 86.996300,564.421997 
z"/>
<path fill="#C4E0E6" opacity="1.000000" stroke="none" 
	d="
M238.975601,587.002441 
	C238.546173,591.538757 238.116760,596.075134 236.874786,600.805054 
	C233.898468,600.997498 231.721329,600.839417 229.573349,601.022888 
	C221.350403,601.725159 212.679214,598.789551 205.003021,603.542969 
	C204.333496,598.062988 203.664612,593.035156 202.995728,588.007324 
	C203.660645,587.240173 204.325577,586.473083 205.467270,585.352783 
	C216.985184,585.162109 228.087936,584.308655 238.975601,587.002441 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M202.996109,587.573792 
	C203.664612,593.035156 204.333496,598.062988 205.000763,603.980347 
	C204.668396,608.247375 204.337631,611.624939 203.636627,614.994629 
	C200.844040,613.995300 198.421707,613.003784 195.999390,612.012207 
	C194.844254,610.154480 193.689117,608.296753 192.267532,605.791748 
	C191.006226,599.436951 190.011353,593.729431 189.261932,587.831177 
	C189.338669,586.759399 189.169937,585.878479 189.001221,584.997559 
	C188.999237,584.250488 188.997253,583.503418 188.992432,582.383057 
	C189.728607,578.709106 190.467590,575.408447 191.589752,572.040100 
	C191.972931,571.972412 192.014832,572.014648 192.021500,572.423218 
	C193.457520,579.389465 194.886887,585.947205 196.316238,592.505005 
	C196.848389,592.428162 197.380539,592.351318 197.912689,592.274475 
	C197.912689,585.545349 197.912689,578.816223 197.940430,572.059326 
	C197.968185,572.031616 198.023727,571.976135 198.023727,571.976135 
	C199.342270,573.081970 201.536880,574.004517 201.813797,575.328308 
	C202.619507,579.179504 202.657455,583.191406 202.996109,587.573792 
z"/>
<path fill="#B8DBE1" opacity="1.000000" stroke="none" 
	d="
M238.984131,586.575073 
	C228.087936,584.308655 216.985184,585.162109 205.476593,584.998596 
	C204.675064,581.282959 204.340973,577.568359 204.479385,573.424438 
	C210.116486,572.996704 215.294830,573.237061 220.442200,572.940186 
	C227.042862,572.559448 233.229141,573.566162 238.954834,576.961365 
	C238.970001,578.045227 238.985168,579.129028 239.005646,580.620422 
	C239.004868,582.734497 238.998764,584.441101 238.984131,586.575073 
z"/>
<path fill="#A4D0D5" opacity="1.000000" stroke="none" 
	d="
M238.964417,576.530640 
	C233.229141,573.566162 227.042862,572.559448 220.442200,572.940186 
	C215.294830,573.237061 210.116486,572.996704 204.485413,572.995117 
	C203.218185,570.769836 202.417435,568.544617 201.305450,565.776978 
	C200.997574,564.488098 201.000946,563.741577 201.445862,562.996704 
	C205.199707,563.647827 208.497223,564.737183 211.827621,564.849182 
	C217.568359,565.042236 223.352448,564.889832 229.075500,564.390991 
	C234.411362,563.925903 239.691467,562.820923 244.996948,562.394836 
	C244.998398,563.849304 244.998993,564.906555 244.999603,565.963806 
	C238.526215,566.688354 238.934555,571.503784 238.964417,576.530640 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M113.003464,584.019531 
	C114.470490,578.835876 115.820854,573.613159 117.527641,568.509705 
	C117.790024,567.725159 119.681946,567.460632 120.840378,567.007019 
	C122.322067,566.426819 123.824142,565.898743 125.643814,565.178040 
	C131.703186,566.006409 131.992844,566.492981 132.007721,575.579773 
	C132.014954,576.022034 132.018066,576.513428 132.020630,576.759155 
	C132.013504,580.385315 132.003799,583.765808 131.698425,587.721863 
	C130.268387,589.868225 129.134033,591.439026 127.635338,592.936646 
	C126.185135,590.242432 125.099289,587.621338 124.022720,584.598755 
	C124.690216,581.470886 125.436005,578.761475 125.968910,576.010742 
	C126.227646,574.675293 126.414368,573.144348 125.977837,571.939514 
	C125.786537,571.411499 123.488815,570.940186 122.968353,571.390930 
	C122.005264,572.225037 121.186935,573.732361 121.119415,575.002380 
	C120.889656,579.323914 121.013519,583.664368 120.991638,588.363647 
	C118.989029,591.153320 116.997169,593.576965 114.959969,595.617554 
	C114.277580,591.496155 113.640526,587.757874 113.003464,584.019531 
z"/>
<path fill="#A9B8CB" opacity="1.000000" stroke="none" 
	d="
M201.004333,562.995117 
	C201.000946,563.741577 200.997574,564.488098 200.988739,565.607178 
	C193.121811,565.842163 193.025238,565.752258 192.999359,558.317688 
	C192.986649,554.666931 193.197510,551.000183 192.922104,547.369934 
	C192.646835,543.741699 194.178253,542.896240 197.538498,542.926270 
	C213.301987,543.067444 229.068268,543.081665 244.831528,542.931274 
	C248.711090,542.894287 250.363190,544.199524 250.015091,548.120605 
	C249.577621,553.048584 249.495605,558.015930 248.845505,562.911316 
	C248.694992,564.044556 246.863922,564.954529 245.402878,565.965576 
	C244.998993,564.906555 244.998398,563.849304 245.280670,562.138062 
	C246.038742,558.324341 246.513931,555.164612 246.986099,551.609375 
	C246.705322,546.196777 244.102600,544.715393 239.141052,544.887207 
	C228.199127,545.265930 217.235046,545.004700 206.280014,545.004883 
	C198.248718,545.005005 198.248718,545.005249 196.000717,553.416504 
	C194.792770,558.453369 198.395126,560.453125 201.004333,562.995117 
z"/>
<path fill="#A9B8CB" opacity="1.000000" stroke="none" 
	d="
M126.967636,554.542236 
	C125.882332,556.626038 124.804466,558.255310 123.366302,559.956970 
	C122.693520,556.272095 122.789726,552.410217 121.866989,548.809387 
	C121.499001,547.373352 118.895119,545.575989 117.282982,545.549805 
	C108.879974,545.413330 100.469482,545.860229 92.059814,545.976562 
	C83.550812,546.094360 79.892738,547.445374 80.979355,557.085083 
	C81.126694,558.392212 81.003632,559.729797 81.008820,561.513306 
	C80.029327,561.358704 78.989388,560.814148 78.100243,560.085632 
	C77.843750,559.875427 78.032303,559.134888 77.998077,558.638855 
	C76.918152,542.985046 76.917801,542.983826 92.693207,542.983398 
	C101.823059,542.983154 110.952919,542.986450 120.082771,542.990479 
	C126.712975,542.993408 126.954155,543.242493 126.980515,550.103760 
	C126.985619,551.431763 126.977242,552.759705 126.967636,554.542236 
z"/>
<path fill="#A9B8CB" opacity="1.000000" stroke="none" 
	d="
M140.985962,560.987793 
	C139.554932,561.624329 138.123901,562.260864 136.348999,562.953613 
	C137.005295,557.845520 137.682312,552.584900 139.176300,547.567566 
	C139.718369,545.747131 142.165054,543.268738 143.823395,543.205505 
	C154.306610,542.805542 164.814880,542.895386 175.307785,543.134460 
	C176.564194,543.163208 178.533875,544.863953 178.877518,546.138916 
	C180.206192,551.068665 181.010040,556.139771 182.013733,561.580322 
	C181.034470,560.899475 180.050858,559.796509 178.884125,558.002686 
	C178.376648,556.372986 178.018051,555.444336 177.733353,554.493591 
	C175.468231,546.928833 174.177673,545.998291 165.874222,545.995361 
	C161.382812,545.993774 156.891403,545.995056 152.399994,545.995117 
	C143.707932,545.995239 141.728973,547.803040 141.024796,556.497864 
	C140.904282,557.985779 140.993576,559.490723 140.985962,560.987793 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M191.952118,571.951172 
	C193.538681,570.556091 195.146027,569.182312 197.377518,567.275085 
	C197.712677,569.382568 197.860260,570.310608 198.015778,571.607422 
	C198.023727,571.976135 197.968185,572.031616 197.523651,572.034180 
	C195.391006,572.029358 193.702927,572.022034 192.014832,572.014648 
	C192.014832,572.014648 191.972931,571.972412 191.952118,571.951172 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M75.000305,564.000000 
	C75.158142,563.145874 75.315674,562.291748 75.473213,561.437622 
	C75.837173,561.564392 76.201126,561.691101 76.565086,561.817871 
	C76.264648,562.512451 75.964211,563.206970 75.331581,563.950745 
	C74.999390,564.000000 75.000000,564.000000 75.000305,564.000000 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M131.954834,563.605957 
	C131.713058,563.209473 131.714783,562.815125 131.745010,562.422913 
	C131.745865,562.411987 132.051468,562.424500 132.214966,562.426086 
	C132.210754,562.820129 132.206543,563.214233 131.954834,563.605957 
z"/>
<path fill="#F6F0FA" opacity="1.000000" stroke="none" 
	d="
M654.389526,674.383118 
	C657.174255,667.443665 659.815247,660.860535 662.456238,654.277344 
	C662.876343,654.200989 663.296448,654.124573 663.716492,654.048218 
	C668.346985,665.904785 672.977417,677.761292 677.844177,690.222839 
	C667.894104,690.222839 658.571777,690.222839 648.317810,690.222839 
	C650.399475,684.785645 652.322693,679.762512 654.389526,674.383118 
z"/>
<path fill="#F6F0FA" opacity="1.000000" stroke="none" 
	d="
M793.338684,660.379272 
	C794.337952,658.095093 795.190857,656.165833 796.680969,652.794922 
	C801.751831,665.845703 806.314941,677.589844 811.153870,690.043823 
	C801.274292,690.043823 791.887573,690.043823 781.686646,690.043823 
	C785.625244,680.010681 789.408752,670.372498 793.338684,660.379272 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M802.570435,642.702576 
	C803.187500,642.268311 803.808228,641.976868 804.428894,641.685364 
	C804.091125,642.109070 803.753357,642.532776 803.093872,643.003906 
	C802.772156,643.051392 802.574036,642.845398 802.570435,642.702576 
z"/>
<path fill="#FAF9FD" opacity="1.000000" stroke="none" 
	d="
M281.591583,658.420410 
	C282.387085,654.543701 283.044250,651.121277 283.852020,646.914673 
	C294.743225,648.085876 305.868744,649.054504 316.920685,650.562256 
	C322.875000,651.374573 323.403778,651.109802 321.776794,645.058044 
	C321.667694,644.652100 322.349609,644.033447 323.065063,643.164185 
	C323.896851,642.553162 324.327148,642.291626 324.757446,642.030090 
	C324.634521,659.494873 324.879333,676.972656 324.264343,694.420105 
	C323.826141,706.852661 316.761322,712.750061 307.626404,714.053223 
	C295.211945,715.824219 287.268097,711.145203 282.980316,699.068970 
	C282.687561,696.882385 282.369537,695.448608 282.051514,694.014832 
	C281.852112,682.301453 281.652679,670.588074 281.591583,658.420410 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M325.088043,641.768677 
	C324.327148,642.291626 323.896851,642.553162 323.232239,642.908569 
	C319.970734,642.038269 316.943512,641.074097 312.991760,639.815430 
	C312.991760,633.005432 312.991760,625.466003 312.986603,617.008057 
	C312.960083,613.382629 312.938721,610.675842 312.932129,607.503906 
	C313.627777,599.652283 313.869232,592.187134 315.172028,584.911926 
	C315.839813,581.182861 317.967896,577.283569 322.878937,577.051636 
	C327.472473,576.834656 332.251678,576.992981 334.335144,581.982056 
	C335.772491,585.423950 336.186127,589.293396 337.040619,593.339722 
	C337.347168,594.805176 337.670319,595.902588 337.993835,597.332886 
	C337.997925,598.109741 338.001678,598.553650 337.997253,599.455322 
	C338.319031,608.274597 338.648987,616.636108 338.978943,624.997559 
	C338.984711,627.517456 338.747650,630.067261 339.052948,632.550354 
	C339.513275,636.294739 338.214447,638.997864 334.913330,641.322632 
	C331.629120,641.634399 328.523865,641.570801 325.088043,641.768677 
z"/>
<path fill="#ECF0F8" opacity="1.000000" stroke="none" 
	d="
M282.001465,694.393494 
	C282.369537,695.448608 282.687561,696.882385 282.976959,698.708801 
	C282.615997,697.658325 282.283691,696.215271 282.001465,694.393494 
z"/>
<path fill="#FAF9FD" opacity="1.000000" stroke="none" 
	d="
M944.000977,831.826416 
	C948.947144,832.142639 953.517944,831.938354 957.830933,832.895630 
	C963.874084,834.237122 966.451721,838.355103 966.308350,844.677795 
	C966.177002,850.470093 962.867493,855.436584 957.252319,856.142822 
	C949.742493,857.087463 942.098267,856.964111 934.080444,857.318420 
	C934.080444,848.572876 934.080444,840.546509 934.080444,831.824341 
	C937.304932,831.824341 940.407776,831.824341 944.000977,831.826416 
z"/>
<path fill="#98C7CF" opacity="1.000000" stroke="none" 
	d="
M700.004883,556.012207 
	C699.033142,552.948059 697.755493,549.924622 697.338440,546.786743 
	C697.251282,546.130981 700.249756,544.638062 701.985107,544.259583 
	C705.194336,543.559631 708.520264,543.071777 711.799011,543.063354 
	C744.240479,542.979553 776.682922,543.118896 809.123047,542.898560 
	C814.049133,542.865112 815.648926,544.373962 815.065430,549.085205 
	C814.740601,551.707886 814.991638,554.401855 814.557617,557.529419 
	C813.138245,557.951050 812.142883,557.928040 811.150757,557.859558 
	C802.632874,557.271973 794.121033,556.304749 785.596802,556.182007 
	C776.176392,556.046387 766.745728,556.719482 757.317078,556.930786 
	C756.236023,556.955078 755.161255,556.163696 754.045410,555.945068 
	C751.128723,555.373596 748.195007,554.510254 745.260742,554.480103 
	C737.154663,554.396790 729.000671,555.258728 720.949768,554.644409 
	C713.808533,554.099487 706.843384,553.183716 700.004883,556.012207 
z"/>
<path fill="#A4D0D5" opacity="1.000000" stroke="none" 
	d="
M699.993225,556.380005 
	C706.843384,553.183716 713.808533,554.099487 720.949768,554.644409 
	C729.000671,555.258728 737.154663,554.396790 745.260742,554.480103 
	C748.195007,554.510254 751.128723,555.373596 754.045410,555.945068 
	C755.161255,556.163696 756.236023,556.955078 757.317078,556.930786 
	C766.745728,556.719482 776.176392,556.046387 785.596802,556.182007 
	C794.121033,556.304749 802.632874,557.271973 811.150757,557.859558 
	C812.142883,557.928040 813.138245,557.951050 814.545593,557.996338 
	C814.968994,558.750305 814.978943,559.503052 814.624390,560.694702 
	C812.370850,565.559570 808.920471,566.637451 804.423706,566.038025 
	C798.577332,565.258850 792.708069,564.496216 786.824402,564.185974 
	C776.406677,563.636597 765.965332,563.049500 755.543823,563.193909 
	C745.152344,563.338013 734.774780,564.392151 724.387024,564.974731 
	C721.993652,565.108887 719.283630,564.225586 717.244446,565.073425 
	C713.136597,566.781433 709.912476,565.546692 706.402344,563.708313 
	C704.095337,562.500061 701.478210,561.883850 699.000000,561.002441 
	C699.327209,559.584229 699.654358,558.165955 699.993225,556.380005 
z"/>
<path fill="#B2D5DD" opacity="1.000000" stroke="none" 
	d="
M698.666260,561.001953 
	C701.478210,561.883850 704.095337,562.500061 706.402344,563.708313 
	C709.912476,565.546692 713.136597,566.781433 717.244446,565.073425 
	C719.283630,564.225586 721.993652,565.108887 724.387024,564.974731 
	C734.774780,564.392151 745.152344,563.338013 755.543823,563.193909 
	C765.965332,563.049500 776.406677,563.636597 786.824402,564.185974 
	C792.708069,564.496216 798.577332,565.258850 804.423706,566.038025 
	C808.920471,566.637451 812.370850,565.559570 814.616577,561.071045 
	C814.976318,564.038269 814.979492,567.067993 814.666016,570.590576 
	C813.569458,571.727905 812.789612,572.372498 811.574341,573.011108 
	C809.673767,572.807495 808.209839,572.451172 806.743347,572.440002 
	C800.783020,572.394653 794.613403,570.144043 788.632690,574.006592 
	C786.856140,573.669556 785.446411,573.338440 784.027100,572.607666 
	C784.168335,570.626648 784.319092,569.045349 784.514465,566.995972 
	C773.987061,566.995972 763.800842,566.995972 753.009766,566.995972 
	C753.009766,569.766235 753.009766,571.883118 752.619385,574.006226 
	C744.001099,577.315369 735.297791,576.998779 726.736328,576.879456 
	C719.173828,576.774048 711.625427,575.658325 704.070801,574.988708 
	C702.922119,573.097107 701.773499,571.205505 700.307556,568.779541 
	C698.992737,566.492737 697.995117,564.740295 696.997559,562.987793 
	C696.998657,562.548157 696.999695,562.108459 696.999146,561.339294 
	C697.442566,561.006958 697.887512,561.004211 698.666260,561.001953 
z"/>
<path fill="#B2D5DD" opacity="1.000000" stroke="none" 
	d="
M687.929138,557.989136 
	C687.948181,559.634521 687.915771,561.282532 687.997375,562.924805 
	C688.145020,565.896973 686.700134,567.253357 683.822998,566.959473 
	C677.967773,566.361389 673.326599,567.283508 672.523865,574.473145 
	C672.393616,575.639648 670.627319,576.623535 669.046753,577.563110 
	C659.351929,577.447510 650.227417,577.484314 641.103333,577.444702 
	C639.915588,577.439575 637.979248,577.405396 637.668274,576.764404 
	C636.032349,573.391968 633.591675,574.173889 631.014648,575.002441 
	C631.284912,572.631104 631.555237,570.259766 631.927979,566.990234 
	C629.185791,566.990234 625.976990,566.992859 622.768188,566.989746 
	C615.503357,566.982727 615.032593,566.521545 615.014893,559.410645 
	C615.011963,558.244690 615.011414,557.078735 615.009277,555.462524 
	C615.266907,554.521790 615.524963,554.031311 616.227356,553.279358 
	C617.120178,553.016113 617.568604,553.014465 618.292480,553.222046 
	C620.368469,559.832397 624.806213,561.319702 631.037964,561.117920 
	C643.197571,560.724243 655.379028,560.990356 667.551331,561.016785 
	C669.196106,561.020386 670.901367,560.975220 672.473633,561.370300 
	C677.322449,562.588501 681.466858,561.553650 685.350769,558.003052 
	C686.456421,558.000916 687.192810,557.995056 687.929138,557.989136 
z"/>
<path fill="#C4E0E6" opacity="1.000000" stroke="none" 
	d="
M812.009766,573.017090 
	C812.789612,572.372498 813.569458,571.727905 814.657593,571.044922 
	C815.605713,572.443848 816.245667,573.881104 816.936707,575.664612 
	C816.906067,578.904602 817.354309,581.956970 816.552429,584.635071 
	C816.035889,586.360107 813.896240,588.288330 812.107849,588.793518 
	C808.840881,589.716309 805.294861,589.651062 801.343384,589.602295 
	C799.008484,588.804382 797.204956,588.351929 795.380615,588.010498 
	C791.596130,587.302124 787.800720,586.652649 784.009766,585.979004 
	C784.008362,583.947754 784.007019,581.916565 784.273682,579.218384 
	C784.373352,576.703369 784.205017,574.855347 784.036621,573.007324 
	C785.446411,573.338440 786.856140,573.669556 788.776855,574.331238 
	C790.714600,581.466614 795.330444,582.973999 801.457397,582.015015 
	C802.103455,581.914001 802.780945,582.033997 803.438354,581.982117 
	C809.751892,581.484619 811.533875,579.614746 812.009766,573.017090 
z"/>
<path fill="#A9B8CB" opacity="1.000000" stroke="none" 
	d="
M618.017090,553.012756 
	C617.568604,553.014465 617.120178,553.016113 616.335449,553.023560 
	C615.972351,543.002930 615.972351,543.000061 625.901733,542.998535 
	C644.218567,542.995789 662.535339,542.996826 680.852173,542.996277 
	C682.646545,542.996277 684.440979,542.996277 686.460449,542.996277 
	C687.009705,546.685059 687.490723,549.915222 687.977295,553.566956 
	C687.975708,555.055725 687.968506,556.122986 687.945190,557.589722 
	C687.192810,557.995056 686.456421,558.000916 685.356934,557.568848 
	C684.994812,556.297729 685.024841,555.463501 684.992493,554.631592 
	C684.688782,546.826599 682.850830,545.009460 675.265808,545.008240 
	C659.268738,545.005737 643.271667,545.003662 627.274597,545.006836 
	C620.146729,545.008179 619.226318,545.810242 618.017090,553.012756 
z"/>
<path fill="#D2E7ED" opacity="1.000000" stroke="none" 
	d="
M273.380859,611.993164 
	C272.997162,606.309692 272.997437,600.621826 273.433411,594.469482 
	C284.246582,594.007385 294.624054,594.009827 305.002380,594.444153 
	C305.333435,599.587341 305.663666,604.298523 305.993896,609.009766 
	C305.673065,609.115479 305.414429,609.305664 305.113159,609.801147 
	C298.075470,609.684204 291.132111,608.971985 284.214020,609.165283 
	C280.707397,609.263245 277.246399,610.995422 273.380859,611.993164 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M306.326660,609.012390 
	C305.663666,604.298523 305.333435,599.587341 305.147278,594.110107 
	C306.529205,589.240051 307.767059,585.135864 309.144897,580.710999 
	C311.029846,578.273987 312.391693,575.554321 314.592896,574.156738 
	C320.738464,570.254761 331.502808,571.652466 337.609528,576.511108 
	C338.176544,576.962280 339.176697,576.869202 339.980591,577.430420 
	C341.324463,581.224670 342.663452,584.617188 343.999573,588.441956 
	C344.332397,593.582764 344.668030,598.291382 344.834717,603.246033 
	C344.107849,603.665405 343.549927,603.838806 342.844971,603.667847 
	C341.934723,601.012695 341.662079,598.169739 338.005432,598.997559 
	C338.001678,598.553650 337.997925,598.109741 338.259644,597.114197 
	C338.035797,595.365601 337.546539,594.168701 337.057251,592.971741 
	C336.186127,589.293396 335.772491,585.423950 334.335144,581.982056 
	C332.251678,576.992981 327.472473,576.834656 322.878937,577.051636 
	C317.967896,577.283569 315.839813,581.182861 315.172028,584.911926 
	C313.869232,592.187134 313.627777,599.652283 312.571899,607.519165 
	C310.794708,608.344421 309.392456,608.689331 307.990234,609.034180 
	C307.546631,609.027771 307.103027,609.021362 306.326660,609.012390 
z"/>
<path fill="#E1EEF5" opacity="1.000000" stroke="none" 
	d="
M307.991791,609.466980 
	C309.392456,608.689331 310.794708,608.344421 312.557129,607.984253 
	C312.938721,610.675842 312.960083,613.382629 312.976105,616.538757 
	C311.904938,617.802429 310.839111,618.616760 309.141785,619.913635 
	C308.727356,616.299988 308.360352,613.099915 307.991791,609.466980 
z"/>
<path fill="#6D48B3" opacity="1.000000" stroke="none" 
	d="
M241.129593,642.022339 
	C242.063751,641.863037 242.993576,642.076782 244.275162,642.352478 
	C246.153107,643.604858 247.679291,644.795288 249.282471,646.287231 
	C246.614700,645.190918 243.869965,643.793152 241.129593,642.022339 
z"/>
<path fill="#E1EEF5" opacity="1.000000" stroke="none" 
	d="
M195.999298,612.402466 
	C198.421707,613.003784 200.844040,613.995300 203.867035,615.267944 
	C204.937347,615.653381 205.463470,615.951538 205.868408,615.833557 
	C216.268036,612.804443 226.640488,612.850220 237.000626,616.007324 
	C237.000549,619.712891 237.000488,623.418457 237.001129,627.563232 
	C230.146149,625.193970 223.119080,625.447693 216.026642,626.818848 
	C215.538956,626.913086 215.037216,626.957458 214.540146,626.976685 
	C205.595917,627.322754 196.653442,627.815430 187.706024,627.934814 
	C183.428757,627.991882 179.141983,627.337708 174.431091,627.005249 
	C173.334702,624.273987 172.666748,621.543152 171.998779,618.408569 
	C177.369537,618.335388 182.757034,619.073181 188.105194,618.853516 
	C191.685196,618.706543 195.941971,618.131104 195.999298,612.402466 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M235.630035,639.527100 
	C232.622055,639.770508 229.305984,639.770142 225.546204,639.701660 
	C228.508972,639.516785 231.915466,639.400024 235.630035,639.527100 
z"/>
<path fill="#E1EEF5" opacity="1.000000" stroke="none" 
	d="
M785.004883,615.000000 
	C789.379883,616.106567 791.424683,619.440735 792.966248,623.645264 
	C785.792786,629.134033 777.587952,629.608582 769.193848,628.949585 
	C767.249573,628.797058 765.343323,628.096924 763.431946,627.601074 
	C759.445801,626.566895 755.467896,625.500793 751.242065,624.723877 
	C749.572998,624.139709 747.938049,623.498596 746.766907,622.374390 
	C744.181824,619.892944 741.830383,617.168274 739.483032,614.052002 
	C741.254944,610.263794 744.012085,611.235474 746.865967,611.349121 
	C754.242615,611.642944 761.647400,611.710571 769.021667,611.404297 
	C773.603027,611.213989 778.182922,609.742004 781.810059,614.097839 
	C782.393982,614.799133 783.916321,614.718933 785.004883,615.000000 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M734.258362,627.245850 
	C734.069824,625.443909 734.142090,623.885864 734.214355,622.327759 
	C734.743347,622.030334 735.272278,621.732849 735.801270,621.435425 
	C738.665833,624.523987 741.530396,627.612549 744.698364,630.848145 
	C746.057983,632.544556 747.452332,633.972900 748.064514,635.681458 
	C748.560791,637.066528 748.117126,638.788452 748.047974,640.690552 
	C747.250305,641.071533 746.494934,641.122925 745.367432,641.095215 
	C743.392517,640.347168 741.799255,639.133972 740.185486,639.106079 
	C734.159973,639.001770 733.857666,638.877258 734.021606,632.814636 
	C734.069702,631.036438 734.346985,629.264526 734.258362,627.245850 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M79.071220,413.967682 
	C78.713966,409.527771 78.163147,405.092896 78.046455,400.646698 
	C77.885902,394.529266 79.380409,393.099335 85.651299,392.995544 
	C91.794121,392.893799 97.939560,392.949585 105.018082,392.939209 
	C118.971863,392.955444 131.991409,392.968719 145.004745,392.989746 
	C144.998535,392.997498 145.015579,392.993103 145.114365,393.218811 
	C145.411560,393.725769 145.677399,393.909424 146.155136,394.360474 
	C146.775742,396.970703 147.150696,399.243195 147.744965,401.456818 
	C150.853439,413.035706 153.950226,424.618622 157.192383,436.160431 
	C158.687241,441.481873 160.504303,446.712769 162.138184,452.382660 
	C162.070007,453.840515 162.038940,454.900482 161.662292,455.936310 
	C160.215698,455.590851 159.114670,455.269501 158.017700,454.548676 
	C156.686935,450.095337 155.352127,446.041504 154.017517,441.574402 
	C153.017029,437.779633 152.016327,434.398132 151.017090,430.568115 
	C149.354248,423.085083 147.689987,416.050598 146.024261,408.602234 
	C145.620682,405.739777 145.438263,403.228302 144.760056,400.858795 
	C143.938416,397.988129 142.352524,395.906311 138.783646,395.924683 
	C120.953743,396.016571 103.122917,395.938202 85.293152,396.043549 
	C83.990044,396.051239 82.153877,396.671844 81.491127,397.640656 
	C79.272568,400.883698 81.511101,408.857056 84.994484,411.396271 
	C85.406715,412.765015 85.781990,413.803101 85.747833,414.877869 
	C83.249352,414.598938 81.160286,414.283325 79.071220,413.967682 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M162.175278,451.984741 
	C160.504303,446.712769 158.687241,441.481873 157.192383,436.160431 
	C153.950226,424.618622 150.853439,413.035706 147.744965,401.456818 
	C147.150696,399.243195 146.775742,396.970703 146.062988,394.129730 
	C145.626495,393.248260 145.356247,393.067932 145.015579,392.993103 
	C145.015579,392.993103 144.998535,392.997498 145.006531,392.718506 
	C145.010498,392.258606 145.006439,392.077728 145.002411,391.896912 
	C146.128098,391.366425 147.253799,390.835938 148.673401,390.181610 
	C149.516846,390.927826 150.066360,391.797882 150.923096,393.157288 
	C152.853424,398.987823 154.578186,404.300751 156.075027,409.677124 
	C158.138794,417.089813 159.909103,424.586823 162.117661,431.954010 
	C162.477295,433.153656 164.449203,433.869995 165.778000,435.190796 
	C164.553543,437.318970 163.226990,439.066040 161.455933,441.398529 
	C162.096649,443.867828 163.022232,447.223389 163.760223,450.619720 
	C163.830811,450.944611 162.731476,451.523682 162.175278,451.984741 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M104.966530,518.062988 
	C97.304993,518.047180 89.641502,517.926270 81.982903,518.068604 
	C79.015198,518.123718 77.988670,517.226196 77.826881,514.129456 
	C77.597168,509.732880 76.547729,505.383789 75.952820,501.000275 
	C75.414436,497.033234 76.539612,494.506958 81.255692,494.837891 
	C82.849945,494.949768 84.500404,494.260834 86.555618,494.261292 
	C86.992805,495.062195 86.999550,495.539642 86.759888,496.290405 
	C85.037270,496.720734 83.561058,496.877716 81.667900,497.034760 
	C76.998276,499.875061 79.117622,503.509888 80.011559,507.420715 
	C80.226128,510.785614 79.100700,514.553162 83.800270,514.956177 
	C89.324844,515.429871 94.874763,515.601501 100.405533,516.013733 
	C101.963394,516.129822 103.492630,516.629883 105.026169,517.231689 
	C105.000404,517.694031 104.983467,517.878540 104.966530,518.062988 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M144.912338,391.507751 
	C145.006439,392.077728 145.010498,392.258606 145.012756,392.710754 
	C131.991409,392.968719 118.971863,392.955444 105.487022,392.908020 
	C107.953712,391.926605 110.861015,390.265137 113.822212,390.162750 
	C123.626572,389.823761 133.450470,390.030670 143.266373,390.099670 
	C143.787369,390.103333 144.303757,390.763855 144.912338,391.507751 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M87.006302,496.017120 
	C86.999550,495.539642 86.992805,495.062195 86.970474,494.224609 
	C86.943634,491.274109 86.932381,488.683777 87.206528,485.343719 
	C87.692513,478.043335 87.893105,471.492645 88.302658,465.095337 
	C89.335083,465.172668 90.158554,465.096619 90.988022,465.466370 
	C91.330719,470.609711 91.667435,475.307312 91.946335,480.416718 
	C91.574387,485.208252 91.260262,489.587982 90.657990,494.118103 
	C89.248665,494.851349 88.127480,495.434235 87.006302,496.017120 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M90.982033,465.020569 
	C90.158554,465.096619 89.335083,465.172668 88.278595,464.643799 
	C87.694496,460.244995 87.343422,456.451080 86.992340,452.657166 
	C86.991928,448.493011 86.991516,444.328827 87.251076,439.405640 
	C87.695259,436.756805 87.879463,434.867035 88.444519,433.078766 
	C89.217407,434.791351 89.666328,436.391205 89.986908,438.016357 
	C90.376350,439.990662 90.657555,441.986298 90.992126,444.391479 
	C91.016136,446.205902 91.033615,447.601624 91.035156,449.464233 
	C91.030724,454.952881 91.042236,459.974609 91.035507,465.001129 
	C91.017273,465.005920 90.982033,465.020599 90.982033,465.020569 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M86.601204,452.833710 
	C87.343422,456.451080 87.694496,460.244995 88.069633,464.490417 
	C87.893105,471.492645 87.692513,478.043335 87.178009,484.911621 
	C86.219589,483.964569 85.040474,482.709808 85.016853,481.433655 
	C84.856903,472.794159 84.906586,464.149750 85.001511,455.508179 
	C85.010704,454.671234 85.788864,453.842712 86.601204,452.833710 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M79.052200,414.369934 
	C81.160286,414.283325 83.249352,414.598938 86.040459,414.959595 
	C87.221535,415.607635 87.700569,416.210571 88.262756,417.232483 
	C88.249565,422.093628 88.153229,426.535858 87.816849,430.856812 
	C87.382507,430.436951 87.188210,430.138367 86.993904,429.839813 
	C86.998917,428.365845 86.920067,426.885895 87.024391,425.418976 
	C87.361618,420.677032 87.397118,416.264282 80.578789,416.802094 
	C80.119797,416.838287 79.550934,415.481262 79.052200,414.369934 
z"/>
<path fill="#364E6C" opacity="1.000000" stroke="none" 
	d="
M105.253265,518.338135 
	C104.983467,517.878540 105.000404,517.694031 105.388504,517.234253 
	C106.486107,516.960632 107.212555,516.962219 108.412636,516.983398 
	C114.585373,517.004089 120.284477,517.005249 125.993271,517.317505 
	C126.005219,518.042419 126.007454,518.456238 126.009689,518.870117 
	C121.955780,518.971558 117.901878,519.072937 113.392685,519.294556 
	C110.471603,519.147583 108.005798,518.880493 105.253265,518.338135 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M86.628464,429.936737 
	C87.188210,430.138367 87.382507,430.436951 87.800201,431.190308 
	C88.036949,432.089142 88.050308,432.533203 88.063667,432.977234 
	C87.879463,434.867035 87.695259,436.756805 87.241730,438.961090 
	C83.057587,436.828735 82.912682,434.940857 86.628464,429.936737 
z"/>
<path fill="#DBECF1" opacity="1.000000" stroke="none" 
	d="
M342.992004,604.012207 
	C343.549927,603.838806 344.107849,603.665405 345.252380,603.245789 
	C355.557709,602.328918 365.276398,601.658386 374.995117,600.987793 
	C375.164215,600.991455 375.333282,600.995117 375.755920,601.009338 
	C375.673828,605.718750 375.338196,610.417664 375.004944,615.560669 
	C371.769714,619.606995 367.712097,620.496643 363.018463,620.053223 
	C357.313477,619.514221 351.572906,619.351990 345.424072,618.565979 
	C344.330963,613.407532 343.661469,608.709900 342.992004,604.012207 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M342.844971,603.667847 
	C343.661469,608.709900 344.330963,613.407532 345.001160,618.558716 
	C343.219330,620.976013 341.436798,622.939819 339.316589,624.950623 
	C338.648987,616.636108 338.319031,608.274597 337.997253,599.455322 
	C341.662079,598.169739 341.934723,601.012695 342.844971,603.667847 
z"/>
<path fill="#B2D5DD" opacity="1.000000" stroke="none" 
	d="
M947.738403,572.742310 
	C946.986511,576.817932 946.052856,580.656921 944.799744,584.388672 
	C944.549744,585.133057 943.132812,585.750488 942.160645,585.935181 
	C939.741821,586.394714 937.247681,586.928711 934.822693,586.783447 
	C930.336609,586.514832 925.540283,586.591614 921.541931,584.922607 
	C919.330750,583.999512 917.492432,579.934814 917.186035,577.102417 
	C916.096252,567.026306 916.372559,566.996277 906.347778,566.996033 
	C899.746277,566.995850 893.144714,566.995972 884.771362,566.995972 
	C886.852173,570.940369 888.281433,573.649658 889.455139,576.672852 
	C880.890442,575.513184 872.695496,575.232727 864.197693,576.519653 
	C857.297791,577.564514 849.818665,578.827942 843.282043,573.777527 
	C841.848938,572.670288 839.135864,573.219727 837.012207,573.006226 
	C835.337646,569.283142 833.663086,565.560059 832.240234,561.419495 
	C832.662903,560.997314 832.833923,560.992554 833.383667,560.999146 
	C835.883606,561.905823 837.969543,562.900696 840.137146,563.664062 
	C841.793945,564.247620 843.541870,564.840576 845.270081,564.909668 
	C855.647583,565.324463 866.033020,565.832092 876.411560,565.752747 
	C879.442200,565.729614 882.460083,563.894043 885.481750,562.883179 
	C887.221680,562.301025 888.992798,561.121826 890.688843,561.223999 
	C896.576416,561.578552 902.531006,561.930237 908.280151,563.138916 
	C913.330994,564.200745 918.730042,565.530518 922.911682,568.330383 
	C928.514648,572.081970 934.179260,570.298157 939.748535,570.216370 
	C941.522644,570.190308 943.247681,566.820374 945.330811,564.986206 
	C946.114258,564.998657 946.562012,565.005432 947.009766,565.012207 
	C947.154602,567.497437 947.299438,569.982666 947.738403,572.742310 
z"/>
<path fill="#B8DBE1" opacity="1.000000" stroke="none" 
	d="
M837.002563,573.269287 
	C839.135864,573.219727 841.848938,572.670288 843.282043,573.777527 
	C849.818665,578.827942 857.297791,577.564514 864.197693,576.519653 
	C872.695496,575.232727 880.890442,575.513184 889.591248,576.995728 
	C891.966125,580.436401 893.949341,583.867981 895.552856,587.596191 
	C893.554443,587.595398 891.941956,587.080261 890.316162,587.034485 
	C876.549866,586.646240 862.781067,586.343628 849.013062,586.015320 
	C845.027893,582.255554 841.042725,578.495789 837.010498,574.396973 
	C836.973328,573.882629 836.983093,573.707458 837.002563,573.269287 
z"/>
<path fill="#A9B8CB" opacity="1.000000" stroke="none" 
	d="
M947.059998,564.598389 
	C946.562012,565.005432 946.114258,564.998657 945.334229,564.538330 
	C944.999695,561.389099 944.997375,558.693298 944.997559,555.568176 
	C944.982178,546.153442 943.779785,545.065552 934.490662,545.624939 
	C934.161438,545.644836 933.830627,545.647827 933.500610,545.644531 
	C912.228271,545.432678 890.956116,545.192139 869.683411,545.018616 
	C860.449036,544.943298 851.213623,545.007385 841.978638,545.004333 
	C835.525635,545.002197 833.125305,547.406677 833.008789,554.434814 
	C833.005249,556.910645 833.005066,558.949219 833.004883,560.987793 
	C832.833923,560.992554 832.662903,560.997314 832.235474,561.015503 
	C831.322083,559.558899 830.087585,558.085144 830.096863,556.619324 
	C830.198730,540.465149 827.645996,543.087524 843.183533,543.027161 
	C876.333374,542.898315 909.484131,542.982849 942.634521,542.991333 
	C948.776794,542.992859 949.648132,543.942993 948.942017,549.948303 
	C948.383362,554.699890 947.724609,559.439575 947.059998,564.598389 
z"/>
<path fill="#DBECF1" opacity="1.000000" stroke="none" 
	d="
M921.006470,617.610718 
	C911.577637,618.331055 902.147034,618.828552 892.712646,618.914429 
	C888.722412,618.950745 884.724182,618.121460 880.364807,617.847168 
	C875.904419,618.144897 872.787354,616.756287 870.501770,613.060608 
	C869.204895,610.963684 866.937256,609.467163 865.050415,607.356201 
	C864.666504,604.940491 864.338989,602.868835 864.453857,600.399841 
	C872.645020,600.335815 880.408508,600.474792 888.138489,601.058289 
	C895.531006,601.616333 903.158691,601.017944 910.046814,604.786987 
	C910.832153,605.216675 912.000610,604.945984 912.991455,605.000000 
	C912.414917,609.240906 916.088928,608.663086 918.668823,609.858337 
	C919.672607,612.412048 920.337952,614.816711 921.006470,617.610718 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M949.769775,512.980347 
	C949.822266,519.290344 944.994995,518.875427 941.040405,518.912292 
	C923.748657,519.073547 906.453918,518.906677 889.161865,519.046326 
	C882.382751,519.101074 875.607666,519.647461 868.300354,519.544067 
	C865.921570,518.726013 864.083313,518.055969 862.223022,517.987671 
	C851.431335,517.591553 840.634338,517.336853 829.854675,516.648315 
	C829.946350,515.495361 830.022522,514.728333 830.448975,513.892761 
	C831.213562,512.520630 831.627991,511.217072 832.403687,510.012207 
	C834.100098,512.583069 835.520996,514.542236 838.971924,514.275635 
	C843.862854,513.897766 848.799927,513.959167 853.712891,514.056458 
	C866.328918,514.306152 878.941040,514.845276 891.557495,514.962280 
	C908.934204,515.123352 926.313416,515.002625 945.072205,515.002625 
	C945.072205,508.490204 945.072205,502.764496 945.395752,497.035400 
	C946.150635,497.024841 946.582031,497.017670 947.273804,497.224243 
	C947.682800,499.290436 947.831360,501.142883 947.979980,502.995331 
	C948.022522,505.311798 948.065063,507.628296 948.100708,510.703308 
	C948.652466,511.968018 949.211121,512.474182 949.769775,512.980347 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M830.098755,513.961365 
	C830.022522,514.728333 829.946350,515.495361 829.645142,516.780090 
	C829.246094,517.751099 829.072083,518.204407 828.898071,518.657654 
	C828.131226,518.753784 827.364319,518.849915 826.242798,519.103149 
	C825.888062,519.260315 825.843079,519.044495 825.808350,518.758240 
	C825.469360,518.337891 825.165100,518.203735 824.860901,518.069580 
	C823.509155,518.002258 822.157410,517.934937 820.270630,517.654175 
	C819.147705,517.195679 818.559814,516.950745 817.976685,516.255615 
	C817.978394,509.846802 818.086426,503.884460 817.887451,497.932404 
	C817.842834,496.598694 816.723328,495.300903 816.036743,493.656738 
	C815.986572,492.880615 815.998169,492.434357 816.253479,491.804138 
	C816.327332,490.749878 816.157532,489.879486 815.954529,488.674194 
	C815.957214,487.892914 815.993042,487.446472 816.401611,487.003479 
	C821.173340,489.336090 825.572327,491.665222 829.975098,494.463257 
	C830.018860,501.275208 830.058777,507.618286 830.098755,513.961365 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M947.013306,497.010498 
	C946.582031,497.017670 946.150635,497.024841 945.369873,497.024323 
	C945.020447,497.016602 944.965332,497.004791 944.979492,496.536469 
	C944.994385,491.570038 945.327271,487.035431 944.838379,482.591156 
	C944.632080,480.715851 942.985901,477.855194 941.487549,477.483215 
	C937.228394,476.425873 932.715210,476.182892 928.289490,476.054871 
	C921.705078,475.864410 921.198547,476.504761 920.616577,483.083984 
	C919.166504,483.761292 918.043640,484.399750 916.864990,484.578125 
	C916.708679,480.431885 916.608093,476.745758 916.507568,473.059631 
	C918.912415,472.109711 921.290588,470.439728 923.727295,470.349548 
	C930.168091,470.111176 936.634277,470.557251 943.693604,471.052368 
	C945.196533,471.574524 946.096069,471.775177 946.995667,471.975800 
	C947.642395,476.353485 948.289185,480.731171 948.528259,485.659821 
	C947.751465,489.810699 947.382385,493.410583 947.013306,497.010498 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M829.313477,518.804504 
	C829.072083,518.204407 829.246094,517.751099 829.629639,517.166077 
	C840.634338,517.336853 851.431335,517.591553 862.223022,517.987671 
	C864.083313,518.055969 865.921570,518.726013 867.879272,519.497559 
	C855.235352,519.568420 842.482117,519.259888 829.313477,518.804504 
z"/>
<path fill="#456D8D" opacity="1.000000" stroke="none" 
	d="
M947.273804,497.224243 
	C947.382385,493.410583 947.751465,489.810699 948.495911,486.113831 
	C948.771118,491.513947 948.670959,497.011078 948.275391,502.751770 
	C947.831360,501.142883 947.682800,499.290436 947.273804,497.224243 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M947.033569,471.627869 
	C946.096069,471.775177 945.196533,471.574524 944.100769,471.071289 
	C944.226074,470.078186 944.547546,469.387726 944.881470,468.411102 
	C947.941406,467.574188 947.998779,469.087280 947.033569,471.627869 
z"/>
<path fill="#456D8D" opacity="1.000000" stroke="none" 
	d="
M949.794739,512.669312 
	C949.211121,512.474182 948.652466,511.968018 948.101746,511.135376 
	C948.679688,511.325348 949.249695,511.841797 949.794739,512.669312 
z"/>
<path fill="#91B7EB" opacity="1.000000" stroke="none" 
	d="
M456.372742,160.720551 
	C455.563110,159.308838 455.080444,157.846542 454.326904,156.192108 
	C453.971130,155.565735 453.886169,155.131485 453.859680,154.370636 
	C456.082306,152.148560 458.246429,150.253098 461.078918,148.189545 
	C463.506927,145.346176 465.266541,142.670898 467.026123,139.995636 
	C467.527435,139.127289 468.028748,138.258972 469.122314,137.189911 
	C475.073120,132.645157 480.421234,128.292984 482.132080,121.098526 
	C482.432434,120.980431 482.677612,120.790916 483.314209,120.266815 
	C488.443390,115.010536 493.170258,110.057266 497.748291,104.970078 
	C498.276276,104.383369 497.975922,103.051201 498.059570,102.064644 
	C498.059570,102.064644 498.000580,102.003319 498.370819,101.995674 
	C499.512970,101.003937 500.284851,100.019836 501.056763,99.035736 
	C501.221985,98.488037 501.387207,97.940331 502.150269,97.217812 
	C504.209595,95.821075 505.947113,94.811562 507.081360,93.337761 
	C510.615570,88.745293 513.858521,83.930023 517.332397,79.289444 
	C521.266235,74.034477 520.552185,70.325508 515.048340,66.747612 
	C514.022827,66.080956 513.644531,64.418747 512.967529,63.215981 
	C515.683472,63.128784 518.399414,63.041588 521.608643,63.426350 
	C523.430603,67.992310 524.759277,72.086319 526.087891,76.180328 
	C513.983521,91.115311 501.949921,106.108391 489.753632,120.967926 
	C478.828796,134.278366 467.724731,147.441711 456.372742,160.720551 
z"/>
<path fill="#B8DDFA" opacity="1.000000" stroke="none" 
	d="
M512.508118,63.127491 
	C513.644531,64.418747 514.022827,66.080956 515.048340,66.747612 
	C520.552185,70.325508 521.266235,74.034477 517.332397,79.289444 
	C513.858521,83.930023 510.615570,88.745293 507.081360,93.337761 
	C505.947113,94.811562 504.209595,95.821075 502.384644,97.045601 
	C504.157288,93.772995 506.305878,90.505882 508.426086,87.220459 
	C510.821686,83.508385 513.016846,79.651764 515.621704,76.094223 
	C518.273804,72.472206 516.037109,70.739975 513.208984,69.765663 
	C510.291992,68.760727 507.099426,68.128181 504.019440,68.095947 
	C488.229553,67.930717 472.436920,67.972832 456.645599,68.031921 
	C452.309357,68.048149 448.640472,66.876335 445.979675,62.925812 
	C446.705139,62.477036 447.445862,62.366306 448.694366,62.680676 
	C453.463165,63.143322 457.748535,66.674500 461.983887,63.016823 
	C478.672150,63.024216 495.360443,63.031609 512.508118,63.127491 
z"/>
<path fill="#B8DDFA" opacity="1.000000" stroke="none" 
	d="
M497.706360,102.069138 
	C497.975922,103.051201 498.276276,104.383369 497.748291,104.970078 
	C493.170258,110.057266 488.443390,115.010536 483.421082,120.057663 
	C487.838593,114.098999 492.595856,108.086319 497.706360,102.069138 
z"/>
<path fill="#B8DDFA" opacity="1.000000" stroke="none" 
	d="
M481.770355,121.091721 
	C480.421234,128.292984 475.073120,132.645157 469.367157,137.018799 
	C473.149353,131.727264 477.278992,126.406090 481.770355,121.091721 
z"/>
<path fill="#B8DDFA" opacity="1.000000" stroke="none" 
	d="
M466.671753,140.021637 
	C465.266541,142.670898 463.506927,145.346176 461.374268,148.023468 
	C462.773285,145.366196 464.545319,142.706909 466.671753,140.021637 
z"/>
<path fill="#B8DDFA" opacity="1.000000" stroke="none" 
	d="
M500.675903,99.026337 
	C500.284851,100.019836 499.512970,101.003937 498.403717,102.022263 
	C498.809265,101.043312 499.552155,100.030128 500.675903,99.026337 
z"/>
<path fill="#B8DDFA" opacity="1.000000" stroke="none" 
	d="
M555.637085,171.585693 
	C555.281555,171.502029 555.212219,171.227676 555.089478,170.533508 
	C555.223389,170.093201 555.410767,170.072662 555.880493,170.068573 
	C556.083008,170.521667 556.003113,170.958313 555.637085,171.585693 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M574.109314,392.990479 
	C594.523743,392.985168 614.919189,393.150360 635.308838,392.877167 
	C640.326233,392.809967 643.688293,393.736267 644.679749,399.122253 
	C644.803040,399.791901 645.699219,400.319275 646.580200,400.992523 
	C647.173706,402.806274 647.424194,404.540070 647.825439,407.020660 
	C649.336182,413.510345 650.696228,419.253265 651.691406,425.003235 
	C650.597046,425.023346 649.867554,425.036407 649.090820,424.632751 
	C648.025269,420.813538 647.006958,417.411041 645.986755,413.607727 
	C645.227661,409.990479 644.809875,406.640320 643.610901,403.597992 
	C642.569031,400.954132 641.142700,397.288300 638.999817,396.507904 
	C635.112915,395.092255 630.570007,395.225525 626.295410,395.174500 
	C614.071045,395.028656 601.837036,394.884613 589.621948,395.248474 
	C585.913025,395.358978 581.489563,396.182251 578.775635,398.383362 
	C575.765991,400.824310 577.800781,409.754822 581.154663,413.280792 
	C581.425842,413.724060 581.660706,413.889038 581.980957,414.293274 
	C582.043701,415.040466 582.077881,415.467529 581.784058,415.936768 
	C581.239014,416.014557 581.021973,416.050201 580.384827,416.075256 
	C575.294617,416.906586 573.868103,414.743378 574.058655,410.302826 
	C574.305969,404.540009 574.073059,398.756561 574.046875,392.981995 
	C574.046875,392.981995 574.088196,392.999329 574.109314,392.990479 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M670.984131,477.097107 
	C671.650024,480.093018 672.315918,483.088898 672.772949,486.626282 
	C672.375793,487.458313 672.187439,487.748840 671.658081,487.993652 
	C670.225525,487.634186 669.133972,487.320435 668.024170,486.616974 
	C667.016724,483.478851 666.027588,480.730438 665.035645,477.584015 
	C663.692566,473.124817 662.352356,469.063629 661.010986,464.602570 
	C660.677246,462.127502 660.344727,460.052307 660.010986,457.528809 
	C660.034485,454.681091 660.059204,452.281708 660.406311,449.914001 
	C661.160095,449.982819 661.591492,450.020020 662.022888,450.057190 
	C663.014465,454.080780 664.006042,458.104370 665.013123,462.830078 
	C665.362976,464.015137 665.697327,464.498047 666.031677,464.980957 
	C666.031677,464.980957 666.000122,464.982635 666.005554,465.386047 
	C667.468140,469.646881 665.759705,474.896545 670.984131,477.097107 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M660.083923,449.882324 
	C660.059204,452.281708 660.034485,454.681091 659.683716,457.490112 
	C658.581665,456.929077 657.805603,455.958405 657.025879,454.568604 
	C657.021240,450.245819 656.900879,446.388641 653.057861,443.542938 
	C652.359009,438.368622 651.736145,433.614960 650.908813,428.897095 
	C650.779419,428.159180 649.719788,427.584442 649.087585,426.619568 
	C649.102600,425.886414 649.120300,425.467957 649.138000,425.049500 
	C649.867554,425.036407 650.597046,425.023346 651.929688,425.175873 
	C652.688538,425.898315 652.844238,426.455139 653.000488,427.428589 
	C652.957153,431.455841 652.920105,435.063507 656.916382,436.933594 
	C656.942749,437.108704 656.969116,437.283844 657.025757,438.115662 
	C658.065308,442.475647 659.074646,446.178986 660.083923,449.882324 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M653.000000,427.011993 
	C652.844238,426.455139 652.688538,425.898315 652.294556,425.168823 
	C650.696228,419.253265 649.336182,413.510345 647.982544,407.364227 
	C649.360596,408.265625 651.319641,409.323517 651.991333,410.922302 
	C653.827209,415.292419 655.224792,419.860626 656.532349,424.425873 
	C656.766052,425.241943 655.738281,426.419312 654.888611,427.624420 
	C653.989441,427.549957 653.494751,427.280975 653.000000,427.011993 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M674.956055,488.754150 
	C677.150635,488.994812 679.345154,489.235443 682.079224,489.156982 
	C683.006226,488.658234 683.393860,488.478638 684.071045,488.520966 
	C684.579407,488.844238 684.798157,488.945618 684.983765,489.407166 
	C684.640015,491.157898 684.329407,492.548431 683.842651,494.187103 
	C682.778381,494.309814 681.890137,494.184418 680.947388,493.704865 
	C679.209839,491.257599 677.864990,491.261719 676.636230,494.012024 
	C675.883301,494.019928 675.452759,494.000763 675.019531,493.646301 
	C674.996643,491.792023 674.976379,490.273102 674.956055,488.754150 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M660.406311,449.914001 
	C659.074646,446.178986 658.065308,442.475647 657.059692,438.379150 
	C661.823914,440.451172 662.755737,444.739807 662.298950,449.807770 
	C661.591492,450.020020 661.160095,449.982819 660.406311,449.914001 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M671.006592,476.655701 
	C665.759705,474.896545 667.468140,469.646881 666.021912,465.377747 
	C670.644226,465.374969 669.588684,469.694336 670.932068,472.295776 
	C671.471375,473.340149 671.026184,474.892914 671.006592,476.655701 
z"/>
<path fill="#BFC0BD" opacity="1.000000" stroke="none" 
	d="
M683.781433,488.299042 
	C683.393860,488.478638 683.006226,488.658234 682.298462,488.877533 
	C680.749268,488.111389 679.520386,487.305573 678.224609,486.168091 
	C680.689087,484.838501 683.220337,483.840546 686.279480,482.958252 
	C686.807373,483.073914 687.175781,483.035095 687.094238,483.381226 
	C686.514648,484.792755 686.016663,485.858154 685.263428,486.917358 
	C684.599304,487.373749 684.190369,487.836395 683.781433,488.299042 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M653.000488,427.428589 
	C653.494751,427.280975 653.989441,427.549957 654.743286,427.932739 
	C655.655945,430.750427 656.309448,433.454315 656.939697,436.545898 
	C652.920105,435.063507 652.957153,431.455841 653.000488,427.428589 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M674.847839,488.500061 
	C674.976379,490.273102 674.996643,491.792023 674.660095,493.592468 
	C673.535217,491.929108 672.767151,489.984222 671.999146,488.039368 
	C672.187439,487.748840 672.375793,487.458313 672.774414,487.007690 
	C673.569702,487.313721 674.154663,487.779877 674.847839,488.500061 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M666.033630,464.671326 
	C665.697327,464.498047 665.362976,464.015137 665.026367,463.215057 
	C665.361206,463.385834 665.698364,463.873779 666.033630,464.671326 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M522.115479,500.603455 
	C522.153076,505.742340 522.111450,510.411865 521.690674,515.701904 
	C519.524414,516.560730 517.738159,516.997131 515.950012,517.004944 
	C504.971405,517.052673 493.992340,517.003540 483.013428,516.986694 
	C474.183655,504.047913 465.353882,491.109131 456.215576,477.989166 
	C453.916229,475.233337 451.925415,472.658691 449.518311,469.610474 
	C449.079590,467.755157 449.057129,466.373413 449.370270,465.037109 
	C450.377472,465.761139 451.035522,466.453766 451.722992,467.115875 
	C454.790802,470.070648 457.867004,473.016663 461.090027,476.254944 
	C462.148651,477.036987 463.057434,477.529999 463.978455,478.411682 
	C464.565002,484.481110 467.854980,488.510712 472.013916,492.392700 
	C472.575287,494.256927 472.971405,495.834045 473.745300,497.196564 
	C475.762878,500.748566 478.608124,503.978577 479.908295,507.755280 
	C481.868011,513.447693 486.211060,515.432190 491.183716,515.825500 
	C498.264954,516.385681 505.429657,516.157959 512.545898,515.905579 
	C516.694885,515.758484 519.282227,513.636536 519.047485,508.922668 
	C518.833374,504.624268 518.972290,500.308319 518.967346,495.534119 
	C518.993652,490.376617 519.008179,485.685089 519.300659,481.113403 
	C520.135742,481.472687 520.692932,481.712128 521.250122,481.951569 
	C521.512146,488.012390 521.774170,494.073242 522.115479,500.603455 
z"/>
<path fill="#456D8D" opacity="1.000000" stroke="none" 
	d="
M451.042847,517.943359 
	C447.989471,518.288086 444.936066,518.632812 441.269348,518.719360 
	C440.097076,517.939697 439.538116,517.418274 439.224670,516.616821 
	C441.355072,515.887085 443.261597,515.510559 445.116913,514.961243 
	C446.300262,514.610840 447.894592,514.326294 448.467773,513.459106 
	C451.451935,508.944427 448.058197,498.066986 442.994629,497.398590 
	C438.583038,496.816284 437.147369,494.469391 436.021027,490.730713 
	C436.620148,488.569153 437.186035,486.744415 437.811371,485.333252 
	C438.796539,494.149933 439.737061,495.013000 447.965393,494.917664 
	C449.627747,494.898438 451.289093,494.792572 452.976501,495.071167 
	C453.033295,495.641876 453.064484,495.868011 452.889069,496.454407 
	C452.464508,498.763092 452.181702,500.706909 452.041229,502.660950 
	C451.675140,507.752686 451.371460,512.848938 451.042847,517.943359 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M452.950867,494.726624 
	C451.289093,494.792572 449.627747,494.898438 447.965393,494.917664 
	C439.737061,495.013000 438.796539,494.149933 437.896179,484.879639 
	C438.100861,479.629517 438.280182,475.246521 438.829742,470.888672 
	C440.462219,470.938782 441.724426,470.963745 443.000732,471.401917 
	C442.829010,473.267639 442.342041,474.757843 442.517853,476.165222 
	C442.931091,479.473236 443.661011,482.741699 444.262756,486.026154 
	C443.915833,490.624969 447.661316,490.036835 450.327271,490.791595 
	C452.136292,491.303741 453.963074,491.753174 455.507355,492.599243 
	C454.472137,493.554108 453.711487,494.140350 452.950867,494.726624 
z"/>
<path fill="#456D8D" opacity="1.000000" stroke="none" 
	d="
M395.888733,503.737549 
	C395.681549,501.724213 395.474335,499.710907 395.487183,497.205750 
	C399.753632,495.719391 403.799988,494.724854 407.903625,494.138062 
	C407.971802,495.359283 407.982697,496.172729 407.662476,496.990997 
	C406.890442,497.017426 406.449585,497.039093 405.613617,497.045776 
	C403.678955,497.990967 401.333038,498.618652 400.770844,499.981903 
	C399.664581,502.664337 399.209381,505.742737 399.100159,508.680206 
	C398.919342,513.542969 401.493103,515.936890 406.368774,515.964600 
	C412.531433,515.999573 418.696686,515.875977 424.856323,516.017456 
	C427.597351,516.080383 430.327393,516.624084 432.590454,516.975830 
	C420.459473,516.966309 408.800415,516.930542 397.113892,516.443665 
	C396.687164,511.907562 396.287933,507.822540 395.888733,503.737549 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M407.846344,493.730286 
	C403.799988,494.724854 399.753632,495.719391 395.373657,496.897522 
	C394.624542,495.904327 394.209045,494.727478 393.622925,493.217346 
	C396.033600,491.321655 398.614899,489.759247 401.894165,488.058716 
	C405.386230,487.074982 405.664032,485.368744 404.213135,482.765076 
	C404.240265,482.028168 404.300964,481.608917 404.709961,481.157959 
	C405.765198,481.028015 406.472137,480.929779 407.402954,481.173828 
	C407.728729,484.353333 407.830627,487.190521 407.881958,490.403381 
	C407.845184,491.538300 407.858948,492.297516 407.846375,493.224365 
	C407.820038,493.391968 407.846344,493.730286 407.846344,493.730286 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M451.451660,517.995972 
	C451.371460,512.848938 451.675140,507.752686 452.041229,502.660950 
	C452.181702,500.706909 452.464508,498.763092 453.293579,496.449707 
	C454.603577,497.308319 455.783325,498.485809 455.905975,499.764648 
	C456.203705,502.869507 455.819794,506.038849 456.103455,509.146332 
	C456.224640,510.474213 457.324799,511.712799 457.980103,512.991943 
	C458.063202,517.246765 455.816376,518.681885 451.451660,517.995972 
z"/>
<path fill="#364E6C" opacity="1.000000" stroke="none" 
	d="
M397.141357,516.894775 
	C408.800415,516.930542 420.459473,516.966309 433.010986,516.980103 
	C435.595367,516.937744 437.287262,516.917297 438.979156,516.896851 
	C439.538116,517.418274 440.097076,517.939697 440.828491,518.695557 
	C426.541565,518.859497 412.082245,518.789001 397.322266,518.137939 
	C397.061523,517.336487 397.101440,517.115662 397.141357,516.894775 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M444.492371,485.689941 
	C443.661011,482.741699 442.931091,479.473236 442.517853,476.165222 
	C442.342041,474.757843 442.829010,473.267639 443.020996,471.030182 
	C444.023926,468.467377 445.020569,466.689484 446.391663,464.904907 
	C447.514374,464.920380 448.262634,464.942566 449.005981,464.978333 
	C449.001007,464.991943 449.034668,464.991699 449.034668,464.991699 
	C449.057129,466.373413 449.079590,467.755157 449.192383,469.542847 
	C448.006805,470.567139 446.730865,471.185516 444.721985,472.159058 
	C444.721985,476.315979 444.721985,480.834839 444.492371,485.689941 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M453.471832,481.054565 
	C454.095062,481.614105 454.382965,482.110474 454.777893,482.892151 
	C455.154663,484.594818 455.424408,486.012115 455.693359,487.425293 
	C447.560547,489.120880 446.997467,484.228851 448.628876,476.627380 
	C450.363983,478.307220 451.750244,479.649323 453.471832,481.054565 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M458.018921,512.525818 
	C457.324799,511.712799 456.224640,510.474213 456.103455,509.146332 
	C455.819794,506.038849 456.203705,502.869507 455.905975,499.764648 
	C455.783325,498.485809 454.603577,497.308319 453.500183,496.089478 
	C453.064484,495.868011 453.033295,495.641876 452.976471,495.071167 
	C453.711487,494.140350 454.472137,493.554108 455.564209,492.983307 
	C456.605072,494.520966 457.836853,496.013092 457.926636,497.571014 
	C458.204163,502.387085 458.046478,507.228271 458.018921,512.525818 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M397.113892,516.443665 
	C397.101440,517.115662 397.061523,517.336487 396.982758,517.893799 
	C395.996155,517.737854 394.218811,517.225159 394.230255,516.756165 
	C394.334198,512.496399 394.750275,508.244324 395.481873,503.863770 
	C396.287933,507.822540 396.687164,511.907562 397.113892,516.443665 
z"/>
<path fill="#DBECF1" opacity="1.000000" stroke="none" 
	d="
M785.003845,614.571899 
	C783.916321,614.718933 782.393982,614.799133 781.810059,614.097839 
	C778.182922,609.742004 773.603027,611.213989 769.021667,611.404297 
	C761.647400,611.710571 754.242615,611.642944 746.865967,611.349121 
	C744.012085,611.235474 741.254944,610.263794 739.291992,613.781738 
	C736.774658,611.875122 734.547974,609.747864 732.159424,607.311523 
	C731.035583,604.569824 730.073608,602.137207 729.523560,599.353271 
	C744.622803,599.003723 759.310181,599.005554 773.997559,599.007324 
	C777.440979,602.305969 780.884338,605.604553 784.665100,608.955261 
	C785.002563,610.719482 785.002686,612.431580 785.003845,614.571899 
z"/>
<path fill="#C8E3EB" opacity="1.000000" stroke="none" 
	d="
M912.955139,604.644531 
	C912.000610,604.945984 910.832153,605.216675 910.046814,604.786987 
	C903.158691,601.017944 895.531006,601.616333 888.138489,601.058289 
	C880.408508,600.474792 872.645020,600.335815 864.445679,600.004944 
	C860.097046,596.856506 856.138062,593.776672 852.336060,590.513855 
	C851.111023,589.462585 850.299133,587.929932 849.155640,586.316406 
	C862.781067,586.343628 876.549866,586.646240 890.316162,587.034485 
	C891.941956,587.080261 893.554443,587.595398 895.588989,587.946472 
	C899.135315,590.569092 902.265808,593.138184 905.698608,595.853394 
	C907.385742,597.722168 908.752441,599.459778 910.161926,601.161926 
	C911.047791,602.231689 911.997681,603.248413 912.955139,604.644531 
z"/>
<path fill="#FAF9FD" opacity="1.000000" stroke="none" 
	d="
M909.536865,572.002258 
	C910.545349,577.503906 911.090759,583.007874 911.636108,588.511780 
	C911.039001,588.687073 910.441833,588.862305 909.844666,589.037537 
	C905.810608,584.703430 901.776550,580.369324 897.404236,575.973511 
	C897.065979,575.911926 897.032837,575.456848 897.014709,574.855469 
	C896.999878,573.506287 897.003174,572.758362 897.006470,572.010498 
	C901.028931,572.008484 905.051331,572.006531 909.536865,572.002258 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M896.803589,571.774414 
	C897.003174,572.758362 896.999878,573.506287 896.994141,574.628296 
	C896.348389,574.244507 895.705017,573.486572 895.061646,572.728638 
	C895.574707,572.331909 896.087708,571.935120 896.803589,571.774414 
z"/>
<path fill="#C4E0E6" opacity="1.000000" stroke="none" 
	d="
M630.855957,575.305298 
	C633.591675,574.173889 636.032349,573.391968 637.668274,576.764404 
	C637.979248,577.405396 639.915588,577.439575 641.103333,577.444702 
	C650.227417,577.484314 659.351929,577.447510 668.735168,577.717896 
	C667.665039,582.082886 666.335938,586.164368 664.606628,590.629150 
	C659.358521,591.341614 654.512268,591.697388 649.662476,591.994568 
	C639.091003,592.642456 628.681396,590.088196 617.897766,591.678223 
	C607.728516,593.177551 597.164307,592.040222 586.774414,591.970154 
	C584.336304,591.953735 581.466553,590.642883 579.556152,591.542725 
	C575.803467,593.310425 573.120972,592.973938 570.187561,590.218750 
	C569.245728,589.334229 567.427185,589.383057 566.009766,589.004883 
	C564.108398,584.894226 562.207092,580.783508 560.595459,576.335571 
	C561.853882,576.134644 562.824280,576.399048 563.791321,576.387573 
	C572.200256,576.288147 580.608582,576.139099 589.017090,576.004883 
	C589.940552,576.326721 591.436707,576.402222 591.697693,577.009033 
	C593.976624,582.307190 598.561829,580.935547 602.638794,580.988220 
	C606.769531,581.041565 610.936462,580.659546 615.024719,581.079468 
	C621.219727,581.715759 626.280090,579.742249 630.855957,575.305298 
z"/>
<path fill="#B2D5DD" opacity="1.000000" stroke="none" 
	d="
M589.012207,575.549316 
	C580.608582,576.139099 572.200256,576.288147 563.791321,576.387573 
	C562.824280,576.399048 561.853882,576.134644 560.460083,575.993408 
	C559.500305,569.179443 557.260010,567.147949 550.635925,566.945618 
	C548.411743,566.877686 544.901062,568.466248 544.998047,563.452698 
	C545.002991,559.663757 545.010864,556.290894 545.365967,552.495483 
	C546.807983,552.386902 547.902771,552.700806 548.996094,553.425598 
	C549.383789,560.281189 550.091614,560.808350 556.295837,561.074219 
	C559.023193,561.191040 561.719238,562.096191 564.423462,562.671753 
	C566.020447,563.011536 567.633789,563.813354 569.191040,563.705322 
	C575.243042,563.285767 581.269104,562.507507 587.316772,562.007690 
	C595.848022,561.302551 597.615662,560.110413 600.365601,553.017212 
	C601.471191,553.000122 602.207581,552.985413 602.943970,552.970703 
	C602.957947,553.731384 602.971924,554.492126 602.987183,555.633789 
	C602.657715,557.429077 601.850769,558.926819 602.080994,560.243164 
	C602.942078,565.166321 600.106018,566.465210 596.129761,566.960999 
	C588.994019,567.850647 588.996216,567.868286 589.012207,575.549316 
z"/>
<path fill="#50ABC1" opacity="1.000000" stroke="none" 
	d="
M490.115601,456.887543 
	C491.120056,456.119324 492.124481,455.351105 493.433411,454.126678 
	C494.821411,453.438812 495.904938,453.207214 497.236908,453.260651 
	C499.727661,454.015381 502.002777,454.993713 504.205566,454.851471 
	C509.141907,454.532684 514.042297,453.656525 518.971619,453.441223 
	C518.656494,457.584595 518.327026,461.294128 517.529175,465.002167 
	C509.924438,465.000305 502.733093,465.542664 495.669250,464.826782 
	C490.079987,464.260345 484.740173,464.262207 479.121002,464.622681 
	C472.535675,465.045135 465.783905,467.202209 459.061035,465.089203 
	C458.513428,464.917084 457.661591,465.712769 456.606689,466.007935 
	C452.500000,460.981384 448.737762,456.005646 445.151855,450.702454 
	C446.005737,449.959961 446.679535,449.190125 447.361298,449.182953 
	C458.245941,449.068878 469.131653,449.052246 480.232788,449.272156 
	C481.776581,450.606720 483.076935,451.719208 484.439423,452.749542 
	C486.306763,454.161560 488.221283,455.511169 490.115601,456.887543 
z"/>
<path fill="#64B6D0" opacity="1.000000" stroke="none" 
	d="
M496.988464,452.975586 
	C495.904938,453.207214 494.821411,453.438812 493.523071,453.755310 
	C493.159760,452.722626 493.011292,451.605072 492.596741,449.772644 
	C492.291779,442.030701 492.252869,435.003662 492.474182,427.726440 
	C492.521942,425.642517 492.309509,423.808838 492.097107,421.975159 
	C492.137207,421.581879 492.177338,421.188629 492.546967,420.436798 
	C493.322052,419.719452 493.767670,419.360687 494.563690,419.097595 
	C496.566437,422.137726 498.526764,424.957581 499.782806,428.062347 
	C500.990631,431.048035 502.321045,431.616028 504.931519,429.779785 
	C505.674988,429.256836 506.806396,428.980133 507.721252,429.056091 
	C513.266602,429.516449 516.506104,426.432281 519.321533,422.019226 
	C520.447021,422.062622 521.196106,422.100555 522.075134,422.447083 
	C522.316467,423.409546 522.427917,424.063385 522.338013,425.141632 
	C522.016968,428.334137 521.897278,431.102295 521.413696,434.006958 
	C520.351379,438.431732 519.652954,442.719971 518.588623,446.924133 
	C517.519592,444.972076 516.816528,443.104065 516.570679,442.450806 
	C512.519348,441.972809 509.359558,441.539764 506.186798,441.247650 
	C504.207336,441.065399 502.113403,440.709625 500.251465,441.166992 
	C499.014465,441.470917 497.362823,442.958435 497.180450,444.117981 
	C496.725769,447.009094 497.004272,450.015472 496.988464,452.975586 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M490.087036,456.530853 
	C488.221283,455.511169 486.306763,454.161560 484.439423,452.749542 
	C483.076935,451.719208 481.776581,450.606720 480.224792,448.896027 
	C476.992462,443.182831 473.983856,438.104462 470.949371,432.670563 
	C467.711273,426.831757 464.601074,421.284424 461.222870,415.905396 
	C460.244415,414.347412 458.497253,413.272186 457.080444,411.603790 
	C454.271637,406.616150 451.645081,401.890289 448.596161,397.454742 
	C447.730560,396.195435 445.643738,395.107483 444.101227,395.086334 
	C430.667664,394.901886 417.229675,394.912384 403.795868,395.085083 
	C402.543823,395.101166 400.256927,396.620483 400.245758,397.465485 
	C400.186157,401.987030 400.696686,406.516113 401.168945,411.287842 
	C401.879089,411.671844 402.430481,411.810638 403.223938,412.136353 
	C403.717682,413.287140 403.969391,414.251038 403.833374,415.418213 
	C401.416565,415.638031 399.387482,415.654572 397.245483,415.238647 
	C396.976746,408.239166 396.820984,401.672211 396.776184,394.779419 
	C396.914246,393.996521 396.941345,393.539429 396.968414,393.082367 
	C400.698029,393.033356 404.427612,392.984314 408.625031,392.875977 
	C422.065552,392.816620 435.038269,392.816620 448.412750,392.816620 
	C459.887024,409.964569 471.583832,427.382416 483.199097,444.854492 
	C485.639465,448.525391 487.779846,452.395721 490.087036,456.530853 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M492.213959,427.976654 
	C492.252869,435.003662 492.291779,442.030701 492.361938,449.524384 
	C491.215851,449.693359 489.278931,449.712952 488.998169,449.040771 
	C488.002869,446.658081 487.398285,444.065521 487.010437,441.497437 
	C486.842285,440.384125 487.562134,439.136749 487.675598,437.704224 
	C487.541168,434.570129 487.609772,431.682037 488.170166,428.351959 
	C489.845917,427.932190 491.029938,427.954407 492.213959,427.976654 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M442.064392,389.444641 
	C444.040100,389.048981 446.098541,389.021698 448.596680,389.046936 
	C447.036499,392.200531 444.644440,391.514923 442.064392,389.444641 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M433.035980,389.388092 
	C433.743774,389.030945 434.501160,389.002106 435.635864,389.016052 
	C435.037292,389.278046 434.061432,389.497192 433.035980,389.388092 
z"/>
<path fill="#C8E3EB" opacity="1.000000" stroke="none" 
	d="
M773.958862,598.638428 
	C759.310181,599.005554 744.622803,599.003723 729.466492,599.003418 
	C727.120178,597.309387 725.136169,595.716125 723.390869,593.894165 
	C720.860596,591.252747 718.502869,588.445984 716.434753,585.354797 
	C720.964294,586.711304 725.003601,585.759399 729.352722,585.369080 
	C739.827698,584.429016 750.440430,585.022522 761.190735,585.267456 
	C763.922607,587.359009 766.461304,589.180725 769.000000,591.002441 
	C770.640076,593.424805 772.280151,595.847168 773.958862,598.638428 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M768.993530,590.611511 
	C766.461304,589.180725 763.922607,587.359009 761.154236,584.908569 
	C758.882446,580.394226 758.260620,575.415100 753.009766,574.000000 
	C753.009766,571.883118 753.009766,569.766235 753.009766,566.995972 
	C763.800842,566.995972 773.987061,566.995972 784.514465,566.995972 
	C784.319092,569.045349 784.168335,570.626648 784.027100,572.607666 
	C784.205017,574.855347 784.373352,576.703369 784.273315,578.782959 
	C782.609192,578.672180 781.213440,578.329773 779.432800,577.566223 
	C779.052979,575.756836 779.058105,574.368652 779.273071,572.823486 
	C779.474182,572.272949 779.465454,571.879211 779.456787,571.485596 
	C778.977356,571.636658 778.497925,571.787720 777.552856,571.953064 
	C772.721497,571.977478 768.355896,571.987671 763.693604,572.034546 
	C763.577393,572.719727 763.757812,573.368164 764.062500,574.266846 
	C764.464050,575.000061 764.741150,575.482971 765.018311,575.965942 
	C766.341248,580.717468 767.664185,585.468994 768.993530,590.611511 
z"/>
<path fill="#FAF9FD" opacity="1.000000" stroke="none" 
	d="
M765.365112,576.014099 
	C764.741150,575.482971 764.464050,575.000061 764.081909,573.930542 
	C763.981384,572.895203 763.985779,572.446533 763.990234,571.997803 
	C768.355896,571.987671 772.721497,571.977478 777.658325,572.187866 
	C778.507446,572.599121 778.785278,572.789795 779.063171,572.980408 
	C779.058105,574.368652 779.052979,575.756836 779.065552,577.560364 
	C779.083252,581.749817 779.083252,585.524048 779.083252,589.298218 
	C778.620300,589.557617 778.157410,589.817017 777.694458,590.076355 
	C773.700317,585.404968 769.706116,580.733643 765.365112,576.014099 
z"/>
<path fill="#D5D6DB" opacity="1.000000" stroke="none" 
	d="
M784.017517,586.376587 
	C787.800720,586.652649 791.596130,587.302124 795.380615,588.010498 
	C797.204956,588.351929 799.008484,588.804382 800.913208,589.601196 
	C796.179016,589.686523 791.341614,589.485168 786.537476,588.972412 
	C785.632141,588.875854 784.859253,587.538818 784.017517,586.376587 
z"/>
<path fill="#D2E7ED" opacity="1.000000" stroke="none" 
	d="
M566.048096,589.380371 
	C567.427185,589.383057 569.245728,589.334229 570.187561,590.218750 
	C573.120972,592.973938 575.803467,593.310425 579.556152,591.542725 
	C581.466553,590.642883 584.336304,591.953735 586.774414,591.970154 
	C597.164307,592.040222 607.728516,593.177551 617.897766,591.678223 
	C628.681396,590.088196 639.091003,592.642456 649.662476,591.994568 
	C654.512268,591.697388 659.358521,591.341614 664.599487,591.003784 
	C665.169128,591.557373 665.345520,592.119629 665.769043,592.855591 
	C665.874451,598.849854 662.506897,602.923706 658.305298,606.861023 
	C657.243103,606.390137 656.455017,605.196167 655.728699,605.232605 
	C646.019409,605.720032 636.313599,606.313354 626.622009,607.073059 
	C625.293152,607.177246 624.045898,608.321777 622.380432,608.997192 
	C621.868530,608.707825 621.644531,608.413391 621.619202,608.102783 
	C621.039490,601.002625 621.043579,601.002380 613.644348,601.002441 
	C611.173767,601.002502 608.681763,601.206726 606.237854,600.951416 
	C603.109192,600.624512 600.771729,601.477173 599.159912,604.684570 
	C594.550293,605.008118 590.105103,605.031738 585.660400,604.991516 
	C581.893616,604.957458 578.346069,604.839417 575.105042,607.755249 
	C572.970337,609.675781 569.759949,610.372620 569.007996,605.391418 
	C568.030396,599.923462 567.058411,594.839600 566.048096,589.380371 
z"/>
<path fill="#A4D0D5" opacity="1.000000" stroke="none" 
	d="
M368.625610,561.001953 
	C368.999329,561.751953 368.999512,562.501221 368.619171,563.631836 
	C363.427338,565.080200 358.650543,566.368042 353.791077,567.126831 
	C351.115112,567.544739 348.300385,567.021423 345.547913,567.012207 
	C337.304230,566.984680 329.060364,566.998047 320.816589,567.005676 
	C319.985474,567.006470 319.151764,567.046570 318.323730,567.119202 
	C311.248230,567.739380 304.174652,568.381714 297.098602,568.995483 
	C293.807709,569.281006 290.493530,569.831909 287.227997,569.634033 
	C285.768982,569.545715 284.403717,567.909363 282.999695,566.593506 
	C284.177795,562.602478 284.059052,558.152710 289.041443,557.358276 
	C290.916504,561.115540 293.747986,561.517273 297.101349,560.430298 
	C298.660004,559.925049 300.222565,559.132996 301.809540,559.062744 
	C311.896484,558.616516 321.989075,558.266724 332.083191,558.025940 
	C338.060883,557.883362 344.046906,558.093994 350.024933,557.958862 
	C353.173126,557.887756 356.116577,557.181396 356.316223,553.134644 
	C358.739197,555.267883 360.798767,557.322510 362.985870,559.231079 
	C363.851440,559.986389 365.000549,560.416748 366.020569,560.995117 
	C366.764404,560.997131 367.508240,560.999146 368.625610,561.001953 
z"/>
<path fill="#A9B8CB" opacity="1.000000" stroke="none" 
	d="
M288.998779,556.992676 
	C284.059052,558.152710 284.177795,562.602478 282.647980,566.635010 
	C280.976105,568.189453 279.613373,569.271545 278.356873,570.465393 
	C277.190186,571.573975 276.136749,572.801758 275.033417,573.976990 
	C276.660736,569.523804 278.262634,565.060974 279.930695,560.623108 
	C280.440277,559.267334 281.131744,557.979980 281.893372,556.332520 
	C290.039612,550.172791 297.989502,544.299316 307.957336,542.109680 
	C322.454590,538.924927 336.985382,538.236633 350.672882,544.626282 
	C357.330536,547.734192 363.030853,552.948059 365.983154,560.641541 
	C365.000549,560.416748 363.851440,559.986389 362.985870,559.231079 
	C360.798767,557.322510 358.739197,555.267883 356.113770,552.855713 
	C348.500488,549.625793 341.602264,545.422180 334.250885,544.362610 
	C325.741913,543.136230 316.832733,544.643921 308.102051,545.063416 
	C307.218689,545.105896 306.324036,545.755798 305.510345,546.248840 
	C303.052032,547.738403 300.453308,549.071045 298.239624,550.870972 
	C295.342316,553.226807 289.846680,551.631653 288.998779,556.992676 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M573.666626,392.908752 
	C574.073059,398.756561 574.305969,404.540009 574.058655,410.302826 
	C573.868103,414.743378 575.294617,416.906586 580.354980,416.451294 
	C579.020996,422.295715 577.296692,427.753479 575.099365,433.143311 
	C573.995117,432.988647 573.363831,432.901917 572.806946,432.447144 
	C573.300781,430.674652 573.635986,429.238220 574.155701,427.871948 
	C575.614624,424.036133 574.239746,422.311768 570.247620,422.427643 
	C569.191895,421.820282 568.136108,421.212952 567.028320,419.910278 
	C566.917053,415.438202 566.857849,411.661469 566.798645,407.884766 
	C566.798645,407.884766 567.025269,407.972687 567.456543,407.975952 
	C567.679443,406.617981 567.471130,405.256683 567.165405,403.472687 
	C566.929138,401.323273 566.790161,399.596588 566.651184,397.869873 
	C567.355835,396.739105 568.060425,395.608307 568.839905,394.150848 
	C570.372009,393.494629 571.829163,393.165070 573.666626,392.908752 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M545.169678,518.964844 
	C544.081299,518.949646 542.992981,518.934387 541.497070,518.896606 
	C540.378601,517.155579 539.667664,515.437134 539.176758,513.048889 
	C539.770569,509.950134 540.420349,507.523834 540.444153,505.091370 
	C540.470398,502.414062 539.921265,499.731110 539.622803,497.050598 
	C539.540833,496.655243 539.458923,496.259888 539.467285,495.303711 
	C539.397766,494.163208 539.237976,493.583496 539.078186,493.003815 
	C539.024292,489.494232 541.110168,488.232269 544.772400,488.320007 
	C545.850281,488.682098 546.407104,488.802917 547.121155,489.155457 
	C547.866943,488.996582 548.455566,488.606049 549.044250,488.215546 
	C549.421997,488.057922 549.799744,487.900299 550.833008,487.791626 
	C552.499695,487.214386 553.510803,486.588257 554.883057,485.926422 
	C557.062439,484.906952 558.880676,483.923187 560.760010,483.313232 
	C560.193604,486.118927 559.566284,488.550873 558.707764,491.167053 
	C558.393494,491.919495 558.310486,492.487671 558.130615,493.250793 
	C558.033752,493.445679 557.926331,493.867462 557.498047,493.921295 
	C555.357849,494.186615 553.645874,494.398102 551.558105,494.497101 
	C550.150208,494.437775 549.118042,494.490936 547.759888,494.416290 
	C546.741516,495.213348 546.049133,496.138214 545.288635,497.488342 
	C545.258484,499.918427 545.296570,501.923279 545.225708,504.386841 
	C545.134338,509.552002 545.151978,514.258423 545.169678,518.964844 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M560.698975,482.939453 
	C558.880676,483.923187 557.062439,484.906952 554.893921,485.698059 
	C554.465637,485.141144 554.241455,484.977570 553.871155,485.014648 
	C553.971863,483.286896 554.072571,481.559113 554.653564,479.370483 
	C557.356995,474.216644 561.224243,469.567505 561.350830,464.818665 
	C561.482910,459.861908 563.303040,456.138000 565.014893,452.019012 
	C565.012207,452.004089 565.032837,452.018158 565.411011,452.021545 
	C566.832947,449.691284 567.876648,447.357635 569.232971,444.984375 
	C570.572693,444.296631 571.599915,443.648529 572.703613,443.377808 
	C571.180481,449.494141 569.580811,455.233063 567.656189,461.165283 
	C565.120483,468.552185 562.909729,475.745819 560.698975,482.939453 
z"/>
<path fill="#060B10" opacity="1.000000" stroke="none" 
	d="
M572.627136,443.000427 
	C571.599915,443.648529 570.572693,444.296631 568.967590,444.885620 
	C567.720520,444.489227 567.051331,444.151917 566.296814,443.476379 
	C566.024841,442.720917 565.838196,442.303619 565.651611,441.886353 
	C565.750671,441.750732 565.849670,441.615112 566.440125,441.215393 
	C568.865234,438.239258 570.798889,435.527222 572.732605,432.815216 
	C573.363831,432.901917 573.995117,432.988647 575.059265,433.537415 
	C574.537170,436.999786 573.582153,440.000092 572.627136,443.000427 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M565.017578,452.033936 
	C563.303040,456.138000 561.482910,459.861908 561.350830,464.818665 
	C561.224243,469.567505 557.356995,474.216644 554.816895,479.026184 
	C555.918640,474.711395 557.434387,470.308044 558.723328,465.839264 
	C559.647827,462.633881 560.205994,459.324371 561.057800,456.095703 
	C561.380127,454.873810 562.067688,453.748230 563.130981,452.317871 
	C564.121277,452.049316 564.569458,452.041626 565.017578,452.033936 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M572.806946,432.447144 
	C570.798889,435.527222 568.865234,438.239258 566.602173,441.022552 
	C567.160583,438.356384 568.048401,435.618958 569.501892,432.463806 
	C571.960632,429.084137 573.956848,426.122528 570.098022,423.140900 
	C570.098022,423.140900 570.061279,422.759552 570.154419,422.593597 
	C574.239746,422.311768 575.614624,424.036133 574.155701,427.871948 
	C573.635986,429.238220 573.300781,430.674652 572.806946,432.447144 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M539.403687,497.375702 
	C539.921265,499.731110 540.470398,502.414062 540.444153,505.091370 
	C540.420349,507.523834 539.770569,509.950134 539.161377,512.683960 
	C539.012207,507.892792 539.098389,502.796783 539.403687,497.375702 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M566.420105,407.992523 
	C566.857849,411.661469 566.917053,415.438202 566.833740,419.667725 
	C566.474731,416.113800 566.258179,412.107025 566.420105,407.992523 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M565.014893,452.019012 
	C564.569458,452.041626 564.121277,452.049316 563.336853,452.029785 
	C563.652893,448.700409 564.305237,445.398346 565.304565,441.991302 
	C565.838196,442.303619 566.024841,442.720917 566.177246,443.889954 
	C565.772949,447.100525 565.402893,449.559326 565.032837,452.018158 
	C565.032837,452.018158 565.012207,452.004089 565.014893,452.019012 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M570.021606,423.467896 
	C573.956848,426.122528 571.960632,429.084137 569.747681,432.202789 
	C569.600220,429.504639 569.772705,426.649780 570.021606,423.467896 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M566.306274,397.976440 
	C566.790161,399.596588 566.929138,401.323273 567.013062,403.784729 
	C566.845581,404.721497 566.733154,404.923523 566.620728,405.125580 
	C566.400940,402.778046 566.181091,400.430511 566.306274,397.976440 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M553.497681,485.000854 
	C554.241455,484.977570 554.465637,485.141144 554.532776,485.733765 
	C553.510803,486.588257 552.499695,487.214386 551.123596,487.689331 
	C551.547241,486.687775 552.335754,485.837433 553.497681,485.000854 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M566.727356,405.494934 
	C566.733154,404.923523 566.845581,404.721497 567.110352,404.207397 
	C567.471130,405.256683 567.679443,406.617981 567.556519,408.045074 
	C567.094849,407.362030 566.964417,406.613129 566.727356,405.494934 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M538.856506,493.117615 
	C539.237976,493.583496 539.397766,494.163208 539.379639,495.013977 
	C539.012756,494.600464 538.823792,493.915924 538.856506,493.117615 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M546.963989,488.923737 
	C546.407104,488.802917 545.850281,488.682098 545.147583,488.313385 
	C545.564331,487.860565 546.126770,487.655579 546.851318,487.924988 
	C546.996948,488.574127 546.980469,488.748932 546.963989,488.923737 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M547.121155,489.155457 
	C546.980469,488.748932 546.996948,488.574127 547.009644,488.135864 
	C547.474243,487.884033 547.942566,487.895660 548.727600,488.061401 
	C548.455566,488.606049 547.866943,488.996582 547.121155,489.155457 
z"/>
<path fill="#B8DBE1" opacity="1.000000" stroke="none" 
	d="
M461.999084,583.010986 
	C462.990356,580.260376 463.981628,577.509827 464.973389,574.381958 
	C467.517029,572.182373 470.060120,570.360107 473.173035,568.308655 
	C476.717743,568.405701 479.696442,569.023865 482.666412,568.984619 
	C486.430756,568.934814 490.183838,568.111450 493.949219,568.045227 
	C502.626343,567.892517 511.307983,567.995728 519.987793,567.995117 
	C520.325806,571.694275 520.663818,575.393433 521.004578,579.546265 
	C521.004333,580.448242 521.001343,580.896484 520.586853,581.726074 
	C515.300293,582.737488 510.423553,583.910706 505.550446,583.895691 
	C491.032593,583.851074 476.516113,583.339844 461.999084,583.010986 
z"/>
<path fill="#A4D0D5" opacity="1.000000" stroke="none" 
	d="
M519.991455,567.557739 
	C511.307983,567.995728 502.626343,567.892517 493.949219,568.045227 
	C490.183838,568.111450 486.430756,568.934814 482.666412,568.984619 
	C479.696442,569.023865 476.717743,568.405701 473.364746,568.042175 
	C473.219482,565.905090 473.452393,563.805237 474.226440,561.347900 
	C476.843445,559.653748 478.919281,558.317078 481.461365,556.985962 
	C491.360474,556.660156 500.792694,556.306274 510.226715,556.011047 
	C513.147766,555.919678 516.073792,555.987793 519.038513,556.348755 
	C519.390381,558.140808 519.701294,559.566895 520.012207,560.993042 
	C520.006470,563.035461 520.000793,565.077942 519.991455,567.557739 
z"/>
<path fill="#A9B8CB" opacity="1.000000" stroke="none" 
	d="
M520.406860,560.903198 
	C519.701294,559.566895 519.390381,558.140808 519.040039,555.903687 
	C519.000427,554.261353 519.002563,553.430115 518.999939,552.598816 
	C518.977600,545.509583 518.482239,545.017151 511.272705,545.007568 
	C505.121185,544.999451 498.968689,544.937439 492.818451,545.026245 
	C486.098450,545.123352 481.006866,550.343933 480.995117,556.980469 
	C478.919281,558.317078 476.843445,559.653748 474.387482,560.989075 
	C476.560150,555.648071 478.960236,550.225098 481.787567,545.034912 
	C482.392456,543.924561 484.538300,543.111511 485.988068,543.090027 
	C496.789032,542.929688 507.593811,542.962891 518.396240,543.063110 
	C519.529907,543.073608 521.643616,543.930420 521.635681,544.364136 
	C521.535583,549.850464 521.129517,555.331238 520.406860,560.903198 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M386.963867,500.557129 
	C386.300507,501.731689 385.645325,502.505066 384.985229,503.629791 
	C383.272034,505.006683 381.563751,506.032288 379.444946,507.044678 
	C379.034424,507.031494 378.918640,506.920105 378.936035,506.548218 
	C378.887726,505.684113 378.821991,505.191925 378.821228,504.524506 
	C378.886139,504.349274 379.122314,504.059601 379.467285,504.032501 
	C381.974487,500.764435 384.704468,497.763580 386.195129,494.238617 
	C391.173523,482.466248 392.510986,469.914124 392.857880,457.276489 
	C393.102722,448.357666 392.862793,439.426300 392.910339,430.500946 
	C392.942810,424.408081 396.430145,422.051727 402.388611,424.324219 
	C402.331940,425.083282 402.260773,425.512634 402.189606,425.941986 
	C395.535431,426.166504 395.016418,426.666779 395.006622,433.101044 
	C394.990234,443.894836 395.202393,454.693695 394.937317,465.481049 
	C394.673309,476.225739 392.759186,486.658203 387.533508,496.277191 
	C386.948486,497.353973 387.140411,498.852814 386.963867,500.557129 
z"/>
<path fill="#8F8E92" opacity="1.000000" stroke="none" 
	d="
M404.361694,481.189697 
	C404.300964,481.608917 404.240265,482.028168 403.858459,482.715088 
	C403.304565,482.933411 403.071777,482.883972 402.838989,482.834534 
	C402.705841,471.789795 402.572662,460.745087 402.897156,449.291534 
	C403.877960,448.939240 404.401154,448.995758 405.143433,449.126923 
	C405.362488,449.201538 405.792389,449.373077 405.861267,449.631653 
	C406.278412,450.280029 406.626678,450.669830 406.984924,451.523743 
	C406.998993,460.951782 407.003113,469.915710 406.684387,478.952423 
	C405.694916,479.746704 405.028290,480.468201 404.361694,481.189697 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M404.924347,449.052307 
	C404.401154,448.995758 403.877960,448.939240 403.095459,448.852661 
	C402.668091,441.487213 402.500031,434.151794 402.260803,426.379181 
	C402.260773,425.512634 402.331940,425.083282 402.587341,424.115234 
	C402.805084,422.803802 402.838531,422.031006 403.263367,421.197937 
	C404.940796,420.985809 406.226807,420.833984 407.636658,421.062073 
	C407.755035,424.667358 407.749603,427.892731 407.349304,431.099182 
	C406.276794,432.274872 405.072418,433.444519 405.014435,434.668518 
	C404.787689,439.453796 404.923370,444.256256 404.924347,449.052307 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M402.796997,483.208008 
	C403.071777,482.883972 403.304565,482.933411 403.892029,483.032776 
	C405.664032,485.368744 405.386230,487.074982 402.225769,487.915771 
	C402.157959,486.467773 402.456482,485.024597 402.796997,483.208008 
z"/>
<path fill="#89A1D8" opacity="1.000000" stroke="none" 
	d="
M941.696045,425.557312 
	C941.618103,425.930908 941.362671,425.849731 941.002930,425.641113 
	C938.744507,427.933380 936.845886,430.434326 934.947205,432.935242 
	C934.947205,432.935242 934.490356,432.964142 933.918579,433.030212 
	C932.872681,434.029480 932.398621,434.962708 931.682495,436.030060 
	C930.998535,436.496948 930.556580,436.829712 929.761963,437.115265 
	C928.937561,437.050781 928.465820,437.033508 928.030212,436.668457 
	C928.389832,435.209167 928.713318,434.097717 929.334045,432.836761 
	C932.433716,428.477325 935.236206,424.267426 938.365479,419.898071 
	C943.204712,418.192596 945.863098,415.556641 945.074829,410.268555 
	C944.661926,407.498474 944.794617,404.594879 945.060913,401.787659 
	C945.487976,397.284332 943.322754,395.955658 939.200989,395.963287 
	C906.547913,396.023834 873.894592,396.033173 841.241638,395.942047 
	C836.808289,395.929657 834.669495,397.556061 834.987366,402.110931 
	C835.379333,407.729401 835.676514,413.354462 835.988770,419.242035 
	C835.945862,419.684265 835.928711,419.861145 835.543335,420.029175 
	C834.435730,420.021667 833.696350,420.023041 832.951294,419.672119 
	C832.303833,418.550903 831.662109,417.781952 830.963135,416.560730 
	C830.733765,412.377838 830.561646,408.647186 830.468506,404.719421 
	C830.547607,404.522247 830.539490,404.097443 830.836914,404.009064 
	C831.400940,402.975464 831.667480,402.030243 831.959412,400.701599 
	C832.178528,394.420441 837.031921,394.276459 841.458252,393.007446 
	C865.955688,392.963928 889.984070,392.938843 914.012451,392.913696 
	C915.063049,392.944458 916.113708,392.975189 918.023926,393.003784 
	C922.591858,393.035370 926.300232,393.069122 930.317261,393.377991 
	C933.734802,393.767700 936.862976,394.151550 939.947815,393.930542 
	C944.045898,393.636963 946.976074,394.697937 948.027100,399.011078 
	C948.041809,402.033905 948.056458,405.056732 947.780273,408.757172 
	C947.644348,410.951843 947.799255,412.468872 947.954163,413.985931 
	C946.771057,416.026489 945.557800,418.050385 944.414368,420.112915 
	C943.490662,421.779022 942.651001,423.491730 941.696045,425.557312 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M913.929565,392.536163 
	C889.984070,392.938843 865.955688,392.963928 841.446411,392.777313 
	C840.965454,392.565613 840.905212,392.108978 840.905212,392.108978 
	C840.934082,391.643707 840.962891,391.178436 840.997437,390.363831 
	C861.660706,390.009155 882.319214,389.900421 902.974792,390.095825 
	C906.604919,390.130157 910.223022,391.438568 913.929565,392.536163 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M948.273560,398.774475 
	C946.976074,394.697937 944.045898,393.636963 939.947815,393.930542 
	C936.862976,394.151550 933.734802,393.767700 930.335327,393.110779 
	C930.060913,392.391296 930.077026,392.214111 930.093201,392.036926 
	C935.808289,392.264008 941.523376,392.491119 947.624512,392.882690 
	C948.180420,394.877411 948.350220,396.707611 948.273560,398.774475 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M929.799744,391.744629 
	C930.077026,392.214111 930.060913,392.391296 930.026672,392.835663 
	C926.300232,393.069122 922.591858,393.035370 918.417603,392.978149 
	C918.741943,392.053406 919.549194,390.371307 920.319336,390.388184 
	C923.387939,390.455383 926.444946,391.051849 929.799744,391.744629 
z"/>
<path fill="#A9B8CB" opacity="1.000000" stroke="none" 
	d="
M548.997559,553.014648 
	C547.902771,552.700806 546.807983,552.386902 545.372009,552.037292 
	C545.030823,549.219238 545.030823,546.436829 545.030823,542.996094 
	C548.381409,542.996094 551.156738,542.996033 553.932068,542.996155 
	C567.253357,542.996887 580.574585,542.993103 593.895874,542.999939 
	C602.958557,543.004578 602.958557,543.011230 602.954346,552.535156 
	C602.207581,552.985413 601.471191,553.000122 600.361816,552.642639 
	C598.774231,547.299133 595.876038,544.840515 590.491821,544.943298 
	C579.417786,545.154846 568.336853,544.979431 557.258972,545.018188 
	C551.409912,545.038696 549.463989,546.977295 548.997559,553.014648 
z"/>
<path fill="#89A1D8" opacity="1.000000" stroke="none" 
	d="
M701.326660,391.090515 
	C702.245361,391.120087 703.164001,391.149658 704.415771,391.230774 
	C706.309692,391.851166 707.868958,392.910919 709.431702,392.916016 
	C736.975098,393.005585 764.518921,392.966553 792.529907,392.913147 
	C799.015686,392.905396 805.034119,392.939911 811.518188,392.942017 
	C814.142151,392.943481 816.215027,393.080170 815.922974,396.986694 
	C816.069885,401.565308 816.152283,405.292358 815.930420,409.285309 
	C815.520569,412.106445 815.414856,414.661682 815.179932,417.382996 
	C815.050659,417.549072 814.825684,417.904755 814.500977,417.960693 
	C813.128601,419.327850 812.080994,420.639038 810.779785,422.110931 
	C810.019409,422.863312 809.512634,423.454956 808.740845,424.164124 
	C807.775635,424.952423 807.075562,425.623169 806.092651,426.158264 
	C805.527771,425.722137 805.245850,425.421722 805.097961,424.805389 
	C807.492065,420.992615 809.752136,417.495758 812.303101,413.701294 
	C812.733154,409.595673 812.960022,405.788391 812.992065,401.979492 
	C813.048096,395.334839 812.697937,395.016907 805.889404,395.013031 
	C781.417114,394.999084 756.944763,395.002075 732.472473,395.000458 
	C724.315063,394.999939 716.157227,395.051056 708.000305,394.987885 
	C703.836487,394.955658 701.897217,396.883484 701.978333,401.037811 
	C702.043274,404.365479 701.961060,407.696045 701.679932,411.283600 
	C700.094421,413.169739 702.701111,416.949646 698.341919,416.513000 
	C698.143188,409.394775 698.045288,402.732025 697.947449,396.069244 
	C698.040344,395.929901 698.133240,395.790588 698.567139,395.328217 
	C699.594788,394.353394 700.281311,393.701660 701.029175,392.723450 
	C701.169189,391.961456 701.247925,391.526001 701.326660,391.090515 
z"/>
<path fill="#89A1D8" opacity="1.000000" stroke="none" 
	d="
M480.287567,408.935791 
	C480.222107,405.599701 480.156677,402.263580 480.066589,398.473297 
	C481.389709,393.939301 484.225311,392.868103 488.408844,392.947418 
	C501.675659,393.198883 514.951477,392.958771 528.220032,393.160065 
	C529.951294,393.186310 531.660217,394.681152 533.667480,396.218872 
	C533.876099,402.239777 533.796631,407.537537 533.429993,412.904419 
	C532.400024,413.827118 531.657288,414.680695 530.531860,415.622803 
	C527.543030,415.884491 524.936829,416.057678 522.240234,415.876892 
	C523.099731,414.027496 524.049744,412.532135 525.348877,410.975464 
	C526.211731,410.520081 526.734436,410.136963 527.237915,409.730164 
	C530.605957,407.008545 530.600220,403.519867 529.393738,399.826996 
	C528.176392,396.101166 524.974915,397.006958 522.275391,396.993988 
	C512.163940,396.945465 502.052032,396.996521 491.940399,396.964752 
	C488.868805,396.955109 485.523071,396.753174 485.094025,400.836700 
	C484.678284,404.793396 484.308044,409.122498 489.991638,410.415039 
	C490.691223,412.161926 491.344238,413.571075 491.808533,415.063568 
	C491.330017,415.228088 491.140961,415.412537 491.052673,415.700256 
	C488.761719,415.615540 486.467621,415.413086 484.180573,415.474182 
	C480.868683,415.562561 479.804413,413.914917 480.430237,410.357300 
	C480.498016,409.534576 480.392792,409.235168 480.287567,408.935791 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M533.717224,412.835327 
	C533.796631,407.537537 533.876099,402.239777 533.944153,396.475586 
	C535.300842,396.011536 536.668823,396.013855 538.347656,396.081665 
	C538.679260,401.743866 538.700195,407.340576 538.366211,413.493408 
	C537.972839,415.361572 537.934387,416.673645 537.895996,417.985687 
	C536.458069,419.601715 535.020081,421.217712 533.308105,422.395081 
	C532.686584,419.977661 532.339172,417.998901 532.333740,415.912415 
	C533.023010,414.814911 533.370117,413.825134 533.717224,412.835327 
z"/>
<path fill="#3F7798" opacity="1.000000" stroke="none" 
	d="
M671.658081,487.993652 
	C672.767151,489.984222 673.535217,491.929108 674.662720,493.927795 
	C675.452759,494.000763 675.883301,494.019928 677.040527,494.048584 
	C678.845398,494.058350 679.923706,494.058685 681.001953,494.059021 
	C681.890137,494.184418 682.778381,494.309814 684.250244,494.220825 
	C685.877563,495.172424 687.736145,496.285339 687.816223,497.514099 
	C688.084473,501.632507 687.717590,505.792297 687.310913,510.023804 
	C687.221252,511.094360 687.414551,512.077332 687.683289,513.368591 
	C687.740967,514.097961 687.723267,514.518982 687.342407,515.014099 
	C678.317566,516.007019 669.656006,516.925781 661.075256,517.835938 
	C661.279602,518.101685 661.581360,518.494202 661.883118,518.886719 
	C660.539429,518.918884 659.195801,518.951111 657.450989,518.999268 
	C654.695068,518.996521 652.340271,518.977722 649.423096,518.791260 
	C648.564026,518.774231 648.267395,518.924866 647.970703,519.075500 
	C647.970703,519.075500 647.924622,519.503235 647.899780,519.716187 
	C647.169067,519.905762 646.463074,519.882385 645.328918,519.437195 
	C641.445312,514.898926 636.835205,517.873291 632.843262,517.051941 
	C630.559875,517.386780 628.276489,517.721680 625.150269,518.024170 
	C622.553406,517.940247 620.799377,517.888672 618.972168,517.498169 
	C617.892212,516.772705 616.885498,516.386047 615.878784,515.999390 
	C615.807861,510.699738 616.050659,505.371674 615.574219,500.108734 
	C615.131653,495.219147 617.640869,494.206207 621.645874,494.008545 
	C624.816040,493.852081 627.967041,493.309418 631.445679,492.502563 
	C631.764832,492.066833 632.136414,491.998688 632.468262,491.964142 
	C633.859436,491.625763 634.918701,491.321960 636.238892,491.220886 
	C635.985229,493.278259 635.470764,495.132874 634.591125,497.028015 
	C631.339844,498.266174 628.545959,499.839081 625.546631,500.576965 
	C620.557800,501.804260 620.757446,505.379639 621.040588,509.126221 
	C621.373840,513.536011 624.646912,513.079163 627.662598,513.023315 
	C628.823730,513.001770 629.986267,513.043640 631.148010,513.066467 
	C646.878784,513.376160 662.609741,513.820007 678.339905,513.791565 
	C679.895874,513.788757 682.492249,511.086426 682.806702,509.317322 
	C683.809509,503.674561 681.385742,500.343384 675.174377,498.239502 
	C671.903625,497.131561 669.451599,495.697388 668.969238,491.651672 
	C668.637451,489.858948 668.339905,488.432800 668.042419,487.006683 
	C669.133972,487.320435 670.225525,487.634186 671.658081,487.993652 
z"/>
<path fill="#498AAB" opacity="1.000000" stroke="none" 
	d="
M635.978027,491.018127 
	C634.918701,491.321960 633.859436,491.625763 632.417419,491.590454 
	C631.291016,489.499084 630.547302,487.746918 629.803589,485.994751 
	C628.763733,484.736389 627.723816,483.478027 626.064514,481.845337 
	C614.307251,481.326904 603.169495,481.182831 592.048706,480.688812 
	C592.378723,479.228790 592.691711,478.118652 593.473145,477.008301 
	C603.769531,477.006287 613.601135,476.856781 623.423401,477.094727 
	C626.295166,477.164276 629.140259,478.336700 632.035461,479.360718 
	C634.665222,483.038666 637.065674,486.425751 635.978027,491.018127 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M608.042725,515.249634 
	C606.336731,516.141174 604.543152,516.686707 602.379578,516.793396 
	C602.434143,509.052002 602.858765,501.749481 603.304626,494.080994 
	C599.193909,494.080994 596.239624,494.169342 593.292480,494.062012 
	C587.684326,493.857788 590.793274,489.507965 590.030701,486.665070 
	C590.352417,485.533661 590.658569,484.740540 591.208557,483.987885 
	C591.452393,484.028381 591.944153,484.078033 591.977356,484.431946 
	C593.690735,485.595337 595.370911,486.404816 596.915039,487.508698 
	C596.958862,488.420898 597.046265,489.526642 597.332947,489.580933 
	C600.176453,490.119659 603.052979,490.484436 605.920105,490.898407 
	C606.483826,494.089996 607.112488,497.272308 607.568970,500.479156 
	C607.694702,501.362122 607.497437,502.405151 607.122742,503.226013 
	C605.264587,507.296509 605.258789,511.182709 608.042725,515.249634 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M619.877258,482.933899 
	C620.894165,484.562775 621.911133,486.191650 623.886902,489.356232 
	C618.291565,489.429993 613.920898,489.487610 609.150513,489.013184 
	C608.488037,488.036194 608.225220,487.591187 608.103638,486.878876 
	C608.922546,486.273560 609.600220,485.935486 610.210022,485.631317 
	C609.735596,484.800629 609.281921,484.006165 609.291321,483.142639 
	C613.128662,483.027008 616.502991,482.980438 619.877258,482.933899 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M620.264526,482.896729 
	C616.502991,482.980438 613.128662,483.027008 608.843384,483.085785 
	C602.603027,483.424652 597.273560,483.751343 591.944153,484.078033 
	C591.944153,484.078033 591.452393,484.028381 591.242432,483.676270 
	C591.364441,482.558411 591.696472,481.792694 592.022583,481.019562 
	C592.016663,481.012115 592.031677,481.038727 592.031677,481.038727 
	C603.169495,481.182831 614.307251,481.326904 625.726685,481.787292 
	C624.222778,482.355560 622.437256,482.607574 620.264526,482.896729 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M607.962402,487.146179 
	C608.225220,487.591187 608.488037,488.036194 608.869507,488.713196 
	C608.082275,489.420197 607.176453,489.895172 606.095337,490.634277 
	C603.052979,490.484436 600.176453,490.119659 597.332947,489.580933 
	C597.046265,489.526642 596.958862,488.420898 597.366089,487.453949 
	C601.289551,487.118591 604.625977,487.132385 607.962402,487.146179 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M614.348755,521.984253 
	C613.342590,521.314026 612.706970,520.625671 611.996826,519.572205 
	C612.908325,518.989258 613.894165,518.771301 615.619141,518.687988 
	C617.225159,518.885803 618.092041,518.948914 619.187256,519.125610 
	C619.612122,519.510254 619.808594,519.781311 620.005066,520.052429 
	C618.243103,520.690308 616.481140,521.328186 614.348755,521.984253 
z"/>
<path fill="#364E6C" opacity="1.000000" stroke="none" 
	d="
M629.593750,486.228821 
	C630.547302,487.746918 631.291016,489.499084 632.085571,491.625000 
	C632.136414,491.998688 631.764832,492.066833 631.580627,492.093292 
	C630.725586,490.234131 630.054749,488.348480 629.593750,486.228821 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M618.958984,519.012024 
	C618.092041,518.948914 617.225159,518.885803 615.939697,518.670166 
	C615.603455,517.889709 615.685669,517.261780 615.823364,516.316650 
	C616.885498,516.386047 617.892212,516.772705 618.947388,517.792725 
	C618.983582,518.621399 618.971252,518.816711 618.958984,519.012024 
z"/>
<path fill="#A9B8CB" opacity="1.000000" stroke="none" 
	d="
M452.029541,550.581238 
	C452.041534,551.463928 452.035034,551.905518 451.744507,552.931885 
	C450.646027,555.685059 449.831543,557.853516 449.011932,559.590088 
	C449.002106,557.434753 448.997406,555.711243 448.995026,553.582581 
	C448.654144,548.145813 446.421753,546.046631 441.054626,546.016235 
	C430.640900,545.957275 420.226227,545.945007 409.812653,546.016052 
	C400.993896,546.076294 400.994324,546.140320 400.954163,555.370117 
	C400.612213,557.500000 400.313629,559.250000 399.692139,560.978638 
	C398.570770,560.641357 397.772278,560.325562 396.973785,560.009766 
	C396.973999,558.298584 396.974243,556.587402 396.960297,554.449951 
	C397.117065,542.990234 397.117065,542.990295 408.401184,542.990295 
	C420.549591,542.990295 432.698029,542.987671 444.846436,542.991272 
	C451.958618,542.993347 451.958618,542.997742 452.029541,550.581238 
z"/>
<path fill="#031829" opacity="1.000000" stroke="none" 
	d="
M400.943817,125.047333 
	C403.238312,130.980972 405.518372,136.920258 407.830933,142.846848 
	C410.874115,150.645920 413.942871,158.434998 416.988892,166.624695 
	C415.051392,163.748047 412.876465,160.593964 411.247833,157.179306 
	C406.786438,147.825302 402.450134,138.407578 398.289795,128.916245 
	C396.862579,125.660210 396.102997,122.111519 395.075134,118.291351 
	C395.529877,117.918663 395.947906,117.951157 396.686249,118.347198 
	C397.661804,119.479904 398.317017,120.249077 398.972198,121.018250 
	C398.972198,121.018250 398.998779,121.001221 398.991180,121.385864 
	C399.636993,122.862793 400.290405,123.955063 400.943817,125.047333 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M396.529022,393.032837 
	C396.941345,393.539429 396.914246,393.996521 396.397827,394.754395 
	C394.684387,396.277802 392.527100,397.412018 392.401459,398.738617 
	C391.919434,403.829681 391.723480,409.012634 392.144043,414.101532 
	C392.425079,417.502319 394.728149,418.062347 397.358429,415.671112 
	C399.387482,415.654572 401.416565,415.638031 404.139221,415.533813 
	C405.214996,415.636505 405.597168,415.826874 405.721161,416.299561 
	C403.829895,416.711456 402.086212,416.543427 400.609070,417.092529 
	C399.837646,417.379303 399.535187,418.927673 398.627136,419.991272 
	C394.779663,419.884460 391.803467,420.629730 389.799622,424.090027 
	C387.889313,424.018555 386.278259,423.632721 384.998871,424.119141 
	C384.073761,424.470856 383.589050,425.980988 382.921021,427.406128 
	C382.700684,432.440826 382.467285,437.043854 381.821106,441.754761 
	C380.000458,441.930054 378.592529,441.997498 377.116943,441.596527 
	C376.985107,435.418182 376.920929,429.708252 376.933990,423.559479 
	C377.149841,420.506989 377.288452,417.893311 377.699585,415.300751 
	C378.325195,415.240784 378.678284,415.159698 379.455322,415.156647 
	C381.604370,415.168335 383.329437,415.101959 385.054504,415.035583 
	C385.979584,413.168427 387.378784,411.382294 387.715179,409.414459 
	C388.183685,406.673889 387.826080,403.796753 387.876251,400.979034 
	C388.016235,393.114014 388.399231,392.752380 396.529022,393.032837 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M385.050415,414.610291 
	C383.329437,415.101959 381.604370,415.168335 379.448822,414.764038 
	C384.577576,411.214569 384.052063,405.937378 384.067078,400.744995 
	C384.078522,396.777954 382.466217,394.937347 378.414246,394.962524 
	C365.918060,395.040100 353.421021,394.972046 340.924500,395.015961 
	C339.810883,395.019867 338.363647,395.055328 337.641998,395.707520 
	C333.897858,399.091248 336.124176,409.682678 340.984283,412.338745 
	C341.341583,413.094666 341.670166,413.555878 342.007141,414.294983 
	C342.033752,414.943024 342.051971,415.313171 341.680725,415.730499 
	C337.839722,415.598328 334.942169,414.553955 333.591217,410.509430 
	C333.340149,408.395874 333.201752,406.691528 333.123291,404.548767 
	C333.044891,401.765167 332.906525,399.419952 332.881012,396.754761 
	C333.069214,395.988892 333.144562,395.543060 333.219910,395.097198 
	C336.356873,394.394379 339.483246,393.146667 342.632721,393.085358 
	C354.719696,392.850037 366.814178,392.983673 378.905792,393.003052 
	C385.703644,393.013947 386.141815,393.340393 385.987518,399.907471 
	C385.875610,404.671387 385.374146,409.426117 385.050415,414.610291 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M333.082092,394.742126 
	C333.144562,395.543060 333.069214,395.988892 332.498474,396.748047 
	C329.666046,397.035370 327.329010,397.009399 325.091034,396.746460 
	C325.466644,396.084412 325.743195,395.659302 326.019714,395.234192 
	C326.923889,394.686493 327.828003,394.138763 328.870117,393.291687 
	C330.320129,393.457214 331.632202,393.922119 333.082092,394.742126 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M277.870667,502.132202 
	C275.946106,501.071167 274.021545,500.010162 271.316620,498.518890 
	C271.046967,503.694275 270.819336,508.063324 269.977905,512.564697 
	C268.093994,512.429199 266.774933,511.798370 265.563110,511.962036 
	C262.042236,512.437561 258.564392,513.231506 255.055176,513.451660 
	C254.745865,509.083588 254.566223,505.151184 254.121658,501.248962 
	C253.730103,497.811859 251.825363,498.376160 249.515900,499.643799 
	C249.174500,497.952240 249.071289,496.511475 249.388641,495.042450 
	C251.211227,495.008911 252.613251,495.003693 254.269791,494.998962 
	C254.693970,495.001160 254.863647,495.002869 255.094437,495.380737 
	C259.156128,497.623840 262.758728,497.030792 266.288483,493.869690 
	C267.720306,493.487091 268.831726,493.242310 270.250061,493.144318 
	C272.994873,496.238129 275.432770,499.185181 277.870667,502.132202 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M280.879425,503.733887 
	C280.879425,503.733887 280.840942,503.604767 280.924622,503.139832 
	C281.036041,502.103668 281.063751,501.532410 281.428162,501.011963 
	C287.514282,505.035858 293.263733,509.008972 299.340149,513.129944 
	C301.949768,514.205811 304.154663,515.507019 306.527649,516.001526 
	C322.399048,519.308899 338.032135,519.294373 353.380432,512.010437 
	C355.583923,510.752258 357.565369,509.661255 359.231812,508.208832 
	C362.246033,505.581726 365.072266,502.738983 367.973083,500.351624 
	C366.956482,502.329712 366.272766,504.346802 364.873901,505.484924 
	C358.950928,510.303894 352.815430,514.861694 347.418854,519.006958 
	C347.472870,519.016968 348.752960,519.253967 350.033051,519.491028 
	C346.347321,520.329163 342.631256,521.987488 338.981506,521.852539 
	C327.808746,521.439392 316.661469,520.337463 305.165710,518.860474 
	C298.923370,515.813782 293.019623,513.410156 287.115845,511.006470 
	C285.848175,509.272034 284.580505,507.537598 283.006897,505.202667 
	C282.093781,504.312744 281.486603,504.023315 280.879425,503.733887 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M350.364990,519.393860 
	C348.752960,519.253967 347.472870,519.016968 347.418854,519.006958 
	C352.815430,514.861694 358.950928,510.303894 364.873901,505.484924 
	C366.272766,504.346802 366.956482,502.329712 368.238708,500.208740 
	C368.744110,498.953217 368.979950,498.205841 369.505920,497.324921 
	C370.527893,496.456329 371.259735,495.721283 372.374878,494.999084 
	C374.172455,494.711548 375.586731,494.411224 377.421936,494.088257 
	C379.172882,494.915436 380.502899,495.765289 381.770935,496.886749 
	C381.558838,497.497345 381.408722,497.836273 380.893372,498.139038 
	C378.687134,501.070312 376.846069,504.037750 374.844116,507.278137 
	C373.784424,508.690857 372.885712,509.830658 371.987000,510.970520 
	C371.642670,510.976227 371.329315,511.077728 370.450623,511.227386 
	C369.231232,511.773804 368.608246,512.367859 367.689026,513.133789 
	C365.582275,514.530762 363.771820,515.755798 361.961365,516.980835 
	C360.197510,517.583618 358.433624,518.186279 356.368774,518.958252 
	C354.277496,519.183899 352.487213,519.240295 350.364990,519.393860 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M281.091461,500.961151 
	C281.063751,501.532410 281.036041,502.103668 280.982178,503.104797 
	C280.130524,503.167664 279.305084,502.800659 278.175140,502.282959 
	C275.432770,499.185181 272.994873,496.238129 270.268738,492.683838 
	C269.967743,488.377502 269.954987,484.678467 270.295166,480.978607 
	C271.118011,480.969666 271.587891,480.961517 272.189819,481.266815 
	C272.915710,483.040527 273.509552,484.500793 274.109039,486.333313 
	C276.440277,491.457428 278.765869,496.209290 281.091461,500.961151 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M255.069672,513.900513 
	C258.564392,513.231506 262.042236,512.437561 265.563110,511.962036 
	C266.774933,511.798370 268.093994,512.429199 269.669739,512.843262 
	C269.948273,513.441406 269.921112,513.893372 269.955688,514.679321 
	C270.017395,515.013428 270.001709,515.004211 269.649933,514.974731 
	C267.866150,515.639404 266.434113,516.333557 265.002106,517.027710 
	C261.967743,517.432983 258.933380,517.838196 255.467834,517.779175 
	C255.047684,516.176819 255.058685,515.038696 255.069672,513.900513 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M287.113586,511.355042 
	C293.019623,513.410156 298.923370,515.813782 304.889862,518.561646 
	C297.685791,519.774231 291.841614,517.118591 287.113586,511.355042 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M280.984283,504.075378 
	C281.486603,504.023315 282.093781,504.312744 282.843353,504.914459 
	C282.353546,504.956757 281.721344,504.686798 280.984283,504.075378 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M521.777588,433.870422 
	C521.897278,431.102295 522.016968,428.334137 522.659302,425.202820 
	C524.496704,424.832062 525.811523,424.824432 527.126343,424.816803 
	C527.272095,426.179230 527.417786,427.541626 527.210083,429.504578 
	C525.145630,433.273682 523.890686,436.414551 527.413513,439.264984 
	C527.438599,447.582642 527.463684,455.900299 526.932495,464.585938 
	C525.927185,462.727112 525.625244,460.457306 524.990295,458.284790 
	C524.274902,455.836884 523.259583,453.476654 522.256104,450.617035 
	C522.017578,444.727325 521.897583,439.298859 521.777588,433.870422 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M522.374756,451.078308 
	C523.259583,453.476654 524.274902,455.836884 524.990295,458.284790 
	C525.625244,460.457306 525.927185,462.727112 526.720276,465.009613 
	C527.164917,467.741180 527.265381,470.417114 527.208862,473.513794 
	C525.678894,474.278870 524.305786,474.623199 522.531616,474.517761 
	C522.211853,466.404755 522.293274,458.741547 522.374756,451.078308 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M527.413330,424.531433 
	C525.811523,424.824432 524.496704,424.832062 522.860596,424.778442 
	C522.427917,424.063385 522.316467,423.409546 522.213257,422.033966 
	C522.249817,419.616455 522.278198,417.920654 522.310791,416.205322 
	C522.315125,416.185760 522.330688,416.230865 522.330688,416.230896 
	C524.936829,416.057678 527.543030,415.884491 530.780334,415.790222 
	C531.604919,415.919464 531.798279,415.969818 531.991699,416.020142 
	C532.339172,417.998901 532.686584,419.977661 532.966370,422.300415 
	C531.165894,423.178284 529.433105,423.712189 527.413330,424.531433 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M527.561768,501.817200 
	C523.718445,498.019379 525.071777,493.575806 527.172607,488.930847 
	C527.563110,492.951660 527.655762,497.156769 527.561768,501.817200 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M527.595947,438.814728 
	C523.890686,436.414551 525.145630,433.273682 527.130798,429.920837 
	C527.529358,432.612549 527.653809,435.488525 527.595947,438.814728 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M538.231201,417.796051 
	C537.934387,416.673645 537.972839,415.361572 538.323730,413.949249 
	C538.612915,415.101440 538.589661,416.353943 538.231201,417.796051 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M527.585815,478.847504 
	C527.235840,478.181946 527.079712,477.095459 527.172791,475.871460 
	C527.541199,476.631470 527.660400,477.529022 527.585815,478.847504 
z"/>
<path fill="#C8E3EB" opacity="1.000000" stroke="none" 
	d="
M461.942688,583.354126 
	C476.516113,583.339844 491.032593,583.851074 505.550446,583.895691 
	C510.423553,583.910706 515.300293,582.737488 520.582214,582.062256 
	C520.658447,588.044922 520.327881,594.072693 519.996216,600.550293 
	C510.475800,600.999329 500.954346,601.119812 491.437866,600.955627 
	C485.017487,600.844849 478.602509,600.360413 472.188049,599.991577 
	C466.475494,599.663086 460.428833,603.144958 455.033691,598.406555 
	C454.510498,597.947021 452.865356,598.765076 451.368652,598.995056 
	C450.997955,597.285034 450.999603,595.570068 451.001831,593.426331 
	C451.998474,592.617615 453.236115,592.491211 453.947754,591.812927 
	C456.685272,589.203552 459.254333,586.417419 461.942688,583.354126 
z"/>
<path fill="#C4E0E6" opacity="1.000000" stroke="none" 
	d="
M435.997375,597.565063 
	C427.933289,598.329102 419.869049,598.663025 411.400574,598.998474 
	C409.700836,596.562195 408.405334,594.124390 407.056580,591.342285 
	C407.668457,588.462036 408.500610,585.954529 408.941162,583.380005 
	C409.243286,581.614624 409.000824,579.755920 409.433472,577.471924 
	C411.681305,577.103088 414.186493,576.471191 415.195923,577.423950 
	C419.893250,581.857605 424.852722,579.315552 429.750458,578.560364 
	C432.183258,578.185181 434.573456,577.533813 436.982910,577.007324 
	C436.988983,579.378845 436.995026,581.750305 437.006042,584.567017 
	C436.673065,589.053101 436.335144,593.094055 435.997375,597.565063 
z"/>
<path fill="#B8DBE1" opacity="1.000000" stroke="none" 
	d="
M752.619385,574.006226 
	C758.260620,575.415100 758.882446,580.394226 760.961060,584.638672 
	C750.440430,585.022522 739.827698,584.429016 729.352722,585.369080 
	C725.003601,585.759399 720.964294,586.711304 716.400269,585.003540 
	C715.253540,585.003906 714.504639,585.000488 713.381531,584.994873 
	C710.953552,581.181702 709.610535,576.559021 703.459534,576.977539 
	C703.065491,576.978027 703.040161,576.506531 703.037354,576.270813 
	C703.298645,575.833984 703.562683,575.632874 703.948792,575.210266 
	C711.625427,575.658325 719.173828,576.774048 726.736328,576.879456 
	C735.297791,576.998779 744.001099,577.315369 752.619385,574.006226 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M696.996277,563.407898 
	C697.995117,564.740295 698.992737,566.492737 699.995117,568.625061 
	C695.779114,569.147888 697.324463,565.943787 696.996277,563.407898 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M696.528015,444.446716 
	C695.201233,444.144440 693.874451,443.842163 692.275391,442.947937 
	C691.549683,441.297394 691.096313,440.238770 690.642944,439.180145 
	C690.088318,439.816498 689.533691,440.452850 688.600952,441.090820 
	C687.732544,441.205109 687.242188,441.317780 686.410095,441.316864 
	C685.126404,441.067932 684.184448,440.932587 683.242554,440.797211 
	C683.242554,440.797211 683.109253,440.916718 683.221558,440.554779 
	C683.314514,439.802795 683.295166,439.412720 683.503906,438.679260 
	C683.809692,435.700012 684.066772,433.055023 683.932800,430.429993 
	C683.447693,420.926697 684.593750,420.236328 695.686523,421.326294 
	C696.490967,421.405365 697.271912,421.724121 698.117798,422.205139 
	C698.147766,422.663055 698.123718,422.847260 697.715088,423.228271 
	C697.221313,428.044006 697.006592,432.665131 697.101868,437.279846 
	C697.118652,438.095306 698.429016,438.884094 699.543579,439.793396 
	C701.311890,439.771454 702.678589,439.641388 704.487427,439.660614 
	C710.938110,439.728699 716.946533,439.647491 723.339600,439.734375 
	C725.109802,439.856659 726.495483,439.810913 728.164185,439.819763 
	C728.735046,439.627625 729.022888,439.380920 729.675476,439.077942 
	C733.201782,433.601135 737.969849,433.877441 743.108948,435.354065 
	C743.251526,436.425323 743.395813,437.509338 743.471069,438.741730 
	C743.401978,438.890106 743.135071,439.079620 742.776123,439.072388 
	C741.577209,440.320740 740.737305,441.576263 739.853638,443.103394 
	C739.837585,443.556305 739.865173,443.737640 739.681091,443.937561 
	C739.469360,443.956177 739.052673,444.040894 738.721252,444.014069 
	C737.979370,444.673035 737.568909,445.358856 737.049561,446.375305 
	C735.099609,448.623230 733.258667,450.540527 730.986938,452.310791 
	C729.382935,452.095520 728.209595,452.027344 727.016113,451.587952 
	C724.818176,447.167633 723.277405,448.662720 721.695007,451.816895 
	C721.464783,451.834412 721.003662,451.860840 720.816528,451.630798 
	C719.738770,450.936279 718.848083,450.471771 718.020203,449.637085 
	C718.305725,447.729950 720.986572,445.350433 716.356934,445.306641 
	C715.244751,445.425842 714.514465,445.584473 713.463257,445.643005 
	C712.094727,445.701019 711.047119,445.859100 709.999512,446.017212 
	C708.585083,445.875549 707.170654,445.733917 705.176941,445.134644 
	C701.907776,444.600281 699.217896,444.523499 696.528015,444.446716 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M704.636597,462.819733 
	C704.444336,462.768036 704.252014,462.716309 704.060913,462.627380 
	C704.500610,462.191345 704.939087,461.792419 705.667786,461.402466 
	C706.324402,461.285034 706.690796,461.158630 707.385864,460.995239 
	C708.117432,460.273590 708.520325,459.588959 708.868042,458.790405 
	C708.812866,458.676514 708.745483,458.432556 709.041016,458.601685 
	C709.781189,458.713440 710.225830,458.656036 711.047852,458.704895 
	C712.799622,458.098755 714.727661,457.734253 715.437622,456.604401 
	C717.115051,453.934875 718.947754,453.522339 721.660034,454.746643 
	C722.983643,455.344086 724.511108,455.489899 726.263794,456.083038 
	C726.952637,456.713806 727.323975,457.101685 727.562012,457.810181 
	C724.645264,461.489868 721.861816,464.848877 718.804138,468.219421 
	C718.074463,468.535217 717.619019,468.839539 716.730713,469.109497 
	C712.518616,468.743195 708.739502,468.411285 704.949585,467.734314 
	C704.838135,465.866028 704.737366,464.342896 704.636597,462.819733 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M704.184204,462.868439 
	C704.737366,464.342896 704.838135,465.866028 704.951172,468.028656 
	C704.959045,469.062683 704.954590,469.457214 704.812378,470.116638 
	C704.116455,470.926086 703.558228,471.470673 702.708252,472.138184 
	C701.592224,473.448303 700.767883,474.635498 699.560730,475.848602 
	C696.712769,476.850708 694.247559,477.826935 691.747070,478.472107 
	C692.487122,477.109131 693.262512,476.077209 694.392578,475.035583 
	C698.557861,472.619293 702.523560,470.319672 701.798828,464.789062 
	C702.443115,464.165070 703.087463,463.541107 704.184204,462.868439 
z"/>
<path fill="#BFC0BD" opacity="1.000000" stroke="none" 
	d="
M709.968018,446.427856 
	C711.047119,445.859100 712.094727,445.701019 713.532471,445.988708 
	C713.007996,448.746277 712.139587,451.077759 711.163574,453.363342 
	C710.429077,455.083618 709.556580,456.744995 708.745483,458.432556 
	C708.745483,458.432556 708.812866,458.676514 708.558594,458.707397 
	C707.891785,458.845367 707.479187,458.952393 706.753906,459.030975 
	C706.029968,459.069672 705.618835,459.136871 705.110535,459.107239 
	C705.013367,459.010376 704.820374,458.815369 704.820312,458.815369 
	C705.645813,457.285858 706.604370,455.811462 707.265686,454.213959 
	C708.264832,451.800568 709.056702,449.301331 709.968018,446.427856 
z"/>
<path fill="#BFC0BD" opacity="1.000000" stroke="none" 
	d="
M694.037903,475.045319 
	C693.262512,476.077209 692.487122,477.109131 691.586975,478.482025 
	C691.154114,479.027832 690.845947,479.232666 690.185974,479.386139 
	C688.709290,480.242981 687.584534,481.151123 686.459717,482.059296 
	C687.132690,480.395905 687.526367,478.535950 688.532410,477.107056 
	C691.241272,473.259460 694.211487,469.595795 697.122070,465.512268 
	C697.859802,465.094360 698.553345,465.023254 699.613098,465.315308 
	C697.998840,468.800720 696.018372,471.923004 694.037903,475.045319 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M694.392578,475.035583 
	C696.018372,471.923004 697.998840,468.800720 699.964050,465.318481 
	C700.358765,464.871277 700.768677,464.784058 701.488770,464.742920 
	C702.523560,470.319672 698.557861,472.619293 694.392578,475.035583 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M696.607788,444.677917 
	C699.217896,444.523499 701.907776,444.600281 704.832153,445.038239 
	C702.673340,445.521027 700.280029,445.642639 697.467163,445.774231 
	C697.076538,445.431244 696.956604,445.139557 696.607788,444.677917 
z"/>
<path fill="#BFC0BD" opacity="1.000000" stroke="none" 
	d="
M705.377563,461.393524 
	C704.939087,461.792419 704.500610,462.191345 703.763428,462.599854 
	C703.676758,461.443207 703.888733,460.276978 704.460571,458.963074 
	C704.820374,458.815369 705.013367,459.010376 705.057617,459.475098 
	C705.193787,460.424377 705.285645,460.908966 705.377563,461.393524 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M208.976196,392.017639 
	C213.486664,391.344513 217.979126,390.295990 222.511444,390.078705 
	C231.135025,389.665314 239.781387,389.727020 248.712799,389.293518 
	C253.005798,391.156250 256.981323,389.130951 260.981018,389.070953 
	C279.029724,388.800110 297.084808,388.944794 315.137573,388.962280 
	C318.218842,388.965271 321.215424,389.221497 321.711426,393.463776 
	C319.620331,394.632660 317.894440,395.384888 316.101685,395.712555 
	C314.332764,394.511383 312.636597,393.075531 310.927704,393.060303 
	C294.015991,392.909393 277.102203,392.993805 259.754150,392.966187 
	C257.543762,392.766846 255.684006,392.219116 254.017471,392.575317 
	C252.504898,392.898621 251.202652,394.206024 249.432480,395.011719 
	C247.243759,394.297852 245.450867,393.162964 243.613358,393.085449 
	C232.073151,392.598572 220.523041,392.347015 208.976196,392.017639 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M316.168549,396.137146 
	C317.894440,395.384888 319.620331,394.632660 321.724243,393.840637 
	C322.102325,393.800842 322.398254,394.088196 322.619751,394.520905 
	C322.930634,395.602173 323.020020,396.250763 322.752747,396.909119 
	C321.377289,396.861877 320.358490,396.804840 319.339722,396.747833 
	C319.920441,397.855072 320.501190,398.962341 321.244202,400.407379 
	C321.885956,404.876495 322.365417,409.007782 322.464813,413.108337 
	C320.025543,413.694366 317.966339,414.311127 315.939209,414.469360 
	C316.037018,408.052948 316.102783,402.095032 316.168549,396.137146 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M323.109406,396.899323 
	C323.020020,396.250763 322.930634,395.602173 322.791870,394.634460 
	C323.705597,394.407623 324.668671,394.499939 325.825745,394.913208 
	C325.743195,395.659302 325.466644,396.084412 324.777161,396.727539 
	C323.945984,396.930115 323.527679,396.914703 323.109406,396.899323 
z"/>
<path fill="#456D8D" opacity="1.000000" stroke="none" 
	d="
M590.015137,487.003326 
	C590.793274,489.507965 587.684326,493.857788 593.292480,494.062012 
	C596.239624,494.169342 599.193909,494.080994 603.304626,494.080994 
	C602.858765,501.749481 602.434143,509.052002 601.969238,516.808472 
	C601.173157,517.522827 600.422119,517.996094 599.661133,518.012634 
	C581.795288,518.399780 563.928406,518.737610 545.615723,519.024658 
	C545.151978,514.258423 545.134338,509.552002 545.560669,504.390198 
	C547.978516,502.292786 549.952393,500.650696 551.946167,499.261475 
	C551.973816,499.683228 551.981506,499.852173 551.804077,500.355347 
	C546.948853,508.806366 549.712769,514.017334 558.701843,514.023010 
	C569.850586,514.029968 581.000854,513.920532 592.147278,514.080750 
	C595.835327,514.133789 597.580078,512.517212 598.483276,509.158691 
	C600.084961,503.202942 596.025391,498.381805 588.004517,496.616394 
	C587.643860,494.497833 587.332092,492.757629 587.280029,490.832794 
	C588.364868,489.433228 589.190002,488.218292 590.015137,487.003326 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M188.219437,518.224976 
	C186.863617,518.272888 185.858810,518.207031 184.491272,518.277039 
	C182.631897,518.609985 181.135284,518.807129 179.259323,518.586182 
	C179.164322,516.560913 179.351822,514.929321 179.749924,513.350769 
	C181.384232,506.870514 183.082626,500.406464 184.879364,493.566406 
	C186.007935,489.788391 187.014618,486.380402 188.430496,482.988953 
	C190.171173,483.181854 191.502640,483.358185 192.834122,483.534546 
	C192.486877,483.894989 192.139633,484.255432 191.792389,484.615845 
	C192.508224,486.384491 192.965302,489.473419 193.988480,489.673950 
	C197.042480,490.272491 197.888474,488.014069 198.222107,485.074463 
	C198.359787,483.861328 200.774414,482.906647 202.364609,482.183838 
	C202.688202,483.352631 202.868988,484.169220 202.902466,484.991791 
	C203.233459,493.126312 203.179886,492.956299 195.460663,495.071320 
	C194.060867,495.454865 192.301239,497.860443 192.280731,499.354156 
	C192.200119,505.224091 192.748199,511.102661 192.711792,516.990845 
	C191.587982,517.400696 190.815231,517.797852 190.042480,518.195007 
	C189.551819,518.167114 189.061142,518.139221 188.219437,518.224976 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M255.055176,513.451721 
	C255.058685,515.038696 255.047684,516.176819 255.053711,517.740112 
	C255.070740,518.165222 254.919617,518.473633 254.809753,518.605652 
	C253.385147,518.866577 252.070389,518.995483 250.253616,518.848633 
	C249.424774,518.021667 249.097946,517.470398 248.863037,516.501648 
	C249.221344,510.687622 249.487717,505.291107 249.754089,499.894623 
	C251.825363,498.376160 253.730103,497.811859 254.121658,501.248962 
	C254.566223,505.151184 254.745865,509.083588 255.055176,513.451721 
z"/>
<path fill="#364E6C" opacity="1.000000" stroke="none" 
	d="
M190.341980,518.472839 
	C190.815231,517.797852 191.587982,517.400696 193.174255,516.977051 
	C204.361023,516.960022 214.734253,516.969543 225.086212,517.351929 
	C225.051193,518.224365 225.037460,518.723999 225.023727,519.223633 
	C213.562988,519.065979 202.102234,518.908325 190.341980,518.472839 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M225.314087,519.464600 
	C225.037460,518.723999 225.051193,518.224365 225.542542,517.345581 
	C233.603790,516.950684 241.187439,516.934937 248.771103,516.919189 
	C249.097946,517.470398 249.424774,518.021667 249.855606,518.877808 
	C247.632980,519.308044 245.307526,519.506775 242.979492,519.544128 
	C237.188431,519.637146 231.396210,519.656555 225.314087,519.464600 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M492.474182,427.726440 
	C491.029938,427.954407 489.845917,427.932190 488.215637,427.941162 
	C487.021820,424.069489 485.119812,421.577637 480.700470,421.622498 
	C479.760162,421.632050 478.808685,420.548248 477.815948,419.602997 
	C482.270966,420.171295 486.772400,421.104797 491.685486,422.006744 
	C492.309509,423.808838 492.521942,425.642517 492.474182,427.726440 
z"/>
<path fill="#B2D5DD" opacity="1.000000" stroke="none" 
	d="
M436.988281,576.581177 
	C434.573456,577.533813 432.183258,578.185181 429.750458,578.560364 
	C424.852722,579.315552 419.893250,581.857605 415.195923,577.423950 
	C414.186493,576.471191 411.681305,577.103088 409.436493,577.011475 
	C408.580688,574.890259 408.400299,572.670288 407.649567,570.663696 
	C407.031982,569.013000 405.737640,567.615601 404.205750,565.807007 
	C402.788940,564.676270 401.909637,563.844482 401.440948,563.018188 
	C406.177490,564.271851 410.494781,565.551575 414.837524,566.738403 
	C415.598938,566.946472 416.470856,566.816589 417.285065,566.754883 
	C424.853882,566.181763 432.421234,565.589294 439.993225,565.255371 
	C440.000458,565.676941 440.003448,565.845581 440.006439,566.014282 
	C439.002197,569.394470 437.997955,572.774719 436.988281,576.581177 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M401.030334,563.012695 
	C401.909637,563.844482 402.788940,564.676270 403.835938,565.768005 
	C398.069519,568.177307 395.774292,566.503967 396.700592,560.257202 
	C397.772278,560.325562 398.570770,560.641357 399.934875,561.149780 
	C400.677124,561.899109 400.853729,562.455933 401.030334,563.012695 
z"/>
<path fill="#B2D5DD" opacity="1.000000" stroke="none" 
	d="
M275.019165,574.417786 
	C276.136749,572.801758 277.190186,571.573975 278.356873,570.465393 
	C279.613373,569.271545 280.976105,568.189453 282.644165,567.017090 
	C284.403717,567.909363 285.768982,569.545715 287.227997,569.634033 
	C290.493530,569.831909 293.807709,569.281006 297.098602,568.995483 
	C304.174652,568.381714 311.248230,567.739380 318.323730,567.119202 
	C319.151764,567.046570 319.985474,567.006470 320.816589,567.005676 
	C329.060364,566.998047 337.304230,566.984680 345.547913,567.012207 
	C348.300385,567.021423 351.115112,567.544739 353.791077,567.126831 
	C358.650543,566.368042 363.427338,565.080200 368.632507,564.006592 
	C370.349426,568.056763 371.672485,572.113525 372.638550,576.547729 
	C370.789459,576.489868 369.280792,575.633057 367.808044,575.691040 
	C360.743561,575.968628 353.690186,576.518066 346.629761,576.916321 
	C344.416687,577.041199 342.194000,576.996094 339.975708,577.028809 
	C339.176697,576.869202 338.176544,576.962280 337.609528,576.511108 
	C331.502808,571.652466 320.738464,570.254761 314.592896,574.156738 
	C312.391693,575.554321 311.029846,578.273987 308.730042,580.646973 
	C306.248138,580.603210 304.313812,580.005737 302.395355,580.053101 
	C293.260468,580.278320 284.129913,580.678894 274.997803,581.017090 
	C275.000153,578.964233 275.002502,576.911438 275.019165,574.417786 
z"/>
<path fill="#C8E3EB" opacity="1.000000" stroke="none" 
	d="
M374.997498,600.540161 
	C365.276398,601.658386 355.557709,602.328918 345.421326,602.999756 
	C344.668030,598.291382 344.332397,593.582764 344.350586,588.283386 
	C347.274200,587.222107 350.281952,585.655762 352.335419,586.476624 
	C359.955627,589.522766 366.936462,587.880127 373.979401,585.000427 
	C374.319550,590.031067 374.659698,595.061768 374.997498,600.540161 
z"/>
<path fill="#B8DBE1" opacity="1.000000" stroke="none" 
	d="
M374.141418,584.641174 
	C366.936462,587.880127 359.955627,589.522766 352.335419,586.476624 
	C350.281952,585.655762 347.274200,587.222107 344.353455,587.851196 
	C342.663452,584.617188 341.324463,581.224670 339.980591,577.430420 
	C342.194000,576.996094 344.416687,577.041199 346.629761,576.916321 
	C353.690186,576.518066 360.743561,575.968628 367.808044,575.691040 
	C369.280792,575.633057 370.789459,576.489868 372.634094,576.963745 
	C376.759125,578.825867 374.655090,581.712402 374.141418,584.641174 
z"/>
<path fill="#C4E0E6" opacity="1.000000" stroke="none" 
	d="
M274.839722,581.362061 
	C284.129913,580.678894 293.260468,580.278320 302.395355,580.053101 
	C304.313812,580.005737 306.248138,580.603210 308.590027,580.967651 
	C307.767059,585.135864 306.529205,589.240051 305.146454,593.678223 
	C294.624054,594.009827 284.246582,594.007385 273.434570,594.009827 
	C272.997894,593.845947 272.995819,593.677246 272.988831,593.255493 
	C273.549805,589.237305 274.115692,585.472168 274.839722,581.362061 
z"/>
<path fill="#D2E7ED" opacity="1.000000" stroke="none" 
	d="
M237.168610,615.674561 
	C226.640488,612.850220 216.268036,612.804443 205.868408,615.833557 
	C205.463470,615.951538 204.937347,615.653381 204.237274,615.275757 
	C204.337631,611.624939 204.668396,608.247375 205.001404,604.432495 
	C212.679214,598.789551 221.350403,601.725159 229.573349,601.022888 
	C231.721329,600.839417 233.898468,600.997498 236.530823,601.000549 
	C237.412384,603.281372 237.825363,605.560303 238.618973,607.920837 
	C238.445267,610.448914 237.890930,612.895325 237.168610,615.674561 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M440.370239,566.170410 
	C440.003448,565.845581 440.000458,565.676941 440.247925,565.252747 
	C440.667999,564.994141 440.837677,564.991089 441.408997,564.993530 
	C444.905792,564.936279 448.118652,565.085693 448.998260,560.766541 
	C449.007507,560.518433 449.017090,560.021973 449.017090,560.021973 
	C449.831543,557.853516 450.646027,555.685059 451.750702,553.263306 
	C451.949280,556.942810 453.231964,561.874878 451.329712,564.491211 
	C449.817291,566.571350 444.411377,565.820496 440.370239,566.170410 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M482.756256,517.115601 
	C493.992340,517.003540 504.971405,517.052673 515.950012,517.004944 
	C517.738159,516.997131 519.524414,516.560730 521.711487,516.168213 
	C522.404602,516.561096 522.697876,517.108154 522.985718,517.989258 
	C519.579834,518.586243 516.179504,519.069885 512.778992,519.070679 
	C502.734192,519.072876 492.689362,518.884216 482.326172,518.516296 
	C482.171539,517.923096 482.335297,517.583801 482.756256,517.115601 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M208.603271,392.025787 
	C220.523041,392.347015 232.073151,392.598572 243.613358,393.085449 
	C245.450867,393.162964 247.243759,394.297852 249.466110,395.405396 
	C249.659698,398.273682 249.444458,400.680450 249.138062,403.465515 
	C249.078217,404.877655 249.109528,405.911591 249.088837,407.371948 
	C248.654739,410.491943 248.272614,413.185425 247.461288,415.862457 
	C245.335770,415.716644 243.639450,415.587311 241.945877,415.119873 
	C246.095169,411.250702 247.514587,406.908722 247.159256,401.389008 
	C246.860062,396.741180 245.338593,394.873352 240.624344,394.925903 
	C226.043518,395.088348 211.456696,394.814392 196.878632,395.072388 
	C191.524185,395.167114 186.187668,396.275208 180.798050,396.587769 
	C180.754562,395.588837 180.755997,394.923187 180.757446,394.257538 
	C180.814667,394.057434 180.871902,393.857300 181.425293,393.335022 
	C184.616364,392.965668 187.311310,392.918396 190.006241,392.871155 
	C190.454910,392.886078 190.903564,392.901001 192.147614,392.932434 
	C197.292038,392.636749 201.641083,392.324524 205.990143,392.012329 
	C206.736877,392.019531 207.483612,392.026733 208.603271,392.025787 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M180.374481,394.195587 
	C180.755997,394.923187 180.754562,395.588837 180.588226,396.867065 
	C180.191605,398.630341 179.959885,399.781067 179.453033,401.176605 
	C177.383118,408.845856 175.588364,416.270294 173.529724,423.774597 
	C172.925186,423.992371 172.584534,424.130249 171.818939,424.207458 
	C170.258987,424.090576 169.123947,424.034332 167.982880,423.738159 
	C167.976837,423.498260 167.970474,423.018341 167.992096,422.618561 
	C167.951645,421.417969 167.889572,420.617188 168.035202,419.512756 
	C169.250717,415.281708 170.258545,411.354340 171.578873,407.266724 
	C172.167419,406.061188 172.443451,405.015839 172.811615,403.567688 
	C172.985245,401.790955 173.066711,400.417023 173.389557,398.839874 
	C174.164169,397.491882 174.697418,396.347076 175.230682,395.202301 
	C175.507263,395.033661 175.725510,394.808502 175.976562,394.308655 
	C177.375626,394.104889 178.683578,394.119263 180.374481,394.195587 
z"/>
<path fill="#8F8E92" opacity="1.000000" stroke="none" 
	d="
M205.704041,391.715027 
	C201.641083,392.324524 197.292038,392.636749 192.483994,392.915192 
	C196.147308,389.264160 200.894943,391.368622 205.704041,391.715027 
z"/>
<path fill="#8F8E92" opacity="1.000000" stroke="none" 
	d="
M189.908264,392.500122 
	C187.311310,392.918396 184.616364,392.965668 181.474274,393.026672 
	C183.595871,389.276947 186.721252,390.878326 189.908264,392.500122 
z"/>
<path fill="#C8E3EB" opacity="1.000000" stroke="none" 
	d="
M112.576935,584.013428 
	C113.640526,587.757874 114.277580,591.496155 114.922707,595.970947 
	C114.286865,598.476501 113.642960,600.245544 112.999062,602.014648 
	C105.249413,602.316284 97.499756,602.617981 89.376572,602.958618 
	C88.670570,596.969299 88.338089,590.941101 88.008911,584.465210 
	C91.379005,584.062378 94.745880,584.148315 98.112587,584.142090 
	C102.791924,584.133423 107.471130,584.055603 112.576935,584.013428 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M184.757477,493.936432 
	C183.082626,500.406464 181.384232,506.870514 179.749924,513.350769 
	C179.351822,514.929321 179.164322,516.560913 178.867737,518.589966 
	C173.284775,518.700745 167.714035,518.148560 162.143417,518.149841 
	C157.066666,518.150940 151.993454,518.832703 146.911636,519.048340 
	C144.971970,519.130615 143.012711,518.751099 140.631470,517.957153 
	C139.468674,515.208801 138.736801,513.085266 138.011658,510.633423 
	C137.709488,509.539307 137.400620,508.773468 137.092682,507.596130 
	C136.675232,505.313202 136.278809,503.436554 135.833786,501.571564 
	C134.985657,498.017181 134.109833,494.469421 133.575714,491.021851 
	C134.955505,493.900482 135.944763,496.702240 137.120941,499.423157 
	C137.293747,499.822937 138.354462,499.838898 139.250183,500.031769 
	C139.495987,500.031128 139.987579,500.026855 139.982880,500.443787 
	C140.120865,502.168610 140.127090,503.508850 140.428177,504.779236 
	C142.540543,513.691772 144.199707,514.991699 153.218475,514.995972 
	C157.859985,514.998169 162.502792,515.081726 167.142639,514.997437 
	C172.095123,514.907471 177.027008,514.694702 177.382797,507.849518 
	C177.415863,507.213226 178.412476,506.627045 179.250931,505.807922 
	C181.279282,501.710968 183.018387,497.823700 184.757477,493.936432 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M138.004929,510.961731 
	C138.736801,513.085266 139.468674,515.208801 140.326187,517.705322 
	C139.236008,518.162354 138.020203,518.246338 136.365631,518.422791 
	C134.489166,518.554199 133.051483,518.593079 131.292145,518.805115 
	C129.591080,518.981201 128.211685,518.984070 126.420982,518.928589 
	C126.007454,518.456238 126.005219,518.042419 126.246826,517.068481 
	C126.627647,515.332336 126.764610,514.156128 127.137589,512.839783 
	C127.017128,512.098083 126.660652,511.496582 126.252960,510.663818 
	C126.201752,510.432495 126.062134,509.979706 126.071861,509.555176 
	C126.076019,507.431641 126.070450,505.732697 126.118805,503.799316 
	C126.172714,503.564880 126.308174,503.104004 126.651428,503.017334 
	C131.968063,499.618988 131.409943,496.162109 125.369934,492.908112 
	C125.587555,491.952820 125.812012,490.999115 126.261642,490.014282 
	C126.486809,489.983154 126.938591,489.932526 127.309113,489.920227 
	C129.106796,489.604797 130.533951,489.301636 132.119873,489.238647 
	C132.600937,489.958801 132.923233,490.438873 133.245514,490.918915 
	C134.109833,494.469421 134.985657,498.017181 135.833786,501.571564 
	C136.278809,503.436554 136.675232,505.313202 136.666931,507.598206 
	C133.963028,508.343750 131.685776,508.675690 128.800262,509.096252 
	C130.525330,511.567169 131.612549,513.124451 132.336136,514.160889 
	C134.434586,512.976685 136.219757,511.969208 138.004929,510.961731 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M698.063843,421.931427 
	C697.271912,421.724121 696.490967,421.405365 695.686523,421.326294 
	C684.593750,420.236328 683.447693,420.926697 683.932800,430.429993 
	C684.066772,433.055023 683.809692,435.700012 683.216858,438.650024 
	C682.327759,438.898834 681.953796,438.833496 681.579834,438.768127 
	C681.492126,434.201172 681.404358,429.634216 681.690674,424.499573 
	C683.693359,421.413361 685.094727,418.913239 681.486755,416.820251 
	C681.419861,412.567230 681.352905,408.314178 681.368164,403.616882 
	C681.817139,403.129242 682.183899,403.085815 683.093567,403.339691 
	C683.746948,407.244415 683.642029,410.878632 684.085876,414.444550 
	C684.238953,415.674438 685.659729,417.600098 686.672363,417.716156 
	C690.325928,418.134857 694.052368,417.917969 697.900024,418.320312 
	C698.053711,419.776794 698.058777,420.854126 698.063843,421.931427 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M681.351990,417.173767 
	C685.094727,418.913239 683.693359,421.413361 681.767212,424.052551 
	C681.385559,421.957916 681.301392,419.742584 681.351990,417.173767 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M688.979065,441.089203 
	C689.533691,440.452850 690.088318,439.816498 690.642944,439.180145 
	C691.096313,440.238770 691.549683,441.297394 691.992554,442.752686 
	C691.239075,443.146301 690.496094,443.143219 689.421631,442.705078 
	C689.053162,441.876465 689.016113,441.482819 688.979065,441.089203 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M683.190552,441.124207 
	C684.184448,440.932587 685.126404,441.067932 686.472900,441.587738 
	C686.920837,442.345062 686.964294,442.717926 687.007751,443.090790 
	C685.718018,442.544250 684.428223,441.997711 683.190552,441.124207 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M681.460022,439.095215 
	C681.953796,438.833496 682.327759,438.898834 682.988770,438.993408 
	C683.295166,439.412720 683.314514,439.802795 683.268066,440.478271 
	C682.581543,440.316559 681.960876,439.869415 681.460022,439.095215 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M687.355591,443.125549 
	C686.964294,442.717926 686.920837,442.345062 686.814697,441.701324 
	C687.242188,441.317780 687.732544,441.205109 688.600952,441.090820 
	C689.016113,441.482819 689.053162,441.876465 689.071899,442.566742 
	C688.603577,442.962372 688.153503,443.061371 687.355591,443.125549 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M522.256104,450.617035 
	C522.293274,458.741547 522.211853,466.404755 522.213562,474.529846 
	C521.929260,477.034119 521.561768,479.076569 521.222168,481.535278 
	C520.692932,481.712128 520.135742,481.472687 519.077271,481.005920 
	C518.300354,480.576538 518.107544,480.313751 518.004150,479.525146 
	C518.006348,474.374603 518.001953,469.689148 517.997559,465.003662 
	C518.327026,461.294128 518.656494,457.584595 518.988525,453.012512 
	C518.978943,450.436005 518.966736,448.722107 518.954529,447.008179 
	C519.652954,442.719971 520.351379,438.431732 521.413696,434.006958 
	C521.897583,439.298859 522.017578,444.727325 522.256104,450.617035 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M491.192108,416.064880 
	C491.140961,415.412537 491.330017,415.228088 492.108948,415.232910 
	C493.136444,416.546570 493.674866,417.774231 494.213318,419.001892 
	C493.767670,419.360687 493.322052,419.719452 492.592957,420.143768 
	C491.983490,418.949341 491.657532,417.689423 491.192108,416.064880 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M480.211914,409.219910 
	C480.392792,409.235168 480.498016,409.534576 480.463165,409.947754 
	C480.260803,409.875732 480.198547,409.689880 480.211914,409.219910 
z"/>
<path fill="#0C1A28" opacity="1.000000" stroke="none" 
	d="
M916.205444,472.965057 
	C916.608093,476.745758 916.708679,480.431885 916.805542,484.991974 
	C916.375122,494.034515 916.375122,494.028595 908.509888,494.006439 
	C904.519775,493.995178 900.529602,494.010590 896.539429,494.012085 
	C892.835144,494.013489 889.130920,494.012421 884.392822,494.012421 
	C889.152222,487.644958 893.078430,482.392090 897.312256,476.985321 
	C900.073975,474.203644 902.528076,471.575897 904.982178,468.948120 
	C907.297729,469.583008 909.647095,470.118286 911.916260,470.889648 
	C913.308838,471.363037 914.578491,472.197906 916.205444,472.965057 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M905.106079,468.637299 
	C902.528076,471.575897 900.073975,474.203644 897.304932,476.595642 
	C896.979431,475.582703 896.968872,474.805542 897.101990,473.707031 
	C898.169312,471.267883 899.092957,469.150055 900.367798,466.988159 
	C905.117249,462.358307 909.610718,457.858673 913.871216,453.148254 
	C915.820068,450.993591 917.310181,448.424011 919.276367,445.918396 
	C920.035461,445.197845 920.526733,444.601562 921.387329,444.007111 
	C922.495544,443.996857 923.234314,443.984833 924.022339,444.024048 
	C924.071594,444.075317 924.168335,444.179504 924.131409,444.540649 
	C924.120422,445.853424 924.146301,446.805054 924.172241,447.756714 
	C923.781494,448.272339 923.390686,448.787964 922.934509,449.631714 
	C918.598938,454.709991 914.328796,459.460144 909.992798,464.546448 
	C908.361389,466.030579 906.795715,467.178528 905.106079,468.637299 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M687.593933,509.936218 
	C687.717590,505.792297 688.084473,501.632507 687.816223,497.514099 
	C687.736145,496.285339 685.877563,495.172424 684.426331,493.972717 
	C684.329407,492.548431 684.640015,491.157898 685.153748,489.320801 
	C685.356873,488.874268 685.734924,488.825714 686.092896,488.870728 
	C687.937622,487.315247 689.424316,485.714722 691.320435,484.120453 
	C695.795898,484.739319 699.861816,485.351929 703.830933,486.289124 
	C699.044800,491.686005 695.641968,497.152527 697.032532,504.590027 
	C697.538330,507.295380 697.173645,510.163483 697.109741,513.371033 
	C697.024780,515.195190 697.036255,516.605591 697.047729,518.016052 
	C696.576538,518.087158 696.105347,518.158325 695.307251,518.374268 
	C693.248535,518.398254 691.516663,518.277466 689.280579,517.836853 
	C688.419434,516.658020 688.062500,515.799011 687.705566,514.940002 
	C687.723267,514.518982 687.740967,514.097961 687.794434,512.984436 
	C687.751465,511.506683 687.672668,510.721466 687.593933,509.936218 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M687.342407,515.014099 
	C688.062500,515.799011 688.419434,516.658020 688.846191,517.859985 
	C686.540283,518.539612 684.164490,518.876160 681.002625,519.138977 
	C678.446289,519.118652 676.676025,519.172058 674.905762,519.225525 
	C674.504150,519.211975 674.102539,519.198425 672.984253,519.132568 
	C671.146729,519.124084 670.025879,519.167969 668.905090,519.211792 
	C666.860962,519.137024 664.816895,519.062256 662.327942,518.937134 
	C661.581360,518.494202 661.279602,518.101685 661.075256,517.835938 
	C669.656006,516.925781 678.317566,516.007019 687.342407,515.014099 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M675.020874,519.563843 
	C676.676025,519.172058 678.446289,519.118652 680.655884,519.138977 
	C679.424438,522.170471 677.315308,521.339905 675.020874,519.563843 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M669.003052,519.530762 
	C670.025879,519.167969 671.146729,519.124084 672.683716,519.158569 
	C671.766907,519.441162 670.433960,519.645447 669.003052,519.530762 
z"/>
<path fill="#3F7798" opacity="1.000000" stroke="none" 
	d="
M697.206055,512.957336 
	C697.173645,510.163483 697.538330,507.295380 697.032532,504.590027 
	C695.641968,497.152527 699.044800,491.686005 703.855713,486.294556 
	C703.977356,485.975403 703.959595,485.935638 704.260864,485.852966 
	C705.634521,484.844513 706.706848,483.918762 708.160767,482.989014 
	C710.009583,482.653412 711.476685,482.321838 712.956543,482.329437 
	C712.975464,483.121246 712.981628,483.573822 712.833679,484.322998 
	C709.611877,488.625153 703.555847,490.529999 703.693726,497.274414 
	C703.169067,499.851074 703.105652,502.178986 702.665344,504.433228 
	C701.081604,512.540100 702.187317,513.997498 710.325562,514.000610 
	C737.420654,514.010925 764.515869,513.968933 791.610718,514.045959 
	C795.559570,514.057190 799.523438,514.470581 803.450256,514.946228 
	C807.570190,515.445251 812.873779,513.313660 812.100220,507.493896 
	C811.600647,503.735016 811.976868,499.859741 811.974854,495.583435 
	C812.007141,492.428497 812.025269,489.726776 812.430664,487.111450 
	C813.211243,489.285278 813.513916,491.395416 814.027893,493.452759 
	C814.410645,494.984711 815.066956,496.448364 815.710693,498.400330 
	C815.800049,503.884186 815.780090,508.910126 815.464539,513.985107 
	C814.446350,514.688538 813.723694,515.342957 812.986755,516.261536 
	C812.958130,516.701599 812.943787,516.877380 812.929382,517.053223 
	C811.497375,517.359314 810.065430,517.665466 808.268677,518.062256 
	C803.272705,518.132690 798.641479,518.112427 793.556519,518.139282 
	C788.382080,518.115540 783.661377,518.044739 778.171265,517.670898 
	C776.757019,517.241577 776.112305,517.005493 775.467407,517.004761 
	C755.853882,516.983398 736.240356,516.967285 716.627014,517.026367 
	C715.397705,517.030090 714.170166,517.650452 712.941833,517.984009 
	C710.593811,517.983093 708.245728,517.982178 705.203796,517.700867 
	C702.075317,515.932800 699.640686,514.445068 697.206055,512.957336 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M265.213318,517.285522 
	C266.434113,516.333557 267.866150,515.639404 269.655945,514.981079 
	C268.483978,515.859009 266.954254,516.701172 265.213318,517.285522 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M948.222778,413.760559 
	C947.799255,412.468872 947.644348,410.951843 947.765381,409.209839 
	C948.191284,410.501678 948.341309,412.018433 948.222778,413.760559 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M171.266357,407.426941 
	C170.258545,411.354340 169.250717,415.281708 167.745819,419.599243 
	C167.489319,420.999023 167.729889,422.008667 167.970459,423.018341 
	C167.970474,423.018341 167.976837,423.498260 167.715805,423.977509 
	C167.048279,426.361420 166.641785,428.266113 166.177917,430.557251 
	C165.972321,432.232391 165.824127,433.521057 165.675903,434.809723 
	C164.449203,433.869995 162.477295,433.153656 162.117661,431.954010 
	C159.909103,424.586823 158.138794,417.089813 156.075027,409.677124 
	C154.578186,404.300751 152.853424,398.987823 151.226379,393.266571 
	C153.578583,393.160187 155.934708,393.433838 158.724915,394.237335 
	C163.822067,396.192474 168.485123,397.617767 173.148178,399.043060 
	C173.066711,400.417023 172.985245,401.790955 172.478058,403.665558 
	C171.790344,405.253113 171.528351,406.340027 171.266357,407.426941 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M173.389557,398.839874 
	C168.485123,397.617767 163.822067,396.192474 159.087204,394.396118 
	C164.127060,394.369049 169.238739,394.713043 174.790527,395.129669 
	C174.697418,396.347076 174.164169,397.491882 173.389557,398.839874 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M712.961792,518.267578 
	C714.170166,517.650452 715.397705,517.030090 716.627014,517.026367 
	C736.240356,516.967285 755.853882,516.983398 775.467407,517.004761 
	C776.112305,517.005493 776.757019,517.241577 777.706055,517.651367 
	C775.181152,518.435852 772.352112,518.936951 769.248047,519.729492 
	C767.599609,520.015015 766.226135,520.009033 764.441345,520.018250 
	C763.265015,520.010620 762.499939,519.987732 761.352905,519.998169 
	C759.598022,520.021973 758.225098,520.012390 756.441040,520.017822 
	C752.984314,520.006409 749.938782,519.979980 746.437378,519.983032 
	C743.621521,520.004639 741.261414,519.996643 738.459839,520.000488 
	C736.941650,519.992859 735.864990,519.973267 734.385071,519.982849 
	C727.278931,519.698730 720.575989,519.385437 713.441406,519.095642 
	C713.000427,518.929810 712.991150,518.740479 712.961792,518.267578 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M697.751282,417.941101 
	C694.052368,417.917969 690.325928,418.134857 686.672363,417.716156 
	C685.659729,417.600098 684.238953,415.674438 684.085876,414.444550 
	C683.642029,410.878632 683.746948,407.244415 683.371460,403.328003 
	C685.921021,396.930328 692.097168,397.631592 697.549194,396.067566 
	C698.045288,402.732025 698.143188,409.394775 698.136414,416.733093 
	C697.938293,417.586151 697.844788,417.763641 697.751282,417.941101 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M700.977051,391.055847 
	C701.247925,391.526001 701.169189,391.961456 700.663574,392.706390 
	C699.503540,393.014221 698.770447,393.012604 698.037354,393.010986 
	C698.450989,392.333679 698.864624,391.656372 699.602051,390.981476 
	C700.159790,390.996338 700.393677,391.008789 700.977051,391.055847 
z"/>
<path fill="#8F8E92" opacity="1.000000" stroke="none" 
	d="
M698.047852,393.375610 
	C698.770447,393.012604 699.503540,393.014221 700.602234,393.032898 
	C700.281311,393.701660 699.594788,394.353394 698.632874,395.085815 
	C698.257812,394.691071 698.158081,394.215668 698.047852,393.375610 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M809.005920,424.046600 
	C809.512634,423.454956 810.019409,422.863312 811.115112,422.090942 
	C812.744629,420.575104 813.785156,419.239960 814.825684,417.904785 
	C814.825684,417.904755 815.050659,417.549072 815.526184,417.249207 
	C816.079407,414.306030 816.157043,411.662750 816.234680,409.019440 
	C816.152283,405.292358 816.069885,401.565308 815.988708,397.365204 
	C817.948730,395.282166 819.845032,393.936707 822.214844,396.462952 
	C822.467896,396.732635 824.194397,395.620056 825.304138,394.797424 
	C825.738037,394.030487 826.105042,393.614899 826.711060,393.055573 
	C828.022644,393.306763 829.095337,393.701660 830.522583,394.515869 
	C830.536255,396.977570 830.195312,399.019989 829.419189,401.039307 
	C825.212708,400.738220 821.441467,400.460297 817.496338,400.169525 
	C818.600647,404.762726 820.285583,407.194855 825.010498,405.178711 
	C826.558899,404.518005 828.582947,404.972198 830.389465,404.916565 
	C830.561646,408.647186 830.733765,412.377838 830.722595,416.881714 
	C830.378174,421.443268 830.217041,425.231537 829.653564,429.009460 
	C828.008789,428.666382 826.784546,428.212708 825.520691,428.023407 
	C821.492249,427.419830 817.412842,426.689331 813.745911,429.918091 
	C813.517517,429.973785 813.049988,430.023651 812.724609,430.047302 
	C811.941406,430.703918 811.483521,431.336914 810.722656,432.013123 
	C809.953430,433.704376 809.487244,435.352386 808.705078,436.855835 
	C807.896301,434.779724 806.903442,432.812561 807.037842,430.925720 
	C807.203735,428.597778 808.307495,426.336609 809.005920,424.046600 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M686.440430,482.286316 
	C687.584534,481.151123 688.709290,480.242981 690.190918,479.755463 
	C690.181763,481.105469 689.815735,482.034851 689.163269,482.937622 
	C688.309753,482.952362 687.742798,482.993713 687.175781,483.035095 
	C687.175781,483.035095 686.807373,483.073914 686.629700,483.020996 
	C686.452026,482.968048 686.421204,482.513367 686.440430,482.286316 
z"/>
<path fill="#ACADA9" opacity="1.000000" stroke="none" 
	d="
M362.332672,517.016479 
	C363.771820,515.755798 365.582275,514.530762 367.960693,513.246521 
	C368.720612,513.227661 368.912506,513.267944 369.104431,513.308228 
	C366.970947,514.556152 364.837463,515.804138 362.332672,517.016479 
z"/>
<path fill="#ACADA9" opacity="1.000000" stroke="none" 
	d="
M372.107178,511.247833 
	C372.885712,509.830658 373.784424,508.690857 375.163818,507.280457 
	C376.066223,506.975433 376.487946,506.940979 376.937500,507.243561 
	C375.965942,509.041443 374.966583,510.502289 373.967194,511.963165 
	C373.967194,511.963165 373.975067,511.979218 373.968140,511.967651 
	C373.383270,511.812439 372.805298,511.668823 372.107178,511.247833 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M369.338959,513.097168 
	C368.912506,513.267944 368.720612,513.227661 368.256989,513.074707 
	C368.608246,512.367859 369.231232,511.773804 370.207153,511.284607 
	C370.231262,511.888367 369.902374,512.387268 369.338959,513.097168 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M697.109741,513.371033 
	C699.640686,514.445068 702.075317,515.932800 704.763672,517.686707 
	C702.532288,518.164734 700.047119,518.376587 697.304810,518.302246 
	C697.036255,516.605591 697.024780,515.195190 697.109741,513.371033 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M924.490845,447.798340 
	C924.146301,446.805054 924.120422,445.853424 924.452148,444.442810 
	C926.578125,441.710022 928.346375,439.436218 930.114624,437.162476 
	C930.556580,436.829712 930.998535,436.496948 932.022217,436.071472 
	C933.057922,435.974976 933.511902,435.971252 933.965820,435.967499 
	C932.975769,438.390442 931.985596,440.813385 930.968262,443.613739 
	C930.820312,444.283417 930.635864,444.527649 929.876099,444.901459 
	C928.863342,445.695831 928.362305,446.312561 927.861206,446.929291 
	C926.843994,447.232880 925.826660,447.536438 924.490845,447.798340 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M935.286499,432.914215 
	C936.845886,430.434326 938.744507,427.933380 940.869141,425.634216 
	C940.803223,426.685883 940.511475,427.535797 940.088867,428.697693 
	C938.513977,430.304138 937.069885,431.598663 935.286499,432.914215 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M933.974365,435.597595 
	C933.511902,435.971252 933.057922,435.974976 932.264282,435.937317 
	C932.398621,434.962708 932.872681,434.029480 933.690552,433.050842 
	C934.017273,433.746185 934.000122,434.486938 933.974365,435.597595 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M633.108398,517.328369 
	C636.835205,517.873291 641.445312,514.898926 644.978516,519.379150 
	C643.980164,519.819580 642.903992,519.896240 641.424194,519.972656 
	C638.471558,519.183289 635.922607,518.394043 633.108398,517.328369 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M619.187256,519.125610 
	C618.971252,518.816711 618.983582,518.621399 619.020630,518.131592 
	C620.799377,517.888672 622.553406,517.940247 624.756409,518.032166 
	C625.147522,518.510315 625.089661,518.948120 625.015869,519.718201 
	C623.612244,520.041382 622.224548,520.032349 620.420898,520.037903 
	C619.808594,519.781311 619.612122,519.510254 619.187256,519.125610 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M374.342468,511.982178 
	C374.966583,510.502289 375.965942,509.041443 376.939392,507.261597 
	C376.913452,506.942627 376.907379,506.914154 377.240906,506.969849 
	C378.022491,506.990417 378.470551,506.955261 378.918640,506.920105 
	C378.918640,506.920105 379.034424,507.031494 379.094879,507.087341 
	C377.676117,508.762543 376.196930,510.381897 374.342468,511.982178 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M829.854431,401.062439 
	C830.195312,399.019989 830.536255,396.977570 830.868958,394.545563 
	C833.929016,393.470398 836.997314,392.784821 840.485352,392.104095 
	C840.905212,392.108978 840.965454,392.565613 840.977356,392.795776 
	C837.031921,394.276459 832.178528,394.420441 831.655945,400.675171 
	C830.920105,401.012390 830.513123,400.992676 830.044800,400.999847 
	C829.983459,401.026733 829.854431,401.062439 829.854431,401.062439 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M815.760132,513.936096 
	C815.780090,508.910126 815.800049,503.884186 815.814819,498.014221 
	C815.905945,496.109009 816.002319,495.047821 816.098633,493.986603 
	C816.723328,495.300903 817.842834,496.598694 817.887451,497.932404 
	C818.086426,503.884460 817.978394,509.846802 817.950684,516.512817 
	C817.910706,517.391052 817.901367,517.561951 817.892090,517.732788 
	C817.532349,517.738464 817.172546,517.744141 816.499329,517.467224 
	C816.106079,516.783508 816.026306,516.382324 815.965454,515.637207 
	C815.909668,514.840881 815.834900,514.388489 815.760132,513.936096 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M815.946533,515.981201 
	C816.026306,516.382324 816.106079,516.783508 816.229919,517.486084 
	C815.324402,517.718140 814.374817,517.648804 813.177307,517.316406 
	C812.943787,516.877380 812.958130,516.701599 813.355347,516.264771 
	C814.474426,515.996216 815.210449,515.988708 815.946533,515.981201 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M818.061401,518.031860 
	C817.901367,517.561951 817.910706,517.391052 817.946045,516.962952 
	C818.559814,516.950745 819.147705,517.195679 819.868286,517.729248 
	C820.046936,518.188354 820.092773,518.358826 820.119141,518.793762 
	C819.476746,518.815857 818.853699,518.573425 818.061401,518.031860 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M824.910339,518.369324 
	C825.165100,518.203735 825.469360,518.337891 825.898560,518.694946 
	C825.669006,518.834961 825.314392,518.752014 824.910339,518.369324 
z"/>
<path fill="#060B10" opacity="1.000000" stroke="none" 
	d="
M605.272156,170.020142 
	C605.010071,169.299622 604.978943,168.316406 604.985596,166.999634 
	C605.183350,167.696533 605.343262,168.726990 605.272156,170.020142 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M648.255493,519.051941 
	C648.267395,518.924866 648.564026,518.774231 648.984741,518.796082 
	C648.919312,518.988525 648.729858,519.008423 648.255493,519.051941 
z"/>
<path fill="#8F8E92" opacity="1.000000" stroke="none" 
	d="
M928.200317,446.949036 
	C928.362305,446.312561 928.863342,445.695831 929.680908,445.026672 
	C929.985962,445.400909 929.974365,445.827545 929.925232,446.570587 
	C929.438232,446.914246 928.988770,446.941498 928.200317,446.949036 
z"/>
<path fill="#060B10" opacity="1.000000" stroke="none" 
	d="
M597.016846,97.391022 
	C597.455017,97.024475 597.889465,97.003548 598.649902,96.979790 
	C599.784058,102.850044 597.684631,107.699883 593.384888,111.952591 
	C593.015015,111.560661 592.986023,111.111473 592.921692,110.326225 
	C592.940491,108.629471 592.994629,107.268768 593.384888,105.416840 
	C594.158081,103.946884 594.595093,102.968163 595.032104,101.989449 
	C595.692444,100.571846 596.352783,99.154236 597.016846,97.391022 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M594.734375,102.132965 
	C594.595093,102.968163 594.158081,103.946884 593.382446,105.008148 
	C593.508057,104.152611 593.972351,103.214546 594.734375,102.132965 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M347.295288,288.703766 
	C346.666168,287.103699 346.046204,285.210602 346.044373,283.316895 
	C345.992249,229.719879 346.006561,176.122818 345.986053,122.525764 
	C345.985291,120.548752 345.743927,118.571838 345.325958,116.295074 
	C345.034943,104.650703 345.032623,93.306129 345.362061,81.487961 
	C346.126801,81.009567 346.559753,81.004768 346.992676,80.999969 
	C347.327148,82.559547 347.953003,84.118904 347.954163,85.678719 
	C348.003448,151.946060 347.999390,218.213440 347.980682,284.480804 
	C347.980316,285.790710 347.727570,287.100555 347.295288,288.703766 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M398.970917,48.035522 
	C398.970917,48.035526 398.990173,48.509670 398.993164,49.163208 
	C399.668945,53.205166 400.341675,56.593582 401.014404,59.981995 
	C399.018158,57.326149 396.527954,54.900791 395.153351,51.954865 
	C393.514832,48.443108 393.268005,44.269478 391.554565,40.810394 
	C390.684784,39.054466 387.960999,37.250114 386.002380,37.165955 
	C377.031097,36.780476 368.033966,36.993233 359.046143,36.995644 
	C350.718140,36.997875 348.744904,38.630730 346.942505,46.744980 
	C345.906433,46.979603 345.475433,46.964748 345.044403,46.949894 
	C345.042023,44.938221 345.039642,42.926548 345.398132,40.398766 
	C346.154968,38.570576 346.550964,37.258495 346.946899,35.946411 
	C347.063934,35.632973 347.256989,35.376839 348.214783,35.082279 
	C362.616455,34.975685 376.329376,34.964806 390.042297,34.953934 
	C390.997650,35.398018 391.953003,35.842102 392.997772,37.002838 
	C394.384277,39.821621 395.681335,41.923748 396.978394,44.025875 
	C396.995270,44.452492 397.012146,44.879101 397.057739,45.953049 
	C397.714600,47.078762 398.342773,47.557140 398.970917,48.035522 
z"/>
<path fill="#031829" opacity="1.000000" stroke="none" 
	d="
M414.063507,86.938095 
	C415.006683,87.713905 416.390106,88.297234 416.826263,89.294724 
	C422.410614,102.066444 427.865631,114.894775 433.321167,127.722481 
	C433.554474,128.271072 433.474274,128.953003 433.304688,129.780853 
	C430.648895,126.166679 427.792542,122.546707 425.904633,118.477463 
	C422.616180,111.389450 419.924744,104.024452 416.944275,96.034233 
	C415.958191,92.507980 415.010834,89.723038 414.063507,86.938095 
z"/>
<path fill="#031829" opacity="1.000000" stroke="none" 
	d="
M344.974365,47.368530 
	C345.475433,46.964748 345.906433,46.979603 346.661041,46.997284 
	C346.988068,58.020962 346.991516,69.041809 346.993805,80.531311 
	C346.559753,81.004768 346.126801,81.009567 345.369965,81.021133 
	C344.709412,80.310684 344.066864,79.586891 344.082062,78.877220 
	C344.304596,68.512764 344.614929,58.150188 344.974365,47.368530 
z"/>
<path fill="#031829" opacity="1.000000" stroke="none" 
	d="
M401.019501,60.229702 
	C400.341675,56.593582 399.668945,53.205166 399.001709,49.400322 
	C399.442749,48.994240 399.878235,49.004585 400.639648,49.024639 
	C402.339203,53.419792 403.688141,57.813232 405.098969,62.186695 
	C405.653625,63.906078 406.346771,65.580788 406.626770,67.587296 
	C404.528259,65.590134 402.779816,63.281517 401.031403,60.972893 
	C401.031403,60.972893 401.024597,60.477409 401.019501,60.229702 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M414.040283,86.591599 
	C415.010834,89.723038 415.958191,92.507980 416.920776,95.658112 
	C411.872406,92.245102 410.134674,87.538216 412.545227,83.070312 
	C413.275665,84.071594 413.646332,85.158356 414.040283,86.591599 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M352.974182,345.623413 
	C349.162781,346.418915 346.332428,345.675568 346.997803,340.419189 
	C348.980560,341.763458 350.957520,343.523254 352.974182,345.623413 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M401.060974,61.349136 
	C402.779816,63.281517 404.528259,65.590134 406.619751,67.967911 
	C406.970001,68.477356 406.977234,68.917633 406.964783,69.688332 
	C401.283844,69.873108 402.987183,64.528587 401.060974,61.349136 
z"/>
<path fill="#031829" opacity="1.000000" stroke="none" 
	d="
M406.999512,70.368492 
	C408.643951,73.098175 410.277985,76.204254 411.924011,79.693047 
	C411.396667,79.879784 410.857391,79.683815 410.126648,78.887932 
	C408.956390,76.526505 407.977570,74.764992 406.998779,73.003479 
	C406.995544,72.250618 406.992310,71.497757 406.999512,70.368492 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M406.730713,73.229340 
	C407.977570,74.764992 408.956390,76.526505 409.966370,78.641945 
	C406.986694,78.318138 405.323822,76.780388 406.730713,73.229340 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M395.241577,111.793564 
	C394.325012,110.582222 393.658325,109.166977 392.989685,107.383286 
	C393.822296,108.539772 394.656891,110.064713 395.241577,111.793564 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M400.961334,124.666595 
	C400.290405,123.955063 399.636993,122.862793 398.981628,121.398529 
	C399.646088,122.112976 400.312469,123.199409 400.961334,124.666595 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M398.976562,120.662659 
	C398.317017,120.249077 397.661804,119.479904 397.001465,118.359215 
	C397.657867,118.774155 398.319397,119.540619 398.976562,120.662659 
z"/>
<path fill="#031829" opacity="1.000000" stroke="none" 
	d="
M389.935822,34.564087 
	C376.329376,34.964806 362.616455,34.975685 348.430481,34.969818 
	C348.935760,34.308414 349.908508,33.112701 350.893250,33.102795 
	C363.193054,32.978962 375.494720,33.008892 387.795471,33.079762 
	C388.475494,33.083679 389.151489,33.793171 389.935822,34.564087 
z"/>
<path fill="#031829" opacity="1.000000" stroke="none" 
	d="
M396.982666,43.649147 
	C395.681335,41.923748 394.384277,39.821621 393.059906,37.340492 
	C394.350739,39.065136 395.668854,41.168777 396.982666,43.649147 
z"/>
<path fill="#031829" opacity="1.000000" stroke="none" 
	d="
M346.591797,36.027466 
	C346.550964,37.258495 346.154968,38.570576 345.406799,39.967789 
	C345.448608,38.738117 345.842621,37.423313 346.591797,36.027466 
z"/>
<path fill="#031829" opacity="1.000000" stroke="none" 
	d="
M398.943329,47.712502 
	C398.342773,47.557140 397.714600,47.078762 397.067627,46.273109 
	C397.671143,46.427052 398.293427,46.908268 398.943329,47.712502 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M577.065308,49.281456 
	C577.611694,46.565598 578.939453,43.890102 578.368896,41.711845 
	C578.026428,40.404316 574.724854,39.115837 572.746643,39.105804 
	C541.273193,38.946190 509.798706,38.994816 478.324463,38.996090 
	C468.998749,38.996468 459.669952,39.141148 450.348633,38.938755 
	C445.556427,38.834702 443.865814,40.818542 443.925446,45.495575 
	C444.043671,54.767101 445.112885,55.993240 454.638916,55.996655 
	C479.785004,56.005672 504.931213,55.975044 530.077087,56.042263 
	C533.344788,56.050999 536.611145,56.542397 539.927917,57.986855 
	C537.009583,58.322510 534.092041,58.941299 531.172791,58.949596 
	C504.861206,59.024342 478.549286,58.989719 452.237488,58.992367 
	C442.957184,58.993301 439.554840,55.513893 440.477234,46.328449 
	C441.241913,38.713833 442.411926,35.781452 451.823914,35.857529 
	C491.289032,36.176529 530.761230,36.252312 570.223633,35.792782 
	C578.913757,35.691589 584.259033,41.341228 580.289368,51.069046 
	C579.867859,52.101955 579.847900,53.298714 578.748169,54.636044 
	C578.367798,53.262608 577.987427,51.889168 577.346191,50.273102 
	C577.072693,49.864040 577.059998,49.697609 577.065308,49.281456 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M499.842346,160.236694 
	C499.682312,160.536774 499.522278,160.836838 498.679901,161.125565 
	C496.629669,162.055313 495.261780,162.996399 493.893860,163.937500 
	C492.565186,165.291550 491.358856,166.814499 489.844177,167.911774 
	C489.283081,168.318283 487.997772,167.725128 486.959778,167.239090 
	C486.912415,166.473679 486.948059,166.057037 487.008148,165.327103 
	C491.313141,160.752899 495.933655,156.777390 499.801208,152.169830 
	C507.140350,143.426559 514.067322,134.336258 521.130493,125.362633 
	C530.603394,113.327644 539.971069,101.208183 549.603210,89.302422 
	C550.622253,88.042862 553.110962,87.972313 554.811890,87.977020 
	C549.610046,95.084915 544.636597,101.666939 539.394104,108.027138 
	C526.146790,124.098633 512.779846,140.071503 499.456482,156.080292 
	C498.183807,157.609512 496.956787,159.106476 499.842346,160.236694 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M529.007324,81.007713 
	C526.011780,85.167419 523.040771,89.345001 520.014465,93.482231 
	C516.390076,98.437141 512.718628,103.357613 509.036804,108.647873 
	C508.693909,109.120628 508.437256,109.312744 508.120605,109.792923 
	C502.106201,116.397690 496.207306,122.788628 490.308441,129.179565 
	C489.907501,128.872314 489.506592,128.565063 489.105682,128.257828 
	C490.625305,125.831757 491.866852,123.162636 493.726990,121.034187 
	C496.847473,117.463554 500.604889,114.440765 503.645569,110.811531 
	C507.002258,106.805168 509.734558,102.282570 512.954651,98.153168 
	C517.227844,92.673325 521.686584,87.336906 526.138367,81.999489 
	C526.593628,81.453621 527.545471,81.321945 528.633423,80.998955 
	C529.000000,80.999825 529.007324,81.007713 529.007324,81.007713 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M500.062378,160.058258 
	C496.956787,159.106476 498.183807,157.609512 499.456482,156.080292 
	C512.779846,140.071503 526.146790,124.098633 539.394104,108.027138 
	C544.636597,101.666939 549.610046,95.084915 554.859131,88.304474 
	C556.984375,85.900047 558.956604,83.795021 561.219238,81.135086 
	C561.726990,79.735237 561.944275,78.890305 562.161560,78.045364 
	C563.681152,76.563522 565.200745,75.081688 567.163574,73.153015 
	C570.052856,70.118118 572.498901,67.530060 574.944946,64.942009 
	C575.452209,64.970284 575.959412,64.998550 576.846680,65.054214 
	C571.364380,72.487785 565.589844,79.965530 559.623657,87.287025 
	C539.880493,111.514992 520.068176,135.686584 500.062378,160.058258 
z"/>
<path fill="#76C0DF" opacity="1.000000" stroke="none" 
	d="
M526.394897,76.030777 
	C524.759277,72.086319 523.430603,67.992310 522.051270,63.529236 
	C526.370056,63.160168 530.739563,63.160168 535.109009,63.160168 
	C535.384521,63.636925 535.659973,64.113686 535.935425,64.590439 
	C532.857605,68.354034 529.779785,72.117638 526.394897,76.030777 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M575.004395,64.596725 
	C572.498901,67.530060 570.052856,70.118118 567.307068,72.861725 
	C566.123291,72.839600 565.239197,72.661926 563.489441,72.310257 
	C567.950195,67.504189 571.898499,63.250244 575.846802,58.996296 
	C576.254517,59.264492 576.662231,59.532692 577.069946,59.800888 
	C576.401245,61.284412 575.732605,62.767933 575.004395,64.596725 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M541.757690,67.788605 
	C539.894592,69.877800 538.255615,71.686760 536.616699,73.495720 
	C536.009155,72.954010 535.401672,72.412300 534.794189,71.870583 
	C537.274048,68.842133 539.753967,65.813683 542.233826,62.785225 
	C542.758423,63.087986 543.283081,63.390743 543.807678,63.693504 
	C543.199036,64.965126 542.590393,66.236748 541.757690,67.788605 
z"/>
<path fill="#89A1D8" opacity="1.000000" stroke="none" 
	d="
M461.653503,62.779743 
	C457.748535,66.674500 453.463165,63.143322 449.067291,62.763863 
	C453.062683,62.462185 457.192871,62.502422 461.653503,62.779743 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M484.278687,133.125763 
	C485.619843,131.836304 486.739563,130.816116 487.859283,129.795929 
	C488.320770,130.306702 488.782227,130.817490 489.243683,131.328262 
	C487.165680,133.156448 485.087646,134.984634 483.009644,136.812805 
	C482.740173,136.622223 482.470734,136.431641 482.201294,136.241043 
	C482.819946,135.292374 483.438629,134.343704 484.278687,133.125763 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M528.995728,80.995682 
	C530.370300,78.572266 531.749084,76.152985 533.127930,73.733711 
	C533.796814,74.213799 534.465698,74.693886 535.134583,75.173973 
	C533.323669,77.092995 531.512756,79.012024 529.354614,80.969376 
	C529.007324,81.007713 529.000000,80.999825 528.995728,80.995682 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M479.640137,142.540131 
	C479.108734,142.154144 478.843475,141.631531 478.578247,141.108917 
	C479.121857,140.882736 479.665466,140.656540 480.209076,140.430328 
	C480.108154,141.088058 480.007233,141.745773 479.640137,142.540131 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M561.817017,78.057938 
	C561.944275,78.890305 561.726990,79.735237 561.254028,80.787979 
	C561.156372,80.020699 561.314392,79.045601 561.817017,78.057938 
z"/>
<path fill="#010102" opacity="1.000000" stroke="none" 
	d="
M443.015869,225.008423 
	C449.659302,217.667877 458.383545,222.256058 466.864746,221.147186 
	C470.097260,225.872803 472.550598,230.435410 474.745544,235.231827 
	C474.671600,237.405914 474.356903,239.589981 475.157532,241.229324 
	C476.357880,243.687057 478.341156,245.762421 480.140686,248.329742 
	C487.871521,266.431732 495.453125,284.204346 503.077271,302.367493 
	C505.380524,306.911469 509.232544,304.541748 512.340454,304.725281 
	C513.144958,304.772766 514.098083,302.304749 515.143799,300.680054 
	C516.168640,299.170746 517.293335,298.084137 517.855652,296.758911 
	C522.580017,285.625427 527.313843,274.493317 531.810852,263.267059 
	C534.470947,256.626587 536.296570,249.630600 539.236816,243.128845 
	C543.060303,234.674026 544.376831,224.904541 551.999390,218.305191 
	C553.256104,217.217163 553.387756,214.829651 554.039368,213.042694 
	C555.095520,213.039459 556.151672,213.036240 557.904541,213.322708 
	C564.094177,213.699875 569.587097,213.787354 575.080017,213.874847 
	C575.528442,213.891830 575.976807,213.908813 576.763855,213.931885 
	C575.801270,226.361496 568.794189,236.659424 564.411621,247.933319 
	C560.724060,257.419556 556.988342,266.890472 553.054382,276.276184 
	C550.968201,281.253601 548.229858,285.956604 546.117554,290.924469 
	C543.229980,297.715668 540.779663,304.692169 537.921997,311.496857 
	C533.896973,321.081360 529.753174,330.618469 525.498596,340.103363 
	C522.946838,345.792175 517.987610,346.475372 512.465332,345.940277 
	C507.859619,345.493988 503.205627,345.507385 498.614960,344.963440 
	C496.973969,344.769043 494.632629,343.944427 494.022827,342.710052 
	C491.253632,337.104218 488.988159,331.249512 486.265198,324.888489 
	C485.326752,323.528412 484.664612,322.764221 484.002441,322.000000 
	C484.002441,322.000000 483.989471,321.509369 483.990540,320.886139 
	C483.328156,319.174866 482.664703,318.086823 482.000366,316.623413 
	C481.336273,315.163422 480.673035,314.078827 479.997009,312.611023 
	C476.875336,304.622925 473.807495,297.000885 470.641693,289.419800 
	C468.814819,285.045013 466.736877,280.774445 464.936676,276.389343 
	C461.886627,268.959747 458.966980,261.476593 455.990479,253.661011 
	C455.330109,252.537262 454.671783,251.768463 454.007935,250.666382 
	C454.002045,249.888626 454.001617,249.444168 454.270874,248.769882 
	C455.362335,245.280182 454.574890,242.889420 450.802917,241.719849 
	C449.409271,239.179871 448.319458,236.860260 447.003479,234.677002 
	C445.809692,232.696442 444.369598,230.864380 443.038086,228.966827 
	C442.719635,227.873795 442.401154,226.780746 442.293854,225.350937 
	C442.675323,225.012253 442.845612,225.010330 443.015869,225.008423 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M514.982910,300.992188 
	C514.098083,302.304749 513.144958,304.772766 512.340454,304.725281 
	C509.232544,304.541748 505.380524,306.911469 503.487427,302.372070 
	C505.206329,301.432495 506.557709,300.878845 507.909058,300.325226 
	C507.289703,299.217804 506.670349,298.110382 506.022949,296.608704 
	C500.695526,282.883911 495.452972,269.530396 490.079437,256.229828 
	C485.459778,244.795166 480.690521,233.420929 475.893433,221.797302 
	C475.615265,221.296310 475.367035,221.098358 475.040649,220.651733 
	C475.017609,219.883698 475.008636,219.444733 475.341553,218.947845 
	C475.456024,216.925980 475.228638,214.961990 475.001221,212.998016 
	C494.022430,212.994705 513.043640,212.991409 532.534058,213.374771 
	C533.006348,214.272644 533.009338,214.783859 532.563721,215.447403 
	C514.878357,215.599731 497.641754,215.599731 479.701508,215.599731 
	C489.386475,238.805801 498.659088,261.023773 507.892578,283.621887 
	C507.853485,287.030792 507.853485,290.059570 507.853485,293.088379 
	C508.574860,293.082703 509.296265,293.077026 510.017639,293.071350 
	C510.017639,289.729858 510.017639,286.388397 510.082397,282.679596 
	C518.693726,261.654663 527.266113,241.007629 535.733826,220.317749 
	C536.354309,218.801758 536.218262,216.976120 536.767029,215.126434 
	C537.396240,214.298996 537.692383,213.639557 537.988525,212.980133 
	C538.155579,212.972717 538.322693,212.965302 538.965332,213.232452 
	C540.660522,214.370895 541.880188,215.234741 543.084961,216.334366 
	C543.070129,216.570145 543.047913,217.042084 542.700562,217.034485 
	C538.172241,218.029617 541.192383,219.606506 541.985718,221.372437 
	C540.913086,224.158295 539.821655,226.555130 538.880005,229.009476 
	C535.285034,238.380142 531.951172,247.857224 528.122681,257.130615 
	C522.605774,270.493896 516.742554,283.714203 510.748230,297.236267 
	C510.355560,298.839447 510.241791,300.201843 510.090973,302.007446 
	C512.182800,301.573303 513.582886,301.282745 514.982910,300.992188 
z"/>
<path fill="#061421" opacity="1.000000" stroke="none" 
	d="
M506.051025,297.002960 
	C506.670349,298.110382 507.289703,299.217804 507.909058,300.325226 
	C506.557709,300.878845 505.206329,301.432495 503.444824,301.981567 
	C495.453125,284.204346 487.871521,266.431732 480.104309,247.956619 
	C478.280457,243.168777 476.642181,239.083405 475.003906,234.998016 
	C472.550598,230.435410 470.097260,225.872803 467.334839,221.138062 
	C466.735535,218.936584 466.445312,216.907242 466.071106,214.290833 
	C461.526398,214.069901 456.557129,213.828339 451.293304,213.291656 
	C457.262085,212.809677 463.524658,212.581696 469.789307,212.466919 
	C471.233459,212.440445 472.684723,212.801804 474.566956,212.991348 
	C475.228638,214.961990 475.456024,216.925980 475.035156,218.781662 
	C473.286987,217.744308 472.187012,216.815247 471.087036,215.886185 
	C470.563110,216.243271 470.039185,216.600372 469.515228,216.957458 
	C471.350342,220.842438 473.358521,224.656433 474.986694,228.626282 
	C479.150879,238.779114 483.018768,249.054352 487.242157,259.181763 
	C492.005463,270.603821 496.948120,281.953705 502.030609,293.236847 
	C502.726746,294.782288 504.679871,295.761566 506.051025,297.002960 
z"/>
<path fill="#061421" opacity="1.000000" stroke="none" 
	d="
M515.143799,300.680054 
	C513.582886,301.282745 512.182800,301.573303 510.090973,302.007446 
	C510.241791,300.201843 510.355560,298.839447 511.119812,297.237366 
	C513.455811,295.394989 515.862732,294.099121 516.698303,292.134888 
	C519.810364,284.819031 522.261963,277.225220 525.247070,269.851410 
	C527.223938,264.968231 529.839661,260.346802 531.903870,255.495178 
	C536.747681,244.110489 541.404907,232.646500 546.198303,221.240097 
	C547.570923,217.973831 547.788940,215.612244 543.099792,216.098587 
	C541.880188,215.234741 540.660522,214.370895 539.216187,213.230743 
	C543.742126,212.964813 548.492798,212.975159 553.641479,213.014099 
	C553.387756,214.829651 553.256104,217.217163 551.999390,218.305191 
	C544.376831,224.904541 543.060303,234.674026 539.236816,243.128845 
	C536.296570,249.630600 534.470947,256.626587 531.810852,263.267059 
	C527.313843,274.493317 522.580017,285.625427 517.855652,296.758911 
	C517.293335,298.084137 516.168640,299.170746 515.143799,300.680054 
z"/>
<path fill="#061421" opacity="1.000000" stroke="none" 
	d="
M574.955566,213.497498 
	C569.587097,213.787354 564.094177,213.699875 558.300537,213.326904 
	C563.610291,213.067642 569.220703,213.093887 574.955566,213.497498 
z"/>
<path fill="#061421" opacity="1.000000" stroke="none" 
	d="
M533.012329,215.295074 
	C533.009338,214.783859 533.006348,214.272644 533.002930,213.377899 
	C534.387695,212.989258 535.772888,212.984146 537.573364,212.979584 
	C537.692383,213.639557 537.396240,214.298996 536.448730,215.290405 
	C534.869080,215.513275 533.940674,215.404175 533.012329,215.295074 
z"/>
<path fill="#061421" opacity="1.000000" stroke="none" 
	d="
M444.786926,212.736557 
	C443.809875,213.165787 442.617340,213.341904 441.212402,213.259705 
	C442.190460,212.828751 443.380951,212.656097 444.786926,212.736557 
z"/>
<path fill="#061421" opacity="1.000000" stroke="none" 
	d="
M443.022949,229.368835 
	C444.369598,230.864380 445.809692,232.696442 447.003479,234.677002 
	C448.319458,236.860260 449.409271,239.179871 450.803955,242.087891 
	C452.007294,244.823700 453.004242,246.911697 454.001221,248.999710 
	C454.001617,249.444168 454.002045,249.888626 454.027283,250.988403 
	C454.698914,252.434464 455.345734,253.225220 455.992554,254.015961 
	C458.966980,261.476593 461.886627,268.959747 464.936676,276.389343 
	C466.736877,280.774445 468.814819,285.045013 470.641693,289.419800 
	C473.807495,297.000885 476.875336,304.622925 479.993774,312.952454 
	C479.993378,314.132660 479.983490,314.588287 479.973633,315.043884 
	C479.022247,314.274353 477.690613,313.697968 477.184875,312.702118 
	C474.919159,308.240692 472.698608,303.736664 470.823273,299.101746 
	C468.681366,293.807983 467.117798,288.278992 464.948486,282.997833 
	C462.071381,275.993469 458.770844,269.164246 455.803345,262.195435 
	C453.363312,256.465332 451.118164,250.651505 448.833954,244.856110 
	C446.857391,239.841278 444.947266,234.800262 443.022949,229.368835 
z"/>
<path fill="#060B10" opacity="1.000000" stroke="none" 
	d="
M482.001221,316.998779 
	C482.664703,318.086823 483.328156,319.174866 483.985107,320.640747 
	C483.247040,321.008881 482.515472,320.999146 481.419250,320.980774 
	C481.032532,320.535950 481.010406,320.099792 481.230408,319.170258 
	C481.641052,318.125031 481.809570,317.573181 481.983917,317.015625 
	C481.989746,317.009979 482.001221,316.998779 482.001221,316.998779 
z"/>
<path fill="#061421" opacity="1.000000" stroke="none" 
	d="
M442.974030,224.669037 
	C442.845612,225.010330 442.675323,225.012253 442.249573,225.010223 
	C441.343445,224.216278 440.692780,223.426285 440.030273,222.311432 
	C440.989685,222.767593 441.960938,223.548630 442.974030,224.669037 
z"/>
<path fill="#060B10" opacity="1.000000" stroke="none" 
	d="
M480.204590,315.211243 
	C479.983490,314.588287 479.993378,314.132660 480.006531,313.335632 
	C480.673035,314.078827 481.336273,315.163422 482.000366,316.623413 
	C482.001221,316.998779 481.989746,317.009979 481.709229,317.007568 
	C481.245331,317.000916 481.061981,316.996674 480.878662,316.992462 
	C480.730957,316.454498 480.583252,315.916565 480.204590,315.211243 
z"/>
<path fill="#061421" opacity="1.000000" stroke="none" 
	d="
M480.674683,317.152588 
	C481.061981,316.996674 481.245331,317.000916 481.703369,317.013245 
	C481.809570,317.573181 481.641052,318.125031 481.226685,318.842407 
	C480.810822,318.442841 480.640778,317.877808 480.674683,317.152588 
z"/>
<path fill="#060B10" opacity="1.000000" stroke="none" 
	d="
M484.026001,322.321350 
	C484.664612,322.764221 485.326752,323.528412 485.991852,324.646240 
	C485.346375,324.214142 484.697937,323.428436 484.026001,322.321350 
z"/>
<path fill="#B8DDFA" opacity="1.000000" stroke="none" 
	d="
M498.049530,163.490295 
	C501.449036,171.287552 505.486725,173.065918 514.244934,171.519592 
	C518.284973,170.806259 522.515564,170.950958 526.651550,171.049713 
	C534.264587,171.231537 541.875488,171.601425 549.477295,172.058533 
	C550.811279,172.138748 552.096558,173.028122 553.724976,173.770325 
	C553.937866,174.552139 553.830139,175.107407 553.614075,175.921753 
	C547.739258,176.433838 541.967346,176.978302 536.207458,176.872391 
	C527.294006,176.708511 518.337646,176.542145 509.498535,175.519928 
	C503.937653,174.876846 498.557678,172.668808 492.832550,170.845428 
	C493.430725,169.133698 494.292908,167.739380 495.516510,165.940369 
	C496.601807,164.853882 497.325653,164.172089 498.049530,163.490295 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M498.182373,163.121323 
	C497.325653,164.172089 496.601807,164.853882 495.582092,165.640701 
	C495.032166,165.296875 494.778107,164.848007 494.208954,164.168320 
	C495.261780,162.996399 496.629669,162.055313 498.420654,161.203003 
	C498.667572,161.778625 498.491394,162.265488 498.182373,163.121323 
z"/>
<path fill="#FAF9FD" opacity="1.000000" stroke="none" 
	d="
M611.460144,616.457886 
	C609.827393,613.604004 608.493896,610.513672 607.160400,607.423401 
	C609.458557,606.801636 611.756714,606.179932 615.299805,605.221436 
	C613.811462,609.845703 612.785461,613.033630 611.460144,616.457886 
z"/>
<path fill="#C8E3EB" opacity="1.000000" stroke="none" 
	d="
M127.999695,593.009766 
	C129.134033,591.439026 130.268387,589.868225 131.697815,588.151123 
	C132.927841,588.322388 133.998199,589.111694 134.777618,588.887817 
	C149.274063,584.725098 164.076996,588.090027 178.686569,586.991821 
	C179.666046,586.918152 180.637558,586.590515 181.610001,586.602661 
	C183.502106,586.626221 185.392258,586.807373 187.750427,587.180725 
	C188.483932,587.632812 188.750198,587.827393 189.016479,588.021973 
	C190.011353,593.729431 191.006226,599.436951 192.002991,605.570984 
	C188.952881,605.485535 185.903748,604.955200 182.846863,604.474060 
	C182.058228,604.349976 181.242310,604.315857 180.443878,604.351501 
	C176.281937,604.536987 172.121033,604.747681 167.960876,604.970764 
	C164.958374,605.131775 161.957657,605.325989 157.940811,605.566040 
	C155.847061,605.184509 152.611389,604.199463 149.344177,604.080505 
	C142.216827,603.820984 135.073410,604.002014 127.467567,603.578003 
	C127.332275,599.771912 127.665985,596.390808 127.999695,593.009766 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M127.635330,592.936646 
	C127.665985,596.390808 127.332275,599.771912 126.998856,603.580139 
	C125.153954,607.429688 123.050697,610.743408 121.547974,614.310059 
	C120.415321,616.998413 120.051811,620.010803 119.113419,623.823730 
	C111.599655,617.396301 113.171738,609.933105 112.999344,602.477844 
	C113.642960,600.245544 114.286865,598.476501 114.968040,596.354004 
	C116.997169,593.576965 118.989029,591.153320 121.319305,588.320801 
	C122.442955,586.941406 123.228203,585.970764 124.013443,585.000183 
	C125.099289,587.621338 126.185135,590.242432 127.635330,592.936646 
z"/>
<path fill="#CACCCF" opacity="1.000000" stroke="none" 
	d="
M189.261932,587.831177 
	C188.750198,587.827393 188.483932,587.632812 188.103439,587.215454 
	C188.163315,586.439087 188.337402,585.885437 188.756348,585.164673 
	C189.169937,585.878479 189.338669,586.759399 189.261932,587.831177 
z"/>
<path fill="#98C7CF" opacity="1.000000" stroke="none" 
	d="
M141.456055,560.992004 
	C140.993576,559.490723 140.904282,557.985779 141.024796,556.497864 
	C141.728973,547.803040 143.707932,545.995239 152.399994,545.995117 
	C156.891403,545.995056 161.382812,545.993774 165.874222,545.995361 
	C174.177673,545.998291 175.468231,546.928833 177.733353,554.493591 
	C178.018051,555.444336 178.376648,556.372986 178.848557,557.655884 
	C175.449356,560.948975 171.297577,561.164062 166.903717,561.057800 
	C158.582230,560.856567 150.252487,560.997437 141.456055,560.992004 
z"/>
<path fill="#F8F9FA" opacity="1.000000" stroke="none" 
	d="
M192.021500,572.423218 
	C193.702927,572.022034 195.391006,572.029358 197.495895,572.061890 
	C197.912689,578.816223 197.912689,585.545349 197.912689,592.274475 
	C197.380539,592.351318 196.848389,592.428162 196.316238,592.505005 
	C194.886887,585.947205 193.457520,579.389465 192.021500,572.423218 
z"/>
<path fill="#9FCBD1" opacity="1.000000" stroke="none" 
	d="
M201.445862,562.996704 
	C198.395126,560.453125 194.792770,558.453369 196.364212,553.453003 
	C200.750809,554.375916 204.711594,556.467590 208.808167,556.780273 
	C214.530701,557.216919 220.351395,556.153503 226.135925,556.046021 
	C233.348419,555.911926 240.728317,556.939697 246.989105,552.004883 
	C246.513931,555.164612 246.038742,558.324341 245.279846,561.740845 
	C239.691467,562.820923 234.411362,563.925903 229.075500,564.390991 
	C223.352448,564.889832 217.568359,565.042236 211.827621,564.849182 
	C208.497223,564.737183 205.199707,563.647827 201.445862,562.996704 
z"/>
<path fill="#F8F9FA" opacity="1.000000" stroke="none" 
	d="
M124.022736,584.598755 
	C123.228203,585.970764 122.442955,586.941406 121.330048,587.954773 
	C121.013519,583.664368 120.889656,579.323914 121.119415,575.002380 
	C121.186935,573.732361 122.005264,572.225037 122.968353,571.390930 
	C123.488815,570.940186 125.786537,571.411499 125.977837,571.939514 
	C126.414368,573.144348 126.227646,574.675293 125.968910,576.010742 
	C125.436005,578.761475 124.690216,581.470886 124.022736,584.598755 
z"/>
<path fill="#98C7CF" opacity="1.000000" stroke="none" 
	d="
M246.986099,551.609375 
	C240.728317,556.939697 233.348419,555.911926 226.135925,556.046021 
	C220.351395,556.153503 214.530701,557.216919 208.808167,556.780273 
	C204.711594,556.467590 200.750809,554.375916 196.365936,553.041382 
	C198.248718,545.005249 198.248718,545.005005 206.280014,545.004883 
	C217.235046,545.004700 228.199127,545.265930 239.141052,544.887207 
	C244.102600,544.715393 246.705322,546.196777 246.986099,551.609375 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M337.040619,593.339722 
	C337.546539,594.168701 338.035797,595.365601 338.259277,596.781250 
	C337.670319,595.902588 337.347168,594.805176 337.040619,593.339722 
z"/>
<path fill="#B8DBE1" opacity="1.000000" stroke="none" 
	d="
M811.574341,573.011108 
	C811.533875,579.614746 809.751892,581.484619 803.438354,581.982117 
	C802.780945,582.033997 802.103455,581.914001 801.457397,582.015015 
	C795.330444,582.973999 790.714600,581.466614 789.143677,574.337158 
	C794.613403,570.144043 800.783020,572.394653 806.743347,572.440002 
	C808.209839,572.451172 809.673767,572.807495 811.574341,573.011108 
z"/>
<path fill="#9FCBD1" opacity="1.000000" stroke="none" 
	d="
M618.292480,553.222046 
	C619.226318,545.810242 620.146729,545.008179 627.274597,545.006836 
	C643.271667,545.003662 659.268738,545.005737 675.265808,545.008240 
	C682.850830,545.009460 684.688782,546.826599 684.992493,554.631592 
	C685.024841,555.463501 684.994812,556.297729 684.987549,557.565125 
	C681.466858,561.553650 677.322449,562.588501 672.473633,561.370300 
	C670.901367,560.975220 669.196106,561.020386 667.551331,561.016785 
	C655.379028,560.990356 643.197571,560.724243 631.037964,561.117920 
	C624.806213,561.319702 620.368469,559.832397 618.292480,553.222046 
z"/>
<path fill="#88C9EF" opacity="1.000000" stroke="none" 
	d="
M84.957527,411.065643 
	C81.511101,408.857056 79.272568,400.883698 81.491127,397.640656 
	C82.153877,396.671844 83.990044,396.051239 85.293152,396.043549 
	C103.122917,395.938202 120.953743,396.016571 138.783646,395.924683 
	C142.352524,395.906311 143.938416,397.988129 144.760056,400.858795 
	C145.438263,403.228302 145.620682,405.739777 145.735443,408.851013 
	C144.462021,415.763947 143.016876,416.975464 136.286331,416.990570 
	C124.984497,417.015930 113.667953,416.657715 102.386932,417.142853 
	C96.871117,417.380066 93.022469,415.004852 89.007668,412.038971 
	C87.979225,411.279175 86.324516,411.367157 84.957527,411.065643 
z"/>
<path fill="#76C0DF" opacity="1.000000" stroke="none" 
	d="
M84.994484,411.396271 
	C86.324516,411.367157 87.979225,411.279175 89.007668,412.038971 
	C93.022469,415.004852 96.871117,417.380066 102.386932,417.142853 
	C113.667953,416.657715 124.984497,417.015930 136.286331,416.990570 
	C143.016876,416.975464 144.462021,415.763947 145.736908,409.264893 
	C147.689987,416.050598 149.354248,423.085083 150.684174,430.515289 
	C149.106552,430.639465 147.816589,430.013062 146.629608,430.169281 
	C144.053055,430.508392 141.505661,431.815216 138.988297,431.711212 
	C132.639023,431.448914 126.312973,430.624695 119.764297,429.729858 
	C118.594719,427.644012 117.638474,425.853912 116.682220,424.063812 
	C116.302734,424.101074 115.923248,424.138336 115.543762,424.175598 
	C115.356888,425.784454 115.170006,427.393341 114.587265,429.002197 
	C112.796684,429.369110 110.796043,429.281860 110.118286,430.186157 
	C107.882172,433.169556 104.915482,433.353180 101.816444,432.947784 
	C98.511147,432.515411 95.259682,431.671539 91.982224,430.563538 
	C91.635651,426.400177 91.444977,422.655029 90.832207,418.980255 
	C90.691101,418.134094 89.102226,417.529388 88.179596,416.813538 
	C87.700569,416.210571 87.221535,415.607635 86.449875,414.922943 
	C85.781990,413.803101 85.406715,412.765015 84.994484,411.396271 
z"/>
<path fill="#50ABC1" opacity="1.000000" stroke="none" 
	d="
M158.013641,454.948181 
	C159.114670,455.269501 160.215698,455.590851 161.882843,456.156799 
	C163.345612,456.908600 164.242264,457.415771 165.500153,457.945007 
	C170.919586,457.033539 169.772461,451.864929 172.238922,449.254456 
	C178.344193,449.685638 184.197998,449.853302 190.040833,450.440491 
	C190.019928,454.577332 190.009995,458.294617 189.990967,462.372589 
	C188.985870,464.491699 187.989883,466.250122 186.596970,467.996765 
	C181.564560,466.989227 176.958130,465.340668 172.287582,465.132812 
	C162.361206,464.690948 152.396637,464.819824 142.457855,465.089050 
	C138.625626,465.192871 134.820908,466.313385 130.841370,466.626129 
	C130.112213,462.519440 129.545334,458.757355 129.392578,454.944946 
	C138.166397,455.363495 146.523926,455.884460 154.888626,456.233887 
	C155.910614,456.276581 156.971024,455.399170 158.013641,454.948181 
z"/>
<path fill="#64B6D0" opacity="1.000000" stroke="none" 
	d="
M119.977615,430.025635 
	C126.312973,430.624695 132.639023,431.448914 138.988297,431.711212 
	C141.505661,431.815216 144.053055,430.508392 146.629608,430.169281 
	C147.816589,430.013062 149.106552,430.639465 150.682739,430.963806 
	C152.016327,434.398132 153.017029,437.779633 153.586273,441.579102 
	C143.761520,442.670288 134.368225,443.343475 124.823563,443.669617 
	C123.107346,438.890289 121.542480,434.457947 119.977615,430.025635 
z"/>
<path fill="#59B1C8" opacity="1.000000" stroke="none" 
	d="
M124.974915,444.016663 
	C134.368225,443.343475 143.761520,442.670288 153.586060,441.992371 
	C155.352127,446.041504 156.686935,450.095337 158.017700,454.548676 
	C156.971024,455.399170 155.910614,456.276581 154.888626,456.233887 
	C146.523926,455.884460 138.166397,455.363495 129.230377,454.624695 
	C127.427696,450.908691 126.201302,447.462677 124.974915,444.016663 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M165.138916,457.922943 
	C164.242264,457.415771 163.345612,456.908600 162.228409,456.180908 
	C162.038940,454.900482 162.070007,453.840515 162.138184,452.382660 
	C162.731476,451.523682 163.830811,450.944611 163.760223,450.619720 
	C163.022232,447.223389 162.096649,443.867828 161.455933,441.398529 
	C163.226990,439.066040 164.553543,437.318970 165.778015,435.190796 
	C165.824127,433.521057 165.972321,432.232391 166.559357,430.511169 
	C168.294846,430.061218 169.591476,430.043793 170.905518,430.440491 
	C170.614319,432.804749 170.406540,434.777222 169.979050,436.700897 
	C168.405090,443.783661 166.758331,450.850250 165.138916,457.922943 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M145.114365,393.218811 
	C145.356247,393.067932 145.626495,393.248260 145.918518,393.764832 
	C145.677399,393.909424 145.411560,393.725769 145.114365,393.218811 
z"/>
<path fill="#258697" opacity="1.000000" stroke="none" 
	d="
M126.064888,504.033722 
	C126.070450,505.732697 126.076019,507.431641 125.712234,509.604614 
	C122.733002,515.112183 118.367058,516.366455 113.080734,516.045959 
	C111.391594,515.943420 109.654274,516.635315 107.938995,516.963745 
	C107.212555,516.962219 106.486107,516.960632 105.397331,516.956482 
	C103.492630,516.629883 101.963394,516.129822 100.405533,516.013733 
	C94.874763,515.601501 89.324844,515.429871 83.800270,514.956177 
	C79.100700,514.553162 80.226128,510.785614 80.057831,507.033508 
	C80.762856,503.156372 81.423859,500.095551 82.084854,497.034698 
	C83.561058,496.877716 85.037270,496.720734 86.759888,496.290405 
	C88.127480,495.434235 89.248665,494.851349 91.039337,494.168915 
	C96.446083,497.941284 102.077309,497.041351 107.523682,496.958740 
	C111.356499,496.900635 113.761185,495.315674 113.268265,491.075745 
	C114.071953,491.459473 114.603691,491.723511 115.378281,492.167419 
	C116.410713,493.226349 117.200294,494.105377 118.038666,495.336487 
	C119.981270,497.948181 121.832169,500.246185 123.794731,502.444489 
	C124.393547,503.115204 125.299576,503.511658 126.064888,504.033722 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M81.667900,497.034760 
	C81.423859,500.095551 80.762856,503.156372 80.055588,506.604431 
	C79.117622,503.509888 76.998276,499.875061 81.667900,497.034760 
z"/>
<path fill="#3099A8" opacity="1.000000" stroke="none" 
	d="
M112.996307,490.956055 
	C113.761185,495.315674 111.356499,496.900635 107.523682,496.958740 
	C102.077309,497.041351 96.446083,497.941284 91.327484,494.018494 
	C91.260262,489.587982 91.574387,485.208252 92.320084,480.410278 
	C96.909775,477.720642 101.569008,476.154572 105.690796,478.749481 
	C108.311523,480.399414 110.400734,480.389435 112.979340,480.426086 
	C112.998428,484.243683 112.997368,487.599884 112.996307,490.956055 
z"/>
<path fill="#40A3B3" opacity="1.000000" stroke="none" 
	d="
M112.959175,479.964691 
	C110.400734,480.389435 108.311523,480.399414 105.690796,478.749481 
	C101.569008,476.154572 96.909775,477.720642 92.377899,479.998413 
	C91.667435,475.307312 91.330719,470.609711 90.988022,465.466370 
	C90.982033,465.020599 91.017273,465.005920 91.468155,465.002625 
	C98.608849,464.995087 105.298660,464.990814 111.996323,465.436096 
	C112.322517,470.578674 112.640846,475.271667 112.959175,479.964691 
z"/>
<path fill="#64B6D0" opacity="1.000000" stroke="none" 
	d="
M91.984711,431.007324 
	C95.259682,431.671539 98.511147,432.515411 101.816444,432.947784 
	C104.915482,433.353180 107.882172,433.169556 110.118286,430.186157 
	C110.796043,429.281860 112.796684,429.369110 114.627762,429.342407 
	C115.377480,430.770813 115.690834,431.859039 116.016891,433.290771 
	C115.989433,434.090271 115.949280,434.546295 115.543442,435.047485 
	C113.786491,439.397186 112.395226,443.701630 110.588257,448.057190 
	C103.798744,448.404602 97.424919,448.700958 91.051102,448.997314 
	C91.033615,447.601624 91.016136,446.205902 91.287262,444.105469 
	C91.712143,439.269592 91.848427,435.138458 91.984711,431.007324 
z"/>
<path fill="#50ABC1" opacity="1.000000" stroke="none" 
	d="
M91.035156,449.464233 
	C97.424919,448.700958 103.798744,448.404602 110.590691,448.510010 
	C111.335365,454.270050 111.661911,459.628296 111.988464,464.986572 
	C105.298660,464.990814 98.608849,464.995087 91.486397,464.997864 
	C91.042236,459.974609 91.030724,454.952881 91.035156,449.464233 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M91.982224,430.563538 
	C91.848427,435.138458 91.712143,439.269592 91.280724,443.686707 
	C90.657555,441.986298 90.376350,439.990662 89.986908,438.016357 
	C89.666328,436.391205 89.217407,434.791351 88.444519,433.078766 
	C88.050308,432.533203 88.036949,432.089142 88.040237,431.311584 
	C88.153229,426.535858 88.249565,422.093628 88.262749,417.232483 
	C89.102226,417.529388 90.691101,418.134094 90.832207,418.980255 
	C91.444977,422.655029 91.635651,426.400177 91.982224,430.563538 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M108.412628,516.983398 
	C109.654274,516.635315 111.391594,515.943420 113.080734,516.045959 
	C118.367058,516.366455 122.733002,515.112183 125.702515,510.029175 
	C126.062134,509.979706 126.201752,510.432495 126.178223,510.955109 
	C126.403664,511.978485 126.652626,512.479248 126.901588,512.979980 
	C126.764610,514.156128 126.627647,515.332336 126.237122,516.757446 
	C120.284477,517.005249 114.585373,517.004089 108.412628,516.983398 
z"/>
<path fill="#9FCBD1" opacity="1.000000" stroke="none" 
	d="
M944.995117,555.997559 
	C944.997375,558.693298 944.999695,561.389099 944.998535,564.532654 
	C943.247681,566.820374 941.522644,570.190308 939.748535,570.216370 
	C934.179260,570.298157 928.514648,572.081970 922.911682,568.330383 
	C918.730042,565.530518 913.330994,564.200745 908.280151,563.138916 
	C902.531006,561.930237 896.576416,561.578552 890.688843,561.223999 
	C888.992798,561.121826 887.221680,562.301025 885.481750,562.883179 
	C882.460083,563.894043 879.442200,565.729614 876.411560,565.752747 
	C866.033020,565.832092 855.647583,565.324463 845.270081,564.909668 
	C843.541870,564.840576 841.793945,564.247620 840.137146,563.664062 
	C837.969543,562.900696 835.883606,561.905823 833.383667,560.999146 
	C833.005066,558.949219 833.005249,556.910645 833.431763,554.435852 
	C842.408569,554.333069 850.956848,554.741760 859.509888,554.982971 
	C873.612183,555.380798 887.717590,555.670105 901.821655,556.006958 
	C902.320312,556.018860 902.818604,556.067017 903.317078,556.066284 
	C917.209778,556.045898 931.102417,556.021362 944.995117,555.997559 
z"/>
<path fill="#98C7CF" opacity="1.000000" stroke="none" 
	d="
M944.997559,555.568176 
	C931.102417,556.021362 917.209778,556.045898 903.317078,556.066284 
	C902.818604,556.067017 902.320312,556.018860 901.821655,556.006958 
	C887.717590,555.670105 873.612183,555.380798 859.509888,554.982971 
	C850.956848,554.741760 842.408569,554.333069 833.435181,553.998596 
	C833.125305,547.406677 835.525635,545.002197 841.978638,545.004333 
	C851.213623,545.007385 860.449036,544.943298 869.683411,545.018616 
	C890.956116,545.192139 912.228271,545.432678 933.500610,545.644531 
	C933.830627,545.647827 934.161438,545.644836 934.490662,545.624939 
	C943.779785,545.065552 944.982178,546.153442 944.997559,555.568176 
z"/>
<path fill="#1D8B97" opacity="1.000000" stroke="none" 
	d="
M945.046326,497.027679 
	C945.072205,502.764496 945.072205,508.490204 945.072205,515.002625 
	C926.313416,515.002625 908.934204,515.123352 891.557495,514.962280 
	C878.941040,514.845276 866.328918,514.306152 853.712891,514.056458 
	C848.799927,513.959167 843.862854,513.897766 838.971924,514.275635 
	C835.520996,514.542236 834.100098,512.583069 832.440796,509.677490 
	C832.405396,508.155609 832.694092,507.067169 832.985901,505.741699 
	C832.989014,505.504700 832.981445,505.030670 833.138672,504.677948 
	C835.100586,502.171692 832.143005,497.632355 837.352051,496.956055 
	C838.371216,496.728119 839.009949,496.362701 839.643921,496.370697 
	C854.101440,496.552429 868.558350,496.774963 883.007690,496.994934 
	C883.000000,497.000000 883.008972,496.984894 883.048401,497.310303 
	C883.742065,498.080414 884.396240,498.525055 885.495361,498.970245 
	C896.951233,498.311127 907.962219,497.651489 919.328735,496.959473 
	C922.377258,494.053101 925.023560,496.943481 927.693359,496.973175 
	C933.450134,497.037109 939.207947,497.000366 944.965332,497.004791 
	C944.965332,497.004791 945.020447,497.016602 945.046326,497.027679 
z"/>
<path fill="#3F7798" opacity="1.000000" stroke="none" 
	d="
M836.969360,496.992615 
	C832.143005,497.632355 835.100586,502.171692 832.978394,504.760559 
	C832.768250,505.456848 832.875549,505.717773 832.982788,505.978729 
	C832.694092,507.067169 832.405396,508.155609 832.079590,509.578796 
	C831.627991,511.217072 831.213562,512.520630 830.448975,513.892761 
	C830.058777,507.618286 830.018860,501.275208 830.230957,494.294373 
	C831.304871,492.764404 832.126648,491.872223 833.285889,490.944580 
	C834.056946,490.220856 834.490662,489.532562 835.060242,488.583801 
	C835.680237,487.513702 836.164368,486.704102 836.939941,485.757660 
	C837.820251,484.742615 838.409058,483.864380 839.302063,482.933105 
	C840.064697,482.251587 840.523132,481.623047 841.256226,480.864014 
	C841.657593,480.149841 841.784241,479.566162 842.192322,478.829285 
	C843.312439,477.439728 844.151123,476.203400 845.291626,474.933624 
	C845.996460,474.868286 846.399536,474.836395 847.000793,475.072662 
	C847.798523,475.572479 848.398071,475.804077 848.992981,476.403259 
	C848.649475,478.184204 848.310608,479.597595 847.701111,481.203186 
	C844.811768,484.569244 842.027649,487.625000 839.641785,490.965240 
	C838.390564,492.716919 837.837585,494.967377 836.969360,496.992615 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M816.036743,493.656738 
	C816.002319,495.047821 815.905945,496.109009 815.705505,497.556335 
	C815.066956,496.448364 814.410645,494.984711 814.027893,493.452759 
	C813.513916,491.395416 813.211243,489.285278 812.415894,486.667877 
	C811.973938,476.411957 811.533569,475.982971 801.595215,475.991211 
	C800.606812,475.992035 799.618408,475.995300 798.630005,475.998077 
	C790.857178,476.019836 789.575989,477.213562 788.632690,485.040771 
	C786.939575,485.057465 785.565125,485.048920 784.127563,484.584808 
	C783.911987,480.797119 783.759583,477.464966 783.713135,473.845276 
	C783.857605,473.356995 783.895935,473.156311 784.170471,472.857117 
	C784.580688,472.474426 784.754761,472.190216 785.348389,471.866943 
	C794.091370,471.799713 802.414734,471.771545 811.132690,471.832275 
	C815.888367,472.337433 815.295776,475.929138 815.901733,479.158752 
	C815.951477,480.373047 816.004395,481.179016 816.010620,482.359558 
	C815.994385,483.487061 816.024780,484.240021 815.979492,485.324036 
	C815.945496,486.103424 815.987183,486.551758 816.028870,487.000061 
	C815.993042,487.446472 815.957214,487.892914 815.880493,489.043335 
	C815.896362,490.494232 815.953125,491.241150 816.009827,491.988068 
	C815.998169,492.434357 815.986572,492.880615 816.036743,493.656738 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M816.401611,487.003479 
	C815.987183,486.551758 815.945496,486.103424 816.411194,485.324890 
	C821.106201,484.919586 825.298096,484.701538 829.479858,484.824585 
	C831.877808,484.895172 834.259460,485.519470 836.648438,485.894501 
	C836.164368,486.704102 835.680237,487.513702 834.742676,488.656555 
	C833.842346,489.653229 833.395386,490.316620 832.948486,490.980011 
	C832.126648,491.872223 831.304871,492.764404 830.227173,493.825500 
	C825.572327,491.665222 821.173340,489.336090 816.401611,487.003479 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M816.253479,491.804138 
	C815.953125,491.241150 815.896362,490.494232 815.913696,489.378204 
	C816.157532,489.879486 816.327332,490.749878 816.253479,491.804138 
z"/>
<path fill="#3099A8" opacity="1.000000" stroke="none" 
	d="
M944.979492,496.536469 
	C939.207947,497.000366 933.450134,497.037109 927.693359,496.973175 
	C925.023560,496.943481 922.377258,494.053101 919.383789,496.575134 
	C919.703369,491.830536 920.323608,487.437836 920.943848,483.045166 
	C921.198547,476.504761 921.705078,475.864410 928.289490,476.054871 
	C932.715210,476.182892 937.228394,476.425873 941.487549,477.483215 
	C942.985901,477.855194 944.632080,480.715851 944.838379,482.591156 
	C945.327271,487.035431 944.994385,491.570038 944.979492,496.536469 
z"/>
<path fill="#3F7798" opacity="1.000000" stroke="none" 
	d="
M920.616577,483.083984 
	C920.323608,487.437836 919.703369,491.830536 919.028137,496.607544 
	C907.962219,497.651489 896.951233,498.311127 885.440186,498.652893 
	C884.296326,497.884949 883.652649,497.434937 883.008972,496.984894 
	C883.008972,496.984894 883.000000,497.000000 882.964539,496.623047 
	C881.093994,490.240723 884.723816,486.192474 888.285278,481.816925 
	C891.351868,479.081726 894.155090,476.555054 896.958313,474.028381 
	C896.968872,474.805542 896.979431,475.582703 896.997314,476.749573 
	C893.078430,482.392090 889.152222,487.644958 884.392822,494.012421 
	C889.130920,494.012421 892.835144,494.013489 896.539429,494.012085 
	C900.529602,494.010590 904.519775,493.995178 908.509888,494.006439 
	C916.375122,494.028595 916.375122,494.034515 916.861328,485.452087 
	C918.043640,484.399750 919.166504,483.761292 920.616577,483.083984 
z"/>
<path fill="#88C9EF" opacity="1.000000" stroke="none" 
	d="
M581.061584,413.083344 
	C577.800781,409.754822 575.765991,400.824310 578.775635,398.383362 
	C581.489563,396.182251 585.913025,395.358978 589.621948,395.248474 
	C601.837036,394.884613 614.071045,395.028656 626.295410,395.174500 
	C630.570007,395.225525 635.112915,395.092255 638.999817,396.507904 
	C641.142700,397.288300 642.569031,400.954132 643.610901,403.597992 
	C644.809875,406.640320 645.227661,409.990479 645.603516,413.608276 
	C640.852295,417.323975 635.777161,417.064911 630.682739,417.026611 
	C618.990906,416.938629 607.297119,417.082397 595.606079,416.953949 
	C591.395935,416.907684 587.191406,416.351227 582.764648,416.005157 
	C582.544922,415.982697 582.112061,415.894623 582.112061,415.894623 
	C582.077881,415.467529 582.043701,415.040466 581.902588,414.083740 
	C581.622314,413.285889 581.377625,413.128998 581.061584,413.083344 
z"/>
<path fill="#76C0DF" opacity="1.000000" stroke="none" 
	d="
M582.984253,416.027618 
	C587.191406,416.351227 591.395935,416.907684 595.606079,416.953949 
	C607.297119,417.082397 618.990906,416.938629 630.682739,417.026611 
	C635.777161,417.064911 640.852295,417.323975 645.605469,414.009094 
	C647.006958,417.411041 648.025269,420.813538 649.090820,424.632782 
	C649.120300,425.467957 649.102600,425.886414 649.051697,427.004211 
	C647.078613,429.452759 645.084412,432.743744 643.208435,432.677795 
	C636.028870,432.425201 628.349976,435.496643 621.676025,430.020447 
	C620.381226,428.957947 617.899841,429.341583 615.843994,428.771973 
	C615.353821,427.615448 614.985413,426.745056 614.664917,425.987915 
	C613.773987,426.339355 612.930664,426.672058 611.940674,426.763306 
	C611.428650,425.522156 611.063110,424.522430 610.483093,422.935974 
	C609.745850,424.363403 609.331787,425.165253 608.554565,425.968079 
	C606.802185,426.993103 605.412903,428.017120 603.660400,429.070557 
	C596.131897,434.481445 589.013245,434.460205 582.002075,428.631439 
	C582.324341,424.160156 582.654297,420.093903 582.984253,416.027618 
M615.734070,423.184540 
	C615.622498,423.028687 615.510864,422.872864 615.399231,422.717010 
	C615.421387,422.934387 615.443542,423.151794 615.734070,423.184540 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M582.764587,416.005157 
	C582.654297,420.093903 582.324341,424.160156 581.982544,429.014893 
	C579.987854,436.209167 578.004883,442.614960 576.012329,449.433167 
	C575.510498,452.031891 575.088379,454.236969 574.508667,456.399841 
	C573.736694,459.279907 572.841980,462.127075 571.738708,464.993286 
	C571.303284,465.003693 571.129089,465.009186 570.575317,465.001038 
	C569.457520,463.648926 568.719360,462.310486 567.981079,460.972015 
	C569.580811,455.233063 571.180481,449.494141 572.703613,443.377808 
	C573.582153,440.000092 574.537170,436.999786 575.532349,433.605347 
	C577.296692,427.753479 579.020996,422.295715 580.775146,416.461914 
	C581.021973,416.050201 581.239014,416.014557 581.784058,415.936768 
	C582.112061,415.894623 582.544922,415.982697 582.764587,416.005157 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M581.154663,413.280792 
	C581.377625,413.128998 581.622314,413.285889 581.874023,413.763641 
	C581.660706,413.889038 581.425842,413.724060 581.154663,413.280792 
z"/>
<path fill="#40A3B3" opacity="1.000000" stroke="none" 
	d="
M570.954956,465.014648 
	C571.129089,465.009186 571.303284,465.003693 572.126221,464.953552 
	C581.134399,465.467194 589.566467,463.699524 597.897339,465.733948 
	C598.820923,465.959442 599.971191,465.256683 601.483765,464.984924 
	C608.961121,464.643829 615.971069,464.302185 623.271912,464.263733 
	C630.984375,464.710907 638.405518,464.890289 645.827759,464.986481 
	C650.888428,465.052032 655.950684,465.001221 661.012207,465.002441 
	C662.352356,469.063629 663.692566,473.124817 664.651978,477.551941 
	C658.518433,477.944122 652.761841,477.849213 647.014099,478.033051 
	C642.003357,478.193329 637.002686,478.667969 631.997559,479.003662 
	C629.140259,478.336700 626.295166,477.164276 623.423401,477.094727 
	C613.601135,476.856781 603.769531,477.006287 593.081543,477.012878 
	C588.185486,478.419708 584.408875,479.182861 579.882141,477.774658 
	C576.294312,476.658508 571.989136,477.848297 568.003967,477.619568 
	C568.988647,473.154846 569.971802,469.084747 570.954956,465.014648 
z"/>
<path fill="#3099A8" opacity="1.000000" stroke="none" 
	d="
M632.035461,479.360718 
	C637.002686,478.667969 642.003357,478.193329 647.014099,478.033051 
	C652.761841,477.849213 658.518433,477.944122 664.654785,477.949951 
	C666.027588,480.730438 667.016724,483.478851 668.024170,486.616974 
	C668.339905,488.432800 668.637451,489.858948 668.601074,491.650208 
	C666.652405,493.035919 665.056091,494.087524 663.416321,495.066101 
	C662.333923,495.712067 661.200134,496.737885 660.070374,496.763763 
	C651.701721,496.955322 643.328186,496.938690 634.956238,496.987488 
	C635.470764,495.132874 635.985229,493.278259 636.238892,491.220886 
	C637.065674,486.425751 634.665222,483.038666 632.035461,479.360718 
z"/>
<path fill="#50ABC1" opacity="1.000000" stroke="none" 
	d="
M661.010986,464.602570 
	C655.950684,465.001221 650.888428,465.052032 645.827759,464.986481 
	C638.405518,464.890289 630.984375,464.710907 623.281250,463.878662 
	C623.658936,460.465942 624.318237,457.741577 625.317139,454.867493 
	C627.702026,454.677704 629.750488,454.696808 631.792053,454.586426 
	C637.859131,454.258270 644.094055,452.091095 649.706909,456.502106 
	C650.189880,456.881622 651.470886,456.286041 652.368958,456.087921 
	C653.927551,455.744080 655.476562,455.356812 657.029602,454.987732 
	C657.805603,455.958405 658.581665,456.929077 659.684937,457.938416 
	C660.344727,460.052307 660.677246,462.127502 661.010986,464.602570 
z"/>
<path fill="#64B6D0" opacity="1.000000" stroke="none" 
	d="
M615.965820,429.058167 
	C617.899841,429.341583 620.381226,428.957947 621.676025,430.020447 
	C628.349976,435.496643 636.028870,432.425201 643.208435,432.677795 
	C645.084412,432.743744 647.078613,429.452759 649.054382,427.318909 
	C649.719788,427.584442 650.779419,428.159180 650.908813,428.897095 
	C651.736145,433.614960 652.359009,438.368622 652.686584,443.702576 
	C649.643188,444.141724 646.962585,443.982819 644.281311,443.838562 
	C643.043152,443.771912 641.803101,443.683899 640.564575,443.692657 
	C637.689087,443.712952 634.804138,443.656891 631.941101,443.871918 
	C628.612549,444.121826 625.303345,444.628143 621.830688,444.689941 
	C619.772339,439.257202 617.869080,434.157684 615.965820,429.058167 
z"/>
<path fill="#59B1C8" opacity="1.000000" stroke="none" 
	d="
M621.985718,445.023163 
	C625.303345,444.628143 628.612549,444.121826 631.941101,443.871918 
	C634.804138,443.656891 637.689087,443.712952 640.564575,443.692657 
	C641.803101,443.683899 643.043152,443.771912 644.281311,443.838562 
	C646.962585,443.982819 649.643188,444.141724 652.695312,444.134979 
	C656.900879,446.388641 657.021240,450.245819 657.025879,454.568604 
	C655.476562,455.356812 653.927551,455.744080 652.368958,456.087921 
	C651.470886,456.286041 650.189880,456.881622 649.706909,456.502106 
	C644.094055,452.091095 637.859131,454.258270 631.792053,454.586426 
	C629.750488,454.696808 627.702026,454.677704 625.039307,454.608124 
	C623.609863,451.340027 622.797791,448.181580 621.985718,445.023163 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M680.947388,493.704865 
	C679.923706,494.058685 678.845398,494.058350 677.362793,494.021484 
	C677.864990,491.261719 679.209839,491.257599 680.947388,493.704865 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M685.734985,488.825714 
	C685.734924,488.825714 685.356873,488.874268 685.186890,488.960632 
	C684.798157,488.945618 684.579407,488.844238 684.071045,488.520966 
	C684.190369,487.836395 684.599304,487.373749 685.400269,487.215088 
	C685.773193,487.954620 685.754089,488.390167 685.734985,488.825714 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M686.092957,488.870728 
	C685.754089,488.390167 685.773193,487.954620 685.655518,487.221313 
	C686.016663,485.858154 686.514648,484.792755 687.094238,483.381226 
	C687.742798,482.993713 688.309753,482.952362 689.479858,483.020813 
	C690.359009,483.458466 690.635010,483.786316 690.911011,484.114197 
	C689.424316,485.714722 687.937622,487.315247 686.092957,488.870728 
z"/>
<path fill="#3099A8" opacity="1.000000" stroke="none" 
	d="
M517.997559,479.990234 
	C518.107544,480.313751 518.300354,480.576538 518.799316,480.886047 
	C519.008179,485.685089 518.993652,490.376617 518.557556,495.591736 
	C509.262909,496.411835 500.389679,496.703766 491.516815,497.006561 
	C486.361420,497.182526 481.365021,497.158691 477.155457,493.240753 
	C476.033325,492.196442 473.763611,492.385223 472.020752,492.007843 
	C467.854980,488.510712 464.565002,484.481110 464.450867,478.403442 
	C482.606567,478.667755 500.302063,479.328979 517.997559,479.990234 
z"/>
<path fill="#40A3B3" opacity="1.000000" stroke="none" 
	d="
M518.004150,479.525146 
	C500.302063,479.328979 482.606567,478.667755 464.438660,478.014771 
	C463.057434,477.529999 462.148651,477.036987 461.095490,475.886658 
	C459.617767,472.172455 458.284424,469.115601 456.951111,466.058746 
	C457.661591,465.712769 458.513428,464.917084 459.061035,465.089203 
	C465.783905,467.202209 472.535675,465.045135 479.121002,464.622681 
	C484.740173,464.262207 490.079987,464.260345 495.669250,464.826782 
	C502.733093,465.542664 509.924438,465.000305 517.529175,465.002167 
	C518.001953,469.689148 518.006348,474.374603 518.004150,479.525146 
z"/>
<path fill="#258697" opacity="1.000000" stroke="none" 
	d="
M472.013916,492.392700 
	C473.763611,492.385223 476.033325,492.196442 477.155457,493.240753 
	C481.365021,497.158691 486.361420,497.182526 491.516815,497.006561 
	C500.389679,496.703766 509.262909,496.411835 518.545776,496.057709 
	C518.972290,500.308319 518.833374,504.624268 519.047485,508.922668 
	C519.282227,513.636536 516.694885,515.758484 512.545898,515.905579 
	C505.429657,516.157959 498.264954,516.385681 491.183716,515.825500 
	C486.211060,515.432190 481.868011,513.447693 479.908295,507.755280 
	C478.608124,503.978577 475.762878,500.748566 473.745300,497.196564 
	C472.971405,495.834045 472.575287,494.256927 472.013916,492.392700 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M456.606659,466.007935 
	C458.284424,469.115601 459.617767,472.172455 460.945648,475.597565 
	C457.867004,473.016663 454.790802,470.070648 451.722992,467.115875 
	C451.035522,466.453766 450.377472,465.761139 449.370270,465.037109 
	C449.034668,464.991699 449.001007,464.991943 448.975281,464.628113 
	C447.626953,462.852509 446.304321,461.440704 444.989624,460.017273 
	C444.997559,460.005615 444.970978,460.012512 444.988525,459.650177 
	C444.297760,458.549927 443.589447,457.812012 442.612244,456.900482 
	C441.555725,455.312103 440.768066,453.897308 439.996399,452.120544 
	C439.998688,451.518616 439.984955,451.278687 440.229279,450.866547 
	C441.983368,450.806213 443.479462,450.918060 444.975525,451.029877 
	C448.737762,456.005646 452.500000,460.981384 456.606659,466.007935 
z"/>
<path fill="#258697" opacity="1.000000" stroke="none" 
	d="
M439.224670,516.616821 
	C437.287262,516.917297 435.595367,516.937744 433.482941,516.953857 
	C430.327393,516.624084 427.597351,516.080383 424.856323,516.017456 
	C418.696686,515.875977 412.531433,515.999573 406.368774,515.964600 
	C401.493103,515.936890 398.919342,513.542969 399.100159,508.680206 
	C399.209381,505.742737 399.664581,502.664337 400.770844,499.981903 
	C401.333038,498.618652 403.678955,497.990967 405.606812,497.413391 
	C405.537262,498.818848 405.346649,500.161346 404.573822,500.807220 
	C401.725830,503.187408 401.253326,506.246216 402.407043,509.329163 
	C403.707367,512.803772 406.896790,513.100891 410.272675,513.051208 
	C417.553955,512.943787 424.839508,513.171204 432.123444,513.180542 
	C435.348724,513.184692 438.857697,513.734863 441.707733,512.647522 
	C443.886108,511.816345 446.716217,508.684326 446.568207,506.785278 
	C446.385437,504.440155 443.610321,502.109253 441.569305,500.180817 
	C440.632446,499.295654 438.616699,499.658905 437.384644,498.946686 
	C436.364319,498.356873 435.776093,497.019562 434.959229,495.647766 
	C434.612640,493.863159 434.303253,492.444214 434.326416,491.024902 
	C435.101898,491.038879 435.544861,491.053192 435.987793,491.067505 
	C437.147369,494.469391 438.583038,496.816284 442.994629,497.398590 
	C448.058197,498.066986 451.451935,508.944427 448.467773,513.459106 
	C447.894592,514.326294 446.300262,514.610840 445.116913,514.961243 
	C443.261597,515.510559 441.355072,515.887085 439.224670,516.616821 
z"/>
<path fill="#498AAB" opacity="1.000000" stroke="none" 
	d="
M436.021027,490.730713 
	C435.544861,491.053192 435.101898,491.038879 434.326385,490.593140 
	C433.662109,486.441925 433.330444,482.722198 433.001862,478.575928 
	C433.339386,473.772430 433.673828,469.395447 434.367035,465.010803 
	C435.677643,465.001495 436.629486,464.999847 437.699036,465.408142 
	C438.030945,467.499878 438.245209,469.181702 438.459503,470.863525 
	C438.280182,475.246521 438.100861,479.629517 437.836731,484.466095 
	C437.186035,486.744415 436.620148,488.569153 436.021027,490.730713 
z"/>
<path fill="#0C1A28" opacity="1.000000" stroke="none" 
	d="
M438.829742,470.888672 
	C438.245209,469.181702 438.030945,467.499878 437.806274,465.000305 
	C437.894653,462.787720 437.993439,461.392853 438.339050,459.760681 
	C438.421722,458.009796 438.257538,456.496246 438.109619,454.657166 
	C438.100708,453.898254 438.075562,453.464935 438.310120,453.024963 
	C439.040039,452.839691 439.510223,452.661133 439.980408,452.482544 
	C440.768066,453.897308 441.555725,455.312103 442.657898,457.215820 
	C443.638611,458.474030 444.304779,459.243286 444.970978,460.012512 
	C444.970978,460.012512 444.997559,460.005615 445.017090,460.381226 
	C445.363495,462.141754 445.690369,463.526672 446.017242,464.911591 
	C445.020569,466.689484 444.023926,468.467377 443.006958,470.617004 
	C441.724426,470.963745 440.462219,470.938782 438.829742,470.888672 
z"/>
<path fill="#208E98" opacity="1.000000" stroke="none" 
	d="
M434.996429,496.013428 
	C435.776093,497.019562 436.364319,498.356873 437.384644,498.946686 
	C438.616699,499.658905 440.632446,499.295654 441.569305,500.180817 
	C443.610321,502.109253 446.385437,504.440155 446.568207,506.785278 
	C446.716217,508.684326 443.886108,511.816345 441.707733,512.647522 
	C438.857697,513.734863 435.348724,513.184692 432.123444,513.180542 
	C424.839508,513.171204 417.553955,512.943787 410.272675,513.051208 
	C406.896790,513.100891 403.707367,512.803772 402.407043,509.329163 
	C401.253326,506.246216 401.725830,503.187408 404.573822,500.807220 
	C405.346649,500.161346 405.537262,498.818848 406.001892,497.428406 
	C406.449585,497.039093 406.890442,497.017426 408.006409,496.993195 
	C409.782806,496.328644 410.884064,495.666687 412.355591,495.043884 
	C416.461090,495.441742 420.189758,495.957031 423.933502,496.114838 
	C427.613190,496.269897 431.308167,496.062012 434.996429,496.013428 
z"/>
<path fill="#258697" opacity="1.000000" stroke="none" 
	d="
M411.985352,495.004730 
	C410.884064,495.666687 409.782806,496.328644 408.337555,496.988403 
	C407.982697,496.172729 407.971802,495.359283 407.903625,494.138062 
	C407.846344,493.730286 407.820038,493.391968 408.134216,493.037109 
	C408.650940,491.822144 408.853485,490.962036 409.386597,490.081360 
	C410.158539,490.048279 410.599884,490.035736 411.309662,490.276337 
	C411.713837,492.021240 411.849579,493.512970 411.985352,495.004730 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M411.041260,490.023193 
	C410.599884,490.035736 410.158539,490.048279 409.106079,490.051575 
	C408.307495,490.037476 408.119995,490.032593 407.932526,490.027740 
	C407.830627,487.190521 407.728729,484.353333 407.383057,480.849548 
	C407.095276,479.748566 407.051270,479.314117 407.007263,478.879669 
	C407.003113,469.915710 406.998993,460.951782 407.222229,451.377808 
	C407.680115,450.480377 407.910645,450.192993 408.440216,449.956543 
	C409.482208,450.336090 410.225098,450.664642 410.978333,451.445526 
	C411.326843,456.266418 411.665039,460.635010 411.707275,465.296356 
	C411.608521,471.051483 411.805695,476.513885 411.987610,482.380493 
	C411.661987,485.197571 411.351624,487.610382 411.041260,490.023193 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M406.684387,478.952423 
	C407.051270,479.314117 407.095276,479.748566 407.159180,480.507294 
	C406.472137,480.929779 405.765198,481.028015 404.709991,481.157990 
	C405.028290,480.468201 405.694916,479.746704 406.684387,478.952423 
z"/>
<path fill="#456D8D" opacity="1.000000" stroke="none" 
	d="
M407.881958,490.403381 
	C408.119995,490.032593 408.307495,490.037476 408.775513,490.072144 
	C408.853485,490.962036 408.650940,491.822144 408.160553,492.869507 
	C407.858948,492.297516 407.845184,491.538300 407.881958,490.403381 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M446.391663,464.904907 
	C445.690369,463.526672 445.363495,462.141754 445.009155,460.392883 
	C446.304321,461.440704 447.626953,462.852509 448.980225,464.614532 
	C448.262634,464.942566 447.514374,464.920380 446.391663,464.904907 
z"/>
<path fill="#9FCBD1" opacity="1.000000" stroke="none" 
	d="
M548.996094,553.425598 
	C549.463989,546.977295 551.409912,545.038696 557.258972,545.018188 
	C568.336853,544.979431 579.417786,545.154846 590.491821,544.943298 
	C595.876038,544.840515 598.774231,547.299133 599.992615,552.645020 
	C597.615662,560.110413 595.848022,561.302551 587.316772,562.007690 
	C581.269104,562.507507 575.243042,563.285767 569.191040,563.705322 
	C567.633789,563.813354 566.020447,563.011536 564.423462,562.671753 
	C561.719238,562.096191 559.023193,561.191040 556.295837,561.074219 
	C550.091614,560.808350 549.383789,560.281189 548.996094,553.425598 
z"/>
<path fill="#64B6D0" opacity="1.000000" stroke="none" 
	d="
M445.151855,450.702454 
	C443.479462,450.918060 441.983368,450.806213 439.945679,450.738800 
	C438.623901,450.565826 437.843750,450.348419 436.778503,449.806824 
	C428.326355,449.311981 420.159302,449.141357 411.988190,448.505676 
	C411.988617,443.022980 411.993073,438.005280 412.466370,432.989471 
	C420.373199,432.993408 427.849426,432.527069 435.238464,433.129608 
	C442.595062,433.729431 449.861115,430.405884 457.175385,432.975739 
	C457.466888,433.078125 457.906128,433.095520 458.150574,432.946228 
	C462.449982,430.320770 466.707153,432.366608 470.975250,433.026093 
	C473.983856,438.104462 476.992462,443.182831 480.009094,448.637329 
	C469.131653,449.052246 458.245941,449.068878 447.361298,449.182953 
	C446.679535,449.190125 446.005737,449.959961 445.151855,450.702454 
z"/>
<path fill="#59B1C8" opacity="1.000000" stroke="none" 
	d="
M518.588623,446.924133 
	C518.966736,448.722107 518.978943,450.436005 518.974182,452.578644 
	C514.042297,453.656525 509.141907,454.532684 504.205566,454.851471 
	C502.002777,454.993713 499.727661,454.015381 497.236908,453.260620 
	C497.004272,450.015472 496.725769,447.009094 497.180450,444.117981 
	C497.362823,442.958435 499.014465,441.470917 500.251465,441.166992 
	C502.113403,440.709625 504.207336,441.065399 506.186798,441.247650 
	C509.359558,441.539764 512.519348,441.972809 516.570679,442.450806 
	C516.816528,443.104065 517.519592,444.972076 518.588623,446.924133 
z"/>
<path fill="#76C0DF" opacity="1.000000" stroke="none" 
	d="
M494.563660,419.097595 
	C493.674866,417.774231 493.136444,416.546570 492.297668,415.149597 
	C491.344238,413.571075 490.691223,412.161926 490.332581,410.251465 
	C492.206177,409.810181 493.926605,409.481720 495.340820,409.995728 
	C502.362000,412.547974 509.174805,413.272552 516.423218,410.316864 
	C518.790588,409.351532 522.113770,410.730164 524.999817,411.036774 
	C524.049744,412.532135 523.099731,414.027496 522.240234,415.876892 
	C522.330688,416.230865 522.315125,416.185760 521.912598,416.168701 
	C520.655151,418.105682 519.800171,420.059723 518.945190,422.013763 
	C516.506104,426.432281 513.266602,429.516449 507.721252,429.056091 
	C506.806396,428.980133 505.674988,429.256836 504.931519,429.779785 
	C502.321045,431.616028 500.990631,431.048035 499.782806,428.062347 
	C498.526764,424.957581 496.566437,422.137726 494.563660,419.097595 
z"/>
<path fill="#89A1D8" opacity="1.000000" stroke="none" 
	d="
M519.321533,422.019226 
	C519.800171,420.059723 520.655151,418.105682 521.908325,416.188263 
	C522.278198,417.920654 522.249817,419.616455 522.083374,421.725372 
	C521.196106,422.100555 520.447021,422.062622 519.321533,422.019226 
z"/>
<path fill="#88C9EF" opacity="1.000000" stroke="none" 
	d="
M405.979340,416.017273 
	C405.597168,415.826874 405.214996,415.636505 404.526978,415.330536 
	C403.969391,414.251038 403.717682,413.287140 403.227936,411.806580 
	C402.618256,410.673035 402.246643,410.056183 401.875031,409.439331 
	C401.586700,409.973724 401.298401,410.508148 401.010132,411.042572 
	C400.696686,406.516113 400.186157,401.987030 400.245758,397.465485 
	C400.256927,396.620483 402.543823,395.101166 403.795868,395.085083 
	C417.229675,394.912384 430.667664,394.901886 444.101227,395.086334 
	C445.643738,395.107483 447.730560,396.195435 448.596161,397.454742 
	C451.645081,401.890289 454.271637,406.616150 456.743713,411.759338 
	C453.925812,417.202118 449.588684,417.257080 444.836761,417.072968 
	C437.539642,416.790283 430.194519,417.383850 422.927490,416.837708 
	C419.193115,416.557068 415.306702,415.116943 411.991699,413.289062 
	C408.307922,411.257843 407.183228,412.140808 406.757812,415.900696 
	C406.323425,415.950043 406.151398,415.983643 405.979340,416.017273 
z"/>
<path fill="#76C0DF" opacity="1.000000" stroke="none" 
	d="
M407.020233,415.884918 
	C407.183228,412.140808 408.307922,411.257843 411.991699,413.289062 
	C415.306702,415.116943 419.193115,416.557068 422.927490,416.837708 
	C430.194519,417.383850 437.539642,416.790283 444.836761,417.072968 
	C449.588684,417.257080 453.925812,417.202118 456.765747,412.131226 
	C458.497253,413.272186 460.244415,414.347412 461.222870,415.905396 
	C464.601074,421.284424 467.711273,426.831757 470.949371,432.670563 
	C466.707153,432.366608 462.449982,430.320770 458.150574,432.946228 
	C457.906128,433.095520 457.466888,433.078125 457.175385,432.975739 
	C449.861115,430.405884 442.595062,433.729431 435.238464,433.129608 
	C427.849426,432.527069 420.373199,432.993408 412.462646,432.552673 
	C411.645203,428.049225 411.509003,423.948547 410.819000,419.943268 
	C410.629669,418.844238 408.782562,418.030792 407.509735,416.933716 
	C407.100311,416.523682 406.999298,416.224945 407.020233,415.884918 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M397.245483,415.238647 
	C394.728149,418.062347 392.425079,417.502319 392.144043,414.101532 
	C391.723480,409.012634 391.919434,403.829681 392.401459,398.738617 
	C392.527100,397.412018 394.684387,396.277802 396.286865,395.080200 
	C396.820984,401.672211 396.976746,408.239166 397.245483,415.238647 
z"/>
<path fill="#76C0DF" opacity="1.000000" stroke="none" 
	d="
M401.168915,411.287842 
	C401.298401,410.508148 401.586700,409.973724 401.875031,409.439331 
	C402.246643,410.056183 402.618256,410.673035 402.985840,411.619659 
	C402.430481,411.810638 401.879089,411.671844 401.168915,411.287842 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M779.273071,572.823486 
	C778.785278,572.789795 778.507446,572.599121 778.124023,572.173645 
	C778.497925,571.787720 778.977356,571.636658 779.456787,571.485596 
	C779.465454,571.879211 779.474182,572.272949 779.273071,572.823486 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M763.693604,572.034546 
	C763.985779,572.446533 763.981384,572.895203 763.957520,573.680237 
	C763.757812,573.368164 763.577393,572.719727 763.693604,572.034546 
z"/>
<path fill="#98C7CF" opacity="1.000000" stroke="none" 
	d="
M289.041443,557.358276 
	C289.846680,551.631653 295.342316,553.226807 298.239624,550.870972 
	C300.453308,549.071045 303.052032,547.738403 305.510345,546.248840 
	C306.324036,545.755798 307.218689,545.105896 308.102051,545.063416 
	C316.832733,544.643921 325.741913,543.136230 334.250885,544.362610 
	C341.602264,545.422180 348.500488,549.625793 355.801514,552.718567 
	C356.116577,557.181396 353.173126,557.887756 350.024933,557.958862 
	C344.046906,558.093994 338.060883,557.883362 332.083191,558.025940 
	C321.989075,558.266724 311.896484,558.616516 301.809540,559.062744 
	C300.222565,559.132996 298.660004,559.925049 297.101349,560.430298 
	C293.747986,561.517273 290.916504,561.115540 289.041443,557.358276 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M570.575317,465.001038 
	C569.971802,469.084747 568.988647,473.154846 567.991760,478.004395 
	C565.319519,484.855072 562.660950,490.926331 560.001221,496.998779 
	C560.000000,497.000000 559.997559,497.001221 559.603455,497.005066 
	C556.781616,497.675507 554.353943,498.342041 551.926208,499.008606 
	C549.952393,500.650696 547.978516,502.292786 545.669678,503.931458 
	C545.296570,501.923279 545.258484,499.918427 545.571533,497.432312 
	C546.643738,496.148682 547.364807,495.346375 548.085938,494.544067 
	C549.118042,494.490936 550.150208,494.437775 551.709534,494.787842 
	C554.392090,495.862457 556.805969,497.645081 557.926331,493.867462 
	C557.926331,493.867462 558.033752,493.445679 558.404480,493.200317 
	C558.829834,492.297607 558.884399,491.640198 558.938965,490.982819 
	C559.566284,488.550873 560.193604,486.118927 560.760010,483.313232 
	C562.909729,475.745819 565.120483,468.552185 567.656250,461.165283 
	C568.719360,462.310486 569.457520,463.648926 570.575317,465.001038 
z"/>
<path fill="#456D8D" opacity="1.000000" stroke="none" 
	d="
M557.498047,493.921295 
	C556.805969,497.645081 554.392090,495.862457 552.085327,494.900269 
	C553.645874,494.398102 555.357849,494.186615 557.498047,493.921295 
z"/>
<path fill="#456D8D" opacity="1.000000" stroke="none" 
	d="
M547.759888,494.416290 
	C547.364807,495.346375 546.643738,496.148682 545.639709,497.007019 
	C546.049133,496.138214 546.741516,495.213348 547.759888,494.416290 
z"/>
<path fill="#456D8D" opacity="1.000000" stroke="none" 
	d="
M558.707764,491.167053 
	C558.884399,491.640198 558.829834,492.297607 558.501343,493.005432 
	C558.310486,492.487671 558.393494,491.919495 558.707764,491.167053 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M565.411011,452.021545 
	C565.402893,449.559326 565.772949,447.100525 566.262573,444.228149 
	C567.051331,444.151917 567.720520,444.489227 568.655029,444.925262 
	C567.876648,447.357635 566.832947,449.691284 565.411011,452.021545 
z"/>
<path fill="#98C7CF" opacity="1.000000" stroke="none" 
	d="
M481.461365,556.985962 
	C481.006866,550.343933 486.098450,545.123352 492.818451,545.026245 
	C498.968689,544.937439 505.121185,544.999451 511.272705,545.007568 
	C518.482239,545.017151 518.977600,545.509583 518.999939,552.598816 
	C519.002563,553.430115 519.000427,554.261353 518.999023,555.537781 
	C516.073792,555.987793 513.147766,555.919678 510.226715,556.011047 
	C500.792694,556.306274 491.360474,556.660156 481.461365,556.985962 
z"/>
<path fill="#ACADA9" opacity="1.000000" stroke="none" 
	d="
M381.258606,498.175232 
	C381.408722,497.836273 381.558838,497.497345 382.025909,496.727234 
	C382.595276,495.530823 382.847687,494.765594 383.344421,493.794434 
	C383.552490,492.979431 383.516174,492.370331 383.683777,491.521271 
	C384.309387,490.566162 384.731079,489.851044 385.514252,489.025146 
	C386.263672,486.954071 386.651611,484.993683 387.101746,482.744080 
	C387.244690,482.068665 387.325470,481.682495 387.691711,481.151978 
	C388.042084,479.671234 388.107056,478.334869 388.199646,476.699066 
	C388.288239,476.003479 388.349182,475.607330 388.766785,475.042053 
	C389.378723,474.284912 389.856659,473.697021 389.856812,473.109009 
	C389.860992,457.705994 389.815704,442.303009 389.844666,426.557159 
	C390.020538,425.525940 390.130707,424.837555 390.240906,424.149170 
	C391.803467,420.629730 394.779663,419.884460 398.972107,420.047607 
	C400.764526,420.431976 401.818268,420.845093 402.871979,421.258240 
	C402.838531,422.031006 402.805084,422.803802 402.572876,423.785522 
	C396.430145,422.051727 392.942810,424.408081 392.910339,430.500946 
	C392.862793,439.426300 393.102722,448.357666 392.857880,457.276489 
	C392.510986,469.914124 391.173523,482.466248 386.195129,494.238617 
	C384.704468,497.763580 381.974487,500.764435 379.412140,503.675659 
	C379.760864,501.622375 380.509735,499.898804 381.258606,498.175232 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M380.893402,498.139038 
	C380.509735,499.898804 379.760864,501.622375 379.067139,503.702759 
	C379.122314,504.059601 378.886139,504.349274 378.495148,504.566589 
	C377.705231,505.493988 377.306305,506.204071 376.907379,506.914154 
	C376.907379,506.914154 376.913452,506.942627 376.911560,506.924591 
	C376.487946,506.940979 376.066223,506.975433 375.324768,507.007538 
	C376.846069,504.037750 378.687134,501.070312 380.893402,498.139038 
z"/>
<path fill="#ACADA9" opacity="1.000000" stroke="none" 
	d="
M377.240906,506.969849 
	C377.306305,506.204071 377.705231,505.493988 378.430237,504.741821 
	C378.821991,505.191925 378.887726,505.684113 378.936035,506.548218 
	C378.470551,506.955261 378.022491,506.990417 377.240906,506.969849 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M405.143433,449.126923 
	C404.923370,444.256256 404.787689,439.453796 405.014435,434.668518 
	C405.072418,433.444519 406.276794,432.274872 407.378113,431.523621 
	C407.798401,437.615112 407.795074,443.263245 407.451263,448.949463 
	C406.671295,449.116058 406.231842,449.244537 405.792389,449.373047 
	C405.792389,449.373077 405.362488,449.201538 405.143433,449.126923 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M405.861267,449.631622 
	C406.231842,449.244537 406.671295,449.116058 407.568604,449.187744 
	C408.064697,449.560516 408.102905,449.733063 408.141144,449.905609 
	C407.910645,450.192993 407.680115,450.480377 407.212280,450.913696 
	C406.626678,450.669830 406.278412,450.280029 405.861267,449.631622 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M408.440216,449.956543 
	C408.102905,449.733063 408.064697,449.560516 407.909119,449.149658 
	C407.795074,443.263245 407.798401,437.615112 407.772949,431.542542 
	C407.749603,427.892731 407.755035,424.667358 407.660706,420.703339 
	C407.605988,419.005219 407.651062,418.045776 407.696167,417.086304 
	C408.782562,418.030792 410.629669,418.844238 410.819000,419.943268 
	C411.509003,423.948547 411.645203,428.049225 411.993835,432.550781 
	C411.993073,438.005280 411.988617,443.022980 411.960724,448.806763 
	C411.614227,450.046326 411.291107,450.519775 410.968018,450.993225 
	C410.225098,450.664642 409.482208,450.336090 408.440216,449.956543 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M407.509735,416.933716 
	C407.651062,418.045776 407.605988,419.005219 407.536865,420.323425 
	C406.226807,420.833984 404.940796,420.985809 403.263367,421.197937 
	C401.818268,420.845093 400.764526,420.431976 399.365845,419.962524 
	C399.535187,418.927673 399.837646,417.379303 400.609070,417.092529 
	C402.086212,416.543427 403.829895,416.711456 405.721161,416.299561 
	C406.151398,415.983643 406.323425,415.950043 406.757812,415.900696 
	C406.999298,416.224945 407.100311,416.523682 407.509735,416.933716 
z"/>
<path fill="#88C9EF" opacity="1.000000" stroke="none" 
	d="
M836.014648,418.976685 
	C835.676514,413.354462 835.379333,407.729401 834.987366,402.110931 
	C834.669495,397.556061 836.808289,395.929657 841.241638,395.942047 
	C873.894592,396.033173 906.547913,396.023834 939.200989,395.963287 
	C943.322754,395.955658 945.487976,397.284332 945.060913,401.787659 
	C944.794617,404.594879 944.661926,407.498474 945.074829,410.268555 
	C945.863098,415.556641 943.204712,418.192596 938.352722,419.574554 
	C937.757751,418.898346 937.314941,417.923370 937.273743,417.940063 
	C934.668091,418.994995 932.095154,420.130646 930.890198,420.658478 
	C927.363403,419.550995 924.925720,418.318085 922.393188,418.075623 
	C913.944153,417.266571 905.350342,419.658112 896.767578,416.741119 
	C895.735168,416.306671 894.895935,416.132996 894.009949,415.745422 
	C893.963135,415.531555 893.911438,415.096741 893.870605,414.700073 
	C893.358398,413.904724 892.890625,413.162872 892.415344,413.158051 
	C882.757935,413.060394 873.098328,412.974915 863.443726,413.142395 
	C862.593506,413.157166 861.773865,414.936768 860.834717,416.157349 
	C860.660034,416.592102 860.590698,416.766235 860.209534,416.957489 
	C858.937866,417.980804 857.978027,418.987000 856.619385,419.943787 
	C854.152100,419.644897 852.089111,419.285858 850.013367,419.180023 
	C848.303894,419.092865 845.991638,420.051392 844.970825,419.246796 
	C841.845764,416.783997 839.022583,417.836395 836.014648,418.976685 
z"/>
<path fill="#64B6D0" opacity="1.000000" stroke="none" 
	d="
M921.017944,444.005310 
	C920.526733,444.601562 920.035461,445.197845 918.899048,445.945557 
	C906.556885,450.765137 894.303528,448.521484 882.270142,448.922974 
	C880.161926,448.993347 878.029297,448.331726 875.930969,447.640625 
	C876.631592,445.511108 877.309204,443.749084 878.353027,441.985046 
	C881.431702,439.730042 884.148499,437.482361 886.854858,435.222198 
	C888.561157,433.797150 890.251648,432.353271 892.187134,431.229706 
	C894.476379,432.026917 896.522217,432.902832 898.579834,432.931488 
	C908.730469,433.072998 918.884216,432.990051 929.036865,432.986298 
	C928.713318,434.097717 928.389832,435.209167 927.890381,436.963745 
	C926.141296,439.071472 924.568176,440.536011 922.731567,442.123230 
	C921.984619,442.832336 921.501282,443.418823 921.017944,444.005310 
z"/>
<path fill="#76C0DF" opacity="1.000000" stroke="none" 
	d="
M929.334106,432.836761 
	C918.884216,432.990051 908.730469,433.072998 898.579834,432.931488 
	C896.522217,432.902832 894.476379,432.026917 892.234619,430.872894 
	C893.683044,425.803436 895.321960,421.402679 896.960938,417.001892 
	C905.350342,419.658112 913.944153,417.266571 922.393188,418.075623 
	C924.925720,418.318085 927.363403,419.550995 930.890198,420.658478 
	C932.095154,420.130646 934.668091,418.994995 937.273743,417.940063 
	C937.314941,417.923370 937.757751,418.898346 938.025879,419.734009 
	C935.236206,424.267426 932.433716,428.477325 929.334106,432.836761 
z"/>
<path fill="#76C0DF" opacity="1.000000" stroke="none" 
	d="
M835.988770,419.242035 
	C839.022583,417.836395 841.845764,416.783997 844.970825,419.246796 
	C845.991638,420.051392 848.303894,419.092865 850.013367,419.180023 
	C852.089111,419.285858 854.152100,419.644897 856.618652,420.382874 
	C857.038818,422.920319 857.060913,424.969330 857.066772,427.425049 
	C857.496704,431.660919 855.679688,434.348297 852.012451,434.371704 
	C847.124390,434.402954 842.211304,433.557312 837.361267,432.767090 
	C836.740051,432.665863 836.115295,430.658173 836.041138,429.498535 
	C835.840027,426.355164 835.934326,423.192841 835.911499,420.038055 
	C835.928711,419.861145 835.945862,419.684265 835.988770,419.242035 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M835.543335,420.029175 
	C835.934326,423.192841 835.840027,426.355164 836.041138,429.498535 
	C836.115295,430.658173 836.740051,432.665863 837.361267,432.767090 
	C842.211304,433.557312 847.124390,434.402954 852.012451,434.371704 
	C855.679688,434.348297 857.496704,431.660919 857.430054,427.420990 
	C859.031616,427.001862 860.253662,426.993439 861.569214,427.405945 
	C861.777344,429.550568 861.892029,431.274353 861.845947,433.227234 
	C861.614380,433.628998 861.543457,433.801605 861.122681,433.977783 
	C854.565186,437.569885 847.824097,438.513763 840.790527,438.018402 
	C835.746948,437.663177 833.017395,434.864746 832.981018,429.965912 
	C832.956360,426.652191 832.963867,423.338257 832.957031,420.024414 
	C833.696350,420.023041 834.435730,420.021667 835.543335,420.029175 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M832.951294,419.672119 
	C832.963867,423.338257 832.956360,426.652191 832.981018,429.965912 
	C833.017395,434.864746 835.746948,437.663177 840.790527,438.018402 
	C847.824097,438.513763 854.565186,437.569885 861.267151,434.309204 
	C861.891968,435.100830 862.022522,435.564606 861.990723,436.378296 
	C860.277222,437.823761 858.844727,439.573090 857.150452,439.880707 
	C853.461914,440.550415 849.639954,440.485352 845.564087,440.682739 
	C844.843384,440.585083 844.431152,440.525238 843.674072,440.286469 
	C841.209167,440.079895 839.089172,440.052246 836.642883,440.020691 
	C835.882202,439.990417 835.447754,439.963989 834.779175,439.678223 
	C833.393677,439.253265 832.242249,439.087616 830.863098,438.857300 
	C830.635376,438.792633 830.164795,438.740509 830.142944,438.553589 
	C830.121033,438.366638 829.955383,438.028625 830.011108,437.578796 
	C830.063171,434.425903 830.059570,431.722870 830.056030,429.019836 
	C830.217041,425.231537 830.378174,421.443268 830.779785,417.333984 
	C831.662109,417.781952 832.303833,418.550903 832.951294,419.672119 
z"/>
<path fill="#060B10" opacity="1.000000" stroke="none" 
	d="
M830.468506,404.719391 
	C828.582947,404.972198 826.558899,404.518005 825.010498,405.178711 
	C820.285583,407.194855 818.600647,404.762726 817.496338,400.169525 
	C821.441467,400.460297 825.212708,400.738220 829.419189,401.039307 
	C829.854431,401.062439 829.983459,401.026733 829.996277,401.403931 
	C830.185913,402.553253 830.362671,403.325348 830.539490,404.097443 
	C830.539490,404.097443 830.547607,404.522247 830.468506,404.719391 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M922.995117,442.000580 
	C924.568176,440.536011 926.141296,439.071472 927.854248,437.311584 
	C928.465820,437.033508 928.937561,437.050781 929.761963,437.115234 
	C928.346375,439.436218 926.578125,441.710022 924.489136,444.081665 
	C924.168335,444.179504 924.071594,444.075317 924.008606,443.729309 
	C923.628784,442.922424 923.311951,442.461517 922.995117,442.000580 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M830.836914,404.009064 
	C830.362671,403.325348 830.185913,402.553253 830.057617,401.377075 
	C830.513123,400.992676 830.920105,401.012390 831.630615,401.058563 
	C831.667480,402.030243 831.400940,402.975464 830.836914,404.009064 
z"/>
<path fill="#76C0DF" opacity="1.000000" stroke="none" 
	d="
M812.012268,413.998932 
	C809.752136,417.495758 807.492065,420.992615 804.755859,424.813110 
	C801.878296,427.113403 799.476929,429.090027 796.632263,431.042816 
	C794.374329,431.093781 792.351501,430.673157 790.782898,431.332794 
	C785.287964,433.643494 780.002441,433.709778 774.464294,431.444702 
	C773.011597,430.850555 770.695557,431.063324 769.313416,431.849915 
	C765.453552,434.046692 762.510376,433.390472 759.953491,429.599731 
	C761.132507,426.896362 762.027222,424.374786 763.532776,422.295380 
	C765.644348,419.379059 765.952209,417.292816 761.827820,415.911560 
	C761.585876,415.870148 761.112366,415.740479 761.068909,415.397095 
	C759.989136,413.734100 758.952820,412.414520 758.322998,411.062378 
	C761.622131,412.098083 765.067200,412.521729 767.262878,414.403290 
	C769.816650,416.591675 772.251221,417.079193 775.300842,417.030121 
	C786.945801,416.842743 798.689026,417.890594 810.339355,413.985687 
	C811.119995,413.992767 811.566162,413.995850 812.012268,413.998932 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M757.916504,411.094910 
	C758.952820,412.414520 759.989136,413.734100 760.870483,415.497620 
	C760.430420,416.044189 760.197327,416.216949 759.552368,416.532898 
	C749.727905,416.724976 740.367310,416.843964 730.734985,416.814636 
	C730.001282,418.765808 729.539368,420.865295 728.976196,423.213928 
	C728.818542,423.633270 728.762024,423.803436 728.341736,424.101440 
	C726.445618,429.388062 727.633728,435.437866 722.955017,439.566284 
	C716.946533,439.647491 710.938110,439.728699 704.477661,439.330872 
	C702.380798,436.893066 700.735901,434.934265 699.058228,432.532349 
	C698.716858,429.069946 698.408264,426.050720 698.099670,423.031464 
	C698.123718,422.847260 698.147766,422.663055 698.117859,422.205139 
	C698.058777,420.854126 698.053711,419.776794 697.900024,418.320312 
	C697.844788,417.763641 697.938293,417.586151 698.237305,417.188538 
	C702.701111,416.949646 700.094421,413.169739 702.010742,411.294495 
	C703.045349,411.057068 703.485046,411.067047 703.931396,411.446228 
	C703.944580,412.556122 703.951111,413.296814 703.948608,414.421204 
	C704.331116,420.485931 704.634521,426.176025 705.217346,431.837372 
	C705.297302,432.613800 706.742432,433.812134 707.597961,433.849701 
	C711.476196,434.020111 715.427979,434.217987 719.231689,433.625549 
	C720.971130,433.354645 723.681396,431.395020 723.806824,430.007416 
	C724.469055,422.681305 724.688171,415.395905 732.551025,411.089539 
	C737.308289,411.084564 741.607666,411.086945 745.997437,411.442627 
	C748.029236,413.504028 749.803345,414.022430 751.520813,411.087830 
	C753.938232,411.087280 755.927368,411.091095 757.916504,411.094910 
z"/>
<path fill="#91B7EB" opacity="1.000000" stroke="none" 
	d="
M703.924744,411.077057 
	C703.485046,411.067047 703.045349,411.057068 702.274902,411.036194 
	C701.961060,407.696045 702.043274,404.365479 701.978333,401.037811 
	C701.897217,396.883484 703.836487,394.955658 708.000305,394.987885 
	C716.157227,395.051056 724.315063,394.999939 732.472473,395.000458 
	C756.944763,395.002075 781.417114,394.999084 805.889404,395.013031 
	C812.697937,395.016907 813.048096,395.334839 812.992065,401.979492 
	C812.960022,405.788391 812.733154,409.595673 812.303101,413.701294 
	C811.566162,413.995850 811.119995,413.992767 810.339844,413.555573 
	C810.088928,411.962891 810.037598,410.775879 810.276123,409.650238 
	C812.365967,399.786499 810.995728,398.004211 801.154907,398.001678 
	C773.185364,397.994476 745.215759,398.032013 717.246277,397.983978 
	C702.135986,397.958038 704.209351,397.274384 703.924744,411.077057 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M797.075623,431.066650 
	C799.476929,429.090027 801.878296,427.113403 804.621826,425.129028 
	C805.245850,425.421722 805.527771,425.722137 806.038391,426.505615 
	C805.456177,428.922272 804.645325,430.855865 803.484131,432.851135 
	C802.402771,434.198029 801.671875,435.483307 800.581909,436.816772 
	C797.828186,439.238617 795.433472,441.612274 792.850952,444.235779 
	C792.091919,445.299408 791.520569,446.113129 790.672668,447.111877 
	C787.950684,449.896576 785.505310,452.496216 782.750488,455.208191 
	C781.654846,456.554565 780.868652,457.788635 779.890259,459.290070 
	C778.719055,460.961151 777.739929,462.364868 776.529541,463.929932 
	C775.645203,464.445648 774.992188,464.800018 774.026123,465.233887 
	C773.487976,465.881927 773.262817,466.450439 772.936035,467.241089 
	C772.645264,467.743896 772.390869,467.940033 771.688599,468.030243 
	C770.541260,468.005493 769.776733,468.002136 768.751343,467.784210 
	C770.402283,464.539337 772.077332,461.322815 774.280762,458.521973 
	C777.672974,454.210114 781.427551,450.183411 785.328369,445.880890 
	C789.444092,440.839600 793.259827,435.953125 797.075623,431.066650 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M803.834473,432.789459 
	C804.645325,430.855865 805.456177,428.922272 806.321289,426.641296 
	C807.075562,425.623169 807.775635,424.952423 808.740845,424.164124 
	C808.307495,426.336609 807.203735,428.597778 807.037842,430.925720 
	C806.903442,432.812561 807.896301,434.779724 808.809570,437.111023 
	C809.518799,437.980927 809.807495,438.451050 810.060242,439.220947 
	C810.012024,439.721069 809.999817,439.921387 809.777710,440.288696 
	C807.656738,437.900269 805.745605,435.344849 803.834473,432.789459 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M815.930420,409.285309 
	C816.157043,411.662750 816.079407,414.306030 815.655457,417.083130 
	C815.414856,414.661682 815.520569,412.106445 815.930420,409.285309 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M814.500977,417.960724 
	C813.785156,419.239960 812.744629,420.575104 811.368774,421.930237 
	C812.080994,420.639038 813.128601,419.327850 814.500977,417.960724 
z"/>
<path fill="#87CEF0" opacity="1.000000" stroke="none" 
	d="
M525.348877,410.975464 
	C522.113770,410.730164 518.790588,409.351532 516.423218,410.316864 
	C509.174805,413.272552 502.362000,412.547974 495.340820,409.995728 
	C493.926605,409.481720 492.206177,409.810181 490.286011,409.913757 
	C484.308044,409.122498 484.678284,404.793396 485.094025,400.836700 
	C485.523071,396.753174 488.868805,396.955109 491.940399,396.964752 
	C502.052032,396.996521 512.163940,396.945465 522.275391,396.993988 
	C524.974915,397.006958 528.176392,396.101166 529.393738,399.826996 
	C530.600220,403.519867 530.605957,407.008545 527.237915,409.730164 
	C526.734436,410.136963 526.211731,410.520081 525.348877,410.975464 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M532.333740,415.912415 
	C531.798279,415.969818 531.604919,415.919464 531.163025,415.701691 
	C531.657288,414.680695 532.400024,413.827118 533.429993,412.904419 
	C533.370117,413.825134 533.023010,414.814911 532.333740,415.912415 
z"/>
<path fill="#24909A" opacity="1.000000" stroke="none" 
	d="
M634.591125,497.028015 
	C643.328186,496.938690 651.701721,496.955322 660.070374,496.763763 
	C661.200134,496.737885 662.333923,495.712067 663.416321,495.066101 
	C665.056091,494.087524 666.652405,493.035919 668.635376,492.016815 
	C669.451599,495.697388 671.903625,497.131561 675.174377,498.239502 
	C681.385742,500.343384 683.809509,503.674561 682.806702,509.317322 
	C682.492249,511.086426 679.895874,513.788757 678.339905,513.791565 
	C662.609741,513.820007 646.878784,513.376160 631.148010,513.066467 
	C629.986267,513.043640 628.823730,513.001770 627.662598,513.023315 
	C624.646912,513.079163 621.373840,513.536011 621.040588,509.126221 
	C620.757446,505.379639 620.557800,501.804260 625.546631,500.576965 
	C628.545959,499.839081 631.339844,498.266174 634.591125,497.028015 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M687.310913,510.023804 
	C687.672668,510.721466 687.751465,511.506683 687.719116,512.676086 
	C687.414551,512.077332 687.221252,511.094360 687.310913,510.023804 
z"/>
<path fill="#3099A8" opacity="1.000000" stroke="none" 
	d="
M560.002441,496.997559 
	C562.660950,490.926331 565.319519,484.855072 567.990234,478.399017 
	C571.989136,477.848297 576.294312,476.658508 579.882141,477.774658 
	C584.408875,479.182861 588.185486,478.419708 592.613159,477.013153 
	C592.691711,478.118652 592.378723,479.228790 592.048706,480.688812 
	C592.031677,481.038727 592.016663,481.012115 591.629639,481.007446 
	C586.457031,482.165588 584.991577,485.641541 587.020386,491.017426 
	C587.332092,492.757629 587.643860,494.497833 587.601929,496.574005 
	C581.536499,495.940582 575.846069,494.435303 570.104858,494.207245 
	C566.779602,494.075165 563.372620,495.998993 560.002441,496.997559 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M608.103577,486.878906 
	C604.625977,487.132385 601.289551,487.118591 597.502136,487.159515 
	C595.370911,486.404816 593.690735,485.595337 591.977356,484.431946 
	C597.273560,483.751343 602.603027,483.424652 608.380371,483.154846 
	C609.281921,484.006165 609.735596,484.800629 610.210022,485.631317 
	C609.600220,485.935486 608.922546,486.273560 608.103577,486.878906 
z"/>
<path fill="#498AAB" opacity="1.000000" stroke="none" 
	d="
M587.280029,490.832794 
	C584.991577,485.641541 586.457031,482.165588 591.635559,481.014893 
	C591.696472,481.792694 591.364441,482.558411 590.998535,483.635742 
	C590.658569,484.740540 590.352417,485.533661 590.030701,486.665070 
	C589.190002,488.218292 588.364868,489.433228 587.280029,490.832794 
z"/>
<path fill="#A4D0D5" opacity="1.000000" stroke="none" 
	d="
M441.007355,564.988098 
	C440.837677,564.991089 440.667999,564.994141 440.243683,564.999756 
	C432.421234,565.589294 424.853882,566.181763 417.285065,566.754883 
	C416.470856,566.816589 415.598938,566.946472 414.837524,566.738403 
	C410.494781,565.551575 406.177490,564.271851 401.440948,563.018188 
	C400.853729,562.455933 400.677124,561.899109 400.257782,561.171204 
	C400.313629,559.250000 400.612213,557.500000 401.416626,555.373657 
	C417.612579,554.660889 433.302612,554.324341 448.992676,553.987793 
	C448.997406,555.711243 449.002106,557.434753 449.011963,559.590088 
	C449.017090,560.021973 449.007507,560.518433 448.599976,560.761841 
	C445.797424,562.332886 443.402374,563.660461 441.007355,564.988098 
z"/>
<path fill="#98C7CF" opacity="1.000000" stroke="none" 
	d="
M448.994995,553.582581 
	C433.302612,554.324341 417.612579,554.660889 401.460022,554.993774 
	C400.994324,546.140320 400.993896,546.076294 409.812653,546.016052 
	C420.226227,545.945007 430.640900,545.957275 441.054626,546.016235 
	C446.421753,546.046631 448.654144,548.145813 448.994995,553.582581 
z"/>
<path fill="#91B7EB" opacity="1.000000" stroke="none" 
	d="
M340.955566,412.044067 
	C336.124176,409.682678 333.897858,399.091248 337.641998,395.707520 
	C338.363647,395.055328 339.810883,395.019867 340.924500,395.015961 
	C353.421021,394.972046 365.918060,395.040100 378.414246,394.962524 
	C382.466217,394.937347 384.078522,396.777954 384.067078,400.744995 
	C384.052063,405.937378 384.577576,411.214569 379.024841,414.685974 
	C378.678284,415.159698 378.325195,415.240784 377.302368,415.207336 
	C376.092255,415.066559 375.551819,415.040344 375.008423,414.756012 
	C375.004730,414.325806 375.003998,414.153748 375.277374,413.787201 
	C376.750885,412.226379 378.339722,411.037292 379.060638,409.453156 
	C380.211639,406.924072 381.772980,403.917145 381.252106,401.528503 
	C380.912354,399.970367 377.229523,398.243591 374.982727,398.155090 
	C365.509796,397.782043 356.013763,397.989105 346.526184,397.997650 
	C339.853027,398.003662 336.901001,402.630676 339.581879,408.872223 
	C340.032440,409.921234 340.536407,410.947296 341.000793,411.998901 
	C340.985962,412.013885 340.955566,412.044067 340.955566,412.044067 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M389.778931,426.899994 
	C389.815704,442.303009 389.860992,457.705994 389.856812,473.109009 
	C389.856659,473.697021 389.378723,474.284912 388.620636,474.692810 
	C388.046509,474.015778 387.975159,473.518799 387.938354,472.571228 
	C387.873169,469.690948 387.773438,467.261230 387.722839,464.377869 
	C387.534088,457.314056 387.353363,450.700623 386.970581,444.098907 
	C386.931946,443.432678 385.672424,442.837250 384.635651,442.139526 
	C383.605835,441.929016 382.919861,441.787964 382.233887,441.646912 
	C382.467285,437.043854 382.700684,432.440826 383.290222,427.448975 
	C385.690521,427.006744 387.734741,426.953369 389.778931,426.899994 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M381.821136,441.754761 
	C382.919861,441.787964 383.605835,441.929016 384.627472,442.587585 
	C384.963135,449.465881 384.963135,455.826630 384.963135,461.955627 
	C382.333435,461.955627 380.207184,461.955627 377.845398,461.929321 
	C377.609863,461.902985 377.138580,461.953156 377.110077,461.488281 
	C377.115906,454.703918 377.150238,448.384430 377.184570,442.064911 
	C378.592529,441.997498 380.000458,441.930054 381.821136,441.754761 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M377.116913,441.596527 
	C377.150238,448.384430 377.115906,454.703918 376.819702,461.684082 
	C376.729950,463.571777 376.902069,464.798798 377.054260,466.385132 
	C376.954071,467.459229 376.873810,468.174042 376.502441,468.906677 
	C375.824493,468.980835 375.437653,469.037109 374.778076,468.856140 
	C374.007721,467.081146 373.510071,465.543427 373.009216,463.538574 
	C372.991882,458.054535 372.977722,453.037628 372.976135,447.581299 
	C373.005829,445.095367 373.022919,443.048889 373.433350,440.908081 
	C374.222321,436.688721 374.493591,432.546021 375.077576,428.447876 
	C375.296478,426.911377 376.242371,425.478455 376.856750,423.998291 
	C376.920929,429.708252 376.985107,435.418182 377.116913,441.596527 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M376.934021,423.559479 
	C376.242371,425.478455 375.296478,426.911377 375.077576,428.447876 
	C374.493591,432.546021 374.222321,436.688721 373.420685,440.468628 
	C372.687958,437.410950 372.361267,434.698364 372.032593,431.536133 
	C373.024170,425.729034 374.017792,420.371582 375.011414,415.014130 
	C375.551819,415.040344 376.092255,415.066559 377.029846,415.186218 
	C377.288452,417.893311 377.149841,420.506989 376.934021,423.559479 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M389.844666,426.557159 
	C387.734741,426.953369 385.690521,427.006744 383.277130,427.017303 
	C383.589050,425.980988 384.073761,424.470856 384.998871,424.119141 
	C386.278259,423.632721 387.889313,424.018555 389.799591,424.090027 
	C390.130707,424.837555 390.020538,425.525940 389.844666,426.557159 
z"/>
<path fill="#76C0DF" opacity="1.000000" stroke="none" 
	d="
M375.008423,414.756012 
	C374.017792,420.371582 373.024170,425.729034 371.645111,431.703613 
	C362.802673,432.971619 353.995117,435.616852 346.967346,427.613159 
	C346.616089,424.486816 346.503693,421.742279 345.828461,419.144012 
	C345.571747,418.156097 344.021240,417.504425 342.837616,416.624451 
	C342.614807,416.549377 342.204651,416.319427 342.197021,416.154907 
	C342.189392,415.990417 342.070251,415.683350 342.070251,415.683350 
	C342.051971,415.313171 342.033752,414.943024 342.229340,414.177917 
	C342.632843,413.512726 342.822510,413.242432 343.428223,412.977661 
	C345.732086,413.349915 348.505341,413.068726 349.338440,414.207184 
	C351.656036,417.374542 354.618561,417.153748 357.715179,416.973419 
	C361.423187,416.757477 365.146942,416.534119 368.812683,415.976959 
	C370.928436,415.655334 372.943115,414.668854 375.003296,413.981659 
	C375.003998,414.153748 375.004730,414.325806 375.008423,414.756012 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M342.204651,416.319427 
	C342.204651,416.319427 342.614807,416.549377 342.866669,417.120361 
	C343.102814,421.019958 343.087097,424.348572 342.724274,427.500549 
	C340.874115,425.691284 339.371063,424.058624 337.478027,422.255066 
	C335.424591,422.205566 333.761108,422.326965 331.734131,422.308777 
	C330.353333,422.304047 329.336029,422.438873 327.946106,422.389740 
	C326.747864,422.204742 325.922241,422.203735 324.700867,422.179657 
	C323.561737,425.017609 323.017609,427.953308 321.985596,430.706146 
	C321.515289,431.960632 320.081299,432.853821 319.056885,433.663696 
	C319.029327,433.416504 318.994476,432.919037 319.000275,432.476471 
	C319.081177,422.348969 318.315186,421.627960 308.379181,422.038055 
	C308.052368,422.051514 307.726562,422.090851 307.250244,421.796143 
	C307.022766,421.008728 306.945343,420.543335 307.091064,419.754730 
	C307.919769,417.095398 308.932465,415.538849 311.853760,415.945435 
	C313.132904,416.123474 314.551727,415.297791 315.907135,414.927856 
	C317.966339,414.311127 320.025543,413.694366 322.489380,413.334229 
	C322.893982,413.590881 322.947754,414.044037 322.956207,414.425720 
	C324.542664,418.054321 326.287842,417.972504 328.561707,415.034973 
	C331.786560,412.476990 331.790009,410.575623 328.948639,407.664612 
	C328.967621,406.891083 328.999420,406.449585 329.379028,405.975952 
	C330.839020,405.624939 331.951172,405.306030 333.063354,404.987152 
	C333.201752,406.691528 333.340149,408.395874 333.331299,410.802002 
	C331.499786,414.928833 332.299042,416.904724 336.379700,416.795563 
	C338.324188,416.743530 340.263184,416.484619 342.204651,416.319427 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M333.123291,404.548767 
	C331.951172,405.306030 330.839020,405.624939 329.308289,405.614655 
	C328.106171,400.823883 324.685486,400.309906 321.081940,400.069580 
	C320.501190,398.962341 319.920441,397.855072 319.339722,396.747833 
	C320.358490,396.804840 321.377289,396.861877 322.752747,396.909119 
	C323.527679,396.914703 323.945984,396.930115 324.678101,396.964478 
	C327.329010,397.009399 329.666046,397.035370 332.385620,397.068054 
	C332.906525,399.419952 333.044891,401.765167 333.123291,404.548767 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M342.197021,416.154907 
	C340.263184,416.484619 338.324188,416.743530 336.379700,416.795563 
	C332.299042,416.904724 331.499786,414.928833 333.443970,411.211182 
	C334.942169,414.553955 337.839722,415.598328 341.680756,415.730499 
	C342.070251,415.683350 342.189392,415.990417 342.197021,416.154907 
z"/>
<path fill="#91B7EB" opacity="1.000000" stroke="none" 
	d="
M343.012177,412.972137 
	C342.822510,413.242432 342.632843,413.512726 342.220978,413.900055 
	C341.670166,413.555878 341.341583,413.094666 340.984283,412.338745 
	C340.955566,412.044067 340.985962,412.013885 341.295044,412.024445 
	C342.073486,412.347382 342.542816,412.659760 343.012177,412.972137 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M248.863037,516.501587 
	C241.187439,516.934937 233.603790,516.950684 225.563812,516.972778 
	C214.734253,516.969543 204.361023,516.960022 193.525330,516.964294 
	C192.748199,511.102661 192.200119,505.224091 192.280731,499.354156 
	C192.301239,497.860443 194.060867,495.454865 195.460663,495.071320 
	C203.179886,492.956299 203.233459,493.126312 202.902466,484.991791 
	C202.868988,484.169220 202.688202,483.352631 202.372025,481.828369 
	C202.147598,480.163696 202.126923,479.203918 202.190125,477.788025 
	C202.275467,473.257812 202.276962,469.183777 202.469391,464.664368 
	C202.594940,459.200714 202.529572,454.182343 202.592865,448.813354 
	C202.492676,447.298401 202.263840,446.134125 202.059113,444.552185 
	C202.441132,442.075439 202.799011,440.016357 203.417480,438.257141 
	C204.454758,442.059753 205.231415,445.562469 205.992828,449.524841 
	C206.323380,454.997803 206.669174,460.011108 207.003723,465.486450 
	C207.005554,470.300201 207.018646,474.651947 207.021698,479.469360 
	C206.990204,484.608582 206.968765,489.282104 206.634979,494.123596 
	C205.127319,495.158875 203.917664,496.780426 202.739288,496.757996 
	C198.479248,496.676971 197.337662,499.436127 196.689316,502.670227 
	C196.238464,504.919281 196.157288,507.248352 195.992981,509.547516 
	C195.747498,512.982849 197.357590,514.970215 200.824341,515.125549 
	C203.420639,515.242004 206.028946,515.065674 208.632004,515.060791 
	C219.526886,515.040466 230.426071,515.181519 241.311996,514.862732 
	C242.888779,514.816589 245.358231,512.858826 245.783005,511.331757 
	C247.917847,503.657043 245.336166,498.962555 237.971375,495.610168 
	C236.952744,490.158234 235.977295,485.081512 235.006226,479.616760 
	C235.351959,477.827332 235.693314,476.425842 236.306854,475.277649 
	C237.409256,477.364136 238.239502,479.197296 239.101517,481.463013 
	C239.501266,485.806976 239.869232,489.718384 240.243149,493.693024 
	C243.436401,494.197266 246.202240,494.634003 248.968063,495.070740 
	C249.071289,496.511475 249.174500,497.952240 249.515900,499.643799 
	C249.487717,505.291107 249.221344,510.687622 248.863037,516.501587 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M249.388641,495.042450 
	C246.202240,494.634003 243.436401,494.197266 240.243149,493.693024 
	C239.869232,489.718384 239.501266,485.806976 239.350555,481.134979 
	C239.687973,471.957306 239.808105,463.540161 240.303833,455.079865 
	C243.162064,456.236328 245.644730,457.435974 247.769897,458.766296 
	C244.054169,459.920715 243.904037,462.161774 245.086121,465.395142 
	C245.062469,472.584564 245.078384,479.302216 245.073486,486.419769 
	C245.800064,492.557281 251.118454,488.302429 253.745819,491.085938 
	C253.814438,492.082397 253.914856,493.540405 254.015259,494.998444 
	C252.613251,495.003693 251.211227,495.008911 249.388641,495.042450 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M254.269775,494.998962 
	C253.914856,493.540405 253.814438,492.082397 253.745819,491.085938 
	C251.118454,488.302429 245.800064,492.557281 245.473816,486.357300 
	C247.838959,485.596313 249.775574,485.119446 251.728577,485.038635 
	C255.138428,484.897614 258.558350,485.000275 262.218719,485.166809 
	C262.623657,485.891510 262.783936,486.450195 262.709106,487.219543 
	C263.662354,487.933899 264.850677,488.437622 266.030823,488.970032 
	C266.022675,488.998749 266.053406,488.943024 266.079224,489.262695 
	C266.516907,489.885040 266.928741,490.187714 267.340607,490.490387 
	C267.560394,489.660248 267.780182,488.830139 268.018585,487.565063 
	C267.738220,484.100128 267.439240,481.070160 267.458069,478.037445 
	C268.518005,478.338409 269.260101,478.642120 269.923035,479.171234 
	C269.876678,479.924225 269.909424,480.451813 269.942200,480.979401 
	C269.954987,484.678467 269.967743,488.377502 269.961853,492.537048 
	C268.831726,493.242310 267.720306,493.487091 265.844971,493.859680 
	C261.731842,494.326538 258.382568,494.665558 255.033310,495.004578 
	C254.863647,495.002869 254.693970,495.001160 254.269775,494.998962 
z"/>
<path fill="#8F8E92" opacity="1.000000" stroke="none" 
	d="
M255.094437,495.380737 
	C258.382568,494.665558 261.731842,494.326538 265.524597,493.997528 
	C262.758728,497.030792 259.156128,497.623840 255.094437,495.380737 
z"/>
<path fill="#1D8B97" opacity="1.000000" stroke="none" 
	d="
M353.016388,512.014648 
	C338.032135,519.294373 322.399048,519.308899 306.527649,516.001526 
	C304.154663,515.507019 301.949768,514.205811 299.301025,512.782898 
	C296.280212,509.860291 293.625458,507.432526 291.377808,505.003510 
	C312.562927,504.402130 333.403625,506.219604 355.163330,504.347626 
	C355.076843,504.270233 356.045319,505.137238 356.971069,506.364319 
	C355.624359,508.487823 354.320374,510.251221 353.016388,512.014648 
z"/>
<path fill="#3F7798" opacity="1.000000" stroke="none" 
	d="
M290.970673,505.004761 
	C293.625458,507.432526 296.280212,509.860291 298.974091,512.635071 
	C293.263733,509.008972 287.514282,505.035858 281.428162,501.011963 
	C278.765869,496.209290 276.440277,491.457428 274.495300,486.342621 
	C275.915131,485.995483 276.954315,486.011292 278.001953,486.404419 
	C280.058380,490.379272 279.067932,496.046112 284.984375,497.376404 
	C286.983887,500.161163 288.977264,502.582947 290.970673,505.004761 
z"/>
<path fill="#3F7798" opacity="1.000000" stroke="none" 
	d="
M353.380432,512.010437 
	C354.320374,510.251221 355.624359,508.487823 357.270081,506.213684 
	C359.411530,503.148407 361.211334,500.593903 363.232880,497.922485 
	C363.640625,497.533569 363.826569,497.261566 364.017151,496.663666 
	C364.020905,495.903290 364.020020,495.468811 364.358582,494.991028 
	C366.442444,493.282715 368.186859,491.617676 370.200928,489.970917 
	C370.650208,490.003265 370.829834,490.017303 370.857605,490.370605 
	C370.209076,492.959412 369.712463,495.208954 369.215820,497.458496 
	C368.979950,498.205841 368.744110,498.953217 368.242615,499.843445 
	C365.072266,502.738983 362.246033,505.581726 359.231812,508.208832 
	C357.565369,509.661255 355.583923,510.752258 353.380432,512.010437 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M376.793549,468.888855 
	C376.873810,468.174042 376.954071,467.459229 377.282898,466.461914 
	C377.531494,466.179443 378.006653,466.266968 378.070648,466.598816 
	C379.401947,467.314850 380.669250,467.699036 381.934998,468.390381 
	C381.935669,469.106171 381.937897,469.514832 381.577576,470.013855 
	C380.896698,476.864471 380.578339,483.624725 380.272034,490.129425 
	C379.149597,491.495667 378.075317,492.803284 377.001038,494.110870 
	C375.586731,494.411224 374.172455,494.711548 372.416382,494.615692 
	C372.619934,491.444977 373.165253,488.670410 373.810028,485.545441 
	C374.259033,483.799042 374.608551,482.403076 375.265198,480.773499 
	C375.639984,478.994385 375.707611,477.448914 375.830566,475.546875 
	C375.935730,474.470367 375.985504,473.750397 376.330811,472.801819 
	C376.682068,471.345093 376.737823,470.116974 376.793549,468.888855 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M377.421936,494.088257 
	C378.075317,492.803284 379.149597,491.495667 380.272034,490.129425 
	C380.578339,483.624725 380.896698,476.864471 381.613129,470.417603 
	C382.727020,474.705841 385.920044,472.308502 387.903809,473.021790 
	C387.975159,473.518799 388.046509,474.015778 388.263977,474.862000 
	C388.349182,475.607330 388.288239,476.003479 387.869080,476.913940 
	C387.475983,478.717651 387.441132,480.007019 387.406281,481.296356 
	C387.325470,481.682495 387.244690,482.068665 386.713257,482.794403 
	C385.892670,485.134644 385.522736,487.135254 385.152771,489.135895 
	C384.731079,489.851044 384.309387,490.566162 383.439270,491.619751 
	C383.027283,492.638947 383.063660,493.319641 383.100037,494.000366 
	C382.847687,494.765594 382.595276,495.530823 382.087891,496.455566 
	C380.502899,495.765289 379.172882,494.915436 377.421936,494.088257 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M373.710571,485.895874 
	C373.165253,488.670410 372.619934,491.444977 372.033142,494.602905 
	C371.259735,495.721283 370.527893,496.456329 369.505920,497.324921 
	C369.712463,495.208954 370.209076,492.959412 370.989868,490.059875 
	C372.086243,488.238525 372.898407,487.067230 373.710571,485.895874 
z"/>
<path fill="#498AAB" opacity="1.000000" stroke="none" 
	d="
M277.993530,486.027100 
	C276.954315,486.011292 275.915131,485.995483 274.489655,485.970367 
	C273.509552,484.500793 272.915710,483.040527 272.174316,480.963806 
	C271.723175,479.844757 271.419617,479.342102 271.260742,478.500488 
	C271.275208,471.110077 271.144928,464.058685 271.384827,457.001556 
	C272.495544,456.992279 273.236023,456.988739 273.977509,457.416443 
	C274.650452,460.571747 275.322418,463.295807 275.993164,466.475677 
	C276.332031,471.287415 276.672119,475.643402 277.163239,480.351868 
	C277.540680,482.478638 277.767120,484.252869 277.993530,486.027100 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M271.116028,478.839478 
	C271.419617,479.342102 271.723175,479.844757 272.042236,480.650391 
	C271.587891,480.961517 271.118011,480.969666 270.295166,480.978607 
	C269.909424,480.451813 269.876678,479.924225 270.202637,479.156372 
	C270.746246,478.890594 270.931122,478.865051 271.116028,478.839478 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M712.943848,481.990234 
	C711.476685,482.321838 710.009583,482.653412 708.247925,482.675049 
	C708.016602,481.937927 708.079712,481.510712 708.450806,480.963409 
	C710.672363,478.309753 712.585815,475.776215 714.775024,473.142151 
	C715.742310,472.381226 716.433899,471.720795 717.447632,470.996399 
	C718.206055,470.024231 718.642212,469.116058 719.078369,468.207886 
	C721.861816,464.848877 724.645264,461.489868 727.821655,457.644104 
	C729.282349,455.590820 730.350037,454.024323 731.417725,452.457825 
	C733.258667,450.540527 735.099609,448.623230 737.343933,446.362305 
	C738.135803,445.959930 738.524231,445.901215 738.981812,446.159332 
	C739.848755,446.596466 740.646667,446.716797 741.444519,446.837128 
	C740.927246,445.864410 740.410034,444.891693 739.892822,443.918976 
	C739.865173,443.737640 739.837585,443.556305 740.138550,443.072937 
	C740.962219,442.489075 741.457214,442.207306 742.103394,442.209564 
	C743.136108,442.997528 744.017761,443.501465 744.894897,444.328491 
	C744.574280,445.449158 744.258179,446.246704 743.792725,447.335510 
	C739.744690,452.750885 735.846130,457.874969 731.632263,463.139343 
	C725.744812,466.961792 721.873901,472.070526 718.803833,478.243530 
	C717.751465,478.993805 716.872070,479.496307 715.706299,480.115479 
	C714.594482,480.818146 713.769165,481.404175 712.943848,481.990234 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M744.899353,444.005371 
	C744.017761,443.501465 743.136108,442.997528 742.220337,441.918518 
	C742.502441,440.588867 742.818787,439.834259 743.135132,439.079620 
	C743.135071,439.079620 743.401978,438.890106 743.789062,438.627411 
	C746.212158,435.647552 748.248108,432.930359 750.568237,430.048218 
	C753.972046,426.278900 757.091614,422.674530 760.548096,419.002747 
	C761.279968,417.941193 761.674866,416.947083 762.069763,415.952972 
	C765.952209,417.292816 765.644348,419.379059 763.532776,422.295380 
	C762.027222,424.374786 761.132507,426.896362 759.646606,429.768188 
	C757.292480,431.782867 755.036987,433.026733 753.270996,434.767303 
	C750.315979,437.679810 747.673401,440.909271 744.899353,444.005371 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M750.284058,430.213135 
	C748.248108,432.930359 746.212158,435.647552 743.858154,438.479034 
	C743.395813,437.509338 743.251526,436.425323 743.108948,435.354065 
	C737.969849,433.877441 733.201782,433.601135 729.571411,438.785278 
	C729.037231,438.352814 728.971924,438.156769 728.922607,437.643616 
	C729.020996,435.906860 729.103455,434.487183 729.508972,433.045776 
	C730.862854,432.343140 731.893738,431.662201 733.294189,430.989319 
	C734.766113,430.693695 735.868286,430.390076 737.373535,430.068176 
	C741.945740,430.104309 746.114868,430.158722 750.284058,430.213135 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M725.946289,455.840118 
	C724.511108,455.489899 722.983643,455.344086 721.660034,454.746643 
	C718.947754,453.522339 717.115051,453.934875 715.437622,456.604401 
	C714.727661,457.734253 712.799622,458.098755 711.168579,458.389954 
	C712.854309,453.761200 714.796570,449.553619 716.738892,445.346039 
	C720.986572,445.350433 718.305725,447.729950 717.787415,449.851624 
	C718.662476,450.911194 719.833069,451.386017 721.003662,451.860840 
	C721.003662,451.860840 721.464783,451.834412 722.095886,451.884644 
	C723.800476,451.994690 724.873901,452.054443 725.961853,452.489166 
	C725.966309,453.856140 725.956238,454.848114 725.946289,455.840118 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M729.185913,433.067474 
	C729.103455,434.487183 729.020996,435.906860 728.707886,437.809326 
	C728.278564,438.783112 728.079895,439.274139 727.881165,439.765167 
	C726.495483,439.810913 725.109802,439.856659 723.339600,439.734344 
	C727.633728,435.437866 726.445618,429.388062 728.480835,424.538971 
	C729.051147,427.588318 729.118530,430.327881 729.185913,433.067474 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M716.356934,445.306641 
	C714.796570,449.553619 712.854309,453.761200 710.791260,458.283722 
	C710.225830,458.656036 709.781189,458.713440 709.041016,458.601685 
	C709.556580,456.744995 710.429077,455.083618 711.163574,453.363342 
	C712.139587,451.077759 713.007996,448.746277 713.853394,446.088806 
	C714.514465,445.584473 715.244751,445.425842 716.356934,445.306641 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M697.715088,423.228271 
	C698.408264,426.050720 698.716858,429.069946 699.018372,432.956787 
	C699.054871,435.777985 699.098389,437.731598 699.141968,439.685211 
	C698.429016,438.884094 697.118652,438.095306 697.101868,437.279846 
	C697.006592,432.665131 697.221313,428.044006 697.715088,423.228271 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M699.543579,439.793396 
	C699.098389,437.731598 699.054871,435.777985 699.051147,433.399902 
	C700.735901,434.934265 702.380798,436.893066 704.035522,439.181580 
	C702.678589,439.641388 701.311890,439.771454 699.543579,439.793396 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M726.263733,456.083038 
	C725.956238,454.848114 725.966309,453.856140 726.233643,452.445068 
	C726.672729,452.003723 726.854492,451.981445 727.036316,451.959137 
	C728.209595,452.027344 729.382935,452.095520 730.986938,452.310791 
	C730.350037,454.024323 729.282349,455.590820 727.955017,457.323425 
	C727.323975,457.101685 726.952637,456.713806 726.263733,456.083038 
z"/>
<path fill="#0C1A28" opacity="1.000000" stroke="none" 
	d="
M727.016113,451.587952 
	C726.854492,451.981445 726.672729,452.003723 726.219177,452.070099 
	C724.873901,452.054443 723.800476,451.994690 722.326111,451.867157 
	C723.277405,448.662720 724.818176,447.167633 727.016113,451.587952 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M739.681091,443.937561 
	C740.410034,444.891693 740.927246,445.864410 741.444519,446.837128 
	C740.646667,446.716797 739.848755,446.596466 739.035583,445.860352 
	C739.031067,444.843353 739.041870,444.442139 739.052673,444.040894 
	C739.052673,444.040894 739.469360,443.956177 739.681091,443.937561 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M742.776123,439.072388 
	C742.818787,439.834259 742.502441,440.588867 742.069214,441.634521 
	C741.457214,442.207306 740.962219,442.489075 740.182251,442.801331 
	C740.737305,441.576263 741.577209,440.320740 742.776123,439.072388 
z"/>
<path fill="#0C1A28" opacity="1.000000" stroke="none" 
	d="
M720.816528,451.630798 
	C719.833069,451.386017 718.662476,450.911194 717.724670,450.221832 
	C718.848083,450.471771 719.738770,450.936279 720.816528,451.630798 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M738.721252,444.014069 
	C739.041870,444.442139 739.031067,444.843353 738.966492,445.543579 
	C738.524231,445.901215 738.135803,445.959930 737.452881,446.031647 
	C737.568909,445.358856 737.979370,444.673035 738.721252,444.014069 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M728.164185,439.819763 
	C728.079895,439.274139 728.278564,438.783112 728.691956,438.126404 
	C728.971924,438.156769 729.037231,438.352814 729.206604,438.841553 
	C729.022888,439.380920 728.735046,439.627625 728.164185,439.819763 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M717.125427,471.060364 
	C716.433899,471.720795 715.742310,472.381226 714.468140,472.876770 
	C710.907104,471.758545 707.928589,470.805176 704.950134,469.851776 
	C704.954590,469.457214 704.959045,469.062683 704.961914,468.373779 
	C708.739502,468.411285 712.518616,468.743195 716.702148,469.427673 
	C717.112793,470.206940 717.119141,470.633636 717.125427,471.060364 
z"/>
<path fill="#3F7798" opacity="1.000000" stroke="none" 
	d="
M717.447632,470.996399 
	C717.119141,470.633636 717.112793,470.206940 717.135010,469.462036 
	C717.619019,468.839539 718.074463,468.535217 718.804138,468.219391 
	C718.642212,469.116058 718.206055,470.024231 717.447632,470.996399 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M705.667786,461.402466 
	C705.285645,460.908966 705.193787,460.424377 705.154785,459.571960 
	C705.618835,459.136871 706.029968,459.069672 706.760254,459.360291 
	C707.072083,460.156158 707.064636,460.594177 707.057190,461.032227 
	C706.690796,461.158630 706.324402,461.285034 705.667786,461.402466 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M707.385864,460.995239 
	C707.064636,460.594177 707.072083,460.156158 707.073059,459.388794 
	C707.479187,458.952393 707.891785,458.845367 708.613770,458.821289 
	C708.520325,459.588959 708.117432,460.273590 707.385864,460.995239 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M704.812378,470.116638 
	C707.928589,470.805176 710.907104,471.758545 714.192383,472.977295 
	C712.585815,475.776215 710.672363,478.309753 708.085693,480.959229 
	C706.261597,482.695374 705.110596,484.315521 703.959595,485.935669 
	C703.959595,485.935638 703.977356,485.975403 703.952576,485.969971 
	C699.861816,485.351929 695.795898,484.739319 691.320435,484.120422 
	C690.635010,483.786316 690.359009,483.458466 689.766357,483.047424 
	C689.815735,482.034851 690.181763,481.105469 690.542847,479.806793 
	C690.845947,479.232666 691.154114,479.027832 691.622314,478.813110 
	C694.247559,477.826935 696.712769,476.850708 699.737427,476.205170 
	C702.603882,477.090424 704.910767,477.645020 707.685852,478.312134 
	C705.598328,475.506958 704.299194,473.761108 703.000000,472.015259 
	C703.558228,471.470673 704.116455,470.926086 704.812378,470.116638 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M702.708252,472.138184 
	C704.299194,473.761108 705.598328,475.506958 707.685852,478.312134 
	C704.910767,477.645020 702.603882,477.090424 700.120239,476.179260 
	C700.767883,474.635498 701.592224,473.448303 702.708252,472.138184 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M315.939209,414.469360 
	C314.551727,415.297791 313.132904,416.123474 311.853760,415.945435 
	C308.932465,415.538849 307.919769,417.095398 306.768524,419.743591 
	C305.791473,420.046143 305.360046,420.036682 304.956573,419.653137 
	C305.658783,417.514923 306.333038,415.750732 307.384064,413.984314 
	C313.624756,411.879639 314.011169,410.738708 314.111938,401.326355 
	C314.157715,397.052704 312.376587,395.044037 308.359100,395.021057 
	C294.901306,394.944061 281.441254,394.893097 267.986633,395.112091 
	C266.383270,395.138184 263.591309,396.726715 263.438934,397.869507 
	C262.897919,401.926758 262.599243,406.272675 263.585815,410.159790 
	C264.023254,411.883392 267.751526,412.771790 269.987915,414.408051 
	C269.952515,415.266693 269.915161,415.756561 269.447266,416.209778 
	C267.000793,416.154694 264.984894,416.136261 262.766052,415.856750 
	C261.703949,415.410675 260.844757,415.225708 259.927490,414.723175 
	C259.731323,414.000031 259.593201,413.594421 259.605621,412.792664 
	C259.745667,411.557251 259.735168,410.717987 259.812958,409.472717 
	C259.792664,404.773926 259.684052,400.481140 259.731171,395.811890 
	C259.987579,394.627472 260.088287,393.819550 260.188995,393.011597 
	C277.102203,392.993805 294.015991,392.909393 310.927704,393.060303 
	C312.636597,393.075531 314.332764,394.511383 316.101685,395.712524 
	C316.102783,402.095032 316.037018,408.052948 315.939209,414.469360 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M259.754150,392.966187 
	C260.088287,393.819550 259.987579,394.627472 259.383453,395.902527 
	C258.333130,398.475830 257.786224,400.582031 257.135712,403.087250 
	C254.462341,403.087250 251.845779,403.087250 249.229218,403.087250 
	C249.444458,400.680450 249.659698,398.273682 249.841309,395.473206 
	C251.202652,394.206024 252.504898,392.898621 254.017471,392.575317 
	C255.684006,392.219116 257.543762,392.766846 259.754150,392.966187 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M321.244202,400.407379 
	C324.685486,400.309906 328.106171,400.823883 328.960480,405.646790 
	C328.999420,406.449585 328.967621,406.891083 328.675262,407.931732 
	C328.278229,410.356506 328.141785,412.182159 327.584656,414.003784 
	C325.758575,414.014526 324.353180,414.029297 322.947754,414.044037 
	C322.947754,414.044037 322.893982,413.590881 322.869446,413.364990 
	C322.365417,409.007782 321.885956,404.876495 321.244202,400.407379 
z"/>
<path fill="#24909A" opacity="1.000000" stroke="none" 
	d="
M560.001221,496.998779 
	C563.372620,495.998993 566.779602,494.075165 570.104858,494.207245 
	C575.846069,494.435303 581.536499,495.940582 587.650818,496.952393 
	C596.025391,498.381805 600.084961,503.202942 598.483276,509.158691 
	C597.580078,512.517212 595.835327,514.133789 592.147278,514.080750 
	C581.000854,513.920532 569.850586,514.029968 558.701843,514.023010 
	C549.712769,514.017334 546.948853,508.806366 552.209229,500.348114 
	C555.198853,499.004822 557.598206,498.003021 559.997559,497.001221 
	C559.997559,497.001221 560.000000,497.000000 560.001221,496.998779 
z"/>
<path fill="#3099A8" opacity="1.000000" stroke="none" 
	d="
M559.603455,497.005066 
	C557.598206,498.003021 555.198853,499.004822 552.394287,500.013855 
	C551.981506,499.852173 551.973816,499.683228 551.946167,499.261444 
	C554.353943,498.342041 556.781616,497.675507 559.603455,497.005066 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M186.993896,468.008545 
	C187.989883,466.250122 188.985870,464.491699 190.273010,462.161255 
	C192.606903,457.374847 194.649628,453.160461 196.734009,449.322968 
	C195.199844,455.809784 193.624008,461.919678 191.835831,468.328583 
	C191.160858,470.711853 190.698196,472.796112 190.096588,475.243683 
	C189.312210,478.062103 188.666763,480.517242 188.021301,482.972382 
	C187.014618,486.380402 186.007935,489.788391 184.879364,493.566406 
	C183.018387,497.823700 181.279282,501.710968 179.257980,505.388611 
	C178.971466,503.783875 178.967148,502.388702 178.982727,500.577087 
	C179.349396,497.111877 179.696167,494.063141 180.414627,490.934021 
	C181.529144,486.908783 182.271957,482.963959 183.299164,478.799347 
	C184.720322,475.055908 185.857101,471.532227 186.993896,468.008545 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M188.430496,482.988953 
	C188.666763,480.517242 189.312210,478.062103 190.460037,475.273621 
	C194.677032,476.041595 198.391647,477.142853 202.106262,478.244141 
	C202.126923,479.203918 202.147598,480.163696 202.160843,481.478943 
	C200.774414,482.906647 198.359787,483.861328 198.222107,485.074463 
	C197.888474,488.014069 197.042480,490.272491 193.988480,489.673950 
	C192.965302,489.473419 192.508224,486.384491 191.792389,484.615845 
	C192.139633,484.255432 192.486877,483.894989 192.834122,483.534546 
	C191.502640,483.358185 190.171173,483.181854 188.430496,482.988953 
z"/>
<path fill="#B2D5DD" opacity="1.000000" stroke="none" 
	d="
M441.408997,564.993530 
	C443.402374,563.660461 445.797424,562.332886 448.590729,561.009949 
	C448.118652,565.085693 444.905792,564.936279 441.408997,564.993530 
z"/>
<path fill="#91B7EB" opacity="1.000000" stroke="none" 
	d="
M179.728180,400.931793 
	C179.959885,399.781067 180.191605,398.630341 180.633148,397.200317 
	C186.187668,396.275208 191.524185,395.167114 196.878632,395.072388 
	C211.456696,394.814392 226.043518,395.088348 240.624344,394.925903 
	C245.338593,394.873352 246.860062,396.741180 247.159256,401.389008 
	C247.514587,406.908722 246.095169,411.250702 241.941559,415.219299 
	C241.934494,415.656860 241.878265,415.847076 241.549179,415.738861 
	C240.766983,415.714661 240.313873,415.798706 239.512787,415.933533 
	C238.806015,417.102386 238.478455,418.231720 238.078766,419.334961 
	C237.750046,420.242279 237.335724,421.118591 236.692551,421.746948 
	C236.275009,419.973511 236.124252,418.461731 235.979218,416.537537 
	C235.139404,412.225739 238.138702,411.902771 240.628677,411.260498 
	C246.216751,409.819092 243.876099,405.277496 243.695572,402.161743 
	C243.609970,400.684692 240.550568,398.214874 238.800079,398.181091 
	C222.821274,397.872559 206.832886,397.913544 190.850754,398.120789 
	C188.978683,398.145081 186.090973,399.338562 185.466934,400.771027 
	C183.791809,404.616302 183.089142,408.885254 181.741623,413.190063 
	C180.657913,414.264191 179.832870,415.134247 179.048859,415.606995 
	C179.302658,410.450409 179.515411,405.691101 179.728180,400.931793 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M259.455078,413.188843 
	C259.593201,413.594421 259.731323,414.000031 259.940002,415.044037 
	C260.996704,415.827606 261.982819,415.972717 262.968964,416.117828 
	C264.984894,416.136261 267.000793,416.154694 269.472229,416.408783 
	C269.927734,416.644440 270.206024,416.934357 270.101257,417.350891 
	C269.894470,419.472687 269.792450,421.177948 269.331940,422.893494 
	C267.310577,423.065887 265.647705,423.228088 263.706635,423.310852 
	C263.053772,423.308563 262.679077,423.385712 261.882172,423.325134 
	C256.281433,422.466553 251.108932,421.698059 245.922165,421.042542 
	C242.764771,420.643494 241.233704,419.130646 241.878265,415.847076 
	C241.878265,415.847076 241.934494,415.656860 241.938812,415.557434 
	C243.639450,415.587311 245.335770,415.716644 247.846634,415.948700 
	C252.259155,415.097229 255.857117,414.143036 259.455078,413.188843 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M249.138062,403.465515 
	C251.845779,403.087250 254.462341,403.087250 257.135712,403.087250 
	C257.786224,400.582031 258.333130,398.475830 259.227753,396.278992 
	C259.684052,400.481140 259.792664,404.773926 259.459839,409.433441 
	C255.725876,408.848602 252.433350,407.897034 249.140823,406.945496 
	C249.109528,405.911591 249.078217,404.877655 249.138062,403.465515 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M249.088837,407.371948 
	C252.433350,407.897034 255.725876,408.848602 259.371521,409.839417 
	C259.735168,410.717987 259.745667,411.557251 259.605621,412.792694 
	C255.857117,414.143036 252.259155,415.097229 248.275833,415.965179 
	C248.272614,413.185425 248.654739,410.491943 249.088837,407.371948 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M165.500153,457.945007 
	C166.758331,450.850250 168.405090,443.783661 169.979050,436.700897 
	C170.406540,434.777222 170.614319,432.804749 171.210968,430.205841 
	C171.747284,427.794067 171.995590,426.031097 172.243881,424.268127 
	C172.584534,424.130249 172.925186,423.992371 173.598190,424.211823 
	C173.935608,427.390533 173.940674,430.211914 173.960999,433.474792 
	C173.313324,438.941132 172.650391,443.966034 171.987457,448.990906 
	C169.772461,451.864929 170.919586,457.033539 165.500153,457.945007 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M173.945740,433.033295 
	C173.940674,430.211914 173.935608,427.390533 173.862061,424.131958 
	C175.588364,416.270294 177.383118,408.845856 179.453033,401.176605 
	C179.515411,405.691101 179.302658,410.450409 178.887695,415.962891 
	C177.791000,421.478027 176.896500,426.239960 175.753967,431.158783 
	C174.985901,431.888214 174.465820,432.460754 173.945740,433.033295 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M171.818939,424.207489 
	C171.995590,426.031097 171.747284,427.794067 171.193558,429.791687 
	C169.591476,430.043793 168.294846,430.061218 166.616760,430.124695 
	C166.641785,428.266113 167.048279,426.361420 167.721848,424.217407 
	C169.123947,424.034332 170.258987,424.090576 171.818939,424.207489 
z"/>
<path fill="#88888B" opacity="1.000000" stroke="none" 
	d="
M171.578888,407.266724 
	C171.528351,406.340027 171.790344,405.253113 172.385895,404.068329 
	C172.443451,405.015839 172.167419,406.061188 171.578888,407.266724 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M167.992096,422.618561 
	C167.729889,422.008667 167.489319,420.999023 167.538132,419.902863 
	C167.889572,420.617188 167.951645,421.417969 167.992096,422.618561 
z"/>
<path fill="#1D8B97" opacity="1.000000" stroke="none" 
	d="
M178.962814,500.993561 
	C178.967148,502.388702 178.971466,503.783875 178.968735,505.598328 
	C178.412476,506.627045 177.415863,507.213226 177.382797,507.849518 
	C177.027008,514.694702 172.095123,514.907471 167.142639,514.997437 
	C162.502792,515.081726 157.859985,514.998169 153.218475,514.995972 
	C144.199707,514.991699 142.540543,513.691772 140.428177,504.779236 
	C140.127090,503.508850 140.120865,502.168610 140.371933,500.478912 
	C147.130524,500.021545 153.496964,499.822662 159.859741,499.905121 
	C166.237198,499.987701 172.751266,497.966095 178.962814,500.993561 
z"/>
<path fill="#24909A" opacity="1.000000" stroke="none" 
	d="
M178.982727,500.577087 
	C172.751266,497.966095 166.237198,499.987701 159.859741,499.905121 
	C153.496964,499.822662 147.130524,500.021545 140.376648,500.062012 
	C139.987579,500.026855 139.495987,500.031128 139.251816,499.612549 
	C138.680634,496.797577 138.353622,494.401154 138.381882,491.942230 
	C140.412613,490.628357 142.072708,488.329926 143.766174,488.305054 
	C151.529007,488.191162 159.312866,488.542755 167.065399,489.074066 
	C171.415131,489.372131 175.718613,490.345367 180.042923,491.014404 
	C179.696167,494.063141 179.349396,497.111877 178.982727,500.577087 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M138.026611,492.004761 
	C138.353622,494.401154 138.680634,496.797577 139.006012,499.613190 
	C138.354462,499.838898 137.293747,499.822937 137.120941,499.423157 
	C135.944763,496.702240 134.955505,493.900482 133.575714,491.021851 
	C132.923233,490.438873 132.600937,489.958801 132.138962,488.848877 
	C131.648422,486.820679 131.297546,485.422363 130.895386,483.652008 
	C129.700363,478.504089 128.556656,473.728241 127.766693,468.958496 
	C129.063873,468.977905 130.007309,468.991150 131.248322,469.221130 
	C132.247437,471.437775 132.944794,473.439178 133.651581,475.437256 
	C134.427582,477.631012 135.212265,479.821716 135.991348,482.429504 
	C136.668549,485.898407 137.347580,488.951599 138.026611,492.004761 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M138.011658,510.633423 
	C136.219757,511.969208 134.434586,512.976685 132.336136,514.160889 
	C131.612549,513.124451 130.525330,511.567169 128.800262,509.096252 
	C131.685776,508.675690 133.963028,508.343750 136.666016,508.009766 
	C137.400620,508.773468 137.709488,509.539307 138.011658,510.633423 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M115.909126,435.002289 
	C115.949280,434.546295 115.989433,434.090271 116.391068,433.372955 
	C118.887054,440.383606 121.021561,447.655548 123.113403,455.235779 
	C123.384636,456.035461 123.698532,456.526886 124.008629,457.401459 
	C124.363281,459.500000 124.721733,461.215363 124.644539,462.949371 
	C122.311340,462.967987 120.413780,462.967987 119.438904,462.967987 
	C118.903297,467.429749 118.487549,471.008240 118.040291,474.582794 
	C117.676247,477.492218 118.164085,479.610504 121.903290,479.325439 
	C121.592392,482.944550 121.243324,486.143341 120.861931,489.638306 
	C123.119141,489.815887 124.577805,489.930664 126.036469,490.045410 
	C125.812012,490.999115 125.587555,491.952820 125.369934,492.908112 
	C131.409943,496.162109 131.968063,499.618988 126.561172,502.613037 
	C126.777321,494.980011 126.777321,494.979675 118.963600,494.960327 
	C118.639046,494.959534 118.314445,494.975983 117.989876,494.984436 
	C117.200294,494.105377 116.410713,493.226349 115.357117,491.706390 
	C115.105118,488.083466 115.086922,485.100952 115.134224,482.119537 
	C115.383415,466.413635 115.649139,450.708008 115.909126,435.002289 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M126.261642,490.014282 
	C124.577805,489.930664 123.119141,489.815887 120.861931,489.638306 
	C121.243324,486.143341 121.592392,482.944550 121.926323,478.998962 
	C121.974365,477.470306 122.037552,476.688507 122.212997,474.517792 
	C123.848396,478.148285 124.937431,480.565918 126.016319,483.381500 
	C126.316971,485.830505 126.627777,487.881500 126.938591,489.932526 
	C126.938591,489.932526 126.486809,489.983154 126.261642,490.014282 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M118.038651,495.336487 
	C118.314445,494.975983 118.639046,494.959534 118.963600,494.960327 
	C126.777321,494.979675 126.777321,494.980011 126.217918,502.699707 
	C126.308174,503.104004 126.172714,503.564880 126.118805,503.799316 
	C125.299576,503.511658 124.393547,503.115204 123.794731,502.444489 
	C121.832169,500.246185 119.981270,497.948181 118.038651,495.336487 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M127.309113,489.920227 
	C126.627777,487.881500 126.316971,485.830505 126.336197,483.391602 
	C128.093048,483.343842 129.519867,483.683960 130.946686,484.024048 
	C131.297546,485.422363 131.648422,486.820679 131.980194,488.608765 
	C130.533951,489.301636 129.106796,489.604797 127.309113,489.920227 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M127.137604,512.839783 
	C126.652626,512.479248 126.403664,511.978485 126.229431,511.186401 
	C126.660652,511.496582 127.017128,512.098083 127.137604,512.839783 
z"/>
<path fill="#50ABC1" opacity="1.000000" stroke="none" 
	d="
M875.907898,448.008148 
	C878.029297,448.331726 880.161926,448.993347 882.270142,448.922974 
	C894.303528,448.521484 906.556885,450.765137 918.631165,446.069794 
	C917.310181,448.424011 915.820068,450.993591 913.871216,453.148254 
	C909.610718,457.858673 905.117249,462.358307 900.280640,466.599670 
	C896.731812,466.121368 893.622070,465.958710 890.510376,465.859009 
	C880.988647,465.553894 871.466125,465.273041 861.994812,464.624817 
	C865.735046,458.053162 869.754333,452.120819 875.907898,448.008148 
z"/>
<path fill="#40A3B3" opacity="1.000000" stroke="none" 
	d="
M861.943909,464.982727 
	C871.466125,465.273041 880.988647,465.553894 890.510376,465.859009 
	C893.622070,465.958710 896.731812,466.121368 899.929504,466.643738 
	C899.092957,469.150055 898.169312,471.267883 897.101990,473.707062 
	C894.155090,476.555054 891.351868,479.081726 887.865479,481.806274 
	C874.200684,478.173004 861.095154,479.237305 847.971680,481.010986 
	C848.310608,479.597595 848.649475,478.184204 849.361389,476.388245 
	C850.955200,475.100800 852.273743,474.300629 853.377991,473.270752 
	C856.283081,470.561432 859.094971,467.752197 861.943909,464.982727 
z"/>
<path fill="#89A1D8" opacity="1.000000" stroke="none" 
	d="
M922.731567,442.123230 
	C923.311951,442.461517 923.628784,442.922424 923.959351,443.678040 
	C923.234314,443.984833 922.495544,443.996857 921.387329,444.007111 
	C921.501282,443.418823 921.984619,442.832336 922.731567,442.123230 
z"/>
<path fill="#208E98" opacity="1.000000" stroke="none" 
	d="
M811.960693,496.036621 
	C811.976868,499.859741 811.600647,503.735016 812.100220,507.493896 
	C812.873779,513.313660 807.570190,515.445251 803.450256,514.946228 
	C799.523438,514.470581 795.559570,514.057190 791.610718,514.045959 
	C764.515869,513.968933 737.420654,514.010925 710.325562,514.000610 
	C702.187317,513.997498 701.081604,512.540100 702.665344,504.433228 
	C703.105652,502.178986 703.169067,499.851074 703.936890,497.534546 
	C707.857849,497.623199 711.257202,497.954956 714.635193,497.805084 
	C726.099976,497.296539 737.557190,496.617737 749.349854,496.150574 
	C753.091003,497.196381 756.458313,498.591125 759.917847,498.879456 
	C765.187134,499.318604 770.530396,498.788116 775.823120,499.052979 
	C780.263062,499.275208 784.353455,498.734222 788.354614,495.963684 
	C789.843628,495.679749 791.202454,494.899963 792.029663,495.279297 
	C798.607971,498.295776 805.278137,496.981018 811.960693,496.036621 
z"/>
<path fill="#3099A8" opacity="1.000000" stroke="none" 
	d="
M749.017334,496.003967 
	C737.557190,496.617737 726.099976,497.296539 714.635193,497.805084 
	C711.257202,497.954956 707.857849,497.623199 704.225220,497.251984 
	C703.555847,490.529999 709.611877,488.625153 713.190918,484.274109 
	C714.465698,482.618683 715.229187,481.308777 715.992676,479.998840 
	C716.872070,479.496307 717.751465,478.993805 719.218628,478.245422 
	C723.303162,478.367798 726.792053,478.910736 730.298340,479.070038 
	C739.199768,479.474487 748.108948,479.707092 756.976440,480.361389 
	C755.615479,483.460083 754.293091,486.205933 752.589966,488.976257 
	C748.133179,489.962341 749.257507,493.294128 749.017334,496.003967 
z"/>
<path fill="#3099A8" opacity="1.000000" stroke="none" 
	d="
M811.974854,495.583435 
	C805.278137,496.981018 798.607971,498.295776 792.029663,495.279297 
	C791.202454,494.899963 789.843628,495.679749 788.368286,495.547974 
	C788.320679,491.787506 788.635986,488.401550 788.951233,485.015564 
	C789.575989,477.213562 790.857178,476.019836 798.630005,475.998077 
	C799.618408,475.995300 800.606812,475.992035 801.595215,475.991211 
	C811.533569,475.982971 811.973938,476.411957 812.028625,486.581482 
	C812.025269,489.726776 812.007141,492.428497 811.974854,495.583435 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M704.260925,485.852966 
	C705.110596,484.315521 706.261597,482.695374 707.777710,481.079346 
	C708.079712,481.510712 708.016602,481.937927 707.866333,482.679077 
	C706.706848,483.918762 705.634521,484.844513 704.260925,485.852966 
z"/>
<path fill="#40A3B3" opacity="1.000000" stroke="none" 
	d="
M715.706299,480.115509 
	C715.229187,481.308777 714.465698,482.618683 713.344971,483.977539 
	C712.981628,483.573822 712.975464,483.121246 712.956543,482.329407 
	C713.769165,481.404175 714.594482,480.818146 715.706299,480.115509 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M815.965454,515.637207 
	C815.210449,515.988708 814.474426,515.996216 813.369751,516.000549 
	C813.723694,515.342957 814.446350,514.688538 815.464539,513.985107 
	C815.834900,514.388489 815.909668,514.840881 815.965454,515.637207 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M810.096191,438.921173 
	C809.807495,438.451050 809.518799,437.980927 809.125549,437.255615 
	C809.487244,435.352386 809.953430,433.704376 811.033081,432.036743 
	C812.061096,432.034698 812.475586,432.052216 813.223328,432.217529 
	C817.963989,433.827057 822.371399,435.288818 827.080383,436.935272 
	C828.239807,437.422852 829.097595,437.725739 829.955383,438.028625 
	C829.955383,438.028625 830.121033,438.366638 830.033020,438.923218 
	C829.344543,439.917725 828.707764,440.777100 828.149292,440.728851 
	C822.124512,440.208496 816.112000,439.545319 810.096191,438.921173 
z"/>
<path fill="#456D8D" opacity="1.000000" stroke="none" 
	d="
M826.778748,436.750549 
	C822.371399,435.288818 817.963989,433.827057 813.265564,431.877136 
	C812.999634,430.933868 813.024841,430.478760 813.049988,430.023651 
	C813.049988,430.023651 813.517517,429.973785 814.206909,429.969666 
	C816.853943,429.957306 818.811462,429.950165 820.769043,429.940582 
	C827.017700,429.909943 827.291565,430.219879 826.778748,436.750549 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M827.080383,436.935242 
	C827.291565,430.219879 827.017700,429.909943 820.769043,429.940582 
	C818.811462,429.950165 816.853943,429.957306 814.435303,429.914001 
	C817.412842,426.689331 821.492249,427.419830 825.520691,428.023407 
	C826.784546,428.212708 828.008789,428.666382 829.653564,429.009460 
	C830.059570,431.722870 830.063171,434.425903 830.011108,437.578796 
	C829.097595,437.725739 828.239807,437.422852 827.080383,436.935242 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M812.724609,430.047302 
	C813.024841,430.478760 812.999634,430.933868 812.932251,431.729370 
	C812.475586,432.052216 812.061096,432.034698 811.336182,431.993530 
	C811.483521,431.336914 811.941406,430.703918 812.724609,430.047302 
z"/>
<path fill="#010204" opacity="1.000000" stroke="none" 
	d="
M474.745544,235.231842 
	C476.642181,239.083405 478.280457,243.168777 479.955078,247.627274 
	C478.341156,245.762421 476.357880,243.687057 475.157532,241.229324 
	C474.356903,239.589981 474.671600,237.405914 474.745544,235.231842 
z"/>
<path fill="#010204" opacity="1.000000" stroke="none" 
	d="
M454.270874,248.769882 
	C453.004242,246.911697 452.007294,244.823700 451.009277,242.367645 
	C454.574890,242.889420 455.362335,245.280182 454.270874,248.769882 
z"/>
<path fill="#010204" opacity="1.000000" stroke="none" 
	d="
M455.990479,253.661011 
	C455.345734,253.225220 454.698914,252.434464 454.032776,251.321686 
	C454.671783,251.768463 455.330109,252.537262 455.990479,253.661011 
z"/>
<path fill="#F8F9FA" opacity="1.000000" stroke="none" 
	d="
M532.563660,215.447403 
	C533.940674,215.404175 534.869080,215.513275 536.115723,215.458405 
	C536.218262,216.976120 536.354309,218.801758 535.733826,220.317749 
	C527.266113,241.007629 518.693726,261.654663 509.736755,282.732727 
	C508.861450,283.182709 508.396545,283.212250 507.931671,283.241760 
	C498.659088,261.023773 489.386475,238.805801 479.701508,215.599731 
	C497.641754,215.599731 514.878357,215.599731 532.563660,215.447403 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M543.084961,216.334366 
	C547.788940,215.612244 547.570923,217.973831 546.198303,221.240097 
	C541.404907,232.646500 536.747681,244.110489 531.903870,255.495178 
	C529.839661,260.346802 527.223938,264.968231 525.247070,269.851410 
	C522.261963,277.225220 519.810364,284.819031 516.698303,292.134888 
	C515.862732,294.099121 513.455811,295.394989 511.398682,296.996582 
	C516.742554,283.714203 522.605774,270.493896 528.122681,257.130615 
	C531.951172,247.857224 535.285034,238.380142 538.880005,229.009476 
	C539.821655,226.555130 540.913086,224.158295 542.248291,221.135529 
	C542.723206,219.371353 542.885559,218.206711 543.047913,217.042084 
	C543.047913,217.042084 543.070129,216.570145 543.084961,216.334366 
z"/>
<path fill="#0E283D" opacity="1.000000" stroke="none" 
	d="
M506.022949,296.608704 
	C504.679871,295.761566 502.726746,294.782288 502.030609,293.236847 
	C496.948120,281.953705 492.005463,270.603821 487.242157,259.181763 
	C483.018768,249.054352 479.150879,238.779114 474.986694,228.626282 
	C473.358521,224.656433 471.350342,220.842438 469.515228,216.957458 
	C470.039185,216.600372 470.563110,216.243271 471.087036,215.886185 
	C472.187012,216.815247 473.286987,217.744308 474.693298,218.839569 
	C475.008636,219.444733 475.017609,219.883698 475.129730,220.876953 
	C475.420380,221.709549 475.671875,221.905792 475.987427,222.019974 
	C480.690521,233.420929 485.459778,244.795166 490.079437,256.229828 
	C495.452972,269.530396 500.695526,282.883911 506.022949,296.608704 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M507.892578,283.621887 
	C508.396545,283.212250 508.861450,283.182709 509.671997,283.100037 
	C510.017639,286.388397 510.017639,289.729858 510.017639,293.071350 
	C509.296265,293.077026 508.574860,293.082703 507.853485,293.088379 
	C507.853485,290.059570 507.853485,287.030792 507.892578,283.621887 
z"/>
<path fill="#061421" opacity="1.000000" stroke="none" 
	d="
M542.700562,217.034485 
	C542.885559,218.206711 542.723206,219.371353 542.298279,220.772903 
	C541.192383,219.606506 538.172241,218.029617 542.700562,217.034485 
z"/>
<path fill="#061421" opacity="1.000000" stroke="none" 
	d="
M475.893433,221.797302 
	C475.671875,221.905792 475.420380,221.709549 475.143799,221.206009 
	C475.367035,221.098358 475.615265,221.296310 475.893433,221.797302 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M123.156067,454.927521 
	C121.021561,447.655548 118.887054,440.383606 116.378372,433.029419 
	C115.690834,431.859039 115.377480,430.770813 115.023628,429.342407 
	C115.170006,427.393341 115.356888,425.784454 115.543762,424.175598 
	C115.923248,424.138336 116.302734,424.101074 116.682220,424.063812 
	C117.638474,425.853912 118.594719,427.644012 119.764297,429.729858 
	C121.542480,434.457947 123.107346,438.890289 124.823563,443.669617 
	C126.201302,447.462677 127.427696,450.908691 128.816284,454.674988 
	C129.545334,458.757355 130.112213,462.519440 130.834167,466.965515 
	C130.976410,468.101135 130.963577,468.552765 130.950729,469.004395 
	C130.007309,468.991150 129.063873,468.977905 127.650902,468.640564 
	C127.134239,467.871094 127.087135,467.425751 127.261871,466.823975 
	C127.071686,466.083252 126.659637,465.498932 126.012207,464.735962 
	C125.544609,464.015106 125.312401,463.472931 125.080185,462.930756 
	C124.721733,461.215363 124.363281,459.500000 124.233871,457.234589 
	C124.027306,456.098877 123.591682,455.513214 123.156067,454.927521 
z"/>
<path fill="#40A3B3" opacity="1.000000" stroke="none" 
	d="
M131.248322,469.221130 
	C130.963577,468.552765 130.976410,468.101135 130.996460,467.310120 
	C134.820908,466.313385 138.625626,465.192871 142.457855,465.089050 
	C152.396637,464.819824 162.361206,464.690948 172.287582,465.132812 
	C176.958130,465.340668 181.564560,466.989227 186.596954,467.996765 
	C185.857101,471.532227 184.720322,475.055908 182.940491,478.639282 
	C169.345535,478.249390 156.393127,477.545074 143.442245,477.572601 
	C140.956085,477.577911 138.476044,480.459229 135.993179,482.013733 
	C135.212265,479.821716 134.427582,477.631012 133.651581,475.437256 
	C132.944794,473.439178 132.247437,471.437775 131.248322,469.221130 
z"/>
<path fill="#64B6D0" opacity="1.000000" stroke="none" 
	d="
M173.960999,433.474792 
	C174.465820,432.460754 174.985901,431.888214 176.053268,431.379730 
	C179.984848,431.629028 183.367188,431.900452 186.753922,431.977386 
	C190.864288,432.070770 195.073746,432.316650 197.472000,427.782379 
	C197.622696,427.497498 199.158783,427.945343 200.076462,428.286682 
	C200.102600,428.521088 200.168732,428.988159 199.874115,429.186493 
	C197.932907,433.426849 196.404434,437.522583 194.600540,441.493134 
	C193.270645,444.420410 191.581100,447.184296 190.051819,450.020966 
	C184.197998,449.853302 178.344193,449.685638 172.238922,449.254456 
	C172.650391,443.966034 173.313324,438.941132 173.960999,433.474792 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M190.040833,450.440491 
	C191.581100,447.184296 193.270645,444.420410 194.600540,441.493134 
	C196.404434,437.522583 197.932907,433.426849 200.044937,429.296143 
	C200.707031,429.406097 200.844406,429.639587 200.905884,430.305481 
	C200.163040,434.105408 199.436768,437.507843 198.451263,440.975891 
	C198.120300,441.690186 198.048553,442.338928 197.976685,443.333984 
	C198.017502,444.139313 198.058441,444.598358 197.853836,445.303467 
	C197.302979,446.681702 196.997665,447.813904 196.692352,448.946075 
	C194.649628,453.160461 192.606903,457.374847 190.282120,461.800537 
	C190.009995,458.294617 190.019928,454.577332 190.040833,450.440491 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M115.543442,435.047485 
	C115.649139,450.708008 115.383415,466.413635 115.134224,482.119537 
	C115.086922,485.100952 115.105118,488.083466 115.114265,491.526489 
	C114.603691,491.723511 114.071953,491.459473 113.268265,491.075745 
	C112.997368,487.599884 112.998428,484.243683 112.979340,480.426086 
	C112.640846,475.271667 112.322517,470.578674 111.996323,465.436096 
	C111.661911,459.628296 111.335365,454.270050 111.006386,448.458923 
	C112.395226,443.701630 113.786491,439.397186 115.543442,435.047485 
z"/>
<path fill="#3099A8" opacity="1.000000" stroke="none" 
	d="
M847.701111,481.203186 
	C861.095154,479.237305 874.200684,478.173004 887.602051,482.014771 
	C884.723816,486.192474 881.093994,490.240723 882.972290,496.617981 
	C868.558350,496.774963 854.101440,496.552429 839.643921,496.370697 
	C839.009949,496.362701 838.371216,496.728119 837.352051,496.956055 
	C837.837585,494.967377 838.390564,492.716919 839.641785,490.965240 
	C842.027649,487.625000 844.811768,484.569244 847.701111,481.203186 
z"/>
<path fill="#3099A8" opacity="1.000000" stroke="none" 
	d="
M883.048401,497.310303 
	C883.652649,497.434937 884.296326,497.884949 884.995239,498.652344 
	C884.396240,498.525055 883.742065,498.080414 883.048401,497.310303 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M832.985901,505.741699 
	C832.875549,505.717773 832.768250,505.456848 832.821228,505.113281 
	C832.981445,505.030670 832.989014,505.504700 832.985901,505.741699 
z"/>
<path fill="#0C1A28" opacity="1.000000" stroke="none" 
	d="
M846.802551,474.804474 
	C846.399536,474.836395 845.996460,474.868286 845.118652,474.615173 
	C842.426208,474.165894 840.208496,474.001587 837.543091,473.873413 
	C834.397400,473.850830 831.699524,473.792145 828.727783,473.503662 
	C826.663818,472.186218 824.873596,471.098541 823.031128,469.697815 
	C822.889404,469.192993 822.800049,469.001221 822.877808,468.503906 
	C823.131287,467.535767 823.217651,466.873138 823.437378,466.105743 
	C823.570801,466.000977 823.661560,465.674072 823.997925,465.723511 
	C825.642700,464.579254 826.951172,463.385559 828.612427,462.124542 
	C829.205322,461.254974 829.445435,460.452698 829.793701,459.391479 
	C830.025879,458.784332 830.149841,458.436127 830.603394,458.173340 
	C832.569092,457.096680 834.205261,455.934540 836.193970,454.869934 
	C838.072083,453.666504 839.597595,452.365540 841.506714,451.015991 
	C842.382446,450.810364 842.874573,450.653351 843.392944,450.536987 
	C843.419312,450.577667 843.515869,450.569000 843.623413,450.965271 
	C843.594727,452.738586 843.458618,454.115662 843.272949,455.993317 
	C845.888306,455.993317 848.019653,455.846802 850.122925,456.026978 
	C853.457764,456.312653 856.773865,456.816864 860.066528,457.565247 
	C858.018250,460.266846 856.001343,462.631775 853.641602,465.043823 
	C852.919067,465.832031 852.539429,466.573151 852.005005,467.607178 
	C850.167664,470.201599 848.485107,472.503052 846.802551,474.804474 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M853.984497,464.996704 
	C856.001343,462.631775 858.018250,460.266846 860.378540,457.440887 
	C861.899536,455.357483 863.077087,453.735046 864.580383,452.045349 
	C866.995728,449.393646 869.085388,446.809265 871.453491,444.068787 
	C872.899231,442.855896 874.066589,441.799103 875.620178,440.805237 
	C876.666504,441.241150 877.326660,441.614136 877.986755,441.987091 
	C877.309204,443.749084 876.631592,445.511108 875.930969,447.640625 
	C869.754333,452.120819 865.735046,458.053162 861.994812,464.624817 
	C859.094971,467.752197 856.283081,470.561432 853.377991,473.270752 
	C852.273743,474.300629 850.955200,475.100800 849.365967,476.020691 
	C848.398071,475.804077 847.798523,475.572479 847.000793,475.072662 
	C848.485107,472.503052 850.167664,470.201599 852.301086,467.491699 
	C853.162842,466.387756 853.573669,465.692230 853.984497,464.996704 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M836.939941,485.757660 
	C834.259460,485.519470 831.877808,484.895172 829.479858,484.824585 
	C825.298096,484.701538 821.106201,484.919586 816.486938,484.993835 
	C816.024780,484.240021 815.994385,483.487061 816.276367,482.121552 
	C816.646240,480.562042 816.703796,479.615082 816.779541,478.387817 
	C816.867188,477.739563 816.936584,477.371643 817.399292,477.004578 
	C821.854614,476.677216 825.916687,476.348969 830.204651,476.294800 
	C833.618713,476.766663 836.806946,476.964417 840.120117,477.403839 
	C840.800354,478.091125 841.355591,478.536804 841.910889,478.982483 
	C841.784241,479.566162 841.657593,480.149841 840.933167,480.893188 
	C839.889526,481.697266 839.443665,482.341705 838.997864,482.986145 
	C838.409058,483.864380 837.820251,484.742615 836.939941,485.757660 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M842.192322,478.829285 
	C841.355591,478.536804 840.800354,478.091125 840.103394,477.061188 
	C839.304688,475.597046 838.647766,474.717163 837.990784,473.837311 
	C840.208496,474.001587 842.426208,474.165894 844.816895,474.648621 
	C844.151123,476.203400 843.312439,477.439728 842.192322,478.829285 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M839.302063,482.933105 
	C839.443665,482.341705 839.889526,481.697266 840.658447,481.023682 
	C840.523132,481.623047 840.064697,482.251587 839.302063,482.933105 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M833.285889,490.944580 
	C833.395386,490.316620 833.842346,489.653229 834.606812,488.917053 
	C834.490662,489.532562 834.056946,490.220856 833.285889,490.944580 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M783.607117,474.132874 
	C783.759583,477.464966 783.911987,480.797119 784.041992,485.029602 
	C783.998718,486.922333 783.837036,487.935730 783.981506,488.903442 
	C784.592834,492.999847 783.017151,494.403015 778.813599,494.195557 
	C771.549255,493.837128 764.256470,493.973541 756.977051,494.044983 
	C754.424744,494.070007 752.729370,493.557159 753.396240,490.348846 
	C759.429260,483.740448 765.152954,477.328125 771.249573,470.987671 
	C775.617371,472.084015 779.612244,473.108429 783.607117,474.132874 
z"/>
<path fill="#3F7798" opacity="1.000000" stroke="none" 
	d="
M753.086914,490.544952 
	C752.729370,493.557159 754.424744,494.070007 756.977051,494.044983 
	C764.256470,493.973541 771.549255,493.837128 778.813599,494.195557 
	C783.017151,494.403015 784.592834,492.999847 783.981506,488.903442 
	C783.837036,487.935730 783.998718,486.922333 784.105103,485.485168 
	C785.565125,485.048920 786.939575,485.057465 788.632690,485.040771 
	C788.635986,488.401550 788.320679,491.787506 787.991699,495.589203 
	C784.353455,498.734222 780.263062,499.275208 775.823120,499.052979 
	C770.530396,498.788116 765.187134,499.318604 759.917847,498.879456 
	C756.458313,498.591125 753.091003,497.196381 749.349854,496.150574 
	C749.257507,493.294128 748.133179,489.962341 752.625854,489.374359 
	C753.057312,490.013580 753.072083,490.279266 753.086914,490.544952 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M783.713135,473.845276 
	C779.612244,473.108429 775.617371,472.084015 771.384399,470.702972 
	C771.454590,469.581451 771.762939,468.816559 772.071289,468.051666 
	C772.390869,467.940033 772.645264,467.743896 773.205872,467.109558 
	C773.831177,466.222015 774.085144,465.688202 774.339111,465.154419 
	C774.992188,464.800018 775.645203,464.445648 776.818726,464.130249 
	C778.240845,464.760559 779.142517,465.351959 780.334656,466.089783 
	C781.747192,466.192169 782.869263,466.148132 784.450439,466.040894 
	C789.156067,466.099548 793.402527,466.221405 797.755066,466.649506 
	C798.545349,466.840790 799.229614,466.725861 800.331055,466.680725 
	C802.147278,466.903717 803.546387,467.056915 805.012268,467.551331 
	C806.965454,469.176178 808.851807,470.459778 810.738159,471.743378 
	C802.414734,471.771545 794.091370,471.799713 785.108154,471.960205 
	C784.277039,472.380188 784.105713,472.667908 783.934326,472.955597 
	C783.895935,473.156311 783.857605,473.356995 783.713135,473.845276 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M811.132690,471.832275 
	C808.851807,470.459778 806.965454,469.176178 805.391357,467.389221 
	C806.801697,466.891174 807.899719,466.896515 809.226440,467.199646 
	C813.873657,467.934814 818.292114,468.372101 822.710632,468.809418 
	C822.800049,469.001221 822.889404,469.192993 822.699158,469.805847 
	C820.615051,472.485870 818.810547,474.744812 817.006042,477.003723 
	C816.936584,477.371643 816.867188,477.739563 816.568787,478.437561 
	C816.339844,478.767639 815.904968,478.750458 815.904968,478.750458 
	C815.295776,475.929138 815.888367,472.337433 811.132690,471.832275 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M815.901733,479.158752 
	C815.904968,478.750458 816.339844,478.767639 816.550537,478.717896 
	C816.703796,479.615082 816.646240,480.562042 816.322998,481.746979 
	C816.004395,481.179016 815.951477,480.373047 815.901733,479.158752 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M784.170471,472.857117 
	C784.105713,472.667908 784.277039,472.380188 784.688660,471.999268 
	C784.754761,472.190216 784.580688,472.474426 784.170471,472.857117 
z"/>
<path fill="#64B6D0" opacity="1.000000" stroke="none" 
	d="
M576.021973,449.020752 
	C578.004883,442.614960 579.987854,436.209167 581.990356,429.419922 
	C589.013245,434.460205 596.131897,434.481445 603.654907,429.456909 
	C601.677612,436.212006 599.342468,442.610168 596.661682,449.149963 
	C589.551331,449.201324 582.786682,449.111053 576.021973,449.020752 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M621.830688,444.689941 
	C622.797791,448.181580 623.609863,451.340027 624.699707,454.757812 
	C624.318237,457.741577 623.658936,460.465942 622.990356,463.575439 
	C615.971069,464.302185 608.961121,464.643829 601.426270,464.636444 
	C597.983398,461.878906 596.128479,459.026978 597.384277,454.959351 
	C599.105164,452.922943 600.478821,450.949585 601.887939,449.361572 
	C601.575500,451.506561 601.227722,453.266235 600.625488,455.250366 
	C600.229553,456.591919 600.087952,457.709015 600.008423,459.244141 
	C606.915771,460.191040 613.761169,460.719940 622.219055,461.373413 
	C619.493591,452.923828 617.247803,445.961182 614.962646,438.637115 
	C614.321533,436.506226 613.719666,434.736694 613.089111,432.576843 
	C612.508606,430.435944 611.956726,428.685333 611.577271,426.934784 
	C611.749695,426.934814 612.087280,427.004730 612.087280,427.004730 
	C612.930664,426.672058 613.773987,426.339355 614.664917,425.987915 
	C614.985413,426.745056 615.353821,427.615448 615.843994,428.771973 
	C617.869080,434.157684 619.772339,439.257202 621.830688,444.689941 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M601.852478,448.976196 
	C600.478821,450.949585 599.105164,452.922943 597.370605,454.528809 
	C597.008911,452.443665 597.008118,450.725983 597.007324,449.008331 
	C599.342468,442.610168 601.677612,436.212006 604.018188,429.427490 
	C605.412903,428.017120 606.802185,426.993103 608.669922,426.229492 
	C609.206177,426.671265 609.264038,426.852600 609.171997,427.350647 
	C608.925476,428.588959 608.828796,429.510529 608.483582,430.624786 
	C608.126770,431.212372 608.018433,431.607300 607.716553,432.305847 
	C607.011841,434.736420 606.500549,436.863403 605.711914,439.167480 
	C604.883667,440.554230 604.332764,441.763916 603.515137,443.016663 
	C603.135254,443.696045 603.022217,444.332306 602.902466,445.315674 
	C602.548035,446.767273 602.200256,447.871735 601.852478,448.976196 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M609.321960,427.033936 
	C609.264038,426.852600 609.206177,426.671265 609.032959,426.228485 
	C609.331787,425.165253 609.745850,424.363403 610.483093,422.935974 
	C611.063110,424.522430 611.428650,425.522156 611.940674,426.763306 
	C612.087280,427.004730 611.749695,426.934814 611.320435,426.968842 
	C610.368103,427.013245 609.845032,427.023590 609.321960,427.033936 
z"/>
<path fill="#64B6D0" opacity="1.000000" stroke="none" 
	d="
M615.599854,423.276855 
	C615.443542,423.151794 615.421387,422.934387 615.399292,422.717010 
	C615.510864,422.872864 615.622498,423.028687 615.599854,423.276855 
z"/>
<path fill="#50ABC1" opacity="1.000000" stroke="none" 
	d="
M596.661682,449.149963 
	C597.008118,450.725983 597.008911,452.443665 597.023376,454.591858 
	C596.128479,459.026978 597.983398,461.878906 600.958862,464.635925 
	C599.971191,465.256683 598.820923,465.959442 597.897339,465.733948 
	C589.566467,463.699524 581.134399,465.467194 572.387512,464.948608 
	C572.841980,462.127075 573.736694,459.279907 574.508667,456.399841 
	C575.088379,454.236969 575.510498,452.031891 576.012329,449.433197 
	C582.786682,449.111053 589.551331,449.201324 596.661682,449.149963 
z"/>
<path fill="#456D8D" opacity="1.000000" stroke="none" 
	d="
M437.063568,450.130981 
	C437.843750,450.348419 438.623901,450.565826 439.687683,450.910980 
	C439.984955,451.278687 439.998688,451.518616 439.996399,452.120544 
	C439.510223,452.661133 439.040039,452.839691 438.053436,453.023804 
	C437.366364,453.017303 437.195709,453.005310 437.034149,452.634583 
	C437.050018,451.560852 437.056793,450.845947 437.063568,450.130981 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M444.988525,459.650177 
	C444.304779,459.243286 443.638611,458.474030 442.926758,457.389435 
	C443.589447,457.812012 444.297760,458.549927 444.988525,459.650177 
z"/>
<path fill="#3099A8" opacity="1.000000" stroke="none" 
	d="
M411.309692,490.276367 
	C411.351624,487.610382 411.661987,485.197571 412.372742,482.382019 
	C415.795715,480.987183 418.756653,479.544586 421.856995,479.120087 
	C425.507843,478.620239 429.279510,479.002594 432.998779,479.002441 
	C433.330444,482.722198 433.662109,486.441925 433.993835,490.593475 
	C434.303253,492.444214 434.612640,493.863159 434.959229,495.647766 
	C431.308167,496.062012 427.613190,496.269897 423.933502,496.114838 
	C420.189758,495.957031 416.461090,495.441742 412.355591,495.043884 
	C411.849579,493.512970 411.713837,492.021240 411.309692,490.276367 
z"/>
<path fill="#40A3B3" opacity="1.000000" stroke="none" 
	d="
M433.001862,478.575928 
	C429.279510,479.002594 425.507843,478.620239 421.856995,479.120087 
	C418.756653,479.544586 415.795715,480.987183 412.388000,481.977783 
	C411.805695,476.513885 411.608521,471.051483 412.058350,465.448730 
	C419.479828,465.198456 426.254272,465.088562 433.273712,464.985779 
	C433.518707,464.992920 434.008301,465.018433 434.008301,465.018433 
	C433.673828,469.395447 433.339386,473.772430 433.001862,478.575928 
z"/>
<path fill="#6B8EBA" opacity="1.000000" stroke="none" 
	d="
M434.367035,465.010803 
	C434.008301,465.018433 433.518707,464.992920 433.271667,464.543304 
	C433.224609,461.645599 433.065430,459.089935 433.754700,456.788361 
	C434.192047,455.327881 435.891571,454.245361 437.025085,452.993347 
	C437.195709,453.005310 437.366364,453.017303 437.793701,453.030457 
	C438.075562,453.464935 438.100708,453.898254 437.823792,454.878418 
	C437.711884,456.949524 437.902069,458.473785 438.092224,459.998016 
	C437.993439,461.392853 437.894653,462.787720 437.688599,464.590393 
	C436.629486,464.999847 435.677643,465.001495 434.367035,465.010803 
z"/>
<path fill="#456D8D" opacity="1.000000" stroke="none" 
	d="
M438.339050,459.760681 
	C437.902069,458.473785 437.711884,456.949524 437.807526,455.204010 
	C438.257538,456.496246 438.421722,458.009796 438.339050,459.760681 
z"/>
<path fill="#50ABC1" opacity="1.000000" stroke="none" 
	d="
M437.034119,452.634552 
	C435.891571,454.245361 434.192047,455.327881 433.754700,456.788361 
	C433.065430,459.089935 433.224609,461.645599 433.026672,464.536194 
	C426.254272,465.088562 419.479828,465.198456 412.354309,465.155975 
	C411.665039,460.635010 411.326843,456.266418 410.978333,451.445526 
	C411.291107,450.519775 411.614227,450.046326 411.964783,449.271790 
	C420.159302,449.141357 428.326355,449.311981 436.778503,449.806824 
	C437.056793,450.845947 437.050018,451.560852 437.034119,452.634552 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M385.514282,489.025146 
	C385.522736,487.135254 385.892670,485.134644 386.651093,483.083679 
	C386.651611,484.993683 386.263672,486.954071 385.514282,489.025146 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M387.691711,481.151978 
	C387.441132,480.007019 387.475983,478.717651 387.841431,477.213440 
	C388.107056,478.334869 388.042084,479.671234 387.691711,481.151978 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M383.344421,493.794434 
	C383.063660,493.319641 383.027283,492.638947 383.235352,491.859741 
	C383.516174,492.370331 383.552490,492.979431 383.344421,493.794434 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M878.353027,441.985046 
	C877.326660,441.614136 876.666504,441.241150 875.769897,440.490234 
	C875.979004,439.317047 876.424561,438.521759 877.069092,437.763977 
	C877.268005,437.801514 877.667358,437.734985 878.005737,437.819702 
	C882.860535,437.478638 883.402283,434.143921 883.778992,430.364136 
	C884.250366,429.733063 884.621460,429.352905 885.344238,428.948486 
	C886.467285,427.638519 887.238647,426.352844 888.111084,424.959656 
	C888.212219,424.852112 888.487366,424.744995 888.876587,424.783386 
	C889.829041,423.860413 890.392273,422.899078 891.016479,421.702362 
	C891.077454,421.466980 891.338684,421.056824 891.682861,420.987183 
	C892.703613,419.264801 893.380188,417.612061 894.056763,415.959320 
	C894.895935,416.132996 895.735168,416.306671 896.767639,416.741119 
	C895.321960,421.402679 893.683044,425.803436 891.996704,430.561005 
	C890.251648,432.353271 888.561157,433.797150 886.854858,435.222198 
	C884.148499,437.482361 881.431702,439.730042 878.353027,441.985046 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M894.009949,415.745422 
	C893.380188,417.612061 892.703613,419.264801 891.570312,420.589783 
	C890.134949,416.477173 887.203735,418.294830 884.575684,417.967651 
	C877.124756,417.573669 870.065063,417.331848 862.678589,417.049469 
	C862.132385,416.997375 861.913147,416.985840 861.399658,417.002808 
	C860.910767,417.000977 860.716064,416.970642 860.521362,416.940338 
	C860.590698,416.766235 860.660034,416.592102 861.257019,416.141724 
	C863.567444,415.597809 865.348633,415.112946 867.133423,415.099213 
	C876.058899,415.030518 884.985352,415.085327 893.911438,415.096741 
	C893.911438,415.096741 893.963135,415.531555 894.009949,415.745422 
z"/>
<path fill="#89A1D8" opacity="1.000000" stroke="none" 
	d="
M893.870605,414.700043 
	C884.985352,415.085327 876.058899,415.030518 867.133423,415.099213 
	C865.348633,415.112946 863.567444,415.597809 861.362305,415.881042 
	C861.773865,414.936768 862.593506,413.157166 863.443726,413.142395 
	C873.098328,412.974915 882.757935,413.060394 892.415344,413.158051 
	C892.890625,413.162872 893.358398,413.904724 893.870605,414.700043 
z"/>
<path fill="#89A1D8" opacity="1.000000" stroke="none" 
	d="
M860.209534,416.957489 
	C860.716064,416.970642 860.910767,417.000977 861.391663,417.272400 
	C861.761841,417.672394 861.845886,417.831329 861.839722,418.402771 
	C861.848328,420.208588 861.947083,421.601959 861.907349,423.377319 
	C861.671082,424.834595 861.573364,425.909821 861.475708,426.985046 
	C860.253662,426.993439 859.031616,427.001862 857.446289,427.014282 
	C857.060913,424.969330 857.038818,422.920319 857.017456,420.432251 
	C857.978027,418.987000 858.937866,417.980804 860.209534,416.957489 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M861.569214,427.405945 
	C861.573364,425.909821 861.671082,424.834595 862.205383,423.347931 
	C864.097351,423.627899 865.552795,424.319305 867.013306,425.385132 
	C866.671631,428.171143 866.324829,430.582703 865.741455,432.994934 
	C865.504883,432.995544 865.031677,432.996338 864.653442,432.993683 
	C863.519043,432.993378 862.762878,432.995758 862.006653,432.998108 
	C861.892029,431.274353 861.777344,429.550568 861.569214,427.405945 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M861.845947,433.227264 
	C862.762878,432.995758 863.519043,432.993378 864.640259,433.358948 
	C864.054443,434.494049 863.103760,435.261230 862.153076,436.028412 
	C862.022522,435.564606 861.891968,435.100830 861.617065,434.305634 
	C861.543457,433.801605 861.614380,433.628998 861.845947,433.227264 
z"/>
<path fill="#0C1A28" opacity="1.000000" stroke="none" 
	d="
M861.990723,436.378296 
	C863.103760,435.261230 864.054443,434.494049 865.018433,433.361603 
	C865.031677,432.996338 865.504883,432.995544 865.784607,433.351379 
	C867.145996,436.395325 868.838013,437.873047 871.708069,436.104095 
	C874.180298,434.580322 875.646973,435.607849 876.870117,437.726440 
	C876.424561,438.521759 875.979004,439.317047 875.383667,440.427307 
	C874.066589,441.799103 872.899231,442.855896 870.971924,444.008423 
	C864.431580,444.094116 858.644836,443.936310 852.875977,444.188141 
	C851.587463,444.244415 850.361694,445.738678 848.941284,446.903687 
	C847.184692,447.813568 845.593811,448.390625 843.977966,448.649872 
	C844.030518,447.914948 844.108154,447.497833 844.551270,446.973602 
	C845.235352,444.817871 845.553955,442.769226 845.872559,440.720581 
	C849.639954,440.485352 853.461914,440.550415 857.150452,439.880707 
	C858.844727,439.573090 860.277222,437.823761 861.990723,436.378296 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M844.185791,447.080688 
	C844.108154,447.497833 844.030518,447.914948 843.753052,448.679657 
	C843.553101,449.027222 843.113953,449.140564 842.776733,449.115234 
	C842.000671,449.748108 841.561890,450.406342 841.123047,451.064575 
	C839.597595,452.365540 838.072083,453.666504 836.185669,454.515869 
	C837.175110,451.555969 838.525513,449.047699 840.430054,445.510132 
	C836.262207,445.510132 832.979919,445.510132 829.411682,445.447784 
	C828.737854,445.343842 828.350098,445.302277 827.975525,444.950623 
	C827.897522,444.239594 827.806274,443.838654 828.163208,443.308411 
	C830.439941,443.119080 832.378174,443.407776 834.059143,442.878693 
	C835.214111,442.515137 836.011780,441.016113 836.969116,440.024597 
	C839.089172,440.052246 841.209167,440.079895 843.583496,440.664795 
	C843.953735,443.174927 844.069763,445.127808 844.185791,447.080688 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M836.642944,440.020721 
	C836.011780,441.016113 835.214111,442.515137 834.059143,442.878693 
	C832.378174,443.407776 830.439941,443.119080 827.968872,443.211029 
	C827.326416,443.242950 826.960083,443.009705 826.879272,442.746826 
	C826.463989,442.442566 826.129517,442.401184 825.358337,442.361572 
	C822.279053,442.332367 819.636536,442.301331 816.904541,441.957214 
	C816.212708,441.855011 815.610291,442.065857 814.626465,442.295258 
	C813.480164,442.302124 812.715332,442.290436 811.891602,441.974884 
	C811.217651,441.154572 810.602661,440.638153 809.987610,440.121704 
	C809.999817,439.921387 810.012024,439.721069 810.060242,439.220947 
	C816.112000,439.545319 822.124512,440.208496 828.149292,440.728851 
	C828.707764,440.777100 829.344543,439.917725 830.054932,439.110138 
	C830.164795,438.740509 830.635376,438.792633 830.960938,439.175232 
	C832.528809,439.684448 833.771057,439.811005 835.013306,439.937592 
	C835.447754,439.963989 835.882202,439.990417 836.642944,440.020721 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M844.551270,446.973602 
	C844.069763,445.127808 843.953735,443.174927 843.928345,440.843719 
	C844.431152,440.525238 844.843384,440.585083 845.564087,440.682739 
	C845.553955,442.769226 845.235352,444.817871 844.551270,446.973602 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M834.779175,439.678223 
	C833.771057,439.811005 832.528809,439.684448 831.188721,439.239929 
	C832.242249,439.087616 833.393677,439.253265 834.779175,439.678223 
z"/>
<path fill="#87CEF0" opacity="1.000000" stroke="none" 
	d="
M703.931396,411.446228 
	C704.209351,397.274384 702.135986,397.958038 717.246277,397.983978 
	C745.215759,398.032013 773.185364,397.994476 801.154907,398.001678 
	C810.995728,398.004211 812.365967,399.786499 810.276123,409.650238 
	C810.037598,410.775879 810.088928,411.962891 810.005371,413.551575 
	C798.689026,417.890594 786.945801,416.842743 775.300842,417.030121 
	C772.251221,417.079193 769.816650,416.591675 767.262878,414.403290 
	C765.067200,412.521729 761.622131,412.098083 758.322998,411.062347 
	C755.927368,411.091095 753.938232,411.087280 751.088135,411.054932 
	C748.787170,411.047363 747.347107,411.068329 745.907043,411.089294 
	C741.607666,411.086945 737.308289,411.084564 732.164673,411.061401 
	C729.038635,411.954437 726.274902,412.340118 724.574585,413.891388 
	C720.965454,417.184265 716.806396,417.475739 712.558472,416.884705 
	C709.616455,416.475403 706.819458,415.023560 703.957642,414.037506 
	C703.951111,413.296814 703.944580,412.556122 703.931396,411.446228 
z"/>
<path fill="#64B6D0" opacity="1.000000" stroke="none" 
	d="
M744.894897,444.328461 
	C747.673401,440.909271 750.315979,437.679810 753.270996,434.767303 
	C755.036987,433.026733 757.292480,431.782867 759.634888,430.147308 
	C762.510376,433.390472 765.453552,434.046692 769.313416,431.849915 
	C770.695557,431.063324 773.011597,430.850555 774.464294,431.444702 
	C780.002441,433.709778 785.287964,433.643494 790.782898,431.332794 
	C792.351501,430.673157 794.374329,431.093781 796.632263,431.042847 
	C793.259827,435.953125 789.444092,440.839600 784.955200,445.873108 
	C783.126587,446.572052 781.896484,447.008575 780.829773,447.697876 
	C777.804749,449.652588 775.052307,452.356049 771.071777,449.324799 
	C770.197632,448.659149 768.493591,449.106354 767.175415,448.996796 
	C759.430359,448.353180 751.686401,447.696533 743.942078,447.044250 
	C744.258179,446.246704 744.574280,445.449158 744.894897,444.328461 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M761.827820,415.911560 
	C761.674866,416.947083 761.279968,417.941193 760.515076,418.567596 
	C760.102173,417.619873 760.059265,417.039856 760.016296,416.459839 
	C760.197327,416.216949 760.430420,416.044189 760.913940,415.841034 
	C761.112366,415.740479 761.585876,415.870148 761.827820,415.911560 
z"/>
<path fill="#76C0DF" opacity="1.000000" stroke="none" 
	d="
M703.948608,414.421204 
	C706.819458,415.023560 709.616455,416.475403 712.558472,416.884705 
	C716.806396,417.475739 720.965454,417.184265 724.574585,413.891388 
	C726.274902,412.340118 729.038635,411.954437 731.706787,411.068726 
	C724.688171,415.395905 724.469055,422.681305 723.806824,430.007416 
	C723.681396,431.395020 720.971130,433.354645 719.231689,433.625549 
	C715.427979,434.217987 711.476196,434.020111 707.597961,433.849701 
	C706.742432,433.812134 705.297302,432.613800 705.217346,431.837372 
	C704.634521,426.176025 704.331116,420.485931 703.948608,414.421204 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M759.552368,416.532898 
	C760.059265,417.039856 760.102173,417.619873 760.178101,418.635010 
	C757.091614,422.674530 753.972046,426.278900 750.568237,430.048218 
	C746.114868,430.158722 741.945740,430.104309 737.412476,429.698486 
	C738.092834,427.279175 739.137268,425.211212 740.844177,421.831726 
	C738.357239,422.422485 737.182190,422.701630 735.586182,422.987244 
	C733.762939,423.011780 732.360474,423.029816 730.965454,422.612061 
	C730.984131,420.438538 730.995422,418.700745 731.006714,416.962952 
	C740.367310,416.843964 749.727905,416.724976 759.552368,416.532898 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M730.958069,423.047821 
	C732.360474,423.029816 733.762939,423.011780 735.714722,423.294952 
	C735.794434,424.904114 735.401855,426.246704 734.833069,427.509979 
	C734.292664,428.710175 733.568115,429.827484 732.924561,430.981262 
	C731.893738,431.662201 730.862854,432.343140 729.508972,433.045776 
	C729.118530,430.327881 729.051147,427.588318 728.844604,424.411194 
	C728.762024,423.803436 728.818542,423.633270 729.289551,423.233643 
	C730.122070,423.018707 730.540100,423.033264 730.958069,423.047821 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M730.965454,422.612061 
	C730.540100,423.033264 730.122070,423.018707 729.390747,422.984467 
	C729.539368,420.865295 730.001282,418.765808 730.734985,416.814636 
	C730.995422,418.700745 730.984131,420.438538 730.965454,422.612061 
z"/>
<path fill="#76C0DF" opacity="1.000000" stroke="none" 
	d="
M745.997437,411.442627 
	C747.347107,411.068329 748.787170,411.047363 750.659912,411.059265 
	C749.803345,414.022430 748.029236,413.504028 745.997437,411.442627 
z"/>
<path fill="#50ABC1" opacity="1.000000" stroke="none" 
	d="
M743.792725,447.335510 
	C751.686401,447.696533 759.430359,448.353180 767.175415,448.996796 
	C768.493591,449.106354 770.197632,448.659149 771.071777,449.324799 
	C775.052307,452.356049 777.804749,449.652588 780.829773,447.697876 
	C781.896484,447.008575 783.126587,446.572052 784.655273,446.027954 
	C781.427551,450.183411 777.672974,454.210114 774.280762,458.521973 
	C772.077332,461.322815 770.402283,464.539337 768.578308,468.027771 
	C768.108948,468.662628 767.551880,468.839355 766.785889,468.726196 
	C764.848999,467.294922 763.202087,465.329010 761.379517,465.149811 
	C751.592957,464.187653 741.762756,463.670135 731.947571,462.999084 
	C735.846130,457.874969 739.744690,452.750885 743.792725,447.335510 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M809.777710,440.288696 
	C810.602661,440.638153 811.217651,441.154572 811.653076,442.220673 
	C809.981567,442.340393 808.150696,442.313049 807.066040,441.399628 
	C804.533142,439.266693 802.969238,439.748413 801.381348,442.693359 
	C795.709229,449.213745 790.283325,455.536163 785.049683,462.013916 
	C784.243896,463.011292 784.322021,464.722870 783.991333,466.104126 
	C782.869263,466.148132 781.747192,466.192169 780.328674,465.714874 
	C780.475098,464.029938 781.197998,462.882751 781.267517,461.697235 
	C781.318298,460.831848 780.508545,459.916046 780.082397,459.022705 
	C780.868652,457.788635 781.654846,456.554565 782.896851,455.493164 
	C786.816345,456.814728 791.569092,451.347351 790.949219,446.926880 
	C791.520569,446.113129 792.091919,445.299408 793.231750,444.243896 
	C798.212402,443.596710 800.683594,441.275330 800.940918,436.768555 
	C801.671875,435.483307 802.402771,434.198029 803.484131,432.851135 
	C805.745605,435.344849 807.656738,437.900269 809.777710,440.288696 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M766.994751,469.016113 
	C767.551880,468.839355 768.108948,468.662628 768.839111,468.242340 
	C769.776733,468.002136 770.541260,468.005493 771.688599,468.030243 
	C771.762939,468.816559 771.454590,469.581451 771.011475,470.631104 
	C765.152954,477.328125 759.429260,483.740448 753.396240,490.348846 
	C753.072083,490.279266 753.057312,490.013580 753.006592,489.349854 
	C754.293091,486.205933 755.615479,483.460083 757.244873,480.169464 
	C760.699463,476.088501 763.847107,472.552307 766.994751,469.016113 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M790.672729,447.111908 
	C791.569092,451.347351 786.816345,456.814728 783.206299,455.380859 
	C785.505310,452.496216 787.950684,449.896576 790.672729,447.111908 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M800.581909,436.816772 
	C800.683594,441.275330 798.212402,443.596710 793.419495,443.994019 
	C795.433472,441.612274 797.828186,439.238617 800.581909,436.816772 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M779.890259,459.290070 
	C780.508545,459.916046 781.318298,460.831848 781.267517,461.697235 
	C781.197998,462.882751 780.475098,464.029938 780.038208,465.568481 
	C779.142517,465.351959 778.240845,464.760559 777.049988,463.968872 
	C777.739929,462.364868 778.719055,460.961151 779.890259,459.290070 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M774.026123,465.233887 
	C774.085144,465.688202 773.831177,466.222015 773.307373,466.887390 
	C773.262817,466.450439 773.487976,465.881927 774.026123,465.233887 
z"/>
<path fill="#87CEF0" opacity="1.000000" stroke="none" 
	d="
M343.428223,412.977661 
	C342.542816,412.659760 342.073486,412.347382 341.309875,412.009460 
	C340.536407,410.947296 340.032440,409.921234 339.581879,408.872223 
	C336.901001,402.630676 339.853027,398.003662 346.526184,397.997650 
	C356.013763,397.989105 365.509796,397.782043 374.982727,398.155090 
	C377.229523,398.243591 380.912354,399.970367 381.252106,401.528503 
	C381.772980,403.917145 380.211639,406.924072 379.060638,409.453156 
	C378.339722,411.037292 376.750885,412.226379 375.277374,413.787201 
	C372.943115,414.668854 370.928436,415.655334 368.812683,415.976959 
	C365.146942,416.534119 361.423187,416.757477 357.715179,416.973419 
	C354.618561,417.153748 351.656036,417.374542 349.338440,414.207184 
	C348.505341,413.068726 345.732086,413.349915 343.428223,412.977661 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M378.080872,461.955627 
	C380.207184,461.955627 382.333435,461.955627 384.963135,461.955627 
	C384.963135,455.826630 384.963135,449.465881 384.971313,442.657043 
	C385.672424,442.837250 386.931946,443.432678 386.970581,444.098907 
	C387.353363,450.700623 387.534088,457.314056 387.362610,464.409851 
	C384.956787,464.590515 382.960297,464.285461 380.607666,463.978149 
	C379.540558,463.972595 378.829651,463.969269 378.080139,463.631775 
	C378.054626,462.850250 378.067749,462.402954 378.080872,461.955627 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M380.963837,463.980438 
	C382.960297,464.285461 384.956787,464.590515 387.313477,464.863525 
	C387.773438,467.261230 387.873169,469.690948 387.938354,472.571228 
	C385.920044,472.308502 382.727020,474.705841 381.975677,470.327209 
	C381.937897,469.514832 381.935669,469.106171 381.927551,468.033325 
	C381.602386,466.239532 381.283112,465.109985 380.963837,463.980438 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M377.845367,461.929321 
	C378.067749,462.402954 378.054626,462.850250 378.033081,464.012360 
	C378.018677,465.240417 378.012665,465.753693 378.006653,466.266968 
	C378.006653,466.266968 377.531494,466.179443 377.302856,466.102661 
	C376.902069,464.798798 376.729950,463.571777 376.848206,462.148926 
	C377.138580,461.953156 377.609863,461.902985 377.845367,461.929321 
z"/>
<path fill="#50ABC1" opacity="1.000000" stroke="none" 
	d="
M372.963562,448.020752 
	C372.977722,453.037628 372.991882,458.054535 372.755463,463.542358 
	C372.335785,464.016205 372.166718,464.019073 371.779205,463.760010 
	C367.611725,463.998688 363.670563,464.576843 359.711487,464.978821 
	C355.492920,465.407166 351.257416,465.668823 347.020691,465.535461 
	C347.333649,459.065399 347.655243,453.063080 348.368103,447.050690 
	C356.827423,447.367310 364.895477,447.694031 372.963562,448.020752 
z"/>
<path fill="#64B6D0" opacity="1.000000" stroke="none" 
	d="
M372.976135,447.581299 
	C364.895477,447.694031 356.827423,447.367310 348.203735,446.660004 
	C347.426086,440.204041 347.204071,434.128632 346.982056,428.053253 
	C353.995117,435.616852 362.802673,432.971619 371.647156,432.153259 
	C372.361267,434.698364 372.687958,437.410950 373.027344,440.562988 
	C373.022919,443.048889 373.005829,445.095367 372.976135,447.581299 
z"/>
<path fill="#498AAB" opacity="1.000000" stroke="none" 
	d="
M371.997650,464.021973 
	C372.166718,464.019073 372.335785,464.016205 372.758636,464.009521 
	C373.510071,465.543427 374.007721,467.081146 374.775330,469.213440 
	C375.375275,470.882141 375.705261,471.956268 376.035278,473.030426 
	C375.985504,473.750397 375.935730,474.470367 375.504761,475.655304 
	C375.068420,477.749207 375.013245,479.378174 374.958069,481.007111 
	C374.608551,482.403076 374.259033,483.799042 373.810059,485.545471 
	C372.898407,487.067230 372.086243,488.238525 371.141754,489.720581 
	C370.829834,490.017303 370.650208,490.003265 370.216003,489.547852 
	C370.300995,486.073578 370.640533,483.040649 370.986267,479.553711 
	C371.327515,474.073822 371.662567,469.047882 371.997650,464.021973 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M376.330811,472.801819 
	C375.705261,471.956268 375.375275,470.882141 375.048035,469.450684 
	C375.437653,469.037109 375.824493,468.980835 376.502441,468.906677 
	C376.737823,470.116974 376.682068,471.345093 376.330811,472.801819 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M346.967377,427.613159 
	C347.204071,434.128632 347.426086,440.204041 347.812469,446.670105 
	C347.655243,453.063080 347.333649,459.065399 347.000732,465.935120 
	C346.010956,471.535309 345.032471,476.268127 343.695129,481.059540 
	C340.621277,481.592407 342.042572,487.160339 337.818787,485.329773 
	C337.626984,483.662262 337.348053,482.334320 337.376953,480.838745 
	C338.464752,478.793457 339.244751,476.915863 340.403229,474.954315 
	C341.173706,474.222076 341.872406,473.591370 341.910767,472.922821 
	C342.330750,465.596283 342.647705,458.263855 342.991241,450.468567 
	C343.017029,442.561798 343.044220,435.119476 343.071381,427.677155 
	C343.087097,424.348572 343.102814,421.019958 343.089478,417.195435 
	C344.021240,417.504425 345.571747,418.156097 345.828461,419.144012 
	C346.503693,421.742279 346.616089,424.486816 346.967377,427.613159 
z"/>
<path fill="#64B6D0" opacity="1.000000" stroke="none" 
	d="
M304.928650,420.027222 
	C305.360046,420.036682 305.791473,420.046143 306.545410,420.066772 
	C306.945343,420.543335 307.022766,421.008728 307.085754,421.777649 
	C307.071350,422.081177 306.788849,421.909271 306.471924,421.979248 
	C306.361572,423.069397 306.568115,424.089539 306.902618,425.455811 
	C307.167572,426.772552 307.304596,427.743195 307.277100,429.159973 
	C307.173798,432.081818 307.235077,434.557495 307.193298,437.384888 
	C307.021667,438.465149 306.953033,439.193756 306.547729,440.131348 
	C305.799683,442.363922 305.281555,444.372833 305.001740,446.414429 
	C304.566254,449.591827 304.292786,452.791382 303.687866,455.733429 
	C302.145874,453.354980 300.796021,449.359283 299.599243,449.404633 
	C293.049774,449.652710 286.090210,446.628448 279.889984,451.285004 
	C279.116455,451.865936 277.311920,451.074158 275.920929,450.513184 
	C275.564667,442.078369 275.272583,434.048859 275.363251,426.019958 
	C278.609802,426.999542 281.433105,428.622742 284.346832,428.805573 
	C288.658936,429.076141 293.035522,428.328278 297.383972,428.005096 
	C298.809357,427.899170 300.232666,427.765594 300.363098,427.754517 
	C302.276855,424.515442 303.602722,422.271332 304.928650,420.027222 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M319.084412,433.910858 
	C320.081299,432.853821 321.515289,431.960632 321.985596,430.706146 
	C323.017609,427.953308 323.561737,425.017609 324.681763,422.629333 
	C325.061371,440.236420 325.064270,457.370728 325.067261,475.037964 
	C324.438873,474.664642 323.167877,473.909607 322.226257,472.957458 
	C324.253265,468.856628 324.135162,465.200562 320.758118,461.712891 
	C317.957489,461.320557 315.479340,461.160278 313.007874,460.531372 
	C312.979126,452.392639 312.943756,444.722473 312.953918,436.719727 
	C315.027802,435.561676 317.056091,434.736267 319.084412,433.910858 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M321.896912,473.154572 
	C323.167877,473.909607 324.438873,474.664642 325.067261,475.037964 
	C325.064270,457.370728 325.061371,440.236420 325.077545,422.652466 
	C325.922241,422.203735 326.747864,422.204742 328.058960,422.641296 
	C328.050171,425.398468 327.555908,427.720032 327.047455,430.507446 
	C327.059784,440.626404 327.086304,450.279480 327.051270,460.367554 
	C327.575073,463.271515 328.160400,465.740479 328.748962,468.507141 
	C328.745697,469.201782 328.739197,469.598694 328.514893,470.243500 
	C328.776337,471.733398 329.255585,472.975372 329.810608,474.559479 
	C332.299805,479.665741 332.299805,479.665741 335.103363,476.630310 
	C336.123352,473.160492 337.066284,470.075073 338.339600,466.978943 
	C338.890167,466.976532 339.110382,466.984863 339.262268,467.326233 
	C339.148804,468.109863 339.103699,468.560547 338.794556,469.217468 
	C338.703308,470.613373 338.876038,471.803040 339.090485,473.285583 
	C339.429688,474.065033 339.727234,474.551636 340.024780,475.038239 
	C339.244751,476.915863 338.464752,478.793457 336.985901,480.823303 
	C331.935059,482.228424 327.583038,483.481323 322.858063,484.528687 
	C321.648804,484.255341 320.812531,484.187531 319.796967,483.816528 
	C318.412415,481.851501 317.124878,480.241638 316.030975,478.509491 
	C315.310089,477.368042 314.867920,476.050537 314.233490,474.457642 
	C314.191315,473.143127 314.216644,472.182831 314.591125,471.183838 
	C316.296631,471.369110 317.652954,471.593079 319.120728,472.151428 
	C320.120392,472.708771 321.008667,472.931671 321.896912,473.154572 
z"/>
<path fill="#DDDFE2" opacity="1.000000" stroke="none" 
	d="
M327.112793,459.932587 
	C327.086304,450.279480 327.059784,440.626404 327.403076,430.425964 
	C329.214447,427.401886 330.656036,424.925110 332.097626,422.448364 
	C333.761108,422.326965 335.424591,422.205566 337.326721,422.609192 
	C337.498962,423.642670 337.432526,424.151123 336.964569,424.761719 
	C332.709076,425.559998 330.926514,427.807495 330.955200,431.779419 
	C331.038055,443.242737 330.671570,454.721191 331.179626,466.162689 
	C331.342468,469.829712 333.682434,473.400116 335.026337,477.014709 
	C332.299805,479.665741 332.299805,479.665741 329.894897,474.164856 
	C329.513153,472.283936 329.122925,471.139771 328.732727,469.995605 
	C328.739197,469.598694 328.745697,469.201782 328.842102,468.060974 
	C328.325592,464.855591 327.719208,462.394073 327.112793,459.932587 
z"/>
<path fill="#939395" opacity="1.000000" stroke="none" 
	d="
M337.366028,424.659607 
	C337.432526,424.151123 337.498962,423.642670 337.716705,422.780090 
	C339.371063,424.058624 340.874115,425.691284 342.724274,427.500549 
	C343.044220,435.119476 343.017029,442.561798 342.649109,450.596558 
	C341.750366,452.704559 341.192413,454.220154 340.379364,455.801208 
	C339.598969,455.970062 339.073669,456.073486 338.358093,455.732361 
	C338.138306,452.478027 338.151855,449.666992 338.070953,446.858673 
	C337.857880,439.458374 337.603790,432.059204 337.366028,424.659607 
z"/>
<path fill="#060B10" opacity="1.000000" stroke="none" 
	d="
M307.441650,428.713867 
	C307.304596,427.743195 307.167572,426.772552 307.038361,425.064911 
	C306.960388,423.521729 306.874603,422.715485 306.788849,421.909271 
	C306.788849,421.909271 307.071350,422.081177 307.235840,422.099640 
	C307.726562,422.090851 308.052368,422.051514 308.379181,422.038055 
	C318.315186,421.627960 319.081177,422.348969 318.686218,432.462952 
	C317.950989,432.834106 317.535553,432.776154 317.024109,432.427795 
	C316.258087,431.781799 315.588165,431.426239 314.559387,431.014496 
	C311.947601,430.210144 309.694611,429.462006 307.441650,428.713867 
z"/>
<path fill="#364E6C" opacity="1.000000" stroke="none" 
	d="
M307.277100,429.159973 
	C309.694611,429.462006 311.947601,430.210144 314.630920,431.333221 
	C315.747589,432.044861 316.433868,432.381531 317.120148,432.718231 
	C317.535553,432.776154 317.950989,432.834106 318.680420,432.905518 
	C318.994476,432.919037 319.029327,433.416504 319.056885,433.663696 
	C317.056091,434.736267 315.027802,435.561676 312.627045,436.667175 
	C310.601837,436.975922 308.949097,437.004578 307.296326,437.033203 
	C307.235077,434.557495 307.173798,432.081818 307.277100,429.159973 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M328.005341,414.007812 
	C328.141785,412.182159 328.278229,410.356506 328.688049,408.263733 
	C331.790009,410.575623 331.786560,412.476990 328.509155,414.770844 
	C328.048218,414.363159 328.026794,414.185486 328.005341,414.007812 
z"/>
<path fill="#BFC0BD" opacity="1.000000" stroke="none" 
	d="
M331.734131,422.308777 
	C330.656036,424.925110 329.214447,427.401886 327.417236,429.960144 
	C327.555908,427.720032 328.050171,425.398468 328.431580,422.825317 
	C329.336029,422.438873 330.353333,422.304047 331.734131,422.308777 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M327.584656,414.003784 
	C328.026794,414.185486 328.048218,414.363159 328.122192,414.804932 
	C326.287842,417.972504 324.542664,418.054321 322.956207,414.425720 
	C324.353180,414.029297 325.758575,414.014526 327.584656,414.003784 
z"/>
<path fill="#258697" opacity="1.000000" stroke="none" 
	d="
M238.014542,495.985352 
	C245.336166,498.962555 247.917847,503.657043 245.783005,511.331757 
	C245.358231,512.858826 242.888779,514.816589 241.311996,514.862732 
	C230.426071,515.181519 219.526886,515.040466 208.632004,515.060791 
	C206.028946,515.065674 203.420639,515.242004 200.824341,515.125549 
	C197.357590,514.970215 195.747498,512.982849 195.992981,509.547516 
	C196.157288,507.248352 196.238464,504.919281 196.689316,502.670227 
	C197.337662,499.436127 198.479248,496.676971 202.739288,496.757996 
	C203.917664,496.780426 205.127319,495.158875 206.930359,494.375702 
	C209.086151,495.306274 210.641357,496.891754 212.181061,496.876831 
	C220.794296,496.793396 229.403778,496.323334 238.014542,495.985352 
z"/>
<path fill="#64B6D0" opacity="1.000000" stroke="none" 
	d="
M206.008072,449.065216 
	C205.231415,445.562469 204.454758,442.059753 203.415527,437.816589 
	C203.150574,434.712097 203.148178,432.348083 203.377106,429.763855 
	C203.738953,428.701447 203.869476,427.859283 204.403381,427.027466 
	C205.902695,428.750946 206.998627,430.464020 208.620239,432.998779 
	C212.588242,432.998779 217.530716,432.624695 222.393280,433.088470 
	C229.287796,433.746033 234.776230,432.914520 235.796585,424.578613 
	C235.862930,424.036652 236.564636,423.572449 236.994888,423.535645 
	C236.668259,432.008270 236.317551,440.017365 235.546036,448.028076 
	C229.224304,448.212555 223.056213,449.526825 217.507874,448.216614 
	C213.149811,447.187439 209.721542,446.762115 206.008072,449.065216 
z"/>
<path fill="#3099A8" opacity="1.000000" stroke="none" 
	d="
M237.971375,495.610168 
	C229.403778,496.323334 220.794296,496.793396 212.181061,496.876831 
	C210.641357,496.891754 209.086151,495.306274 207.242706,494.207703 
	C206.968765,489.282104 206.990204,484.608582 207.446503,479.466492 
	C212.912262,478.717926 217.952286,478.117157 222.971237,478.259399 
	C226.994690,478.373383 230.992584,479.389160 235.001831,480.004761 
	C235.977295,485.081512 236.952744,490.158234 237.971375,495.610168 
z"/>
<path fill="#50ABC1" opacity="1.000000" stroke="none" 
	d="
M205.992828,449.524841 
	C209.721542,446.762115 213.149811,447.187439 217.507874,448.216614 
	C223.056213,449.526825 229.224304,448.212555 235.561615,448.492493 
	C235.983795,454.305847 235.969604,459.656372 235.489532,465.004395 
	C225.687439,465.009399 216.351212,465.016907 207.014969,465.024414 
	C206.669174,460.011108 206.323380,454.997803 205.992828,449.524841 
z"/>
<path fill="#40A3B3" opacity="1.000000" stroke="none" 
	d="
M207.003723,465.486450 
	C216.351212,465.016907 225.687439,465.009399 235.505096,465.457794 
	C236.002548,468.950623 236.018600,471.987488 236.034668,475.024384 
	C235.693314,476.425842 235.351959,477.827332 235.006226,479.616760 
	C230.992584,479.389160 226.994690,478.373383 222.971237,478.259399 
	C217.952286,478.117157 212.912262,478.717926 207.456543,479.000763 
	C207.018646,474.651947 207.005554,470.300201 207.003723,465.486450 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M236.306839,475.277649 
	C236.018600,471.987488 236.002548,468.950623 235.970947,465.460327 
	C235.969604,459.656372 235.983795,454.305847 235.982422,448.490845 
	C236.317551,440.017365 236.668259,432.008270 237.010406,423.269073 
	C236.987656,422.362183 236.973495,422.185425 236.959320,422.008636 
	C237.335724,421.118591 237.750046,420.242279 238.078766,419.334961 
	C238.478455,418.231720 238.806015,417.102386 239.547668,416.408569 
	C239.989395,421.249542 240.048279,425.666260 240.103561,430.373230 
	C240.150192,431.047974 240.200439,431.432465 240.149994,432.284729 
	C240.031738,436.500366 240.014160,440.248169 239.857224,444.246857 
	C239.529739,445.013672 239.341614,445.529602 239.114471,446.479614 
	C239.359711,449.650116 239.643982,452.386566 239.928253,455.123047 
	C239.808105,463.540161 239.687973,471.957306 239.318787,480.702454 
	C238.239502,479.197296 237.409256,477.364136 236.306839,475.277649 
z"/>
<path fill="#060B10" opacity="1.000000" stroke="none" 
	d="
M202.190125,477.788025 
	C198.391647,477.142853 194.677032,476.041595 190.598969,474.910339 
	C190.698196,472.796112 191.160858,470.711853 192.226379,468.328674 
	C193.872421,468.006195 194.915588,467.982574 196.342514,467.974304 
	C198.576996,467.029663 200.427734,466.069702 202.278458,465.109711 
	C202.276962,469.183777 202.275467,473.257812 202.190125,477.788025 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M195.958755,467.958984 
	C194.915588,467.982574 193.872421,468.006195 192.438705,468.029724 
	C193.624008,461.919678 195.199844,455.809784 196.734009,449.322968 
	C196.997665,447.813904 197.302979,446.681702 198.245270,445.296692 
	C199.933167,445.019165 200.984085,444.994507 202.034988,444.969818 
	C202.263840,446.134125 202.492676,447.298401 202.299591,448.869080 
	C200.965286,450.366150 199.541397,451.328552 199.230011,452.570038 
	C197.955017,457.653351 197.019333,462.821838 195.958755,467.958984 
z"/>
<path fill="#0C1A28" opacity="1.000000" stroke="none" 
	d="
M196.342514,467.974304 
	C197.019333,462.821838 197.955017,457.653351 199.230011,452.570038 
	C199.541397,451.328552 200.965286,450.366150 202.170944,449.219727 
	C202.529572,454.182343 202.594940,459.200714 202.469391,464.664368 
	C200.427734,466.069702 198.576996,467.029663 196.342514,467.974304 
z"/>
<path fill="#364E6C" opacity="1.000000" stroke="none" 
	d="
M202.059113,444.552185 
	C200.984085,444.994507 199.933167,445.019165 198.490814,445.050598 
	C198.058441,444.598358 198.017502,444.139313 198.224564,443.159668 
	C198.551865,442.062775 198.631165,441.486511 198.710480,440.910278 
	C199.436768,437.507843 200.163040,434.105408 201.277222,430.293945 
	C202.158676,429.917969 202.652237,429.950989 203.145782,429.984009 
	C203.148178,432.348083 203.150574,434.712097 203.154938,437.516724 
	C202.799011,440.016357 202.441132,442.075439 202.059113,444.552185 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M261.974060,485.000824 
	C258.558350,485.000275 255.138428,484.897614 251.728577,485.038635 
	C249.775574,485.119446 247.838959,485.596313 245.494629,485.957336 
	C245.078384,479.302216 245.062469,472.584564 245.528976,465.409943 
	C249.650681,464.652222 253.289978,464.351501 257.200684,464.007690 
	C257.654266,463.950745 257.836456,463.936951 258.234009,464.213440 
	C259.398224,464.653778 260.347107,464.803833 261.552917,465.284851 
	C261.864594,472.077484 261.919342,478.539154 261.974060,485.000824 
z"/>
<path fill="#060B10" opacity="1.000000" stroke="none" 
	d="
M240.303833,455.079865 
	C239.643982,452.386566 239.359711,449.650116 239.497284,446.440979 
	C241.293365,445.563202 242.667618,445.158081 244.444153,444.860596 
	C247.814850,445.144409 250.783279,445.320557 254.109558,445.657684 
	C255.956894,446.582214 257.446381,447.345764 258.913269,448.551331 
	C258.889801,451.650970 258.888947,454.308655 258.631165,457.187836 
	C256.921448,458.604431 255.468658,459.799530 253.666183,460.919006 
	C251.586777,460.107483 249.857071,459.371582 248.127380,458.635620 
	C245.644730,457.435974 243.162064,456.236328 240.303833,455.079865 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M247.769897,458.766327 
	C249.857071,459.371582 251.586777,460.107483 253.756943,461.215271 
	C255.108017,462.408356 256.018646,463.229584 256.929260,464.050781 
	C253.289978,464.351501 249.650681,464.652222 245.568542,464.938171 
	C243.904037,462.161774 244.054169,459.920715 247.769897,458.766327 
z"/>
<path fill="#ACADA9" opacity="1.000000" stroke="none" 
	d="
M262.218719,485.166809 
	C261.919342,478.539154 261.864594,472.077484 261.524292,464.956390 
	C261.156891,463.866730 261.075043,463.436493 261.061829,462.580750 
	C261.073181,460.436035 261.015900,458.716766 261.158203,456.663330 
	C261.224365,452.887115 261.090973,449.445099 261.034363,445.630859 
	C261.065857,444.503845 261.020538,443.749023 261.267334,442.702515 
	C261.761780,437.630005 261.964081,432.849152 262.514465,428.061401 
	C263.565308,428.043823 264.268097,428.033112 264.980957,428.483185 
	C265.033051,432.947418 265.075043,436.950836 265.070862,441.423767 
	C265.009033,448.725342 264.715973,455.571716 265.067505,462.384857 
	C265.337921,467.625000 266.419434,472.823303 267.140259,478.040192 
	C267.439240,481.070160 267.738220,484.100128 267.765991,487.665039 
	C267.014343,488.447693 266.533875,488.695343 266.053406,488.942993 
	C266.053406,488.943024 266.022675,488.998749 265.966278,488.628265 
	C264.921326,487.841492 263.932770,487.425201 262.944214,487.008911 
	C262.783936,486.450195 262.623657,485.891510 262.218719,485.166809 
z"/>
<path fill="#7D7C8C" opacity="1.000000" stroke="none" 
	d="
M267.458069,478.037445 
	C266.419434,472.823303 265.337921,467.625000 265.067505,462.384857 
	C264.715973,455.571716 265.009033,448.725342 265.425659,441.440948 
	C266.541016,440.998688 267.255402,441.008728 267.984558,441.008545 
	C267.999298,440.998322 267.983276,441.029968 268.032593,441.360291 
	C268.981995,442.108459 269.882050,442.526337 270.840546,443.408813 
	C270.937561,448.251373 270.976105,452.629303 271.014648,457.007263 
	C271.144928,464.058685 271.275208,471.110077 271.260742,478.500488 
	C270.931122,478.865051 270.746246,478.890594 270.281769,478.930969 
	C269.260101,478.642120 268.518005,478.338409 267.458069,478.037445 
z"/>
<path fill="#8F8E92" opacity="1.000000" stroke="none" 
	d="
M262.709106,487.219543 
	C263.932770,487.425201 264.921326,487.841492 265.974426,488.599548 
	C264.850677,488.437622 263.662354,487.933899 262.709106,487.219543 
z"/>
<path fill="#8F8E92" opacity="1.000000" stroke="none" 
	d="
M266.079224,489.262695 
	C266.533875,488.695343 267.014343,488.447693 267.747406,488.100006 
	C267.780182,488.830139 267.560394,489.660248 267.340607,490.490387 
	C266.928741,490.187714 266.516907,489.885040 266.079224,489.262695 
z"/>
<path fill="#24909A" opacity="1.000000" stroke="none" 
	d="
M364.019135,495.034302 
	C364.020020,495.468811 364.020905,495.903290 363.792206,496.776855 
	C363.378784,497.490417 363.194946,497.764893 363.011108,498.039368 
	C361.211334,500.593903 359.411530,503.148407 357.312775,505.853577 
	C356.045319,505.137238 355.076843,504.270233 355.163330,504.347626 
	C333.403625,506.219604 312.562927,504.402130 291.377808,505.003510 
	C288.977264,502.582947 286.983887,500.161163 285.370850,497.364502 
	C288.119598,495.994385 290.756439,493.780792 292.805939,494.232391 
	C298.942535,495.584717 304.607025,491.195068 311.231018,494.294128 
	C315.851074,496.455566 322.212555,494.888824 327.795868,495.001007 
	C329.456055,495.034363 331.730438,494.442963 332.660919,495.296295 
	C335.583160,497.976196 337.285065,496.087494 339.411072,494.384003 
	C341.353485,492.827545 343.194672,492.422729 346.102753,493.214386 
	C350.469818,494.403259 355.341064,496.073212 359.924927,493.068848 
	C362.190552,491.583923 363.695923,492.208771 364.019135,495.034302 
z"/>
<path fill="#3099A8" opacity="1.000000" stroke="none" 
	d="
M364.358582,494.991028 
	C363.695923,492.208771 362.190552,491.583923 359.924927,493.068848 
	C355.341064,496.073212 350.469818,494.403259 346.102753,493.214386 
	C343.194672,492.422729 341.353485,492.827545 339.411072,494.384003 
	C337.285065,496.087494 335.583160,497.976196 332.660919,495.296295 
	C331.730438,494.442963 329.456055,495.034363 327.795868,495.001007 
	C322.212555,494.888824 315.851074,496.455566 311.231018,494.294128 
	C304.607025,491.195068 298.942535,495.584717 292.805939,494.232391 
	C290.756439,493.780792 288.119598,495.994385 285.364777,497.001526 
	C279.067932,496.046112 280.058380,490.379272 278.001953,486.404388 
	C277.767120,484.252869 277.540680,482.478638 277.544525,480.307983 
	C287.538177,479.673248 297.309143,479.229919 307.305725,481.144653 
	C309.747955,483.525604 311.883789,485.746521 314.317352,488.003540 
	C315.076355,488.378967 315.537628,488.718292 316.070190,489.422821 
	C321.150269,492.938507 326.377167,492.970795 329.285645,489.700165 
	C329.449036,489.532135 329.892365,489.379852 330.149475,489.598999 
	C330.617981,489.892059 330.829346,489.965942 331.421448,490.024994 
	C334.101685,489.014526 337.723969,489.936615 337.825073,485.796082 
	C337.777191,485.769196 337.731689,485.669312 337.731689,485.669312 
	C342.042572,487.160339 340.621277,481.592407 344.062866,481.013885 
	C346.445374,480.309601 348.107605,479.181427 349.756104,479.201202 
	C356.832947,479.285919 363.905762,479.708313 370.980042,480.007690 
	C370.640533,483.040649 370.300995,486.073578 369.946350,489.529602 
	C368.186859,491.617676 366.442444,493.282715 364.358582,494.991028 
z"/>
<path fill="#3099A8" opacity="1.000000" stroke="none" 
	d="
M363.232880,497.922485 
	C363.194946,497.764893 363.378784,497.490417 363.787598,497.102722 
	C363.826569,497.261566 363.640625,497.533569 363.232880,497.922485 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M378.070648,466.598816 
	C378.012665,465.753693 378.018677,465.240417 378.071716,464.346558 
	C378.829651,463.969269 379.540558,463.972595 380.607666,463.978149 
	C381.283112,465.109985 381.602386,466.239532 381.929108,467.726135 
	C380.669250,467.699036 379.401947,467.314850 378.070648,466.598816 
z"/>
<path fill="#446585" opacity="1.000000" stroke="none" 
	d="
M375.265198,480.773499 
	C375.013245,479.378174 375.068420,477.749207 375.449402,476.011841 
	C375.707611,477.448914 375.639984,478.994385 375.265198,480.773499 
z"/>
<path fill="#50ABC1" opacity="1.000000" stroke="none" 
	d="
M275.985107,450.918396 
	C277.311920,451.074158 279.116455,451.865936 279.889984,451.285004 
	C286.090210,446.628448 293.049774,449.652710 299.599243,449.404633 
	C300.796021,449.359283 302.145874,453.354980 303.708649,456.185089 
	C303.988098,459.589935 303.983948,462.294952 303.512421,464.999695 
	C294.028168,465.339539 285.011292,465.679718 275.994415,466.019897 
	C275.322418,463.295807 274.650452,460.571747 273.974274,457.006775 
	C274.641754,454.416748 275.313446,452.667572 275.985107,450.918396 
z"/>
<path fill="#40A3B3" opacity="1.000000" stroke="none" 
	d="
M275.993164,466.475677 
	C285.011292,465.679718 294.028168,465.339539 303.532928,465.417969 
	C305.013672,470.885925 306.006531,475.935272 306.999359,480.984650 
	C297.309143,479.229919 287.538177,479.673248 277.393494,479.955444 
	C276.672119,475.643402 276.332031,471.287415 275.993164,466.475677 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M275.920929,450.513153 
	C275.313446,452.667572 274.641754,454.416748 273.973297,456.575562 
	C273.236023,456.988739 272.495544,456.992279 271.384827,457.001556 
	C270.976105,452.629303 270.937561,448.251373 270.883026,443.122955 
	C270.575012,441.918030 270.282990,441.463562 270.028442,440.541687 
	C269.940765,434.343903 269.815613,428.613586 269.690430,422.883240 
	C269.792450,421.177948 269.894470,419.472687 270.469696,417.366608 
	C272.288757,419.983643 273.634613,423.001495 274.980469,426.019318 
	C275.272583,434.048859 275.564667,442.078369 275.920929,450.513153 
z"/>
<path fill="#40A3B3" opacity="1.000000" stroke="none" 
	d="
M766.785889,468.726196 
	C763.847107,472.552307 760.699463,476.088501 757.283447,479.816589 
	C748.108948,479.707092 739.199768,479.474487 730.298340,479.070038 
	C726.792053,478.910736 723.303162,478.367798 719.391541,477.997681 
	C721.873901,472.070526 725.744812,466.961792 731.632263,463.139374 
	C741.762756,463.670135 751.592957,464.187653 761.379517,465.149811 
	C763.202087,465.329010 764.848999,467.294922 766.785889,468.726196 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M733.294189,430.989319 
	C733.568115,429.827484 734.292664,428.710175 734.833069,427.509979 
	C735.401855,426.246704 735.794434,424.904114 736.135559,423.288452 
	C737.182190,422.701630 738.357239,422.422485 740.844177,421.831726 
	C739.137268,425.211212 738.092834,427.279175 737.009399,429.716797 
	C735.868286,430.390076 734.766113,430.693695 733.294189,430.989319 
z"/>
<path fill="#76C0DF" opacity="1.000000" stroke="none" 
	d="
M275.363251,426.019958 
	C273.634613,423.001495 272.288757,419.983643 270.574463,416.950073 
	C270.206024,416.934357 269.927734,416.644440 269.902771,416.445435 
	C269.915161,415.756561 269.952515,415.266693 269.995361,414.140930 
	C270.003906,413.327179 270.006927,413.149323 270.402649,412.987244 
	C272.865753,413.550629 275.234009,413.631287 276.953857,414.728394 
	C283.642883,418.995422 290.898407,416.300323 297.875763,416.767395 
	C298.633881,416.818176 299.764221,415.881836 300.216614,415.107971 
	C301.877625,412.266571 304.159485,411.875244 306.991455,413.218964 
	C307.000702,413.645416 307.003998,413.816010 307.007324,413.986572 
	C306.333038,415.750732 305.658783,417.514923 304.956543,419.653137 
	C303.602722,422.271332 302.276855,424.515442 300.363098,427.754517 
	C300.232666,427.765594 298.809357,427.899170 297.383972,428.005096 
	C293.035522,428.328278 288.658936,429.076141 284.346832,428.805573 
	C281.433105,428.622742 278.609802,426.999542 275.363251,426.019958 
z"/>
<path fill="#91B7EB" opacity="1.000000" stroke="none" 
	d="
M307.384064,413.984314 
	C307.003998,413.816010 307.000702,413.645416 307.152771,412.916565 
	C307.887665,411.765198 308.359741,410.930267 309.063782,410.617493 
	C314.357788,408.265686 310.568115,404.194641 310.545593,401.065094 
	C310.538177,400.029968 307.111877,398.180939 305.214386,398.135376 
	C293.733887,397.859650 282.243378,398.014771 270.756317,397.990021 
	C266.837036,397.981567 264.316437,400.030182 265.192169,403.735748 
	C265.966217,407.010925 268.341614,409.907654 270.009979,412.971497 
	C270.006927,413.149323 270.003906,413.327179 269.993469,413.772156 
	C267.751526,412.771790 264.023254,411.883392 263.585815,410.159790 
	C262.599243,406.272675 262.897919,401.926758 263.438934,397.869507 
	C263.591309,396.726715 266.383270,395.138184 267.986633,395.112091 
	C281.441254,394.893097 294.901306,394.944061 308.359100,395.021057 
	C312.376587,395.044037 314.157715,397.052704 314.111938,401.326355 
	C314.011169,410.738708 313.624756,411.879639 307.384064,413.984314 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M262.766052,415.856750 
	C261.982819,415.972717 260.996704,415.827606 259.998047,415.361603 
	C260.844757,415.225708 261.703949,415.410675 262.766052,415.856750 
z"/>
<path fill="#3099A8" opacity="1.000000" stroke="none" 
	d="
M135.991348,482.429504 
	C138.476044,480.459229 140.956085,477.577911 143.442245,477.572601 
	C156.393127,477.545074 169.345535,478.249390 182.656097,478.859039 
	C182.271957,482.963959 181.529144,486.908783 180.414627,490.934021 
	C175.718613,490.345367 171.415131,489.372131 167.065399,489.074066 
	C159.312866,488.542755 151.529007,488.191162 143.766174,488.305054 
	C142.072708,488.329926 140.412613,490.628357 138.381882,491.942230 
	C137.347580,488.951599 136.668549,485.898407 135.991348,482.429504 
z"/>
<path fill="#87CEF0" opacity="1.000000" stroke="none" 
	d="
M182.000290,412.985931 
	C183.089142,408.885254 183.791809,404.616302 185.466934,400.771027 
	C186.090973,399.338562 188.978683,398.145081 190.850754,398.120789 
	C206.832886,397.913544 222.821274,397.872559 238.800079,398.181091 
	C240.550568,398.214874 243.609970,400.684692 243.695572,402.161743 
	C243.876099,405.277496 246.216751,409.819092 240.628677,411.260498 
	C238.138702,411.902771 235.139404,412.225739 235.513702,416.552948 
	C226.388382,416.997467 217.734100,416.982056 209.080292,417.039673 
	C199.836990,417.101227 190.255844,419.496521 182.000290,412.985931 
z"/>
<path fill="#76C0DF" opacity="1.000000" stroke="none" 
	d="
M181.741623,413.190063 
	C190.255844,419.496521 199.836990,417.101227 209.080292,417.039673 
	C217.734100,416.982056 226.388382,416.997467 235.507980,416.965332 
	C236.124252,418.461731 236.275009,419.973511 236.692535,421.746948 
	C236.973495,422.185425 236.987656,422.362183 236.986298,422.805511 
	C236.564636,423.572449 235.862930,424.036652 235.796585,424.578613 
	C234.776230,432.914520 229.287796,433.746033 222.393280,433.088470 
	C217.530716,432.624695 212.588242,432.998779 208.620239,432.998779 
	C206.998627,430.464020 205.902695,428.750946 204.378387,426.663086 
	C203.458954,425.579041 202.967896,424.869781 202.476837,424.160522 
	C201.668015,425.457764 200.859177,426.755035 200.050323,428.052277 
	C199.158783,427.945343 197.622696,427.497498 197.472000,427.782379 
	C195.073746,432.316650 190.864288,432.070770 186.753922,431.977386 
	C183.367188,431.900452 179.984848,431.629028 176.301270,431.222839 
	C176.896500,426.239960 177.791000,421.478027 178.846649,416.360199 
	C179.832870,415.134247 180.657913,414.264191 181.741623,413.190063 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M262.166412,428.068329 
	C261.964081,432.849152 261.761780,437.630005 260.881470,442.710876 
	C259.428589,442.977142 258.653656,442.943390 257.947357,442.570587 
	C258.009399,440.481720 258.002838,438.731934 258.118744,436.661377 
	C257.798889,434.664459 257.356567,432.988312 257.114990,430.971893 
	C257.519104,428.646393 258.162170,425.009583 257.858521,424.928741 
	C254.435196,424.017303 250.830383,423.175323 247.359665,423.438904 
	C246.358704,423.514923 245.631363,427.193848 244.433960,429.196716 
	C242.761948,429.475983 241.434540,429.779480 240.107147,430.083008 
	C240.048279,425.666260 239.989395,421.249542 239.895660,416.357758 
	C240.313873,415.798706 240.766983,415.714661 241.549179,415.738861 
	C241.233704,419.130646 242.764771,420.643494 245.922165,421.042542 
	C251.108932,421.698059 256.281433,422.466553 261.795898,423.693909 
	C262.143372,425.489685 262.154877,426.778992 262.166412,428.068329 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M269.331970,422.893494 
	C269.815613,428.613586 269.940765,434.343903 269.694000,440.526428 
	C268.875824,440.995728 268.429565,441.012848 267.983276,441.029968 
	C267.983276,441.029968 267.999298,440.998322 267.993652,440.539825 
	C267.976532,436.117645 268.229858,432.125885 267.814636,428.204956 
	C267.662598,426.769379 265.925049,425.501709 264.716125,424.074219 
	C264.225708,423.895050 264.046844,423.694824 263.984833,423.390259 
	C265.647705,423.228088 267.310577,423.065887 269.331970,422.893494 
z"/>
<path fill="#8F8E92" opacity="1.000000" stroke="none" 
	d="
M263.706635,423.310852 
	C264.046844,423.694824 264.225708,423.895050 264.751251,424.457336 
	C264.977661,425.956635 264.974274,426.989502 264.970917,428.022400 
	C264.268097,428.033112 263.565308,428.043823 262.514465,428.061401 
	C262.154877,426.778992 262.143372,425.489685 262.218140,423.831604 
	C262.679077,423.385712 263.053772,423.308563 263.706635,423.310852 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M127.040024,466.980408 
	C127.087135,467.425751 127.134239,467.871094 127.297150,468.634399 
	C128.556656,473.728241 129.700363,478.504089 130.895386,483.652008 
	C129.519867,483.683960 128.093048,483.343842 126.346359,482.993622 
	C124.937431,480.565918 123.848396,478.148285 122.212997,474.517792 
	C122.037552,476.688507 121.974365,477.470306 121.888153,478.578613 
	C118.164085,479.610504 117.676247,477.492218 118.040291,474.582794 
	C118.487549,471.008240 118.903297,467.429749 119.438904,462.967987 
	C120.413780,462.967987 122.311340,462.967987 124.644547,462.949371 
	C125.312401,463.472931 125.544609,464.015106 125.972916,465.036865 
	C126.459343,466.004425 126.749680,466.492432 127.040024,466.980408 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M123.113403,455.235779 
	C123.591682,455.513214 124.027306,456.098877 124.237671,456.851440 
	C123.698532,456.526886 123.384636,456.035461 123.113403,455.235779 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M127.261871,466.823975 
	C126.749680,466.492432 126.459343,466.004425 126.208298,465.215515 
	C126.659637,465.498932 127.071686,466.083252 127.261871,466.823975 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M200.076477,428.286682 
	C200.859177,426.755035 201.668015,425.457764 202.476837,424.160522 
	C202.967896,424.869781 203.458954,425.579041 203.975006,426.652710 
	C203.869476,427.859283 203.738953,428.701447 203.377106,429.763824 
	C202.652237,429.950989 202.158676,429.917969 201.293793,429.896454 
	C200.844406,429.639587 200.707031,429.406097 200.339554,429.097839 
	C200.168732,428.988159 200.102600,428.521088 200.076477,428.286682 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M198.451263,440.975891 
	C198.631165,441.486511 198.551865,442.062775 198.224701,442.813324 
	C198.048553,442.338928 198.120300,441.690186 198.451263,440.975891 
z"/>
<path fill="#E9EBEF" opacity="1.000000" stroke="none" 
	d="
M784.450439,466.040924 
	C784.322021,464.722870 784.243896,463.011292 785.049683,462.013916 
	C790.283325,455.536163 795.709229,449.213745 801.756592,442.858826 
	C810.532104,448.126923 819.298340,446.145752 827.962280,445.260712 
	C828.350098,445.302277 828.737854,445.343842 829.580444,445.752380 
	C831.636719,446.689087 833.238281,447.258850 835.659729,448.120300 
	C833.645996,450.780182 831.887329,452.882263 830.420715,455.171478 
	C829.964844,455.882904 830.300537,457.101501 830.273804,458.087921 
	C830.149841,458.436127 830.025879,458.784332 829.482910,459.304077 
	C828.653931,459.608582 828.243835,459.741638 827.478516,459.904846 
	C825.969360,461.848053 824.815491,463.761078 823.661560,465.674072 
	C823.661560,465.674072 823.570801,466.000977 823.046753,466.000977 
	C818.014343,466.301270 813.506042,466.601532 808.997742,466.901825 
	C807.899719,466.896515 806.801697,466.891174 805.324585,467.047974 
	C803.546387,467.056915 802.147278,466.903717 800.117920,466.445435 
	C798.874817,466.207977 798.261902,466.275635 797.649048,466.343262 
	C793.402527,466.221405 789.156067,466.099548 784.450439,466.040924 
z"/>
<path fill="#010204" opacity="1.000000" stroke="none" 
	d="
M843.113953,449.140564 
	C843.113953,449.140564 843.553101,449.027222 843.778076,448.997437 
	C845.593811,448.390625 847.184692,447.813568 849.318726,446.999939 
	C854.659546,448.546448 859.457092,450.329529 864.254700,452.112640 
	C863.077087,453.735046 861.899536,455.357483 860.409912,457.104248 
	C856.773865,456.816864 853.457764,456.312653 850.122925,456.026978 
	C848.019653,455.846802 845.888306,455.993317 843.272949,455.993317 
	C843.458618,454.115662 843.594727,452.738586 843.575134,450.901550 
	C843.419373,450.441559 843.117065,450.314758 843.138611,450.021149 
	C843.144714,449.531891 843.129333,449.336212 843.113953,449.140564 
z"/>
<path fill="#ACADA9" opacity="1.000000" stroke="none" 
	d="
M830.603394,458.173340 
	C830.300537,457.101501 829.964844,455.882904 830.420715,455.171478 
	C831.887329,452.882263 833.645996,450.780182 835.659729,448.120300 
	C833.238281,447.258850 831.636719,446.689087 829.866455,445.814728 
	C832.979919,445.510132 836.262207,445.510132 840.430054,445.510132 
	C838.525513,449.047699 837.175110,451.555969 835.833130,454.418304 
	C834.205261,455.934540 832.569092,457.096680 830.603394,458.173340 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M817.399292,477.004608 
	C818.810547,474.744812 820.615051,472.485870 822.751465,470.118896 
	C824.873596,471.098541 826.663818,472.186218 828.811462,473.810089 
	C829.438904,474.904449 829.708862,475.462585 829.978760,476.020752 
	C825.916687,476.348969 821.854614,476.677216 817.399292,477.004608 
z"/>
<path fill="#ACADA9" opacity="1.000000" stroke="none" 
	d="
M809.226440,467.199646 
	C813.506042,466.601532 818.014343,466.301270 822.913330,466.105743 
	C823.217651,466.873138 823.131287,467.535767 822.877808,468.503906 
	C818.292114,468.372101 813.873657,467.934814 809.226440,467.199646 
z"/>
<path fill="#364E6C" opacity="1.000000" stroke="none" 
	d="
M830.204651,476.294800 
	C829.708862,475.462585 829.438904,474.904449 829.085266,474.039886 
	C831.699524,473.792145 834.397400,473.850830 837.543091,473.873413 
	C838.647766,474.717163 839.304688,475.597046 839.978394,476.819550 
	C836.806946,476.964417 833.618713,476.766663 830.204651,476.294800 
z"/>
<path fill="#ACADA9" opacity="1.000000" stroke="none" 
	d="
M823.997925,465.723511 
	C824.815491,463.761078 825.969360,461.848053 827.564331,460.290192 
	C828.090088,461.160797 828.174866,461.676300 828.259644,462.191833 
	C826.951172,463.385559 825.642700,464.579254 823.997925,465.723511 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M828.612427,462.124512 
	C828.174866,461.676300 828.090088,461.160797 827.919556,460.260010 
	C828.243835,459.741638 828.653931,459.608582 829.374817,459.562988 
	C829.445435,460.452698 829.205322,461.254974 828.612427,462.124512 
z"/>
<path fill="#2C2F3F" opacity="1.000000" stroke="none" 
	d="
M842.776733,449.115234 
	C843.129333,449.336212 843.144714,449.531891 843.211060,450.048492 
	C843.261902,450.369446 843.366638,450.496277 843.366638,450.496277 
	C842.874573,450.653351 842.382446,450.810364 841.506714,451.015991 
	C841.561890,450.406342 842.000671,449.748108 842.776733,449.115234 
z"/>
<path fill="#11293E" opacity="1.000000" stroke="none" 
	d="
M853.641602,465.043823 
	C853.573669,465.692230 853.162842,466.387756 852.455933,467.198761 
	C852.539429,466.573151 852.919067,465.832031 853.641602,465.043823 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M843.392944,450.536987 
	C843.366638,450.496277 843.261902,450.369446 843.189453,450.342102 
	C843.117065,450.314758 843.419373,450.441559 843.467651,450.505280 
	C843.515869,450.569000 843.419312,450.577667 843.392944,450.536987 
z"/>
<path fill="#364E6C" opacity="1.000000" stroke="none" 
	d="
M864.580383,452.045349 
	C859.457092,450.329529 854.659546,448.546448 849.484497,446.667114 
	C850.361694,445.738678 851.587463,444.244415 852.875977,444.188141 
	C858.644836,443.936310 864.431580,444.094116 870.693481,444.164490 
	C869.085388,446.809265 866.995728,449.393646 864.580383,452.045349 
z"/>
<path fill="#ACADA9" opacity="1.000000" stroke="none" 
	d="
M797.755066,466.649506 
	C798.261902,466.275635 798.874817,466.207977 799.700806,466.375610 
	C799.229614,466.725861 798.545349,466.840790 797.755066,466.649506 
z"/>
<path fill="#0C151E" opacity="1.000000" stroke="none" 
	d="
M609.171997,427.350647 
	C609.845032,427.023590 610.368103,427.013245 611.148071,426.968811 
	C611.956726,428.685333 612.508606,430.435944 612.779053,432.716461 
	C611.678162,434.162170 610.858582,435.077942 609.704651,436.367310 
	C611.837036,437.426483 613.419495,438.212494 615.001953,438.998505 
	C617.247803,445.961182 619.493591,452.923828 622.219055,461.373413 
	C613.761169,460.719940 606.915771,460.191040 600.421387,459.321594 
	C608.248962,458.850098 610.580139,455.598114 607.902466,448.798187 
	C608.980164,447.075012 609.973328,445.706177 610.960449,444.345581 
	C609.875366,443.117889 608.939270,442.058746 608.232483,440.834778 
	C608.010742,440.095978 607.559692,439.522003 606.828003,438.937592 
	C606.361328,438.948242 606.175293,438.969299 605.989258,438.990387 
	C606.500549,436.863403 607.011841,434.736420 607.980957,432.126038 
	C608.536682,431.239136 608.634399,430.835632 608.732178,430.432129 
	C608.828796,429.510529 608.925476,428.588959 609.171997,427.350647 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M608.003174,440.999603 
	C608.939270,442.058746 609.875366,443.117889 610.960449,444.345581 
	C609.973328,445.706177 608.980164,447.075012 607.587280,448.843018 
	C605.084900,451.170074 602.982422,453.097992 600.879883,455.025909 
	C601.227722,453.266235 601.575500,451.506561 601.887939,449.361542 
	C602.200256,447.871735 602.548035,446.767273 603.150513,445.153137 
	C603.530701,444.086884 603.656311,443.530243 603.781860,442.973602 
	C604.332764,441.763916 604.883667,440.554230 605.711914,439.167480 
	C606.175293,438.969299 606.361328,438.948242 606.805298,439.243011 
	C607.376526,440.039093 607.689819,440.519348 608.003174,440.999603 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M600.625488,455.250366 
	C602.982422,453.097992 605.084900,451.170074 607.502686,449.197327 
	C610.580139,455.598114 608.248962,458.850098 600.359375,458.903564 
	C600.087952,457.709015 600.229553,456.591919 600.625488,455.250366 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M614.962646,438.637115 
	C613.419495,438.212494 611.837036,437.426483 609.704651,436.367310 
	C610.858582,435.077942 611.678162,434.162170 612.807739,433.106781 
	C613.719666,434.736694 614.321533,436.506226 614.962646,438.637115 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M603.515137,443.016663 
	C603.656311,443.530243 603.530701,444.086884 603.157166,444.806061 
	C603.022217,444.332306 603.135254,443.696045 603.515137,443.016663 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M608.483643,430.624786 
	C608.634399,430.835632 608.536682,431.239136 608.174500,431.822449 
	C608.018433,431.607300 608.126770,431.212372 608.483643,430.624786 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M877.069092,437.763977 
	C875.646973,435.607849 874.180298,434.580322 871.708069,436.104095 
	C868.838013,437.873047 867.145996,436.395325 866.021179,433.350769 
	C866.324829,430.582703 866.671631,428.171143 867.425537,425.330200 
	C874.605347,424.558655 881.378235,424.216400 888.261536,424.081665 
	C888.372009,424.289185 888.487366,424.744995 888.487366,424.744995 
	C888.487366,424.744995 888.212219,424.852112 887.808533,425.073669 
	C886.600708,426.521057 885.796631,427.746918 884.992554,428.972778 
	C884.621460,429.352905 884.250366,429.733063 883.468018,430.486633 
	C881.260254,433.151672 879.463806,435.443329 877.667358,437.734985 
	C877.667358,437.734985 877.268005,437.801514 877.069092,437.763977 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M878.005737,437.819702 
	C879.463806,435.443329 881.260254,433.151672 883.367737,430.737549 
	C883.402283,434.143921 882.860535,437.478638 878.005737,437.819702 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M884.967041,418.119843 
	C887.203735,418.294830 890.134949,416.477173 891.226074,420.659424 
	C891.338684,421.056824 891.077454,421.466980 890.783020,421.730988 
	C890.193604,422.138977 889.886230,422.174164 889.224121,421.960815 
	C887.576904,420.587311 886.271973,419.353577 884.967041,418.119843 
z"/>
<path fill="#537DA5" opacity="1.000000" stroke="none" 
	d="
M885.344238,428.948486 
	C885.796631,427.746918 886.600708,426.521057 887.707397,425.181183 
	C887.238647,426.352844 886.467285,427.638519 885.344238,428.948486 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M889.566345,422.100525 
	C889.886230,422.174164 890.193604,422.138977 890.722046,421.966370 
	C890.392273,422.899078 889.829041,423.860413 888.876587,424.783386 
	C888.487366,424.744995 888.372009,424.289185 888.556519,423.834778 
	C889.016052,422.953735 889.291199,422.527130 889.566345,422.100525 
z"/>
<path fill="#060B10" opacity="1.000000" stroke="none" 
	d="
M889.224121,421.960815 
	C889.291199,422.527130 889.016052,422.953735 888.446045,423.627258 
	C881.378235,424.216400 874.605347,424.558655 867.420349,424.955811 
	C865.552795,424.319305 864.097351,423.627899 862.343933,422.965881 
	C861.947083,421.601959 861.848328,420.208588 862.074097,418.329712 
	C862.682251,417.658203 862.884521,417.406799 863.005432,417.089996 
	C870.065063,417.331848 877.124756,417.573669 884.575684,417.967651 
	C886.271973,419.353577 887.576904,420.587311 889.224121,421.960815 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M862.678589,417.049469 
	C862.884521,417.406799 862.682251,417.658203 862.164307,417.917236 
	C861.845886,417.831329 861.761841,417.672394 861.685852,417.243866 
	C861.913147,416.985840 862.132385,416.997375 862.678589,417.049469 
z"/>
<path fill="#ACADA9" opacity="1.000000" stroke="none" 
	d="
M827.975525,444.950623 
	C819.298340,446.145752 810.532104,448.126923 802.065918,442.711792 
	C802.969238,439.748413 804.533142,439.266693 807.066040,441.399628 
	C808.150696,442.313049 809.981567,442.340393 811.711914,442.524536 
	C812.715332,442.290436 813.480164,442.302124 814.761536,442.513367 
	C815.850037,442.565399 816.421997,442.417847 816.994019,442.270294 
	C819.636536,442.301331 822.279053,442.332367 825.517212,442.524384 
	C826.365417,442.871490 826.647827,442.979584 826.960083,443.009705 
	C826.960083,443.009705 827.326416,443.242950 827.520752,443.340332 
	C827.806274,443.838654 827.897522,444.239594 827.975525,444.950623 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M816.904541,441.957214 
	C816.421997,442.417847 815.850037,442.565399 815.142944,442.494843 
	C815.610291,442.065857 816.212708,441.855011 816.904541,441.957214 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M826.879272,442.746826 
	C826.647827,442.979584 826.365417,442.871490 825.953979,442.522583 
	C826.129517,442.401184 826.463989,442.442566 826.879272,442.746826 
z"/>
<path fill="#40A3B3" opacity="1.000000" stroke="none" 
	d="
M370.986267,479.553711 
	C363.905762,479.708313 356.832947,479.285919 349.756104,479.201202 
	C348.107605,479.181427 346.445374,480.309601 344.421722,480.955261 
	C345.032471,476.268127 346.010956,471.535309 347.009338,466.402893 
	C351.257416,465.668823 355.492920,465.407166 359.711487,464.978821 
	C363.670563,464.576843 367.611725,463.998688 371.779205,463.760010 
	C371.662567,469.047882 371.327515,474.073822 370.986267,479.553711 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M329.892365,489.379852 
	C329.892365,489.379852 329.449036,489.532135 328.864838,489.668091 
	C325.870087,489.515839 323.459503,489.227631 321.196533,488.663879 
	C321.973083,487.170288 322.602051,485.952271 323.231018,484.734222 
	C327.583038,483.481323 331.935059,482.228424 336.678101,480.990936 
	C337.348053,482.334320 337.626984,483.662262 337.818787,485.329773 
	C337.731689,485.669312 337.777191,485.769196 337.476807,485.819214 
	C335.133575,486.925720 333.090729,487.982239 330.837250,489.026428 
	C330.341858,489.055847 330.097107,489.177734 329.892365,489.379852 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M340.634430,455.735718 
	C341.192413,454.220154 341.750366,452.704559 342.650452,451.061005 
	C342.647705,458.263855 342.330750,465.596283 341.910767,472.922821 
	C341.872406,473.591370 341.173706,474.222076 340.403229,474.954315 
	C339.727234,474.551636 339.429688,474.065033 339.342468,473.075104 
	C339.388062,471.384918 339.223297,470.198059 339.058563,469.011200 
	C339.103699,468.560547 339.148804,468.109863 339.617493,467.215576 
	C340.238861,463.093170 340.436646,459.414459 340.634430,455.735718 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M307.193298,437.384888 
	C308.949097,437.004578 310.601837,436.975922 312.581482,436.999817 
	C312.943756,444.722473 312.979126,452.392639 312.591797,460.550171 
	C310.781525,461.024597 309.393951,461.011658 307.726807,460.695007 
	C307.259583,453.568329 307.072021,446.745361 306.884430,439.922363 
	C306.953033,439.193756 307.021667,438.465149 307.193298,437.384888 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M306.547729,440.131348 
	C307.072021,446.745361 307.259583,453.568329 307.643250,461.127380 
	C307.863373,463.911011 307.887451,465.958618 307.675476,468.238770 
	C308.641449,472.644012 309.843445,476.816742 311.037415,481.354004 
	C312.026123,483.801514 313.022858,485.884491 314.019592,487.967438 
	C311.883789,485.746521 309.747955,483.525604 307.305725,481.144653 
	C306.006531,475.935272 305.013672,470.885925 304.000305,465.418274 
	C303.983948,462.294952 303.988098,459.589935 303.971436,456.433228 
	C304.292786,452.791382 304.566254,449.591827 305.001740,446.414429 
	C305.281555,444.372833 305.799683,442.363922 306.547729,440.131348 
z"/>
<path fill="#8196C9" opacity="1.000000" stroke="none" 
	d="
M306.471924,421.979248 
	C306.874603,422.715485 306.960388,423.521729 306.910431,424.718811 
	C306.568115,424.089539 306.361572,423.069397 306.471924,421.979248 
z"/>
<path fill="#090B10" opacity="1.000000" stroke="none" 
	d="
M307.911530,468.006195 
	C307.887451,465.958618 307.863373,463.911011 307.922852,461.431091 
	C309.393951,461.011658 310.781525,461.024597 312.585144,461.018768 
	C315.479340,461.160278 317.957489,461.320557 320.724945,462.138916 
	C321.089325,464.257660 320.747589,465.914337 321.323730,467.139313 
	C322.635101,469.927673 321.060486,470.899872 319.009308,471.817078 
	C317.652954,471.593079 316.296631,471.369110 314.185730,471.081940 
	C311.591278,470.014557 309.751404,469.010376 307.911530,468.006195 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M319.120728,472.151459 
	C321.060486,470.899872 322.635101,469.927673 321.323730,467.139313 
	C320.747589,465.914337 321.089325,464.257660 321.047485,462.370972 
	C324.135162,465.200562 324.253265,468.856628 322.226257,472.957458 
	C321.008667,472.931671 320.120392,472.708771 319.120728,472.151459 
z"/>
<path fill="#FAF9FD" opacity="1.000000" stroke="none" 
	d="
M336.964539,424.761719 
	C337.603790,432.059204 337.857880,439.458374 338.070953,446.858673 
	C338.151855,449.666992 338.138306,452.478027 338.262695,456.167969 
	C338.241486,460.361938 338.125366,463.675812 338.009247,466.989685 
	C337.066284,470.075073 336.123352,473.160492 335.103363,476.630310 
	C333.682434,473.400116 331.342468,469.829712 331.179626,466.162689 
	C330.671570,454.721191 331.038055,443.242737 330.955200,431.779419 
	C330.926514,427.807495 332.709076,425.559998 336.964539,424.761719 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M314.317352,488.003540 
	C313.022858,485.884491 312.026123,483.801514 311.392548,481.280518 
	C312.604126,478.832245 313.452545,476.822052 314.300964,474.811859 
	C314.867920,476.050537 315.310089,477.368042 316.030975,478.509491 
	C317.124878,480.241638 318.412415,481.851501 319.587952,484.064209 
	C316.774292,485.237976 316.620087,486.819244 317.648346,488.974243 
	C316.878876,489.017181 316.438873,489.037415 315.998871,489.057617 
	C315.537628,488.718292 315.076355,488.378967 314.317352,488.003540 
z"/>
<path fill="#364E6C" opacity="1.000000" stroke="none" 
	d="
M314.233490,474.457642 
	C313.452545,476.822052 312.604126,478.832245 311.400574,480.915955 
	C309.843445,476.816742 308.641449,472.644012 307.675476,468.238770 
	C309.751404,469.010376 311.591278,470.014557 313.836548,471.120667 
	C314.216644,472.182831 314.191315,473.143127 314.233490,474.457642 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M338.339600,466.978943 
	C338.125366,463.675812 338.241486,460.361938 338.453003,456.612488 
	C339.073669,456.073486 339.598969,455.970062 340.379364,455.801208 
	C340.436646,459.414459 340.238861,463.093170 339.685852,466.882568 
	C339.110382,466.984863 338.890167,466.976532 338.339600,466.978943 
z"/>
<path fill="#364E6C" opacity="1.000000" stroke="none" 
	d="
M317.977814,488.951538 
	C316.620087,486.819244 316.774292,485.237976 319.767212,484.367401 
	C320.812531,484.187531 321.648804,484.255341 322.858063,484.528687 
	C322.602051,485.952271 321.973083,487.170288 320.813507,488.702545 
	C319.514526,488.995026 318.746185,488.973297 317.977814,488.951538 
z"/>
<path fill="#BFC0BD" opacity="1.000000" stroke="none" 
	d="
M327.051270,460.367554 
	C327.719208,462.394073 328.325592,464.855591 328.838867,467.763245 
	C328.160400,465.740479 327.575073,463.271515 327.051270,460.367554 
z"/>
<path fill="#BFC0BD" opacity="1.000000" stroke="none" 
	d="
M328.514893,470.243500 
	C329.122925,471.139771 329.513153,472.283936 329.819092,473.822754 
	C329.255585,472.975372 328.776337,471.733398 328.514893,470.243500 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M338.794556,469.217468 
	C339.223297,470.198059 339.388062,471.384918 339.300781,472.782227 
	C338.876038,471.803040 338.703308,470.613373 338.794556,469.217468 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M317.024109,432.427795 
	C316.433868,432.381531 315.747589,432.044861 314.989746,431.389435 
	C315.588165,431.426239 316.258087,431.781799 317.024109,432.427795 
z"/>
<path fill="#0C1A28" opacity="1.000000" stroke="none" 
	d="
M256.914215,431.312134 
	C257.356567,432.988312 257.798889,434.664459 257.773682,436.517120 
	C253.226562,435.508850 249.146988,434.324127 244.716309,433.051056 
	C242.993698,432.580780 241.622177,432.198883 240.250671,431.816956 
	C240.200439,431.432465 240.150192,431.047974 240.103546,430.373230 
	C241.434540,429.779480 242.761948,429.475983 244.694641,429.497742 
	C249.171371,430.319397 253.042786,430.815765 256.914215,431.312134 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M240.149994,432.284729 
	C241.622177,432.198883 242.993698,432.580780 244.683929,433.489319 
	C244.728897,437.351379 244.455139,440.686798 243.763000,444.013977 
	C242.228607,444.002502 241.112595,443.999268 239.996582,443.996002 
	C240.014160,440.248169 240.031738,436.500366 240.149994,432.284729 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M239.857208,444.246857 
	C241.112595,443.999268 242.228607,444.002502 243.738419,444.198822 
	C244.132202,444.391907 244.041870,444.752991 244.041870,444.752991 
	C242.667618,445.158081 241.293365,445.563202 239.536301,446.006927 
	C239.341614,445.529602 239.529739,445.013672 239.857208,444.246857 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M260.958618,456.997528 
	C261.015900,458.716766 261.073181,460.436035 260.730011,462.573883 
	C259.559235,463.302704 258.788940,463.612915 258.018646,463.923096 
	C257.836456,463.936951 257.654266,463.950745 257.200684,464.007690 
	C256.018646,463.229584 255.108017,462.408356 254.106644,461.290894 
	C255.468658,459.799530 256.921448,458.604431 258.977478,457.200317 
	C260.040039,456.993347 260.499329,456.995422 260.958618,456.997528 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M258.234009,464.213440 
	C258.788940,463.612915 259.559235,463.302704 260.661377,462.999390 
	C261.075043,463.436493 261.156891,463.866730 261.267365,464.625427 
	C260.347107,464.803833 259.398224,464.653778 258.234009,464.213440 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M244.444153,444.860596 
	C244.041870,444.752991 244.132202,444.391907 244.156799,444.207092 
	C244.455139,440.686798 244.728897,437.351379 245.035034,433.577667 
	C249.146988,434.324127 253.226562,435.508850 257.651184,436.837891 
	C258.002838,438.731934 258.009399,440.481720 257.608398,442.588928 
	C256.051086,443.796448 254.901382,444.646606 253.751694,445.496735 
	C250.783279,445.320557 247.814850,445.144409 244.444153,444.860596 
z"/>
<path fill="#454759" opacity="1.000000" stroke="none" 
	d="
M261.158203,456.663330 
	C260.499329,456.995422 260.040039,456.993347 259.234436,456.978760 
	C258.888947,454.308655 258.889801,451.650970 258.978333,448.223816 
	C259.696533,446.970551 260.327057,446.486816 260.957550,446.003052 
	C261.090973,449.445099 261.224365,452.887115 261.158203,456.663330 
z"/>
<path fill="#3C3F48" opacity="1.000000" stroke="none" 
	d="
M261.034363,445.630859 
	C260.327057,446.486816 259.696533,446.970551 259.000946,447.781799 
	C257.446381,447.345764 255.956894,446.582214 254.109558,445.657684 
	C254.901382,444.646606 256.051086,443.796448 257.539734,442.927979 
	C258.653656,442.943390 259.428589,442.977142 260.589355,443.002563 
	C261.020538,443.749023 261.065857,444.503845 261.034363,445.630859 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M264.980957,428.483185 
	C264.974274,426.989502 264.977661,425.956635 264.945892,424.540588 
	C265.925049,425.501709 267.662598,426.769379 267.814636,428.204956 
	C268.229858,432.125885 267.976532,436.117645 267.978882,440.550049 
	C267.255402,441.008728 266.541016,440.998688 265.471863,440.971466 
	C265.075043,436.950836 265.033051,432.947418 264.980957,428.483185 
z"/>
<path fill="#696B78" opacity="1.000000" stroke="none" 
	d="
M268.032593,441.360291 
	C268.429565,441.012848 268.875824,440.995728 269.656555,440.993866 
	C270.282990,441.463562 270.575012,441.918030 270.824554,442.658325 
	C269.882050,442.526337 268.981995,442.108459 268.032593,441.360291 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M317.648346,488.974243 
	C318.746185,488.973297 319.514526,488.995026 320.665924,488.978088 
	C323.459503,489.227631 325.870087,489.515839 328.701477,489.836121 
	C326.377167,492.970795 321.150269,492.938507 316.070190,489.422821 
	C316.438873,489.037415 316.878876,489.017181 317.648346,488.974243 
z"/>
<path fill="#6083AE" opacity="1.000000" stroke="none" 
	d="
M331.047882,489.038727 
	C333.090729,487.982239 335.133575,486.925720 337.524658,485.846069 
	C337.723969,489.936615 334.101685,489.014526 331.408508,489.774384 
	C331.025909,489.371948 331.036896,489.205322 331.047882,489.038727 
z"/>
<path fill="#364E6C" opacity="1.000000" stroke="none" 
	d="
M330.837250,489.026428 
	C331.036896,489.205322 331.025909,489.371948 331.027832,489.789185 
	C330.829346,489.965942 330.617981,489.892059 330.149475,489.598999 
	C330.097107,489.177734 330.341858,489.055847 330.837250,489.026428 
z"/>
<path fill="#87CEF0" opacity="1.000000" stroke="none" 
	d="
M270.402649,412.987244 
	C268.341614,409.907654 265.966217,407.010925 265.192169,403.735748 
	C264.316437,400.030182 266.837036,397.981567 270.756317,397.990021 
	C282.243378,398.014771 293.733887,397.859650 305.214386,398.135376 
	C307.111877,398.180939 310.538177,400.029968 310.545593,401.065094 
	C310.568115,404.194641 314.357788,408.265686 309.063782,410.617493 
	C308.359741,410.930267 307.887665,411.765198 307.146851,412.660706 
	C304.159485,411.875244 301.877625,412.266571 300.216614,415.107971 
	C299.764221,415.881836 298.633881,416.818176 297.875763,416.767395 
	C290.898407,416.300323 283.642883,418.995422 276.953857,414.728394 
	C275.234009,413.631287 272.865753,413.550629 270.402649,412.987244 
z"/>
<path fill="#5E6374" opacity="1.000000" stroke="none" 
	d="
M257.114990,430.971893 
	C253.042786,430.815765 249.171371,430.319397 245.039246,429.522003 
	C245.631363,427.193848 246.358704,423.514923 247.359665,423.438904 
	C250.830383,423.175323 254.435196,424.017303 257.858521,424.928741 
	C258.162170,425.009583 257.519104,428.646393 257.114990,430.971893 
z"/>
<path fill="#1D222F" opacity="1.000000" stroke="none" 
	d="
M608.232483,440.834778 
	C607.689819,440.519348 607.376526,440.039093 607.085938,439.253448 
	C607.559692,439.522003 608.010742,440.095978 608.232483,440.834778 
z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
